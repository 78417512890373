import { ILayer } from './LayerMenuControl';
import { Tools } from './EditingControl';

export class ExtendedEvent<TTarget = ControlEventTarget, TValue = unknown> extends Event {
    public eventTarget!: TTarget;
    public value: TValue;
    public oldValue?: TValue;
    public isOverride: boolean;

    constructor(type: string, value: TValue, oldValue?: TValue, isOverride = false) {
        super(type);
        this.value = value;
        this.oldValue = oldValue;
        this.isOverride = isOverride;
    }
}

type IBaseILayerEvent = ExtendedEvent<ControlEventTarget.ActiveBaseILayerKey, string>;
type IActiveILayerEvent = ExtendedEvent<ControlEventTarget.ActiveILayer, ILayer>;
type IActiveControllerEvent = ExtendedEvent<ControlEventTarget.ActiveController, string>;
type IIsDrawingEvent = ExtendedEvent<ControlEventTarget.IsDrawing, boolean>;
type IActiveToolEvent = ExtendedEvent<ControlEventTarget.ActiveTool, Tools>;
type ISaveStatusEvent = ExtendedEvent<ControlEventTarget.SaveStatus, SaveStatus>;
type IGPSCenterErrorEvent = ExtendedEvent<ControlEventTarget.GPSCenterError, boolean>;

export type IControlHandlerEvent =
    | IBaseILayerEvent
    | IActiveILayerEvent
    | IActiveControllerEvent
    | IIsDrawingEvent
    | IActiveToolEvent
    | ISaveStatusEvent
    | IGPSCenterErrorEvent;

// interface IBaseILayerEvent extends ExtendedEvent {
//     eventTarget: ControlEventTarget.ActiveBaseILayerKey;
//     value: string;
//     oldValue: string;
// }
// interface IActiveILayerEvent extends ExtendedEvent {
//     eventTarget: ControlEventTarget.ActiveILayer;
//     value: ILayer;
//     oldValue: ILayer;
// }

// interface IActiveControllerEvent extends ExtendedEvent {
//     eventTarget: ControlEventTarget.ActiveController;
//     value: string;
//     oldValue: string;
// }
// interface IIsDrawingEvent extends ExtendedEvent {
//     eventTarget: ControlEventTarget.IsDrawing;
//     value: boolean;
//     oldValue: boolean;
// }
// interface IActiveToolEvent extends ExtendedEvent {
//     eventTarget: ControlEventTarget.ActiveTool;
//     value: Tools;
//     oldValue: Tools;
// }
// interface ISaveStatusEvent extends ExtendedEvent {
//     eventTarget: ControlEventTarget.SaveStatus;
//     value: SaveStatus;
//     oldValue: SaveStatus;
// }
// interface IGPSCenterErrorEvent extends ExtendedEvent {
//     eventTarget: ControlEventTarget.GPSCenterError;
//     value: boolean;
//     oldValue: boolean;
// }

// export type IExtendedEvent = IBaseILayerEvent | IActiveILayerEvent | IActiveControllerEvent | IIsDrawingEvent | IActiveToolEvent | ISaveStatusEvent | IGPSCenterErrorEvent;

export enum ControlEventTarget {
    ActiveBaseILayerKey,
    ActiveILayer,
    ActiveController,
    IsDrawing,
    ActiveTool,
    SaveStatus,
    GPSCenterError,
}

export enum SaveStatus {
    Unedited,
    Ready,
    Start,
    Saving,
    Done,
    Cancel,
    Labels,
}
