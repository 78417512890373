const SvgAbv = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlSpace='preserve'
        width={28.249}
        height={28.586}
        fill='{{replacement-value-color}}'
    >
        <path
            d='M39.297 25.025c-2.053-.205-3.82-.461-3.927-.568-.334-.333-.44-22.654-.117-24.818l.306-2.057 4.297-.451c6.222-.652 12.583-.632 18.118.06 2.665.332 4.908.667 4.985.744.315.315.49 20.099.204 23.211l-.308 3.366-1.683.268c-2.927.467-17.986.635-21.875.245M60.05 11.047V5.623H38.355v10.848H60.05zm-12.072 1.259c-1.377-.77-.315-2.87 1.207-2.387l.766.243v.897c0 .493-.299 1.057-.664 1.252l-.664.356zm-4.942-.278c-.638-.136-1.171-.518-1.243-.89l-.125-.652h1.118c.615 0 1.183-.194 1.262-.432l.144-.43 1.054.69 1.055.69-.835.676c-.459.372-.942.656-1.073.632a96.942 96.942 0 0 1-1.357-.284m8.75-.374-.862-.698 1.197-.618c.665-.344 1.197-.449 1.197-.236 0 .211.505.384 1.122.384h1.122v.573c0 .586-.206.702-1.925 1.076l-.99.216z'
            transform='translate(-35.058 3.344)'
        />
    </svg>
);

export default SvgAbv;
