const SvgADKcentral = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        width='30'
        zoomAndPan='magnify'
        viewBox='0 0 30 30.000001'
        height='30'
        preserveAspectRatio='xMidYMid meet'
        version='1.0'
    >
        <defs>
            <g />
            <clipPath id='e8a2898247'>
                <path
                    d='M 0.484375 0 L 29.515625 0 L 29.515625 29.03125 L 0.484375 29.03125 Z M 0.484375 0 '
                    clipRule='nonzero'
                />
            </clipPath>
            <clipPath id='f0515c8b87'>
                <path d='M 0.484375 8 L 10 8 L 10 19 L 0.484375 19 Z M 0.484375 8 ' clipRule='nonzero' />
            </clipPath>
            <clipPath id='9e0e0685a1'>
                <path d='M 21 8 L 29.515625 8 L 29.515625 19 L 21 19 Z M 21 8 ' clipRule='nonzero' />
            </clipPath>
        </defs>
        <g clipPath='url(#e8a2898247)'>
            <path
                fill='{{replacement-value-color}}'
                d='M 0.484375 0 L 29.515625 0 L 29.515625 29.03125 L 0.484375 29.03125 Z M 0.484375 0 '
                fillOpacity='1'
                fillRule='nonzero'
            />
            <path
                fill='{{replacement-value-color}}'
                d='M 0.484375 0 L 29.515625 0 L 29.515625 29.03125 L 0.484375 29.03125 Z M 0.484375 0 '
                fillOpacity='1'
                fillRule='nonzero'
            />
            <path
                fill='{{replacement-value-color}}'
                d='M -0.683594 0 L 30.703125 0 L 30.703125 29.757812 L -0.683594 29.757812 Z M -0.683594 0 '
                fillOpacity='1'
                fillRule='nonzero'
            />
        </g>
        <g clipPath='url(#f0515c8b87)'>
            <g fill='{{replacement-value-color}}' fillOpacity='1'>
                <g transform='translate(0.48387, 18.372814)'>
                    <g>
                        <path d='M 9.390625 0 L 7.359375 0 L 6.625 -2.25 L 2.875 -2.25 L 2.140625 0 L 0.140625 0 L 3.53125 -9.78125 L 6.015625 -9.78125 Z M 4.65625 -7.734375 L 3.390625 -3.875 L 6.078125 -3.875 L 4.8125 -7.734375 Z M 4.65625 -7.734375 ' />
                    </g>
                </g>
            </g>
        </g>
        <g fill='{{replacement-value-color}}' fillOpacity='1'>
            <g transform='translate(10.015847, 18.372814)'>
                <g>
                    <path d='M 4.796875 -9.78125 C 6.378906 -9.78125 7.585938 -9.367188 8.421875 -8.546875 C 9.265625 -7.722656 9.6875 -6.503906 9.6875 -4.890625 C 9.6875 -3.273438 9.265625 -2.054688 8.421875 -1.234375 C 7.585938 -0.410156 6.378906 0 4.796875 0 L 1.265625 0 L 1.265625 -9.78125 Z M 4.671875 -1.625 C 5.679688 -1.625 6.4375 -1.878906 6.9375 -2.390625 C 7.4375 -2.910156 7.6875 -3.742188 7.6875 -4.890625 C 7.6875 -6.035156 7.4375 -6.863281 6.9375 -7.375 C 6.4375 -7.894531 5.679688 -8.15625 4.671875 -8.15625 L 3.203125 -8.15625 L 3.203125 -1.625 Z M 4.671875 -1.625 ' />
                </g>
            </g>
        </g>
        <g clipPath='url(#9e0e0685a1)'>
            <g fill='{{replacement-value-color}}' fillOpacity='1'>
                <g transform='translate(20.470918, 18.372814)'>
                    <g>
                        <path d='M 1.265625 0 L 1.265625 -9.78125 L 3.203125 -9.78125 L 3.203125 -5.78125 L 3.421875 -5.78125 L 6.6875 -9.78125 L 8.671875 -9.78125 L 8.671875 -9.609375 L 5.0625 -5.171875 L 9.046875 -0.171875 L 9.046875 0 L 6.859375 0 L 3.421875 -4.21875 L 3.203125 -4.21875 L 3.203125 0 Z M 1.265625 0 ' />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
export default SvgADKcentral;
