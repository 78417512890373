
    const Svg2_06_06 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="334.71 254.97000000000003 146.52000000000004 101.01999999999998" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M339.71 323.68L430.72 323.68 339.71 287.28 339.71 323.68Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M430.72 323.68L430.72 287.28 339.71 287.28 430.72 323.68Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M430.72 287.28L476.23 305.48 430.72 259.97 430.72 287.28Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M430.72 350.99L476.23 305.48 430.72 323.68 430.72 350.99Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M430.72 323.68L476.23 305.48 430.72 287.28 430.72 323.68Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M430.72 259.97L430.72 350.99Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M430.72 350.99L476.23 305.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M476.23 305.48L430.72 259.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M430.72 287.28L339.71 287.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M339.71 287.28L339.71 323.68Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M339.71 323.68L430.72 323.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M430.72 323.68L430.72 287.28Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_06_06;

    