
    const Svg1_07_02 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="225.79 221.67 410.84000000000003 150.76000000000002" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M230.79 367.43L512.32 367.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M512.32 226.67L230.79 226.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M230.79 226.67L230.79 367.43Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M512.32 367.43L512.32 226.67Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M300.55 261.86L300.55 312.13Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M300.55 312.13L303.9 322.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M303.9 322.19L310.6 328.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.6 328.89L320.66 332.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.66 332.24L327.36 332.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M327.36 332.24L337.41 328.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M337.41 328.89L344.12 322.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M344.12 322.19L347.47 312.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M347.47 312.13L347.47 261.86Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.55 278.62L421.2 271.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.2 271.92L414.5 265.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.5 265.21L407.8 261.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.8 261.86L394.39 261.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.39 261.86L387.69 265.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.69 265.21L380.98 271.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.98 271.92L377.63 278.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.63 278.62L374.28 288.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.28 288.67L374.28 305.43Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.28 305.43L377.63 315.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.63 315.49L380.98 322.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.98 322.19L387.69 328.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.69 328.89L394.39 332.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.39 332.24L407.8 332.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.8 332.24L414.5 328.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.5 328.89L421.2 322.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.2 322.19L424.55 315.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M540.47 367.43L578.01 311.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M540.47 311.13L578.01 367.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M594.09 367.43L631.63 311.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M594.09 311.13L631.63 367.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_07_02;

    