
    const Svg2_06_02 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="351.86 228.96 104.88 104.87999999999997" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M356.86 233.96L356.86 328.84Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.74 328.84L451.74 233.96Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.2 305.12L382.2 257.68Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.2 257.68L402.53 257.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.53 257.68L409.31 259.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.31 259.94L411.57 262.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.57 262.2L413.83 266.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.83 266.72L413.83 273.5Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.83 273.5L411.57 278.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.57 278.02L409.31 280.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.31 280.28L402.53 282.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.53 282.53L382.2 282.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.86 328.84L451.74 328.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.74 233.96L356.86 233.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_06_02;

    