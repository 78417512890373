const SvgNglskab = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        width='30'
        zoomAndPan='magnify'
        viewBox='0 0 30 30.000001'
        height='30'
        preserveAspectRatio='xMidYMid meet'
        version='1.0'
    >
        <defs>
            <g />
            <clipPath id='14863eef76'>
                <path
                    d='M 0.484375 0 L 29.515625 0 L 29.515625 29.03125 L 0.484375 29.03125 Z M 0.484375 0 '
                    clipRule='nonzero'
                />
            </clipPath>
        </defs>
        <g clipPath='url(#14863eef76)'>
            <path
                fill='{{replacement-value-color}}'
                d='M 1.269531 28.242188 L 28.796875 28.242188 L 28.796875 0.714844 L 1.269531 0.714844 Z M 30.308594 29.753906 L -0.242188 29.753906 L -0.242188 -0.796875 L 30.308594 -0.796875 L 30.308594 29.753906 '
                fillOpacity='1'
                fillRule='nonzero'
            />
        </g>
        <g fill='{{replacement-value-color}}' fillOpacity='1'>
            <g transform='translate(1.198803, 22.03432)'>
                <g>
                    <path d='M 1.46875 0 L 1.46875 -14.21875 L 4.265625 -14.21875 L 10.078125 -4.71875 L 10.078125 -14.21875 L 12.75 -14.21875 L 12.75 0 L 9.875 0 L 4.125 -9.265625 L 4.125 0 Z M 1.46875 0 ' />
                </g>
            </g>
        </g>
        <g fill='{{replacement-value-color}}' fillOpacity='1'>
            <g transform='translate(15.540545, 22.03432)'>
                <g>
                    <path d='M 0.71875 -4.625 L 3.515625 -4.90625 C 3.671875 -3.957031 4.003906 -3.257812 4.515625 -2.8125 C 5.035156 -2.375 5.734375 -2.15625 6.609375 -2.15625 C 7.535156 -2.15625 8.234375 -2.347656 8.703125 -2.734375 C 9.179688 -3.128906 9.421875 -3.59375 9.421875 -4.125 C 9.421875 -4.457031 9.316406 -4.742188 9.109375 -4.984375 C 8.910156 -5.222656 8.570312 -5.429688 8.09375 -5.609375 C 7.738281 -5.722656 6.96875 -5.925781 5.78125 -6.21875 C 4.238281 -6.59375 3.160156 -7.0625 2.546875 -7.625 C 1.671875 -8.40625 1.234375 -9.359375 1.234375 -10.484375 C 1.234375 -11.210938 1.4375 -11.890625 1.84375 -12.515625 C 2.257812 -13.148438 2.851562 -13.628906 3.625 -13.953125 C 4.40625 -14.285156 5.335938 -14.453125 6.421875 -14.453125 C 8.203125 -14.453125 9.539062 -14.0625 10.4375 -13.28125 C 11.34375 -12.5 11.820312 -11.457031 11.875 -10.15625 L 9 -10.03125 C 8.875 -10.757812 8.609375 -11.28125 8.203125 -11.59375 C 7.804688 -11.914062 7.203125 -12.078125 6.390625 -12.078125 C 5.679688 -12.078125 5.097656 -11.953125 4.640625 -11.703125 C 4.191406 -11.460938 3.96875 -11.125 3.96875 -10.6875 C 3.96875 -10.351562 4.113281 -10.066406 4.40625 -9.828125 C 4.769531 -9.523438 5.648438 -9.207031 7.046875 -8.875 C 8.441406 -8.539062 9.472656 -8.195312 10.140625 -7.84375 C 10.804688 -7.5 11.328125 -7.019531 11.703125 -6.40625 C 12.085938 -5.789062 12.28125 -5.03125 12.28125 -4.125 C 12.28125 -3.320312 12.050781 -2.566406 11.59375 -1.859375 C 11.144531 -1.148438 10.503906 -0.617188 9.671875 -0.265625 C 8.847656 0.0859375 7.820312 0.265625 6.59375 0.265625 C 3.039062 0.265625 1.082031 -1.363281 0.71875 -4.625 Z M 0.71875 -4.625 ' />
                </g>
            </g>
        </g>
    </svg>
);
export default SvgNglskab;
