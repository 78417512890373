
    const Svg1_05_07 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="306.47 203.18 194.57999999999998 194.57999999999998" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M311.47 300.47L403.76 392.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.76 392.76L496.05 300.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M496.05 300.47L403.76 208.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.76 208.18L311.47 300.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_05_07;

    