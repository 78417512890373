
    const SvgXx_07_02 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="318.18 265.69 144.95999999999998 95.25999999999999" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M458.14 355.95L458.14 270.69Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.14 270.69L323.18 270.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.29 296.27L399.18 296.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.62 296.27L449.62 296.27 418.29 304.8 418.29 287.75 449.62 296.27Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M418.29 330.37L399.18 330.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.62 330.37L449.62 330.37 418.29 338.9 418.29 321.85 449.62 330.37Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M363.03 296.27L382.13 296.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M331.7 296.27L331.7 296.27 363.03 287.75 363.03 304.8 331.7 296.27Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M363.03 330.37L382.13 330.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M331.7 330.37L331.7 330.37 363.03 321.85 363.03 338.9 331.7 330.37Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M382.13 330.37L399.18 296.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.13 296.27L399.18 330.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.14 355.95L323.18 355.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.18 355.95L323.18 270.69Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_07_02;

    