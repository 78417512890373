import { Dispatch, ReactNode, RefObject, createContext, useId, useRef, useState } from 'react';

import DataGrid from 'devextreme-react/data-grid';
import { ValidatedAutoGridProps } from './auto-grid';

export interface IAutoGridContext {
    id: string;
    props: ValidatedAutoGridProps;
    gridRef: RefObject<DataGrid>;
    setGridRef: Dispatch<React.SetStateAction<RefObject<DataGrid>>>;
}

export const AutoGridContext = createContext<IAutoGridContext>({} as unknown as IAutoGridContext);
export const AutoGridProvider = ({
    children,
    props,
}: {
    children: ReactNode | ReactNode[];
    props: ValidatedAutoGridProps;
}) => {
    const [gridRef, setGridRef] = useState<RefObject<DataGrid>>(useRef(null));
    const id = useId();

    return (
        <>
            <AutoGridContext.Provider value={{ id, gridRef, setGridRef, props }}>{children}</AutoGridContext.Provider>
        </>
    );
};
