
    const Svg2_01_04A = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="319.08 235.25 187.25 99.26999999999998" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M324.08 329.52L413.34 329.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.34 329.52L413.34 240.25Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.34 240.25L324.08 240.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.08 240.25L324.08 329.52Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.2 329.52L431.2 284.88Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.2 284.88L458.83 284.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.2 306.14L448.2 306.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.2 329.52L458.83 329.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M471.58 329.52L501.33 284.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M471.58 284.88L501.33 329.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_01_04A;

    