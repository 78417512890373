import { useState } from 'react';

/**
 * useState storing the state in localstorage for persistence
 *
 * @param key localstorage key
 * @param initialState Serializable state
 * @returns an array with the state and a state setter
 */
// T extends {} stops function taking null | undefined as type param
const useLocalStorageState = <T,>(key: string, initialState: T | undefined = undefined) => {
    const lazyLoadInitalState = () => {
        const storedState = window.localStorage.getItem(key);
        if (storedState != null)
            try {
                return JSON.parse(storedState) as T;
            } catch (err) {
                return;
            }
        return initialState;
    };

    const [state, setState] = useState<T | undefined>(lazyLoadInitalState);

    const setNewState = (newState: T | undefined) => {
        window.localStorage.setItem(key, JSON.stringify(newState));
        setState(newState);
    };

    return [state, setNewState] as [T | undefined, (arg0: T | undefined) => void];
};

export default useLocalStorageState;
