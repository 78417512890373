
    const Svg1_01_07 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="305.34 251.47000000000003 164.63000000000005 113.08999999999997" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M439.21 282.24L336.11 282.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.11 297.7L336.11 266.78Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.2 297.7L439.2 266.78Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.34 359.56L464.97 359.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.97 359.56L464.97 256.47Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.97 256.47L310.34 256.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.34 256.47L310.34 359.56Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.42 308.01L413.22 311.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.22 311.18L412.64 314.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.64 314.3L411.68 317.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.68 317.32L410.35 320.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.35 320.21L408.68 322.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.68 322.9L406.69 325.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.69 325.38L404.41 327.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.41 327.58L401.88 329.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.88 329.5L399.13 331.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.13 331.08L396.21 332.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.21 332.32L393.16 333.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.16 333.19L390.03 333.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.03 333.67L386.85 333.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.85 333.77L383.69 333.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.69 333.48L380.59 332.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.59 332.8L377.6 331.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.6 331.75L374.76 330.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.76 330.33L372.12 328.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.12 328.58L369.71 326.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.71 326.51L367.57 324.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.57 324.17L365.74 321.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.74 321.58L364.24 318.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.24 318.78L363.09 315.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.09 315.83L362.31 312.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.31 312.75L361.93 309.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.93 309.6L361.93 306.43Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.93 306.43L362.31 303.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.31 303.28L363.09 300.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.09 300.2L364.24 297.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.24 297.24L365.74 294.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.74 294.45L367.57 291.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.57 291.86L369.71 289.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.71 289.51L372.12 287.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.12 287.45L374.76 285.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.76 285.7L377.6 284.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.6 284.28L380.59 283.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.59 283.23L383.69 282.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.69 282.55L386.85 282.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.85 282.25L390.03 282.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.03 282.35L393.16 282.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.16 282.84L396.21 283.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.21 283.71L399.13 284.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.13 284.94L401.88 286.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.88 286.53L404.41 288.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.41 288.44L406.69 290.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.69 290.65L408.68 293.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.68 293.12L410.35 295.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.35 295.82L411.68 298.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.68 298.7L412.64 301.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.64 301.73L413.22 304.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.22 304.85L413.42 308.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_07;

    