import { DropDownOptions, Lookup } from 'devextreme-react/lookup';
import { useContext, useMemo, useRef, useState } from 'react';

import Api from '../../../../shared/networking/api';
import { AutoGridContext } from '../../context';
import { Button } from 'devextreme-react/button';
import { IDictionary } from '../../../../shared/utils/types';
import { Panels } from '../interfaces';
import { ToolbarContext } from '../context';
import { ZExtras } from '../../layout-interface';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import useBreakpoints from '../../../../shared/hooks/use-breakpoints';
import useCentralizedSnackbar from '../../../../shared/hooks/redux-use-centralized-snackbar';
import { useEnhedId } from '../../../../shared/hooks/use-enhed-id';
import { useParams } from 'react-router-dom';

const Insert = ({ showLookup, lookupUrl }: ZExtras) => {
    const { enqueueSnackbar } = useCentralizedSnackbar();
    const { activePanel, setActivePanel } = useContext(ToolbarContext);
    const { md } = useBreakpoints();
    const {
        gridRef,
        props: { url, append: { get = '', post = '' } = {} },
    } = useContext(AutoGridContext);
    const [opened, setOpened] = useState(false);
    const enhId = useEnhedId();
    const { bygId, delId } = useParams<{ bygId: string; delId: string }>();

    const selectedIdRef = useRef<string | number>();
    const lookupSource = useMemo(
        () =>
            createStore({
                key: 'id',
                loadUrl: lookupUrl ? Api.createUrl(lookupUrl) : Api.createUrl(url) + '/Lookup/Priskatalog' + get,
                loadMode: 'raw',
                onBeforeSend: (_operation, ajax) => {
                    const headers: IDictionary<string> = {
                        Authorization: `Bearer ${Api.token}`,
                    };
                    ajax.headers = headers;
                },
            }),
        [get, url, lookupUrl]
    );

    const insert = async (template: boolean) => {
        let extraUrl = '';
        if (template) {
            if (!selectedIdRef.current)
                return enqueueSnackbar('Vælg en skabelon fra priskataloget først', { variant: 'error' });
            extraUrl = `&priskatalog=${encodeURIComponent(selectedIdRef.current)}&bygid=${bygId}`;
        }
        const _url = url + post + `${post.includes('?') ? '&' : '?'}enhedid=${enhId}&delid=${delId}${extraUrl}`;
        const response = await Api.post<{ id: number }[]>(_url);
        if (!Api.ok(response)) return enqueueSnackbar('Der skete en fejl. Intet blev oprettet', { variant: 'error' });

        const newActivities = [...JSON.parse(sessionStorage.getItem('newActivities') || '[]'), response.data[0].id];
        sessionStorage.setItem('newActivities', JSON.stringify(newActivities));
        window.dispatchEvent(new Event('storage'));
        await gridRef.current?.instance.refresh(false);
        const rowIndex = gridRef.current?.instance.getRowIndexByKey(response.data[0].id);
        // rowIndex !== undefined && gridRef.current?.instance.editRow(rowIndex);
        setActivePanel(Panels.None);
    };
    //test
    const onAddClick = () => {
        if (showLookup) {
            setActivePanel(Panels.Insert);
        } else {
            insert(false);
        }
    };

    if (activePanel === Panels.None) return <Button icon='add' onClick={onAddClick} />;
    const lookupWidth = gridRef.current?.instance.element().offsetWidth
        ? `calc(${gridRef.current?.instance.element().offsetWidth}px - 126px)`
        : 'calc(80vw - 126px)';

    return (
        <>
            <Button icon='plus' rtlEnabled className='dg-item-child' onClick={() => insert(true)} type='success' />
            <Button
                icon='close'
                rtlEnabled
                className='dg-item-child'
                onClick={() => {
                    setActivePanel(Panels.None);
                }}
                type='danger'
            />
            <Lookup
                width={lookupWidth}
                dataSource={lookupSource}
                onSelectionChanged={(e) => (selectedIdRef.current = e.selectedItem.id)}
                valueExpr='id'
                deferRendering={false}
                displayExpr='value'
                searchEnabled={true}
                searchExpr={'value'}
                searchMode={'contains'}
                searchStartEvent='input change keyup'
                className='dg-item-child'
                opened={opened}
                onOpened={() => setOpened(true)}
                onClosed={() => setOpened(false)}
                onContentReady={() => setOpened(true)}
            >
                <DropDownOptions fullScreen={!md} hideOnOutsideClick />
            </Lookup>
        </>
    );
};

export default Insert;
