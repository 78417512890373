import { Button, Column } from 'devextreme-react/data-grid';
import GetCommandRender, { CommandRenderKeys, ICommandMoreProps, IDxRenderProps } from './render-commands';
import { RenderedSpecialColumn, ZCommand, ZSpecialColumn } from './layout-interface';

/**
 * Function to flatmap ISpecialColumn to their respective components, meant to be parsed into the flatmap function of an ISpecialColumn array
 *
 * @example const renderedColumns = specialColumns.flatMap((column, index) => flatmapSpecialColumns(column, index, endpoint));
 *
 * @param column An array of columns to create renders for - param from flatmap
 * @param index index of the current column - param from flatmap
 * @param endpoint api endpoint for this grid
 * @returns An array of rendered columns to display
 */
export const flatmapSpecialColumns = (
    { items, position, width, name, caption = 'Knapper', showInColumnChooser }: ZSpecialColumn,
    index: number
) => {
    //#region Command Column

    if (name === 'command') {
        const commandButtons: JSX.Element[] = [];
        const menuItems: ((dxProps: IDxRenderProps) => JSX.Element)[] = [];

        // Populer ovenstående lister
        for (const [bIndex, item] of items.entries()) {
            const render = GetCommandRender(item.name, item);
            if (render === undefined) continue;
            if (item.asMenuItem) menuItems.push(render);
            else {
                commandButtons.push(
                    <Button
                        key={`cb${bIndex}`}
                        visible={item.visible}
                        disabled={item.disabled}
                        hint={item.hint}
                        render={render}
                    />
                );
            }
        }

        // Lav "more" knap
        if (menuItems.length > 0)
            commandButtons.push(
                <Button
                    key={`cbOther`}
                    hint='Andre muligheder'
                    cssClass={'otherCommandButton'}
                    render={GetCommandRender<ICommandMoreProps>(
                        CommandRenderKeys.More,
                        {} as ZCommand, // More is not a ZCommand from schema, but is generated the same way
                        { itemFactories: menuItems }
                    )}
                />
            );

        // Retuner colonne
        return [
            {
                position: position,
                element: (
                    <Column
                        type='buttons'
                        key={`cb${index}`}
                        width={width}
                        caption={caption}
                        showInColumnChooser={showInColumnChooser}
                    >
                        {commandButtons}
                    </Column>
                ),
            } as RenderedSpecialColumn,
        ];
    }

    //#endregion Command Column

    return [];
};
