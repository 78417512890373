const SvgEltavleE = () => (
    <svg xmlns='http://www.w3.org/2000/svg' xmlSpace='preserve' width={21.202} height={29.65}>
        <path
            d='M-26.232 57.67c-.103-.166-.087-6.838.034-14.826l.22-14.524h20.88v29.65h-10.474c-5.872 0-10.556-.132-10.66-.3m20.135-14.692V29.32H-25.086V56.637H-6.097zm-17.91.084.087-12.91h16.657l.087 12.91.087 12.909h-17.006zm9.522 3.015c1.865-2.785 3.39-5.146 3.39-5.247 0-.101-.915-.184-2.034-.184h-2.034l.194-.506c.107-.278.798-1.395 1.535-2.48.737-1.086 1.34-2.057 1.34-2.16 0-.1-1.116-.184-2.48-.184h-2.48l-1.2 3.845c-.66 2.115-1.281 4.056-1.38 4.314l-.18.468 1.812.1 1.812.101-.93 2.982c-1.206 3.86-1.424 4.763-1.053 4.348.164-.184 1.824-2.612 3.688-5.397'
            style={{
                fill: '{{replacement-value-color}}',
                fillOpacity: 1,
            }}
            transform='translate(26.3 -28.32)'
        />
    </svg>
);
export default SvgEltavleE;
