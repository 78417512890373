import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { AutoGridContext } from '../context';
import { Panels } from './interfaces';

// sanitize id
const si = (s: string) => s.replaceAll(':', '\\:');

export interface IToolbarProvider {
    activePanel?: Panels;
    setActivePanel(m: Panels): void;
}

export const ToolbarContext = createContext<IToolbarProvider>({} as unknown as IToolbarProvider);
export const ToolbarProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const { id } = useContext(AutoGridContext);
    const [activePanel, _setActivePanel] = useState<Panels>(Panels.None);

    const setActivePanel = (m: Panels) => {
        _setActivePanel(m);
    };

    // Changes visiblity of panels without using state, since the items being changed are inserted directly into the datagrid
    useEffect(() => {
        const elements = document.querySelectorAll(
            `div#${si(
                id
            )} div.dx-toolbar-items-container div.dx-item.dx-toolbar-item.dx-toolbar-button:not(.dx-state-invisible)`
        ) as unknown as HTMLDivElement[];
        elements.forEach((e) => {
            e.classList.remove('dx-auto-grid-ct-hide');
            !e.classList.contains(activePanel) && e.classList.add('dx-auto-grid-ct-hide');
        });
    });

    return (
        <ToolbarContext.Provider value={{ activePanel: activePanel, setActivePanel: setActivePanel }}>
            {children}
        </ToolbarContext.Provider>
    );
};
