import Popup from 'devextreme-react/popup';
import { PropsOf } from '../utils/types';
import { useState } from 'react';

const usePopup = (startVisible = false) => {
    const [visible, setVisible] = useState(startVisible);

    const show = () => setVisible(true);
    const hide = () => setVisible(false);
    const popup = (props: Exclude<PropsOf<Popup>, 'visible'>) => (
        <Popup visible={visible} onHiding={hide} showCloseButton {...props} />
    );

    return [popup, show, hide, visible] as const;
};

export default usePopup;
