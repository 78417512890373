
    const Svg3_07_08 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="331.33 264.4 133.22000000000003 71.61000000000001" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M459.55 269.4L336.33 269.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.33 269.4L336.33 331.01Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.33 331.01L459.55 331.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.55 331.01L459.55 269.4Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M351.73 269.4L351.73 281.72Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.54 269.4L382.54 281.72Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.34 269.4L413.34 281.72Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.14 269.4L444.14 281.72Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.94 331.01L397.94 318.69Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M448.34 298.91L447.95 300.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.95 300.49L447.25 301.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.25 301.95L446.28 303.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.28 303.25L445.07 304.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M445.07 304.33L443.67 305.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.67 305.15L442.14 305.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.14 305.68L440.53 305.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.53 305.89L403.33 305.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.33 305.89L403.33 305.89Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.33 305.89L402.04 306.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.04 306.05L400.82 306.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.82 306.51L399.75 307.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.75 307.24L398.88 308.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.88 308.21L398.26 309.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.26 309.36L397.94 310.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M347.54 298.91L347.93 300.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M347.93 300.49L348.62 301.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.62 301.95L349.6 303.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.6 303.25L350.8 304.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.8 304.33L352.2 305.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.2 305.15L353.74 305.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.74 305.68L355.34 305.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.34 305.89L392.54 305.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.54 305.89L392.54 305.89Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.54 305.89L393.83 306.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.83 306.05L395.05 306.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.05 306.51L396.13 307.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.13 307.24L397 308.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397 308.21L397.61 309.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.61 309.36L397.94 310.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg3_07_08;

    