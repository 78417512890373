const SvgBrandB = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlSpace='preserve'
        width={21.837}
        height={27.713}
        fill='{{replacement-value-color}}'
    >
        <path
            d='M8.455 23.534c-.167-.954-.303-2.063-.303-2.465v-.731h.806c.443 0 .857.092.92.205.062.113-.01 1.223-.16 2.466l-.274 2.26H9.1c-.207 0-.463-.687-.645-1.735m-.497-3.933c-.098-.255-.063-.501.077-.548.152-.05.223-3.957.176-9.741C8.168 4 8.203-.63 8.29-.978l.157-.635.606.086.607.087.072 10.111.072 10.112.294.355c.453.546.039.926-1.01.926h-.952zm-1.05-2.62c-.073-.19-.1-4.587-.06-9.771l.072-9.427.342-.114.343-.114v19.769h-.283c-.155 0-.342-.154-.415-.344m14.941-9.518v-9.908l.343.114.342.114v19.359l-.342.114-.343.114zm-11.176 8.71-.329-.328V-.962l.434-.36.434-.36.456.287.457.288.072 8.43.073 8.429-.312.375c-.384.463-.852.48-1.285.047m2.26-.015c-.072-.19-.099-4.227-.06-8.972l.073-8.626h1.37l.072 8.596.073 8.596-.312.375c-.39.47-1.04.486-1.215.03m2.2.019c-.077-.2-.107-4.246-.068-8.99l.073-8.627h1.37v17.806l-.618.087c-.34.049-.68-.076-.757-.276m2.363-.05-.312-.375.073-8.43.072-8.429.496-.304.495-.303.395.531.395.532v8.047c0 5.023-.107 8.246-.284 8.577-.331.62-.89.685-1.33.154m2.217.047-.33-.329V-.962l.435-.36.434-.36.456.287.457.288.072 8.43.073 8.429-.312.375c-.384.463-.852.48-1.285.047m5.603-7.616-.692-.821h-.702c-.387 0-.703-.124-.703-.274 0-.151.316-.274.703-.274h.702l.692-.822.691-.822H27.562l.568.722c.746.948.717 1.62-.105 2.44-.913.914-1.859.862-2.71-.149m1.19-.41a.412.412 0 0 0-.41-.411c-.428 0-.544.324-.229.639.315.315.64.2.64-.229m1.307-.086c.06-.18-.086-.325-.325-.325a.435.435 0 0 0-.434.433c0 .484.59.4.76-.108m-1.307-1.307c0-.239-.146-.385-.325-.325-.508.169-.592.759-.108.759a.435.435 0 0 0 .433-.434m1.307.108c-.17-.508-.759-.592-.759-.108 0 .238.195.434.434.434.238 0 .385-.147.325-.326'
            transform='translate(-6.83 2.445)'
        />
    </svg>
);
export default SvgBrandB;
