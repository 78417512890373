
    const Svg1_05_03 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="338.21 242.74 208.52000000000004 117.44999999999999" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M450.66 355.19L450.66 247.74Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.21 247.74L343.21 355.19Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.66 247.74L343.21 355.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.21 355.19L450.66 355.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.66 247.74L343.21 247.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M472.14 355.19L500.8 312.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M472.14 312.21L500.8 355.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M513.08 355.19L541.73 312.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M513.08 312.21L541.73 355.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_05_03;

    