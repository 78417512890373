import { Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style';
import { ClickableFeature, ClickableFeatureType } from '../ClickableFeatures';

import { BaseIconId } from '../SVG';
import CTLayer from '../CTLayer';
import { Cluster } from 'ol/source';
import { Colors } from '../Colors';
import Feature from 'ol/Feature';
import { FeatureProperty } from '../feature-utility';
import Geometry from 'ol/geom/Geometry';
import { IDictionary } from '../../../../shared/utils/types';
import { IMapLayer } from '../../caretaker-map';
import { Notifyer } from '../Notifyer';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import { WKT } from 'ol/format';
import { decodeJsonResponse } from './DataIO';

export interface Options {
    statusColumnName?: string;
    minZoom?: number;
    maxZoom?: number;
}

function scale(number: number, inMin: number, inMax: number, outMin: number, outMax: number) {
    return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}

/**
 * @deprecated Use GeometryLayer instead
 */
export class EnhLayer extends CTLayer<Cluster> /*VectorLayer<VectorSource<any>>*/ {
    private styleCache: IDictionary = {};
    private static wktFormat = new WKT();
    private asyncNotifyer?: Notifyer;

    public baseIconID: BaseIconId = BaseIconId.Enhed;

    public addPoints(coordinateList: number[][]) {
        coordinateList.forEach((coor) =>
            (this.getSource() as Cluster)?.getSource()?.addFeature(new Feature(new Point(coor)))
        );
    }

    public addPointsFromWKTPoints(features: Feature<Geometry>[]) {
        this.getSource()
            ?.getSource()
            ?.addFeatures(features.filter((f) => f != null));
    }

    public clearPoints() {
        this.getSource()?.getSource()?.clear();
    }

    public setStatusColumnName(columnName: string) {
        this.setStyle((feature) => {
            const features = feature.get('features');
            const size = features.length;
            const status =
                size === 1
                    ? features[0].get(FeatureProperty.Data)[columnName] / 100
                    : (features as Array<Feature<any>>)
                          .map((f) => f.get(FeatureProperty.Data)[columnName] / 100)
                          .reduce((a, b) => a + b) / size;

            return new Style({
                image: new CircleStyle({
                    radius: size > 1 ? 15 : 12,
                    stroke: new Stroke({
                        color: '#fff',
                    }),
                    fill: new Fill({
                        color: Colors.hsvToRgb(scale(status, 0, 1, 0, 0.4), 0.9, 0.8),
                    }),
                }),
                text: new Text({
                    text: (size > 1 ? size.toString() + '\u000A' : '') + (status * 100).toFixed(0) + '%',
                    fill: new Fill({
                        color: '#fff',
                    }),
                }),
            });
        });
    }

    public async loadEnhDots(dataEndpointUri: string, geometryColumnName: string, statusColumnName?: string) {
        const self = this;
        let json;
        try {
            // eslint-disable-next-line no-restricted-syntax
            json = await fetch(dataEndpointUri)
                .then((r) => r.json())
                .then((json) => decodeJsonResponse(json));
        } catch (err) {
            json = [];
        }

        if (statusColumnName != null && statusColumnName !== '') self.setStatusColumnName(statusColumnName);
        self
            .getSource()
            ?.getSource()
            ?.addFeatures(
                json
                    .map((o: IDictionary) => {
                        const feature =
                            o[geometryColumnName] === '' || o[geometryColumnName] === undefined
                                ? new Feature<any>()
                                : EnhLayer.wktFormat.readFeature(o[geometryColumnName]);
                        if (feature != null) {
                            feature.set(FeatureProperty.Data, o);
                            (feature as ClickableFeature).dataColumns = ['ENHEDSYS', 'ENHKODE', 'NAVN', 'STATUS'];
                            (feature as ClickableFeature).webPage = '/Enhed/EnhedMenu/{EnhedSYS}?caretakerMap=true';
                            (feature as ClickableFeature).featureType = ClickableFeatureType.Enh;
                        }
                        return feature;
                    })
                    .filter((f) => f != null)
            );
        if (this.asyncNotifyer !== undefined) this.asyncNotifyer.enhLoadDone = true;
    }

    constructor(opt_options: Options & IMapLayer, asyncNotifyer?: Notifyer) {
        super(opt_options, {
            source: new Cluster({
                distance: 40,
                source: new VectorSource(),
            }),
            style: (feature) => {
                const features = feature.get('features');
                const size = features.length;
                let style = opt_options?.statusColumnName ?? this.styleCache[size];
                const status =
                    size === 1
                        ? features[0].get(FeatureProperty.Data)[style] / 100
                        : (features as Array<Feature<any>>)
                              .map((f) => f.get(FeatureProperty.Data)[style] / 100)
                              .reduce((a, b) => a + b) / size;
                let radius: number;
                if (opt_options?.statusColumnName == null)
                    if (size > 1) radius = 12;
                    else radius = 8;
                else {
                    if (size > 1) radius = 15;
                    else radius = 12;
                }

                if (!style) {
                    style = new Style({
                        image: new CircleStyle({
                            radius: radius,
                            stroke: new Stroke({
                                color: '#fff',
                            }),
                            fill: new Fill({
                                color:
                                    opt_options?.statusColumnName != null
                                        ? Colors.hsvToRgb(scale(status, 0, 1, 0, 0.4), 0.9, 0.8)
                                        : '#3399CC',
                            }),
                        }),
                        text: new Text({
                            text:
                                (opt_options?.statusColumnName != null || size > 1 ? size.toString() : '') +
                                (opt_options?.statusColumnName != null
                                    ? '\u000A' + (status * 100).toFixed(0) + '%'
                                    : ''),
                            fill: new Fill({
                                color: '#fff',
                            }),
                        }),
                    });
                    this.styleCache[size] = style;
                }
                return style;
            },
            zIndex: 1000,
            minZoom: opt_options?.minZoom ?? 0,
            maxZoom: opt_options?.maxZoom ?? 12,
        });

        this.asyncNotifyer = asyncNotifyer;

        this.loadEnhDots = this.loadEnhDots.bind(this);
    }
}
