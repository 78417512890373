
    const SvgXx_06_20 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="349.59 257.85 108.23000000000002 106.79999999999995" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M411.52 328.93L411.52 262.85Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.21 328.93L452.82 328.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407 359.65L437.72 328.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.8 359.65L411.52 328.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.59 359.65L385.31 328.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_06_20;

    