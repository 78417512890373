
    const Svg2_01_06 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="336.16 268.94 152.78999999999996 152.79000000000002" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M341.16 416.73L483.95 416.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M483.95 416.73L483.95 273.94Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M483.95 273.94L341.16 273.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.16 273.94L341.16 416.73Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.62 309.03L421.62 363.43Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.62 363.43L418.22 373.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.22 373.63L414.82 377.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.82 377.03L408.02 380.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.02 380.43L401.22 380.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.22 380.43L394.42 377.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.42 377.03L391.02 373.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.02 373.63L387.62 363.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.62 363.43L387.62 356.63Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_01_06;

    