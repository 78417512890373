import { UserPermissions } from '../../components/restricted/interfaces';
import { fromRestrictedRouteObjects } from '../../components/restricted/routing';
import { lazy } from 'react';

const DriftDrawings = lazy(() => import('./active-components/drift-drawings'));
const DriftOpgaverScreen = lazy(() => import('./drift-opgaver'));
const DriftUdførScreen = lazy(() => import('./drift-rul-screen'));
const SkemaScreen = lazy(() => import('./skema-screen'));

const Http404 = lazy(() => import('../http/404'));
const DriftGrid = lazy(() => import('./active-components/drift-grids'));
const DriftStatus = lazy(() => import('./drift-status-components'));
const DriftNavigationButtons = lazy(() => import('./active-components/drift-navigation-buttons'));

export const DriftRouterObjects = fromRestrictedRouteObjects([
    {
        element: <DriftOpgaverScreen />,
        children: fromRestrictedRouteObjects([
            { index: true, element: <DriftNavigationButtons /> },
            { path: 'Aktiviteter', element: <DriftGrid /> },
            { path: 'Aktiviteter/:period', element: <DriftGrid /> },
            { path: 'Status', element: <DriftStatus /> },
            { path: 'Tegninger', element: <DriftDrawings />, permissions: UserPermissions.Drift_Tegninger },
        ]),
    },
    {
        path: 'Rul/:aktId',
        element: <DriftUdførScreen />,
        permissions: UserPermissions.Drift_Rul,
    },
    {
        path: 'Skema/:skemaId',
        element: <SkemaScreen />,
        permissions: UserPermissions.Drift_Rul,
    },
    { path: '*', element: <Http404 /> },
]);
