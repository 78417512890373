
    const Svg1_01_26 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="244.85 241.23 292.9599999999999 130.53" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M310.12 318.55L310.12 366.76Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.12 366.76L430.65 366.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M430.65 366.76L430.65 246.23Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M430.65 246.23L310.12 246.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.12 246.23L310.12 318.55Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.97 276.36L346.97 336.62Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.97 336.62L381.41 336.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.12 276.36L249.85 276.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M279.99 261.29L310.12 276.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.12 276.36L279.99 291.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.12 336.62L249.85 336.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M249.85 336.62L279.99 321.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M249.85 336.62L279.99 351.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.75 366.76L486.89 318.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.75 318.55L486.89 366.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M500.67 366.76L532.81 318.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M500.67 318.55L532.81 366.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_26;

    