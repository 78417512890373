
    const Svg2_03_04 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="315.87 240.64 282.83000000000004 100.94999999999999" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M557.32 336.59L393.62 318.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M593.7 245.64L557.32 336.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.62 282.02L593.7 245.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.87 263.83L320.87 336.59Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.87 336.59L393.62 336.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.62 336.59L393.62 263.83Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.62 263.83L320.87 263.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_03_04;

    