const SvgSquatMarkerRed = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width={200} height={200} viewBox='0 0 25 39'>
        <path
            d='M12.5 1013.9c-6.617 0-11.98 5.355-11.98 11.962 0 4.736 2.773 8.82 6.771 10.759l5.208 14.238c1.937-4.729 3.687-9.524 5.404-14.335 3.891-1.976 6.576-6.004 6.576-10.662 0-6.607-5.363-11.962-11.98-11.962z'
            style={{
                stroke: '#000',
                fill: '{{replacement-value-color}}',
            }}
            transform='translate(0 -1013.4)'
        />
    </svg>
);
export default SvgSquatMarkerRed;
