
    const Svg1_01_34 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="329.33 217.65 170.19 155.61999999999998" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M421.71 295.46L465.39 295.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.71 310.03L421.71 280.9 392.58 295.46 421.71 310.03Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M465.39 310.03L494.52 295.46 465.39 280.9 465.39 310.03Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M392.58 295.46L421.71 280.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.71 280.9L421.71 310.03Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.71 310.03L392.58 295.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M494.52 295.46L465.39 280.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M465.39 280.9L465.39 310.03Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M465.39 310.03L494.52 295.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.74 331.87L443.55 368.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.55 368.27L443.55 222.65Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M334.33 331.87L352.54 331.87 334.33 259.06 334.33 331.87Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M352.54 331.87L352.54 259.06 334.33 259.06 352.54 331.87Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M352.54 331.87L370.74 331.87 352.54 259.06 352.54 331.87Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M370.74 331.87L370.74 259.06 352.54 259.06 370.74 331.87Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M370.74 259.06L334.33 259.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M334.33 331.87L370.74 331.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M334.33 259.06L334.33 331.87Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.74 259.06L443.55 222.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.74 331.87L370.74 259.06Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_34;

    