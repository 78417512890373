
    const Svg2_04_01 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="321.58 254.35000000000002 191.83000000000004 100.90999999999997" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M326.58 259.35L326.58 350.26Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M326.58 350.26L508.41 350.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M508.41 350.26L508.41 259.35Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M508.41 259.35L326.58 259.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.01 327.53L371.33 282.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.33 282.08L388.64 327.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.5 312.38L382.15 312.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.93 288.57L427.6 284.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.6 284.24L421.11 282.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.11 282.08L412.45 282.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.45 282.08L405.96 284.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.96 284.24L401.63 288.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.63 288.57L401.63 292.9Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.63 292.9L403.79 297.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.79 297.23L405.96 299.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.96 299.39L410.29 301.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.29 301.56L423.28 305.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.28 305.89L427.6 308.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.6 308.05L429.77 310.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.77 310.22L431.93 314.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.93 314.54L431.93 321.04Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.93 321.04L427.6 325.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.6 325.37L421.11 327.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.11 327.53L412.45 327.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.45 327.53L405.96 325.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.96 325.37L401.63 321.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.92 327.53L444.92 282.08Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.92 282.08L464.4 282.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.4 282.08L470.9 284.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.9 284.24L473.06 286.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M473.06 286.4L475.23 290.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M475.23 290.73L475.23 297.23Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M475.23 297.23L473.06 301.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M473.06 301.56L470.9 303.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.9 303.72L464.4 305.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.4 305.89L444.92 305.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_04_01;

    