
    const Svg2_05_05 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="305.96 219.27 209.42000000000002 109.72" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M310.96 224.27L310.96 323.99Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.96 323.99L510.38 323.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M510.38 323.99L510.38 224.27Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M510.38 224.27L310.96 224.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M385.74 274.13L385.54 277.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M385.54 277.25L384.96 280.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.96 280.33L383.99 283.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.99 283.31L382.66 286.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.66 286.14L380.98 288.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.98 288.78L378.98 291.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.98 291.19L376.7 293.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.7 293.34L374.17 295.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.17 295.18L371.43 296.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.43 296.69L368.52 297.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.52 297.84L365.48 298.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.48 298.62L362.38 299.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.38 299.01L359.25 299.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.25 299.01L356.14 298.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.14 298.62L353.11 297.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.11 297.84L350.2 296.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.2 296.69L347.46 295.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M347.46 295.18L344.92 293.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M344.92 293.34L342.64 291.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M342.64 291.19L340.65 288.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.65 288.78L338.97 286.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M338.97 286.14L337.64 283.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M337.64 283.31L336.67 280.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.67 280.33L336.08 277.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.08 277.25L335.89 274.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M335.89 274.13L336.08 271.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.08 271.01L336.67 267.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.67 267.93L337.64 264.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M337.64 264.95L338.97 262.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M338.97 262.12L340.65 259.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.65 259.48L342.64 257.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M342.64 257.07L344.92 254.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M344.92 254.92L347.46 253.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M347.46 253.08L350.2 251.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.2 251.57L353.11 250.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.11 250.42L356.14 249.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.14 249.64L359.25 249.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.25 249.25L362.38 249.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.38 249.25L365.48 249.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.48 249.64L368.52 250.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.52 250.42L371.43 251.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.43 251.57L374.17 253.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.17 253.08L376.7 254.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.7 254.92L378.98 257.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.98 257.07L380.98 259.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.98 259.48L382.66 262.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.66 262.12L383.99 264.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.99 264.95L384.96 267.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.96 267.93L385.54 271.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M385.54 271.01L385.74 274.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.19 291.76L378.44 256.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.19 256.5L378.44 291.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M448.48 294.28L475.07 254.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M448.48 254.4L475.07 294.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_05_05;

    