import BurgerMenuList, { BurgerMenuListItem } from './burger-menu-list';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import Breadcrumbs from '../breadcrumbs/breadcrumbs';
import CachedIcon from '@mui/icons-material/Cached';
import { CenterLeftBox } from '../../mui/styled-mui';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer/Drawer';
import { FlexColumn } from '../../misc/flex';
import GetAppIcon from '@mui/icons-material/GetApp';
import HomeIcon from '@mui/icons-material/Home';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List/List';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import Restricted from '../../restricted/restricted';
import SettingsIcon from '@mui/icons-material/Settings';
import Toolbar from '@mui/material/Toolbar';
import { UserPermissions } from '../../restricted/interfaces';
import { defferedPrompt, forceReload } from '../../../serviceWorkerRegistration';
import { styled } from '@mui/material/styles';
import { useAuth } from '../../../shared/hooks/redux-use-user';
import useBreakpoints from '../../../shared/hooks/use-breakpoints';
import useDoubleState from '../../../shared/hooks/use-double-state';
import useHistoryNavigate from '../../../shared/hooks/extended-hooks/use-history-navigate';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
}));

interface IDraverProps {
    children: React.ReactNode | React.ReactNode[];
}

export default function BurgerMenuMobile({ children }: IDraverProps) {
    const [open, setOpen] = useDoubleState(false);
    const { navigate } = useHistoryNavigate();
    const { signOut } = useAuth();
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const { md } = useBreakpoints();

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div className='flex-column-container'>
            <div className='flex-item-no-fill'>
                <CssBaseline />
                <AppBar position='sticky' open={open}>
                    <Toolbar>
                        <IconButton
                            color='inherit'
                            aria-label='open drawer'
                            onClick={handleDrawerOpen}
                            edge='start'
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <CenterLeftBox>{!open && <Breadcrumbs />}</CenterLeftBox>
                        <IconButton onClick={() => navigate('/')} sx={{ ...(!md && { display: 'none' }) }}>
                            <img src='/images/ct-logo-text-scale.svg' alt='Caretaker Logo' width='150px' />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </div>
            <div className='flex-item-fill'>
                <Drawer
                    sx={{
                        'width': drawerWidth,
                        'flexShrink': 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                        'zIndex': 1600,
                    }}
                    variant='temporary'
                    anchor='left'
                    open={open}
                    onClose={() => setOpen(false)}
                // onOpen={() => setOpen(true)}
                >
                    <FlexColumn
                        header={
                            <>
                                <DrawerHeader sx={{ p: '0px' }}>
                                    <List disablePadding sx={{ width: '100%' }}>
                                        <BurgerMenuListItem
                                            tekst='Hjem'
                                            path='/'
                                            close={handleDrawerClose}
                                            icon={<HomeIcon />}
                                        />
                                    </List>
                                </DrawerHeader>
                                <Divider />
                            </>
                        }
                        footer={
                            <List>
                                <Restricted permissions={UserPermissions.Settings}>
                                    <BurgerMenuListItem
                                        tekst='Indstillinger'
                                        path='/Settings'
                                        close={handleDrawerClose}
                                        icon={<SettingsIcon />}
                                    />
                                </Restricted>
                                <BurgerMenuListItem
                                    onClick={() => {
                                        forceReload();
                                    }}
                                    tekst='Genstart App'
                                    icon={<CachedIcon />}
                                />
                                {defferedPrompt != null && (
                                    <BurgerMenuListItem
                                        onClick={() => defferedPrompt?.prompt()}
                                        tekst='Download app'
                                        icon={<GetAppIcon />}
                                    />
                                )}
                                <Divider />
                                <BurgerMenuListItem
                                    onClick={signOut}
                                    tekst='Log ud'
                                    close={handleDrawerClose}
                                    icon={<LogoutIcon />}
                                />
                            </List>
                        }
                    >
                        <BurgerMenuList close={handleDrawerClose} />
                    </FlexColumn>
                </Drawer>
                {children}
            </div>
        </div>
    );
}
