
    const SvgXx_06_03 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="280.84 245.56 258.62000000000006 69.62" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M370.23 310.18L452.35 310.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M285.84 275.37L534.46 275.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.01 275.37L434.81 278.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.81 278.49L434.23 281.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.23 281.55L433.26 284.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.26 284.52L431.93 287.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.93 287.35L430.26 289.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M430.26 289.98L428.27 292.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.27 292.39L426 294.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426 294.53L423.47 296.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.47 296.36L420.73 297.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.73 297.87L417.83 299.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.83 299.01L414.81 299.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.81 299.79L411.71 300.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.71 300.18L408.59 300.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.59 300.18L405.49 299.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.49 299.79L402.46 299.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.46 299.01L399.56 297.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.56 297.87L396.83 296.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.83 296.36L394.3 294.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.3 294.53L392.02 292.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.02 292.39L390.03 289.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.03 289.98L388.36 287.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.36 287.35L387.03 284.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.03 284.52L386.07 281.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.07 281.55L385.48 278.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M385.48 278.49L385.29 275.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M385.29 275.37L385.48 272.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M385.48 272.25L386.07 269.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.07 269.19L387.03 266.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.03 266.22L388.36 263.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.36 263.39L390.03 260.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.03 260.76L392.02 258.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.02 258.35L394.3 256.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.3 256.21L396.83 254.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.83 254.38L399.56 252.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.56 252.87L402.46 251.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.46 251.72L405.49 250.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.49 250.95L408.59 250.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.59 250.56L411.71 250.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.71 250.56L414.81 250.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.81 250.95L417.83 251.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.83 251.72L420.73 252.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.73 252.87L423.47 254.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.47 254.38L426 256.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426 256.21L428.27 258.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.27 258.35L430.26 260.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M430.26 260.76L431.93 263.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.93 263.39L433.26 266.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.26 266.22L434.23 269.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.23 269.19L434.81 272.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.81 272.25L435.01 275.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_06_03;

    