
    const Svg3_01_01 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="300.11 243.51 204.95999999999998 96.65000000000003" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M500.07 281L467.58 281" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M500.07 302.67L500.07 281Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.58 302.67L500.07 302.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M305.11 335.16L467.58 335.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.58 335.16L467.58 248.51Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.58 248.51L305.11 248.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M305.11 248.51L305.11 335.16Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg3_01_01;

    