
    const Svg4_05_12 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="336.08 210.0 164.53000000000003 178.60000000000002" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M431.41 215L368.27 215" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.27 299.3L368.27 215Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.27 215L431.41 215" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.41 215L431.41 299.3Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.59 299.3L341.08 299.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.27 299.3L368.27 309.65Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.27 322.37L368.27 335.09Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.27 347.81L368.27 360.53Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.27 373.25L368.27 383.6Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.27 383.6L380.76 383.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.48 383.6L406.2 383.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.92 383.6L431.41 383.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.41 383.6L431.41 373.25Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.41 360.53L431.41 347.81Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.41 335.09L431.41 322.37Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.41 309.65L431.41 299.3Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.13 357.35L465.33 325.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.13 325.55L465.33 357.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M474.41 357.35L495.61 325.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M474.41 325.55L495.61 357.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg4_05_12;

    