/**
 * Class intended to help show / hide controls with an animation that looks like mui's grow component
 */
class AnimationHelper {
    public static readonly time = 500;
    public readonly time = AnimationHelper.time;
    private isShowing = false;

    private clear(classList: DOMTokenList) {
        classList.remove('ol-replicate-mui-grow-shown');
        classList.remove('ol-replicate-mui-grow-showing');
        classList.remove('ol-replicate-mui-grow-hiding');
        classList.remove('ol-replicate-mui-grow-hidden');
    }

    public show(element: HTMLElement) {
        this.isShowing = true;
        const classList = element.classList;
        this.clear(classList);
        classList.add('ol-replicate-mui-grow-showing');
        setTimeout(() => {
            if (this.isShowing) {
                this.clear(classList);
                classList.add('ol-replicate-mui-grow-shown');
            }
        }, this.time);
    }

    public hide(element: HTMLElement) {
        this.isShowing = false;
        const classList = element.classList;
        this.clear(classList);
        classList.add('ol-replicate-mui-grow-hiding');
        setTimeout(() => {
            if (!this.isShowing) {
                this.clear(classList);
                classList.add('ol-replicate-mui-grow-hidden');
            }
        }, this.time);
    }
}

export default AnimationHelper;
