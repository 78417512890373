
    const SvgXx_06_12 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="239.63 187.04 287.24 287.24" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M521.87 192.04L458.53 213.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.53 213.16L500.76 255.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M500.76 255.38L521.87 192.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M521.87 192.04L521.87 192.04 500.76 255.38 458.53 213.16 521.87 192.04Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M521.87 192.04L268.52 445.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M244.63 445.39L245 449.54 268.52 445.39 268.52 445.39 244.63 445.39Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M245 449.54L246.08 453.56 268.52 445.39 268.52 445.39 245 449.54Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M246.08 453.56L247.83 457.33 268.52 445.39 268.52 445.39 246.08 453.56Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M247.83 457.33L250.22 460.74 268.52 445.39 268.52 445.39 247.83 457.33Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M250.22 460.74L253.17 463.69 268.52 445.39 268.52 445.39 250.22 460.74Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M253.17 463.69L256.58 466.08 268.52 445.39 268.52 445.39 253.17 463.69Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M256.58 466.08L260.35 467.84 268.52 445.39 268.52 445.39 256.58 466.08Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M260.35 467.84L264.37 468.91 268.52 445.39 268.52 445.39 260.35 467.84Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M264.37 468.91L268.52 469.28 268.52 445.39 268.52 445.39 264.37 468.91Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M268.52 469.28L272.67 468.91 268.52 445.39 268.52 445.39 268.52 469.28Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M272.67 468.91L276.69 467.84 268.52 445.39 268.52 445.39 272.67 468.91Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M276.69 467.84L280.46 466.08 268.52 445.39 268.52 445.39 276.69 467.84Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M280.46 466.08L283.87 463.69 268.52 445.39 268.52 445.39 280.46 466.08Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M283.87 463.69L286.82 460.74 268.52 445.39 268.52 445.39 283.87 463.69Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M286.82 460.74L289.21 457.33 268.52 445.39 268.52 445.39 286.82 460.74Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M289.21 457.33L290.97 453.56 268.52 445.39 268.52 445.39 289.21 457.33Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M290.97 453.56L292.04 449.54 268.52 445.39 268.52 445.39 290.97 453.56Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M292.04 449.54L292.41 445.39 268.52 445.39 268.52 445.39 292.04 449.54Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M292.41 445.39L292.04 441.24 268.52 445.39 268.52 445.39 292.41 445.39Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M292.04 441.24L290.97 437.22 268.52 445.39 268.52 445.39 292.04 441.24Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M290.97 437.22L289.21 433.45 268.52 445.39 268.52 445.39 290.97 437.22Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M289.21 433.45L286.82 430.04 268.52 445.39 268.52 445.39 289.21 433.45Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M286.82 430.04L283.87 427.09 268.52 445.39 268.52 445.39 286.82 430.04Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M283.87 427.09L280.46 424.71 268.52 445.39 268.52 445.39 283.87 427.09Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M280.46 424.71L276.69 422.95 268.52 445.39 268.52 445.39 280.46 424.71Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M276.69 422.95L272.67 421.87 268.52 445.39 268.52 445.39 276.69 422.95Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M272.67 421.87L268.52 421.51 268.52 445.39 268.52 445.39 272.67 421.87Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M268.52 421.51L264.37 421.87 268.52 445.39 268.52 445.39 268.52 421.51Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M264.37 421.87L260.35 422.95 268.52 445.39 268.52 445.39 264.37 421.87Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M260.35 422.95L256.58 424.71 268.52 445.39 268.52 445.39 260.35 422.95Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M256.58 424.71L253.17 427.09 268.52 445.39 268.52 445.39 256.58 424.71Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M253.17 427.09L250.22 430.04 268.52 445.39 268.52 445.39 253.17 427.09Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M250.22 430.04L247.83 433.45 268.52 445.39 268.52 445.39 250.22 430.04Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M247.83 433.45L246.08 437.22 268.52 445.39 268.52 445.39 247.83 433.45Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M246.08 437.22L245 441.24 268.52 445.39 268.52 445.39 246.08 437.22Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M245 441.24L244.63 445.39 268.52 445.39 268.52 445.39 245 441.24Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
</svg>
    );
    export default SvgXx_06_12;

    