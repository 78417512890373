//#region Interfaces

import 'devextreme-react/select-box';
import 'devextreme-react/text-area';

import { GroupItem, Item } from 'devextreme-react/form';
import { IItem, ItemTypes } from './interfaces-and-defaults';
import TextField, { TextFieldProps } from '@mui/material/TextField/TextField';
import { Fragment, useMemo, useState } from 'react';
import Validator, { StringLengthRule } from 'devextreme-react/validator';

import Api from '../../../shared/networking/api';
import GetType from '../../data-getters/type-map';
import HtmlEditor, { ImageUpload, Item as ToolbarItem, MediaResizing, Toolbar } from 'devextreme-react/html-editor';
import { IDictionary } from '../../../shared/utils/types';
import RadioGroup from 'devextreme-react/radio-group';
import SelectBox from 'devextreme-react/select-box';
import Typography from '@mui/material/Typography/Typography';
import __ from '../../../shared/utils/lodash-expansions';
import config from '../../../config';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { isMobile } from 'react-device-detect';
import useCentralizedSnackbar from '../../../shared/hooks/redux-use-centralized-snackbar';
import UtilsString from '../../../shared/utils/utils-string';

/** Styled TextField. The styled function wouldn't compile it with npm run build */
const AltStyledTextField = (props: TextFieldProps) => {
    const dProps: TextFieldProps = {
        fullWidth: true,
        sx: {
            '& div.MuiOutlinedInput-root.MuiInputBase-root': {
                'color': 'rgba(0, 0, 0, 0.87)',

                '& .MuiOutlinedInput-input.MuiInputBase-input': {
                    WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)',
                },
            },
            '& fieldset legend': props.required
                ? {}
                : {
                    width: '0px',
                },
        },
    };

    return <TextField {...__.deepMerge(dProps, props)} />;
};
export const FormTextField = (props: TextFieldProps) => <AltStyledTextField variant='outlined' fullWidth {...props} />;

//#region Custom items

export interface IComboConditionValue {
    choice: string | number;
    text: string;
}

const Custom_Radio = ({ item, autoSubmit, service }: { item: IItem; autoSubmit?: boolean; service?: boolean }) => {
    const { enqueueSnackbar } = useCentralizedSnackbar();
    let valueChanged = false;

    const onValueChanged = async (e: any) => {
        if (e.name === 'isActive' && e.value === false) {
            if (valueChanged) {
                valueChanged = false;
            } else {
                e.component.resetOption('value');
            }
        }
        if (e.name === 'value' && e.value !== e.previousValue) {
            valueChanged = true;
            if (autoSubmit) {
                const formData = new FormData();
                if (service) {
                    formData.append('column', item.id ?? '');
                    formData.append('value', e.value);
                    formData.append('typedId', item.props?.radioProps?.putId ?? '');
                    formData.append('type', GetType({ column: item.id ?? '' }) ?? 'error');
                    formData.append('prevValue', '');
                } else {
                    formData.append(item.id ?? '', e.value);
                }
                const response = await Api.put(config.SERVER_URL + '/' + item.props?.radioProps?.url ?? '', formData);
                if (!Api.ok(response)) {
                    enqueueSnackbar('Data blev ikke gemt', { variant: 'error' });
                }
            }
        }
    };

    return (
        <Item {...item.defaultProps} label={{ ...item.defaultProps?.label, visible: true }}>
            <RadioGroup
                items={item.choices ?? []}
                valueExpr={'id'}
                displayExpr={'value'}
                defaultValue={item.value}
                layout='horizontal'
                name={item.id}
                onOptionChanged={onValueChanged}
            // itemRender={itemRender}
            ></RadioGroup>
        </Item>
    );
};

// eslint-disable-next-line react/jsx-pascal-case
const GetCustom_Lookup = ({
    item,
    d,
    props,
}: {
    item: IItem;
    d: any;
    props?: { colCount?: number; autoSubmit?: boolean; service?: boolean };
}) => <CustomLookup item={item} d={d} props={props} />;
const CustomLookup = ({
    item,
    d,
    props,
}: {
    item: IItem;
    d: any;
    props?: { colCount?: number; autoSubmit?: boolean; service?: boolean };
}) => {
    const { enqueueSnackbar } = useCentralizedSnackbar();
    const [value, setValue] = useState(d.editorOptions.value);
    const [open, setOpen] = useState(false);

    const onValueChanged = async (e: any) => {
        setValue(e.value);
        if (props?.autoSubmit !== true) return;
        const formData = new FormData();
        if (props?.service) {
            formData.append('column', item.id ?? '');
            formData.append('value', e.value);
            formData.append('typedId', item.props?.lookupProps?.putId ?? '');
            formData.append('type', GetType({ column: item.id ?? '' }) ?? 'error');
            formData.append('prevValue', '');
        } else {
            formData.append(item.id ?? '', e.value);
        }
        const response = await Api.put(Api.createUrl(item.props?.lookupProps?.putUrl ?? ''), formData);
        if (!Api.ok(response)) {
            enqueueSnackbar('Data blev ikke gemt', { variant: 'error' });
        }
    };

    const dataSource = useMemo(
        () =>
            createStore({
                key: 'id',
                loadUrl: Api.createUrl(item.props?.lookupProps?.url ?? ''),
                loadMode: 'raw',
                onBeforeSend: (_operation, ajax) => {
                    const headers: IDictionary<string> = {
                        Authorization: `Bearer ${Api.token}`,
                    };
                    ajax.headers = headers;
                },
                cacheRawData: true,
            }),
        [item.props?.lookupProps?.url]
    );

    const customValueProps = item.props?.lookupProps?.acceptCustomValue
        ? {
            allowCustomValue: true,
            acceptCustomValue: true,
            onCustomItemCreating: (e: any) => {
                e.customItem = { id: e.text, value: e.text };
            }
        }
        : {};

    return (
        <SelectBox
            {...d.editorOptions}
            dataSource={item.props?.lookupProps?.dataArray ?? item.choices ?? dataSource}
            showClearButton={open && (!item.props?.validationRules?.required ?? false)}
            {...customValueProps}
            showDropDownButton={false}
            placeholder={'Vælg'}
            displayExpr='value'
            valueExpr='id'
            value={value}
            deferRendering={false}
            searchEnabled={true}
            defaultValue={item.value}
            onOpened={() => setOpen(true)}
            onClosed={() => setOpen(false)}
            onValueChanged={onValueChanged}
            name={item.id}
            readOnly={item.readonly}
        ></SelectBox>
    );
};

const HtmlBlockItem = ({ item }: { item: IItem }) => {
    const value = item.value as string ?? '';

    const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
    const fontValues = [
        'Arial',
        'Courier New',
        'Georgia',
        'Impact',
        'Lucida Console',
        'Tahoma',
        'Times New Roman',
        'Verdana',
    ];
    const headerValues = [false, 1, 2, 3, 4, 5];
    const fontSizeOptions = {
        inputAttr: {
            'aria-label': 'Font size',
        },
    };
    const fontFamilyOptions = {
        inputAttr: {
            'aria-label': 'Font family',
        },
    };
    const headerOptions = {
        inputAttr: {
            'aria-label': 'Font family',
        },
    };

    const maxLength = 1000;

    return (
        <HtmlEditor
            // height='300px'
            style={{
                minHeight: '300px',
            }}
            name={item.id}
            defaultValue={value}
            validationMessageMode='always'
        >
            <MediaResizing enabled={true} />
            <ImageUpload tabs={['file', 'url']} fileUploadMode='base64' />
            <Validator>
                <StringLengthRule max={maxLength} message={`Noten er for lang. Billeder og formatering optager ekstra karakterer.\nMaximum antal karakterer er ${maxLength}`} />
            </Validator>
            <Toolbar multiline>
                <ToolbarItem key="undo" name="undo" />
                <ToolbarItem key="redo" name="redo" />
                {/* <ToolbarItem key="separator1" name="separator" /> */}
                {/* <ToolbarItem key="size" name="size" acceptedValues={sizeValues} options={fontSizeOptions} /> */}
                {/* <ToolbarItem key="font" name="font" acceptedValues={fontValues} options={fontFamilyOptions} /> */}
                <ToolbarItem key="separator2" name="separator" />
                <ToolbarItem key="bold" name="bold" />
                <ToolbarItem key="italic" name="italic" />
                <ToolbarItem key="strike" name="strike" />
                <ToolbarItem key="underline" name="underline" />
                <ToolbarItem key="separator3" name="separator" />
                <ToolbarItem key="alignLeft" name="alignLeft" />
                <ToolbarItem key="alignCenter" name="alignCenter" />
                <ToolbarItem key="alignRight" name="alignRight" />
                <ToolbarItem key="alignJustify" name="alignJustify" />
                {/* <ToolbarItem key="separator4" name="separator" /> */}
                {/* <ToolbarItem key="orderedList" name="orderedList" /> */}
                {/* <ToolbarItem key="bulletList" name="bulletList" /> */}
                {/* <ToolbarItem key="separator5" name="separator" /> */}
                {/* <ToolbarItem key="header" name="header" acceptedValues={headerValues} options={headerOptions} /> */}
                {/* <ToolbarItem key="separator6" name="separator" /> */}
                {/* <ToolbarItem key="color" name="color" /> */}
                {/* <ToolbarItem key="background" name="background" /> */}
                {/* <ToolbarItem key="separator7" name="separator" /> */}
                {/* <ToolbarItem key="link" name="link" /> */}
                {/* <ToolbarItem key="image" name="image" /> */}
                {/* <ToolbarItem key="separator8" name="separator" /> */}
                {/* <ToolbarItem key="clear" name="clear" /> */}
                {/* <ToolbarItem key="codeBlock" name="codeBlock" /> */}
                {/* <ToolbarItem key="blockquote" name="blockquote" /> */}
                {/* <ToolbarItem key="separator9" name="separator" /> */}
                {/* <ToolbarItem key="insertTable" name="insertTable" /> */}
                {/* <ToolbarItem key="deleteTable" name="deleteTable" /> */}
                {/* <ToolbarItem key="insertRowAbove" name="insertRowAbove" /> */}
                {/* <ToolbarItem key="insertRowBelow" name="insertRowBelow" /> */}
                {/* <ToolbarItem key="deleteRow" name="deleteRow" /> */}
                {/* <ToolbarItem key="insertColumnLeft" name="insertColumnLeft" /> */}
                {/* <ToolbarItem key="insertColumnRight" name="insertColumnRight" /> */}
                {/* <ToolbarItem key="deleteColumn" name="deleteColumn" /> */}
            </Toolbar>
        </HtmlEditor>
    )
}
//#endregion Custom items

const GenerateDxFormItem = ({
    item,
    props,
}: {
    item: IItem;
    props: { colCount?: number; autoSubmit?: boolean; service?: boolean };
}) => {
    // Create a validationRule for length, if simpleMax is specified
    if ((item.props?.simpleMax ?? 0) > 0) {
        if (!item.props?.validationRules) item.props!.validationRules = [];
        item.props!.validationRules = [
            ...item.props!.validationRules,
            {
                type: 'stringLength',
                max: item.props?.simpleMax,
                message: `Højst ${item.props?.simpleMax} tegn`,
            },
        ];
        item.props!.editorOptions = { ...item.props?.editorOptions, maxLength: item.props?.simpleMax };
    }

    item.defaultProps = {
        key: item.id,
        dataField: item.id,
        label: { text: item.description },
        ...item.props,
        colSpan: item.props?.colSpan === 'max' ? props.colCount : item.props?.colSpan ?? 12,
        editorOptions: { value: item.value, ...item.props?.editorOptions },
    };
    // item.defaultProps = item.defaultProps;
    switch (item.type) {
        //#region Base items
        default:
        case ItemTypes.String:
            return (
                // <Item key={item.id} colSpan={12} editorType={'dxTextBox'} editorOptions={{value: 'test'}}/>
                <Item {...item.defaultProps} editorOptions={{ ...item.defaultProps?.editorOptions, readOnly: item.readonly }} editorType='dxTextBox' />
            );
        case ItemTypes.Block:
            return (
                <Item
                    {...item.defaultProps}
                    editorType='dxTextArea'
                    editorOptions={{ ...item.defaultProps?.editorOptions, autoResizeEnabled: true, readOnly: item.readonly }}
                />
            );
        case ItemTypes.HtmlBlock:
            return (
                <HtmlBlockItem key='html-block-item' item={item} />
            );
        case ItemTypes.Number:
        case ItemTypes.Decimal:
            const onInput = (e: any, before: number, seperate: boolean) => {
                const val = e.event.currentTarget.value;
                let sel = e.event.originalEvent.target.selectionEnd;
                let result = val.split(',')[0];

                // Max length
                if (result.replaceAll('.', '').length <= before) return;
                if (result[sel - 1] === '.') sel--;
                result = result.slice(0, sel - 1) + result.slice(sel);
                result = result.replaceAll('.', '');
                const seperators = result.length / 3;
                const length = result.length;

                // 1000-seperator
                if (seperate) {
                    for (let i = 1; i < seperators; i++) {
                        result = result.slice(0, length - 3 * i) + '.' + result.slice(length - 3 * i);
                    }
                }

                if (val.split(',')[1]) result += `,${val.split(',')[1]}`;

                e.event.currentTarget.value = result;
                e.event.originalEvent.target.selectionEnd = sel - 1;
            };

            if ((item.props?.numberProps?.maxLength ?? 0) > 0) {
                item.props!.editorOptions = {
                    ...item.defaultProps.editorOptions,
                    max: item.props?.numberProps?.max ?? Math.pow(10, item.props?.numberProps?.maxLength ?? 0) - 1,
                    onInput: (e: any) =>
                        onInput(e, item.props?.numberProps?.maxLength ?? 0, item.props?.numberProps?.seperate ?? false),
                };
            }

            const format = item.type === ItemTypes.Decimal ? '#.##' : '#.';
            return (
                <Item
                    {...item.defaultProps}
                    editorType='dxNumberBox'
                    editorOptions={{
                        format: format,
                        step: 0,
                        ...item.defaultProps?.editorOptions,
                        value: item.value != null
                            ? item.type === 'decimal'
                                ? parseFloat(String(item.value))
                                : parseInt(String(item.value))
                            : undefined,
                        readOnly: item.readonly,
                    }}
                />
            );
        case ItemTypes.Lookup:
            return (
                <Item {...item.defaultProps} render={(d: any) => GetCustom_Lookup({ item, d, props: props })}></Item>
            );
        case ItemTypes.Choice:
            if (item.serviceLayout) {
                return Custom_Radio({ item, autoSubmit: props.autoSubmit, service: props.service });
            }
            return (
                <Item {...item.defaultProps} render={(d: any) => GetCustom_Lookup({ item, d, props: props })}></Item>
            )
        case ItemTypes.NoInput:
            if (UtilsString.IsNullOrWhitespace(item.value?.toString())) {
                console.log("NoInput fragment")
                return <Fragment key={item.id}></Fragment>;
            }

            return (
                <Item {...item.defaultProps} label={{ visible: false }}>
                    <Typography
                        key='text'
                        pt={'19px'}
                        fontSize={17}
                        fontFamily='"Helvetica Neue","Segoe UI",helvetica,verdana,sans-serif'
                    >
                        {String(item.value)}
                    </Typography>
                    <hr style={{ border: '0px', borderTop: '1px solid #e5e5e5' }} />
                </Item>
            );
        case ItemTypes.Date:
            return (
                <Item
                    {...item.defaultProps}
                    editorType='dxDateBox'
                    editorOptions={{
                        displayFormat: 'dd-MM-yyyy',
                        forceIsoDateParsing: true,
                        dateSerializationFormat: 'yyy-MM-dd',
                        pickerType: item.defaultProps?.editorOptions?.readOnly
                            ? 'calendar'
                            : isMobile
                                ? 'native'
                                : 'calendar',
                        acceptCustomValue: true,
                        openOnFieldClick: false,
                        ...item.defaultProps.editorOptions,
                        readOnly: item.readonly,
                    }}
                />
            );
        //#endregion Base items
        //#region Combo items
        case ItemTypes.File:
            return (
                <Item
                    {...item.defaultProps}
                    editorType='dxFileUploader'
                    editorOptions={{
                        multiple: true,
                        labelText: '',
                        uploadMode: 'useForm',
                        ...item.props?.fileProps
                    }}
                />
            )
        // case ItemTypes.Combo_ConditionalBlock:
        //     return <Custom_Combo_ConditionalBlock {...item} />;
        case ItemTypes.Combined:
            if (item.serviceLayout) {
                return (
                    <GroupItem colSpan={12}>
                        {Custom_Radio({ item, autoSubmit: props.autoSubmit })}
                        <Item
                            {...item.defaultProps}
                            label={{ text: item.descriptionText ?? 'Kommentar', location: 'left' }}
                            dataField={`${item.id}-text`}
                            editorType='dxTextBox'
                            editorOptions={{ value: item.valueText }}
                            key={`${item.id}-text`}
                        />
                    </GroupItem>
                );
            }
            return (
                <GroupItem colSpan={12}>
                    <Item
                        {...item.defaultProps}
                        editorType='dxSelectBox'
                        editorOptions={{
                            showClearButton: true,
                            showDropDownButton: false,
                            placeholder: 'Vælg',
                            displayExpr: 'value',
                            valueExpr: 'id',
                            deferRendering: false,
                            searchEnabled: true,
                            dataSource: item.choices,
                            value: item.value,
                        }}
                    />
                    <Item
                        {...item.defaultProps}
                        label={{ text: item.descriptionText ?? 'Beskrivelse' }}
                        dataField={`${item.id}-text`}
                        editorType='dxTextBox'
                        key={`${item.id}-text`}
                    />
                </GroupItem>
            );
        //#endregion Combo items
    }
};

export default GenerateDxFormItem;
