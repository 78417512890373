import { IFormData, IFormDataFile, IGroup, IInput } from './interfaces';

export const isInput = (item: IInput | IGroup): item is IInput => {
    return (item as IGroup).items === undefined;
};

export const isGroup = (item: IInput | IGroup): item is IGroup => {
    return !isInput(item);
};

export const isFormData = (formData: IFormData | IFormDataFile): formData is IFormData => {
    return (formData as IFormDataFile).filename === undefined;
};

export const isFormDataFile = (formDataFile: IFormData | IFormDataFile): formDataFile is IFormDataFile => {
    return !isFormData(formDataFile);
};
