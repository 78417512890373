import { IData } from './graph';
import { RadialBarSerie } from '@nivo/radial-bar';

interface IPreData {
    id: string;
    data: {
        x: string;
        y?: number;
        z: number;
    }[];
}

export const addPercent = <T extends IPreData[]>(dataArr: T): RadialBarSerie<IData>[] => {
    return dataArr.map((data) => {
        const sum = data.data.reduce((p, c) => p + c.z, 0);
        return {
            ...data,
            data: data.data.map((d) => {
                return {
                    ...d,
                    y: sum ? (d.z / sum) * 100 : 0,
                };
            }),
        };
    });
};

export const predata = [
    {
        id: 'Daglige',
        data: [
            {
                x: 'Senere',
                z: 0,
            },
            {
                x: 'I dag',
                z: 0,
            },
            {
                x: 'Tidligere',
                z: 1,
            },
        ],
    },
    {
        id: 'Ugentlige',
        data: [
            {
                x: 'Senere',
                z: 0,
            },
            {
                x: 'I dag',
                z: 0,
            },
            {
                x: 'Tidligere',
                z: 1,
            },
        ],
    },
    {
        id: 'Månedlige',
        data: [
            {
                x: 'Senere',
                z: 0,
            },
            {
                x: 'I dag',
                z: 0,
            },
            {
                x: 'Tidligere',
                z: 1,
            },
        ],
    },
    {
        id: 'Årlige',
        data: [
            {
                x: 'Senere',
                z: 0,
            },
            {
                x: 'I dag',
                z: 0,
            },
            {
                x: 'Tidligere',
                z: 1,
            },
        ],
    },
];

export const data = [
    {
        id: 'Daglige',
        data: [
            {
                x: 'Senere',
                z: 2,
            },
            {
                x: 'I dag',
                z: 1,
            },
            {
                x: 'Tidligere',
                z: 1,
            },
        ],
    },
    {
        id: 'Ugentlige',
        data: [
            {
                x: 'Senere',
                z: 5,
            },
            {
                x: 'I dag',
                z: 1,
            },
            {
                x: 'Tidligere',
                z: 1,
            },
        ],
    },
    {
        id: 'Månedlige',
        data: [
            {
                x: 'Senere',
                z: 10,
            },
            {
                x: 'I dag',
                z: 1,
            },
            {
                x: 'Tidligere',
                z: 0,
            },
        ],
    },
    {
        id: 'Årlige',
        data: [
            {
                x: 'Senere',
                z: 30,
            },
            {
                x: 'I dag',
                z: 0,
            },
            {
                x: 'Tidligere',
                z: 1,
            },
        ],
    },
    {
        id: 'Årlige2',
        data: [
            {
                x: 'Senere',
                z: 30,
            },
            {
                x: 'I dag',
                z: 0,
            },
            {
                x: 'Tidligere',
                z: 1,
            },
        ],
    },
    {
        id: 'Årlige3',
        data: [
            {
                x: 'Senere',
                z: 30,
            },
            {
                x: 'I dag',
                z: 0,
            },
            {
                x: 'Tidligere',
                z: 1,
            },
        ],
    },
    {
        id: 'Årlige4',
        data: [
            {
                x: 'Senere',
                z: 30,
            },
            {
                x: 'I dag',
                z: 0,
            },
            {
                x: 'Tidligere',
                z: 1,
            },
        ],
    },
];
