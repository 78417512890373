
    const Svg2_05_06 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="299.5 259.31 192.01999999999998 101.00999999999999" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M304.5 264.31L304.5 355.32Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M304.5 355.32L486.52 355.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M486.52 355.32L486.52 264.31Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M486.52 264.31L304.5 264.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.76 309.82L372.56 312.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.56 312.79L371.98 315.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.98 315.71L371.03 318.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.03 318.52L369.71 321.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.71 321.19L368.06 323.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.06 323.67L366.09 325.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.09 325.91L363.86 327.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.86 327.87L361.38 329.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.38 329.52L358.71 330.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.71 330.84L355.89 331.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.89 331.8L352.97 332.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.97 332.38L350 332.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350 332.57L347.03 332.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M347.03 332.38L344.12 331.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M344.12 331.8L341.3 330.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.3 330.84L338.63 329.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M338.63 329.52L336.15 327.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.15 327.87L333.92 325.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M333.92 325.91L331.95 323.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M331.95 323.67L330.3 321.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.3 321.19L328.98 318.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.98 318.52L328.03 315.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.03 315.71L327.45 312.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M327.45 312.79L327.25 309.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M327.25 309.82L327.45 306.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M327.45 306.85L328.03 303.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.03 303.93L328.98 301.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.98 301.11L330.3 298.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.3 298.44L331.95 295.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M331.95 295.97L333.92 293.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M333.92 293.73L336.15 291.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.15 291.77L338.63 290.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M338.63 290.11L341.3 288.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.3 288.8L344.12 287.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M344.12 287.84L347.03 287.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M347.03 287.26L350 287.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350 287.06L352.97 287.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.97 287.26L355.89 287.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.89 287.84L358.71 288.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.71 288.8L361.38 290.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.38 290.11L363.86 291.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.86 291.77L366.09 293.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.09 293.73L368.06 295.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.06 295.97L369.71 298.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.71 298.44L371.03 301.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.03 301.11L371.98 303.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.98 303.93L372.56 306.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.56 306.85L372.76 309.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M333.92 325.91L366.09 293.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M333.92 293.73L366.09 325.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.92 328.09L431.92 309.89Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.12 328.09L450.12 309.89Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 420.54 291.54 A 20.478 20.478 0 0 0 461.49 291.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.54 291.54L461.49 291.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_05_06;

    