
    const Svg2_01_12 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="331.08 217.07 267.61000000000007 139.45" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M336.08 222.07L336.08 351.52Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M465.53 351.52L465.53 222.07Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.08 351.52L465.53 351.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M465.53 222.07L336.08 222.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M593.69 286.79L465.53 286.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M593.69 286.79L593.69 286.79 546.12 299.74 546.12 273.85 593.69 286.79Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M375.66 254.43L375.66 319.16Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.66 319.16L412.64 319.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_01_12;

    