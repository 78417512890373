
    const Svg1_01_06 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="277.16 205.67 190.28999999999996 155.60999999999999" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M462.45 286.97L323.78 286.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.79 307.76L323.79 266.17Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M462.44 307.76L462.44 266.17Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 323.79 238.44 A 15.252 15.252 0 0 0 309.93 252.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 323.79 224.55 A 29.117 29.117 0 0 0 296.03 252.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 323.79 210.67 A 42.982 42.982 0 0 0 282.16 252.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.77 321.63L427.57 325.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.57 325.31L426.98 328.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.98 328.96L426.01 332.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.01 332.52L424.67 335.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.67 335.95L422.97 339.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.97 339.23L420.93 342.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.93 342.3L418.57 345.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.57 345.14L415.93 347.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.93 347.72L413.03 349.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.03 349.99L409.9 351.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.9 351.95L406.58 353.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.58 353.56L403.11 354.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.11 354.82L399.53 355.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.53 355.69L395.87 356.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.87 356.18L392.18 356.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.18 356.28L388.5 355.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.5 355.99L384.88 355.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.88 355.3L381.34 354.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.34 354.24L377.95 352.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.95 352.8L374.72 351.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.72 351.01L371.7 348.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.7 348.89L368.92 346.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.92 346.46L366.42 343.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.42 343.75L364.22 340.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.22 340.79L362.35 337.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.35 337.61L360.82 334.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.82 334.25L359.66 330.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.66 330.75L358.88 327.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.88 327.14L358.49 323.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.49 323.47L358.49 319.78Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.49 319.78L358.88 316.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.88 316.12L359.66 312.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.66 312.51L360.82 309.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.82 309.01L362.35 305.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.35 305.65L364.22 302.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.22 302.47L366.42 299.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.42 299.51L368.92 296.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.92 296.8L371.7 294.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.7 294.37L374.72 292.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.72 292.24L377.95 290.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.95 290.46L381.34 289.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.34 289.02L384.88 287.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.88 287.96L388.5 287.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.5 287.27L392.18 286.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.18 286.98L395.87 287.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.87 287.08L399.53 287.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.53 287.57L403.11 288.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.11 288.44L406.58 289.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.58 289.69L409.9 291.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.9 291.31L413.03 293.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.03 293.27L415.93 295.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.93 295.54L418.57 298.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.57 298.12L420.93 300.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.93 300.96L422.97 304.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.97 304.03L424.67 307.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.67 307.31L426.01 310.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.01 310.74L426.98 314.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.98 314.3L427.57 317.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.57 317.95L427.77 321.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_06;

    