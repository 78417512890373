
    const SvgXx_06_02 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="263.44 265.55 237.03000000000003 90.75999999999999" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M268.44 322.72L495.47 322.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.93 294.14L366.35 351.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.91 308.66L431.32 270.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.91 270.55L431.32 308.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_06_02;

    