
    const Svg1_01_11 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="278.75 232.21 269.17999999999995 139.59" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M380.94 237.21L380.94 366.8Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.14 366.8L478.14 237.21Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M445.74 237.21L445.74 366.8Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.34 366.8L413.34 237.21Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M316.15 366.8L316.15 302.01Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.54 366.8L348.54 237.21Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.94 366.8L413.34 366.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M316.15 366.8L348.54 366.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M445.74 366.8L478.14 366.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M316.15 302.01L283.75 302.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.54 237.21L380.94 237.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M510.53 237.21L510.53 302.01Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M510.53 302.01L542.93 302.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.14 237.21L510.53 237.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.34 237.21L445.74 237.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_11;

    