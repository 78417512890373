
    const Svg3_07_04 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="302.04 254.89 140.06 96.71000000000004" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M307.04 346.6L437.1 346.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.1 346.6L437.1 259.89Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.1 259.89L307.04 259.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M307.04 259.89L307.04 346.6Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.92 320.59L320.92 285.91Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.92 285.91L344.04 320.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M344.04 320.59L344.04 285.91Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M357.26 285.91L370.47 320.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.47 320.59L383.68 285.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.29 320.59L390.29 285.91Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.29 285.91L405.15 285.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.15 285.91L410.1 287.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.1 287.56L411.76 289.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.76 289.21L413.41 292.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.41 292.51L413.41 295.82Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.41 295.82L411.76 299.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.76 299.12L410.1 300.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.1 300.77L405.15 302.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.15 302.42L390.29 302.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.85 302.42L413.41 320.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg3_07_04;

    