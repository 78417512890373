import './enhed-list.css';

import { CenterLeftBox, CenterRightBox } from '../../mui/styled-mui';
import { useEffect, useState } from 'react';

import Api from '../../../shared/networking/api';
import Box from '@mui/material/Box/Box';
import Container from '@mui/material/Container/Container';
import GraphMini from '../../data-displaying/graph-mini';
import Grid from '@mui/material/Grid/Grid';
import { ISearchEnhed } from '../enhed-lookup';
import IconButton from '@mui/material/IconButton/IconButton';
import { ItemClickEvent } from 'devextreme/ui/list';
import { List } from 'devextreme-react/list';
import LoadingScreen from '../../../screens/loading';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import Typography from '@mui/material/Typography/Typography';
import { dxTextEditorButton } from 'devextreme/ui/text_box/ui.text_editor.base';
import useCentralizedSnackbar from '../../../shared/hooks/redux-use-centralized-snackbar';
import { useEnhed } from '../../../shared/hooks/use-enhed';
import { useGetEnhedQuery } from '../../../shared/hooks/use-enhed-list';
import yellow from '@mui/material/colors/yellow';

interface ExtendedISearchEnhed extends ISearchEnhed {
    text?: string;
}

export const GetLookupItemRender = (props: { data: ISearchEnhed; showGraph: boolean; refetch: () => void }) => (
    <LookupItemRender {...props} />
);
/**
 *
 * @returns Box med stjerne til favorit og hvis det er en favorit har den over overbliks graf med
 */
const LookupItemRender = ({
    data,
    showGraph,
    refetch,
}: {
    data: ExtendedISearchEnhed;
    showGraph: boolean;
    refetch: () => void;
}) => {
    const [isFav, setIsFav] = useState(data.isfavourite);
    const { enqueueSnackbar } = useCentralizedSnackbar();
    const onClick = async () => {
        setIsFav(!isFav);
        const response = await Api.post(`/Drift/favorit/${data.id}?isFavourite=${!isFav}`);
        refetch();

        if (!Api.ok(response)) {
            setIsFav(isFav);
            enqueueSnackbar('Der skete en fejl', { variant: 'error' });
        }
    };

    if (showGraph && isFav) {
        return (
            <Box width={'calc(100% + 22px)'} display='flex' style={{ marginLeft: '-12px', alignItems: 'center' }}>
                <CenterLeftBox display='inline-block' width={'calc(100% - 50px)'}>
                    <Box display='flex' sx={{ alignItems: 'center' }}>
                        <IconButton
                            key={`${data.id}-favourite`}
                            onClick={onClick}
                            sx={{ width: 40, pt: '1px', pr: '10px' }}
                        >
                            {isFav ? (
                                <StarIcon sx={{ color: yellow[700], fontSize: 32 }} />
                            ) : (
                                <StarBorderIcon sx={{ fontSize: 32 }} />
                            )}
                        </IconButton>
                        <Typography
                            variant='body1'
                            sx={{
                                display: 'inline-block',
                                fontSize: '14px',
                                textAlign: 'start',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                width: '100%',
                            }}
                        >
                            {data.value ?? data.text}
                        </Typography>
                    </Box>
                </CenterLeftBox>
                <CenterRightBox display='inline-flex' height={'50px'} width={'50px'}>
                    <GraphMini url={`/Drift/Status?enhedsys=${data.id}`} />
                </CenterRightBox>
            </Box>
        );
    }

    return (
        <Box width={'calc(100% + 24px)'} display='flex' style={{ marginLeft: '-12px', alignItems: 'center' }}>
            <IconButton key={`${data.id}-favourite`} onClick={onClick} sx={{ width: 40, pt: '1px', pr: '10px', pl: 0 }}>
                {isFav ? (
                    <StarIcon sx={{ color: yellow[700], fontSize: 32 }} />
                ) : (
                    <StarBorderIcon sx={{ fontSize: 32 }} />
                )}
            </IconButton>
            <Typography
                variant='body1'
                sx={{
                    display: 'inline-block',
                    fontSize: '14px',
                    textAlign: 'start',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: '100%',
                }}
            >
                {data.value ?? data.text}
            </Typography>
        </Box>
    );
};

interface IEnhedList {
    data?: ISearchEnhed[];
    teamonly?: boolean;
    onItemClick?(selection: ISearchEnhed): void;
    searchBarButtons?: (string | dxTextEditorButton)[];
    url?: string;
    showGraph?: boolean;
}

export const useEnhedListWidth = () => {
    useEffect(() => {
        const app = document.querySelector('#App') as HTMLDivElement;
        const prevAppWidth = app.style.width;
        const prevBodyPosition = document.body.style.position;

        document.body.style.position = 'fixed';
        app.style.width = '100vw';
        return () => {
            document.body.style.position = prevBodyPosition;
            app.style.width = prevAppWidth;
        };
    }, []);
};

/**
 *
 * @param data bruges hvis det sendes med, ellers bruges data fra fetch med url eller default url
 * @param teamonly kommer med som parameter i defaulturl, hvis der ikke sendes en url med - default: false
 * @param onItemClick funtion som kaldes, når der klikkes på et item
 * @param searchBarButtons knapper i søgefeltet
 * @param url til at fetche fra - default: '/Drift/Enhed?teamonly=${teamonly}'
 * @returns
 */

const EnhedList = ({ data, teamonly = false, onItemClick, searchBarButtons, showGraph = false }: IEnhedList) => {
    const { data: fetchData, isLoading, isSuccess, refetch } = useGetEnhedQuery(`${teamonly}`);
    const { updateId } = useEnhed();

    useEnhedListWidth();

    const handleItemClick = (e: ItemClickEvent<ISearchEnhed>) => {
        if (e.itemData == null) return;
        onItemClick?.(e.itemData);
        updateId(e.itemData.id);
    };

    if (!isSuccess && isLoading) {
        return <LoadingScreen />;
    }

    return (
        <Container sx={{ paddingTop: '16px', height: '100%' }}>
            <Grid container spacing={1} sx={{ height: '100%', margin: 0, width: '100%' }}>
                <Grid
                    item
                    xs
                    sx={{ height: 'calc(100% - 10px)', mt: '5px', mb: '5px', pr: '2px' }}
                    boxShadow={6}
                    borderRadius={3}
                >
                    <List
                        dataSource={data ?? fetchData}
                        itemRender={(i: ISearchEnhed) => GetLookupItemRender({ data: i, showGraph, refetch })}
                        searchEnabled={true}
                        searchExpr={['value', 'searchCriteria']}
                        onItemClick={handleItemClick}
                        scrollingEnabled={true}
                        searchEditorOptions={{ buttons: searchBarButtons }}
                        pageLoadMode={'scrollBottom'}
                        id='enhedList'
                    ></List>
                </Grid>
                {/* <Grid item xs="auto">
            <DxButton key="hide-lookup-btn" style={{ marginLeft: 5 }} icon='remove' onClick={() => setSelecting(false)} />
        </Grid> */}
            </Grid>
        </Container>
    );
};
export default EnhedList;
