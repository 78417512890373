
    const Svg4_05_13 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="340.59 200.21 230.70999999999998 187.71" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M397.22 283.82L397.22 382.92Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.22 382.92L345.59 382.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.59 382.92L345.59 247.85Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.59 247.85L397.22 247.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.22 247.85L397.22 266.95Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.54 283.5L382.34 285.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.34 285.57L381.75 287.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.75 287.56L380.8 289.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.8 289.41L379.51 291.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.51 291.05L377.94 292.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.94 292.41L376.14 293.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.14 293.45L374.17 294.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.17 294.13L372.11 294.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.11 294.43L370.03 294.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.03 294.33L368.01 293.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.01 293.84L366.12 292.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.12 292.97L364.42 291.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.42 291.77L362.99 290.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.99 290.26L361.86 288.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.86 288.51L361.09 286.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.09 286.58L360.69 284.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.69 284.54L360.69 282.45Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.69 282.45L361.09 280.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.09 280.41L361.86 278.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.86 278.48L362.99 276.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.99 276.73L364.42 275.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.42 275.22L366.12 274.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.12 274.02L368.01 273.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.01 273.15L370.03 272.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.03 272.66L372.11 272.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.11 272.56L374.17 272.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.17 272.86L376.14 273.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.14 273.54L377.94 274.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.94 274.58L379.51 275.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.51 275.94L380.8 277.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.8 277.58L381.75 279.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.75 279.43L382.34 281.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.34 281.42L382.54 283.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.66 284.16L360.66 282.83 360.64 283.5 360.66 284.16Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M360.66 284.16L360.72 284.81 360.66 282.83 360.66 284.16Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M360.72 284.81L360.72 282.18 360.66 282.83 360.72 284.81Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M360.72 284.81L360.82 285.47 360.72 282.18 360.72 284.81Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M360.82 285.47L360.82 281.52 360.72 282.18 360.82 285.47Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M360.82 285.47L360.96 286.11 360.82 281.52 360.82 285.47Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M360.96 286.11L360.96 280.88 360.82 281.52 360.96 286.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M360.96 286.11L361.14 286.75 360.96 280.88 360.96 286.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M361.14 286.75L361.14 280.24 360.96 280.88 361.14 286.75Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M361.14 286.75L361.36 287.38 361.14 280.24 361.14 286.75Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M361.36 287.38L361.36 279.61 361.14 280.24 361.36 287.38Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M361.36 287.38L361.61 287.99 361.36 279.61 361.36 287.38Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M361.61 287.99L361.61 279 361.36 279.61 361.61 287.99Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M361.61 287.99L361.9 288.58 361.61 279 361.61 287.99Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M361.9 288.58L361.9 278.41 361.61 279 361.9 288.58Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M361.9 288.58L362.22 289.16 361.9 278.41 361.9 288.58Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M362.22 289.16L362.22 277.83 361.9 278.41 362.22 289.16Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M362.22 289.16L362.58 289.71 362.22 277.83 362.22 289.16Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M362.58 289.71L362.58 277.28 362.22 277.83 362.58 289.71Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M362.58 289.71L362.97 290.25 362.58 277.28 362.58 289.71Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M362.97 290.25L362.97 276.74 362.58 277.28 362.97 290.25Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M362.97 290.25L363.4 290.75 362.97 276.74 362.97 290.25Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M363.4 290.75L363.4 276.24 362.97 276.74 363.4 290.75Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M363.4 290.75L363.85 291.23 363.4 276.24 363.4 290.75Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M363.85 291.23L363.85 275.76 363.4 276.24 363.85 291.23Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M363.85 291.23L364.33 291.69 363.85 275.76 363.85 291.23Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M364.33 291.69L364.33 275.3 363.85 275.76 364.33 291.69Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M364.33 291.69L364.84 292.11 364.33 275.3 364.33 291.69Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M364.84 292.11L364.84 274.88 364.33 275.3 364.84 292.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M364.84 292.11L365.37 292.5 364.84 274.88 364.84 292.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M365.37 292.5L365.37 274.49 364.84 274.88 365.37 292.5Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M365.37 292.5L365.93 292.86 365.37 274.49 365.37 292.5Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M365.93 292.86L365.93 274.13 365.37 274.49 365.93 292.86Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M365.93 292.86L366.5 293.19 365.93 274.13 365.93 292.86Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M366.5 293.19L366.5 273.8 365.93 274.13 366.5 293.19Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M366.5 293.19L367.1 293.48 366.5 273.8 366.5 293.19Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M367.1 293.48L367.1 273.51 366.5 273.8 367.1 293.48Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M367.1 293.48L367.71 293.73 367.1 273.51 367.1 293.48Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M367.71 293.73L367.71 273.26 367.1 273.51 367.71 293.73Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M367.71 293.73L368.33 293.95 367.71 273.26 367.71 293.73Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M368.33 293.95L368.33 273.05 367.71 273.26 368.33 293.95Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M368.33 293.95L368.97 294.12 368.33 273.05 368.33 293.95Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M368.97 294.12L368.97 272.87 368.33 273.05 368.97 294.12Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M368.97 294.12L369.62 294.26 368.97 272.87 368.97 294.12Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M369.62 294.26L369.62 272.73 368.97 272.87 369.62 294.26Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M369.62 294.26L370.27 294.36 369.62 272.73 369.62 294.26Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M370.27 294.36L370.27 272.63 369.62 272.73 370.27 294.36Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M370.27 294.36L370.93 294.42 370.27 272.63 370.27 294.36Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M370.93 294.42L370.93 272.57 370.27 272.63 370.93 294.42Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M370.93 294.42L371.59 294.44 370.93 272.57 370.93 294.42Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M371.59 294.44L371.59 272.55 370.93 272.57 371.59 294.44Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M371.59 294.44L372.25 294.42 371.59 272.55 371.59 294.44Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M372.25 294.42L372.25 272.57 371.59 272.55 372.25 294.42Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M372.25 294.42L372.91 294.36 372.25 272.57 372.25 294.42Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M372.91 294.36L372.91 272.63 372.25 272.57 372.91 294.36Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M372.91 294.36L373.56 294.26 372.91 272.63 372.91 294.36Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M373.56 294.26L373.56 272.73 372.91 272.63 373.56 294.26Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M373.56 294.26L374.21 294.12 373.56 272.73 373.56 294.26Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M374.21 294.12L374.21 272.87 373.56 272.73 374.21 294.12Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M374.21 294.12L374.85 293.95 374.21 272.87 374.21 294.12Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M374.85 293.95L374.85 273.05 374.21 272.87 374.85 293.95Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M374.85 293.95L375.47 293.73 374.85 273.05 374.85 293.95Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M375.47 293.73L375.47 273.26 374.85 273.05 375.47 293.73Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M375.47 293.73L376.08 293.48 375.47 273.26 375.47 293.73Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M376.08 293.48L376.08 273.51 375.47 273.26 376.08 293.48Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M376.08 293.48L376.68 293.19 376.08 273.51 376.08 293.48Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M376.68 293.19L376.68 273.8 376.08 273.51 376.68 293.19Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M376.68 293.19L377.25 292.86 376.68 273.8 376.68 293.19Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M377.25 292.86L377.25 274.13 376.68 273.8 377.25 292.86Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M377.25 292.86L377.81 292.5 377.25 274.13 377.25 292.86Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M377.81 292.5L377.81 274.49 377.25 274.13 377.81 292.5Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M377.81 292.5L378.34 292.11 377.81 274.49 377.81 292.5Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M378.34 292.11L378.34 274.88 377.81 274.49 378.34 292.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M378.34 292.11L378.85 291.69 378.34 274.88 378.34 292.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M378.85 291.69L378.85 275.3 378.34 274.88 378.85 291.69Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M378.85 291.69L379.33 291.23 378.85 275.3 378.85 291.69Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M379.33 291.23L379.33 275.76 378.85 275.3 379.33 291.23Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M379.33 291.23L379.78 290.75 379.33 275.76 379.33 291.23Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M379.78 290.75L379.78 276.24 379.33 275.76 379.78 290.75Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M379.78 290.75L380.21 290.25 379.78 276.24 379.78 290.75Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M380.21 290.25L380.21 276.74 379.78 276.24 380.21 290.25Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M380.21 290.25L380.6 289.71 380.21 276.74 380.21 290.25Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M380.6 289.71L380.6 277.28 380.21 276.74 380.6 289.71Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M380.6 289.71L380.96 289.16 380.6 277.28 380.6 289.71Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M380.96 289.16L380.96 277.83 380.6 277.28 380.96 289.16Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M380.96 289.16L381.28 288.58 380.96 277.83 380.96 289.16Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M381.28 288.58L381.28 278.41 380.96 277.83 381.28 288.58Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M381.28 288.58L381.57 287.99 381.28 278.41 381.28 288.58Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M381.57 287.99L381.57 279 381.28 278.41 381.57 287.99Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M381.57 287.99L381.82 287.38 381.57 279 381.57 287.99Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M381.82 287.38L381.82 279.61 381.57 279 381.82 287.38Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M381.82 287.38L382.04 286.75 381.82 279.61 381.82 287.38Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M382.04 286.75L382.04 280.24 381.82 279.61 382.04 286.75Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M382.04 286.75L382.22 286.11 382.04 280.24 382.04 286.75Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M382.22 286.11L382.22 280.88 382.04 280.24 382.22 286.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M382.22 286.11L382.36 285.47 382.22 280.88 382.22 286.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M382.36 285.47L382.36 281.52 382.22 280.88 382.36 285.47Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M382.36 285.47L382.46 284.81 382.36 281.52 382.36 285.47Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M382.46 284.81L382.46 282.18 382.36 281.52 382.46 284.81Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M382.46 284.81L382.52 284.16 382.46 282.18 382.46 284.81Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M382.52 284.16L382.52 282.83 382.46 282.18 382.52 284.16Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M382.52 284.16L382.54 283.5 382.52 282.83 382.52 284.16Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.91 285.03L393.91 268.16 388.47 270.14 393.91 285.03Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.91 285.03L406.06 280.59 393.91 268.16 393.91 285.03Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M406.06 280.59L406.06 263.72 393.91 268.16 406.06 280.59Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M406.06 280.59L411.49 278.61 406.06 263.72 406.06 280.59Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M427.51 272.76L427.51 255.89 422.07 257.87 427.51 272.76Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M427.51 272.76L439.66 268.32 427.51 255.89 427.51 272.76Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M439.66 268.32L439.66 251.45 427.51 255.89 439.66 268.32Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M439.66 268.32L445.09 266.34 439.66 251.45 439.66 268.32Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M461.11 260.49L461.11 243.62 455.67 245.61 461.11 260.49Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M461.11 260.49L473.26 256.06 461.11 243.62 461.11 260.49Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M473.26 256.06L473.26 239.18 461.11 243.62 473.26 256.06Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M473.26 256.06L478.69 254.07 473.26 239.18 473.26 256.06Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M494.71 248.22L494.71 231.35 489.27 233.34 494.71 248.22Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M494.71 248.22L506.85 243.79 494.71 231.35 494.71 248.22Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M506.85 243.79L506.85 226.92 494.71 231.35 506.85 243.79Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M506.85 243.79L512.29 241.8 506.85 226.92 506.85 243.79Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M528.31 235.96L528.31 219.08 522.87 221.07 528.31 235.96Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M528.31 235.96L540.45 231.52 528.31 219.08 528.31 235.96Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M540.45 231.52L540.45 214.65 528.31 219.08 540.45 231.52Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M540.45 231.52L545.89 229.53 540.45 214.65 540.45 231.52Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M566.3 205.21L377.37 274.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M566.3 222.08L380.43 289.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M566.3 222.08L566.3 205.21Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.47 270.14L393.91 285.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.06 263.72L411.49 278.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.07 257.87L427.51 272.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.66 251.45L445.09 266.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.67 245.61L461.11 260.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M473.26 239.18L478.69 254.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M489.27 233.34L494.71 248.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M506.85 226.92L512.29 241.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M522.87 221.07L528.31 235.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M540.45 214.65L545.89 229.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.38 347.32L426.98 319.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.38 319.42L426.98 347.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.95 347.32L453.55 319.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.95 319.42L453.55 347.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg4_05_13;

    