
    const SvgXx_06_19 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="331.98 234.98 114.09999999999997 133.20000000000002" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M389.03 294.19L389.03 239.98Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.15 294.19L422.91 294.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.62 311.13L414.44 311.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.09 328.07L405.97 328.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.08 311.13L440.88 315.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.88 315.67L440.29 320.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.29 320.17L439.31 324.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.31 324.6L437.94 328.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.94 328.93L436.2 333.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.2 333.13L434.11 337.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.11 337.16L431.67 340.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.67 340.99L428.9 344.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.9 344.59L425.83 347.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.83 347.94L422.49 351" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.49 351L418.88 353.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.88 353.77L415.06 356.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.06 356.21L411.03 358.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.03 358.3L406.83 360.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.83 360.04L402.5 361.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.5 361.41L398.07 362.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.07 362.39L393.57 362.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.57 362.98L389.03 363.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.03 363.18L384.49 362.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.49 362.98L379.99 362.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.99 362.39L375.56 361.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.56 361.41L371.23 360.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.23 360.04L367.03 358.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.03 358.3L363.01 356.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.01 356.21L359.18 353.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.18 353.77L355.57 351" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.57 351L352.23 347.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.23 347.94L349.16 344.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.16 344.59L346.39 340.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.39 340.99L343.96 337.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.96 337.16L341.86 333.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.86 333.13L340.12 328.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.12 328.93L338.76 324.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M338.76 324.6L337.77 320.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M337.77 320.17L337.18 315.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M337.18 315.67L336.98 311.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.98 311.13L337.18 306.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M337.18 306.6L337.77 302.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M337.77 302.09L338.76 297.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M338.76 297.66L340.12 293.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.12 293.33L341.86 289.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.86 289.14L343.96 285.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.96 285.11L346.39 281.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.39 281.28L349.16 277.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.16 277.68L352.23 274.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.23 274.33L355.57 271.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.57 271.26L359.18 268.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.18 268.5L363.01 266.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.01 266.06L367.03 263.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.03 263.96L371.23 262.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.23 262.22L375.56 260.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.56 260.86L379.99 259.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.99 259.87L384.49 259.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.49 259.28L389.03 259.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.03 259.08L393.57 259.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.57 259.28L398.07 259.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.07 259.87L402.5 260.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.5 260.86L406.83 262.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.83 262.22L411.03 263.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.03 263.96L415.06 266.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.06 266.06L418.88 268.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.88 268.5L422.49 271.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.49 271.26L425.83 274.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.83 274.33L428.9 277.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.9 277.68L431.67 281.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.67 281.28L434.11 285.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.11 285.11L436.2 289.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.2 289.14L437.94 293.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.94 293.33L439.31 297.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.31 297.66L440.29 302.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.29 302.09L440.88 306.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.88 306.6L441.08 311.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_06_19;

    