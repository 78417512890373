import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import Chip from '@mui/material/Chip/Chip';
import Grid from '@mui/material/Grid/Grid';
import { HtmlTooltip } from '../misc/tooltips';
import { IFile } from './common';

export const FileGridItem = (file: IFile) => {
    const onClick = () => {
        // Åben nyt tab med url der henter filen
        const newWindow = window.open(file.url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    return (
        <Grid item xs={6} sm={4} md={3}>
            <HtmlTooltip title={file.name} placement='top'>
                <Chip
                    label={file.name}
                    variant='outlined'
                    clickable
                    sx={{ width: '100%', height: 50 }}
                    deleteIcon={<ArrowCircleDownIcon />}
                    onDelete={onClick}
                />
            </HtmlTooltip>
        </Grid>
    );
};

export interface IFileGridProps {
    files: IFile[];
}

const FileGrid = (props: IFileGridProps) => {
    return (
        <Grid container spacing={2} sx={{ paddingLeft: '8px' }}>
            {props.files.map((file, index) => (
                <FileGridItem {...file} key={`FileGridItem${index}`} />
            ))}
        </Grid>
    );
};

export default FileGrid;
