
    const Svg2_04_02 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="311.14 236.92 191.83000000000004 100.91" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M316.14 241.92L316.14 332.83Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M316.14 332.83L497.97 332.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M497.97 332.83L497.97 241.92Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M497.97 241.92L316.14 241.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.52 310.1L360.84 264.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.84 264.65L378.16 310.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.02 294.95L371.66 294.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.14 264.65L408.46 310.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.46 310.1L425.78 264.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.44 310.1L451.75 264.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.75 264.65L469.07 310.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.93 294.95L462.58 294.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_04_02;

    