import { IAllowedPopupProps, IAllowedQrProps, ICallbacks, ISliceState } from './interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IDictionary } from '../../utils/types';

export const callbackStore: IDictionary<ICallbacks> = {};

const initialState: ISliceState[] = [];

const useQRScannerSlice = createSlice({
    name: 'useQRScanner',
    initialState: initialState,
    reducers: {
        add: (
            state,
            action: PayloadAction<{
                newState: ISliceState;
                callbacks: ICallbacks;
            }>
        ) => {
            callbackStore[action.payload.newState.key] = action.payload.callbacks;
            return [...state, action.payload.newState];
        },

        remove: (
            state,
            action: PayloadAction<{
                key: string;
            }>
        ) => {
            delete callbackStore[action.payload.key];
            return state.filter((r) => r.key !== action.payload.key);
        },

        start: (
            state,
            action: PayloadAction<{
                key: string;
            }>
        ) => {
            return state.map((s) => (s.key === action.payload.key ? { ...s, active: true } : s));
        },

        stop: (
            state,
            action: PayloadAction<{
                key: string;
            }>
        ) => {
            return state.map((s) => (s.key === action.payload.key ? { ...s, active: false, loading: false } : s));
        },

        setLoading: (
            state,
            action: PayloadAction<{
                key: string;
                loading: boolean;
            }>
        ) => {
            return state.map((s) => (s.key === action.payload.key ? { ...s, loading: action.payload.loading } : s));
        },

        updateProps: (
            state,
            action: PayloadAction<{
                key: string;
                QRProps?: IAllowedQrProps;
                PopupProps?: IAllowedPopupProps;
            }>
        ) => {
            return state.map((s) =>
                s.key === action.payload.key
                    ? {
                          ...s,
                          QRProps: action.payload.QRProps ?? s.QRProps,
                          PopupProps: action.payload.PopupProps ?? s.PopupProps,
                      }
                    : s
            );
        },
    },
});

export default useQRScannerSlice;
