
    const Svg3_01_02 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="323.39 249.93 140.06 96.70999999999998" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M328.39 341.64L458.45 341.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.45 341.64L458.45 254.93Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.45 254.93L328.39 254.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.39 254.93L328.39 341.64Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.36 292.74L426.72 292.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.36 303.83L443.36 292.74Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.72 303.83L443.36 303.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.48 320.48L426.72 320.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.72 320.48L426.72 276.09Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.72 276.09L343.48 276.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.48 276.09L343.48 320.48Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg3_01_02;

    