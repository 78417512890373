
    const SvgXx_06_21 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="393.68 240.64 61.329999999999984 133.2" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M424.35 322.64L424.35 245.64Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.68 322.64L450.01 322.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.01 322.64L424.35 368.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.35 368.84L398.68 322.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_06_21;

    