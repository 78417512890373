
    const Svg3_07_03 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="329.87 264.23 140.06 96.70999999999998" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M334.87 355.94L464.93 355.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.93 355.94L464.93 269.23Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.93 269.23L334.87 269.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M334.87 269.23L334.87 355.94Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.75 329.93L348.75 295.25Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.75 295.25L360.31 295.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.31 295.25L365.26 296.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.26 296.9L368.57 300.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.57 300.2L370.22 303.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.22 303.5L371.87 308.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.87 308.46L371.87 316.72Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.87 316.72L370.22 321.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.22 321.67L368.57 324.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.57 324.97L365.26 328.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.26 328.28L360.31 329.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.31 329.93L348.75 329.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.43 295.25L396.64 329.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.64 329.93L409.86 295.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.46 329.93L416.46 295.25Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.46 295.25L431.33 295.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.33 295.25L436.28 296.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.28 296.9L437.93 298.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.93 298.55L439.58 301.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.58 301.85L439.58 305.15Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.58 305.15L437.93 308.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.93 308.46L436.28 310.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.28 310.11L431.33 311.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.33 311.76L416.46 311.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.02 311.76L439.58 329.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg3_07_03;

    