
    const Svg2_01_03 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="317.68 212.99 147.52999999999997 175.05" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M460.21 245.5L460.21 266.13Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.21 279.88L460.21 307.39Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.21 321.14L460.21 348.65Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.21 362.4L460.21 383.04Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.21 383.04L439.58 383.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.83 383.04L398.32 383.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.57 383.04L357.06 383.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.31 383.04L322.68 383.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.68 383.04L322.68 362.4Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.68 348.65L322.68 321.14Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.68 307.39L322.68 279.88Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.68 266.13L322.68 245.5Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.68 245.5L343.31 245.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M357.06 245.5L384.57 245.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.32 245.5L425.83 245.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.58 245.5L460.21 245.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.68 217.99L343.31 217.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M357.06 217.99L384.57 217.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.32 217.99L425.83 217.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.58 217.99L460.21 217.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_01_03;

    