
    const Svg4_05_05 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="324.36 222.26 173.34999999999997 173.35000000000002" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M329.36 308.94L329.36 349.77Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M329.36 349.77L411.04 390.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.04 390.61L411.04 227.26Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.04 227.26L329.36 268.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M329.36 268.1L329.36 308.94Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M492.71 349.77L411.04 349.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.04 349.77L451.87 370.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.04 349.77L451.87 329.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.04 268.1L492.71 268.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M492.71 268.1L451.87 288.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M492.71 268.1L451.87 247.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg4_05_05;

    