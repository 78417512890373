/**
 * Decodes values of objects in a list
 * @param encodedJson any list of objects with encoded values
 * @returns The json with the values decoded
 */
export function decodeJsonResponse(encodedJson: any[]): any[] {
    encodedJson.forEach((obj) => {
        Object.keys(obj).forEach((key) => {
            obj[key] = decodeURIComponent(obj[key]);
        });
    });
    return encodedJson;
}
