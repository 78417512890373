import { useLayoutEffect, useRef, useState } from 'react';

import { Cluster } from 'ol/source';
import { FeatureProperty } from '../../feature-utility';
import GetHelpdeskEditRender from './helpdesk-edit-render';
import Grid from '@mui/material/Grid/Grid';
import { IFeatureData } from './interfaces';
import { IOverlayProps } from '../../react-components/clickable-features-overlays';
import ImageGridSmall from '../../../../file-displaying/image-grid-small';
import Popup from 'devextreme-react/popup';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography/Typography';
import { WideButton } from '../../../../mui/styled-mui';
import { isMobile } from 'react-device-detect';

const ClickableFeatureOverlay = (props: IOverlayProps) => {
    const mProps = useRef(props);
    const featureData: IFeatureData = props.feature.get(FeatureProperty.Data);
    const [open, setOpen] = useState(false);

    useLayoutEffect(() => {
        const offset = mProps.current.overlay.getOffset();
        mProps.current.overlay.setOffset([offset[0], -40]);
    }, []);

    const handleDetailsClicked = () => {
        setOpen(true);
    };

    const closeFeature = () => {
        setOpen(false);
        props.close();
    };

    const closePopup = () => {
        setOpen(false);
    };

    const handleSubmitted = () => {
        closeFeature();
        (props.layer.getSource() as Cluster).getSource()!.refresh();
        // props.layer.getSource()!.getSource()!.refresh()
    };

    const handleCanceled = () => {
        closeFeature();
    };
    const v = featureData?.files?.filter((file) => file.type === 'image').length > 0 ? 6 : 12;
    return (
        <>
            <Grid container spacing={1} ml='0px' mt='-4px' maxHeight={`35vh`}>
                <Grid container sx={{ overflow: 'scroll', maxHeight: 'calc(35vh - 52px)' }}>
                    <Grid item xs={v} height='calc(100% - 24px)'>
                        <Stack spacing={1} height='100%'>
                            <Typography
                                variant='h6'
                                align='center'
                                sx={{
                                    wordBreak: 'break-word',
                                }}
                            >
                                {featureData.problem}
                            </Typography>
                            <Typography
                                sx={{
                                    wordBreak: 'break-word',
                                    whiteSpace: 'pre-line',
                                }}
                            >
                                {featureData.note}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12 - v} sx={{}}>
                        <ImageGridSmall
                            files={
                                featureData?.files?.filter(
                                    (file, index, arr) =>
                                        file.type === 'image' && (index < 3 || index >= arr.length - 3)
                                ) ?? []
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} width='100%'>
                    <Grid item xs={6} padding={'4px'}>
                        <WideButton variant='contained' onClick={handleDetailsClicked}>
                            Detaljer
                        </WideButton>
                    </Grid>
                    <Grid item xs={6} padding={'4px'}>
                        <WideButton variant='outlined' onClick={closeFeature}>
                            Luk
                        </WideButton>
                    </Grid>
                </Grid>
            </Grid>
            <Popup
                fullScreen={isMobile}
                visible={open}
                title={'Detaljer'}
                showCloseButton={false}
                hideOnOutsideClick={false}
                contentRender={() =>
                    GetHelpdeskEditRender({
                        closePopup,
                        onSubmitted: handleSubmitted,
                        onCanceled: handleCanceled,
                        data: featureData,
                    })
                }
            />
        </>
    );
};

export default ClickableFeatureOverlay;
