
    const Svg1_01_36 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="302.08 207.11 194.90000000000003 194.89999999999998" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M491.98 235.25L491.98 373.92Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M491.98 304.56L491.79 310.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M491.79 310.61L491.19 316.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M491.19 316.63L490.21 322.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M490.21 322.6L488.83 328.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.83 328.49L487.08 334.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M487.08 334.28L484.95 339.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M484.95 339.94L482.45 345.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M482.45 345.45L479.6 350.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M479.6 350.79L476.4 355.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M476.4 355.92L472.88 360.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M472.88 360.84L469.04 365.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M469.04 365.52L464.91 369.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.91 369.93L460.49 374.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.49 374.07L455.81 377.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.81 377.91L450.9 381.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.9 381.43L445.76 384.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M445.76 384.62L440.42 387.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.42 387.48L434.91 389.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.91 389.97L429.25 392.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.25 392.1L423.46 393.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.46 393.86L417.57 395.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.57 395.23L411.6 396.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.6 396.22L405.58 396.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.58 396.81L399.53 397.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.53 397.01L393.49 396.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.49 396.81L387.47 396.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.47 396.22L381.5 395.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.5 395.23L375.6 393.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.6 393.86L369.82 392.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.82 392.1L364.15 389.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.15 389.97L358.64 387.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.64 387.48L353.31 384.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.31 384.62L348.17 381.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.17 381.43L343.25 377.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.25 377.91L338.58 374.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M338.58 374.07L334.16 369.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M334.16 369.93L330.02 365.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.02 365.52L326.19 360.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M326.19 360.84L322.66 355.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.66 355.92L319.47 350.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319.47 350.79L316.62 345.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M316.62 345.45L314.12 339.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M314.12 339.94L311.99 334.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M311.99 334.28L310.23 328.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.23 328.49L308.86 322.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M308.86 322.6L307.87 316.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M307.87 316.63L307.28 310.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M307.28 310.61L307.08 304.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M307.08 304.56L307.28 298.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M307.28 298.51L307.87 292.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M307.87 292.49L308.86 286.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M308.86 286.52L310.23 280.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.23 280.63L311.99 274.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M311.99 274.84L314.12 269.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M314.12 269.18L316.62 263.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M316.62 263.67L319.47 258.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319.47 258.33L322.66 253.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.66 253.2L326.19 248.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M326.19 248.28L330.02 243.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.02 243.6L334.16 239.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M334.16 239.19L338.58 235.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M338.58 235.05L343.25 231.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.25 231.21L348.17 227.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.17 227.69L353.31 224.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.31 224.5L358.64 221.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.64 221.64L364.15 219.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.15 219.15L369.82 217.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.82 217.02L375.6 215.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.6 215.26L381.5 213.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.5 213.89L387.47 212.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.47 212.9L393.49 212.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.49 212.31L399.53 212.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.53 212.11L405.58 212.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.58 212.31L411.6 212.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.6 212.9L417.57 213.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.57 213.89L423.46 215.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.46 215.26L429.25 217.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.25 217.02L434.91 219.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.91 219.15L440.42 221.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.42 221.64L445.76 224.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M445.76 224.5L450.9 227.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.9 227.69L455.81 231.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.81 231.21L460.49 235.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.49 235.05L464.91 239.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.91 239.19L469.04 243.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M469.04 243.6L472.88 248.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M472.88 248.28L476.4 253.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M476.4 253.2L479.6 258.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M479.6 258.33L482.45 263.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M482.45 263.67L484.95 269.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M484.95 269.18L487.08 274.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M487.08 274.84L488.83 280.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.83 280.63L490.21 286.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M490.21 286.52L491.19 292.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M491.19 292.49L491.79 298.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M491.79 298.51L491.98 304.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_36;

    