
    const Svg1_01_28 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="245.62 263.06 320.65 61.76999999999998" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M250.62 319.83L561.27 319.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M561.27 319.83L561.27 268.06Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M561.27 268.06L250.62 268.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M250.62 268.06L250.62 319.83Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M267.34 293.94L280.29 293.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M295.82 293.94L311.35 293.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M326.88 293.94L342.42 293.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M357.95 293.94L370.89 293.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.83 281L383.83 306.89Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.83 306.89L398.62 306.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.04 284.7L419.58 282.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.58 282.23L415.88 281" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.88 281L410.95 281" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.95 281L407.25 282.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.25 282.23L404.78 284.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.78 284.7L404.78 287.16Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.78 287.16L406.02 289.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.02 289.63L407.25 290.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.25 290.86L409.71 292.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.71 292.1L417.11 294.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.11 294.56L419.58 295.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.58 295.79L420.81 297.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.81 297.03L422.04 299.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.04 299.49L422.04 303.19Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.04 303.19L419.58 305.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.58 305.66L415.88 306.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.88 306.89L410.95 306.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.95 306.89L407.25 305.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.25 305.66L404.78 303.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M544.55 293.94L531.6 293.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M516.07 293.94L500.54 293.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M485 293.94L469.47 293.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M453.94 293.94L440.99 293.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M528.98 284.96L544.55 293.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M544.55 293.94L528.98 302.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M282.91 284.96L267.34 293.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M267.34 293.94L282.91 302.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_28;

    