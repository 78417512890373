
    const Svg2_07_02 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="309.01 238.98 184.98000000000002 110.97" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M421.68 344.95L421.68 243.98Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.68 243.98L464.95 243.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.95 243.98L479.38 248.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M479.38 248.79L484.18 253.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M484.18 253.59L488.99 263.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.99 263.21L488.99 272.83Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.99 272.83L484.18 282.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M484.18 282.44L479.38 287.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M479.38 287.25L464.95 292.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.95 292.06L421.68 292.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.34 292.06L488.99 344.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.52 279.2L365.95 275.63 383.8 257.78 387.37 261.35 369.52 279.2Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M365.95 313.29L369.52 309.72 387.37 327.57 383.8 331.14 365.95 313.29Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M335.43 275.63L331.86 279.2 314.01 261.35 317.58 257.78 335.43 275.63Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M331.86 309.72L335.43 313.29 317.58 331.14 314.01 327.57 331.86 309.72Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M350.69 321.09L347.36 320.88 347.99 315.87 350.69 316.04 350.69 321.09Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M347.36 320.88L344.07 320.25 345.33 315.36 347.99 315.87 347.36 320.88Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M344.07 320.25L340.89 319.22 342.75 314.53 345.33 315.36 344.07 320.25Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M340.89 319.22L337.86 317.8 340.3 313.37 342.75 314.53 340.89 319.22Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M337.86 317.8L335.04 316.01 338.01 311.92 340.3 313.37 337.86 317.8Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M335.04 316.01L332.46 313.87 335.92 310.19 338.01 311.92 335.04 316.01Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M332.46 313.87L330.18 311.44 334.07 308.22 335.92 310.19 332.46 313.87Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M330.18 311.44L328.21 308.73 332.47 306.03 334.07 308.22 330.18 311.44Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M328.21 308.73L326.6 305.8 331.17 303.65 332.47 306.03 328.21 308.73Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M326.6 305.8L325.37 302.69 330.17 301.13 331.17 303.65 326.6 305.8Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M325.37 302.69L324.54 299.45 329.5 298.51 330.17 301.13 325.37 302.69Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M324.54 299.45L324.12 296.13 329.16 295.82 329.5 298.51 324.54 299.45Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M324.12 296.13L324.12 292.79 329.16 293.11 329.16 295.82 324.12 296.13Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M324.12 292.79L324.54 289.47 329.5 290.42 329.16 293.11 324.12 292.79Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M324.54 289.47L325.37 286.23 330.17 287.79 329.5 290.42 324.54 289.47Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M325.37 286.23L326.6 283.12 331.17 285.27 330.17 287.79 325.37 286.23Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M326.6 283.12L328.21 280.19 332.47 282.9 331.17 285.27 326.6 283.12Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M328.21 280.19L330.18 277.49 334.07 280.71 332.47 282.9 328.21 280.19Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M330.18 277.49L332.46 275.05 335.92 278.73 334.07 280.71 330.18 277.49Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M332.46 275.05L335.04 272.92 338.01 277 335.92 278.73 332.46 275.05Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M335.04 272.92L337.86 271.13 340.3 275.55 338.01 277 335.04 272.92Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M337.86 271.13L340.89 269.7 342.75 274.4 340.3 275.55 337.86 271.13Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M340.89 269.7L344.07 268.67 345.33 273.56 342.75 274.4 340.89 269.7Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M344.07 268.67L347.36 268.04 347.99 273.05 345.33 273.56 344.07 268.67Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M347.36 268.04L350.69 267.83 350.69 272.88 347.99 273.05 347.36 268.04Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M350.69 267.83L354.03 268.04 353.4 273.05 350.69 272.88 350.69 267.83Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M354.03 268.04L357.32 268.67 356.06 273.56 353.4 273.05 354.03 268.04Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M357.32 268.67L360.5 269.7 358.64 274.4 356.06 273.56 357.32 268.67Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M360.5 269.7L363.52 271.13 361.09 275.55 358.64 274.4 360.5 269.7Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M363.52 271.13L366.35 272.92 363.38 277 361.09 275.55 363.52 271.13Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M366.35 272.92L368.92 275.05 365.47 278.73 363.38 277 366.35 272.92Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M368.92 275.05L371.21 277.49 367.32 280.71 365.47 278.73 368.92 275.05Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M371.21 277.49L373.18 280.19 368.91 282.9 367.32 280.71 371.21 277.49Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M373.18 280.19L374.79 283.12 370.22 285.27 368.91 282.9 373.18 280.19Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M374.79 283.12L376.02 286.23 371.22 287.79 370.22 285.27 374.79 283.12Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M376.02 286.23L376.85 289.47 371.89 290.42 371.22 287.79 376.02 286.23Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M376.85 289.47L377.27 292.79 372.23 293.11 371.89 290.42 376.85 289.47Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M377.27 292.79L377.27 296.13 372.23 295.82 372.23 293.11 377.27 292.79Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M377.27 296.13L376.85 299.45 371.89 298.51 372.23 295.82 377.27 296.13Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M376.85 299.45L376.02 302.69 371.22 301.13 371.89 298.51 376.85 299.45Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M376.02 302.69L374.79 305.8 370.22 303.65 371.22 301.13 376.02 302.69Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M374.79 305.8L373.18 308.73 368.91 306.03 370.22 303.65 374.79 305.8Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M373.18 308.73L371.21 311.44 367.32 308.22 368.91 306.03 373.18 308.73Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M371.21 311.44L368.92 313.87 365.47 310.19 367.32 308.22 371.21 311.44Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M368.92 313.87L366.35 316.01 363.38 311.92 365.47 310.19 368.92 313.87Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M366.35 316.01L363.52 317.8 361.09 313.37 363.38 311.92 366.35 316.01Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M363.52 317.8L360.5 319.22 358.64 314.53 361.09 313.37 363.52 317.8Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M360.5 319.22L357.32 320.25 356.06 315.36 358.64 314.53 360.5 319.22Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M357.32 320.25L354.03 320.88 353.4 315.87 356.06 315.36 357.32 320.25Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M354.03 320.88L350.69 321.09 350.69 316.04 353.4 315.87 354.03 320.88Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
</svg>
    );
    export default Svg2_07_02;

    