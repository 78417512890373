import { ClickableFeature, ClickableFeatureType } from './ClickableFeatures';
import { Coordinate, distance } from 'ol/coordinate';

import Api from '../../../shared/networking/api';
import { BaseIconId } from './SVG';
import Cluster from 'ol/source/Cluster';
import { Feature } from 'ol';
import { FeatureProperty } from './feature-utility';
import { Geometry } from 'ol/geom';
import { IMapLayer } from '../caretaker-map';
import { ISearchSource } from './react-controls/search-features/interfaces';
import { isRepresentationLayer, Layers } from './LayerMenuControl';
import { Options } from 'ol/layer/BaseVector';
import { SFC } from './react-controls/search-features/control';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import WKT from 'ol/format/WKT';
import __ from '../../../shared/utils/lodash-expansions';
import { map } from '.';
import { FeatureLike } from 'ol/Feature';
import { all, bbox } from 'ol/loadingstrategy';

export const DEFAULT_FEATURE_THEME = "tema1";

/**
 * Get the feature theme color or a default color
 *
 * @param feature The feature to get the theme color from
 * @param theme The theme to get the color from. Defaults to 
 * @param defaultColor The default color to return if no theme color is found
 * @returns The color of the theme or the default color
 */
export const getThemeOrDefault = ({
    feature,
    theme = DEFAULT_FEATURE_THEME,
    layer,
    defaultColor = 'black',
}: {
    feature?: Feature<Geometry> | FeatureLike,
    theme?: string,
    layer?: CTLayer
    defaultColor?: string
}): string => {
    const featureThemes = feature?.get(FeatureProperty.Data)?.color;
    const themes = [
        // Start with selected theme
        featureThemes?.[theme],
        // Fallback to default theme
        featureThemes?.tema1,
        featureThemes?.tema2,
        featureThemes?.tema3,
        featureThemes?.tema4,
        // Fallback to layer color
        layer?.options.color,
        // Fallback to hardcoded default color
        layer?.color,
    ];
    // If none are found, return default color
    return themes.find((t) => t !== undefined) ?? defaultColor;
};

interface ILoadData<TData = unknown> {
    id: string | number;
    geometry: string;
    data: TData;
    label?: string;
    color?: {
        tema1?: string;
        tema2?: string;
        tema3?: string;
        tema4?: string;
    };
    /** feature level parallel to {@link IMapLayer} svgString*/
    svg?: string;
    search?: Omit<ISearchSource, 'featureId'>;
}

export default class CTLayer<TSource extends VectorSource = VectorSource> extends VectorLayer<TSource> {
    private _options: IMapLayer;
    public get options() {
        return this._options;
    }
    public color: string = '#ff0000';
    public baseIconID: BaseIconId = BaseIconId.Enhed;

    private _searchSource: ISearchSource[] = [];
    protected get searchSource() {
        return this._searchSource;
    }

    constructor(options: IMapLayer, vectorLayerOptions?: Options<TSource>) {
        super({ ...vectorLayerOptions, declutter: options.declutter ?? false });
        this._options = options;
        this.initSource();
        this.initListeners();
    }

    public ResetLayerFeatures() {
        const source = this.getSource();
        source?.clear();
        this.initSource();
        this.initListeners();
    }

    public getNearestFeature(coordinates: Coordinate = map!.getView().getCenter()!) {
        let near: Feature | undefined;
        let nearDistance: number | undefined;
        this.getSource()!.forEachFeature((feature: Feature<Geometry>) => {
            const featureCoordinates = feature.getGeometry()!.getClosestPoint(coordinates);
            const d = distance(coordinates, featureCoordinates);
            if (nearDistance === undefined || d < nearDistance) {
                nearDistance = d;
                near = feature;
            }
        });
        return near;
    }

    /**
     * Places the layer on top of the other currently registered layers.
     * "Representation layers" such as "Lokaler" are placed in their own z-index group, instead of overtaking feature layers.
     *
     * @param layers the Layers object containing the z-indexes and registered layers
     */
    public putOnTop(layers: Layers) {
        if (isRepresentationLayer(this.options.displayName)) {
            this.setZIndex(++layers.representationLayerZIndex);
        } else {
            this.setZIndex(++layers.urlLayerZIndex);
        }
    }

    //#region Init

    /**
     * Initialize listeners for fx setting the search source when the layer becomes visible
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     */
    private initListeners() {
        this.addChangeListener('visible', () => {
            SFC.SetSearchSource(
                this.options.displayName,
                this.options.type,
                this.getVisible() ? this._searchSource : []
            );
        });
    }

    /**
     * Initializes the layer source from the given options
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     */
    protected initSource() {
        const createVectorSource = () => {
            const lThis = this;
            return new VectorSource({
                format: new WKT(),
                strategy: this._options.loadingStrategy ?? all,
                async loader(this, extent, resolution, projection, success?, failure?) {
                    const format = this.getFormat();
                    if (lThis.options.url === undefined || format === undefined) return failure?.();

                    let url = lThis.options.url;
                    if (lThis.options.loadingStrategy === bbox) {
                        let _url = new URL(url);
                        _url.searchParams.set('bbox', extent.join(','));
                        _url.searchParams.set('resolution', resolution.toString());
                        url = _url.toString();
                    }

                    const response = await Api.get<ILoadData[]>(lThis.options.url);
                    if (!Api.ok(response)) return failure?.();

                    type TRes = Array<{ feature: ClickableFeature; searchSource?: ISearchSource }>;

                    // Set and function used to make sure no two features have the same geometry
                    // Done here, as we don't always have full control over the data
                    const geoSet: Set<string> = new Set();
                    const getUniqueGeometry = (geometry: string): string => {
                        if (!geometry.includes('POINT') || !geoSet.has(geometry)) {
                            geoSet.add(geometry);
                            return geometry;
                        }

                        // Move geom 10 up
                        const match = geometry.match(/POINT *\(.* (\d*)/i);
                        if (match?.[1] != null) {
                            const yCoord = parseInt(match[1]) + 1;
                            const g = geometry.replace(/(POINT *\(.* )([0-9.]*)/i, '$1' + yCoord.toString());
                            return getUniqueGeometry(g);
                        }

                        return geometry;
                    };

                    const res = response.data.reduce((p: TRes, f: ILoadData) => {
                        if (typeof f.geometry !== 'string') return p;

                        try {
                            const geometry = getUniqueGeometry(f.geometry);
                            const feature = format.readFeature(geometry) as ClickableFeature;
                            feature.setId(f.id);
                            feature.set(FeatureProperty.Search, f.search);

                            // putting data in f became standard. Used to be f.data instead.
                            // Resolve conflicts by putting any in f.data into f, while keeping f.data for backwards compatibility
                            let fData = { ...f };
                            if (typeof fData.data === 'object') {
                                fData = { ...fData, ...fData.data };
                            }

                            feature.set(FeatureProperty.Data, fData);
                            // feature.set(FeatureProperty.StyleKeys, []);
                            feature.featureType = ClickableFeatureType.Pin;

                            let searchSource: ISearchSource | undefined;
                            if (f.search)
                                searchSource = {
                                    ...f.search,
                                    featureId: f.id,
                                    searchstring: f.search?.searchstring + f.id,
                                };

                            p.push({
                                feature,
                                searchSource,
                            });
                        } catch {
                            // Not really anything to do if it fails
                            console.log('Failed');
                        }

                        return p;
                    }, [] as TRes);

                    const features = res.map((r) => r.feature);
                    (this as VectorSource).addFeatures(features);
                    lThis._searchSource = res.map((r) => r.searchSource).filter((ss) => ss) as ISearchSource[];
                    if (lThis.getVisible()) {
                        SFC.SetSearchSource(lThis.options.displayName, lThis.options.type, lThis._searchSource);
                    }
                    success?.(features);
                },
            });
        };
        const cl = this.options.clusterOptions;
        const source = this.options.isCluster
            ? new Cluster({ distance: 40, source: createVectorSource(), ...__.getDefined(cl) })
            : createVectorSource();
        this.setSource(source as TSource);
    }

    //#endregion Init
}
