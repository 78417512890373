
    const Svg2_02_05 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="347.17 229.23 155.37 155.39000000000001" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M497.54 306.92L497.34 312.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M497.34 312.29L496.74 317.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M496.74 317.63L495.75 322.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M495.75 322.91L494.38 328.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M494.38 328.11L492.63 333.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M492.63 333.19L490.5 338.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M490.5 338.12L488.02 342.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.02 342.89L485.19 347.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M485.19 347.46L482.03 351.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M482.03 351.8L478.56 355.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.56 355.9L474.8 359.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M474.8 359.74L470.76 363.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.76 363.28L466.47 366.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.47 366.52L461.95 369.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.95 369.44L457.24 372.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.24 372.01L452.34 374.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.34 374.22L447.3 376.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.3 376.07L442.13 377.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.13 377.54L436.87 378.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.87 378.63L431.54 379.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.54 379.32L426.17 379.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.17 379.62L420.8 379.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.8 379.52L415.45 379.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.45 379.02L410.15 378.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.15 378.13L404.93 376.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.93 376.85L399.82 375.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.82 375.19L394.85 373.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.85 373.16L390.04 370.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.04 370.76L385.42 368.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M385.42 368.02L381.01 364.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.01 364.94L376.85 361.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.85 361.55L372.94 357.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.94 357.86L369.32 353.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.32 353.89L366.01 349.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.01 349.66L363.01 345.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.01 345.2L360.35 340.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.35 340.53L358.05 335.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.05 335.67L356.11 330.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.11 330.66L354.54 325.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.54 325.52L353.36 320.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.36 320.28L352.57 314.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.57 314.97L352.17 309.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.17 309.61L352.17 304.23Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.17 304.23L352.57 298.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.57 298.88L353.36 293.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.36 293.56L354.54 288.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.54 288.32L356.11 283.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.11 283.18L358.05 278.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.05 278.17L360.35 273.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.35 273.32L363.01 268.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.01 268.65L366.01 264.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.01 264.19L369.32 259.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.32 259.96L372.94 255.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.94 255.99L376.85 252.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.85 252.29L381.01 248.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.01 248.9L385.42 245.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M385.42 245.82L390.04 243.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.04 243.08L394.85 240.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.85 240.68L399.82 238.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.82 238.65L404.93 236.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.93 236.99L410.15 235.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.15 235.71L415.45 234.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.45 234.82L420.8 234.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.8 234.33L426.17 234.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.17 234.23L431.54 234.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.54 234.52L436.87 235.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.87 235.22L442.13 236.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.13 236.3L447.3 237.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.3 237.77L452.34 239.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.34 239.62L457.24 241.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.24 241.84L461.95 244.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.95 244.41L466.47 247.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.47 247.32L470.76 250.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.76 250.56L474.8 254.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M474.8 254.1L478.56 257.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.56 257.94L482.03 262.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M482.03 262.04L485.19 266.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M485.19 266.39L488.02 270.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.02 270.96L490.5 275.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M490.5 275.72L492.63 280.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M492.63 280.66L494.38 285.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M494.38 285.74L495.75 290.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M495.75 290.93L496.74 296.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M496.74 296.21L497.34 301.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M497.34 301.55L497.54 306.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.18 306.92L460.98 310.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.98 310.72L460.39 314.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.39 314.48L459.4 318.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.4 318.16L458.04 321.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.04 321.71L456.31 325.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M456.31 325.1L454.24 328.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.24 328.29L451.84 331.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.84 331.25L449.15 333.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.15 333.94L446.2 336.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.2 336.33L443 338.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443 338.4L439.61 340.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.61 340.13L436.06 341.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.06 341.5L432.39 342.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.39 342.48L428.63 343.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.63 343.08L424.83 343.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.83 343.28L421.03 343.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.03 343.08L417.27 342.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.27 342.48L413.59 341.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.59 341.5L410.04 340.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.04 340.13L406.65 338.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.65 338.4L403.46 336.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.46 336.33L400.5 333.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.5 333.94L397.81 331.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.81 331.25L395.42 328.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.42 328.29L393.35 325.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.35 325.1L391.62 321.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.62 321.71L390.25 318.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.25 318.16L389.27 314.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.27 314.48L388.67 310.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.67 310.72L388.47 306.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.47 306.92L388.67 303.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.67 303.12L389.27 299.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.27 299.36L390.25 295.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.25 295.69L391.62 292.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.62 292.14L393.35 288.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.35 288.74L395.42 285.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.42 285.55L397.81 282.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.81 282.6L400.5 279.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.5 279.91L403.46 277.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.46 277.51L406.65 275.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.65 275.44L410.04 273.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.04 273.71L413.59 272.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.59 272.35L417.27 271.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.27 271.36L421.03 270.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.03 270.77L424.83 270.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.83 270.57L428.63 270.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.63 270.77L432.39 271.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.39 271.36L436.06 272.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.06 272.35L439.61 273.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.61 273.71L443 275.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443 275.44L446.2 277.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.2 277.51L449.15 279.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.15 279.91L451.84 282.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.84 282.6L454.24 285.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.24 285.55L456.31 288.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M456.31 288.74L458.04 292.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.04 292.14L459.4 295.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.4 295.69L460.39 299.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.39 299.36L460.98 303.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.98 303.12L461.18 306.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_02_05;

    