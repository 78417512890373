
    const Svg1_05_08 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="210.64 221.89 409.44000000000005 143.14" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M615.08 360.03L481.93 226.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M215.64 360.03L481.93 360.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.93 360.03L481.93 226.89Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.93 226.89L215.64 226.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M215.64 226.89L215.64 360.03Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.93 360.03L615.08 360.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M273.88 326.77L273.88 260.2Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M273.88 260.2L299.24 326.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M299.24 326.77L324.6 260.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.6 260.2L324.6 326.77Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.98 260.2L362.64 263.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.64 263.37L356.3 269.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.3 269.71L353.13 276.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.13 276.05L349.96 285.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.96 285.56L349.96 301.41Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.96 301.41L353.13 310.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.13 310.92L356.3 317.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.3 317.26L362.64 323.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.64 323.6L368.98 326.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.98 326.77L381.66 326.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.66 326.77L388.01 323.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.01 323.6L394.35 317.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.35 317.26L397.52 310.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.52 310.92L400.69 301.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.69 301.41L400.69 285.56Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.69 285.56L397.52 276.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.52 276.05L394.35 269.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.35 269.71L388.01 263.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.01 263.37L381.66 260.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.66 260.2L368.98 260.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_05_08;

    