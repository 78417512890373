import { Outlet, RouteObject } from 'react-router-dom';

import Http403 from '../../screens/http/403';
import Restricted from './restricted';
import { RestrictedRouteObject } from './interfaces';

export const fromRestrictedRouteObjects = (routes: RestrictedRouteObject[]): RouteObject[] => {
    return routes.map(({ permissions, ...rest }) => {
        if (!permissions) {
            return rest;
        }

        return {
            ...rest,
            element: (
                <Restricted permissions={permissions} renderUnauthorized={<Http403 />}>
                    <>{rest.element ?? <Outlet />}</>
                </Restricted>
            ),
        };
    });
};
