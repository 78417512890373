import { TopCenterBox, WideButton } from '../../mui/styled-mui';
import { UserPermissions, usePermissions } from '../../restricted';
import { useDeferredValue, useEffect, useState } from 'react';

import { FlexColumn } from '../../misc/flex';
import Grid from '@mui/material/Grid';
import { ITabSelectorProps } from './interface';
import List from './list';
import Map from './map';
import { NativeEventInfo } from 'devextreme/events';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextBox from 'devextreme-react/text-box';
import { TextBoxInstance } from 'devextreme/ui/text_box';
import { useEnhed } from '../../../shared/hooks/use-enhed';
import useEnhedWhitelist from '../../../shared/hooks/use-enhed-whitelist';
import useHistoryNavigate from '../../../shared/hooks/extended-hooks/use-history-navigate';
import useLocalStorageState from '../../../shared/hooks/extended-hooks/use-local-storage-state';
import useQRScanner from '../../../shared/hooks/use-qr-scanner/use-qr-scanner';
import { ItemClickEvent } from 'devextreme/ui/list';

const TabSelector = ({ index, query, alternativeOnClick }: ITabSelectorProps) => {
    switch (index) {
        case 0:
            return (
                <TopCenterBox style={{ paddingLeft: '24px', paddingRight: '24px', height: '100%' }}>
                    <List query={query} alternativeOnClick={alternativeOnClick} />
                </TopCenterBox>
            );
        case 1:
            return <Map query={query} />;
        default:
            return <></>;
    }
};

const ExtendedEnhedList = ({
    alternativeOnClick,
    listOnly,
}: {
    alternativeOnClick?: (e: ItemClickEvent) => {};
    listOnly?: boolean;
}) => {
    const [index, setIndex] = useLocalStorageState<number>('enhedListTab', 0);
    const [query, setQuery] = useState('');
    const defferedQuery = useDeferredValue(query);
    const whitelisted = useEnhedWhitelist();
    const { updateId } = useEnhed();
    const { goBack } = useHistoryNavigate();
    const { hasPermissions } = usePermissions();

    const [scanQR] = useQRScanner({
        PopupProps: {
            fullScreen: false,
        },
    });

    const handleInput = (e: NativeEventInfo<TextBoxInstance, UIEvent>) =>
        setQuery((e.event?.currentTarget as HTMLInputElement).value ?? '');

    const handleChange = (_: React.SyntheticEvent, newIndex: number) => {
        setIndex(newIndex);
    };

    const handleBack = () => {
        goBack();
    };

    const handleNoEnhed = () => {
        updateId(0);
    };

    const buttonXs = whitelisted ? 4 : 6;

    useEffect(() => {
        if (hasPermissions(UserPermissions.QR_Vaelg) && !hasPermissions(UserPermissions.Admin)) {
            scanQR();
        }
    }, [scanQR, hasPermissions]);

    return (
        <>
            {/* <Container sx={{ paddingTop: '16px', height: '100%' }}> */}
            <FlexColumn
                scroll
                header={
                    <>
                        <Grid container spacing={1} sx={{ paddingTop: '12px', paddingInline: '24px' }}>
                            <Grid item xs={12} md>
                                <TextBox
                                    mode='search'
                                    placeholder='Vælg en enhed'
                                    showClearButton
                                    text={query}
                                    onInput={handleInput}
                                />
                            </Grid>
                            {whitelisted && (
                                <Grid item xs={buttonXs} md='auto'>
                                    <WideButton onClick={handleNoEnhed} sx={{ borderRadius: '4px', fontSize: 13 }}>
                                        Alle
                                    </WideButton>
                                </Grid>
                            )}
                            <Grid item xs={buttonXs} md='auto'>
                                <WideButton onClick={scanQR} sx={{ borderRadius: '4px', fontSize: 13 }}>
                                    Scan QR
                                </WideButton>
                            </Grid>
                            <Grid item xs={buttonXs} md='auto'>
                                <WideButton onClick={handleBack} sx={{ borderRadius: '4px', fontSize: 13 }}>
                                    Tilbage
                                </WideButton>
                            </Grid>
                        </Grid>
                        {listOnly || (
                            //     <TabSelector index={0} query={defferedQuery} alternativeOnClick={alternativeOnClick} />
                            // ) : (
                            <Tabs
                                value={index!}
                                onChange={handleChange}
                                textColor='inherit'
                                variant='fullWidth'
                                style={{ paddingInline: '24px' }}
                            >
                                <Tab label='Liste' />
                                <Tab label='Kort' disabled={!hasPermissions(UserPermissions.Map)} />
                            </Tabs>
                        )}
                    </>
                }
            >
                <TabSelector
                    index={listOnly ? 0 : index!}
                    query={defferedQuery}
                    alternativeOnClick={alternativeOnClick}
                />
            </FlexColumn>
            {/* </Container> */}
        </>
    );
};

export default ExtendedEnhedList;
