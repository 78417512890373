
    const Svg2_01_02 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="323.64 239.88 87.5 103.0" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M406.14 322.38L406.14 310.75Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.14 303L406.14 287.5Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.14 279.75L406.14 264.25Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.14 256.5L406.14 244.88Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.14 244.88L394.51 244.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.76 244.88L371.26 244.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.51 244.88L348.01 244.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.26 244.88L328.64 244.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.64 244.88L328.64 256.5Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.64 264.25L328.64 279.75Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.64 287.5L328.64 303Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.64 310.75L328.64 322.38Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.64 322.38L340.26 322.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.01 322.38L363.51 322.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.26 322.38L386.76 322.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.51 322.38L406.14 322.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.64 337.88L340.26 337.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.01 337.88L363.51 337.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.26 337.88L386.76 337.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.51 337.88L406.14 337.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_01_02;

    