
    const Svg1_02_02 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="343.03 263.31 160.17000000000002 101.00999999999999" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M470.89 313.81L484.55 313.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.89 322.91L484.55 336.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.89 304.71L484.55 291.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.03 359.32L439.04 359.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.04 359.32L439.04 268.31Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.04 268.31L348.03 268.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.03 268.31L348.03 359.32Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.04 327.46L461.79 327.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.79 327.46L461.79 300.16Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.79 300.16L439.04 300.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.04 300.16L439.04 327.46Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 475.45 336.57 A 9.101 9.101 0 0 0 493.65 336.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 493.65 336.57 A 9.101 9.101 0 0 0 493.65 318.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 498.2 322.91 A 9.101 9.101 0 0 0 498.2 304.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 493.65 309.26 A 9.101 9.101 0 0 0 493.65 291.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 493.65 291.06 A 9.101 9.101 0 0 0 475.45 291.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_02_02;

    