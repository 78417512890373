
    const Svg1_01_19 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="257.57 216.39 241.14 167.79000000000002" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M428.22 244.35L428.22 227.98Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.48 260.72L379.11 244.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.97 260.72L477.34 244.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.48 293.47L395.68 289.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.68 289.87L396.27 286.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.27 286.31L397.25 282.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.25 282.84L398.61 279.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.61 279.49L400.33 276.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.33 276.32L402.38 273.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.38 273.36L404.75 270.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.75 270.64L407.41 268.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.41 268.19L410.31 266.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.31 266.06L413.44 264.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.44 264.25L416.74 262.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.74 262.8L420.18 261.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.18 261.73L423.73 261.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.73 261.03L427.32 260.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.32 260.74L430.93 260.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M430.93 260.84L434.5 261.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.5 261.33L438 262.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438 262.22L441.38 263.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.38 263.48L444.6 265.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.6 265.11L447.62 267.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.62 267.08L450.4 269.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.4 269.38L452.92 271.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.92 271.96L455.13 274.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.13 274.81L457.02 277.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.02 277.88L458.56 281.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.56 281.15L459.73 284.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.73 284.56L460.52 288.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.52 288.08L460.92 291.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.92 291.66L460.92 295.27Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.92 295.27L460.52 298.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.52 298.86L459.73 302.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.73 302.38L458.56 305.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.56 305.79L457.02 309.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.02 309.05L455.13 312.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.13 312.13L452.92 314.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.92 314.97L450.4 317.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.4 317.56L447.62 319.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.62 319.85L444.6 321.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.6 321.83L441.38 323.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.38 323.45L438 324.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438 324.72L434.5 325.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.5 325.61L430.93 326.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M430.93 326.1L427.32 326.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.32 326.2L423.73 325.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.73 325.9L420.18 325.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.18 325.21L416.74 324.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.74 324.13L413.44 322.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.44 322.68L410.31 320.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.31 320.88L407.41 318.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.41 318.74L404.75 316.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.75 316.3L402.38 313.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.38 313.58L400.33 310.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.33 310.62L398.61 307.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.61 307.44L397.25 304.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.25 304.1L396.27 300.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.27 300.63L395.68 297.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.68 297.07L395.48 293.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.22 247.63L428.22 227.98Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.48 260.72L375.83 241.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.38 293.47L362.73 293.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.48 326.21L375.83 345.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.97 326.21L480.61 345.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.97 260.72L480.61 241.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.22 339.31L428.22 358.96Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M474.07 293.47L493.71 293.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 362.73 247.63 A 14.408 14.408 0 0 0 349.64 260.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 362.73 234.5 A 27.506 27.506 0 0 0 336.51 260.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 362.73 221.39 A 40.604 40.604 0 0 0 323.4 260.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M262.57 379.18L297.5 326.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M262.57 326.79L297.5 379.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M312.47 379.18L347.4 326.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M312.47 326.79L347.4 379.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_19;

    