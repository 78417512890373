
    const Svg2_04_09 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="306.05 256.66 203.97999999999996 106.98999999999995" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M311.05 261.66L311.05 358.65Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M311.05 358.65L505.03 358.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M505.03 358.65L505.03 261.66Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M505.03 261.66L311.05 261.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M334.64 334.4L334.64 285.91Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M334.64 285.91L355.42 285.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.42 285.91L362.35 288.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.35 288.22L364.66 290.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.66 290.53L366.97 295.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.97 295.15L366.97 299.77Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.97 299.77L364.66 304.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.66 304.38L362.35 306.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.35 306.69L355.42 309" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M334.64 309L355.42 309" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.42 309L362.35 311.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.35 311.31L364.66 313.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.66 313.62L366.97 318.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.97 318.24L366.97 325.17Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.97 325.17L364.66 329.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.66 329.79L362.35 332.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.35 332.09L355.42 334.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.42 334.4L334.64 334.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.13 334.4L383.13 285.91Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.13 285.91L401.6 334.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.6 334.4L420.08 285.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.08 285.91L420.08 334.4Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438.55 334.4L438.55 285.91Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438.55 285.91L459.33 285.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.33 285.91L466.26 288.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.26 288.22L468.57 290.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M468.57 290.53L470.88 295.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.88 295.15L470.88 302.08Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.88 302.08L468.57 306.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M468.57 306.69L466.26 309" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.26 309L459.33 311.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.33 311.31L438.55 311.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_04_09;

    