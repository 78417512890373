
    const Svg2_01_11 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="242.88 204.42 300.62 173.73" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M247.88 209.42L247.88 373.15Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.61 373.15L411.61 209.42Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M247.88 373.15L411.61 373.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.61 209.42L247.88 209.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M297.94 250.36L297.94 332.22Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M297.94 332.22L344.72 332.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.61 291.29L423.89 291.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.89 291.29L424.09 288.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.09 288.93L424.67 286.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.67 286.64L425.62 284.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.62 284.47L426.91 282.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.91 282.49L428.51 280.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.51 280.75L430.38 279.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M430.38 279.29L432.46 278.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.46 278.17L434.7 277.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.7 277.4L437.03 277.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.03 277.01L439.4 277.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.4 277.01L441.73 277.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.73 277.4L443.97 278.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.97 278.17L446.05 279.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.05 279.29L447.92 280.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.92 280.75L449.52 282.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.52 282.49L450.82 284.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.82 284.47L451.77 286.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.77 286.64L452.35 288.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.35 288.93L452.54 291.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.54 291.29L452.54 291.29Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.54 291.29L452.74 293.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.74 293.65L453.32 295.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M453.32 295.94L454.27 298.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.27 298.11L455.56 300.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.56 300.09L457.17 301.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.17 301.83L459.03 303.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.03 303.28L461.11 304.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.11 304.41L463.35 305.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M463.35 305.18L465.69 305.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M465.69 305.56L468.05 305.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M468.05 305.56L470.39 305.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.39 305.18L472.62 304.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M472.62 304.41L474.7 303.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M474.7 303.28L476.57 301.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M476.57 301.83L478.17 300.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.17 300.09L479.47 298.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M479.47 298.11L480.42 295.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M480.42 295.94L481 293.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481 293.65L481.2 291.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.2 291.29L481.39 288.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.39 288.93L481.97 286.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.97 286.64L482.92 284.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M482.92 284.47L484.22 282.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M484.22 282.49L485.82 280.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M485.82 280.75L487.69 279.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M487.69 279.29L489.77 278.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M489.77 278.17L492 277.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M492 277.4L494.34 277.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M494.34 277.01L496.7 277.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M496.7 277.01L499.04 277.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M499.04 277.4L501.28 278.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M501.28 278.17L503.36 279.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M503.36 279.29L505.22 280.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M505.22 280.75L506.83 282.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M506.83 282.49L508.12 284.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M508.12 284.47L509.07 286.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M509.07 286.64L509.65 288.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M509.65 288.93L509.85 291.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M509.85 291.29L509.85 291.29Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M509.85 291.29L510.04 293.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M510.04 293.65L510.62 295.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M510.62 295.94L511.57 298.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M511.57 298.11L512.87 300.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M512.87 300.09L514.47 301.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M514.47 301.83L516.34 303.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M516.34 303.28L518.42 304.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M518.42 304.41L520.66 305.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M520.66 305.18L522.99 305.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M522.99 305.56L525.36 305.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M525.36 305.56L527.69 305.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M527.69 305.18L529.93 304.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M529.93 304.41L532.01 303.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M532.01 303.28L533.88 301.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M533.88 301.83L535.48 300.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M535.48 300.09L536.77 298.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M536.77 298.11L537.72 295.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M537.72 295.94L538.3 293.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M538.3 293.65L538.5 291.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_01_11;

    