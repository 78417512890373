import { ExtendedEvent } from './ControlHandlerUtils';
import { IDictionary } from '../../../shared/utils/types';
import __ from '../../../shared/utils/lodash-expansions';

type TListeners<T extends ExtendedEvent> = IDictionary<Array<IHandler<T>>>;
interface IHandler<T extends ExtendedEvent = ExtendedEvent> {
    callback: (e: T) => unknown;
    onlyOnce: boolean;
}
interface IOptions {
    onlyOnce?: boolean;
}
const defaultOptions: IOptions = {
    onlyOnce: false,
};

export interface INotifyPropertyChanged<T extends ExtendedEvent = ExtendedEvent> {
    listeners: TListeners<T>;
    addCustomEventListener(type: string, callback: (e: ExtendedEvent) => void, options?: IOptions): void;
    removeCustomEventListener(type: string, callback: (e: ExtendedEvent) => void): void;
    dispatchCustomEvent(event: ExtendedEvent): void;
}

export class NotifyPropertyChanged<T extends ExtendedEvent = ExtendedEvent> implements INotifyPropertyChanged<T> {
    listeners: TListeners<T>;

    addCustomEventListener(type: string, callback: (e: T) => void, options?: IOptions) {
        const opt = __.deepMerge(defaultOptions, options);

        if (!Object.keys(this.listeners).includes(type)) {
            this.listeners[type] = [];
        }

        const handler: IHandler<T> = {
            callback,
            onlyOnce: opt.onlyOnce!,
        };

        this.listeners[type].unshift(handler);
    }
    removeCustomEventListener(type: string, callback: (e: T) => void) {
        if (!(type in this.listeners)) {
            return;
        }
        const stack = this.listeners[type];
        for (let i = 0, l = stack.length; i < l; i++) {
            if (stack[i].callback === callback) {
                return stack.splice(i, 1)[0];
            }
        }
    }

    dispatchCustomEvent(event: T) {
        // Findes eventtypen?
        if (!(event.type in this.listeners)) {
            return true;
        }

        // Find events af type
        const stack = this.listeners[event.type]; /*.slice()*/

        // Call callbacks og gem reference til onlyOnce events
        const toRemove: IHandler<T>[] = [];
        for (let i = 0, l = stack.length; i < l; i++) {
            const handler = stack[i];
            handler.callback.call(this, event);

            if (handler.onlyOnce) toRemove.unshift(handler);
        }

        // Slet brugte callbacks
        for (const handler of toRemove) {
            this.removeCustomEventListener(event.type, handler.callback);
        }

        // Slet onlyOnce listeners

        return !event.defaultPrevented;
    }

    constructor() {
        this.listeners = {};
    }
}
