import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux-base-hooks';

import { ControlNames } from './interfaces';
import { RecursiveRequired } from '../../shared/utils/types';
import __ from '../../shared/utils/lodash-expansions';
import { useCallback } from 'react';

interface IState {
    setup: {
        controls: RecursiveRequired<IControlSetup>;
    };
}

interface IControlSetup {
    [ControlNames.ReportDriftError]?: ISingleControlSetup & {
        isPlacing?: boolean;
    };
}

interface ISingleControlSetup {
    isActive?: boolean;
}

const initialState: IState = {
    setup: {
        controls: {
            [ControlNames.ReportDriftError]: {
                isActive: false,
                isPlacing: false,
            },
        },
    },
};

const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        setControlSetup(state: IState, action: PayloadAction<IControlSetup>) {
            const controlsSetup = __.deepMerge(state.setup.controls, action.payload);
            return { ...state, setup: { ...state.setup, controls: controlsSetup } };
        },
    },
});

export const mapReducer = mapSlice.reducer;

export const useMapSetup = () => {
    const setup = useAppSelector((s) => s.map.setup);
    // const setup = initialState.setup;
    const dispatch = useAppDispatch();
    const setControlSetup = useCallback(
        (controlSetup: IControlSetup) => dispatch(mapSlice.actions.setControlSetup(controlSetup)),
        [dispatch]
    );
    return { setup, setControlSetup };
};
