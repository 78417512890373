import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import { Fragment } from 'react';
import GetBtn from './form-buttons';
import Grid from '@mui/material/Grid/Grid';
import { GridItemCenter } from '../../mui/extended-mui';
import { IAutoFormProps } from './interfaces-and-defaults';

//#region Submitter

export interface ISubmitterProps {
    allValid: boolean;
    handleHelp: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    handleCancel: VoidFunction;
    buttons?: NonNullable<IAutoFormProps['buttons']>;
}

export const Submitter = ({
    allValid,
    handleHelp,
    handleCancel,
    buttons = ['save', 'cancel', 'help', 'submit'],
}: ISubmitterProps) => {
    return (
        <Box>
            <Grid container columnSpacing={1} rowSpacing={1} sx={{ pb: 1, pt: 2 }}>
                {buttons.map((btn) => (
                    <Fragment key={btn}>{GetBtn({ btn, props: { allValid, handleHelp, handleCancel } })}</Fragment>
                ))}
            </Grid>
        </Box>
    );
};

//#endregion Submitter
//#region EditToggler

interface IEditTogglerProps {
    allowEditing: boolean;
    setEditing(edit: boolean): void;
    handleCancel(): void;
}

/** Header / Footer for when edit === false */
export const EditToggler = ({ allowEditing, setEditing: setEdit, handleCancel }: IEditTogglerProps) => {
    return (
        <Box>
            <Grid container spacing={2} sx={{ pb: 3, pt: 2 }}>
                {/* Submit */}
                <GridItemCenter xs={6}>
                    <Button
                        variant='contained'
                        onClick={handleCancel}
                        sx={(theme) => ({
                            transition: theme.transitions.create('all', {
                                easing: theme.transitions.easing.easeInOut,
                                duration: '1s',
                            }),
                            width: '100%',
                        })}
                    >
                        Tilbage
                    </Button>
                </GridItemCenter>

                {/* Cancel */}
                <GridItemCenter xs={6}>
                    <Button
                        variant='outlined'
                        sx={(theme) => ({
                            transition: theme.transitions.create('all', {
                                easing: theme.transitions.easing.easeInOut,
                                duration: '1s',
                            }),
                            width: '100%',
                        })}
                        onClick={() => setEdit(true)}
                        disabled={!allowEditing}
                    >
                        Rediger
                    </Button>
                </GridItemCenter>
            </Grid>
        </Box>
    );
};

//#region EditToggler
