const SvgElSikker = () => (
    <svg xmlns='http://www.w3.org/2000/svg' xmlSpace='preserve' width={30.981} height={15.125}>
        <path
            d='M-70.148 41.568v-7.563h30.981V49.13h-30.98zm7.928 1.951c1.57-.268 3.502-.488 4.294-.488h1.44v-1.774l-2.257.17a48.596 48.596 0 0 0-4.45.584l-2.192.415-.085.44c-.084.44.094 1.141.291 1.141.058 0 1.39-.22 2.96-.488m18.567-.14c.086-.345.06-.716-.057-.825-.308-.285-4.248-.954-6.704-1.139l-2.1-.158-.134.703c-.074.387-.084.786-.02.887.062.102.848.195 1.745.208.898.013 2.675.216 3.95.452 3.19.591 3.139.593 3.32-.127m-10.207-1.498c-.367-.055-1.025-.056-1.464-.002-.438.054-.138.1.667.101.805.002 1.164-.043.797-.099m0-.487c-.367-.056-1.025-.057-1.464-.003-.438.054-.138.1.667.101.805.002 1.164-.043.797-.099m-1.565-.556c-.656-.265-.967-.734-.487-.734.198 0 .416.137.483.305.074.182.131.133.143-.122.03-.67.507-.499.507.183v.61l.549-.028.549-.029-.427-.111c-.516-.135-.576-1.052-.069-1.052h.358l-.15.427-.15.427.328-.305c.422-.391.915-.402.524-.012-.372.372-.375.683-.008.683.157 0 .213-.072.126-.159-.087-.087.031-.3.264-.471l.423-.313-.267-.271c-.602-.612-3.758-.408-3.758.242 0 .447.588.954 1.098.947l.488-.006zm1.067-1.954c-.02-.268-.11-.487-.2-.487-.09 0-.222.22-.292.487l-.127.488h.327c.18 0 .312-.22.292-.488'
            style={{
                fill: '{{replacement-value-color}}',
                fillOpacity: 1,
            }}
            transform='translate(70.148 -34.005)'
        />
    </svg>
);
export default SvgElSikker;
