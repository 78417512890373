
    const Svg2_01_01 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="349.52 255.89 91.0 91.0" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M435.52 341.89L435.52 260.89Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.52 260.89L354.52 341.89Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.52 341.89L435.52 341.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.52 260.89L354.52 260.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_01_01;

    