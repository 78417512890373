const SvgRogalarm = () => (
    <svg xmlns='http://www.w3.org/2000/svg' xmlSpace='preserve' width={26.277} height={25.962}>
        <path
            d='M-26.75 19.335c-1.363-.625-1.878-1.194-2.637-2.909l-.594-1.343.138-1.722c.08-.988.413-2.288.78-3.047l.643-1.323.224 1.128c.123.62.512 1.399.863 1.729l.639.6.183-1.977c.204-2.189.8-3.756 2.131-5.611 1.04-1.447 1.341-1.536 1.341-.398 0 1.196.563 2.385 2.308 4.875.96 1.37 1.651 2.698 1.848 3.55l.314 1.36-.437 1.502c-.484 1.666-1.72 3.139-3.13 3.732-1.235.518-3.311.453-4.615-.146m8.288-2.439c.103-.416.268-1.065.368-1.443l.18-.688 1.837-.081c2.353-.104 2.408-.606.067-.606-1.879 0-1.908-.016-2.174-1.13l-.17-.716 1.792-.168c1.754-.164 4.087-1.089 5.141-2.038l.482-.433v3.66H-6.874l.167-.435c.306-.797.184-9.953-.143-10.669-.425-.932-.088-2.051.73-2.424l.68-.31.68.31c.375.17.765.647.868 1.06l.188.75-.455.794-.456.794v11.199l-.611.428-.611.428h-5.102v.626c0 .344-.193.901-.428 1.237l-.429.612h-6.853zm-.331-6.15c-.264-.51-.941-1.547-1.506-2.304-.564-.757-1.236-1.792-1.492-2.3l-.467-.922.09-1.759c.05-.967-.005-1.758-.125-1.758-.119 0-.977.74-1.907 1.645s-1.76 1.575-1.846 1.49c-.085-.086-.148-1.105-.14-2.264l.016-2.108.579-1.286c.814-1.808 2.618-3.658 4.438-4.55l1.552-.76 2.2.01 2.2.012 1.556.752c1.869.903 3.341 2.459 4.306 4.552L-8.626.74v3.85l-.703 1.525c-.387.839-1.104 1.98-1.593 2.538-1.141 1.3-3.846 2.617-5.836 2.842l-1.556.177zm2.472-7c.548-.548.559-1.87.019-2.318-.569-.472-1.749-.43-2.258.08-.51.509-.552 1.689-.08 2.257.449.54 1.77.53 2.319-.019'
            style={{
                fill: '{{replacement-value-color}}',
                fillOpacity: 1,
            }}
            transform='translate(29.98 6.13)'
        />
    </svg>
);
export default SvgRogalarm;
