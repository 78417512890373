import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { AnyAction } from '@reduxjs/toolkit';
import Api from '../networking/api';
import { ISearchEnhed } from '../../components/data-getters/enhed-lookup';
import config from '../../config';
import { useDispatch } from 'react-redux';

export interface ExtendedISearchEnhed extends ISearchEnhed {
    text?: string;
}

export const driftApi = createApi({
    reducerPath: 'driftApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.SERVER_URL + '/Drift',
        prepareHeaders: (headers) => {
            headers.append('Authorization', `Bearer ${Api.token}`);
            return headers;
        },
    }),
    endpoints: (builder) => ({
        base: builder.query<unknown, string>({
            query: (path) => path,
        }),
        getEnhed: builder.query<ExtendedISearchEnhed[], string>({
            query: (teamOnly) => `Enhed?teamonly=${teamOnly}`,
        }),
    }),
});

export const { useGetEnhedQuery } = driftApi;

const useEnhedList = () => {
    const dispatch = useDispatch();
    const enhedList = useGetEnhedQuery('false').data ?? [];
    const addEnhed = (enhed: ExtendedISearchEnhed) => {
        if (enhedList.find((e) => e.id === enhed.id)) return;
        dispatch(
            driftApi.util.updateQueryData('getEnhed', 'false', (prev) => {
                prev?.push(enhed);
            }) as unknown as AnyAction
        );
    };
    return { enhedList, addEnhed };
};

export default useEnhedList;
