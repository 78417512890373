
    const Svg4_05_04 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="282.04 219.39 287.32 120.93" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M287.04 224.39L508.9 224.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M508.9 224.39L508.9 335.32Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M508.9 335.32L287.04 335.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M287.04 335.32L287.04 224.39Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.21 260.05L348.92 254.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.92 254.76L341 252.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341 252.12L330.43 252.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.43 252.12L322.51 254.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.51 254.76L317.23 260.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M317.23 260.05L317.23 265.33Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M317.23 265.33L319.87 270.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319.87 270.61L322.51 273.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.51 273.25L327.79 275.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M327.79 275.89L343.64 281.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.64 281.18L348.92 283.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.92 283.82L351.56 286.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M351.56 286.46L354.21 291.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.21 291.74L354.21 299.66Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.21 299.66L348.92 304.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.92 304.95L341 307.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341 307.59L330.43 307.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.43 307.59L322.51 304.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.51 304.95L317.23 299.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.82 260.05L393.82 307.59Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.05 283.82L417.59 283.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438.72 307.59L438.72 252.12Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438.72 252.12L459.85 307.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.85 307.59L480.98 252.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M480.98 252.12L480.98 307.59Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M508.9 252.12L564.36 252.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M564.36 252.12L536.63 238.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M564.36 252.12L536.63 265.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M564.36 307.59L508.9 307.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M508.9 307.59L536.63 293.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M508.9 307.59L536.63 321.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg4_05_04;

    