
    const SvgXx_06_18 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="366.84 267.59 83.44 70.48000000000002" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M397.76 333.07L419.36 333.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.8 320.11L432.32 320.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.84 307.15L445.28 307.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.56 307.15L408.56 272.59Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_06_18;

    