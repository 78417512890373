import { useCallback, useEffect, useState } from 'react';

import defaultTheme from '../utils/theme';

const breakpoints = defaultTheme.breakpoints.values;

type BreakpointString = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
interface UseBreakpointReturntype {
    xs: boolean;
    sm: boolean;
    md: boolean;
    lg: boolean;
    xl: boolean;
    current: BreakpointString;
}

const isBreakpoint = (breakpointSize: number) => {
    return window.innerWidth >= breakpointSize;
};

export const getCurrentBreakpoint = (): BreakpointString => {
    const s = window.innerWidth;
    if (s >= 1536) return 'xl';
    if (s >= 1200) return 'lg';
    if (s >= 900) return 'md';
    if (s >= 600) return 'sm';
    return 'xs';
};

const useBreakpoints = (): UseBreakpointReturntype => {
    const [xs, setXs] = useState(() => isBreakpoint(breakpoints.xs));
    const [sm, setSm] = useState(() => isBreakpoint(breakpoints.sm));
    const [md, setMd] = useState(() => isBreakpoint(breakpoints.md));
    const [lg, setLg] = useState(() => isBreakpoint(breakpoints.lg));
    const [xl, setXl] = useState(() => isBreakpoint(breakpoints.xl));
    const [current, setCurrent] = useState<BreakpointString>(() => getCurrentBreakpoint());

    const listener = useCallback(() => {
        setXs(isBreakpoint(breakpoints.xs));
        setSm(isBreakpoint(breakpoints.sm));
        setMd(isBreakpoint(breakpoints.md));
        setLg(isBreakpoint(breakpoints.lg));
        setXl(isBreakpoint(breakpoints.xl));
        setCurrent(getCurrentBreakpoint());
    }, []);

    useEffect(() => {
        window.addEventListener('resize', listener);
        return () => {
            window.removeEventListener('resize', listener);
        };
    }, [listener]);

    return { xs, sm, md, lg, xl, current };
};

export default useBreakpoints;
