import { ExtendedEvent } from './ControlHandlerUtils';
import { NotifyPropertyChanged } from './NotifyPropertyChanged';

export class Notifyer extends NotifyPropertyChanged {
    private _enhLoadDone: boolean;

    //#region Get Set

    get enhLoadDone() {
        return this._enhLoadDone;
    }

    set enhLoadDone(value: boolean) {
        const event = document.createEvent('Event');
        event.initEvent('change', false, true);
        this._enhLoadDone = value;

        this.dispatchCustomEvent(event as ExtendedEvent);
    }

    //#endregion

    constructor() {
        super();
        this._enhLoadDone = false;
    }
}
