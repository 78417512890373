
    const Svg2_02_02 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="347.26 195.18 173.72000000000003 173.71999999999997" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M352.26 363.9L515.98 363.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M515.98 363.9L515.98 200.18Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M515.98 200.18L352.26 200.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.26 200.18L352.26 363.9Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.26 363.9L393.19 363.9 352.26 200.18 352.26 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.19 363.9L393.19 200.18 352.26 200.18 393.19 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.19 363.9L393.21 363.9 393.19 282.04 393.19 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.21 363.9L393.21 283.31 393.19 282.04 393.21 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.19 282.04L393.21 280.77 393.19 200.18 393.19 282.04Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.21 280.77L393.21 200.18 393.19 200.18 393.21 280.77Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.21 363.9L393.27 363.9 393.21 283.31 393.21 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.27 363.9L393.27 284.58 393.21 283.31 393.27 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.21 280.77L393.27 279.5 393.21 200.18 393.21 280.77Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.27 279.5L393.27 200.18 393.21 200.18 393.27 279.5Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.27 363.9L393.37 363.9 393.27 284.58 393.27 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.37 363.9L393.37 285.85 393.27 284.58 393.37 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.27 279.5L393.37 278.23 393.27 200.18 393.27 279.5Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.37 278.23L393.37 200.18 393.27 200.18 393.37 278.23Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.37 363.9L393.5 363.9 393.37 285.85 393.37 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.5 363.9L393.5 287.12 393.37 285.85 393.5 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.37 278.23L393.5 276.96 393.37 200.18 393.37 278.23Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.5 276.96L393.5 200.18 393.37 200.18 393.5 276.96Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.5 363.9L393.68 363.9 393.5 287.12 393.5 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.68 363.9L393.68 288.38 393.5 287.12 393.68 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.5 276.96L393.68 275.7 393.5 200.18 393.5 276.96Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.68 275.7L393.68 200.18 393.5 200.18 393.68 275.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.68 363.9L393.9 363.9 393.68 288.38 393.68 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.9 363.9L393.9 289.63 393.68 288.38 393.9 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.68 275.7L393.9 274.45 393.68 200.18 393.68 275.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.9 274.45L393.9 200.18 393.68 200.18 393.9 274.45Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.9 363.9L394.15 363.9 393.9 289.63 393.9 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M394.15 363.9L394.15 290.88 393.9 289.63 394.15 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.9 274.45L394.15 273.2 393.9 200.18 393.9 274.45Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M394.15 273.2L394.15 200.18 393.9 200.18 394.15 273.2Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M394.15 363.9L394.45 363.9 394.15 290.88 394.15 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M394.45 363.9L394.45 292.12 394.15 290.88 394.45 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M394.15 273.2L394.45 271.96 394.15 200.18 394.15 273.2Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M394.45 271.96L394.45 200.18 394.15 200.18 394.45 271.96Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M394.45 363.9L394.78 363.9 394.45 292.12 394.45 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M394.78 363.9L394.78 293.35 394.45 292.12 394.78 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M394.45 271.96L394.78 270.73 394.45 200.18 394.45 271.96Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M394.78 270.73L394.78 200.18 394.45 200.18 394.78 270.73Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M394.78 363.9L395.15 363.9 394.78 293.35 394.78 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M395.15 363.9L395.15 294.57 394.78 293.35 395.15 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M394.78 270.73L395.15 269.51 394.78 200.18 394.78 270.73Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M395.15 269.51L395.15 200.18 394.78 200.18 395.15 269.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M395.15 363.9L395.56 363.9 395.15 294.57 395.15 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M395.56 363.9L395.56 295.77 395.15 294.57 395.56 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M395.15 269.51L395.56 268.31 395.15 200.18 395.15 269.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M395.56 268.31L395.56 200.18 395.15 200.18 395.56 268.31Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M395.56 363.9L396.01 363.9 395.56 295.77 395.56 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M396.01 363.9L396.01 296.97 395.56 295.77 396.01 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M395.56 268.31L396.01 267.11 395.56 200.18 395.56 268.31Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M396.01 267.11L396.01 200.18 395.56 200.18 396.01 267.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M396.01 363.9L396.49 363.9 396.01 296.97 396.01 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M396.49 363.9L396.49 298.14 396.01 296.97 396.49 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M396.01 267.11L396.49 265.94 396.01 200.18 396.01 267.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M396.49 265.94L396.49 200.18 396.01 200.18 396.49 265.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M396.49 363.9L397.01 363.9 396.49 298.14 396.49 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M397.01 363.9L397.01 299.31 396.49 298.14 397.01 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M396.49 265.94L397.01 264.77 396.49 200.18 396.49 265.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M397.01 264.77L397.01 200.18 396.49 200.18 397.01 264.77Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M397.01 363.9L397.56 363.9 397.01 299.31 397.01 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M397.56 363.9L397.56 300.45 397.01 299.31 397.56 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M397.01 264.77L397.56 263.63 397.01 200.18 397.01 264.77Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M397.56 263.63L397.56 200.18 397.01 200.18 397.56 263.63Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M397.56 363.9L398.15 363.9 397.56 300.45 397.56 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M398.15 363.9L398.15 301.58 397.56 300.45 398.15 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M397.56 263.63L398.15 262.5 397.56 200.18 397.56 263.63Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M398.15 262.5L398.15 200.18 397.56 200.18 398.15 262.5Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M398.15 363.9L398.78 363.9 398.15 301.58 398.15 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M398.78 363.9L398.78 302.69 398.15 301.58 398.78 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M398.15 262.5L398.78 261.39 398.15 200.18 398.15 262.5Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M398.78 261.39L398.78 200.18 398.15 200.18 398.78 261.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M398.78 363.9L399.44 363.9 398.78 302.69 398.78 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M399.44 363.9L399.44 303.78 398.78 302.69 399.44 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M398.78 261.39L399.44 260.3 398.78 200.18 398.78 261.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M399.44 260.3L399.44 200.18 398.78 200.18 399.44 260.3Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M399.44 363.9L400.13 363.9 399.44 303.78 399.44 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M400.13 363.9L400.13 304.85 399.44 303.78 400.13 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M399.44 260.3L400.13 259.23 399.44 200.18 399.44 260.3Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M400.13 259.23L400.13 200.18 399.44 200.18 400.13 259.23Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M400.13 363.9L400.86 363.9 400.13 304.85 400.13 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M400.86 363.9L400.86 305.89 400.13 304.85 400.86 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M400.13 259.23L400.86 258.19 400.13 200.18 400.13 259.23Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M400.86 258.19L400.86 200.18 400.13 200.18 400.86 258.19Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M400.86 363.9L401.61 363.9 400.86 305.89 400.86 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M401.61 363.9L401.61 306.92 400.86 305.89 401.61 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M400.86 258.19L401.61 257.17 400.86 200.18 400.86 258.19Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M401.61 257.17L401.61 200.18 400.86 200.18 401.61 257.17Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M401.61 363.9L402.4 363.9 401.61 306.92 401.61 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M402.4 363.9L402.4 307.91 401.61 306.92 402.4 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M401.61 257.17L402.4 256.17 401.61 200.18 401.61 257.17Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M402.4 256.17L402.4 200.18 401.61 200.18 402.4 256.17Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M402.4 363.9L403.22 363.9 402.4 307.91 402.4 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M403.22 363.9L403.22 308.89 402.4 307.91 403.22 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M402.4 256.17L403.22 255.19 402.4 200.18 402.4 256.17Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M403.22 255.19L403.22 200.18 402.4 200.18 403.22 255.19Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M403.22 363.9L404.07 363.9 403.22 308.89 403.22 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M404.07 363.9L404.07 309.84 403.22 308.89 404.07 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M403.22 255.19L404.07 254.24 403.22 200.18 403.22 255.19Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M404.07 254.24L404.07 200.18 403.22 200.18 404.07 254.24Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M404.07 363.9L404.95 363.9 404.07 309.84 404.07 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M404.95 363.9L404.95 310.76 404.07 309.84 404.95 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M404.07 254.24L404.95 253.32 404.07 200.18 404.07 254.24Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M404.95 253.32L404.95 200.18 404.07 200.18 404.95 253.32Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M404.95 363.9L405.86 363.9 404.95 310.76 404.95 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M405.86 363.9L405.86 311.65 404.95 310.76 405.86 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M404.95 253.32L405.86 252.43 404.95 200.18 404.95 253.32Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M405.86 252.43L405.86 200.18 404.95 200.18 405.86 252.43Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M405.86 363.9L406.79 363.9 405.86 311.65 405.86 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M406.79 363.9L406.79 312.51 405.86 311.65 406.79 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M405.86 252.43L406.79 251.57 405.86 200.18 405.86 252.43Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M406.79 251.57L406.79 200.18 405.86 200.18 406.79 251.57Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M406.79 363.9L407.76 363.9 406.79 312.51 406.79 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M407.76 363.9L407.76 313.35 406.79 312.51 407.76 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M406.79 251.57L407.76 250.73 406.79 200.18 406.79 251.57Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M407.76 250.73L407.76 200.18 406.79 200.18 407.76 250.73Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M407.76 363.9L408.74 363.9 407.76 313.35 407.76 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M408.74 363.9L408.74 314.15 407.76 313.35 408.74 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M407.76 250.73L408.74 249.93 407.76 200.18 407.76 250.73Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M408.74 249.93L408.74 200.18 407.76 200.18 408.74 249.93Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M408.74 363.9L409.75 363.9 408.74 314.15 408.74 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M409.75 363.9L409.75 314.93 408.74 314.15 409.75 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M408.74 249.93L409.75 249.15 408.74 200.18 408.74 249.93Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M409.75 249.15L409.75 200.18 408.74 200.18 409.75 249.15Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M409.75 363.9L410.79 363.9 409.75 314.93 409.75 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M410.79 363.9L410.79 315.67 409.75 314.93 410.79 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M409.75 249.15L410.79 248.41 409.75 200.18 409.75 249.15Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M410.79 248.41L410.79 200.18 409.75 200.18 410.79 248.41Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M410.79 363.9L411.84 363.9 410.79 315.67 410.79 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M411.84 363.9L411.84 316.38 410.79 315.67 411.84 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M410.79 248.41L411.84 247.7 410.79 200.18 410.79 248.41Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M411.84 247.7L411.84 200.18 410.79 200.18 411.84 247.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M411.84 363.9L412.92 363.9 411.84 316.38 411.84 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M412.92 363.9L412.92 317.06 411.84 316.38 412.92 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M411.84 247.7L412.92 247.03 411.84 200.18 411.84 247.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M412.92 247.03L412.92 200.18 411.84 200.18 412.92 247.03Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M412.92 363.9L414.02 363.9 412.92 317.06 412.92 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M414.02 363.9L414.02 317.7 412.92 317.06 414.02 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M412.92 247.03L414.02 246.38 412.92 200.18 412.92 247.03Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M414.02 246.38L414.02 200.18 412.92 200.18 414.02 246.38Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M414.02 363.9L415.14 363.9 414.02 317.7 414.02 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M415.14 363.9L415.14 318.31 414.02 317.7 415.14 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M414.02 246.38L415.14 245.78 414.02 200.18 414.02 246.38Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M415.14 245.78L415.14 200.18 414.02 200.18 415.14 245.78Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M415.14 363.9L416.28 363.9 415.14 318.31 415.14 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M416.28 363.9L416.28 318.88 415.14 318.31 416.28 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M415.14 245.78L416.28 245.2 415.14 200.18 415.14 245.78Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M416.28 245.2L416.28 200.18 415.14 200.18 416.28 245.2Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M416.28 363.9L417.43 363.9 416.28 318.88 416.28 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.43 363.9L417.43 319.42 416.28 318.88 417.43 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M416.28 245.2L417.43 244.67 416.28 200.18 416.28 245.2Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.43 244.67L417.43 200.18 416.28 200.18 417.43 244.67Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.43 363.9L418.6 363.9 417.43 319.42 417.43 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M418.6 363.9L418.6 319.92 417.43 319.42 418.6 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.43 244.67L418.6 244.16 417.43 200.18 417.43 244.67Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M418.6 244.16L418.6 200.18 417.43 200.18 418.6 244.16Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M418.6 363.9L419.79 363.9 418.6 319.92 418.6 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M419.79 363.9L419.79 320.38 418.6 319.92 419.79 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M418.6 244.16L419.79 243.7 418.6 200.18 418.6 244.16Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M419.79 243.7L419.79 200.18 418.6 200.18 419.79 243.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M419.79 363.9L420.99 363.9 419.79 320.38 419.79 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M420.99 363.9L420.99 320.81 419.79 320.38 420.99 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M419.79 243.7L420.99 243.27 419.79 200.18 419.79 243.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M420.99 243.27L420.99 200.18 419.79 200.18 420.99 243.27Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M420.99 363.9L422.2 363.9 420.99 320.81 420.99 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M422.2 363.9L422.2 321.2 420.99 320.81 422.2 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M420.99 243.27L422.2 242.88 420.99 200.18 420.99 243.27Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M422.2 242.88L422.2 200.18 420.99 200.18 422.2 242.88Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M422.2 363.9L423.42 363.9 422.2 321.2 422.2 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M423.42 363.9L423.42 321.55 422.2 321.2 423.42 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M422.2 242.88L423.42 242.53 422.2 200.18 422.2 242.88Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M423.42 242.53L423.42 200.18 422.2 200.18 423.42 242.53Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M423.42 363.9L424.66 363.9 423.42 321.55 423.42 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M424.66 363.9L424.66 321.86 423.42 321.55 424.66 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M423.42 242.53L424.66 242.22 423.42 200.18 423.42 242.53Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M424.66 242.22L424.66 200.18 423.42 200.18 424.66 242.22Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M424.66 363.9L425.9 363.9 424.66 321.86 424.66 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M425.9 363.9L425.9 322.14 424.66 321.86 425.9 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M424.66 242.22L425.9 241.94 424.66 200.18 424.66 242.22Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M425.9 241.94L425.9 200.18 424.66 200.18 425.9 241.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M425.9 363.9L427.15 363.9 425.9 322.14 425.9 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M427.15 363.9L427.15 322.37 425.9 322.14 427.15 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M425.9 241.94L427.15 241.71 425.9 200.18 425.9 241.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M427.15 241.71L427.15 200.18 425.9 200.18 427.15 241.71Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M427.15 363.9L428.41 363.9 427.15 322.37 427.15 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M428.41 363.9L428.41 322.57 427.15 322.37 428.41 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M427.15 241.71L428.41 241.51 427.15 200.18 427.15 241.71Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M428.41 241.51L428.41 200.18 427.15 200.18 428.41 241.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M428.41 363.9L429.67 363.9 428.41 322.57 428.41 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M429.67 363.9L429.67 322.73 428.41 322.57 429.67 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M428.41 241.51L429.67 241.35 428.41 200.18 428.41 241.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M429.67 241.35L429.67 200.18 428.41 200.18 429.67 241.35Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M429.67 363.9L430.94 363.9 429.67 322.73 429.67 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M430.94 363.9L430.94 322.85 429.67 322.73 430.94 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M429.67 241.35L430.94 241.23 429.67 200.18 429.67 241.35Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M430.94 241.23L430.94 200.18 429.67 200.18 430.94 241.23Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M430.94 363.9L432.21 363.9 430.94 322.85 430.94 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M432.21 363.9L432.21 322.93 430.94 322.85 432.21 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M430.94 241.23L432.21 241.15 430.94 200.18 430.94 241.23Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M432.21 241.15L432.21 200.18 430.94 200.18 432.21 241.15Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M432.21 363.9L433.48 363.9 432.21 322.93 432.21 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M433.48 363.9L433.48 322.97 432.21 322.93 433.48 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M432.21 241.15L433.48 241.11 432.21 200.18 432.21 241.15Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M433.48 241.11L433.48 200.18 432.21 200.18 433.48 241.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M433.48 363.9L434.76 363.9 433.48 322.97 433.48 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M434.76 363.9L434.76 322.97 433.48 322.97 434.76 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M433.48 241.11L434.76 241.11 433.48 200.18 433.48 241.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M434.76 241.11L434.76 200.18 433.48 200.18 434.76 241.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M434.76 363.9L436.03 363.9 434.76 322.97 434.76 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M436.03 363.9L436.03 322.93 434.76 322.97 436.03 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M434.76 241.11L436.03 241.15 434.76 200.18 434.76 241.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M436.03 241.15L436.03 200.18 434.76 200.18 436.03 241.15Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M436.03 363.9L437.3 363.9 436.03 322.93 436.03 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M437.3 363.9L437.3 322.85 436.03 322.93 437.3 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M436.03 241.15L437.3 241.23 436.03 200.18 436.03 241.15Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M437.3 241.23L437.3 200.18 436.03 200.18 437.3 241.23Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M437.3 363.9L438.57 363.9 437.3 322.85 437.3 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M438.57 363.9L438.57 322.73 437.3 322.85 438.57 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M437.3 241.23L438.57 241.35 437.3 200.18 437.3 241.23Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M438.57 241.35L438.57 200.18 437.3 200.18 438.57 241.35Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M438.57 363.9L439.83 363.9 438.57 322.73 438.57 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M439.83 363.9L439.83 322.57 438.57 322.73 439.83 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M438.57 241.35L439.83 241.51 438.57 200.18 438.57 241.35Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M439.83 241.51L439.83 200.18 438.57 200.18 439.83 241.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M439.83 363.9L441.09 363.9 439.83 322.57 439.83 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M441.09 363.9L441.09 322.37 439.83 322.57 441.09 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M439.83 241.51L441.09 241.71 439.83 200.18 439.83 241.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M441.09 241.71L441.09 200.18 439.83 200.18 441.09 241.71Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M441.09 363.9L442.34 363.9 441.09 322.37 441.09 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M442.34 363.9L442.34 322.14 441.09 322.37 442.34 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M441.09 241.71L442.34 241.94 441.09 200.18 441.09 241.71Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M442.34 241.94L442.34 200.18 441.09 200.18 442.34 241.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M442.34 363.9L443.58 363.9 442.34 322.14 442.34 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M443.58 363.9L443.58 321.86 442.34 322.14 443.58 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M442.34 241.94L443.58 242.22 442.34 200.18 442.34 241.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M443.58 242.22L443.58 200.18 442.34 200.18 443.58 242.22Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M443.58 363.9L444.82 363.9 443.58 321.86 443.58 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M444.82 363.9L444.82 321.55 443.58 321.86 444.82 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M443.58 242.22L444.82 242.53 443.58 200.18 443.58 242.22Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M444.82 242.53L444.82 200.18 443.58 200.18 444.82 242.53Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M444.82 363.9L446.04 363.9 444.82 321.55 444.82 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M446.04 363.9L446.04 321.2 444.82 321.55 446.04 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M444.82 242.53L446.04 242.88 444.82 200.18 444.82 242.53Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M446.04 242.88L446.04 200.18 444.82 200.18 446.04 242.88Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M446.04 363.9L447.25 363.9 446.04 321.2 446.04 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M447.25 363.9L447.25 320.81 446.04 321.2 447.25 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M446.04 242.88L447.25 243.27 446.04 200.18 446.04 242.88Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M447.25 243.27L447.25 200.18 446.04 200.18 447.25 243.27Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M447.25 363.9L448.45 363.9 447.25 320.81 447.25 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M448.45 363.9L448.45 320.38 447.25 320.81 448.45 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M447.25 243.27L448.45 243.7 447.25 200.18 447.25 243.27Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M448.45 243.7L448.45 200.18 447.25 200.18 448.45 243.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M448.45 363.9L449.64 363.9 448.45 320.38 448.45 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M449.64 363.9L449.64 319.92 448.45 320.38 449.64 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M448.45 243.7L449.64 244.16 448.45 200.18 448.45 243.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M449.64 244.16L449.64 200.18 448.45 200.18 449.64 244.16Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M449.64 363.9L450.81 363.9 449.64 319.92 449.64 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M450.81 363.9L450.81 319.42 449.64 319.92 450.81 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M449.64 244.16L450.81 244.67 449.64 200.18 449.64 244.16Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M450.81 244.67L450.81 200.18 449.64 200.18 450.81 244.67Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M450.81 363.9L451.96 363.9 450.81 319.42 450.81 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M451.96 363.9L451.96 318.88 450.81 319.42 451.96 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M450.81 244.67L451.96 245.2 450.81 200.18 450.81 244.67Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M451.96 245.2L451.96 200.18 450.81 200.18 451.96 245.2Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M451.96 363.9L453.1 363.9 451.96 318.88 451.96 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M453.1 363.9L453.1 318.31 451.96 318.88 453.1 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M451.96 245.2L453.1 245.78 451.96 200.18 451.96 245.2Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M453.1 245.78L453.1 200.18 451.96 200.18 453.1 245.78Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M453.1 363.9L454.22 363.9 453.1 318.31 453.1 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M454.22 363.9L454.22 317.7 453.1 318.31 454.22 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M453.1 245.78L454.22 246.38 453.1 200.18 453.1 245.78Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M454.22 246.38L454.22 200.18 453.1 200.18 454.22 246.38Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M454.22 363.9L455.31 363.9 454.22 317.7 454.22 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M455.31 363.9L455.31 317.06 454.22 317.7 455.31 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M454.22 246.38L455.31 247.03 454.22 200.18 454.22 246.38Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M455.31 247.03L455.31 200.18 454.22 200.18 455.31 247.03Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M455.31 363.9L456.39 363.9 455.31 317.06 455.31 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M456.39 363.9L456.39 316.38 455.31 317.06 456.39 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M455.31 247.03L456.39 247.7 455.31 200.18 455.31 247.03Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M456.39 247.7L456.39 200.18 455.31 200.18 456.39 247.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M456.39 363.9L457.45 363.9 456.39 316.38 456.39 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M457.45 363.9L457.45 315.67 456.39 316.38 457.45 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M456.39 247.7L457.45 248.41 456.39 200.18 456.39 247.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M457.45 248.41L457.45 200.18 456.39 200.18 457.45 248.41Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M457.45 363.9L458.49 363.9 457.45 315.67 457.45 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M458.49 363.9L458.49 314.93 457.45 315.67 458.49 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M457.45 248.41L458.49 249.15 457.45 200.18 457.45 248.41Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M458.49 249.15L458.49 200.18 457.45 200.18 458.49 249.15Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M458.49 363.9L459.5 363.9 458.49 314.93 458.49 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M459.5 363.9L459.5 314.15 458.49 314.93 459.5 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M458.49 249.15L459.5 249.93 458.49 200.18 458.49 249.15Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M459.5 249.93L459.5 200.18 458.49 200.18 459.5 249.93Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M459.5 363.9L460.48 363.9 459.5 314.15 459.5 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M460.48 363.9L460.48 313.35 459.5 314.15 460.48 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M459.5 249.93L460.48 250.73 459.5 200.18 459.5 249.93Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M460.48 250.73L460.48 200.18 459.5 200.18 460.48 250.73Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M460.48 363.9L461.44 363.9 460.48 313.35 460.48 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M461.44 363.9L461.44 312.51 460.48 313.35 461.44 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M460.48 250.73L461.44 251.57 460.48 200.18 460.48 250.73Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M461.44 251.57L461.44 200.18 460.48 200.18 461.44 251.57Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M461.44 363.9L462.38 363.9 461.44 312.51 461.44 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M462.38 363.9L462.38 311.65 461.44 312.51 462.38 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M461.44 251.57L462.38 252.43 461.44 200.18 461.44 251.57Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M462.38 252.43L462.38 200.18 461.44 200.18 462.38 252.43Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M462.38 363.9L463.29 363.9 462.38 311.65 462.38 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M463.29 363.9L463.29 310.76 462.38 311.65 463.29 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M462.38 252.43L463.29 253.32 462.38 200.18 462.38 252.43Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M463.29 253.32L463.29 200.18 462.38 200.18 463.29 253.32Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M463.29 363.9L464.16 363.9 463.29 310.76 463.29 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M464.16 363.9L464.16 309.84 463.29 310.76 464.16 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M463.29 253.32L464.16 254.24 463.29 200.18 463.29 253.32Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M464.16 254.24L464.16 200.18 463.29 200.18 464.16 254.24Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M464.16 363.9L465.01 363.9 464.16 309.84 464.16 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M465.01 363.9L465.01 308.89 464.16 309.84 465.01 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M464.16 254.24L465.01 255.19 464.16 200.18 464.16 254.24Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M465.01 255.19L465.01 200.18 464.16 200.18 465.01 255.19Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M465.01 363.9L465.83 363.9 465.01 308.89 465.01 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M465.83 363.9L465.83 307.91 465.01 308.89 465.83 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M465.01 255.19L465.83 256.17 465.01 200.18 465.01 255.19Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M465.83 256.17L465.83 200.18 465.01 200.18 465.83 256.17Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M465.83 363.9L466.62 363.9 465.83 307.91 465.83 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M466.62 363.9L466.62 306.92 465.83 307.91 466.62 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M465.83 256.17L466.62 257.17 465.83 200.18 465.83 256.17Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M466.62 257.17L466.62 200.18 465.83 200.18 466.62 257.17Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M466.62 363.9L467.38 363.9 466.62 306.92 466.62 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M467.38 363.9L467.38 305.89 466.62 306.92 467.38 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M466.62 257.17L467.38 258.19 466.62 200.18 466.62 257.17Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M467.38 258.19L467.38 200.18 466.62 200.18 467.38 258.19Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M467.38 363.9L468.11 363.9 467.38 305.89 467.38 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M468.11 363.9L468.11 304.85 467.38 305.89 468.11 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M467.38 258.19L468.11 259.23 467.38 200.18 467.38 258.19Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M468.11 259.23L468.11 200.18 467.38 200.18 468.11 259.23Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M468.11 363.9L468.8 363.9 468.11 304.85 468.11 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M468.8 363.9L468.8 303.78 468.11 304.85 468.8 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M468.11 259.23L468.8 260.3 468.11 200.18 468.11 259.23Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M468.8 260.3L468.8 200.18 468.11 200.18 468.8 260.3Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M468.8 363.9L469.46 363.9 468.8 303.78 468.8 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M469.46 363.9L469.46 302.69 468.8 303.78 469.46 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M468.8 260.3L469.46 261.39 468.8 200.18 468.8 260.3Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M469.46 261.39L469.46 200.18 468.8 200.18 469.46 261.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M469.46 363.9L470.08 363.9 469.46 302.69 469.46 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M470.08 363.9L470.08 301.58 469.46 302.69 470.08 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M469.46 261.39L470.08 262.5 469.46 200.18 469.46 261.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M470.08 262.5L470.08 200.18 469.46 200.18 470.08 262.5Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M470.08 363.9L470.67 363.9 470.08 301.58 470.08 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M470.67 363.9L470.67 300.45 470.08 301.58 470.67 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M470.08 262.5L470.67 263.63 470.08 200.18 470.08 262.5Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M470.67 263.63L470.67 200.18 470.08 200.18 470.67 263.63Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M470.67 363.9L471.23 363.9 470.67 300.45 470.67 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M471.23 363.9L471.23 299.31 470.67 300.45 471.23 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M470.67 263.63L471.23 264.77 470.67 200.18 470.67 263.63Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M471.23 264.77L471.23 200.18 470.67 200.18 471.23 264.77Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M471.23 363.9L471.75 363.9 471.23 299.31 471.23 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M471.75 363.9L471.75 298.14 471.23 299.31 471.75 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M471.23 264.77L471.75 265.94 471.23 200.18 471.23 264.77Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M471.75 265.94L471.75 200.18 471.23 200.18 471.75 265.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M471.75 363.9L472.23 363.9 471.75 298.14 471.75 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M472.23 363.9L472.23 296.97 471.75 298.14 472.23 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M471.75 265.94L472.23 267.11 471.75 200.18 471.75 265.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M472.23 267.11L472.23 200.18 471.75 200.18 472.23 267.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M472.23 363.9L472.68 363.9 472.23 296.97 472.23 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M472.68 363.9L472.68 295.77 472.23 296.97 472.68 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M472.23 267.11L472.68 268.31 472.23 200.18 472.23 267.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M472.68 268.31L472.68 200.18 472.23 200.18 472.68 268.31Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M472.68 363.9L473.09 363.9 472.68 295.77 472.68 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M473.09 363.9L473.09 294.57 472.68 295.77 473.09 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M472.68 268.31L473.09 269.51 472.68 200.18 472.68 268.31Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M473.09 269.51L473.09 200.18 472.68 200.18 473.09 269.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M473.09 363.9L473.46 363.9 473.09 294.57 473.09 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M473.46 363.9L473.46 293.35 473.09 294.57 473.46 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M473.09 269.51L473.46 270.73 473.09 200.18 473.09 269.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M473.46 270.73L473.46 200.18 473.09 200.18 473.46 270.73Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M473.46 363.9L473.79 363.9 473.46 293.35 473.46 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M473.79 363.9L473.79 292.12 473.46 293.35 473.79 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M473.46 270.73L473.79 271.96 473.46 200.18 473.46 270.73Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M473.79 271.96L473.79 200.18 473.46 200.18 473.79 271.96Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M473.79 363.9L474.08 363.9 473.79 292.12 473.79 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.08 363.9L474.08 290.88 473.79 292.12 474.08 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M473.79 271.96L474.08 273.2 473.79 200.18 473.79 271.96Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.08 273.2L474.08 200.18 473.79 200.18 474.08 273.2Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.08 363.9L474.34 363.9 474.08 290.88 474.08 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.34 363.9L474.34 289.63 474.08 290.88 474.34 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.08 273.2L474.34 274.45 474.08 200.18 474.08 273.2Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.34 274.45L474.34 200.18 474.08 200.18 474.34 274.45Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.34 363.9L474.56 363.9 474.34 289.63 474.34 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.56 363.9L474.56 288.38 474.34 289.63 474.56 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.34 274.45L474.56 275.7 474.34 200.18 474.34 274.45Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.56 275.7L474.56 200.18 474.34 200.18 474.56 275.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.56 363.9L474.73 363.9 474.56 288.38 474.56 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.73 363.9L474.73 287.12 474.56 288.38 474.73 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.56 275.7L474.73 276.96 474.56 200.18 474.56 275.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.73 276.96L474.73 200.18 474.56 200.18 474.73 276.96Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.73 363.9L474.87 363.9 474.73 287.12 474.73 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.87 363.9L474.87 285.85 474.73 287.12 474.87 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.73 276.96L474.87 278.23 474.73 200.18 474.73 276.96Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.87 278.23L474.87 200.18 474.73 200.18 474.87 278.23Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.87 363.9L474.97 363.9 474.87 285.85 474.87 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.97 363.9L474.97 284.58 474.87 285.85 474.97 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.87 278.23L474.97 279.5 474.87 200.18 474.87 278.23Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.97 279.5L474.97 200.18 474.87 200.18 474.97 279.5Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.97 363.9L475.03 363.9 474.97 284.58 474.97 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M475.03 363.9L475.03 283.31 474.97 284.58 475.03 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.97 279.5L475.03 280.77 474.97 200.18 474.97 279.5Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M475.03 280.77L475.03 200.18 474.97 200.18 475.03 280.77Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M475.03 363.9L475.05 363.9 475.03 283.31 475.03 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M475.05 363.9L475.05 282.04 475.03 283.31 475.05 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M475.03 280.77L475.05 282.04 475.03 200.18 475.03 280.77Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M475.05 282.04L475.05 200.18 475.03 200.18 475.05 282.04Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M475.05 363.9L515.98 363.9 475.05 200.18 475.05 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M515.98 363.9L515.98 200.18 475.05 200.18 515.98 363.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M475.05 282.04L474.85 286.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M474.85 286.05L474.26 290.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M474.26 290.03L473.29 293.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M473.29 293.92L471.93 297.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M471.93 297.7L470.22 301.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.22 301.33L468.15 304.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M468.15 304.78L465.76 308.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M465.76 308.01L463.06 310.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M463.06 310.98L460.09 313.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.09 313.68L456.86 316.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M456.86 316.07L453.41 318.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M453.41 318.14L449.78 319.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.78 319.86L446 321.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446 321.21L442.1 322.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.1 322.18L438.13 322.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438.13 322.77L434.12 322.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.12 322.97L430.11 322.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M430.11 322.77L426.13 322.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.13 322.18L422.24 321.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.24 321.21L418.46 319.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.46 319.86L414.82 318.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.82 318.14L411.38 316.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.38 316.07L408.15 313.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.15 313.68L405.18 310.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.18 310.98L402.48 308.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.48 308.01L400.09 304.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.09 304.78L398.02 301.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.02 301.33L396.3 297.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.3 297.7L394.95 293.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.95 293.92L393.97 290.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.97 290.03L393.39 286.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.39 286.05L393.19 282.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.19 282.04L393.39 278.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.39 278.03L393.97 274.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.97 274.06L394.95 270.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.95 270.16L396.3 266.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.3 266.38L398.02 262.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.02 262.75L400.09 259.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.09 259.3L402.48 256.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.48 256.07L405.18 253.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.18 253.1L408.15 250.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.15 250.4L411.38 248.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.38 248.01L414.82 245.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.82 245.94L418.46 244.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.46 244.23L422.24 242.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.24 242.87L426.13 241.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.13 241.9L430.11 241.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M430.11 241.31L434.12 241.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.12 241.11L438.13 241.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438.13 241.31L442.1 241.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.1 241.9L446 242.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446 242.87L449.78 244.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.78 244.23L453.41 245.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M453.41 245.94L456.86 248.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M456.86 248.01L460.09 250.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.09 250.4L463.06 253.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M463.06 253.1L465.76 256.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M465.76 256.07L468.15 259.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M468.15 259.3L470.22 262.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.22 262.75L471.93 266.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M471.93 266.38L473.29 270.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M473.29 270.16L474.26 274.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M474.26 274.06L474.85 278.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M474.85 278.03L475.05 282.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_02_02;

    