import { IDictionary } from './types';
import _ from 'lodash';
import { useMemo } from 'react';

/**
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * Class with functions expanding on the default lodash library
 */
class __ {
    /**
     * Performs a recursive merge like _.merge, but without mutating the original object
     *
     * For conflicts, y has highest priority
     *
     * For memoization; use the useDeepMerge hook
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param x The first object
     * @param y The second object
     * @returns The merged object
     */
    public static deepMerge<Tx = unknown, Ty = Tx>(x: Tx, y: Ty) {
        return _.merge<Tx, Ty>(_.cloneDeep<Tx>(x), _.cloneDeep<Ty>(y));
    }

    public static copyOmit<T = IDictionary<never>>(obj: IDictionary, ...keysToOmit: string[]) {
        const copy = { ...obj };

        for (const key of keysToOmit) {
            delete copy[key];
        }

        return copy as unknown as T;
    }

    public static omit<T = IDictionary<unknown>>(obj: IDictionary, ...keysToOmit: string[]) {
        const copy = { ...obj };
        for (const key of keysToOmit) delete copy[key];
        return copy as unknown as T;
    }

    public static getDefined(obj: IDictionary | undefined) {
        const copy = { ...obj };
        for (const [key, val] of Object.entries(copy)) {
            if (val === undefined) delete copy[key as keyof typeof copy];
        }
        return copy;
    }

    public static dismissKeyboard() {
        const field = document.createElement('input');
        field.setAttribute('type', 'text');
        document.body.appendChild(field);

        setTimeout(function () {
            field.focus();
            setTimeout(function () {
                field.setAttribute('style', 'display:none;');
            }, 1);
        }, 1);
    }
}

//#region Hooks for some of the above

/**
 * Performs a recursive merge like _.merge, but without mutating the original object
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @param x The first object
 * @param y The second object
 * @returns The merged object
 */
export function useDeepMerge<Tx = unknown, Ty = Tx>(x: Tx, y: Ty) {
    return useMemo(() => __.deepMerge(x, y), [x, y]);
}

//#endregion Hooks for some of the above

export default __;
