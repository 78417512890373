const GetType = ({ column }: { column: string }) => {
    const typeMap = new Map([
        //Del
        ['id', 'int'],
        ['aktiv', 'string'],
        ['kode', 'string'],
        ['beskrivelse', 'string'],
        ['betegnelse', 'string'],
        ['klasse', 'int'],
        ['udvklasse', 'int'],
        ['bemaerkning', 'string'],
        ['maengde1', 'decimal'],
        ['enhed1', 'string'],
        ['maengde2', 'decimal'],
        ['enhed2', 'string'],
        ['nrfelt1', 'string'],
        ['nrfelt2', 'string'],
        ['fabrikat', 'string'],
        ['deltype', 'string'],
        ['stoerrelse', 'string'],
        ['serienummer', 'string'],
        ['indkoebtaar', 'year'],
        ['indkoebspris', 'int'],
        ['monteretaar', 'year'],
        ['forsikringsvaerdi', 'int'],
        ['levetid', 'int'],
        ['intervaltype', 'int'],
        ['tilstandkode', 'string'],
        ['tilstanddato', 'date'],
        ['garantiudloeb', 'date'],
        ['retdato', 'date'],
        ['retbruger', 'string'],
        ['note', 'string'],

        //Aktivitet
        ['enhedsys', 'int'],
        ['bygtersys', 'int'],
        ['delsys', 'int'],
        ['aktivitetstatusId', 'int'],
        ['beskrivelse', 'string'],
        ['katalogkode', 'string'],
        ['skemakode', 'int'],
        ['positionsnummer', 'string'],
        ['bedstetid', 'date'],
        ['interval', 'int'],
        ['interval_type', 'int'],
        ['aktiv', 'string'],
        ['opretdato', 'date'],
        ['opretbruger', 'string'],
        ['retdato', 'date'],
        ['retbruger', 'string'],
        ['fritekst', 'string'],
        ['note', 'string'],
    ]);
    return typeMap.get(column);
};

export default GetType;
