
    const Svg3_07_07 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="335.31 261.03 148.07999999999998 79.04000000000002" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M478.39 266.03L340.31 266.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.31 266.03L340.31 335.07Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.31 335.07L478.39 335.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.39 335.07L478.39 266.03Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M357.57 266.03L357.57 279.84Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.09 266.03L392.09 279.84Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.61 266.03L426.61 279.84Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.13 266.03L461.13 279.84Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.61 335.07L426.61 321.26Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.61 321.26L447.32 285.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.09 335.07L392.09 321.26Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.09 321.26L412.8 285.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg3_07_07;

    