import { Suspense, lazy } from 'react';

import { ExportingProps } from '.';

const Internal = lazy(() => import('./internal'));

const Exporting = (props: ExportingProps) => {
    return (
        <Suspense fallback={<></>}>
            <Internal {...props} />
        </Suspense>
    );
};

export default Exporting;
