import { Item as DxItem, Toolbar as DxToolbar } from 'devextreme-react/data-grid';

import Insert from './insert';
import { Panels } from './interfaces';
import { ReactNode } from 'react';
import { ZLayout } from '../layout-interface';

const getPanel = (
    p: Panels,
    visible: boolean = true,
    locateInMenu?: string,
    text?: string,
    content?: ReactNode | ReactNode[]
) => {
    return (
        <DxItem
            cssClass={`${Panels.None} ${p}`}
            name={p}
            visible={visible}
            locateInMenu={locateInMenu}
            options={{ text: text, showText: true }}
        >
            {content}
        </DxItem>
    );
};

const ModularToolbar = (layout: ZLayout) => {
    return (
        <DxToolbar>
            {layout.extras?.allowAdding &&
                getPanel(Panels.Insert, true, 'never', 'Opret ny', <Insert {...layout.extras} />)}
            {layout.extras?.export && getPanel(Panels.DxExport, true, 'auto')}
            {getPanel(Panels.DxColumnChooser, true)}
            {getPanel(Panels.DxGroup, true)}
            {getPanel(Panels.DxSearch, true, 'auto')}
        </DxToolbar>
    );
};

const getToolbar = ModularToolbar;
export default getToolbar;
