const SvgADKundercentral = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        width='30'
        zoomAndPan='magnify'
        viewBox='0 0 30 30.000001'
        height='30'
        preserveAspectRatio='xMidYMid meet'
        version='1.0'
    >
        <defs>
            <g />
            <clipPath id='8d03c4d412'>
                <path
                    d='M 0.484375 0 L 1.582031 0 L 1.582031 29.03125 L 0.484375 29.03125 Z M 0.484375 0 '
                    clipRule='nonzero'
                />
            </clipPath>
            <clipPath id='ef4f8ded74'>
                <path
                    d='M 28.417969 0 L 29.515625 0 L 29.515625 29.03125 L 28.417969 29.03125 Z M 28.417969 0 '
                    clipRule='nonzero'
                />
            </clipPath>
            <clipPath id='05048658a3'>
                <path
                    d='M 0.484375 28.148438 L 29.515625 28.148438 L 29.515625 29.03125 L 0.484375 29.03125 Z M 0.484375 28.148438 '
                    clipRule='nonzero'
                />
            </clipPath>
            <clipPath id='3b80642721'>
                <path
                    d='M 0.484375 0 L 29.515625 0 L 29.515625 0.941406 L 0.484375 0.941406 Z M 0.484375 0 '
                    clipRule='nonzero'
                />
            </clipPath>
        </defs>
        <g clipPath='url(#8d03c4d412)'>
            <path
                fill='{{replacement-value-color}}'
                d='M -6.835938 0 L 1.582031 0 L 1.582031 32.527344 L -6.835938 32.527344 Z M -6.835938 0 '
                fillOpacity='1'
                fillRule='nonzero'
            />
        </g>
        <g fill='{{replacement-value-color}}' fillOpacity='1'>
            <g transform='translate(3.744863, 17.78226)'>
                <g>
                    <path d='M 7.3125 0 L 6.140625 0 L 5.21875 -2.375 L 1.953125 -2.375 L 1.078125 0 L -0.015625 0 L 3 -7.828125 L 4.109375 -7.828125 Z M 4.90625 -3.203125 C 4.257812 -4.898438 3.894531 -5.863281 3.8125 -6.09375 C 3.738281 -6.320312 3.648438 -6.628906 3.546875 -7.015625 L 3.515625 -7.015625 C 3.421875 -6.484375 3.289062 -5.992188 3.125 -5.546875 L 2.25 -3.203125 Z M 4.90625 -3.203125 ' />
                </g>
            </g>
        </g>
        <g fill='{{replacement-value-color}}' fillOpacity='1'>
            <g transform='translate(11.036729, 17.78226)'>
                <g>
                    <path d='M 0.875 -7.828125 L 3.578125 -7.828125 C 4.046875 -7.828125 4.453125 -7.800781 4.796875 -7.75 C 5.140625 -7.707031 5.46875 -7.59375 5.78125 -7.40625 C 6.09375 -7.21875 6.363281 -6.957031 6.59375 -6.625 C 6.832031 -6.300781 7.015625 -5.925781 7.140625 -5.5 C 7.273438 -5.070312 7.34375 -4.554688 7.34375 -3.953125 C 7.34375 -2.804688 7.066406 -1.859375 6.515625 -1.109375 C 5.972656 -0.367188 5.035156 0 3.703125 0 L 0.875 0 Z M 1.90625 -0.921875 L 3.59375 -0.921875 C 3.9375 -0.921875 4.238281 -0.941406 4.5 -0.984375 C 4.769531 -1.035156 5.039062 -1.15625 5.3125 -1.34375 C 5.59375 -1.53125 5.820312 -1.835938 6 -2.265625 C 6.1875 -2.703125 6.28125 -3.273438 6.28125 -3.984375 C 6.28125 -4.367188 6.242188 -4.734375 6.171875 -5.078125 C 6.109375 -5.421875 5.984375 -5.726562 5.796875 -6 C 5.609375 -6.28125 5.390625 -6.484375 5.140625 -6.609375 C 4.898438 -6.742188 4.664062 -6.828125 4.4375 -6.859375 C 4.207031 -6.890625 3.914062 -6.90625 3.5625 -6.90625 L 1.90625 -6.90625 Z M 1.90625 -0.921875 ' />
                </g>
            </g>
        </g>
        <g fill='{{replacement-value-color}}' fillOpacity='1'>
            <g transform='translate(18.931801, 17.78226)'>
                <g>
                    <path d='M 7.28125 0 L 5.90625 0 L 3.125 -3.953125 L 1.84375 -2.71875 L 1.84375 0 L 0.796875 0 L 0.796875 -7.828125 L 1.84375 -7.828125 L 1.84375 -3.953125 L 5.734375 -7.828125 L 7.125 -7.828125 L 3.84375 -4.65625 Z M 7.28125 0 ' />
                </g>
            </g>
        </g>
        <g clipPath='url(#ef4f8ded74)'>
            <path
                fill='{{replacement-value-color}}'
                d='M 28.417969 0 L 32.046875 0 L 32.046875 32.519531 L 28.417969 32.519531 Z M 28.417969 0 '
                fillOpacity='1'
                fillRule='nonzero'
            />
        </g>
        <g clipPath='url(#05048658a3)'>
            <path
                fill='{{replacement-value-color}}'
                d='M 31.257812 28.148438 L 31.257812 31.777344 L -1.261719 31.777344 L -1.261719 28.148438 Z M 31.257812 28.148438 '
                fillOpacity='1'
                fillRule='nonzero'
            />
        </g>
        <g clipPath='url(#3b80642721)'>
            <path
                fill='{{replacement-value-color}}'
                d='M -1.257812 0.941406 L -1.257812 -2.6875 L 31.261719 -2.6875 L 31.261719 0.941406 Z M -1.257812 0.941406 '
                fillOpacity='1'
                fillRule='nonzero'
            />
        </g>
    </svg>
);
export default SvgADKundercentral;
