
    const Svg1_05_04B = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="322.13 148.55 219.28999999999996 271.59" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M379.44 362.84L510.2 362.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M510.2 362.84L510.2 336.68Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M510.2 336.68L379.44 336.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.44 336.68L379.44 362.84Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.89 362.84L457.89 415.14Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.89 415.14L327.13 415.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M327.13 415.14L327.13 232.07Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M327.13 232.07L457.89 232.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.89 232.07L457.89 336.68Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M536.42 232.07L536.06 226.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M536.06 226.53L535.32 221.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M535.32 221.02L534.2 215.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M534.2 215.57L532.71 210.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M532.71 210.21L530.86 204.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M530.86 204.97L528.65 199.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M528.65 199.87L526.1 194.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M526.1 194.93L523.22 190.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M523.22 190.17L520.02 185.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M520.02 185.63L516.51 181.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M516.51 181.32L512.71 177.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M512.71 177.25L508.65 173.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M508.65 173.46L504.34 169.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M504.34 169.95L499.79 166.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M499.79 166.75L495.04 163.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M495.04 163.87L490.1 161.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M490.1 161.31L485 159.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M485 159.11L479.75 157.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M479.75 157.26L474.4 155.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M474.4 155.77L468.95 154.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M468.95 154.65L463.44 153.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M463.44 153.91L457.89 153.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M510.25 232.07L509.86 227.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M509.86 227.58L509.1 223.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M509.1 223.13L507.98 218.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M507.98 218.76L506.5 214.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M506.5 214.49L504.68 210.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M504.68 210.36L502.53 206.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M502.53 206.4L500.05 202.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M500.05 202.62L497.28 199.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M497.28 199.06L494.22 195.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M494.22 195.75L490.9 192.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M490.9 192.69L487.34 189.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M487.34 189.91L483.57 187.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M483.57 187.44L479.6 185.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M479.6 185.29L475.47 183.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M475.47 183.47L471.21 181.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M471.21 181.99L466.84 180.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.84 180.87L462.39 180.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M462.39 180.11L457.89 179.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M484.05 232.07L483.56 228.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M483.56 228.76L482.7 225.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M482.7 225.53L481.46 222.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.46 222.42L479.87 219.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M479.87 219.48L477.95 216.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M477.95 216.74L475.73 214.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M475.73 214.24L473.23 212.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M473.23 212.01L470.49 210.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.49 210.1L467.55 208.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.55 208.51L464.44 207.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.44 207.27L461.21 206.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.21 206.41L457.9 205.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_05_04B;

    