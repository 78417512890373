
    const Svg1_01_01 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="362.97 245.0 85.55999999999995 85.57" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M367.97 325.57L367.97 250 443.53 250 443.53 325.57 367.97 325.57Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M367.97 325.57L443.53 325.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.53 325.57L443.53 250Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.53 250L367.97 250" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.97 250L367.97 325.57Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_01;

    