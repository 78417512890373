import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { ReactElement } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useEnhedId } from '../../../shared/hooks/use-enhed-id';
import BoltIcon from '@mui/icons-material/Bolt';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import ListAltIcon from '@mui/icons-material/ListAlt';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import BugReportIcon from '@mui/icons-material/BugReport';
import Restricted, { UserPermissions } from '../../../components/restricted';
import ImageFavorite from '../../../components/file-displaying/image-favorite';
import Typography from '@mui/material/Typography';
import useFetch from '../../../shared/hooks/fetch-hooks/use-fetch';
import Stack from '@mui/material/Stack';

const BigButton = ({
    children,
    onClick,
    style,
}: {
    children: ReactElement | string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    style?: React.CSSProperties;
}) => {
    return (
        <Button sx={{ width: '110px', height: '110px', borderRadius: '8px !important', ...style }} onClick={onClick}>
            {children}
        </Button>
    );
};

export const Tile = ({
    text,
    icon,
    to,
    permissions,
}: {
    text: string;
    icon: ReactElement;
    to: string;
    permissions?: UserPermissions | UserPermissions[];
}) => {
    return (
        <Restricted permissions={permissions ?? []}>
            <Grid item xs={'auto'}>
                <NavLink to={to}>
                    <BigButton onClick={() => {}}>
                        <Grid container className='w-full' spacing={2}>
                            <Grid item xs={12}>
                                {icon}
                            </Grid>
                            <Grid item xs={12}>
                                <p style={{ fontSize: '12px' }}>{text}</p>
                            </Grid>
                        </Grid>
                    </BigButton>
                </NavLink>
            </Grid>
        </Restricted>
    );
};

const SmallTile = ({
    text,
    icon,
    to,
    permissions,
}: {
    text: string;
    icon: ReactElement;
    to: string;
    permissions?: UserPermissions | UserPermissions[];
}) => {
    return (
        <Restricted permissions={permissions ?? []}>
            <NavLink to={to} style={{ paddingRight: '8px' }}>
                <Stack
                    direction='row'
                    alignItems='center'
                    spacing={1}
                    style={{
                        backgroundColor: '#1976d2',
                        borderRadius: '12px',
                        color: 'white',
                        height: '40px',
                        width: ' 150px',
                        paddingLeft: '8px',
                    }}
                >
                    {icon}
                    <Typography variant='caption'>{text}</Typography>
                </Stack>
            </NavLink>
        </Restricted>
    );
};

const OverviewTilesScreen = () => {
    const [params] = useSearchParams();
    const ids = params.get('ids')?.split(',');
    const id = ids?.[ids.length - 1];
    const [data] = useFetch<{ name: string }>(`/overview/info?typedId=${id}`);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
            <Typography pb='8px' variant='h6'>
                {data?.name}
            </Typography>
            <SmallTilesAndImageFavorite />
        </div>
    );
};

export default OverviewTilesScreen;

export const SmallTilesAndImageFavorite = () => {
    const enhId = useEnhedId();
    const [params] = useSearchParams();
    const ids = params.get('ids')?.split(',');
    const id = ids?.[ids.length - 1];
    const context = 'Context';
    return (
        <Box width='100%' paddingX='40px' pt='0px' flexGrow={2} overflow={'hidden'} maxHeight={'inherit'}>
            {/* <div style={{ display: 'inline-block', height: '100%', overflowY: 'scroll', maxHeight: 'inherit' }}>
                <Stack direction={'column'} spacing={1}>
                    <SmallTile
                        text='Drift'
                        icon={<ListAltIcon fontSize='medium' />}
                        to={`/${enhId}/Overview/${context}/Drift${window.location.search}`}
                        permissions={UserPermissions.Drift}
                    />
                    <SmallTile
                        text='Syn'
                        icon={<AssignmentIcon fontSize='medium' />}
                        to={`/${enhId}/Overview/${context}/Syn${window.location.search}`}
                        permissions={UserPermissions.Syn}
                    />
                    <SmallTile
                        text='Bygningsdelskort'
                        icon={<MapsHomeWorkIcon fontSize='medium' />}
                        to={`/${enhId}/Overview/${context}/Bygningsdelskort${window.location.search}`}
                        permissions={UserPermissions.Bygningsdelskort}
                    />
                    <SmallTile
                        text='Forsyning'
                        icon={<BoltIcon fontSize='medium' />}
                        to={`/${enhId}/Overview/${context}/Forsyning${window.location.search}`}
                        permissions={UserPermissions.Forsyning}
                    />
                    <SmallTile
                        text='Service'
                        icon={<HomeRepairServiceIcon fontSize='medium' />}
                        to={`/${enhId}/Overview/${context}/Service${window.location.search}`}
                        permissions={UserPermissions.Service}
                    />
                    <SmallTile
                        text='Stamdata'
                        icon={<DocumentScannerIcon fontSize='medium' />}
                        to={`/${enhId}/Overview/${context}/Stamdata${window.location.search}`}
                        permissions={UserPermissions.Overview}
                    />
                    <SmallTile
                        text='Debug'
                        icon={<BugReportIcon fontSize='medium' />}
                        to={`Debug${window.location.search}`}
                        permissions={UserPermissions.Admin}
                    />
                </Stack>
            </div> */}
            {enhId !== 0 && (
                <div style={{ paddingBottom: '16px', width: '100%', float: 'right', maxHeight: '100%' }}>
                    <ImageFavorite id={id} />
                </div>
            )}
        </Box>
    );
};
