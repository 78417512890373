
    const Svg1_01_33 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="319.76 252.26 173.81 112.26999999999998" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M324.94 359.53L488.57 359.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.57 277.72L324.94 277.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.94 277.72L324.94 359.53Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.57 359.53L488.57 277.72Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.62 349.3L459.9 287.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.57 257.26L324.76 257.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.56 315.48L428.56 298.54 420.38 301.31 428.56 315.48Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M428.56 315.48L459.9 287.94 428.56 298.54 428.56 315.48Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M459.9 287.94L420.38 301.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.38 301.31L428.56 315.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.56 315.48L459.9 287.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_33;

    