import Button from '@mui/material/Button/Button';
import { GridItemCenter } from '../../mui/extended-mui';
import { IAutoFormProps } from './interfaces-and-defaults';
import { ISubmitterProps } from './headers-footers';
import Restricted from '../../restricted/restricted';
import { UserPermissions } from '../../restricted/interfaces';

interface IGetBtn {
    btn: NonNullable<IAutoFormProps['buttons']>[number];
    props: ISubmitterProps;
}

const GetBtn = ({ btn, props }: IGetBtn) => {
    switch (btn) {
        case 'save':
            return (
                <GridItemCenter xs={true} key='save'>
                    <Button
                        type='submit'
                        name='save'
                        value='save'
                        variant='contained'
                        color='success'
                        disabled={!props.allValid}
                        sx={(theme) => ({
                            transition: theme.transitions.create('all', {
                                easing: theme.transitions.easing.easeInOut,
                                duration: '1s',
                            }),
                            width: '100%',
                            minWidth: '90px',
                        })}
                    >
                        Gem
                    </Button>
                </GridItemCenter>
            );
        case 'cancel':
            return (
                <GridItemCenter xs={true} key='cancel'>
                    <Button
                        variant={props.allValid ? 'outlined' : 'contained'}
                        color='primary'
                        sx={(theme) => ({
                            transition: theme.transitions.create('all', {
                                easing: theme.transitions.easing.easeInOut,
                                duration: '1s',
                            }),
                            width: '100%',
                            minWidth: '111px',
                        })}
                        onClick={props.handleCancel}
                    >
                        Annullér
                    </Button>
                </GridItemCenter>
            );
        case 'help':
            return (
                <GridItemCenter xs={true} key='help'>
                    <Button
                        variant='outlined'
                        onClick={(e) => props.handleHelp(e)}
                        disabled={props.allValid}
                        sx={(theme) => ({
                            transition: theme.transitions.create('all', {
                                easing: theme.transitions.easing.easeInOut,
                                duration: '1s',
                            }),
                            scrollMarginBottom: '24px',
                            width: '100%',
                            minWidth: '95px',
                        })}
                    >
                        Hjælp
                    </Button>
                </GridItemCenter>
            );
        case 'submit':
            return (
                <Restricted permissions={UserPermissions.Drift_Rul}>
                    <GridItemCenter xs={true} key='submit'>
                        <Button
                            type='submit'
                            name='submit'
                            value='submit'
                            variant='contained'
                            color='success'
                            disabled={!props.allValid}
                            sx={(theme) => ({
                                transition: theme.transitions.create('all', {
                                    easing: theme.transitions.easing.easeInOut,
                                    duration: '1s',
                                }),
                                width: '100%',
                                minWidth: '95px',
                            })}
                        >
                            Udfør
                        </Button>
                    </GridItemCenter>
                </Restricted>
            );
        case 'disabledSubmit':
            return (
                <Restricted permissions={UserPermissions.Drift_Rul}>
                    <GridItemCenter xs={true} key='submit'>
                        <Button
                            type='submit'
                            name='submit'
                            value='submit'
                            variant='contained'
                            color='success'
                            disabled={true}
                            sx={(theme) => ({
                                transition: theme.transitions.create('all', {
                                    easing: theme.transitions.easing.easeInOut,
                                    duration: '1s',
                                }),
                                width: '100%',
                                minWidth: '95px',
                            })}
                        >
                            Udfør
                        </Button>
                    </GridItemCenter>
                </Restricted>
            );
        case 'service':
            return (
                // <Restricted permissions={UserPermissions.Drift_Rul}>
                <GridItemCenter xs={true} key='service'>
                    <Button
                        type='submit'
                        name='submit'
                        value='submit'
                        variant='contained'
                        color='success'
                        // disabled={!props.allValid}
                        sx={(theme) => ({
                            'backgroundColor': '#fbc02d',
                            'transition': theme.transitions.create('all', {
                                easing: theme.transitions.easing.easeInOut,
                                duration: '1s',
                            }),
                            '&:hover': { backgroundColor: '#af861f' },
                            'width': '100%',
                            'minWidth': '133px',
                        })}
                    >
                        Færdigmeld
                    </Button>
                </GridItemCenter>
                // </Restricted>
            );
        default:
            return <></>;
    }
};

export default GetBtn;
