const SvgFlugtvejsskilt = () => (
    <svg xmlns='http://www.w3.org/2000/svg' xmlSpace='preserve' width={27.745} height={27.766}>
        <path
            d='M38.523-11.24c-1.47-.458-2.66-1.674-3.104-3.168l-.362-1.217V-33.862l.37-1.245.37-1.245.966-.873c.532-.48 1.417-.995 1.967-1.143l1-.27 9.756.073 9.756.072 1.142.671 1.143.672.637 1.28.638 1.279v9.713c0 5.646-.101 10.067-.242 10.557-.133.464-.656 1.268-1.162 1.786l-.92.942-1.215.361-1.215.36-9.224-.015-9.225-.017zm11.698-4.555c1.07-.788 2.589-1.199 2.852-.773.085.138-.186.537-.601.887l-.757.636h1.33l1.079-1.078 1.078-1.079-.067-4.507-.067-4.508-1.228-.075-1.227-.076-.77-.744-.771-.743-.169.273c-.093.15-.227.85-.299 1.556l-.13 1.282 1.595 3.745 1.595 3.744h-2.088l-.93-2.025a404.005 404.005 0 0 1-1.524-3.36l-.594-1.334-.904 1.825-.904 1.825-1.78.072-1.78.072v2.936l-1.014 1.1-1.013 1.099H49.2zm-5.025-6.432c.226-.169.914-1.678 1.529-3.355l1.118-3.047-.237-.237c-.445-.445-1.393.036-1.815.923l-.414.867H44.334l.162-.644c.088-.354.35-1.045.58-1.535l.419-.89 2.565-.07c3.04-.084 3.701.132 4.627 1.514l.677 1.011.913.076.914.076v-6.904H43.16v6.097c0 3.354.073 6.171.163 6.261.273.273 1.435.184 1.873-.143m5.069-9.038c-.675-.675-.533-1.644.333-2.288l.687-.51.66.37c.364.204.766.67.895 1.035l.233.664-.58.618c-.704.749-1.548.79-2.228.111'
            style={{
                fill: '{{replacement-value-color}}',
                fillOpacity: 1,
            }}
            transform='translate(-35.057 38.637)'
        />
    </svg>
);
export default SvgFlugtvejsskilt;
