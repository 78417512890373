const SvgLocationMarkerBlue = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width={100} height={100} viewBox='0 0 30 40'>
        <defs>
            <linearGradient
                id='location-marker-blue_svg__a'
                x1={205.97}
                x2={205.97}
                y1={126.38}
                y2={128.66}
                gradientUnits='userSpaceOnUse'
            >
                <stop
                    offset={0}
                    style={{
                        stopColor: '#000',
                    }}
                />
                <stop
                    offset={1}
                    style={{
                        stopColor: '#666',
                    }}
                />
            </linearGradient>
            <linearGradient
                id='location-marker-blue_svg__b'
                x1={204}
                x2={206}
                y1={119.36}
                y2={119.36}
                gradientUnits='userSpaceOnUse'
            >
                <stop
                    offset={0}
                    style={{
                        stopColor: '#666',
                    }}
                />
                <stop
                    offset={0.125}
                    style={{
                        stopColor: '#b3b3b3',
                    }}
                />
                <stop
                    offset={0.227}
                    style={{
                        stopColor: '#ccc',
                    }}
                />
                <stop
                    offset={0.344}
                    style={{
                        stopColor: '#ebebeb',
                    }}
                />
                <stop
                    offset={0.688}
                    style={{
                        stopColor: '#b3b3b3',
                    }}
                />
                <stop
                    offset={0.859}
                    style={{
                        stopColor: 'gray',
                    }}
                />
                <stop
                    offset={1}
                    style={{
                        stopColor: '#4d4d4d',
                    }}
                />
            </linearGradient>
            <radialGradient
                id='location-marker-blue_svg__c'
                cx={203.71}
                cy={106.36}
                r={5}
                gradientTransform='matrix(1.4 0 0 1.4 -81.2 -42.545)'
                gradientUnits='userSpaceOnUse'
            >
                <stop
                    offset={0}
                    style={{
                        stopColor: '#d5d5ff',
                    }}
                />
                <stop
                    offset={0.143}
                    style={{
                        stopColor: '#8080ff',
                    }}
                />
                <stop
                    offset={0.346}
                    style={{
                        stopColor: '#2a2aff',
                    }}
                />
                <stop
                    offset={0.578}
                    style={{
                        stopColor: '#0000d4',
                    }}
                />
                <stop
                    offset={1}
                    style={{
                        stopColor: 'navy',
                    }}
                />
            </radialGradient>
        </defs>
        <path
            d='M207 127.36c0 .552-.895 1-2 1s-2-.448-2-1 .895-1 2-1 2 .448 2 1'
            style={{
                fillRule: 'evenodd',
                fill: 'url(#location-marker-blue_svg__a)',
            }}
            transform='translate(-190 -97.4)'
        />
        <path
            d='M203 127.45c-.005-.028 0-.065 0-.094 0-.552.895-1 2-1s2 .448 2 1c0 .03.005.066 0 .094-.069-.521-.94-.937-2-.937s-1.931.416-2 .937'
            style={{
                fillRule: 'evenodd',
                fill: '#999',
            }}
            transform='translate(-190 -97.4)'
        />
        <path
            d='M204 111.36h2v16s-.006.465-.992.465c-1.012 0-1.008-.465-1.008-.465z'
            style={{
                fillRule: 'evenodd',
                fill: 'url(#location-marker-blue_svg__b)',
            }}
            transform='translate(-190 -97.4)'
        />
        <path
            d='M210 107.36a5 5 0 1 1-10 0 5 5 0 0 1 10 0'
            style={{
                fillRule: 'evenodd',
                fill: 'url(#location-marker-blue_svg__c)',
            }}
            transform='translate(-190 -97.4)'
        />
        <path
            d='M203.16 127.69c.095.136.231.24.438.344.354.177.86.312 1.406.312.546 0 1.052-.135 1.406-.312.206-.104.343-.208.437-.344a1.635 1.635 0 0 1-.468.313c-.367.172-.84.25-1.375.25s-1.009-.078-1.375-.25a1.634 1.634 0 0 1-.47-.313'
            style={{
                fillRule: 'evenodd',
                fill: '#ececec',
            }}
            transform='translate(-190 -97.4)'
        />
    </svg>
);
export default SvgLocationMarkerBlue;
