
    const Svg2_01_09 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="296.74 232.9 265.08000000000004 136.92999999999998" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M301.74 364.83L428.68 364.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.68 364.83L428.68 237.9Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.68 237.9L301.74 237.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M301.74 237.9L301.74 364.83Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.28 333.1L328.28 269.63Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.28 269.63L352.46 333.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.46 333.1L376.64 269.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.64 269.63L376.64 333.1Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.06 364.83L496.37 301.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.06 301.36L496.37 364.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M514.51 364.83L556.82 301.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M514.51 301.36L556.82 364.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_01_09;

    