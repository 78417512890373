
    const Svg2_04_04 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="316.33 256.89 191.82 100.91000000000003" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M321.33 261.89L321.33 352.8Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M321.33 352.8L503.15 352.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M503.15 352.8L503.15 261.89Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M503.15 261.89L321.33 261.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.71 330.07L366.02 284.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.02 284.62L383.34 330.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.2 314.92L376.85 314.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.33 330.07L396.33 284.62Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.33 284.62L415.81 284.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.81 284.62L422.3 286.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.3 286.78L424.47 288.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.47 288.95L426.63 293.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.63 293.28L426.63 297.61Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.63 297.61L424.47 301.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.47 301.94L422.3 304.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.3 304.1L415.81 306.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.33 306.26L415.81 306.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.81 306.26L422.3 308.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.3 308.43L424.47 310.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.47 310.59L426.63 314.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.63 314.92L426.63 321.42Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.63 321.42L424.47 325.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.47 325.75L422.3 327.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.3 327.91L415.81 330.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.81 330.07L396.33 330.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.78 330.07L459.1 284.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.1 284.62L476.42 330.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M448.28 314.92L469.92 314.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_04_04;

    