
    const Svg2_04_07 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="315.2 224.59 191.82999999999998 100.91" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M320.2 229.59L320.2 320.5Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.2 320.5L502.03 320.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M502.03 320.5L502.03 229.59Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M502.03 229.59L320.2 229.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.88 297.77L363.2 252.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.2 252.32L380.51 297.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.37 282.62L374.02 282.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.5 297.77L393.5 252.32Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.5 252.32L412.98 252.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.98 252.32L419.47 254.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.47 254.48L421.64 256.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.64 256.64L423.8 260.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.8 260.97L423.8 265.3Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.8 265.3L421.64 269.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.64 269.63L419.47 271.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.47 271.8L412.98 273.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.98 273.96L393.5 273.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.65 273.96L423.8 297.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M469.26 258.81L464.93 254.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.93 254.48L458.44 252.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.44 252.32L449.78 252.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.78 252.32L443.28 254.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.28 254.48L438.96 258.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438.96 258.81L438.96 263.14Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438.96 263.14L441.12 267.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.12 267.47L443.28 269.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.28 269.63L447.61 271.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.61 271.8L460.6 276.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.6 276.13L464.93 278.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.93 278.29L467.09 280.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.09 280.45L469.26 284.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M469.26 284.78L469.26 291.28Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M469.26 291.28L464.93 295.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.93 295.61L458.44 297.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.44 297.77L449.78 297.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.78 297.77L443.28 295.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.28 295.61L438.96 291.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_04_07;

    