import { UserPermissions, usePermissions } from '../../restricted';

import CaretakerMap from '../../map/caretaker-map';
import Feature from 'ol/Feature';
import { FeatureProperty } from '../../map/src/feature-utility';
import { IMapProps } from './interface';
import { ISearchSource } from '../../map/src/react-controls/search-features/interfaces';
import { getNextFeatureClick } from '../../../cli/map/features';
import { searchString } from '../../../cli/map/search';
import { shapeTypes } from '../../map/src/EditingControl';
import { useEnhed } from '../../../shared/hooks/use-enhed';
import { useGetEnhedQuery } from '../../../shared/hooks/use-enhed-list';
import useHistoryNavigate from '../../../shared/hooks/extended-hooks/use-history-navigate';
import useMountState from '../../../shared/hooks/use-mount-state';
import usePath from '../../../shared/hooks/use-path';
import useRerenderEffect from '../../../shared/hooks/use-rerender-effect';

const bygContexts = ['Bygningsdelskort', 'Stamdata', 'Syn'];

/** Gets a singular id from a list of features */
const featuresToId = (features: Feature[], idKey: keyof NonNullable<ISearchSource['parents']>): number | null => {
    const getId = (f: Feature) => f.get(FeatureProperty.Search)?.parents?.[idKey];
    const set = features.reduce((set, f) => set.add(getId(f)), new Set<number | undefined>());
    set.delete(undefined);

    if (set.size !== 1) return null;

    const id = set.values().next().value;
    if (typeof id === 'string') return parseInt(id);
    return id;
};

const Map = ({ query }: IMapProps) => {
    const path = usePath();
    const { data } = useGetEnhedQuery('false');
    const { updateId } = useEnhed();
    const { navigate, goBack } = useHistoryNavigate();
    const { hasPermissions } = usePermissions();

    const mount = useMountState();
    useRerenderEffect(() => {
        searchString(query, { animate: false });
    }, [query]);

    useRerenderEffect(() => {
        let abort = false;

        const listen = async () => {
            while (!abort) {
                const features = await getNextFeatureClick();

                // Abort if unmounted
                if (abort) return;

                // Check for identical enhIds in case of multiple features

                // Ensure navigation keeps building id if relevant
                const enhId = featuresToId(features, 'enhId');
                const bygId = featuresToId(features, 'bygId');
                const context = path[0];
                if (path.includes('Choose')) {
                    goBack();
                }
                if (bygId && bygContexts.includes(context)) {
                    navigate(`${enhId}/${context}/${bygId}`);
                }

                // Navigate normally
                if (enhId && data?.some((d) => d.id === enhId)) {
                    updateId(enhId);
                    return;
                }
            }
        };

        listen();

        return () => {
            abort = true;
        };
    }, [mount, data]);

    return (
        <CaretakerMap
            id='extended-enhed-list'
            controls={[
                {
                    name: 'search-features',
                    noGui: true,
                },
                {
                    name: 'lmenu',
                },
                {
                    name: 'gps',
                },
            ]}
            layers={[
                {
                    displayName: hasPermissions(UserPermissions.OK) ? 'Anlæg' : 'Ejendomme',
                    type: 'POLY',
                    shapeType: shapeTypes.Point,
                    url: '/map/layer/-1',
                    minZoom: 0,
                    maxZoom: 9999,
                    isCluster: false,
                    readOnly: true,
                    color: '#CC3333',
                    declutter: true,
                    svgString: 'tank'
                },
                {
                    displayName: 'Bygninger',
                    type: 'POLY',
                    shapeType: shapeTypes.Polygon,
                    url: '/map/layer/-2',
                    minZoom: 0,
                    maxZoom: 13,
                    isCluster: false,
                    readOnly: true,
                    color: '#CC3333',
                    declutter: true,
                },
            ]}
        />
    );
};

export default Map;
