
    const Svg4_05_01 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="319.31 247.65 212.51000000000005 60.62999999999997" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M374.94 303.28L374.94 252.65Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.31 252.65L324.31 303.28Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M476.19 252.65L476.19 303.28Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M526.82 303.28L526.82 252.65Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.31 303.28L374.94 303.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M476.19 303.28L526.82 303.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.94 252.65L324.31 252.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M526.82 252.65L476.19 252.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.94 277.96L387.6 277.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.78 277.96L417.97 277.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.16 277.96L448.35 277.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M463.54 277.96L476.19 277.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.71 268.92L352.3 266.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.3 266.51L348.68 265.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.68 265.31L343.86 265.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.86 265.31L340.24 266.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.24 266.51L337.83 268.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M337.83 268.92L337.83 271.33Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M337.83 271.33L339.04 273.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M339.04 273.74L340.24 274.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.24 274.95L342.65 276.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M342.65 276.15L349.89 278.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.89 278.56L352.3 279.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.3 279.77L353.5 280.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.5 280.98L354.71 283.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.71 283.39L354.71 287Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.71 287L352.3 289.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.3 289.41L348.68 290.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.68 290.62L343.86 290.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.86 290.62L340.24 289.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.24 289.41L337.83 287" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M486.78 290.62L486.78 265.31Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M486.78 265.31L496.42 290.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M496.42 290.62L506.06 265.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M506.06 265.31L506.06 290.62Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.42 273L411.42 252.75Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.14 273L419.14 252.75Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.14 252.75L427.82 252.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.82 252.75L430.71 253.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M430.71 253.71L431.67 254.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.67 254.68L432.64 256.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.64 256.61L432.64 258.54Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.64 258.54L431.67 260.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.67 260.46L430.71 261.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M430.71 261.43L427.82 262.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.82 262.39L419.14 262.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.89 262.39L432.64 273" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg4_05_01;

    