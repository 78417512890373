
    const Svg1_05_16 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="378.7 227.24 99.86000000000001 173.3" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M383.7 395.54L383.7 232.24Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 383.7 395.54 A 102.062 102.062 0 0 0 383.7 232.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 424.53 362.83 A 17.963 17.963 0 0 0 440.86 346.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 424.53 379.2 A 34.293 34.293 0 0 0 457.22 346.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 424.53 395.54 A 50.623 50.623 0 0 0 473.56 346.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_05_16;

    