import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

import { CSSProperties, Suspense } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Outlet } from 'react-router-dom';
import OverviewMap from './overview.map';
import OverviewTree from './overview.tree';
import LoadingScreen from '../loading';
import OverviewToolbar from './overview.toolbar';

const handleStyle: CSSProperties = {
    width: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
};

const OverviewLayout = () => {
    return (
        <PanelGroup direction='horizontal' autoSaveId={'overview-layout'}>
            <Panel defaultSize={25}>
                <div className='h-full w-full flex flex-col'>
                    {/* <div className='flex-none w-full'>
                        <ContextPicker />
                    </div> */}
                    <div className='flex-1 overflow-auto' style={{ paddingTop: '5px' }}>
                        <OverviewTree />
                    </div>
                </div>
            </Panel>
            <PanelResizeHandle style={handleStyle} className='flex justify-center items-center'>
                <MoreVertIcon color='primary' />
            </PanelResizeHandle>
            <Panel defaultSize={25}>
                <OverviewMap />
            </Panel>
            <PanelResizeHandle style={handleStyle} className='flex justify-center items-center'>
                <MoreVertIcon color='primary' />
            </PanelResizeHandle>
            <Panel>
                <Suspense fallback={<LoadingScreen />}>
                    {/* {showBackButton ? (
                        <div style={{ marginTop: '6px', height: '100%' }}>
                            <div style={{ width: '100%', paddingBottom: '6px', display: 'flex' }}>
                                <NavLink to={getBackUrl()}>
                                    <Button icon={'arrowleft'} style={{ display: 'flex', width: '36px' }} />
                                </NavLink>
                            </div>
                            <div style={{ marginTop: '-36px', height: 'calc(100% - 6px)', overflow: 'scroll' }}>
                                <Outlet />
                            </div>
                        </div>
                    ) : ( */}
                    <div style={{ paddingTop: '8px', height: '100%', overflow: 'scroll' }}>
                        <div>
                            <OverviewToolbar />
                        </div>
                        <Outlet />
                    </div>
                    {/* )} */}
                </Suspense>
            </Panel>
        </PanelGroup>
    );
};

export default OverviewLayout;
