import { RadialBarSerie, ResponsiveRadialBar } from '@nivo/radial-bar';
import { addPercent, predata } from './defaults';

import { IData } from './graph';
import useFetch from '../../shared/hooks/fetch-hooks/use-fetch';
import { useState } from 'react';

const GraphMini = ({ url }: { url: string }) => {
    const [fetchdata, isLoading] = useFetch<RadialBarSerie<IData>[]>(url);
    const [first, setFirst] = useState(true);
    const [trueData, setTrueData] = useState(addPercent(predata));

    if (!isLoading && first) {
        if (fetchdata) setTrueData(addPercent(fetchdata));
        setFirst(false);
    }
    return (
        <>
            <div style={{ height: '100%', width: '100%', paddingTop: '0px' }}>
                <ResponsiveRadialBar
                    data={trueData}
                    colors={['#006B3D', '#FFD301', '#E03C32']}
                    radialAxisStart={null}
                    circularAxisOuter={null}
                    endAngle={270}
                    motionConfig='slow'
                    animate
                    tooltip={() => <></>}
                />
            </div>
        </>
    );
};

export default GraphMini;
