import { useEffect, useState } from 'react';

/**
 * useState hook which triggers an additional render. Occassionally useful for components which do not behave as expected.
 *
 * @param initialState the initial state
 * @returns same as useState
 */
const useDoubleState = <T,>(initialState: T) => {
    const [state, setState] = useState<T>(initialState);
    const [prevState, setPrevState] = useState<T>(initialState);

    useEffect(() => {
        if (state !== prevState) setPrevState(state);
    }, [prevState, state]);

    return [state, setState] as const;
};

export default useDoubleState;
