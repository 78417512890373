import { Select } from "ol/interaction";
import { staticImplements } from "../../../../shared/utils/decorators";
import { ControlNames } from "../../interfaces";
import { ICTControl } from "../CTControl";
import CTLayer from "../CTLayer";
import ReactControl from "./react-control-parent";
import { ControlHandler } from "../ControlHandler";
import { map } from "..";
import { MapBrowserEvent } from "ol";
import { ControlEventTarget } from "../ControlHandlerUtils";
import route from "../../../../cli/navigation/route";
import { Tools } from "../EditingControl";


@staticImplements<ICTControl>()
export class AddAddressControl extends ReactControl {
    public static ControlName = ControlNames.AddAddress;
    private layers: CTLayer[];
    private selectTool!: Select;

    constructor(controlHandler: ControlHandler, layers: CTLayer[]) {
        controlHandler.activeToolType = Tools.Select;
        super(controlHandler, {}, {});
        this.layers = layers;
        this.addInteractions();
        this.addControlHandlerListeners();
    }

    private addInteractions() {


        const onMapClick = async(e: MapBrowserEvent<any>) => {
            const labels: string[] = [];
            map?.forEachFeatureAtPixel(e.pixel, (f) => {
                const data = f.get('data');
                if (data?.label && data.label != "null") labels.push(data.label);
            })
            
            const url = new URL(window.location.href);
            if (labels.length > 0) {
                // add if new else remove
                const old = url.searchParams.get('selected')?.split(',') ?? [];
                // make set of labels
                const labelSet = new Set(labels);
                for (const label of labelSet) {
                    if (old.includes(label)) {
                        old.splice(old.indexOf(label), 1);
                    } else {
                        old.push(label);
                    }
                }
                url.searchParams.set('selected', old.join(','));
            } else {
                url.searchParams.set('coord', e.coordinate.toString());
            }
            const to = url.pathname + url.hash + url.search;
            route.changeRoute(to);
            // fetchJordstykke(e.coordinate);
        }

        map?.on('click', onMapClick)
    }

    private addControlHandlerListeners() {
        this.controlHandler.addCustomEventListener('change', (e) => {
            switch (e.eventTarget) {
                case ControlEventTarget.ActiveILayer:
                    this.removeInteractions();
                    this.addInteractions();
                    break;
            }
        })
    }

    private removeInteractions() {
        if (this.selectTool) map!.removeInteraction(this.selectTool);
    }

}