import breadcrumbSlice from '../components/layout/breadcrumbs/slice';
import { centralizedSnackbarReducer } from './hooks/redux-use-centralized-snackbar';
import { configureStore } from '@reduxjs/toolkit';
import { dictionaryReducer } from './hooks/redux-use-dictionary';
import { driftApi } from './hooks/use-enhed-list';
import { fetchReducer } from './hooks/fetch-hooks/use-fetch';
import { historyNavigateReducer } from './hooks/extended-hooks/use-history-navigate';
import { mapReducer } from '../components/map/redux-slice';
import { reduxFetchReducer } from './hooks/fetch-hooks/use-redux-fetch';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import usePortalSlice from './hooks/use-portal/slice';
import useQRScannerSlice from './hooks/use-qr-scanner/slice';
import { userReducer } from './hooks/redux-use-user';

const configure = () =>
    configureStore({
        reducer: {
            centralizedSnackbar: centralizedSnackbarReducer,
            dictionary: dictionaryReducer,
            fetch: fetchReducer,
            historyNavigate: historyNavigateReducer,
            map: mapReducer,
            reduxFetch: reduxFetchReducer,
            user: userReducer,
            usePortal: usePortalSlice.reducer,
            useQRScanner: useQRScannerSlice.reducer,
            breadcrumb: breadcrumbSlice.reducer,

            // RTK reducers
            [driftApi.reducerPath]: driftApi.reducer,
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: ['usePortal/add', 'useQRScanner/add'],
                },
            }).concat(driftApi.middleware),
        devTools: true,
    });

let store: ReturnType<typeof configure>;

const getStore = () => {
    if (store === undefined) resetStore();
    return store;
};

export const resetStore = () => {
    store = configure();
    setupListeners(store.dispatch);
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred til reducer dictionarien ovenover
export type AppDispatch = typeof store.dispatch;

export default getStore;
