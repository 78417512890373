
    const Svg2_05_04 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="295.18 256.45 191.64999999999998 100.82999999999998" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M300.18 261.45L300.18 352.28Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M300.18 352.28L481.83 352.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.83 352.28L481.83 261.45Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.83 261.45L300.18 261.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.3 306.86L368.1 309.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.1 309.83L367.52 312.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.52 312.74L366.57 315.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.57 315.55L365.26 318.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.26 318.22L363.6 320.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.6 320.69L361.65 322.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.65 322.92L359.41 324.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.41 324.88L356.94 326.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.94 326.53L354.28 327.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.28 327.84L351.47 328.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M351.47 328.8L348.55 329.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.55 329.37L345.59 329.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.59 329.57L342.63 329.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M342.63 329.37L339.71 328.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M339.71 328.8L336.9 327.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.9 327.84L334.24 326.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M334.24 326.53L331.77 324.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M331.77 324.88L329.53 322.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M329.53 322.92L327.58 320.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M327.58 320.69L325.93 318.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M325.93 318.22L324.61 315.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.61 315.55L323.66 312.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.66 312.74L323.08 309.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.08 309.83L322.88 306.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.88 306.86L323.08 303.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.08 303.9L323.66 300.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.66 300.98L324.61 298.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.61 298.17L325.93 295.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M325.93 295.51L327.58 293.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M327.58 293.04L329.53 290.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M329.53 290.81L331.77 288.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M331.77 288.85L334.24 287.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M334.24 287.2L336.9 285.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.9 285.88L339.71 284.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M339.71 284.93L342.63 284.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M342.63 284.35L345.59 284.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.59 284.15L348.55 284.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.55 284.35L351.47 284.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M351.47 284.93L354.28 285.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.28 285.88L356.94 287.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.94 287.2L359.41 288.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.41 288.85L361.65 290.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.65 290.81L363.6 293.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.6 293.04L365.26 295.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.26 295.51L366.57 298.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.57 298.17L367.52 300.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.52 300.98L368.1 303.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.1 303.9L368.3 306.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M329.53 322.92L361.65 290.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M329.53 290.81L361.65 322.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_05_04;

    