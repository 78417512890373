
    const Svg3_07_02 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="297.19 245.87 140.06 96.69999999999999" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M302.19 337.57L432.25 337.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.25 337.57L432.25 250.87Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.25 250.87L302.19 250.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M302.19 250.87L302.19 337.57Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M333.53 311.56L333.53 276.88Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M333.53 276.88L345.09 276.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.09 276.88L350.05 278.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.05 278.53L353.35 281.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.35 281.83L355 285.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355 285.13L356.65 290.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.65 290.09L356.65 298.35Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.65 298.35L355 303.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355 303.3L353.35 306.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.35 306.6L350.05 309.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.05 309.91L345.09 311.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.09 311.56L333.53 311.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.22 311.56L381.43 276.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.43 276.88L394.64 311.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M373.17 300L389.68 300" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg3_07_02;

    