
    const Svg1_07_01 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="301.21 236.3 316.66 117.69" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M306.21 348.99L521.59 348.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M521.59 241.3L306.21 241.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M306.21 241.3L306.21 348.99Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M521.59 348.99L521.59 241.3Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.06 281.05L372.5 275.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.5 275.92L367.37 270.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.37 270.79L362.24 268.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.24 268.23L351.99 268.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M351.99 268.23L346.86 270.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.86 270.79L341.73 275.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.73 275.92L339.17 281.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M339.17 281.05L336.6 288.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.6 288.74L336.6 301.56Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.6 301.56L339.17 309.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M339.17 309.25L341.73 314.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.73 314.38L346.86 319.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.86 319.51L351.99 322.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M351.99 322.07L362.24 322.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.24 322.07L367.37 319.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.37 319.51L372.5 314.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.5 314.38L375.06 309.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.4 268.23L408.4 322.07Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.45 268.23L426.35 268.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.6 322.07L436.6 268.23Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.6 268.23L459.68 268.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.68 268.23L467.37 270.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.37 270.79L469.93 273.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M469.93 273.35L472.5 278.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M472.5 278.48L472.5 283.61Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M472.5 283.61L469.93 288.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M469.93 288.74L467.37 291.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.37 291.3L459.68 293.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.68 293.87L436.6 293.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.55 293.87L472.5 322.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M543.13 348.99L571.85 305.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M543.13 305.92L571.85 348.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M584.15 348.99L612.87 305.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M584.15 305.92L612.87 348.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_07_01;

    