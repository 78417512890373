import DxList from 'devextreme-react/list';
import { GetLookupItemRender } from '../enhed-list/enhed-list';
import { IListProps } from './interface';
import { ISearchEnhed } from '../enhed-lookup';
import { ItemClickEvent } from 'devextreme/ui/list';
import LoadingScreen from '../../../screens/loading';
import { useEnhed } from '../../../shared/hooks/use-enhed';
import { useGetEnhedQuery } from '../../../shared/hooks/use-enhed-list';
import useHistoryNavigate from '../../../shared/hooks/extended-hooks/use-history-navigate';
import { useState } from 'react';
import { useCTEffect } from '../../../shared/hooks/use-ct';

const List = ({ query, alternativeOnClick }: IListProps) => {
    const [location, setLocation] = useState<GeolocationPosition | null>(null);
    const { data, isLoading, isSuccess, refetch } = useGetEnhedQuery(
        location ? `false&longitude=${location.coords.longitude}&latitude=${location.coords.latitude}` : 'false'
    );
    const { updateId } = useEnhed();
    const { goBack } = useHistoryNavigate();

    const handleItemClick = (e: ItemClickEvent<ISearchEnhed>) => {
        if (e.itemData == null) return;
        if (window.location.pathname.includes('Choose')) {
            goBack();
        }
        updateId(e.itemData.id);
    };

    useCTEffect(() => {
        navigator.geolocation.getCurrentPosition((e) => {
            setLocation(e);
        });
    }, []);

    const regex = new RegExp(query, 'i');

    if (!isSuccess && isLoading) return <LoadingScreen />;

    return (
        <DxList
            dataSource={data!.filter((d) => regex.test(d.value + d.searchcriteria))}
            itemRender={(d) => GetLookupItemRender({ data: d, showGraph: false, refetch })}
            onItemClick={alternativeOnClick ?? handleItemClick}
            scrollingEnabled={true}
            pageLoadMode={'scrollBottom'}
            id='enhedList'
            height={'100%'}
            style={{ maxWidth: '1200px' }}
        />
    );
};

export default List;
