
    const Svg1_01_21 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="286.29 224.71 192.28999999999996 148.48" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M473.58 353.5L473.58 258.29Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.89 305.89L388.09 302.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.09 302.85L388.67 299.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.67 299.86L389.63 296.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.63 296.96L390.95 294.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.95 294.22L392.62 291.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.62 291.66L394.6 289.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.6 289.34L396.85 287.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.85 287.29L399.35 285.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.35 285.54L402.06 284.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.06 284.13L404.92 283.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.92 283.08L407.89 282.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.89 282.4L410.93 282.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.93 282.11L413.98 282.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.98 282.2L416.99 282.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.99 282.69L419.91 283.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.91 283.56L422.7 284.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.7 284.79L425.31 286.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.31 286.37L427.69 288.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.69 288.28L429.82 290.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.82 290.47L431.64 292.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.64 292.91L433.14 295.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.14 295.57L434.28 298.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.28 298.39L435.06 301.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.06 301.35L435.45 304.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.45 304.37L435.45 307.42Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.45 307.42L435.06 310.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.06 310.44L434.28 313.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.28 313.39L433.14 316.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.14 316.22L431.64 318.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.64 318.88L429.82 321.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.82 321.32L427.69 323.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.69 323.51L425.31 325.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.31 325.42L422.7 327" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.7 327L419.91 328.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.91 328.23L416.99 329.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.99 329.1L413.98 329.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.98 329.59L410.93 329.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.93 329.68L407.89 329.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.89 329.39L404.92 328.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.92 328.71L402.06 327.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.06 327.66L399.35 326.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.35 326.25L396.85 324.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.85 324.5L394.6 322.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.6 322.45L392.62 320.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.62 320.13L390.95 317.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.95 317.57L389.63 314.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.63 314.83L388.67 311.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.67 311.93L388.09 308.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.09 308.94L387.89 305.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.69 272.57L411.69 258.29Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.89 282.09L373.61 267.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.37 305.89L364.09 305.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.89 329.7L373.61 343.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.49 329.7L449.77 343.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.49 282.09L449.77 267.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.69 339.22L411.69 353.5Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M445.01 305.89L459.3 305.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 364.09 248.77 A 10.472 10.472 0 0 0 354.57 258.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 364.09 239.23 A 19.993 19.993 0 0 0 345.03 258.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 364.09 229.71 A 29.513 29.513 0 0 0 335.5 258.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M291.29 368.19L316.68 330.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M291.29 330.11L316.68 368.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M327.56 368.19L352.94 330.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M327.56 330.11L352.94 368.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_21;

    