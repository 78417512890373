
    const Svg1_05_05 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="346.98 188.63 234.87 234.76999999999998" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M408.2 306.01L408.58 312.54 464.42 306.01 464.42 306.01 408.2 306.01Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M408.58 312.54L409.72 318.98 464.42 306.01 464.42 306.01 408.58 312.54Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M409.72 318.98L411.59 325.24 464.42 306.01 464.42 306.01 409.72 318.98Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M411.59 325.24L414.18 331.24 464.42 306.01 464.42 306.01 411.59 325.24Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M414.18 331.24L417.45 336.91 464.42 306.01 464.42 306.01 414.18 331.24Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M417.45 336.91L421.35 342.15 464.42 306.01 464.42 306.01 417.45 336.91Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M421.35 342.15L425.84 346.9 464.42 306.01 464.42 306.01 421.35 342.15Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M425.84 346.9L430.85 351.11 464.42 306.01 464.42 306.01 425.84 346.9Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M430.85 351.11L436.31 354.7 464.42 306.01 464.42 306.01 430.85 351.11Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M436.31 354.7L442.15 357.63 464.42 306.01 464.42 306.01 436.31 354.7Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M442.15 357.63L448.3 359.87 464.42 306.01 464.42 306.01 442.15 357.63Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M448.3 359.87L454.66 361.38 464.42 306.01 464.42 306.01 448.3 359.87Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M454.66 361.38L461.15 362.14 464.42 306.01 464.42 306.01 454.66 361.38Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M461.15 362.14L467.69 362.14 464.42 306.01 464.42 306.01 461.15 362.14Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M467.69 362.14L474.18 361.38 464.42 306.01 464.42 306.01 467.69 362.14Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M474.18 361.38L480.54 359.87 464.42 306.01 464.42 306.01 474.18 361.38Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M480.54 359.87L486.69 357.63 464.42 306.01 464.42 306.01 480.54 359.87Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M486.69 357.63L492.53 354.7 464.42 306.01 464.42 306.01 486.69 357.63Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M492.53 354.7L497.99 351.11 464.42 306.01 464.42 306.01 492.53 354.7Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M497.99 351.11L503 346.9 464.42 306.01 464.42 306.01 497.99 351.11Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M503 346.9L507.48 342.15 464.42 306.01 464.42 306.01 503 346.9Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M507.48 342.15L511.39 336.91 464.42 306.01 464.42 306.01 507.48 342.15Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M511.39 336.91L514.66 331.24 464.42 306.01 464.42 306.01 511.39 336.91Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M514.66 331.24L517.25 325.24 464.42 306.01 464.42 306.01 514.66 331.24Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M517.25 325.24L519.12 318.98 464.42 306.01 464.42 306.01 517.25 325.24Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M519.12 318.98L520.26 312.54 464.42 306.01 464.42 306.01 519.12 318.98Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M520.26 312.54L520.64 306.01 464.42 306.01 464.42 306.01 520.26 312.54Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M520.64 306.01L520.26 299.49 464.42 306.01 464.42 306.01 520.64 306.01Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M520.26 299.49L519.12 293.05 464.42 306.01 464.42 306.01 520.26 299.49Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M519.12 293.05L517.25 286.79 464.42 306.01 464.42 306.01 519.12 293.05Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M517.25 286.79L514.66 280.78 464.42 306.01 464.42 306.01 517.25 286.79Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M514.66 280.78L511.39 275.12 464.42 306.01 464.42 306.01 514.66 280.78Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M511.39 275.12L507.48 269.88 464.42 306.01 464.42 306.01 511.39 275.12Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M507.48 269.88L503 265.12 464.42 306.01 464.42 306.01 507.48 269.88Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M503 265.12L497.99 260.92 464.42 306.01 464.42 306.01 503 265.12Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M497.99 260.92L492.53 257.33 464.42 306.01 464.42 306.01 497.99 260.92Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M492.53 257.33L486.69 254.39 464.42 306.01 464.42 306.01 492.53 257.33Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M486.69 254.39L480.54 252.16 464.42 306.01 464.42 306.01 486.69 254.39Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M480.54 252.16L474.18 250.65 464.42 306.01 464.42 306.01 480.54 252.16Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M474.18 250.65L467.69 249.89 464.42 306.01 464.42 306.01 474.18 250.65Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M467.69 249.89L461.15 249.89 464.42 306.01 464.42 306.01 467.69 249.89Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M461.15 249.89L454.66 250.65 464.42 306.01 464.42 306.01 461.15 249.89Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M454.66 250.65L448.3 252.16 464.42 306.01 464.42 306.01 454.66 250.65Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M448.3 252.16L442.15 254.39 464.42 306.01 464.42 306.01 448.3 252.16Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M442.15 254.39L436.31 257.33 464.42 306.01 464.42 306.01 442.15 254.39Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M436.31 257.33L430.85 260.92 464.42 306.01 464.42 306.01 436.31 257.33Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M430.85 260.92L425.84 265.12 464.42 306.01 464.42 306.01 430.85 260.92Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M425.84 265.12L421.35 269.88 464.42 306.01 464.42 306.01 425.84 265.12Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M421.35 269.88L417.45 275.12 464.42 306.01 464.42 306.01 421.35 269.88Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M417.45 275.12L414.18 280.78 464.42 306.01 464.42 306.01 417.45 275.12Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M414.18 280.78L411.59 286.79 464.42 306.01 464.42 306.01 414.18 280.78Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M411.59 286.79L409.72 293.05 464.42 306.01 464.42 306.01 411.59 286.79Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M409.72 293.05L408.58 299.49 464.42 306.01 464.42 306.01 409.72 293.05Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M408.58 299.49L408.2 306.01 464.42 306.01 464.42 306.01 408.58 299.49Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M576.85 306.01L576.66 312.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M576.66 312.67L576.07 319.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M576.07 319.31L575.08 325.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M575.08 325.9L573.71 332.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M573.71 332.42L571.95 338.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M571.95 338.85L569.82 345.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M569.82 345.16L567.31 351.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M567.31 351.34L564.45 357.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M564.45 357.35L561.23 363.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M561.23 363.19L557.67 368.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M557.67 368.83L553.79 374.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M553.79 374.24L549.59 379.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M549.59 379.41L545.09 384.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M545.09 384.33L540.31 388.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M540.31 388.97L535.26 393.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M535.26 393.32L529.97 397.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M529.97 397.37L524.44 401.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M524.44 401.09L518.7 404.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M518.7 404.48L512.77 407.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M512.77 407.52L506.68 410.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M506.68 410.21L500.43 412.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M500.43 412.53L494.06 414.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M494.06 414.47L487.58 416.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M487.58 416.04L481.02 417.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.02 417.22L474.4 418.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M474.4 418.01L467.75 418.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.75 418.4L461.09 418.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.09 418.4L454.44 418.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.44 418.01L447.82 417.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.82 417.22L441.26 416.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.26 416.04L434.78 414.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.78 414.47L428.41 412.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.41 412.53L422.16 410.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.16 410.21L416.06 407.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.06 407.52L410.14 404.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.14 404.48L404.4 401.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.4 401.09L398.87 397.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.87 397.37L393.57 393.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.57 393.32L388.53 388.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.53 388.97L383.75 384.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.75 384.33L379.25 379.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.25 379.41L375.05 374.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.05 374.24L371.16 368.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.16 368.83L367.61 363.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.61 363.19L364.39 357.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.39 357.35L361.52 351.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.52 351.34L359.02 345.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.02 345.16L356.89 338.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.89 338.85L355.13 332.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.13 332.42L353.76 325.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.76 325.9L352.77 319.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.77 319.31L352.18 312.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.18 312.67L351.98 306.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M351.98 306.01L352.18 299.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.18 299.35L352.77 292.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.77 292.72L353.76 286.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.76 286.12L355.13 279.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.13 279.6L356.89 273.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.89 273.18L359.02 266.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.02 266.86L361.52 260.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.52 260.69L364.39 254.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.39 254.67L367.61 248.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.61 248.84L371.16 243.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.16 243.2L375.05 237.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.05 237.79L379.25 232.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.25 232.61L383.75 227.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.75 227.7L388.53 223.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.53 223.05L393.57 218.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.57 218.7L398.87 214.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.87 214.66L404.4 210.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.4 210.94L410.14 207.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.14 207.55L416.06 204.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.06 204.51L422.16 201.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.16 201.82L428.41 199.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.41 199.5L434.78 197.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.78 197.55L441.26 195.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.26 195.99L447.82 194.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.82 194.81L454.44 194.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.44 194.02L461.09 193.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.09 193.63L467.75 193.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.75 193.63L474.4 194.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M474.4 194.02L481.02 194.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.02 194.81L487.58 195.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M487.58 195.99L494.06 197.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M494.06 197.55L500.43 199.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M500.43 199.5L506.68 201.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M506.68 201.82L512.77 204.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M512.77 204.51L518.7 207.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M518.7 207.55L524.44 210.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M524.44 210.94L529.97 214.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M529.97 214.66L535.26 218.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M535.26 218.7L540.31 223.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M540.31 223.05L545.09 227.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M545.09 227.7L549.59 232.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M549.59 232.61L553.79 237.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M553.79 237.79L557.67 243.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M557.67 243.2L561.23 248.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M561.23 248.84L564.45 254.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M564.45 254.67L567.31 260.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M567.31 260.69L569.82 266.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M569.82 266.86L571.95 273.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M571.95 273.18L573.71 279.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M573.71 279.6L575.08 286.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M575.08 286.12L576.07 292.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M576.07 292.72L576.66 299.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M576.66 299.35L576.85 306.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_05_05;

    