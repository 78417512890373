import { RefObject, useEffect, useState } from 'react';

const getWidth = (el: Element) => el?.getBoundingClientRect().width;

/**
 * Hook that returns the width of a ref element. Useful for adapting width to witdh of something else.
 *
 * Has no way of detecting if the ref changes, so if the ref changes, the hook will not update untill next rerender.
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @param element The element to get the width of
 * @param defaultWidth The default width to return if the element is not yet rendered
 * @returns a string with widtch in pixels
 */
const useElementWidth = (ref: RefObject<Element | null>, defaultWidth?: string) => {
    const [width, setWidth] = useState<number | undefined>();

    useEffect(() => {
        if (!ref.current) return;

        const element = ref.current;
        const observer = new ResizeObserver(() => setWidth(getWidth(element)));
        observer.observe(element);
        return () => observer.disconnect();
    }, [ref]);

    if (width) return width + 'px';
    return defaultWidth;
};

/**
 *
 * same as useElementWidth, but returns the width as a number instead of a string
 * @returns number of pixles as number
 */
export const useElementWidthNumber = (ref: RefObject<Element | null>, defaultWidth?: number) => {
    const [width, setWidth] = useState<number | undefined>();

    useEffect(() => {
        if (!ref.current) return;

        const element = ref.current;
        const observer = new ResizeObserver(() => setWidth(getWidth(element)));
        observer.observe(element);
        return () => observer.disconnect();
    }, [ref]);

    if (width) return width;
    return defaultWidth;
};

export default useElementWidth;
