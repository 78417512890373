
    const SvgXx_06_06 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="289.57 259.63 220.06 84.74000000000001" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M294.57 302L504.63 302" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.29 264.63L380.92 339.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.92 339.37L364.2 331.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435 272.99L418.29 264.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_06_06;

    