
    const SvgXx_06_01 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="325.65 311.74 165.66000000000003 11" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M330.65 316.74L486.31 316.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_06_01;

    