
    const SvgXx_06_09 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="240.01 297.54 229.74 57.26999999999998" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M464.75 349.81L299.26 349.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 464.75 349.81 A 47.546 47.546 0 1 0 417.21 302.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.21 302.54L245.01 302.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_06_09;

    