import { useEffect, useRef } from 'react';
import useStable from './use-stable';

/** 
 * useEffect which doesn't run on initial render.
 * Passing an empty dependency list will cause it to only run on the second render 
 */
const useRerenderEffect = (effect: React.EffectCallback, deps?: React.DependencyList | undefined) => {
    const renderCount = useRef(-1);
    renderCount.current += 1;
    const depsRef = useStable(deps);
    const effectRef = useStable(effect);

    const _deps = deps?.length === 0 ? undefined : deps;

    useEffect(() => {
        // Skip first render
        if (renderCount.current === 0) return;
        // If empty deps list, only run on second render
        if (depsRef.current?.length === 0 && renderCount.current !== 1) return;
        return effectRef.current();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, _deps);
};

export default useRerenderEffect;
