
    const Svg2_05_07 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="290.89 211.34 205.68 139.58" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M426.13 313.54L425.93 317.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.93 317.1L425.34 320.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.34 320.62L424.37 324.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.37 324.06L423.03 327.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.03 327.36L421.33 330.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.33 330.5L419.3 333.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.3 333.44L416.95 336.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.95 336.13L414.33 338.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.33 338.54L411.45 340.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.45 340.66L408.36 342.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.36 342.44L405.09 343.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.09 343.88L401.68 344.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.68 344.94L398.18 345.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.18 345.63L394.62 345.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.62 345.92L391.05 345.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.05 345.82L387.52 345.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.52 345.33L384.06 344.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.06 344.46L380.72 343.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.72 343.21L377.53 341.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.53 341.59L374.54 339.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.54 339.64L371.79 337.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.79 337.37L369.3 334.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.3 334.81L367.11 332" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.11 332L365.24 328.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.24 328.96L363.71 325.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.71 325.73L362.55 322.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.55 322.35L361.77 318.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.77 318.87L361.38 315.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.38 315.32L361.38 311.75Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.38 311.75L361.77 308.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.77 308.2L362.55 304.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.55 304.72L363.71 301.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.71 301.34L365.24 298.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.24 298.12L367.11 295.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.11 295.08L369.3 292.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.3 292.26L371.79 289.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.79 289.7L374.54 287.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.54 287.43L377.53 285.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.53 285.48L380.72 283.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.72 283.87L384.06 282.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.06 282.62L387.52 281.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.52 281.74L391.05 281.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.05 281.25L394.62 281.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.62 281.15L398.18 281.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.18 281.45L401.68 282.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.68 282.13L405.09 283.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.09 283.19L408.36 284.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.36 284.63L411.45 286.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.45 286.41L414.33 288.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.33 288.53L416.95 290.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.95 290.95L419.3 293.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.3 293.64L421.33 296.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.33 296.57L423.03 299.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.03 299.71L424.37 303.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.37 303.02L425.34 306.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.34 306.45L425.93 309.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.93 309.97L426.13 313.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.82 336.44L416.64 290.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.82 290.63L416.64 336.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M295.89 281.14L361.33 281.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.33 281.14L361.33 216.34Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.33 216.34L426.13 216.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.13 216.34L426.13 281.14Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.13 281.14L491.57 281.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_05_07;

    