
    const Svg1_01_08 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="246.19 173.73 265.56 194.58" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M471.28 256.84L329.29 256.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M329.31 278.13L329.31 235.55Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M471.26 278.13L471.26 235.55Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 293.82 207.16 A 15.615 15.615 0 0 0 279.62 221.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 293.82 192.93 A 29.811 29.811 0 0 0 265.4 221.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 293.82 178.73 A 44.007 44.007 0 0 0 251.19 221.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M293.82 363.31L506.75 363.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M506.75 363.31L506.75 221.35Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M506.75 221.35L293.82 221.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M293.82 221.35L293.82 363.31Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.76 292.33L435.57 296.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.57 296.04L434.99 299.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.99 299.71L434.02 303.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.02 303.3L432.69 306.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.69 306.76L431.01 310.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.01 310.07L428.98 313.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.98 313.19L426.65 316.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.65 316.08L424.02 318.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.02 318.7L421.13 321.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.13 321.04L418.02 323.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.02 323.06L414.71 324.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.71 324.75L411.24 326.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.24 326.08L407.65 327.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.65 327.04L403.98 327.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.98 327.62L400.27 327.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.27 327.82L396.56 327.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.56 327.62L392.89 327.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.89 327.04L389.31 326.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.31 326.08L385.84 324.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M385.84 324.75L382.53 323.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.53 323.06L379.41 321.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.41 321.04L376.53 318.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.53 318.7L373.9 316.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M373.9 316.08L371.56 313.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.56 313.19L369.54 310.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.54 310.07L367.85 306.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.85 306.76L366.52 303.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.52 303.3L365.56 299.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.56 299.71L364.98 296.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.98 296.04L364.78 292.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.78 292.33L364.98 288.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.98 288.62L365.56 284.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.56 284.95L366.52 281.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.52 281.36L367.85 277.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.85 277.89L369.54 274.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.54 274.58L371.56 271.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.56 271.47L373.9 268.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M373.9 268.58L376.53 265.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.53 265.96L379.41 263.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.41 263.62L382.53 261.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.53 261.59L385.84 259.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M385.84 259.91L389.31 258.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.31 258.58L392.89 257.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.89 257.62L396.56 257.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.56 257.03L400.27 256.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.27 256.84L403.98 257.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.98 257.03L407.65 257.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.65 257.62L411.24 258.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.24 258.58L414.71 259.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.71 259.91L418.02 261.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.02 261.59L421.13 263.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.13 263.62L424.02 265.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.02 265.96L426.65 268.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.65 268.58L428.98 271.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.98 271.47L431.01 274.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.01 274.58L432.69 277.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.69 277.89L434.02 281.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.02 281.36L434.99 284.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.99 284.95L435.57 288.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.57 288.62L435.76 292.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_08;

    