
    const Svg1_01_31 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="318.82 232.75 153.82999999999998 153.83999999999997" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M323.82 321.18L323.82 381.59Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.82 381.59L467.65 381.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.65 381.59L467.65 237.75Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.65 237.75L323.82 237.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.82 237.75L323.82 321.18Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.23 273.71L389.62 345.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.62 345.63L417.02 273.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_31;

    