import { QrScanner, QrScannerProps } from '@yudiel/react-qr-scanner';
import { useAppDispatch, useAppSelector } from '../redux-base-hooks';
import { useEffect, useState } from 'react';
import useQRScannerSlice, { callbackStore } from './slice';

import Popup from 'devextreme-react/popup';
import styles from './scanners.module.css';

const Scanner = ({ scannerKey }: { scannerKey: string }) => {
    const [errorMessage, setErrorMessage] = useState<string>();
    const [, setOrientation] = useState<number>(0);

    const dispatch = useAppDispatch();
    const scanner = useAppSelector((state) => state.useQRScanner.find((s) => s.key === scannerKey))!;

    const handleError: QrScannerProps['onError'] = (e) => {
        console.log('QR Error', e);
        switch (e.name) {
            case 'NotAllowedError':
                setErrorMessage('Du skal give tilladelse til at bruge kameraet for at scanne QR koder');
                break;
            case 'NotFoundError':
                setErrorMessage('Intet kamera blev fundet');
                break;
            case 'NotReadableError':
                setErrorMessage('Kameraet er ikke tilgængeligt. Er det i brug af et andet program?');
                break;
            default:
                setErrorMessage('Der skete en ukendt fejl');
        }
        callbackStore[scannerKey].onError(e);
    };

    useEffect(() => {
        const handleResize = () => {
            setOrientation((o) => o + 1);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Popup
            visible={scanner.active}
            onHiding={() => dispatch(useQRScannerSlice.actions.stop({ key: scannerKey }))}
            showCloseButton
            wrapperAttr={{ class: styles['fix-popup-content-size'] }}
            {...scanner.PopupProps}
            title={'Scan QR kode'}
            position={{ of: document.documentElement }}
        >
            {/** This div prevents popup from attempting to remove */}
            <div>
                {errorMessage ||
                    (scanner.active && (
                        <QrScanner
                            {...scanner.QRProps}
                            onError={handleError}
                            onDecode={callbackStore[scannerKey].onDecode}
                        />
                    ))}
            </div>
        </Popup>
    );
};

const Scanners = () => {
    const scanners = useAppSelector((state) => state.useQRScanner.map((s) => s.key));

    return (
        <>
            {scanners.map((k) => (
                <Scanner key={k} scannerKey={k} />
            ))}
        </>
    );
};

export default Scanners;
