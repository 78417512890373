import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import BarChartIcon from '@mui/icons-material/BarChart';
import { Tile } from '../tiles.screen';

const TestingTilesScreen = () => {
    return (
        <Box height='100%' width='100%' padding='40px'>
            <Grid container justifyContent={'center'} spacing={2}>
                <Tile
                    text='Autogrid Flexing'
                    icon={<BarChartIcon fontSize='large' />}
                    to={`AutoGridFlex`}
                />
            </Grid>
        </Box>
    );
};

export default TestingTilesScreen;
