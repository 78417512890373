import { IDictionary } from './types';

const inverter = (num: number, invert: boolean = true) => (invert ? num : -num);

export const sortObjectArray_Number = (key: string) => (a: IDictionary, b: IDictionary) =>
    (b[key] ?? -1) - a[key ?? -1];

export const sortObjectArray_String =
    (keys: string | string[], asc: boolean = true) =>
    (a: IDictionary, b: IDictionary) => {
        const _keys = typeof keys === 'string' ? [keys] : keys;

        const getVal = (obj: IDictionary) => {
            let val: string | IDictionary = obj;
            for (let i = 0; i < _keys.length; i++) {
                if (val == null) return null;
                val = (val as IDictionary)[_keys[i]];
            }

            return val as string;
        };

        const _a = getVal(a);
        const _b = getVal(b);

        if (_b == null) return inverter(-1, asc);
        if (_a == null) return inverter(1, asc);
        if (_a > _b) return inverter(1, asc);
        if (_a < _b) return inverter(-1, asc);
        return 0;
    };

/**
 * Dictionary of sorting functions
 */
const sortBy = {
    /**
     * Functions for sorting objects based on the value of a key
     */
    object: {
        number: sortObjectArray_Number,
        string: sortObjectArray_String,
    },
};

export default sortBy;
