
    const Svg1_01_20 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="284.45 212.18 185.05 165.25" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M 346.1 325.31 A 93.516 93.516 0 0 0 346.1 222.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M294.62 273.83L356.4 299.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M294.62 273.83L356.4 248.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.54 273.83L376.99 273.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.43 273.83L413.02 273.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.46 273.83L449.05 273.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.4 248.09L376.99 248.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.43 248.09L413.02 248.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.46 248.09L449.05 248.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.4 299.57L376.99 299.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.43 299.57L413.02 299.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.46 299.57L449.05 299.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.5 325.31L464.5 222.35Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 320.36 237.8 A 11.325 11.325 0 0 0 310.07 248.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 320.36 227.48 A 21.62 21.62 0 0 0 299.75 248.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 320.36 217.18 A 31.916 31.916 0 0 0 289.45 248.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.98 372.43L402.44 331.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.98 331.25L402.44 372.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.2 372.43L441.66 331.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.2 331.25L441.66 372.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_20;

    