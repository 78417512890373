import AutoGrid from "../../../../components/auto-grid/auto-grid"

const AutoGridFlexTest = () => {
    return (
        <>
            <AutoGrid url='/Byg/Aktivitet?delid=157563&sfbkode=4'
                layoutJson={{
                    columns: [
                        {
                            dataField: 'id'
                        },
                        {
                            dataField: 'sfbkode'
                        },
                        {
                            dataField: 'delid'
                        }
                    ]
                }} />
        </>
    )
}

export default AutoGridFlexTest;
