
    const Svg2_02_01 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="331.55 258.51 111.88999999999999 111.88999999999999" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M336.55 365.4L438.44 365.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438.44 365.4L438.44 263.51Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438.44 263.51L336.55 263.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.55 263.51L336.55 365.4Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.96 314.46L412.77 317.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.77 317.59L412.2 320.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.2 320.67L411.24 323.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.24 323.66L409.93 326.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.93 326.51L408.28 329.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.28 329.17L406.32 331.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.32 331.62L404.06 333.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.06 333.8L401.56 335.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.56 335.69L398.85 337.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.85 337.26L395.96 338.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.96 338.48L392.94 339.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.94 339.34L389.84 339.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.84 339.82L386.71 339.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.71 339.92L383.58 339.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.58 339.63L380.52 338.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.52 338.96L377.56 337.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.56 337.91L374.75 336.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.75 336.52L372.14 334.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.14 334.78L369.76 332.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.76 332.74L367.65 330.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.65 330.43L365.83 327.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.83 327.87L364.35 325.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.35 325.1L363.22 322.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.22 322.18L362.45 319.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.45 319.14L362.07 316.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.07 316.02L362.07 312.89Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.07 312.89L362.45 309.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.45 309.78L363.22 306.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.22 306.73L364.35 303.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.35 303.81L365.83 301.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.83 301.05L367.65 298.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.65 298.49L369.76 296.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.76 296.17L372.14 294.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.14 294.13L374.75 292.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.75 292.4L377.56 291" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.56 291L380.52 289.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.52 289.96L383.58 289.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.58 289.28L386.71 289" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.71 289L389.84 289.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.84 289.09L392.94 289.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.94 289.57L395.96 290.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.96 290.43L398.85 291.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.85 291.65L401.56 293.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.56 293.22L404.06 295.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.06 295.11L406.32 297.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.32 297.3L408.28 299.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.28 299.74L409.93 302.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.93 302.41L411.24 305.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.24 305.25L412.2 308.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.2 308.24L412.77 311.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.77 311.33L412.96 314.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_02_01;

    