const SvgSkydedoer = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        width='30'
        zoomAndPan='magnify'
        viewBox='0 0 30 30.000001'
        height='30'
        preserveAspectRatio='xMidYMid meet'
        version='1.0'
    >
        <defs>
            <clipPath id='7b824f449d'>
                <path
                    d='M 0.484375 9.074219 L 29.515625 9.074219 L 29.515625 19.960938 L 0.484375 19.960938 Z M 0.484375 9.074219 '
                    clipRule='nonzero'
                />
            </clipPath>
            <clipPath id='a0636cbba3'>
                <path d='M 0.484375 24 L 18 24 L 18 27 L 0.484375 27 Z M 0.484375 24 ' clipRule='nonzero' />
            </clipPath>
            <clipPath id='dbf18f5b94'>
                <path d='M 15 2 L 29.515625 2 L 29.515625 5 L 15 5 Z M 15 2 ' clipRule='nonzero' />
            </clipPath>
        </defs>
        <g clipPath='url(#7b824f449d)'>
            <path
                fill='{{replacement-value-color}}'
                d='M 0.484375 9.074219 L 29.53125 9.074219 L 29.53125 19.960938 L 0.484375 19.960938 Z M 0.484375 9.074219 '
                fillOpacity='1'
                fillRule='nonzero'
            />
        </g>
        <g clipPath='url(#a0636cbba3)'>
            <path
                strokeLinecap='butt'
                transform='matrix(0.725806, 0, 0, 0.725806, -8.225807, 24.677421)'
                fill='none'
                strokeLinejoin='miter'
                d='M -0.0010411 0.998782 L 34.502605 0.998782 '
                stroke='{{replacement-value-color}}'
                strokeWidth='2'
                strokeOpacity='1'
                strokeMiterlimit='4'
            />
        </g>
        <path
            strokeLinecap='round'
            transform='matrix(0.725806, 0, 0, 0.725806, -8.225807, 24.677421)'
            fillOpacity='1'
            fill='{{replacement-value-color}}'
            fillRule='nonzero'
            strokeLinejoin='round'
            d='M 34.997744 -1.998961 L 39.001911 0.998782 L 34.997744 4.001907 Z M 34.997744 -1.998961 '
            stroke='{{replacement-value-color}}'
            strokeWidth='2'
            strokeOpacity='1'
            strokeMiterlimit='4'
        />
        <g clipPath='url(#dbf18f5b94)'>
            <path
                strokeLinecap='butt'
                transform='matrix(0.725806, 0, 0, 0.725806, 12.096774, 2.903228)'
                fill='none'
                strokeLinejoin='miter'
                d='M 5.501563 0.999824 L 39.999828 0.999824 '
                stroke='{{replacement-value-color}}'
                strokeWidth='2'
                strokeOpacity='1'
                strokeMiterlimit='4'
            />
        </g>
        <path
            strokeLinecap='round'
            transform='matrix(0.725806, 0, 0, 0.725806, 12.096774, 2.903228)'
            fillOpacity='1'
            fill='{{replacement-value-color}}'
            fillRule='nonzero'
            strokeLinejoin='round'
            d='M 5.001043 -1.997919 L 1.002258 0.999824 L 5.001043 3.997567 Z M 5.001043 -1.997919 '
            stroke='{{replacement-value-color}}'
            strokeWidth='2'
            strokeOpacity='1'
            strokeMiterlimit='4'
        />
    </svg>
);
export default SvgSkydedoer;
