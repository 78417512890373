const SvgSirene = () => (
    <svg xmlns='http://www.w3.org/2000/svg' xmlSpace='preserve' width={31.016} height={28.524}>
        <path
            d='M-1.082-11.491c0-.76-.151-1.353-.366-1.44-.219-.088-.16-.157.146-.17l.512-.024v1.463c0 .805-.066 1.463-.146 1.463-.08 0-.146-.581-.146-1.292m-17.337-1.856-12.216-.077v-2.068l.718-.718c.395-.395.837-.719.982-.719.145 0 .386-.428.536-.95l.273-.952H-7.96l.28.951c.154.523.428.951.608.951s.613.305.963.678l.637.677v1.047c0 .587-.161 1.093-.366 1.151-.201.058-5.863.07-12.582.029m-7.728-7.255c-.098-.256-.112-1.614-.032-3.018l.145-2.552.588-1.33c.747-1.689 2.425-3.372 4.148-4.16l1.343-.616 2.048.014 2.048.013 1.205.625c1.835.952 2.835 1.914 3.77 3.628l.852 1.56.012 3.072.013 3.072-7.98.078-7.981.078zm13.068-5.005c0-1.843-1.846-3.995-3.755-4.377l-.927-.185v.453c0 .257.438.597 1.015.788 1.23.406 2.374 1.698 2.652 2.995.113.527.265 1.059.338 1.182.25.425.677-.114.677-.856m-18.727.912v-.451l2.245.085 2.244.086.12.366.122.365h-4.731zm22.892.098.126-.378h2.244c3.084 0 3.04.562-.053.67l-2.443.086zm-17.562-7.922c-1.577-1.597-1.793-1.966-1.15-1.966.507 0 3.497 3.053 3.24 3.309-.351.352-.528.238-2.09-1.343m14.86 1.169c0-.475 2.677-3.135 3.155-3.135.71 0 .327.782-1.044 2.133-1.397 1.376-2.111 1.715-2.111 1.002m-6.84-2.707c-.28-.731-.208-3.739.102-4.229l.276-.437.319.319c.42.42.257 4.567-.185 4.713-.181.06-.412-.105-.512-.366'
            style={{
                fill: '{{replacement-value-color}}',
                fillOpacity: 1,
            }}
            transform='translate(31.806 38.723)'
        />
    </svg>
);
export default SvgSirene;
