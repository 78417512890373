import { CenterContainer, PaddedPaper } from '../../../mui/styled-mui';

import AppProviders from '../../../../App-providers';
import Box from '@mui/material/Box/Box';
import CTLayer from '../CTLayer';
import { Children } from '../../../../shared/utils/props';
import { ClickableFeature } from '../ClickableFeatures';
import { IDictionary } from '../../../../shared/utils/types';
import Overlay from 'ol/Overlay';
import PluggableMap from 'ol/PluggableMap';
import ReportDriftErrorOverlay from '../react-controls/report-drift-error/clickable-feature-overlay';
import ServiceOverlay from '../react-controls/service/service-overlay';
import { createRoot } from 'react-dom/client';
import styled from '@mui/styles/styled';
import { useMapContentMaxWith } from '../react-controls/base-control';

export interface IOverlayProps {
    map: PluggableMap;
    layer: CTLayer;
    feature: ClickableFeature;
    overlay: Overlay;
    close(): void;
}

//#region Base overlay

const OverlayPaper = styled(PaddedPaper)(() => ({
    pointerEvents: 'none',
}));

const OverlayBox = styled(Box)(() => ({
    pointerEvents: 'auto',
}));

export const BaseOverlay = ({ children }: Children) => {
    const contentMaxWidth = useMapContentMaxWith();

    return (
        <CenterContainer>
            <OverlayPaper
                elevation={6}
                sx={{
                    maxWidth: contentMaxWidth,
                }}
            >
                <OverlayBox>{children}</OverlayBox>
            </OverlayPaper>
        </CenterContainer>
    );
};

//#endregion Base overlay

export enum OverlayKeys {
    reportError = 'report-error',
    serviceMap = 'service-map',
}

const overlays: IDictionary<(props: IOverlayProps) => JSX.Element> = {
    [OverlayKeys.reportError]: ReportDriftErrorOverlay,
    [OverlayKeys.serviceMap]: ServiceOverlay,
};

const getOverlayElement = (map: PluggableMap, layer: CTLayer, feature: ClickableFeature, overlay: Overlay) => {
    const key = layer.options?.overlayKey;
    // Return if key doesn't exist
    if (key === undefined || !Object.values(OverlayKeys).includes(key)) return undefined;
    map.getOverlays()
        .getArray()
        .forEach((overlay) => map.removeOverlay(overlay));
    const div = document.createElement('div');
    const close = () => map.removeOverlay(overlay);
    const RenderElement = overlays[key];

    const root = createRoot(div);
    root.render(
        <AppProviders>
            <BaseOverlay>
                <RenderElement
                    {...{
                        map,
                        layer,
                        feature,
                        overlay,
                        close,
                    }}
                />
            </BaseOverlay>
        </AppProviders>
    );

    return div;
};

export default getOverlayElement;
