
    const Svg2_07_04 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="207.5 235.26 445.74 88.98000000000002" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M212.5 303.84L648.24 303.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.08 283.83L393.68 240.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.68 240.26L410.28 283.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.3 269.31L404.05 269.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.78 246.48L447.63 242.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.63 242.33L441.4 240.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.4 240.26L433.1 240.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.1 240.26L426.88 242.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.88 242.33L422.73 246.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.73 246.48L422.73 250.63Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.73 250.63L424.8 254.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.8 254.78L426.88 256.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.88 256.86L431.03 258.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.03 258.93L443.48 263.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.48 263.08L447.63 265.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.63 265.16L449.7 267.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.7 267.23L451.78 271.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.78 271.38L451.78 277.61Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.78 277.61L447.63 281.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.63 281.76L441.4 283.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.4 283.83L433.1 283.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.1 283.83L426.88 281.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.88 281.76L422.73 277.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.23 283.83L464.23 240.26Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.23 240.26L482.9 240.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M482.9 240.26L489.12 242.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M489.12 242.33L491.2 244.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M491.2 244.41L493.27 248.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M493.27 248.56L493.27 254.78Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M493.27 254.78L491.2 258.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M491.2 258.93L489.12 261.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M489.12 261.01L482.9 263.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M482.9 263.08L464.23 263.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.2 288.43L322.39 319.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_07_04;

    