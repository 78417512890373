
    const Svg2_03_03 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="306.27 298.6 192.76 91.21999999999997" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M362.04 384.82L362.04 303.6Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.26 384.82L443.26 303.6Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 494.03 303.6 A 91.376 91.376 0 0 0 311.27 303.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M311.27 303.6L494.03 303.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_03_03;

    