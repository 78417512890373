
    const Svg4_05_18 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="276.07 247.11 287.46999999999997 120.99000000000001" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M336.56 252.11L281.07 252.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M281.07 252.11L281.07 307.6Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M558.54 307.6L558.54 363.1Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M558.54 363.1L503.05 363.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg4_05_18;

    