
    const Svg3_01_07 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="273.48 261.54 265.52 82.69" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M278.48 266.54L416.89 266.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.29 266.54L382.29 278.07Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M313.08 266.54L313.08 278.07Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 313.08 278.07 A 34.605 34.605 0 0 0 382.29 278.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.78 293.1L421.95 293.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.95 293.1L408.76 310.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.76 310.67L415.36 310.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.36 310.67L419.75 312.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.75 312.87L421.95 315.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.95 315.07L424.14 321.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.14 321.66L424.14 326.05Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.14 326.05L421.95 332.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.95 332.64L417.55 337.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.55 337.04L410.96 339.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.96 339.23L404.37 339.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.37 339.23L397.78 337.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.78 337.04L395.58 334.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.58 334.84L393.38 330.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M463.69 299.69L461.49 295.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.49 295.29L454.9 293.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.9 293.1L450.51 293.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.51 293.1L443.92 295.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.92 295.29L439.52 301.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.52 301.88L437.33 312.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.33 312.87L437.33 323.85Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.33 323.85L439.52 332.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.52 332.64L443.92 337.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.92 337.04L450.51 339.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.51 339.23L452.71 339.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.71 339.23L459.3 337.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.3 337.04L463.69 332.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M463.69 332.64L465.89 326.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M465.89 326.05L465.89 323.85Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M465.89 323.85L463.69 317.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M463.69 317.26L459.3 312.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.3 312.87L452.71 310.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.71 310.67L450.51 310.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.51 310.67L443.92 312.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.92 312.87L439.52 317.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.52 317.26L437.33 323.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M494.45 293.1L487.86 295.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M487.86 295.29L483.47 301.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M483.47 301.88L481.27 312.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.27 312.87L481.27 319.46Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.27 319.46L483.47 330.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M483.47 330.45L487.86 337.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M487.86 337.04L494.45 339.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M494.45 339.23L498.85 339.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M498.85 339.23L505.44 337.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M505.44 337.04L509.83 330.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M509.83 330.45L512.03 319.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M512.03 319.46L512.03 312.87Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M512.03 312.87L509.83 301.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M509.83 301.88L505.44 295.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M505.44 295.29L498.85 293.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M498.85 293.1L494.45 293.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M534 297.49L533.8 296.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M533.8 296.19L533.24 295.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M533.24 295.01L532.35 294.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M532.35 294.05L531.21 293.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M531.21 293.4L529.93 293.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M529.93 293.11L528.63 293.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M528.63 293.21L527.41 293.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M527.41 293.68L526.38 294.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M526.38 294.5L525.65 295.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M525.65 295.58L525.26 296.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M525.26 296.83L525.26 298.14Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M525.26 298.14L525.65 299.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M525.65 299.4L526.38 300.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M526.38 300.48L527.41 301.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M527.41 301.29L528.63 301.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M528.63 301.77L529.93 301.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M529.93 301.87L531.21 301.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M531.21 301.58L532.35 300.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M532.35 300.92L533.24 299.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M533.24 299.96L533.8 298.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M533.8 298.78L534 297.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg3_01_07;

    