import { ClickableFeature, ClickableFeatureType } from '../ClickableFeatures';
import { Fill, Stroke, Style, Text } from 'ol/style';

import { Options as BOptions } from 'ol/layer/BaseVector';
import { BaseIconId } from '../SVG';
import CTLayer from '../CTLayer';
import Feature from 'ol/Feature';
import { FeatureProperty } from '../feature-utility';
import Geometry from 'ol/geom/Geometry';
import { IMapLayer } from '../../caretaker-map';
import VectorSource from 'ol/source/Vector';
import { WKT } from 'ol/format';
import { decodeJsonResponse } from './DataIO';

/**
 * @deprecated Use GeometryLayer instead
 */
export class LokLayer extends CTLayer {
    private static wktFormat = new WKT();

    public baseIconID: BaseIconId;

    //#region Construtor-related

    constructor(options: IMapLayer) {
        const _options = (options ?? {}) as BOptions<VectorSource<any>>;
        _options.source = new VectorSource();
        // options.minZoom ??= 13;
        _options.minZoom = _options.minZoom ?? 13;
        // options.maxZoom ??= 100;
        _options.maxZoom = _options.maxZoom ?? 100;
        _options.zIndex = 1000;

        super(options, _options);
        this.baseIconID = BaseIconId.Bygning;
        this.loadLokPolygons = this.loadLokPolygons.bind(this);
        this.addFeaturesFromWKT = this.addFeaturesFromWKT.bind(this);
    }

    public addFeaturesFromWKT(features: Feature<Geometry>[]) {
        const notNullFeatures = features.filter((f) => f != null);
        this.getSource()?.addFeatures(notNullFeatures);
    }

    public async loadLokPolygons(dataEndpointUri: string, geometryColumnName: string) {
        let json;
        try {
            // eslint-disable-next-line no-restricted-syntax
            json = await fetch(dataEndpointUri)
                .then((r) => r.json())
                .then((json) => decodeJsonResponse(json));
        } catch (err) {
            json = [];
        }
        this.addFeaturesFromWKT(
            json.map((o) => {
                const feature =
                    o[geometryColumnName] === '' || o[geometryColumnName] === undefined
                        ? new Feature<any>()
                        : LokLayer.wktFormat.readFeature(o[geometryColumnName]);
                if (feature != null) {
                    feature.set(FeatureProperty.Data, o);
                    (feature as ClickableFeature).dataColumns = ['ENHEDSYS', 'BygTerSYS', 'LokaleSYS'];
                    (feature as ClickableFeature).webPage =
                        '/Lok/LokMenu/{LokaleSYS}?enhedId={ENHEDSYS}&bygId={BygTerSYS}&caretakerMap=true';
                    (feature as ClickableFeature).featureType = ClickableFeatureType.Lok;

                    feature.setStyle(() => {
                        return new Style({
                            fill: new Fill({
                                color: 'rgb(0, 150, 170, 1)',
                            }),
                            text: new Text({
                                font: '14px "Segoe UI", Verdana, Tahoma, sans-serif',
                                fill: new Fill({ color: '#FFF' }),
                                stroke: new Stroke({
                                    color: '#000',
                                    width: 2,
                                }),
                                text: o['LABEL'] ?? '',
                            }),
                        });
                    });
                }
                return feature;
            })
        );
    }

    //#endregion

    public clearFeatures() {
        this.getSource()?.clear();
    }
}
