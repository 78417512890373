import { loadMessages, locale } from 'devextreme/localization';

import daMessages from './da.json';

const Localize = () => {
    loadMessages(daMessages);
    locale('da');
};

export default Localize;
