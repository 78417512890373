import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ISliceState } from './interfaces';

const initialState: ISliceState = {
    labels: {},
};

const breadcrumbSlice = createSlice({
    name: 'breadcrumb',
    initialState: initialState,
    reducers: {
        set: (
            state,
            action: PayloadAction<{
                path: string;
                label: string;
            }>
        ) => {
            return {
                ...state,
                labels: {
                    ...state.labels,
                    [action.payload.path]: action.payload.label,
                },
            };
        },

        remove: (
            state,
            action: PayloadAction<{
                path: string;
            }>
        ) => {
            const newLabels = { ...state.labels };
            delete newLabels[action.payload.path];
            return { ...state, labels: newLabels };
        },
    },
});

export default breadcrumbSlice;
