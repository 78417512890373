
    const Svg1_02_01 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="347.34 253.99 175.01000000000005 102.09999999999997" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M442.53 305.2L473.34 258.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.53 258.99L473.34 305.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M486.54 305.2L517.35 258.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M486.54 258.99L517.35 305.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.34 351.09L421.65 351.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.65 351.09L421.65 281.78Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.65 281.78L352.34 281.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.34 281.78L352.34 351.09Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.89 339.54L410.1 339.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.1 339.54L410.1 293.33Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.1 293.33L363.89 293.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.89 293.33L363.89 339.54Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_02_01;

    