
    const SvgXx_07_03 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="294.74 215.94 191.5 152.60000000000002" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M299.74 220.94L481.24 220.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.24 220.94L481.24 363.54Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.24 363.54L299.74 363.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M299.74 363.54L299.74 220.94Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.8 324.33L320.8 260.16Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.8 260.16L360.53 260.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.8 290.71L345.25 290.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.81 260.16L391.09 324.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.09 324.33L406.37 260.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.37 260.16L421.65 324.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.65 324.33L436.93 260.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_07_03;

    