// This used to be the CRA template serviceWorkerRegistration.ts
// Has since been replaces to handle sw aspects which vite-plugin-pwa doesn't handle

export const forceReload = () => {
    const url = new URL(window.location.href);
    url.searchParams.set('_', Date.now().toString());
    window.location.href = url.toString();
}

/** Ensures the app is updated immediatly after installing a new SW */
export const keepServiceWorkerUpdated = () => {
    navigator.serviceWorker?.getRegistration().then((registration) => {
        if (!registration) return;

        registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            if (!installingWorker) return;

            installingWorker.onstatechange = () => {
                if (installingWorker.state === 'installed') {
                    if (navigator.serviceWorker.controller) {
                        console.log('New content is available; Refreshing...');
                        forceReload();
                    } else {
                        console.log('Content is cached for offline use.');
                    }
                }
            };
        };
    });
};

/**
 * https://stackoverflow.com/a/51847335
 *
 * The BeforeInstallPromptEvent is fired at the Window.onbeforeinstallprompt handler
 * before a user is prompted to "install" a web site to a home screen on mobile.
 *
 * @deprecated Only supported on Chrome and Android Webview.
 */
interface BeforeInstallPromptEvent extends Event {
    /**
     * Returns an array of DOMString items containing the platforms on which the event was dispatched.
     * This is provided for user agents that want to present a choice of versions to the user such as,
     * for example, "web" or "play" which would allow the user to chose between a web version or
     * an Android version.
     */
    readonly platforms: Array<string>;

    /**
     * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
     */
    readonly userChoice: Promise<{
        outcome: 'accepted' | 'dismissed';
        platform: string;
    }>;

    /**
     * Allows a developer to show the install prompt at a time of their own choosing.
     * This method returns a Promise.
     */
    prompt(): Promise<void>;
}

export let defferedPrompt: BeforeInstallPromptEvent | null = null;
window.addEventListener('beforeinstallprompt', (event) => {
    event.preventDefault();
    console.log('deffering install promt');
    defferedPrompt = event as BeforeInstallPromptEvent;
});
