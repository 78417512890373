
    const Svg1_05_10 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="301.42 244.07 259.7 99.94999999999999" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M535.31 269.88L532.24 275.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M532.24 275.91L528.83 281.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M528.83 281.76L525.1 287.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M525.1 287.42L521.05 292.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M521.05 292.87L516.69 298.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M516.69 298.09L512.06 303.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M512.06 303.07L507.15 307.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M507.15 307.78L501.99 312.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M501.99 312.2L496.59 316.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M496.59 316.34L490.98 320.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M490.98 320.16L485.17 323.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M485.17 323.66L479.19 326.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M479.19 326.82L473.04 329.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M473.04 329.64L466.77 332.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.77 332.11L460.37 334.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.37 334.2L453.89 335.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M453.89 335.93L447.34 337.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.34 337.28L440.74 338.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.74 338.25L434.11 338.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.11 338.83L427.48 339.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.48 339.02L420.88 338.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.88 338.83L414.32 338.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.32 338.25L407.82 337.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.82 337.28L401.42 335.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.42 335.93L395.12 334.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.12 334.2L388.96 332.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.96 332.11L382.95 329.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.95 329.64L377.12 326.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.12 326.82L371.48 323.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.48 323.66L366.05 320.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.05 320.16L360.86 316.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.86 316.34L355.92 312.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.92 312.2L351.24 307.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M351.24 307.78L346.85 303.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.85 303.07L342.76 298.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M342.76 298.09L338.98 292.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M338.98 292.87L335.52 287.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M335.52 287.42L332.41 281.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M332.41 281.76L329.64 275.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M329.64 275.91L327.23 269.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M306.44 270.79L306.44 268.98 306.42 269.88 306.44 270.79Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M306.44 270.79L306.5 271.7 306.44 268.98 306.44 270.79Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M306.5 271.7L306.5 268.07 306.44 268.98 306.5 271.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M306.5 271.7L306.6 272.6 306.5 268.07 306.5 271.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M306.6 272.6L306.6 267.17 306.5 268.07 306.6 272.6Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M306.6 272.6L306.74 273.5 306.6 267.17 306.6 272.6Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M306.74 273.5L306.74 266.27 306.6 267.17 306.74 273.5Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M306.74 273.5L306.92 274.39 306.74 266.27 306.74 273.5Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M306.92 274.39L306.92 265.38 306.74 266.27 306.92 274.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M306.92 274.39L307.13 275.27 306.92 265.38 306.92 274.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M307.13 275.27L307.13 264.5 306.92 265.38 307.13 275.27Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M307.13 275.27L307.39 276.14 307.13 264.5 307.13 275.27Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M307.39 276.14L307.39 263.63 307.13 264.5 307.39 276.14Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M307.39 276.14L307.68 277 307.39 263.63 307.39 276.14Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M307.68 277L307.68 262.77 307.39 263.63 307.68 277Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M307.68 277L308.01 277.85 307.68 262.77 307.68 277Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M308.01 277.85L308.01 261.92 307.68 262.77 308.01 277.85Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M308.01 277.85L308.37 278.68 308.01 261.92 308.01 277.85Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M308.37 278.68L308.37 261.09 308.01 261.92 308.37 278.68Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M308.37 278.68L308.77 279.49 308.37 261.09 308.37 278.68Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M308.77 279.49L308.77 260.27 308.37 261.09 308.77 279.49Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M308.77 279.49L309.21 280.29 308.77 260.27 308.77 279.49Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M309.21 280.29L309.21 259.48 308.77 260.27 309.21 280.29Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M309.21 280.29L309.68 281.06 309.21 259.48 309.21 280.29Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M309.68 281.06L309.68 258.7 309.21 259.48 309.68 281.06Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M309.68 281.06L310.19 281.82 309.68 258.7 309.68 281.06Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M310.19 281.82L310.19 257.95 309.68 258.7 310.19 281.82Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M310.19 281.82L310.72 282.55 310.19 257.95 310.19 281.82Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M310.72 282.55L310.72 257.22 310.19 257.95 310.72 282.55Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M310.72 282.55L311.29 283.26 310.72 257.22 310.72 282.55Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M311.29 283.26L311.29 256.51 310.72 257.22 311.29 283.26Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M311.29 283.26L311.89 283.94 311.29 256.51 311.29 283.26Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M311.89 283.94L311.89 255.82 311.29 256.51 311.89 283.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M311.89 283.94L312.52 284.6 311.89 255.82 311.89 283.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M312.52 284.6L312.52 255.17 311.89 255.82 312.52 284.6Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M312.52 284.6L313.17 285.22 312.52 255.17 312.52 284.6Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M313.17 285.22L313.17 254.54 312.52 255.17 313.17 285.22Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M313.17 285.22L313.86 285.82 313.17 254.54 313.17 285.22Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M313.86 285.82L313.86 253.94 313.17 254.54 313.86 285.82Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M313.86 285.82L314.56 286.39 313.86 253.94 313.86 285.82Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M314.56 286.39L314.56 253.37 313.86 253.94 314.56 286.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M314.56 286.39L315.3 286.93 314.56 253.37 314.56 286.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M315.3 286.93L315.3 252.84 314.56 253.37 315.3 286.93Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M315.3 286.93L316.05 287.43 315.3 252.84 315.3 286.93Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M316.05 287.43L316.05 252.33 315.3 252.84 316.05 287.43Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M316.05 287.43L316.83 287.9 316.05 252.33 316.05 287.43Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M316.83 287.9L316.83 251.86 316.05 252.33 316.83 287.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M316.83 287.9L317.62 288.34 316.83 251.86 316.83 287.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M317.62 288.34L317.62 251.43 316.83 251.86 317.62 288.34Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M317.62 288.34L318.44 288.74 317.62 251.43 317.62 288.34Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M318.44 288.74L318.44 251.02 317.62 251.43 318.44 288.74Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M318.44 288.74L319.27 289.11 318.44 251.02 318.44 288.74Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M319.27 289.11L319.27 250.66 318.44 251.02 319.27 289.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M319.27 289.11L320.11 289.44 319.27 250.66 319.27 289.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M320.11 289.44L320.11 250.33 319.27 250.66 320.11 289.44Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M320.11 289.44L320.97 289.73 320.11 250.33 320.11 289.44Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M320.97 289.73L320.97 250.04 320.11 250.33 320.97 289.73Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M320.97 289.73L321.85 289.98 320.97 250.04 320.97 289.73Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M321.85 289.98L321.85 249.78 320.97 250.04 321.85 289.98Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M321.85 289.98L322.73 290.2 321.85 249.78 321.85 289.98Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M322.73 290.2L322.73 249.57 321.85 249.78 322.73 290.2Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M322.73 290.2L323.62 290.37 322.73 249.57 322.73 290.2Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M323.62 290.37L323.62 249.39 322.73 249.57 323.62 290.37Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M323.62 290.37L324.52 290.51 323.62 249.39 323.62 290.37Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M324.52 290.51L324.52 249.25 323.62 249.39 324.52 290.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M324.52 290.51L325.42 290.61 324.52 249.25 324.52 290.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M325.42 290.61L325.42 249.15 324.52 249.25 325.42 290.61Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M325.42 290.61L326.32 290.67 325.42 249.15 325.42 290.61Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M326.32 290.67L326.32 249.09 325.42 249.15 326.32 290.67Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M326.32 290.67L327.23 290.69 326.32 249.09 326.32 290.67Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M327.23 290.69L327.23 249.07 326.32 249.09 327.23 290.69Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M327.23 290.69L328.14 290.67 327.23 249.07 327.23 290.69Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M328.14 290.67L328.14 249.09 327.23 249.07 328.14 290.67Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M328.14 290.67L329.04 290.61 328.14 249.09 328.14 290.67Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M329.04 290.61L329.04 249.15 328.14 249.09 329.04 290.61Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M329.04 290.61L329.95 290.51 329.04 249.15 329.04 290.61Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M329.95 290.51L329.95 249.25 329.04 249.15 329.95 290.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M329.95 290.51L330.84 290.37 329.95 249.25 329.95 290.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M330.84 290.37L330.84 249.39 329.95 249.25 330.84 290.37Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M330.84 290.37L331.74 290.2 330.84 249.39 330.84 290.37Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M331.74 290.2L331.74 249.57 330.84 249.39 331.74 290.2Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M331.74 290.2L332.62 289.98 331.74 249.57 331.74 290.2Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M332.62 289.98L332.62 249.78 331.74 249.57 332.62 289.98Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M332.62 289.98L333.49 289.73 332.62 249.78 332.62 289.98Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M333.49 289.73L333.49 250.04 332.62 249.78 333.49 289.73Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M333.49 289.73L334.35 289.44 333.49 250.04 333.49 289.73Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M334.35 289.44L334.35 250.33 333.49 250.04 334.35 289.44Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M334.35 289.44L335.19 289.11 334.35 250.33 334.35 289.44Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M335.19 289.11L335.19 250.66 334.35 250.33 335.19 289.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M335.19 289.11L336.03 288.74 335.19 250.66 335.19 289.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M336.03 288.74L336.03 251.02 335.19 250.66 336.03 288.74Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M336.03 288.74L336.84 288.34 336.03 251.02 336.03 288.74Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M336.84 288.34L336.84 251.43 336.03 251.02 336.84 288.34Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M336.84 288.34L337.64 287.9 336.84 251.43 336.84 288.34Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M337.64 287.9L337.64 251.86 336.84 251.43 337.64 287.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M337.64 287.9L338.41 287.43 337.64 251.86 337.64 287.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M338.41 287.43L338.41 252.33 337.64 251.86 338.41 287.43Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M338.41 287.43L339.17 286.93 338.41 252.33 338.41 287.43Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M339.17 286.93L339.17 252.84 338.41 252.33 339.17 286.93Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M339.17 286.93L339.9 286.39 339.17 252.84 339.17 286.93Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M339.9 286.39L339.9 253.37 339.17 252.84 339.9 286.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M339.9 286.39L340.61 285.82 339.9 253.37 339.9 286.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M340.61 285.82L340.61 253.94 339.9 253.37 340.61 285.82Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M340.61 285.82L341.29 285.22 340.61 253.94 340.61 285.82Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M341.29 285.22L341.29 254.54 340.61 253.94 341.29 285.22Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M341.29 285.22L341.94 284.6 341.29 254.54 341.29 285.22Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M341.94 284.6L341.94 255.17 341.29 254.54 341.94 284.6Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M341.94 284.6L342.57 283.94 341.94 255.17 341.94 284.6Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M342.57 283.94L342.57 255.82 341.94 255.17 342.57 283.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M342.57 283.94L343.17 283.26 342.57 255.82 342.57 283.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M343.17 283.26L343.17 256.51 342.57 255.82 343.17 283.26Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M343.17 283.26L343.74 282.55 343.17 256.51 343.17 283.26Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M343.74 282.55L343.74 257.22 343.17 256.51 343.74 282.55Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M343.74 282.55L344.28 281.82 343.74 257.22 343.74 282.55Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M344.28 281.82L344.28 257.95 343.74 257.22 344.28 281.82Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M344.28 281.82L344.78 281.06 344.28 257.95 344.28 281.82Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M344.78 281.06L344.78 258.7 344.28 257.95 344.78 281.06Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M344.78 281.06L345.25 280.29 344.78 258.7 344.78 281.06Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M345.25 280.29L345.25 259.48 344.78 258.7 345.25 280.29Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M345.25 280.29L345.69 279.49 345.25 259.48 345.25 280.29Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M345.69 279.49L345.69 260.27 345.25 259.48 345.69 279.49Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M345.69 279.49L346.09 278.68 345.69 260.27 345.69 279.49Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M346.09 278.68L346.09 261.09 345.69 260.27 346.09 278.68Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M346.09 278.68L346.46 277.85 346.09 261.09 346.09 278.68Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M346.46 277.85L346.46 261.92 346.09 261.09 346.46 277.85Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M346.46 277.85L346.78 277 346.46 261.92 346.46 277.85Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M346.78 277L346.78 262.77 346.46 261.92 346.78 277Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M346.78 277L347.08 276.14 346.78 262.77 346.78 277Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M347.08 276.14L347.08 263.63 346.78 262.77 347.08 276.14Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M347.08 276.14L347.33 275.27 347.08 263.63 347.08 276.14Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M347.33 275.27L347.33 264.5 347.08 263.63 347.33 275.27Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M347.33 275.27L347.55 274.39 347.33 264.5 347.33 275.27Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M347.55 274.39L347.55 265.38 347.33 264.5 347.55 274.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M347.55 274.39L347.72 273.5 347.55 265.38 347.55 274.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M347.72 273.5L347.72 266.27 347.55 265.38 347.72 273.5Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M347.72 273.5L347.86 272.6 347.72 266.27 347.72 273.5Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M347.86 272.6L347.86 267.17 347.72 266.27 347.86 272.6Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M347.86 272.6L347.96 271.7 347.86 267.17 347.86 272.6Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M347.96 271.7L347.96 268.07 347.86 267.17 347.96 271.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M347.96 271.7L348.02 270.79 347.96 268.07 347.96 271.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M348.02 270.79L348.02 268.98 347.96 268.07 348.02 270.79Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M348.02 270.79L348.04 269.88 348.02 268.98 348.02 270.79Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M348.04 269.88L347.85 272.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M347.85 272.72L347.27 275.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M347.27 275.5L346.32 278.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.32 278.17L345.01 280.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.01 280.69L343.37 283.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.37 283.01L341.43 285.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.43 285.09L339.23 286.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M339.23 286.88L336.8 288.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.8 288.36L334.2 289.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M334.2 289.49L331.46 290.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M331.46 290.26L328.65 290.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.65 290.64L325.81 290.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M325.81 290.64L323 290.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323 290.26L320.26 289.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.26 289.49L317.66 288.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M317.66 288.36L315.23 286.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M315.23 286.88L313.03 285.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M313.03 285.09L311.09 283.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M311.09 283.01L309.45 280.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M309.45 280.69L308.15 278.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M308.15 278.17L307.2 275.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M307.2 275.5L306.62 272.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M306.62 272.72L306.42 269.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M306.42 269.88L306.62 267.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M306.62 267.05L307.2 264.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M307.2 264.27L308.15 261.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M308.15 261.59L309.45 259.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M309.45 259.07L311.09 256.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M311.09 256.75L313.03 254.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M313.03 254.68L315.23 252.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M315.23 252.88L317.66 251.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M317.66 251.41L320.26 250.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.26 250.28L323 249.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323 249.51L325.81 249.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M325.81 249.12L328.65 249.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.65 249.12L331.46 249.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M331.46 249.51L334.2 250.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M334.2 250.28L336.8 251.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.8 251.41L339.23 252.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M339.23 252.88L341.43 254.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.43 254.68L343.37 256.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.37 256.75L345.01 259.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.01 259.07L346.32 261.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.32 261.59L347.27 264.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M347.27 264.27L347.85 267.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M347.85 267.05L348.04 269.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M514.52 270.79L514.52 268.98 514.5 269.88 514.52 270.79Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M514.52 270.79L514.58 271.7 514.52 268.98 514.52 270.79Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M514.58 271.7L514.58 268.07 514.52 268.98 514.58 271.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M514.58 271.7L514.68 272.6 514.58 268.07 514.58 271.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M514.68 272.6L514.68 267.17 514.58 268.07 514.68 272.6Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M514.68 272.6L514.82 273.5 514.68 267.17 514.68 272.6Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M514.82 273.5L514.82 266.27 514.68 267.17 514.82 273.5Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M514.82 273.5L515 274.39 514.82 266.27 514.82 273.5Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M515 274.39L515 265.38 514.82 266.27 515 274.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M515 274.39L515.21 275.27 515 265.38 515 274.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M515.21 275.27L515.21 264.5 515 265.38 515.21 275.27Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M515.21 275.27L515.47 276.14 515.21 264.5 515.21 275.27Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M515.47 276.14L515.47 263.63 515.21 264.5 515.47 276.14Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M515.47 276.14L515.76 277 515.47 263.63 515.47 276.14Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M515.76 277L515.76 262.77 515.47 263.63 515.76 277Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M515.76 277L516.09 277.85 515.76 262.77 515.76 277Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M516.09 277.85L516.09 261.92 515.76 262.77 516.09 277.85Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M516.09 277.85L516.45 278.68 516.09 261.92 516.09 277.85Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M516.45 278.68L516.45 261.09 516.09 261.92 516.45 278.68Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M516.45 278.68L516.85 279.49 516.45 261.09 516.45 278.68Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M516.85 279.49L516.85 260.27 516.45 261.09 516.85 279.49Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M516.85 279.49L517.29 280.29 516.85 260.27 516.85 279.49Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M517.29 280.29L517.29 259.48 516.85 260.27 517.29 280.29Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M517.29 280.29L517.76 281.06 517.29 259.48 517.29 280.29Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M517.76 281.06L517.76 258.7 517.29 259.48 517.76 281.06Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M517.76 281.06L518.27 281.82 517.76 258.7 517.76 281.06Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M518.27 281.82L518.27 257.95 517.76 258.7 518.27 281.82Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M518.27 281.82L518.8 282.55 518.27 257.95 518.27 281.82Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M518.8 282.55L518.8 257.22 518.27 257.95 518.8 282.55Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M518.8 282.55L519.37 283.26 518.8 257.22 518.8 282.55Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M519.37 283.26L519.37 256.51 518.8 257.22 519.37 283.26Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M519.37 283.26L519.97 283.94 519.37 256.51 519.37 283.26Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M519.97 283.94L519.97 255.82 519.37 256.51 519.97 283.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M519.97 283.94L520.6 284.6 519.97 255.82 519.97 283.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M520.6 284.6L520.6 255.17 519.97 255.82 520.6 284.6Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M520.6 284.6L521.25 285.22 520.6 255.17 520.6 284.6Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M521.25 285.22L521.25 254.54 520.6 255.17 521.25 285.22Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M521.25 285.22L521.94 285.82 521.25 254.54 521.25 285.22Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M521.94 285.82L521.94 253.94 521.25 254.54 521.94 285.82Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M521.94 285.82L522.64 286.39 521.94 253.94 521.94 285.82Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M522.64 286.39L522.64 253.37 521.94 253.94 522.64 286.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M522.64 286.39L523.38 286.93 522.64 253.37 522.64 286.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M523.38 286.93L523.38 252.84 522.64 253.37 523.38 286.93Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M523.38 286.93L524.13 287.43 523.38 252.84 523.38 286.93Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M524.13 287.43L524.13 252.33 523.38 252.84 524.13 287.43Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M524.13 287.43L524.91 287.9 524.13 252.33 524.13 287.43Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M524.91 287.9L524.91 251.86 524.13 252.33 524.91 287.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M524.91 287.9L525.7 288.34 524.91 251.86 524.91 287.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M525.7 288.34L525.7 251.43 524.91 251.86 525.7 288.34Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M525.7 288.34L526.52 288.74 525.7 251.43 525.7 288.34Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M526.52 288.74L526.52 251.02 525.7 251.43 526.52 288.74Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M526.52 288.74L527.35 289.11 526.52 251.02 526.52 288.74Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M527.35 289.11L527.35 250.66 526.52 251.02 527.35 289.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M527.35 289.11L528.19 289.44 527.35 250.66 527.35 289.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M528.19 289.44L528.19 250.33 527.35 250.66 528.19 289.44Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M528.19 289.44L529.05 289.73 528.19 250.33 528.19 289.44Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M529.05 289.73L529.05 250.04 528.19 250.33 529.05 289.73Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M529.05 289.73L529.93 289.98 529.05 250.04 529.05 289.73Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M529.93 289.98L529.93 249.78 529.05 250.04 529.93 289.98Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M529.93 289.98L530.81 290.2 529.93 249.78 529.93 289.98Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M530.81 290.2L530.81 249.57 529.93 249.78 530.81 290.2Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M530.81 290.2L531.7 290.37 530.81 249.57 530.81 290.2Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M531.7 290.37L531.7 249.39 530.81 249.57 531.7 290.37Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M531.7 290.37L532.6 290.51 531.7 249.39 531.7 290.37Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M532.6 290.51L532.6 249.25 531.7 249.39 532.6 290.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M532.6 290.51L533.5 290.61 532.6 249.25 532.6 290.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M533.5 290.61L533.5 249.15 532.6 249.25 533.5 290.61Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M533.5 290.61L534.4 290.67 533.5 249.15 533.5 290.61Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M534.4 290.67L534.4 249.09 533.5 249.15 534.4 290.67Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M534.4 290.67L535.31 290.69 534.4 249.09 534.4 290.67Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M535.31 290.69L535.31 249.07 534.4 249.09 535.31 290.69Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M535.31 290.69L536.22 290.67 535.31 249.07 535.31 290.69Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M536.22 290.67L536.22 249.09 535.31 249.07 536.22 290.67Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M536.22 290.67L537.12 290.61 536.22 249.09 536.22 290.67Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M537.12 290.61L537.12 249.15 536.22 249.09 537.12 290.61Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M537.12 290.61L538.03 290.51 537.12 249.15 537.12 290.61Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M538.03 290.51L538.03 249.25 537.12 249.15 538.03 290.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M538.03 290.51L538.92 290.37 538.03 249.25 538.03 290.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M538.92 290.37L538.92 249.39 538.03 249.25 538.92 290.37Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M538.92 290.37L539.82 290.2 538.92 249.39 538.92 290.37Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M539.82 290.2L539.82 249.57 538.92 249.39 539.82 290.2Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M539.82 290.2L540.7 289.98 539.82 249.57 539.82 290.2Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M540.7 289.98L540.7 249.78 539.82 249.57 540.7 289.98Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M540.7 289.98L541.57 289.73 540.7 249.78 540.7 289.98Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M541.57 289.73L541.57 250.04 540.7 249.78 541.57 289.73Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M541.57 289.73L542.43 289.44 541.57 250.04 541.57 289.73Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M542.43 289.44L542.43 250.33 541.57 250.04 542.43 289.44Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M542.43 289.44L543.27 289.11 542.43 250.33 542.43 289.44Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M543.27 289.11L543.27 250.66 542.43 250.33 543.27 289.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M543.27 289.11L544.11 288.74 543.27 250.66 543.27 289.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M544.11 288.74L544.11 251.02 543.27 250.66 544.11 288.74Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M544.11 288.74L544.92 288.34 544.11 251.02 544.11 288.74Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M544.92 288.34L544.92 251.43 544.11 251.02 544.92 288.34Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M544.92 288.34L545.72 287.9 544.92 251.43 544.92 288.34Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M545.72 287.9L545.72 251.86 544.92 251.43 545.72 287.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M545.72 287.9L546.49 287.43 545.72 251.86 545.72 287.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M546.49 287.43L546.49 252.33 545.72 251.86 546.49 287.43Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M546.49 287.43L547.25 286.93 546.49 252.33 546.49 287.43Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M547.25 286.93L547.25 252.84 546.49 252.33 547.25 286.93Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M547.25 286.93L547.98 286.39 547.25 252.84 547.25 286.93Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M547.98 286.39L547.98 253.37 547.25 252.84 547.98 286.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M547.98 286.39L548.69 285.82 547.98 253.37 547.98 286.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M548.69 285.82L548.69 253.94 547.98 253.37 548.69 285.82Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M548.69 285.82L549.37 285.22 548.69 253.94 548.69 285.82Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M549.37 285.22L549.37 254.54 548.69 253.94 549.37 285.22Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M549.37 285.22L550.02 284.6 549.37 254.54 549.37 285.22Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M550.02 284.6L550.02 255.17 549.37 254.54 550.02 284.6Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M550.02 284.6L550.65 283.94 550.02 255.17 550.02 284.6Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M550.65 283.94L550.65 255.82 550.02 255.17 550.65 283.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M550.65 283.94L551.25 283.26 550.65 255.82 550.65 283.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M551.25 283.26L551.25 256.51 550.65 255.82 551.25 283.26Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M551.25 283.26L551.82 282.55 551.25 256.51 551.25 283.26Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M551.82 282.55L551.82 257.22 551.25 256.51 551.82 282.55Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M551.82 282.55L552.36 281.82 551.82 257.22 551.82 282.55Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M552.36 281.82L552.36 257.95 551.82 257.22 552.36 281.82Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M552.36 281.82L552.86 281.06 552.36 257.95 552.36 281.82Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M552.86 281.06L552.86 258.7 552.36 257.95 552.86 281.06Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M552.86 281.06L553.33 280.29 552.86 258.7 552.86 281.06Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M553.33 280.29L553.33 259.48 552.86 258.7 553.33 280.29Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M553.33 280.29L553.77 279.49 553.33 259.48 553.33 280.29Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M553.77 279.49L553.77 260.27 553.33 259.48 553.77 279.49Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M553.77 279.49L554.17 278.68 553.77 260.27 553.77 279.49Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M554.17 278.68L554.17 261.09 553.77 260.27 554.17 278.68Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M554.17 278.68L554.54 277.85 554.17 261.09 554.17 278.68Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M554.54 277.85L554.54 261.92 554.17 261.09 554.54 277.85Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M554.54 277.85L554.86 277 554.54 261.92 554.54 277.85Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M554.86 277L554.86 262.77 554.54 261.92 554.86 277Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M554.86 277L555.16 276.14 554.86 262.77 554.86 277Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M555.16 276.14L555.16 263.63 554.86 262.77 555.16 276.14Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M555.16 276.14L555.41 275.27 555.16 263.63 555.16 276.14Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M555.41 275.27L555.41 264.5 555.16 263.63 555.41 275.27Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M555.41 275.27L555.63 274.39 555.41 264.5 555.41 275.27Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M555.63 274.39L555.63 265.38 555.41 264.5 555.63 274.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M555.63 274.39L555.8 273.5 555.63 265.38 555.63 274.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M555.8 273.5L555.8 266.27 555.63 265.38 555.8 273.5Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M555.8 273.5L555.94 272.6 555.8 266.27 555.8 273.5Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M555.94 272.6L555.94 267.17 555.8 266.27 555.94 272.6Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M555.94 272.6L556.04 271.7 555.94 267.17 555.94 272.6Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M556.04 271.7L556.04 268.07 555.94 267.17 556.04 271.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M556.04 271.7L556.1 270.79 556.04 268.07 556.04 271.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M556.1 270.79L556.1 268.98 556.04 268.07 556.1 270.79Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M556.1 270.79L556.12 269.88 556.1 268.98 556.1 270.79Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M556.12 269.88L555.93 272.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M555.93 272.72L555.35 275.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M555.35 275.5L554.4 278.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M554.4 278.17L553.09 280.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M553.09 280.69L551.45 283.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M551.45 283.01L549.51 285.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M549.51 285.09L547.31 286.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M547.31 286.88L544.88 288.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M544.88 288.36L542.28 289.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M542.28 289.49L539.54 290.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M539.54 290.26L536.73 290.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M536.73 290.64L533.89 290.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M533.89 290.64L531.08 290.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M531.08 290.26L528.34 289.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M528.34 289.49L525.74 288.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M525.74 288.36L523.31 286.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M523.31 286.88L521.11 285.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M521.11 285.09L519.17 283.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M519.17 283.01L517.53 280.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M517.53 280.69L516.23 278.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M516.23 278.17L515.28 275.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M515.28 275.5L514.7 272.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M514.7 272.72L514.5 269.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M514.5 269.88L514.7 267.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M514.7 267.05L515.28 264.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M515.28 264.27L516.23 261.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M516.23 261.59L517.53 259.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M517.53 259.07L519.17 256.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M519.17 256.75L521.11 254.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M521.11 254.68L523.31 252.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M523.31 252.88L525.74 251.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M525.74 251.41L528.34 250.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M528.34 250.28L531.08 249.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M531.08 249.51L533.89 249.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M533.89 249.12L536.73 249.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M536.73 249.12L539.54 249.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M539.54 249.51L542.28 250.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M542.28 250.28L544.88 251.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M544.88 251.41L547.31 252.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M547.31 252.88L549.51 254.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M549.51 254.68L551.45 256.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M551.45 256.75L553.09 259.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M553.09 259.07L554.4 261.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M554.4 261.59L555.35 264.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M555.35 264.27L555.93 267.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M555.93 267.05L556.12 269.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_05_10;

    