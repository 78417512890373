
    const Svg1_01_10 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="231.5 265.56 336.25 91.56" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M318.06 352.12L318.06 270.56Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M236.5 270.56L236.5 352.12Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.19 270.56L481.19 352.12Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M562.75 352.12L562.75 270.56Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M236.5 352.12L318.06 352.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.19 352.12L562.75 352.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M318.06 270.56L236.5 270.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M562.75 270.56L481.19 270.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M318.06 311.34L338.45 311.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.92 311.34L387.39 311.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.86 311.34L436.33 311.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.8 311.34L481.19 311.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M285.47 296.77L281.59 292.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M281.59 292.89L275.76 290.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M275.76 290.95L267.99 290.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M267.99 290.95L262.17 292.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M262.17 292.89L258.28 296.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M258.28 296.77L258.28 300.66Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M258.28 300.66L260.22 304.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M260.22 304.54L262.17 306.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M262.17 306.48L266.05 308.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M266.05 308.42L277.7 312.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M277.7 312.31L281.59 314.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M281.59 314.25L283.53 316.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M283.53 316.19L285.47 320.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M285.47 320.08L285.47 325.9Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M285.47 325.9L281.59 329.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M281.59 329.79L275.76 331.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M275.76 331.73L267.99 331.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M267.99 331.73L262.17 329.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M262.17 329.79L258.28 325.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M498.24 331.73L498.24 290.95Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M498.24 290.95L513.78 331.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M513.78 331.73L529.31 290.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M529.31 290.95L529.31 331.73Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_10;

    