const SvgAabnesignaldoer = () => (
    <svg
        id='Lag_1'
        data-name='Lag 1'
        xmlns='http://www.w3.org/2000/svg'
        version='1.1'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        viewBox='0 0 40 40'
        width='30'
        height='30'
    >
        <defs>
            <style>{`
      .cls-1 {
        stroke-width: 0px;
      }

      .cls-1, .cls-2 {
        fill: {{replacement-value-color}};
      }

      .cls-3 {
        clip-path: url(#clippath-2);
      }

      .cls-4 {
        clip-path: url(#clippath-1);
      }

      .cls-5 {
        clip-path: url(#clippath);
      }

      .cls-6 {
        clip-path: url(#clippath-3);
      }
        `}</style>
            <clipPath id='clippath'>
                <rect className='cls-1' y='.6' width='1.5' height='38.7' />
            </clipPath>
            <clipPath id='clippath-1'>
                <rect className='cls-1' x='38.6' width='1.5' height='40' />
            </clipPath>
            <clipPath id='clippath-2'>
                <rect className='cls-1' y='38.8' width='38.7' height='1.2' />
            </clipPath>
            <clipPath id='clippath-3'>
                <rect className='cls-1' width='38.7' height='1.3' />
            </clipPath>
        </defs>
        <g>
            <rect className='cls-2' y='.6' width='1.5' height='38.7' />
            <g className='cls-5'>
                <path className='cls-1' d='M-9.8.6H1.5v43.4h-11.2V.6h-.1Z' />
            </g>
        </g>
        <g>
            <rect className='cls-2' x='38.6' width='1.5' height='40' />
            <g className='cls-4'>
                <path className='cls-1' d='M38.6,0h4.8v44.8h-4.8V0Z' />
            </g>
        </g>
        <g>
            <rect className='cls-2' y='38.8' width='38.7' height='1.2' />
            <g className='cls-3'>
                <path className='cls-1' d='M41,38.8v4.8H-2.3v-4.8h43.4-.1Z' />
            </g>
        </g>
        <g>
            <rect className='cls-2' width='38.7' height='1.3' />
            <g className='cls-6'>
                <path className='cls-1' d='M-2.3,1.3V-3.6h43.4V1.3H-2.3Z' />
            </g>
        </g>
        <g>
            <path
                className='cls-1'
                d='M2.4,32.3l7.9-21.3h2.5l7.9,21.3h-3.2l-5.9-17.2-5.9,17.2h-3.3ZM6.1,27.6v-2.8h11.2v2.8H6.1ZM11.6,11.4c-1.2,0-2-.3-2.7-.9s-.9-1.4-.9-2.5.3-2,.9-2.6,1.5-.9,2.7-.9,2,.3,2.7.9.9,1.5.9,2.6-.3,1.9-.9,2.5-1.5.9-2.7.9ZM11.6,9.5c.5,0,.8-.1,1.1-.4s.4-.6.4-1.1-.1-.9-.4-1.2-.6-.4-1.1-.4-.8,0-1.1.4-.4.7-.4,1.2.1.9.4,1.1.6.4,1.1.4Z'
            />
            <path
                className='cls-1'
                d='M29.7,32.5c-1,0-2,0-2.9-.3s-1.8-.5-2.6-.9c-.8-.4-1.5-.9-2.2-1.5l1.9-2.3c.8.7,1.7,1.2,2.7,1.6s2,.5,3.2.5,2.7-.3,3.6-.9,1.3-1.4,1.3-2.4h0c0-.8-.2-1.4-.6-1.9s-1-.7-1.7-1-1.5-.4-2.3-.5-1.6-.3-2.4-.5-1.6-.5-2.2-.9-1.2-1-1.6-1.7-.6-1.7-.6-3h0c0-2,.7-3.5,2-4.6s3.2-1.6,5.6-1.6,2.3.2,3.4.5,2.2.9,3.2,1.7l-1.7,2.4c-.8-.6-1.6-1.1-2.4-1.3-.8-.3-1.6-.4-2.4-.4-1.5,0-2.6.3-3.4.9-.8.6-1.2,1.4-1.2,2.5h0c0,.8.2,1.4.7,1.8s1.1.7,1.8.9c.8.2,1.6.4,2.4.6.8.2,1.6.3,2.3.6s1.5.5,2.1,1c.6.4,1.2,1,1.5,1.7s.6,1.7.6,2.8h0c0,2-.7,3.5-2.1,4.6s-3.3,1.6-5.8,1.6h-.2Z'
            />
        </g>
    </svg>
);
export default SvgAabnesignaldoer;
