import { ControlEventTarget, IControlHandlerEvent } from '../ControlHandlerUtils';
import { useCallback, useEffect, useLayoutEffect } from 'react';

import { ControlHandler } from '../ControlHandler';
import { IStateObject } from '../../../../shared/hooks/extended-hooks/use-state-object';
import { useMapSetup } from '../../redux-slice';

/**
 * Optional options for useControlToggler
 */
interface IOptions {
    /**
     * array of controller names for which this controller should stay open when opened
     */
    stayOpen: string[];
}

/**
 * A hook for controlling the openState of a controller
 *
 * @param name The name of the controller
 * @param openState The openState object
 * @param controlHandler Controlhandler to handle events
 * @param options Optional options for extra configuration
 */
const useControlToggler = (
    name: string,
    open: IStateObject<boolean>,
    controlHandler: ControlHandler,
    options?: IOptions
) => {
    // Handle setup from redux
    const { setup, setControlSetup } = useMapSetup();
    const isKey = Object.keys(setup.controls).includes(name);
    const potentialKey = name as keyof typeof setup.controls;
    let isSetupActive = false;
    if (isKey) isSetupActive = setup.controls[potentialKey].isActive;
    useLayoutEffect(() => {
        if (!isSetupActive) return;
        open.setState(isSetupActive);
        setControlSetup({ [potentialKey]: { isActive: false } });
    }, [isSetupActive, open, potentialKey, setControlSetup]);

    // Eventhandler. Der subscribes i useEffect længere nede
    const handler = useCallback(
        (e: IControlHandlerEvent) => {
            // Hvis event er ActiveController og den nye controller ikke findes i stayOpen: Luk denne controller
            if (
                e.eventTarget === ControlEventTarget.ActiveController &&
                !options?.stayOpen.includes(e.value) &&
                e.value !== name
            ) {
                open.setState(false);
            }
        },
        [name, open, options?.stayOpen]
    );

    // Lyt efter ændringer på controlhandleren
    // Fjern listener på unmount
    useEffect(() => {
        controlHandler.addCustomEventListener('change', handler);
        return () => {
            controlHandler.removeCustomEventListener('change', handler);
        };
    }, [controlHandler, handler]);

    // Dispatch to controlHandler when controller is opened
    useEffect(() => {
        if (open.state === true && controlHandler.activeController !== name) controlHandler.activeController = name;
    }, [open, controlHandler, name]);
};

export default useControlToggler;
