
    const Svg2_06_03 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="294.88 250.52 223.61 126.52000000000001" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M299.88 255.52L299.88 372.04Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M299.88 372.04L513.49 372.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M513.49 372.04L513.49 255.52Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M513.49 255.52L299.88 255.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M325.15 333.39L441.67 333.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M490.22 333.39L490.02 336.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M490.02 336.5L489.42 339.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M489.42 339.55L488.44 342.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.44 342.5L487.09 345.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M487.09 345.3L485.4 347.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M485.4 347.91L483.38 350.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M483.38 350.28L481.08 352.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.08 352.37L478.53 354.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.53 354.15L475.77 355.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M475.77 355.59L472.85 356.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M472.85 356.66L469.82 357.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M469.82 357.35L466.72 357.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.72 357.65L463.61 357.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M463.61 357.55L460.54 357.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.54 357.06L457.56 356.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.56 356.17L454.72 354.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.72 354.91L452.06 353.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.06 353.3L449.62 351.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.62 351.36L447.46 349.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.46 349.13L445.6 346.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M445.6 346.63L444.07 343.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.07 343.92L442.91 341.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.91 341.04L442.12 338.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.12 338.03L441.72 334.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.72 334.95L441.72 331.84Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.72 331.84L442.12 328.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.12 328.75L442.91 325.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.91 325.74L444.07 322.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.07 322.86L445.6 320.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M445.6 320.15L447.46 317.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.46 317.66L449.62 315.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.62 315.42L452.06 313.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.06 313.48L454.72 311.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.72 311.87L457.56 310.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.56 310.61L460.54 309.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.54 309.73L463.61 309.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M463.61 309.23L466.72 309.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.72 309.13L469.82 309.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M469.82 309.43L472.85 310.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M472.85 310.12L475.77 311.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M475.77 311.19L478.53 312.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.53 312.63L481.08 314.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.08 314.41L483.38 316.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M483.38 316.5L485.4 318.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M485.4 318.87L487.09 321.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M487.09 321.48L488.44 324.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.44 324.28L489.42 327.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M489.42 327.23L490.02 330.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M490.02 330.29L490.22 333.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M325.15 333.39L325.15 357.67Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.75 296.86L336.75 269.68Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.75 269.68L348.4 269.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.4 269.68L352.29 270.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.29 270.97L353.58 272.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.58 272.26L354.88 274.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.88 274.85L354.88 277.44Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.88 277.44L353.58 280.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.58 280.03L352.29 281.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.29 281.33L348.4 282.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.75 282.62L348.4 282.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.4 282.62L352.29 283.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.29 283.92L353.58 285.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.58 285.21L354.88 287.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.88 287.8L354.88 291.68Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.88 291.68L353.58 294.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.58 294.27L352.29 295.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.29 295.57L348.4 296.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.4 296.86L336.75 296.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.94 296.86L363.94 269.68Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.94 269.68L375.59 269.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.59 269.68L379.47 270.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.47 270.97L380.77 272.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.77 272.26L382.06 274.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.06 274.85L382.06 277.44Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.06 277.44L380.77 280.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.77 280.03L379.47 281.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.47 281.33L375.59 282.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.59 282.62L363.94 282.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M373 282.62L382.06 296.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.13 296.86L401.48 269.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.48 269.68L411.84 296.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.01 287.8L407.96 287.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.61 296.86L419.61 269.68Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.61 269.68L437.73 296.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.73 296.86L437.73 269.68Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M448.09 296.86L448.09 269.68Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M448.09 269.68L457.15 269.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.15 269.68L461.04 270.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.04 270.97L463.62 273.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M463.62 273.56L464.92 276.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.92 276.15L466.21 280.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.21 280.03L466.21 286.51Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.21 286.51L464.92 290.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.92 290.39L463.62 292.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M463.62 292.98L461.04 295.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.04 295.57L457.15 296.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.15 296.86L448.09 296.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_06_03;

    