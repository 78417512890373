import { useLocation } from 'react-router-dom';

/**
 * @returns The path of the current url, without enhId
 */
const usePath = () => {
    const { pathname } = useLocation();
    const path = pathname
        .split('/')
        .filter((s) => s !== '') // Clean ends
        .splice(1); // Remove id
    return path;
};

export default usePath;
