
    const Svg1_01_03 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="315.69 243.86 146.82 101.19999999999999" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M343.5 248.86L343.5 340.06Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.5 248.86L320.69 271.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.1 294.45L457.51 294.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.5 271.65L320.69 294.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.5 294.46L320.69 317.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.5 317.25L320.69 340.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.1 294.45L388.91 297.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.91 297.43L388.33 300.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.33 300.35L387.37 303.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.37 303.18L386.05 305.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.05 305.85L384.39 308.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.39 308.33L382.42 310.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.42 310.57L380.18 312.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.18 312.54L377.7 314.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.7 314.2L375.03 315.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.03 315.52L372.2 316.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.2 316.48L369.28 317.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.28 317.06L366.3 317.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.3 317.25L363.32 317.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.32 317.06L360.4 316.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.4 316.48L357.57 315.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M357.57 315.52L354.9 314.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.9 314.2L352.42 312.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.42 312.54L350.18 310.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.18 310.57L348.21 308.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.21 308.33L346.55 305.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.55 305.85L345.23 303.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.23 303.18L344.27 300.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M344.27 300.35L343.69 297.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.69 297.43L343.5 294.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.5 294.45L343.69 291.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.69 291.47L344.27 288.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M344.27 288.55L345.23 285.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.23 285.72L346.55 283.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.55 283.05L348.21 280.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.21 280.57L350.18 278.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.18 278.33L352.42 276.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.42 276.36L354.9 274.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.9 274.7L357.57 273.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M357.57 273.38L360.4 272.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.4 272.42L363.32 271.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.32 271.84L366.3 271.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.3 271.65L369.28 271.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.28 271.84L372.2 272.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.2 272.42L375.03 273.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.03 273.38L377.7 274.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.7 274.7L380.18 276.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.18 276.36L382.42 278.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.42 278.33L384.39 280.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.39 280.57L386.05 283.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.05 283.05L387.37 285.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.37 285.72L388.33 288.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.33 288.55L388.91 291.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.91 291.47L389.1 294.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_03;

    