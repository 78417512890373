import './App.css';
import './shared/flex.css';
import 'devextreme/dist/css/dx.light.css';

import { OuterRouterObjects, PageContainer, SignInRoutes } from './app.router';
import { Outlet, RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import { Suspense, lazy, useEffect } from 'react';
import useUser, { IUserState, useAuthInit } from './shared/hooks/redux-use-user';

import AppProviders from './App-providers';
import BreadCrumbProvider from './shared/contexts/breadcrumb-context';
import GeneralErrorBoundary from './shared/error-handlers/boundary-generel';
import LoadingScreen from './screens/loading';
import Localize from './shared/localization/localization';
import MapPortal from './components/map/map-portal';
import Scanners from './shared/hooks/use-qr-scanner/scanners';
import { SnackbarDisplayer } from './shared/hooks/redux-use-centralized-snackbar';
import { isMobile } from 'react-device-detect';
import { useCli } from './cli';
import { useInitHistoryNavigate } from './shared/hooks/extended-hooks/use-history-navigate';

const DesktopCss = lazy(() => import('./style/desktop'));
const MobileCss = lazy(() => import('./style/mobile'));

const Layout = () => {
    useEffect(() => {
        Localize();
    }, []);

    window.addEventListener('scroll', (e) => {
        if (document.body.style.position === 'fixed') {
            e.preventDefault();
            window.scrollTo(0, 0);
        }
    });

    return (
        <>
            {/* Collection of all the app's providers */}
            <OuterLayout /> {/* Wrapper of all screens */}
            <Suspense fallback={<></>}>{isMobile ? <MobileCss /> : <DesktopCss />}</Suspense>
            <PortalComponents />
            <SnackbarDisplayer />{' '}
            {/* Component to display snackbars in the main window, even from other react trees (fx map overlays) */}
        </>
    );
};

const UNAUTHENTICATED_ROUTES = [/\/signin/i, /\/reset/i, /\/forgot/i];

// Redirect to sign-in if user is not authenticated
const catchUnauthenticated = (user: IUserState, request: Request) => {
    const path = new URL(request.url).pathname;
    if (!user.active && !UNAUTHENTICATED_ROUTES.some((r) => r.test(path))) {
        const url = new URL(window.location.origin);
        url.pathname = '/SignIn';
        url.searchParams.set('redirect', request.url);
        return redirect(url.toString()); // Redirect to sign-in
    }
};

// Redirect to a specific page if the redirect query parameter is set
const catchRedirect = (user: IUserState, request: Request) => {
    if (!user.active) return;
    const redirectTo = new URL(request.url).searchParams.get('redirect');
    if (redirectTo) {
        return redirect(redirectTo);
    }
};

const catchRedirectRoot = (_: IUserState, request: Request) => {
    if (new URL(request.url).pathname === '/') {
        return redirect('/0');
    }
};

const createRouter = (user: IUserState) =>
    createBrowserRouter([
        {
            path: '/',
            element: <Layout />, // Signin router is rendered
            loader: ({ request }) => {
                let returnValue = catchUnauthenticated(user, request);
                returnValue ??= catchRedirect(user, request);
                returnValue ??= catchRedirectRoot(user, request);
                if (returnValue) return returnValue;

                return null;
            },
            children: [
                ...SignInRoutes(user),
                {
                    path: '/:id/',
                    element: <PageContainer />,
                    children: [...OuterRouterObjects],
                },
            ],
        },
    ]);

const RootRouter = () => {
    const user = useUser();

    return <RouterProvider router={createRouter(user)} />;
};

const App = () => {
    return (
        <AppProviders>
            <_App />
        </AppProviders>
    );
};

const _App = () => {
    useAuthInit();
    useInitHistoryNavigate();
    return <RootRouter />;
};

const OuterLayout = () => {
    // Sign-in and navigation here, to put it outside the router suspense
    // const user = useUser();

    // Return
    // if (user.active)
    return (
        <BreadCrumbProvider>
            <GeneralErrorBoundary>
                <Suspense fallback={<LoadingScreen />}>
                    <Outlet />
                </Suspense>
            </GeneralErrorBoundary>
        </BreadCrumbProvider>
    );
    // return <SignInRouter />;
};

/**
 * A collection of components that either has to be anchored at the bottom of the page, or has to be located in a place that never rerenders externally
 * Fx used for components that break if ever unmounted, like the portal for <CaretakerMap />
 */
const PortalComponents = () => {
    useCli();
    return (
        <>
            <MapPortal />
            <Scanners />
        </>
    );
};

export default App;
