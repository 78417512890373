const SvgVarslingstryk = () => (
    <svg xmlns='http://www.w3.org/2000/svg' xmlSpace='preserve' width={32} height={32}>
        <path
            d='M-30.565 60.61v-6.52h13.04v13.041h-13.04zm8.644 2.852c.931-.617 1.653-1.907 1.653-2.956 0-1.68-1.554-3.382-3.302-3.617l-.818-.11-1.099.473-1.098.473-.518.883-.517.883v2.029l.518.885c.46.785.933 1.136 2.216 1.645.651.259 2.129-.035 2.965-.588'
            style={{
                fill: '{{replacement-value-color}}',
            }}
            transform='translate(40.192 -50)'
        />
    </svg>
);
export default SvgVarslingstryk;
