import CustomStore from 'devextreme/data/custom_store';
import { IDictionary } from '../../shared/utils/types';
import Typography from '@mui/material/Typography/Typography';
import dxDataGrid from 'devextreme/ui/data_grid';
import useMountEffect from '../../shared/hooks/use-mount-effect';
import { useState } from 'react';
import { ImageGridItem } from '../file-displaying/image-grid';
import { IFile } from '../auto-form';
import Box from '@mui/material/Box';

// TODO: Fix any types
// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ICellProps<TData = any, TOptions = any> {
    dxProps: IDxRenderProps<TData>;
    options: TOptions;
}

interface IDxRenderProps<T = object> extends IDictionary {
    cellElement?: HTMLTableCellElement;
    columnIndex: number;
    component: dxDataGrid;
    data: T;
    key: number;
    displayValue: string;
    rowIndex: number;
    text: string;
    value: string | number | Date;
    values: Array<string | number | Date>;
}

const Default = (props: ICellProps) => {
    return <>{props.dxProps.text}</>;
};

//#region Drift cells

//#region Ansvarlig over Beskrivelse

interface DAOB_Data {
    ansvarlig: string;
    beskrivelse: string;
}

interface DAOB_Options {
    ansvarligStore: CustomStore;
}

/**
 * Displays "Ansvarlig" on a line above "Beskrivelse"
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @param DAOB_Option A store with an byKey function taking an id and giving the corresponding string
 */

const Drift_Ansvarlig_Over_Beskrivelse = (props: ICellProps<DAOB_Data, DAOB_Options>) => {
    const [ansvarlig, setAnsvarlig] = useState('...');

    useMountEffect(() => {
        const get = async () =>
            setAnsvarlig((await props.options.ansvarligStore.byKey(props.dxProps.data.ansvarlig)).value);
        get();
    });

    return (
        <>
            {ansvarlig} <br />
            {props.dxProps.data.beskrivelse ?? 'Ingen beskrivelse'}
        </>
    );
};

//#endregion Ansvarlig over Beskrivelse
//#region Interval_Type over Beskrivelse

interface DITOB_Data {
    interval_type: string;
    beskrivelse: string;
}

interface DITOB_Options {
    intervalTypeStore: CustomStore;
}

/**
 * Displays "Interval_Type" on a line above "Beskrivelse"
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @param DITOB_Option A store with an byKey function taking an id and giving the corresponding string
 */
const Drift_Interval_Type_Over_Beskrivelse = (props: ICellProps<DITOB_Data, DITOB_Options>) => {
    const [interval_Type, setInterval_Type] = useState('...');

    useMountEffect(() => {
        console.log('finding interval_type');
        const get = async () => {
            setInterval_Type((await props.options.intervalTypeStore.byKey(props.dxProps.data.interval_type)).value);
        };
        get();
    });
    return (
        <>
            {interval_Type} <br />
            {props.dxProps.data.beskrivelse ?? 'Ingen beskrivelse'}
        </>
    );
};

//#endregion Interval_Type over Beskrivelse
//#region Periodetekst over Beskrivelse

interface DPOB_Data {
    periodetekst: string;
    beskrivelse: string;
}

interface DPOB_Options {}

/**
 * Displays "Periodetekst" on a line above "Beskrivelse"
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 */
const Drift_Periodetekst_Over_Beskrivelse = (props: ICellProps<DPOB_Data, DPOB_Options>) => {
    return (
        <>
            <Typography sx={{ paddingBottom: '5px', fontSize: '14px', color: 'gray' }}>
                {props.dxProps.data.periodetekst}
            </Typography>
            <Typography sx={{ fontSize: '14px' }}>{props.dxProps.data.beskrivelse ?? 'Ingen beskrivelse'}</Typography>
        </>
    );
};

//#endregion Periodetekst over Beskrivelse
//#region Drift cells

/**
 * Displays the first image in a datagrid cell
 */
const Forsyning_Billede = (props: ICellProps) => {
    const files = props.dxProps.data.filer;
    if (files?.length === 0) return <></>;
    const testImage: IFile = files[0];
    return (
        <>
            <Box
                maxHeight={'70px'}
                maxWidth={'70px'}
                style={{ pointerEvents: 'none', marginTop: '-9px', marginBottom: '-9px' }}
            >
                <ImageGridItem file={testImage} disableDownload disableDelete />
            </Box>
        </>
    );
};

/**
 * Enum of keys for CellRender functions
 * Each key is prepended with a string indicating which CT-module it's intended for
 */
export enum CellRenderKeys {
    Default = 'default',
    Drift_Ansvarlig_Over_Beskrivelse = 'drift-ansvarlig-over-beskrivelse',
    Drift_Interval_Type_Over_Beskrivelse = 'drift-interval-type-over-beskrivelse',
    Drift_Periodetekst_Over_Beskrivelse = 'drift-periodetekst-over-beskrivelse',
    Forsyning_Billede = 'forsyning-billede',
}

const CellRenders: IDictionary<(props: ICellProps) => JSX.Element> = {
    [CellRenderKeys.Default]: Default,
    [CellRenderKeys.Drift_Ansvarlig_Over_Beskrivelse]: Drift_Ansvarlig_Over_Beskrivelse,
    [CellRenderKeys.Drift_Interval_Type_Over_Beskrivelse]: Drift_Interval_Type_Over_Beskrivelse,
    [CellRenderKeys.Drift_Periodetekst_Over_Beskrivelse]: Drift_Periodetekst_Over_Beskrivelse,
    [CellRenderKeys.Forsyning_Billede]: Forsyning_Billede,
};

/**
 * Function to get a customized render function for a datagrid cell
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @param key CellRenderKey corresponding to the desired render function
 * @param options Options object. Varies between render functions
 * @returns A function which, given the dxProps object, renders a cell in a datagrid
 */
function GetCellRender<TOptions = unknown>(key: string, options: TOptions | {} = {}) {
    const RenderElement = CellRenders[key];
    if (RenderElement === undefined) return undefined;
    return (dxProps: IDxRenderProps<unknown>) => (
        <>
            <RenderElement dxProps={dxProps} options={options} />
        </>
    );
}

export default GetCellRender;
