import { layers, map } from '../..';
import { useEffect, useRef, useState } from 'react';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BaseControl from '../base-control';
import Button from '@mui/material/Button';
import CTLayer from '../../CTLayer';
import { ControlHandler } from '../../ControlHandler';
import Feature from 'ol/Feature';
import { FeatureProperty } from '../../feature-utility';
import { PlaygroundControl } from './control';
import { Point } from 'ol/geom';
import { RepresentationLayer } from '../../LayerMenuControl';
import Typography from '@mui/material/Typography';
import { bbruuid } from './interfaces';
import { getNextBBRUUIDClick } from '../../../../../cli/map/features';

const PlaygroundGui = ({ controlHandler }: { controlHandler: ControlHandler }) => {
    return (
        <BaseControl
            id={`ol-${PlaygroundControl.ControlName}`}
            name={PlaygroundControl.ControlName}
            controlHandler={controlHandler}
            buttonProps={{
                icon: <AdminPanelSettingsIcon />,
            }}
        >
            <Content />
        </BaseControl>
    );
};

export default PlaygroundGui;

const Content = () => {
    const [bbruuid, setBbruuid] = useState<bbruuid | null>();
    const layerRef = useRef<CTLayer>();

    const bygBBRUUID = async () => {
        setBbruuid(undefined);
        const uuid = await getNextBBRUUIDClick();
        setBbruuid(uuid);
    };

    // Create and add layer on mount
    useEffect(() => {
        layerRef.current = layers.getRepresentationLayer(RepresentationLayer.Enhed)?.layer;
        if (!layerRef.current) return;

        map?.addLayer(layerRef.current);

        // Cleanup
        return () => {
            map?.removeLayer(layerRef.current!);
        };
    }, []);

    // Draw pin on chosen building
    useEffect(() => {
        const layer = layerRef.current!;

        if (!bbruuid) return;

        const pin = new Feature({
            geometry: new Point(bbruuid.bbrCoordinate),
        });
        pin.set(FeatureProperty.Data, { label: bbruuid.uuid });

        const source = layer.getSource()!;
        source.addFeature(pin);

        return () => {
            source.removeFeature(pin);
        };
    }, [bbruuid]);

    return (
        <>
            <Button onClick={bygBBRUUID}>Print next feature</Button>
            {bbruuid && <Typography>{bbruuid.uuid}</Typography>}
            {bbruuid?.distance != null && (
                <Typography>Afstand fra klik: {bbruuid.distance?.toFixed(2)} meter</Typography>
            )}
            {bbruuid === null && <Typography>BBR Fejl</Typography>}
        </>
    );
};
