
    const Svg1_01_15 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="277.54 236.67 202.98999999999995 136.24000000000004" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M393.51 296.35L393.71 293.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.71 293.05L394.3 289.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.3 289.81L395.29 286.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.29 286.65L396.64 283.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.64 283.64L398.35 280.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.35 280.82L400.38 278.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.38 278.22L402.72 275.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.72 275.88L405.32 273.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.32 273.85L408.14 272.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.14 272.14L411.15 270.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.15 270.78L414.31 269.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.31 269.8L417.55 269.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.55 269.21L420.85 269.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.85 269.01L424.14 269.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.14 269.21L427.39 269.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.39 269.8L430.54 270.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M430.54 270.78L433.55 272.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.55 272.14L436.38 273.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.38 273.85L438.98 275.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438.98 275.88L441.31 278.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.31 278.22L443.35 280.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.35 280.82L445.06 283.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M445.06 283.64L446.41 286.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.41 286.65L447.39 289.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.39 289.81L447.99 293.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.99 293.05L448.19 296.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M448.19 296.35L447.99 299.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.99 299.64L447.39 302.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.39 302.89L446.41 306.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.41 306.04L445.06 309.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M445.06 309.05L443.35 311.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.35 311.88L441.31 314.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.31 314.48L438.98 316.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438.98 316.81L436.38 318.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.38 318.85L433.55 320.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.55 320.56L430.54 321.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M430.54 321.91L427.39 322.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.39 322.89L424.14 323.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.14 323.49L420.85 323.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.85 323.69L417.55 323.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.55 323.49L414.31 322.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.31 322.89L411.15 321.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.15 321.91L408.14 320.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.14 320.56L405.32 318.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.32 318.85L402.72 316.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.72 316.81L400.38 314.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.38 314.48L398.35 311.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.35 311.88L396.64 309.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.64 309.05L395.29 306.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.29 306.04L394.3 302.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.3 302.89L393.71 299.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.71 299.64L393.51 296.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.85 258.07L420.85 241.67Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.51 269.01L377.1 252.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.57 296.35L366.17 296.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.51 323.69L377.1 340.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M448.19 323.69L464.59 340.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M448.19 269.01L464.59 252.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.85 334.63L420.85 351.03Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.13 296.35L475.53 296.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M282.54 367.91L311.7 324.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M282.54 324.17L311.7 367.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.2 367.91L353.36 324.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.2 324.17L353.36 367.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_15;

    