
    const Svg1_05_15 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="245.11 228.0 296.71000000000004 153.37" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M536.82 304.69L536.62 300.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M536.62 300.9L536.02 297.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M536.02 297.13L535.02 293.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M535.02 293.38L533.63 289.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M533.63 289.66L531.85 285.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M531.85 285.98L529.68 282.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M529.68 282.35L527.13 278.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M527.13 278.79L524.22 275.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M524.22 275.3L520.93 271.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M520.93 271.89L517.29 268.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M517.29 268.57L513.31 265.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M513.31 265.35L508.99 262.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M508.99 262.24L504.35 259.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M504.35 259.25L499.4 256.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M499.4 256.39L494.15 253.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M494.15 253.66L488.62 251.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.62 251.07L482.83 248.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M482.83 248.64L476.79 246.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M476.79 246.36L470.52 244.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.52 244.24L464.03 242.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.03 242.29L457.35 240.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.35 240.51L450.48 238.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.48 238.92L443.46 237.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.46 237.5L436.3 236.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.3 236.28L429.02 235.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.02 235.24L421.64 234.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.64 234.4L414.18 233.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.18 233.75L406.67 233.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.67 233.3L399.11 233.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.11 233.05L391.54 233" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.54 233L383.98 233.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.98 233.15L376.44 233.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.44 233.5L368.95 234.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.95 234.05L361.53 234.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.53 234.79L354.2 235.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.2 235.73L346.98 236.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.98 236.87L339.88 238.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M339.88 238.19L332.94 239.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M332.94 239.69L326.16 241.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M326.16 241.38L319.57 243.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319.57 243.24L313.19 245.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M313.19 245.28L307.03 247.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M307.03 247.48L301.11 249.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M301.11 249.84L295.45 252.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M295.45 252.35L290.06 255.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M290.06 255.01L284.96 257.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M284.96 257.81L280.17 260.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M280.17 260.73L275.69 263.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M275.69 263.78L271.53 266.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M271.53 266.95L267.72 270.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M267.72 270.22L264.26 273.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M264.26 273.58L261.15 277.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M261.15 277.03L258.42 280.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M258.42 280.56L256.06 284.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M256.06 284.16L254.08 287.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M254.08 287.81L252.5 291.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M252.5 291.51L251.3 295.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M251.3 295.25L250.51 299.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M250.51 299.02L250.11 302.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M250.11 302.79L250.11 306.58Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M250.11 306.58L250.51 310.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M250.51 310.36L251.3 314.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M251.3 314.12L252.5 317.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M252.5 317.86L254.08 321.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M254.08 321.56L256.06 325.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M256.06 325.21L258.42 328.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M258.42 328.81L261.15 332.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M261.15 332.34L264.26 335.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M264.26 335.79L267.72 339.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M267.72 339.16L271.53 342.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M271.53 342.43L275.69 345.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M275.69 345.59L280.17 348.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M280.17 348.64L284.96 351.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M284.96 351.57L290.06 354.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M290.06 354.37L295.45 357.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M295.45 357.02L301.11 359.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M301.11 359.54L307.03 361.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M307.03 361.9L313.19 364.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M313.19 364.1L319.57 366.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319.57 366.13L326.16 368" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M326.16 368L332.94 369.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M332.94 369.68L339.88 371.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M339.88 371.19L346.98 372.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.98 372.51L354.2 373.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.2 373.64L361.53 374.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.53 374.58L368.95 375.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.95 375.32L376.44 375.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.44 375.87L383.98 376.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.98 376.22L391.54 376.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.54 376.37L399.11 376.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.11 376.32L406.67 376.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.67 376.07L414.18 375.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.18 375.62L421.64 374.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.64 374.98L429.02 374.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.02 374.13L436.3 373.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.3 373.1L443.46 371.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.46 371.87L450.48 370.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.48 370.46L457.35 368.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.35 368.86L464.03 367.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.03 367.09L470.52 365.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.52 365.14L476.79 363.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M476.79 363.02L482.83 360.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M482.83 360.74L488.62 358.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.62 358.3L494.15 355.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M494.15 355.71L499.4 352.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M499.4 352.98L504.35 350.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M504.35 350.12L508.99 347.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M508.99 347.13L513.31 344.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M513.31 344.02L517.29 340.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M517.29 340.8L520.93 337.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M520.93 337.49L524.22 334.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M524.22 334.08L527.13 330.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M527.13 330.58L529.68 327.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M529.68 327.02L531.85 323.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M531.85 323.39L533.63 319.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M533.63 319.72L535.02 316" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M535.02 316L536.02 312.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M536.02 312.24L536.62 308.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M536.62 308.47L536.82 304.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340 333.36L378.23 276.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340 276.01L378.23 333.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.62 333.36L432.85 276.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.62 276.01L432.85 333.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_05_15;

    