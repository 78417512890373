
    const SvgXx_06_14 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="219.07 247.28 391.34 98.35999999999999" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M312.4 296.46L312.21 300.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M312.21 300.6L311.63 304.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M311.63 304.7L310.66 308.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.66 308.73L309.33 312.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M309.33 312.65L307.63 316.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M307.63 316.43L305.59 320.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M305.59 320.04L303.22 323.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M303.22 323.43L300.54 326.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M300.54 326.59L297.57 329.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M297.57 329.49L294.35 332.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M294.35 332.09L290.9 334.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M290.9 334.38L287.25 336.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M287.25 336.34L283.43 337.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M283.43 337.95L279.48 339.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M279.48 339.19L275.43 340.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M275.43 340.06L271.32 340.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M271.32 340.55L267.17 340.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M267.17 340.64L263.04 340.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M263.04 340.35L258.95 339.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M258.95 339.67L254.95 338.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M254.95 338.62L251.06 337.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M251.06 337.19L247.32 335.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M247.32 335.41L243.77 333.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M243.77 333.28L240.43 330.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M240.43 330.83L237.33 328.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M237.33 328.08L234.51 325.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M234.51 325.04L231.98 321.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M231.98 321.76L229.77 318.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M229.77 318.26L227.9 314.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M227.9 314.56L226.38 310.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M226.38 310.71L225.23 306.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M225.23 306.73L224.45 302.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M224.45 302.66L224.07 298.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M224.07 298.53L224.07 294.39Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M224.07 294.39L224.45 290.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M224.45 290.27L225.23 286.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M225.23 286.2L226.38 282.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M226.38 282.22L227.9 278.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M227.9 278.36L229.77 274.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M229.77 274.67L231.98 271.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M231.98 271.16L234.51 267.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M234.51 267.88L237.33 264.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M237.33 264.85L240.43 262.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M240.43 262.1L243.77 259.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M243.77 259.65L247.32 257.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M247.32 257.52L251.06 255.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M251.06 255.73L254.95 254.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M254.95 254.31L258.95 253.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M258.95 253.25L263.04 252.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M263.04 252.57L267.17 252.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M267.17 252.28L271.32 252.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M271.32 252.38L275.43 252.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M275.43 252.86L279.48 253.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M279.48 253.73L283.43 254.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M283.43 254.98L287.25 256.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M287.25 256.58L290.9 258.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M290.9 258.54L294.35 260.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M294.35 260.83L297.57 263.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M297.57 263.44L300.54 266.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M300.54 266.33L303.22 269.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M303.22 269.49L305.59 272.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M305.59 272.89L307.63 276.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M307.63 276.49L309.33 280.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M309.33 280.27L310.66 284.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.66 284.19L311.63 288.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M311.63 288.22L312.21 292.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M312.21 292.32L312.4 296.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M312.4 296.46L330.08 296.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.08 296.46L330.08 292.04Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 365.43 292.04 A 17.677 17.677 0 0 0 347.76 274.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 347.76 274.37 A 17.677 17.677 0 0 0 330.08 292.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.43 292.04L365.43 300.88Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 365.43 300.88 A 17.677 17.677 0 0 0 383.11 318.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.79 300.88L400.79 292.04Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 383.11 318.56 A 17.677 17.677 0 0 0 400.79 300.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 418.46 274.37 A 17.677 17.677 0 0 0 400.79 292.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.14 292.04L436.14 300.88Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 436.14 292.04 A 17.677 17.677 0 0 0 418.46 274.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 436.14 300.88 A 17.677 17.677 0 0 0 453.82 318.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M471.49 300.88L471.49 292.04Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 453.82 318.56 A 17.677 17.677 0 0 0 471.49 300.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 489.17 274.37 A 17.677 17.677 0 0 0 471.49 292.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M506.85 292.04L506.85 300.88Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 506.85 292.04 A 17.677 17.677 0 0 0 489.17 274.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 506.85 300.88 A 17.677 17.677 0 0 0 524.52 318.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M542.2 300.88L542.2 292.04Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 524.52 318.56 A 17.677 17.677 0 0 0 542.2 300.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 559.88 274.37 A 17.677 17.677 0 0 0 542.2 292.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M577.55 292.04L577.55 296.46Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 577.55 292.04 A 17.677 17.677 0 0 0 559.88 274.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M577.55 296.46L605.41 296.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_06_14;

    