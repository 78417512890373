
    const Svg1_05_04A = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="325.61 189.36 140.51999999999998 173.14" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M363.23 324.88L444.78 324.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.78 324.88L444.78 308.57Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.78 308.57L363.23 308.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.23 308.57L363.23 324.88Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.16 324.88L412.16 357.5Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.16 357.5L330.61 357.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.61 357.5L330.61 243.33Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.61 243.33L412.16 243.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.16 243.33L412.16 308.57Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.92 292.26L395.85 292.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.85 292.26L395.85 259.64Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.85 259.64L346.92 259.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.92 259.64L346.92 292.26Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.23 259.64L363.23 292.26Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.54 259.64L379.54 292.26Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.13 243.33L460.79 238.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.79 238.86L460.06 234.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.06 234.43L458.94 230.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.94 230.09L457.43 225.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.43 225.86L455.56 221.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.56 221.79L453.34 217.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M453.34 217.89L450.77 214.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.77 214.21L447.9 210.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.9 210.77L444.72 207.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.72 207.59L441.28 204.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.28 204.72L437.6 202.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.6 202.15L433.7 199.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.7 199.93L429.63 198.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.63 198.06L425.4 196.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.4 196.55L421.06 195.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.06 195.43L416.63 194.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.63 194.7L412.16 194.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.81 243.33L444.45 239.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.45 239.73L443.72 236.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.72 236.19L442.62 232.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.62 232.74L441.16 229.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.16 229.43L439.35 226.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.35 226.3L437.23 223.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.23 223.37L434.81 220.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.81 220.68L432.12 218.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.12 218.26L429.19 216.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.19 216.14L426.06 214.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.06 214.34L422.75 212.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.75 212.87L419.3 211.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.3 211.77L415.76 211.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.76 211.04L412.16 210.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.47 243.33L428.08 240.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.08 240.86L427.34 238.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.34 238.47L426.29 236.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.29 236.2L424.92 234.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.92 234.1L423.28 232.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.28 232.21L421.39 230.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.39 230.57L419.29 229.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.29 229.21L417.02 228.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.02 228.15L414.63 227.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.63 227.42L412.16 227.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_05_04A;

    