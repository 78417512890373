import { CUSTOMER_LOGO_URL } from '../../config';
import Button from 'devextreme-react/button';
import DOMPurify from 'dompurify';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Api from '../../shared/networking/api';
import useCentralizedSnackbar from '../../shared/hooks/redux-use-centralized-snackbar';
import { useEffect } from 'react';
import { FormFilesUploader } from '../byg/stamdata/stamdata-form-components';
import FileInfoGrid, { IFileInfo } from '../../components/file-displaying/file-info-grid';
import ImageGrid from '../../components/file-displaying/image-grid';
import { IFile } from '../../components/file-displaying/common';
import TextArea from 'devextreme-react/text-area';
import useFetch from '../../shared/hooks/fetch-hooks/use-fetch';
import LoadingScreen from '../loading';
import { Divider } from '@mui/material';
import useCTNavigate from '../../shared/hooks/use-ct-navigate';
import Box from '@mui/system/Box';
import { custom as customDialog } from 'devextreme/ui/dialog';
import WarningIcon from '@mui/icons-material/Warning';

const VendorFields = () => {
    const { rekvisitionId } = useParams<{ rekvisitionId: string }>();
    const { enqueueSnackbar } = useCentralizedSnackbar();
    const navigate = useCTNavigate();
    const uploadUrl = '/Rekv/rekvisition/1/file';
    // TODO: Remove null coalescing from data access when error handling is added
    const [_data, isLoading, isError, , , refetch] = useFetch<{ note: string; files: IFileInfo[], completed: boolean }[]>(
        // '/Rekv/rekvisition/1/status'
        '/Byg/Del/157563'
    );

    const afslutRekvisition = async () => {
        const confirmed = await customDialog({
            title: 'Afslut rekvisition',
            message: 'Bekræft at rekvisitionen er afsluttet',
            buttons: [
                {
                    text: 'Afslut',
                    type: 'default',
                    onClick: () => {
                        return true;
                    },
                },
                {
                    text: 'Anullér',
                    onClick: () => {
                        return false;
                    },
                },
            ],
        }).show();
        if (!confirmed) return;

        const response = await Api.put(`/Finans/RekvAfslut?rekvsys=${rekvisitionId}`);
        if (!Api.ok(response)) {
            enqueueSnackbar('Kunne ikke afslutte rekvisition, prøv igen senere', { variant: 'error' });
        } else {
            enqueueSnackbar('Rekvisition afsluttet', { variant: 'success' });
            navigate('/Rekvisition');
        }
    }

    useEffect(() => {
        if (isError) {
            enqueueSnackbar('Kunne ikke hente note eller filer', { variant: 'error' });
        }
    }, [isError]);

    if (isLoading) return <LoadingScreen />;
    if (isError) return (
        <div className='flex justify-center space-x-2'>
            <WarningIcon className='text-yellow-500' />
            <Typography>Noter fra leverendør kunne ikke hentes. Prøv igen senere</Typography>
            <WarningIcon className='text-yellow-500' />
        </div>
    );

    const data = _data?.[0];

    return (
        <div>
            <Typography
                pt='15px'
                pb='10px'
                mb='10px'
                fontFamily={'"Helvetica Neue","Segoe UI",helvetica,verdana,sans-serif'}
                fontSize={'19px'}
                textAlign='left'
            >
                Filer og Billeder fra leverandøren
            </Typography>
            <FormFilesUploader uploadUrl={uploadUrl} refetch={refetch} />
            <ImageGrid files={data?.files?.filter((file) => (file as IFile).type === 'image') ?? []} />
            <FileInfoGrid
                getUrl=''
                data={data?.files?.filter((file) => file.type === 'file') ?? []}
                onDelete={refetch}
            />
            <Typography
                pt='15px'
                pb='10px'
                mb='10px'
                fontFamily={'"Helvetica Neue","Segoe UI",helvetica,verdana,sans-serif'}
                fontSize={'19px'}
                textAlign='left'
            >
                Noter fra leverandøren
            </Typography>
            <TextArea defaultValue={data?.note} height={200} />
            <div className="flex justify-center">
                <Button className='m-2' text='Gem note' type='success' />
                <Button className='m-2' text='Afslut rekvisition' type='default' onClick={afslutRekvisition} />
            </div>
        </div>
    );
}

export interface RekvData {
    id: number;
    rekvisitionsDato?: string;
    litra?: string;
    nummer?: number;
    aftaltBeloeb?: number;
    rekvisitionTekst?: string;
    rekvisitionNote?: string;
    levMail?: string;
    levId?: number;
    levNavn?: string;
    levAdresse?: string;
    levPostnr?: string;
    levPostby?: string;
    levAtt?: string;
    levmailAtt?: string;
    leveresNavn?: string;
    leveresAdresse?: string;
    leveresPostnr?: string;
    leveresPostby?: string;
    leveresKontaktperson?: string;
    leveresTidspunkt?: string;
    leveresVenlighilsen1?: string;
    leveresVenlighilsen2?: string;
    rekvisitionNr?: string;
    rekvisitionEannr?: string;
    rekvisitionEnhed?: string;
    rekvisitionKreditornr?: string;
    // Yep, these have lots of spelling mistakes
    fakuraNavn?: string;
    fakturAdresse?: string;
    fakturAdresse2?: string;
    fakturaPostnr?: string;
    fakturaPostby?: string;
    fakturaTlf?: string;
    rekvirentNavn1?: string;
    rekvirentNavn2?: string;
    rekvirentMail?: string;
    bundtekst1?: string;
    bundtekst2?: string;
    bundtekst3?: string;
    bundtekst4?: string;
    enhedId?: number;
    afsluttet?: string;
    annuleret?: string;
    opretDato?: string;
    opretBruger?: string;
    retDato?: string;
    retBruger?: string;
    konto?: string;
    kontoTekst?: string;
    note?: string;
}

export const formatSingleLine = (callback: (data: RekvData) => string) => (data: RekvData) => {
    return callback(data).replace(/\n/g, ' ');
}

export const formatLev = (data: RekvData) => {
    return (
        (data.levNavn ? data.levNavn + '\n' : '')
        + (data.levAdresse ? data.levAdresse + '\n' : '')
        + (data.levPostnr ? data.levPostnr + '\t' : '')
        + (data.levPostby ? data.levPostby + '\n' : '')
        + (data.levAtt ? 'Att.: ' + data.levAtt + '\n' : '')
    );
}

export const formatLeveres = (data: RekvData) => {
    return (
        (data.leveresNavn ? data.leveresNavn + '\n' : '')
        + (data.leveresAdresse ? data.leveresAdresse + '\n' : '')
        + (data.leveresPostnr ? data.leveresPostnr + '\t' : '')
        + (data.leveresPostby ? data.leveresPostby : '')
    );
}

export const formatVenligHilsen = (data: RekvData) => {
    return (
        (data.leveresVenlighilsen1 ? data.leveresVenlighilsen1 + '\n' : '')
        + (data.leveresVenlighilsen2 ? data.leveresVenlighilsen2 : '')
    );
}

export const formatRekvisitionsDato = (data: RekvData) => {
    return data.rekvisitionsDato ? new Date(data.rekvisitionsDato).toLocaleDateString() : '';
}

export const formatFakturaAdresse = (data: RekvData) => {
    return (
        (data.fakuraNavn ? data.fakuraNavn + '\n\n' : '')
        + (data.fakturAdresse ? data.fakturAdresse + '\n' : '')
        + (data.fakturAdresse2 ? data.fakturAdresse2 + '\n' : '')
        + (data.fakturaPostnr ? data.fakturaPostnr + '\t' : '')
        + (data.fakturaPostby ? data.fakturaPostby : '')
    );
}

export const formatRekvirent = (data: RekvData) => {
    return (data.rekvirentNavn1 ?? '') + '\n' + (data.rekvirentNavn2 ?? '');
}

export const formatRekvirentFull = (data: RekvData) => {
    return (data.rekvirentNavn1 ? data.rekvirentNavn1 + (
        data.fakturaTlf ? `(${data.fakturaTlf})` : ''
    ) + '\n' : '')
        + (data.rekvirentNavn2 ? data.rekvirentNavn2 + '\n' : '')
        + (data.rekvirentMail ? data.rekvirentMail : '')
}

const RekvLine = ({
    label,
    value,
    placement = 'side',
    alwaysShow = false
}: {
    label: string;
    value: string | undefined;
    placement: 'side' | 'under';
    alwaysShow?: boolean;
}) => {
    if (value == null && !alwaysShow) return null;
    return (
        <>
            <Grid container pb='0.5rem'>
                <Grid item xs={placement === 'side' ? 4.5 : 12} pr='0.5rem'>
                    <Typography fontWeight={800} align='left' fontSize='0.65rem'>
                        {label}
                    </Typography>
                </Grid>
                <Grid item xs={placement === 'side' ? 7.5 : 12}>
                    <Typography whiteSpace='pre-line' align='left' fontSize='0.65rem'>
                        {value}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

const RekvDocument = () => {
    const { rekvisitionId } = useParams<{ rekvisitionId: string }>();
    const [_data, isLoading, isError] = useFetch<[RekvData]>(`/Finans/RekvPrint?rekvsys=${rekvisitionId}`);

    // Data is a 1-element array...
    const data = _data?.[0];

    const logo = CUSTOMER_LOGO_URL;

    if (isLoading) {
        return <LoadingScreen />;
    }

    if (isError) {
        return <Typography>Der skete en fejl, prøv igen senere</Typography>;
    }

    return (
        <div
            className='flex flex-col aspect-[1/1.4142] p-12 bg-white text-[3] border border-black border-solid max-w-[892px]'
        >
            <div style={{ flexGrow: 0 }}>
                <Grid container>
                    <Grid item xs={3}>
                        <img src={logo} alt='customer logo' />
                    </Grid>
                    <Grid item xs={9} pl='2rem' mb='2rem'>
                        <Typography fontSize='0.65rem' fontWeight={800} align='left'>
                            Rekvisition
                        </Typography>
                        <Typography fontSize='0.65rem' align='left'>
                            {data.rekvisitionTekst}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} pr={'0.5rem'}>
                        <RekvLine
                            label=''
                            value={formatLev(data)}
                            placement='side'
                            alwaysShow
                        />
                        <div style={{ paddingBottom: '3rem' }} />
                        <RekvLine
                            label='Leveres'
                            value={formatLeveres(data)}
                            placement='side'
                            alwaysShow
                        />
                        <RekvLine
                            label='Knt.pers.'
                            value={data.leveresKontaktperson}
                            placement='side'
                            alwaysShow
                        />
                        <RekvLine
                            label='Lev.tidspunkt'
                            value={data.leveresTidspunkt}
                            placement='side'
                            alwaysShow
                        />
                        <RekvLine
                            label='Venlig hilsen'
                            value={formatVenligHilsen(data)}
                            placement='under'
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <RekvLine
                            label='Rekvisitionsnr.'
                            value={data.rekvisitionNr}
                            placement='side'
                            alwaysShow
                        />
                        <RekvLine
                            label='EAN-nummer'
                            value={data.rekvisitionEannr}
                            placement='side'
                            alwaysShow
                        />
                        <RekvLine
                            label='Rekvisitionsdato'
                            value={formatRekvisitionsDato(data)}
                            placement='side'
                            alwaysShow
                        />
                        <RekvLine
                            label='Kreditornr.'
                            value={data.rekvisitionKreditornr}
                            placement='side'
                        />
                        <RekvLine
                            label='Enhednr'
                            value={data.rekvisitionEnhed}
                            placement='side'
                            alwaysShow
                        />
                        <RekvLine
                            label='Kontonr.'
                            value={data.kontoTekst}
                            placement='side'
                        />
                        <RekvLine
                            label='Faktura adresse'
                            value={formatFakturaAdresse(data)}
                            placement='side'
                            alwaysShow
                        />
                        <RekvLine
                            label='Rekvirent'
                            value={formatRekvirentFull(data)}
                            placement='side'
                            alwaysShow
                        />
                        <RekvLine
                            label='Tlf.'
                            value={data.fakturaTlf}
                            placement='side'
                        />
                    </Grid>
                </Grid>
                <Divider />
            </div>
            <div style={{ flexGrow: 1 }}>
                <Typography fontSize='0.65rem' align='left' fontWeight={800}>
                    {data.rekvisitionTekst}
                </Typography>
                <Box
                    sx={{
                        fontSize: '0.65rem',
                        '& table': {
                            border: '1px solid black',
                            borderCollapse: 'collapse',
                        },
                        '& td': {
                            border: '1px solid black',
                            padding: '0.25rem',
                        }
                    }}
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data.rekvisitionNote ?? '')
                    }}
                />
            </div>
            <div style={{ flexGrow: 0 }}>
                <Divider />
                <Typography fontSize='0.65rem' align='left'>
                    {data.bundtekst1}
                </Typography>
                <Typography fontSize='0.65rem' align='left'>
                    {data.bundtekst2}
                </Typography>
                <Typography fontSize='0.65rem' align='left'>
                    {data.bundtekst3}
                </Typography>
                <Typography fontSize='0.65rem' align='left'>
                    {data.bundtekst4}
                </Typography>
            </div>
        </div>
    );
}

const SeeRekvisitionScreen = () => {
    const navigate = useCTNavigate();

    return (
        <div className='w-full h-full flex flex-col p-2 space-y-2'>
            <div className='flex grow-0'>
                <Button
                    style={{
                        height: '38px',
                    }}
                    icon='arrowleft'
                    onClick={() => navigate('/Rekvisition')}
                />
            </div>
            <div
                className='grow overflow-auto'
            >
                <div className="max-w-[892px] space-y-4">
                    <RekvDocument />
                    <VendorFields />
                </div>
            </div>
        </div >
    );
};

export default SeeRekvisitionScreen;
