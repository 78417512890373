import { useCallback } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useEnhedId } from "./use-enhed-id";

/**
 * Hook for navigating through the app, automatically prefixing the current enhed id to the path if it is not already present
 *
 * To traverse history, navigate can be used with the following values:
 * - -1: Go back one step
 * - -n: Go back n steps
 * - 1: Go forward one step
 * - n: Go forward n steps
 *
 * @returns the navigation function
 */
const useCTNavigate = (): NavigateFunction => {
    const id = useEnhedId();
    const _navigate = useNavigate();

    const navigate = useCallback(((toOrDelta, options) => {
        // regex: starts with / but not followed by a number
        if (typeof toOrDelta === "string" && /^\/-?[^0-9]+/gm.test(toOrDelta)) {
            toOrDelta = `/${id}${toOrDelta}`;
        }
        _navigate(toOrDelta, options);
    }) as NavigateFunction, [_navigate, id]);

    return navigate;
}

export default useCTNavigate;
