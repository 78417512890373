import { ClickableFeature, IZoomOptions } from '../../ClickableFeatures';
import { GuiProps, IGroupedLayers, ISearchSource, ISearchSourceCollection, UnionFeature } from './interfaces';
import { IMapControl, IMapLayer } from '../../../caretaker-map';

import AppProviders from '../../../../../App-providers';
import CTLayer from '../../CTLayer';
import { Cluster } from 'ol/source';
import Content from './content';
import { ControlHandler } from '../../ControlHandler';
import { ControlNames } from '../../../interfaces';
import Feature from 'ol/Feature';
import { FeatureProperty } from '../../feature-utility';
import Geometry from 'ol/geom/Geometry';
import { ICTControl } from '../../CTControl';
import { ILayers } from '../../LayerMenuControl';
import { PluggableMap } from 'ol';
import ReactControl from '../react-control-parent';
import UtilsString from '../../../../../shared/utils/utils-string';
import { changeFloor } from '../create-gui/create-layer-gui';
import { createRoot } from 'react-dom/client';
import { getSnackbar } from '../../../../../shared/hooks/redux-use-centralized-snackbar';
import { staticImplements } from '../../../../../shared/utils/decorators';

// ===================================================================
//
//  Class controlling the searchbar for highlighting features
//  Everything is static because only a single instance is needed
//
//  Logic is contained here while the ui exists in ./content.ts
//
// ===================================================================

/**
 * Control for highlighting features through searching
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 */
@staticImplements<ICTControl>()
export class SearchFeaturesControl extends ReactControl {
    public static readonly ControlName = ControlNames.SearchFeatures;
    private static ctrl: IMapControl;
    private static _map: PluggableMap;
    private static getMap: () => PluggableMap | undefined;
    private static get map() {
        return this._map ?? (this._map = this.getMap()!) ?? this._map;
    }
    private static container: HTMLDivElement;
    // private static reactRoot?: Root;
    private static groupedLayers: IGroupedLayers;
    private static matchSet?: Set<number | string>;
    private static searchSourceArray: ISearchSource[] = [];
    private static searchSourceCollection: ISearchSourceCollection = {
        DEL: {},
        ENH: {},
        BYG: {},
        LOK: {},
        PIN: {},
        POLY: {},
    };
    // private static snackbar = o_getSnackbar();
    private static snackbar = getSnackbar();

    /**
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param controlHandler Controlhandler for subscribing to map events
     * @param iLayers The layers to search for features
     */
    constructor(controlHandler: ControlHandler, iLayers: ILayers, ctrl: IMapControl) {
        const container = document.createElement('div');
        container.style.top = controlHandler.getAssignedHeight(45) + 'px';
        container.id = `ol-search-bar-outer-container-${Date.now()}`;
        super(controlHandler, {}, { element: container });
        SFC.ctrl = ctrl;

        // SFC.getMap = this.getMap.bind(this);
        SFC.getMap = () => this.getMap() ?? undefined;

        SFC.container = container;
        SFC.groupedLayers = getGroupedLayers(iLayers);
        SFC.renderGui();
    }

    /**
     * Render or Rerender the ui with current props
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     */
    private static renderGui() {
        if (this.ctrl.noGui) return;

        // Clear all children
        this.container.textContent = '';

        // Append and render new child
        const div = document.createElement('div');
        this.container.appendChild(div);
        const root = createRoot(div);
        root.render(
            <Gui
                onEnterKey={this.handleEnterKey.bind(this)}
                onSearchStringChange={this.handleSearchStringChange.bind(this)}
                onSelectionChange={this.handleSelectionChange.bind(this)}
                source={this.searchSourceArray}
            />
        );
    }

    /**
     * Function to set the search source for the layers features
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param key key for the layers search source. Is usually the layers displayname, as that is already unique
     * @param type layers type
     * @param source Array of search source data
     */
    public static SetSearchSource(key: string, type: IMapLayer['type'], source: ISearchSource[]) {
        this.searchSourceCollection[type][key] = source;
        this.computeSearchSourceArray();
        this.renderGui();
    }

    /**
     * Reduce the searchSource collection to an array of searchSources
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @returns An ISearchSource array for autocomplete datasource
     */
    private static computeSearchSourceArray() {
        let prioritizedArray: ISearchSource[] = [];
        for (const key of ['PIN', 'DEL', 'LOK', 'BYG', 'ENH', 'POLY']) {
            const dict = this.searchSourceCollection[key as keyof typeof this.searchSourceCollection];
            for (const [, ss] of Object.entries(dict)) {
                prioritizedArray = prioritizedArray.concat(ss);
            }
        }
        this.searchSourceArray = prioritizedArray;
    }

    /**
     * Handle change in searchstring
     *
     * Fits zoom to matching features and changes feature style acording to the search
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param searchString The string to look for in search params
     */
    private static handleSearchStringChange(searchString: string) {
        if (UtilsString.IsNullOrWhitespace(searchString)) this.matchSet = undefined;
        else this.searchFeatures(searchString);
        this.repaintFeatures();
    }

    /**
     * Zoom to feature on selection change
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param selection Selected search source
     * @returns true if any match was found, otherwise false
     */
    private static handleSelectionChange(selection: ISearchSource | null) {
        if (selection == null) return false;
        const feature = this.getFeatureFromId(selection.featureId);

        if (feature == null) {
            this.snackbar.enqueueSnackbar('Undskyld 😞 Resultatet blev ikke fundet', { variant: 'error' });
            return false;
        }

        changeFloor(feature.get(FeatureProperty.Data)?.floor);

        ClickableFeature.ZoomFeatures([feature], { animate: false });
        return true;
    }

    /**
     * Zoom to matching features on search
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @returns true if any match was found, otherwise false
     */
    private static handleEnterKey() {
        if (this.matchSet == null) return false;
        const features = this.getFeaturesFromIds(Array.from(this.matchSet)).filter((f) => f != null);

        if (features.length === 0) {
            this.snackbar.enqueueSnackbar('Søgningen gav ingen resultater 😞', { variant: 'info' });
            return false;
        }

        ClickableFeature.ZoomFeatures(features, { animate: false });
        return true;
    }

    /**
     * Repaint all features to update style and clustering after changes.
     *
     * Use this instead of layer.prototype.changed(), since changed doesn't
     * trigger an update of clusters
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     */
    public static repaintFeatures() {
        const view = this.map.getView();
        const zoom = view.getZoom();
        if (zoom === undefined) return;
        view.setZoom(zoom - 0.00000000001);
    }

    /**
     * Searches through all layers to find the feature with the given id
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param id The features id
     * @returns The matching feature
     */
    private static getFeatureFromId(id: number | string): UnionFeature | undefined {
        return this.getFeaturesFromIds([id])[0];
    }

    /**
     * Searches through all layers to find the features with the given id
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param ids an array of ids
     * @returns The matching features
     */
    private static getFeaturesFromIds(ids: (number | string)[]): UnionFeature[] {
        let missing = [...ids];
        const found: UnionFeature[] = [];

        for (const key of Object.keys(this.groupedLayers) as (keyof typeof this.groupedLayers)[]) {
            const layers = this.groupedLayers[key];

            for (const layer of layers) {
                const newlyFound: (number | string)[] = [];
                let source = layer.getSource();

                if (source == null) continue;

                if (layer.options.isCluster) source = (source as Cluster).getSource();

                for (const id of missing) {
                    const feature: Feature<Geometry> | null = source!.getFeatureById(id);
                    if (feature == null) continue;

                    newlyFound.push(id);
                    found.push(feature);
                }

                missing = missing.filter((id) => !newlyFound.includes(id));
            }
        }
        return found;
    }

    /**
     * Function to reduce boilerplate when calling a function on each feature in an array of layers
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param layers An array of layers to extract features from
     * @param callback The callback to call with each feature extracted from layers
     */
    private static forEachFeatureInLayers(
        layers: IGroupedLayers[keyof IGroupedLayers],
        callback: (feature: Feature<Geometry>, layer: CTLayer) => void
    ) {
        for (let i = 0; i < layers.length; i++) {
            const clustersOrFeatures = layers[i].getSource()?.getFeatures() ?? [];
            for (let j = 0; j < clustersOrFeatures.length; j++) {
                const clusterOrFeature = clustersOrFeatures[j];
                const features: Feature[] = clusterOrFeature.get('features') ?? [clusterOrFeature];
                for (let k = 0; k < features.length; k++) {
                    const feature = features[k];
                    callback(feature, layers[i]);
                }
            }
        }
    }

    /**
     * Finds ids of all features matching the search
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     */
    private static searchFeatures(searchString: string) {
        const matchList: (number | string)[] = [];
        const parentsSet: Set<number | string> = new Set();
        const regex = new RegExp(searchString, 'i');

        /**     ----------------------------------------------------------------------------------------------------------------
         * |    Multiple methods have been tried and timed.                          |       Miliseconds to search in dummy data    |
         * |    ----------------------------------------------------------------------------------------------------------------    |
         * |    Regex                                                                |       50 (30 - 75)                           |
         * |    s.toLowerCase().indexOf(searchString.toLocaleLowerCase()) > -1       |       140                                    |
         * |    s.toLowerCase().includes(searchString.toLowerCase())                 |       63                                     |
         *      ----------------------------------------------------------------------------------------------------------------
         */
        const compare = (s: string) => {
            return regex.test(s);
        };

        for (const search of this.searchSourceArray) {
            // If neither parent nor match
            if (!parentsSet.has(search.featureId) && !compare(search.searchstring)) continue;

            matchList.push(search.featureId);
            search.parents?.enhId !== undefined && parentsSet.add(search.parents?.enhId);
            search.parents?.bygId !== undefined && parentsSet.add(search.parents?.bygId);
            search.parents?.lokId !== undefined && parentsSet.add(search.parents?.lokId);
            search.parents?.delId !== undefined && parentsSet.add(search.parents?.delId);
        }

        console.log('matchList:', matchList);
        this.matchSet = new Set(matchList);
    }

    /**
     * Find out how many features are matched by the current search criteria
     *
     * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
     * @param feature A feature object. Can be either a cluster feature or just a regular one.
     * @returns
     * [isMatch, matchCount?, matchedFeatures]
     *
     * If no search was performed, isMatch is true, matchCount? is undefined and matchedFeatures is all features
     */
    public static matchingFeatures(feature: UnionFeature): [boolean, number | undefined, UnionFeature[]] {
        const features: UnionFeature[] = feature.get('features') ?? [feature];

        if (this.matchSet === undefined) return [true, undefined, features];

        const matches: UnionFeature[] = [];

        for (const feat of features) {
            const id = feat.getId();
            if (id == null) continue;

            if (this.matchSet!.has(id)) matches.push(feat);
        }

        return [matches.length > 0, matches.length, matches];
    }

    /**************************************** External functions made for PowerBI hooks ****************************************/
    private static zoomMatchset(options: IZoomOptions = {}) {
        options.maxZoom ??= 14.5;
        const features = this.matchSet && this.getFeaturesFromIds(Array.from(this.matchSet));
        features && ClickableFeature.ZoomFeatures(features, options);
        return this.matchSet?.size ?? 0;
    }

    public static zoomIds(ids: number[], options: IZoomOptions = {}) {
        options.maxZoom ??= 14.5;
        const features = this.getFeaturesFromIds(ids);
        ClickableFeature.ZoomFeatures(features, options);
        return features.length;
    }

    public static search(query: string, options: IZoomOptions): number {
        this.handleSearchStringChange(query);
        return this.zoomMatchset(options);
    }
}
export const SFC = SearchFeaturesControl;

const Gui = (props: GuiProps) => {
    return (
        <AppProviders>
            <Content {...props} />
        </AppProviders>
    );
};

/**
 * Groups the layers by type
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @param iLayers the iLayers object to split up
 * @returns an object with grouped layers
 */
const getGroupedLayers = (iLayers: ILayers): IGroupedLayers => {
    const groupedLayers: IGroupedLayers = {
        enh: [],
        byg: [],
        lok: [],
        del: [],
        pin: [],
        pol: [],
    };

    for (const key of Object.keys(iLayers)) {
        groupedLayers[key.substring(0, 3).toLowerCase() as keyof IGroupedLayers]?.push(iLayers[key].layer as never);
    }

    return groupedLayers;
};
