import { GeoJSON, KML, WKT } from 'ol/format';

import { Feature } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';

//#region Export

export const wktFormat = new WKT();

export function getKMLFromFeatures(features: Feature[]) {
    const format = new KML();
    const kml = format.writeFeatures(features, { featureProjection: 'EPSG:25832' });
    return kml;
}
export function getWKTFromFeatures(features: Feature[]) {
    const format = new WKT();
    const wkt = format.writeFeatures(features, { featureProjection: 'EPSG:25832' });
    return wkt;
}
export function getGeoJSONFromFeatures(features: Feature[]) {
    const format = new GeoJSON();
    const geoJSON = format.writeFeatures(features, { featureProjection: 'EPSG:25832' });
    return geoJSON;
}
export function getFeaturesFromLayer(layer: VectorLayer<VectorSource>) {
    const source = layer.getSource();
    const features = source?.getFeatures() ?? [];
    return features;
}

export function exportWkt(layer: VectorLayer<VectorSource>) {
    const wktStrings: string[] = [];
    const features = getFeaturesFromLayer(layer);
    features.forEach((f) => {
        wktStrings.push(wktFormat.writeFeature(f));
    });
    return wktStrings.join('\n');
}

//#endregion Export

//#region Import

export function addWktFromString(layer: VectorLayer<VectorSource>, wkt: string) {
    const wktlist = wkt.split('\n');
    wktlist.forEach((f) => {
        const feature = wktFormat.readFeature(f, {
            dataProjection: 'EPSG:25832',
            featureProjection: 'EPSG:25832',
        });
        layer.getSource()?.addFeature(feature);
    });
}

export function importWktFromUrl(url: string, layer: VectorLayer<VectorSource>) {
    // eslint-disable-next-line no-restricted-syntax
    fetch(url)
        .then((response) => response.text())
        .then((wkt) => addWktFromString(layer, wkt));
}

//#endregion Import
