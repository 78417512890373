class UtilsArray<T> extends Array<T> {
    public static isUnique(arr: unknown[] | undefined | null) {
        return arr == null || arr.length === new Set(arr).size;
    }
}

/**
 * Length should be set to the length of the array
 */
export type FixedArray<Length extends number, T = unknown> = [T, ...T[]] & { length: Length };

export default UtilsArray;
