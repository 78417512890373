
    const SvgXx_06_13 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="338.84 223.78 193.63000000000005 119.51000000000002" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M527.47 228.78L502.43 237.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M502.43 237.13L519.12 253.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M519.12 253.82L527.47 228.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M527.47 228.78L527.47 228.78 519.12 253.82 502.43 237.13 527.47 228.78Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M527.47 228.78L427.3 328.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M510.77 245.47L485.73 253.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M485.73 253.82L502.43 270.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M502.43 270.51L510.77 245.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M510.77 245.47L510.77 245.47 502.43 270.51 485.73 253.82 510.77 245.47Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.86 328.94L418.24 331.6 427.3 328.94 427.3 328.94 417.86 328.94Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M418.24 331.6L419.36 334.05 427.3 328.94 427.3 328.94 418.24 331.6Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M419.36 334.05L421.12 336.08 427.3 328.94 427.3 328.94 419.36 334.05Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M421.12 336.08L423.38 337.53 427.3 328.94 427.3 328.94 421.12 336.08Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M423.38 337.53L425.96 338.29 427.3 328.94 427.3 328.94 423.38 337.53Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M425.96 338.29L428.65 338.29 427.3 328.94 427.3 328.94 425.96 338.29Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M428.65 338.29L431.23 337.53 427.3 328.94 427.3 328.94 428.65 338.29Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M431.23 337.53L433.49 336.08 427.3 328.94 427.3 328.94 431.23 337.53Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M433.49 336.08L435.25 334.05 427.3 328.94 427.3 328.94 433.49 336.08Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M435.25 334.05L436.37 331.6 427.3 328.94 427.3 328.94 435.25 334.05Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M436.37 331.6L436.75 328.94 427.3 328.94 427.3 328.94 436.37 331.6Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M436.75 328.94L436.37 326.28 427.3 328.94 427.3 328.94 436.75 328.94Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M436.37 326.28L435.25 323.83 427.3 328.94 427.3 328.94 436.37 326.28Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M435.25 323.83L433.49 321.8 427.3 328.94 427.3 328.94 435.25 323.83Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M433.49 321.8L431.23 320.35 427.3 328.94 427.3 328.94 433.49 321.8Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M431.23 320.35L428.65 319.59 427.3 328.94 427.3 328.94 431.23 320.35Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M428.65 319.59L425.96 319.59 427.3 328.94 427.3 328.94 428.65 319.59Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M425.96 319.59L423.38 320.35 427.3 328.94 427.3 328.94 425.96 319.59Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M423.38 320.35L421.12 321.8 427.3 328.94 427.3 328.94 423.38 320.35Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M421.12 321.8L419.36 323.83 427.3 328.94 427.3 328.94 421.12 321.8Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M419.36 323.83L418.24 326.28 427.3 328.94 427.3 328.94 419.36 323.83Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M418.24 326.28L417.86 328.94 427.3 328.94 427.3 328.94 418.24 326.28Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M343.84 245.47L368.88 253.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.88 253.82L352.18 270.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.18 270.51L343.84 245.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.84 245.47L343.84 245.47 368.88 253.82 352.18 270.51 343.84 245.47Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M343.84 245.47L427.3 328.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_06_13;

    