import Tooltip, { TooltipProps } from '@mui/material/Tooltip/Tooltip';

import { styled } from '@mui/material/styles';
import tooltipClasses from '@mui/material/Tooltip/tooltipClasses';

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));
