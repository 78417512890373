
    const Svg1_01_30 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="325.29 230.35 155.40999999999997 155.41" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M330.29 322.6L330.29 380.76Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.29 380.76L475.7 380.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M475.7 380.76L475.7 235.35Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M475.7 235.35L330.29 235.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.29 235.35L330.29 322.6Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M335.3 344.41L335.3 271.7Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M335.3 271.7L380.31 271.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M335.3 306.33L363 306.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.62 344.41L397.62 271.7Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.62 271.7L421.85 271.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.85 271.7L432.24 275.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.24 275.17L439.16 282.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.16 282.09L442.63 289.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.63 289.02L446.09 299.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.09 299.4L446.09 316.71Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.09 316.71L442.63 327.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.63 327.1L439.16 334.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.16 334.02L432.24 340.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.24 340.95L421.85 344.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.85 344.41L397.62 344.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_30;

    