export interface ToolbarProps {}

export enum Panels {
    None = 'ct-toolbar-panel-none',
    Insert = 'ct-toolbar-panel-insert',
    DxColumnChooser = 'columnChooserButton',
    DxGroup = 'groupPanel',
    DxSearch = 'searchPanel',
    DxExport = 'exportButton',
}
