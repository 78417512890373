
    const Svg3_07_05 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="292.16 204.08 268.46999999999997 182.31999999999996" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M297.16 381.4L555.63 381.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M555.63 381.4L555.63 209.08Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M555.63 209.08L297.16 209.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M297.16 209.08L297.16 381.4Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.16 283.3L350.16 231.6Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.16 231.6L367.39 231.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.39 231.6L374.77 234.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.77 234.06L379.7 238.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.7 238.99L382.16 243.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.16 243.91L384.62 251.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.62 251.3L384.62 263.6Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.62 263.6L382.16 270.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.16 270.99L379.7 275.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.7 275.91L374.77 280.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.77 280.84L367.39 283.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.39 283.3L350.16 283.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.85 231.6L421.55 283.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.55 283.3L441.24 231.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.08 283.3L451.08 231.6Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.08 231.6L473.24 231.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M473.24 231.6L480.62 234.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M480.62 234.06L483.09 236.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M483.09 236.53L485.55 241.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M485.55 241.45L485.55 246.37Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M485.55 246.37L483.09 251.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M483.09 251.3L480.62 253.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M480.62 253.76L473.24 256.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M473.24 256.22L451.08 256.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M468.32 256.22L485.55 283.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.16 359.8L350.16 308.1Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.16 308.1L384.62 359.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.62 359.8L384.62 308.1Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.31 308.1L424.01 359.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.01 359.8L443.7 308.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M453.55 359.8L453.55 308.1Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M453.55 308.1L475.7 308.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M475.7 308.1L483.09 310.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M483.09 310.56L485.55 313.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M485.55 313.03L488.01 317.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.01 317.95L488.01 322.87Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.01 322.87L485.55 327.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M485.55 327.8L483.09 330.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M483.09 330.26L475.7 332.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M475.7 332.72L453.55 332.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.78 332.72L488.01 359.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg3_07_05;

    