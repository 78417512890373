
    const Svg1_05_14 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="264.9 202.58 318.80000000000007 241.6" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M269.9 439.18L578.7 439.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M578.7 207.58L269.9 207.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M269.9 207.58L269.9 439.18Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M578.7 439.18L578.7 207.58Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M333.06 369.7L333.06 292.5Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M333.06 292.5L362.47 369.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.47 369.7L391.88 292.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.88 292.5L391.88 369.7Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.18 331.1L454.98 336.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.98 336.61L454.39 342.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.39 342.08L453.41 347.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M453.41 347.51L452.05 352.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.05 352.85L450.31 358.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.31 358.08L448.2 363.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M448.2 363.17L445.74 368.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M445.74 368.1L442.92 372.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.92 372.83L439.78 377.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.78 377.36L436.32 381.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.32 381.65L432.57 385.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.57 385.69L428.53 389.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.53 389.44L424.24 392.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.24 392.9L419.72 396.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.72 396.04L414.98 398.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.98 398.85L410.05 401.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.05 401.32L404.96 403.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.96 403.43L399.73 405.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.73 405.17L394.39 406.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.39 406.53L388.97 407.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.97 407.51L383.49 408.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.49 408.1L377.98 408.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.98 408.3L372.47 408.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.47 408.1L366.99 407.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.99 407.51L361.57 406.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.57 406.53L356.23 405.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.23 405.17L351 403.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M351 403.43L345.91 401.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.91 401.32L340.98 398.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.98 398.85L336.24 396.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.24 396.04L331.72 392.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M331.72 392.9L327.43 389.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M327.43 389.44L323.39 385.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.39 385.69L319.64 381.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319.64 381.65L316.18 377.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M316.18 377.36L313.04 372.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M313.04 372.83L310.23 368.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.23 368.1L307.76 363.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M307.76 363.17L305.65 358.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M305.65 358.08L303.91 352.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M303.91 352.85L302.55 347.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M302.55 347.51L301.57 342.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M301.57 342.08L300.98 336.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M300.98 336.61L300.78 331.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M300.78 331.1L300.98 325.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M300.98 325.59L301.57 320.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M301.57 320.11L302.55 314.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M302.55 314.69L303.91 309.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M303.91 309.35L305.65 304.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M305.65 304.12L307.76 299.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M307.76 299.03L310.23 294.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.23 294.1L313.04 289.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M313.04 289.36L316.18 284.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M316.18 284.83L319.64 280.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319.64 280.54L323.39 276.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.39 276.51L327.43 272.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M327.43 272.76L331.72 269.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M331.72 269.3L336.24 266.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.24 266.15L340.98 263.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.98 263.34L345.91 260.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.91 260.88L351 258.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M351 258.77L356.23 257.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.23 257.03L361.57 255.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.57 255.66L366.99 254.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.99 254.69L372.47 254.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.47 254.1L377.98 253.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.98 253.9L383.49 254.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.49 254.1L388.97 254.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.97 254.69L394.39 255.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.39 255.66L399.73 257.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.73 257.03L404.96 258.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.96 258.77L410.05 260.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.05 260.88L414.98 263.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.98 263.34L419.72 266.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.72 266.15L424.24 269.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.24 269.3L428.53 272.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.53 272.76L432.57 276.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.57 276.51L436.32 280.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.32 280.54L439.78 284.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.78 284.83L442.92 289.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.92 289.36L445.74 294.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M445.74 294.1L448.2 299.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M448.2 299.03L450.31 304.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.31 304.12L452.05 309.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.05 309.35L453.41 314.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M453.41 314.69L454.39 320.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.39 320.11L454.98 325.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.98 325.59L455.18 331.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M517 303.53L509.64 296.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M509.64 296.17L498.62 292.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M498.62 292.5L483.91 292.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M483.91 292.5L472.88 296.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M472.88 296.17L465.53 303.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M465.53 303.53L465.53 310.88Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M465.53 310.88L469.21 318.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M469.21 318.23L472.88 321.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M472.88 321.91L480.24 325.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M480.24 325.58L502.29 332.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M502.29 332.94L509.64 336.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M509.64 336.61L513.32 340.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M513.32 340.29L517 347.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M517 347.64L517 358.67Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M517 358.67L509.64 366.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M509.64 366.02L498.62 369.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M498.62 369.7L483.91 369.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M483.91 369.7L472.88 366.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M472.88 366.02L465.53 358.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_05_14;

    