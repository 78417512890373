
    const Svg2_07_03 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="195.89 253.45999999999998 474.26 94.15000000000003" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M200.89 326.2L218.3 326.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M229.9 326.2L253.12 326.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M264.72 326.2L287.94 326.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M299.54 326.2L322.76 326.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M334.36 326.2L357.58 326.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.18 326.2L392.4 326.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404 326.2L427.22 326.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438.82 326.2L462.04 326.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M473.64 326.2L496.86 326.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M508.46 326.2L531.68 326.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M543.28 326.2L566.5 326.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M578.1 326.2L601.32 326.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M612.92 326.2L636.14 326.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M647.74 326.2L665.15 326.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.23 304.88L393.92 258.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.92 258.46L411.61 304.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.87 289.41L404.97 289.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.82 265.09L451.4 260.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.4 260.67L444.77 258.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.77 258.46L435.93 258.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.93 258.46L429.29 260.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.29 260.67L424.87 265.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.87 265.09L424.87 269.51Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.87 269.51L427.08 273.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.08 273.93L429.29 276.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.29 276.14L433.71 278.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.71 278.35L446.98 282.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.98 282.78L451.4 284.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.4 284.99L453.61 287.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M453.61 287.2L455.82 291.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.82 291.62L455.82 298.25Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.82 298.25L451.4 302.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.4 302.67L444.77 304.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.77 304.88L435.93 304.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.93 304.88L429.29 302.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.29 302.67L424.87 298.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M469.09 304.88L469.09 258.46Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M469.09 258.46L488.98 258.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.98 258.46L495.62 260.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M495.62 260.67L497.83 262.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M497.83 262.88L500.04 267.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M500.04 267.3L500.04 273.93Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M500.04 273.93L497.83 278.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M497.83 278.35L495.62 280.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M495.62 280.57L488.98 282.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.98 282.78L469.09 282.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.8 309.78L317.97 342.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_07_03;

    