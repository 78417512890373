import DateFnsUtils from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { MathJaxContext } from 'better-react-mathjax';
import { Provider } from 'react-redux';
import { ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import da from 'date-fns/locale/da';
import defaultTheme from './shared/utils/theme';
import getStore from './shared/store';

export interface AppProvidersProps {
    children: ReactNode | ReactNode[];
}

const AppProviders = (props: AppProvidersProps) => (
    <>
        {/* Redux store */}
        <Provider store={getStore()}>
            <MathJaxContext>
                {/* Mui localization and theme */}
                <LocalizationProvider dateAdapter={DateFnsUtils} locale={da}>
                    <ThemeProvider theme={defaultTheme}>
                        {/* Global snackbars */}
                        <SnackbarProvider
                            autoHideDuration={3000}
                            maxSnack={2}
                            variant={'info'}
                            className='ct-snackbar-provider'
                        >
                            {props.children}
                        </SnackbarProvider>
                    </ThemeProvider>
                </LocalizationProvider>
            </MathJaxContext>
        </Provider>
    </>
);

export default AppProviders;
