
    const Svg2_04_10 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="311.36 261.73 191.21999999999997 100.60999999999996" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M316.36 266.73L316.36 357.34Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M316.36 357.34L497.58 357.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M497.58 357.34L497.58 266.73Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M497.58 266.73L316.36 266.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.14 334.68L357.4 289.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M357.4 289.38L374.66 334.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.61 319.58L368.19 319.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.96 300.17L417.81 295.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.81 295.85L413.49 291.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.49 291.54L409.18 289.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.18 289.38L400.55 289.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.55 289.38L396.23 291.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.23 291.54L391.92 295.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.92 295.85L389.76 300.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.76 300.17L387.6 306.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.6 306.64L387.6 317.42Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.6 317.42L389.76 323.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.76 323.9L391.92 328.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.92 328.21L396.23 332.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.23 332.53L400.55 334.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.55 334.68L409.18 334.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.18 334.68L413.49 332.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.49 332.53L417.81 328.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.81 328.21L419.96 323.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.96 323.9L419.96 317.42Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.96 317.42L409.18 317.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.91 334.68L450.17 289.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.17 289.38L467.43 334.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.38 319.58L460.95 319.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_04_10;

    