import { Children } from '../../shared/utils/props';
import Grid from '@mui/material/Grid/Grid';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography/Typography';
import { WideButton } from '../../components/mui/styled-mui';
import useHistoryNavigate from '../../shared/hooks/extended-hooks/use-history-navigate';

const Item = ({ children }: Children) => (
    <Grid item xs={true}>
        {children}
    </Grid>
);

const Http404 = () => {
    const { navigate, goBack } = useHistoryNavigate();
    console.log(window.location.pathname + window.location.search);
    return (
        <Stack spacing={2} sx={{ ml: 2, mr: 2, height: '100%', pb: 4 }}>
            <Typography variant='h1' fontFamily='"StatusHeader"'>
                404
            </Typography>
            <Typography variant='h6'>Denne side eksisterer desværre ikke</Typography>
            <Grid container spacing={2} sx={{ pr: 4 }}>
                <Item>
                    <WideButton sx={{ maxWidth: '300px', float: 'right' }} onClick={() => goBack()}>
                        Tilbage
                    </WideButton>
                </Item>
                <Item>
                    <WideButton sx={{ maxWidth: '300px', float: 'left' }} onClick={() => navigate('/')}>
                        Til Forsiden
                    </WideButton>
                </Item>
            </Grid>
        </Stack>
    );
};

export default Http404;
