import { IInput, ItemTypes } from '../interfaces';
import { generateProps, generateRules } from './generate';

import { SimpleItem } from 'devextreme-react/form';
import { defaultIInputs } from '../defaults';

const InputString = (input: IInput) => {
    return (
        <SimpleItem {...generateProps(input, defaultIInputs[ItemTypes.String])}>
            {generateRules(input, defaultIInputs[ItemTypes.String])}
        </SimpleItem>
    );
};

export default InputString;
