import { useEffect, useState } from 'react';

const useSeconds = (initialSeconds: number) => {
    const [seconds, setSeconds] = useState(Math.floor(initialSeconds));
    const [isPaused, setIsPaused] = useState(true);

    useEffect(() => {
        let isMounted = true;
        if (!isPaused)
            setTimeout(() => {
                if (!isPaused && seconds > 0) {
                    if (isMounted) setSeconds((s) => s - 1);
                }
            }, 1000);
        return () => {
            isMounted = false;
        };
    }, [seconds, isPaused]);

    const start = () => {
        setIsPaused(false);
    };

    const reset = () => {
        setSeconds(Math.floor(initialSeconds));
        setIsPaused(true);
    };

    const pause = () => {
        setIsPaused(true);
    };

    return [seconds, start, reset, pause, isPaused] as [number, () => void, () => void, () => void, boolean];
};

export default useSeconds;
