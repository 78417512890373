
    const Svg1_01_22 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="282.23 227.13 172.06 156.89999999999998" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M449.29 379.03L449.29 286.43Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 333.53 355.88 A 84.117 84.117 0 0 0 333.53 263.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M287.23 309.58L342.79 332.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M287.23 309.58L342.79 286.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.38 314.99L370.41 321.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.44 328.89L398.48 338.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.51 345.09L426.55 354.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.94 292.63L381.98 301.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.01 308.84L410.05 318.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.08 325.04L438.12 334.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M342.79 332.73L358.83 341.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.86 348.93L386.9 358.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.93 365.14L414.97 374.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.48 269.18L391.18 232.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.48 232.13L391.18 269.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.76 269.18L426.46 232.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.76 232.13L426.46 269.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_22;

    