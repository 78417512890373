
    const Svg2_04_03 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="310.55 231.77 215.17000000000002 112.58000000000001" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M315.55 236.77L315.55 339.35Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M315.55 339.35L520.72 339.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M520.72 339.35L520.72 236.77Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M520.72 236.77L315.55 236.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.5 313.71L366.04 262.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.04 262.41L385.58 313.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.83 296.61L378.25 296.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.23 262.41L419.77 313.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.77 313.71L439.31 262.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M483.28 269.74L478.39 264.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.39 264.86L471.07 262.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M471.07 262.41L461.3 262.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.3 262.41L453.97 264.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M453.97 264.86L449.08 269.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.08 269.74L449.08 274.63Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.08 274.63L451.53 279.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.53 279.51L453.97 281.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M453.97 281.95L458.85 284.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.85 284.4L473.51 289.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M473.51 289.28L478.39 291.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.39 291.72L480.84 294.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M480.84 294.17L483.28 299.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M483.28 299.05L483.28 306.38Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M483.28 306.38L478.39 311.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.39 311.26L471.07 313.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M471.07 313.71L461.3 313.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.3 313.71L453.97 311.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M453.97 311.26L449.08 306.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_04_03;

    