
    const Svg2_03_06 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="289.65 201.26 174.17000000000002 207.0" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M458.82 403.26L360.31 337.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.82 206.26L458.82 403.26Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.31 271.92L458.82 206.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.31 271.92L294.65 271.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.31 337.59L360.31 271.92Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M294.65 337.59L360.31 337.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M294.65 271.92L294.65 337.59Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_03_06;

    