
    const Svg4_05_09 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="255.88 210.19 297.61 153.12" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M260.88 358.31L404 358.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404 358.31L404 215.19Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404 215.19L260.88 215.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M260.88 215.19L260.88 358.31Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.97 286.75L383.77 291.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.77 291.24L383.18 295.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.18 295.7L382.21 300.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.21 300.08L380.86 304.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.86 304.37L379.14 308.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.14 308.52L377.06 312.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.06 312.51L374.65 316.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.65 316.3L371.91 319.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.91 319.87L368.88 323.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.88 323.18L365.56 326.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.56 326.22L362 328.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362 328.96L358.2 331.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.2 331.37L354.22 333.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.22 333.45L350.06 335.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.06 335.17L345.78 336.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.78 336.52L341.39 337.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.39 337.49L336.93 338.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.93 338.08L332.44 338.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M332.44 338.27L327.95 338.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M327.95 338.08L323.49 337.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.49 337.49L319.11 336.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319.11 336.52L314.82 335.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M314.82 335.17L310.67 333.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.67 333.45L306.68 331.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M306.68 331.37L302.89 328.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M302.89 328.96L299.32 326.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M299.32 326.22L296.01 323.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M296.01 323.18L292.97 319.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M292.97 319.87L290.23 316.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M290.23 316.3L287.82 312.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M287.82 312.51L285.74 308.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M285.74 308.52L284.02 304.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M284.02 304.37L282.67 300.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M282.67 300.08L281.7 295.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M281.7 295.7L281.11 291.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M281.11 291.24L280.92 286.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M280.92 286.75L281.11 282.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M281.11 282.26L281.7 277.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M281.7 277.8L282.67 273.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M282.67 273.41L284.02 269.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M284.02 269.13L285.74 264.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M285.74 264.97L287.82 260.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M287.82 260.99L290.23 257.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M290.23 257.19L292.97 253.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M292.97 253.63L296.01 250.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M296.01 250.31L299.32 247.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M299.32 247.28L302.89 244.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M302.89 244.54L306.68 242.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M306.68 242.13L310.67 240.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.67 240.05L314.82 238.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M314.82 238.33L319.11 236.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319.11 236.98L323.49 236.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.49 236.01L327.95 235.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M327.95 235.42L332.44 235.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M332.44 235.22L336.93 235.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.93 235.42L341.39 236.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.39 236.01L345.78 236.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.78 236.98L350.06 238.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.06 238.33L354.22 240.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.22 240.05L358.2 242.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.2 242.13L362 244.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362 244.54L365.56 247.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.56 247.28L368.88 250.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.88 250.31L371.91 253.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.91 253.63L374.65 257.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.65 257.19L377.06 260.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.06 260.99L379.14 264.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.14 264.97L380.86 269.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.86 269.13L382.21 273.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.21 273.41L383.18 277.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.18 277.8L383.77 282.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.77 282.26L383.97 286.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M296.01 323.18L368.88 250.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M296.01 250.31L368.88 323.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.63 358.31L480.34 286.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.63 286.75L480.34 358.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M500.78 358.31L548.49 286.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M500.78 286.75L548.49 358.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg4_05_09;

    