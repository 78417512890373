import { Divider, Menu, MenuItem, Stack, Tooltip } from '@mui/material';
import BoltIcon from '@mui/icons-material/Bolt';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import ListAltIcon from '@mui/icons-material/ListAlt';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import BugReportIcon from '@mui/icons-material/BugReport';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import BarChartIcon from '@mui/icons-material/BarChart';
import DescriptionIcon from '@mui/icons-material/Description';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import { ReactElement, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useEnhedId } from '../../shared/hooks/use-enhed-id';
import { useCTEffect } from '../../shared/hooks/use-ct';
import { CenterCenterBox } from '../../components/mui/styled-mui';

const getIconStyle = (isActive: boolean, isTransitioning: boolean, label: string, to: string) => {
    const isBackButton = label === 'Tilbage';
    const isForsyning = window.location.pathname.includes('Forsyning') && label === 'Forsyning';
    isActive = isActive || isForsyning;
    return {
        backgroundColor: isActive && !isBackButton ? '#cccccc' : 'white',
        color: isActive && !isBackButton ? '#1976d2' : '#707070',
        viewTransitionName: isTransitioning ? 'slide' : '',
        height: '40px',
        minWidth: '40px',
        padding: '4px',
    };
};

interface IconButtonProps {
    label: string;
    icon: ReactElement;
    onClick?: (e: any) => void;
}

interface NavLinkIconButtonProps extends IconButtonProps {
    to: string;
    divider?: boolean;
}

const IconButton = ({ label, icon, onClick }: IconButtonProps) => {
    return (
        <CenterCenterBox onClick={onClick}>
            <Tooltip title={label} placement='bottom-start'>
                {icon}
                {/* <Button
                    // label={label}
                    icon={icon}
                    onClick={onClick}
                    sx={{ paddingX: '4px', minWidth: '40px' }}
                    value={to}
                    /> */}
            </Tooltip>
        </CenterCenterBox>
    );
};

const NavLinkIconButton = ({ label, icon, to, divider = true }: NavLinkIconButtonProps) => {
    return (
        <>
            {label !== 'Tilbage' && divider && <Divider orientation='vertical' flexItem style={{ height: '30px' }} />}
            <NavLink
                to={to}
                style={({ isActive, isTransitioning }) => {
                    return getIconStyle(isActive, isTransitioning, label, to);
                }}
            >
                <IconButton label={label} icon={icon} />
            </NavLink>
        </>
    );
};

const ExpandableIconButton = ({
    parent,
    childComponents,
}: {
    parent: IconButtonProps;
    childComponents: NavLinkIconButtonProps[];
}) => {
    const [expanded, setExpanded] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setExpanded((prev) => !prev);
    };
    const handleClose = () => {
        setExpanded(false);
    };
    return (
        <>
            {parent.label !== 'Tilbage' && <Divider orientation='vertical' flexItem style={{ height: '30px' }} />}
            <div style={getIconStyle(expanded, false, parent.label, '/')}>
                <IconButton onClick={handleClick} {...parent} />
            </div>
            <Menu open={expanded} anchorEl={anchorEl} onClose={handleClose}>
                <MenuItem onClick={handleClose} style={{ backgroundColor: 'white' }}>
                    {childComponents.map(({ label, icon, to }, index) => {
                        return (
                            <NavLinkIconButton key={label} to={to} icon={icon} label={label} divider={index !== 0} />
                        );
                    })}
                </MenuItem>
            </Menu>
        </>
    );
};

const OverviewToolbar = () => {
    const context = 'Context'; // TODO: Get context from somewhere
    const enhId = useEnhedId();

    const getNewShowBackButton = () => {
        return (
            !(window.location.pathname.toLowerCase().split('/').length === 4) &&
            !window.location.pathname.toLowerCase().includes('drift')
        );
    };

    const [showBackButton, setShowBackButton] = useState(getNewShowBackButton());
    const params = useParams();
    useCTEffect(() => {
        setShowBackButton(getNewShowBackButton());
    }, [params]);

    const getBackUrl = () => {
        let path = window.location.pathname
            .toLowerCase()
            .split('/')
            .filter((x) => x !== '');
        if (path.length > 2) {
            path = path.slice(0, -1);
            if (path.includes('forsyning')) {
                path = path.slice(0, -1);
            }
        }
        return '/' + path.join('/') + window.location.search;
    };

    const getCurrUrl = () => {
        const path = window.location.pathname
            .toLowerCase()
            .split('/')
            .filter((x) => x !== '')
            .slice(1);
        return path.join('/') + window.location.search;
    };

    console.log(getCurrUrl());

    return (
        <>
            <CenterCenterBox
                sx={{
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    height: '45px',
                    width: '100%',
                    overflow: 'hidden',
                    paddingTop: '5px',
                }}
            >
                <Stack
                    direction='row'
                    spacing={0}
                    sx={{
                        overflowX: 'auto',
                        overflowY: 'hidden',
                        height: '57px',
                    }}
                >
                    {showBackButton && (
                        <NavLinkIconButton
                            label='Tilbage'
                            icon={<KeyboardBackspaceIcon fontSize='medium' />}
                            to={getBackUrl()}
                        />
                    )}
                    <NavLinkIconButton
                        label='Drift'
                        icon={<ListAltIcon fontSize='medium' />}
                        to={`/${enhId}/Overview/${context}/Drift${window.location.search}`}
                    />
                    <NavLinkIconButton
                        label='Syn'
                        icon={<AssignmentIcon fontSize='medium' />}
                        to={`/${enhId}/Overview/${context}/Syn${window.location.search}`}
                    />
                    <NavLinkIconButton
                        label='Bygningsdelskort'
                        icon={<MapsHomeWorkIcon fontSize='medium' />}
                        to={`/${enhId}/Overview/${context}/Bygningsdelskort${window.location.search}`}
                    />
                    <ExpandableIconButton
                        parent={{ label: 'Forsyning', icon: <BoltIcon fontSize='medium' /> }}
                        childComponents={[
                            {
                                label: 'Forbrug',
                                icon: <BarChartIcon fontSize='medium' />,
                                to: `/${enhId}/Overview/${context}/Forsyning/Graphs${window.location.search}`,
                            },
                            {
                                label: 'Stamdata',
                                icon: <DescriptionIcon fontSize='medium' />,
                                to: `/${enhId}/Overview/${context}/Forsyning/Stamdata${window.location.search}`,
                            },
                            {
                                label: 'Administration',
                                icon: <ContentPasteSearchIcon fontSize='medium' />,
                                to: `/${enhId}/Overview/${context}/Forsyning/ForsyningAdmin${window.location.search}`,
                            },
                        ]}
                    />
                    <NavLinkIconButton
                        label='Service'
                        icon={<HomeRepairServiceIcon fontSize='medium' />}
                        to={`/${enhId}/Overview/${context}/Service${window.location.search}`}
                    />
                    <NavLinkIconButton
                        label='Stamdata'
                        icon={<DocumentScannerIcon fontSize='medium' />}
                        to={`/${enhId}/Overview/${context}/Stamdata${window.location.search}`}
                    />
                    <NavLinkIconButton
                        label='Debug'
                        icon={<BugReportIcon fontSize='medium' />}
                        to={`Debug${window.location.search}`}
                    />
                </Stack>
            </CenterCenterBox>
        </>
    );
};

export default OverviewToolbar;

/*
<SmallTile
                        text='Drift'
                        icon={<ListAltIcon fontSize='medium' />}
                        to={`/${enhId}/Overview/${context}/Drift${window.location.search}`}
                        permissions={UserPermissions.Drift}
                    />
                    <SmallTile
                        text='Syn'
                        icon={<AssignmentIcon fontSize='medium' />}
                        to={`/${enhId}/Overview/${context}/Syn${window.location.search}`}
                        permissions={UserPermissions.Syn}
                    />
                    <SmallTile
                        text='Bygningsdelskort'
                        icon={<MapsHomeWorkIcon fontSize='medium' />}
                        to={`/${enhId}/Overview/${context}/Bygningsdelskort${window.location.search}`}
                        permissions={UserPermissions.Bygningsdelskort}
                    />
                    <SmallTile
                        text='Forsyning'
                        icon={<BoltIcon fontSize='medium' />}
                        to={`/${enhId}/Overview/${context}/Forsyning${window.location.search}`}
                        permissions={UserPermissions.Forsyning}
                    />
                    <SmallTile
                        text='Service'
                        icon={<HomeRepairServiceIcon fontSize='medium' />}
                        to={`/${enhId}/Overview/${context}/Service${window.location.search}`}
                        permissions={UserPermissions.Service}
                    />
                    <SmallTile
                        text='Stamdata'
                        icon={<DocumentScannerIcon fontSize='medium' />}
                        to={`/${enhId}/Overview/${context}/Stamdata${window.location.search}`}
                        permissions={UserPermissions.Overview}
                    />
                    <SmallTile
                        text='Debug'
                        icon={<BugReportIcon fontSize='medium' />}
                        to={`Debug${window.location.search}`}
                        permissions={UserPermissions.Admin}
                    />
                    */
