
    const Svg1_05_13 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="361.04 242.07 63.70999999999998 112.07" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M399.69 288.95L402.36 287.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.36 287.85L404.86 286.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.86 286.41L407.14 284.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.14 284.65L409.16 282.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.16 282.6L410.9 280.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.9 280.29L412.3 277.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.3 277.78L413.36 275.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.36 275.1L414.06 272.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.06 272.3L414.36 269.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.36 269.43L414.29 266.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.29 266.55L413.83 263.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.83 263.7L412.99 260.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.99 260.95L411.79 258.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.79 258.32L410.25 255.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.25 255.89L408.39 253.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.39 253.68L406.26 251.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.26 251.74L403.89 250.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.89 250.1L401.32 248.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.32 248.79L398.6 247.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.6 247.84L395.77 247.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.77 247.27L392.9 247.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.9 247.07L390.02 247.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.02 247.27L387.19 247.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.19 247.84L384.47 248.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.47 248.79L381.9 250.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.9 250.1L379.53 251.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.53 251.74L377.4 253.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.4 253.68L375.54 255.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.54 255.89L374 258.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374 258.32L372.8 260.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.8 260.95L371.97 263.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.97 263.7L371.5 266.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.5 266.55L371.43 269.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.43 269.43L371.74 272.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.74 272.3L372.43 275.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.43 275.1L373.49 277.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M373.49 277.78L374.9 280.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.9 280.29L376.63 282.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.63 282.6L378.65 284.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.65 284.65L380.94 286.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.94 286.41L383.43 287.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.43 287.85L386.1 288.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.1 288.95L366.04 349.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.04 349.14L419.75 349.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.75 349.14L399.69 288.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_05_13;

    