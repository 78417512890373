
    const Svg2_01_05 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="309.29 221.55 155.08999999999997 155.08999999999997" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M314.29 371.64L459.38 371.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.38 371.64L459.38 226.55Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.38 226.55L314.29 226.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M314.29 226.55L314.29 371.64Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.06 262.82L380.06 335.37Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.88 262.82L404.24 262.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_01_05;

    