
    const Svg1_07_03 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="286.03 227.16 240.73000000000002 125.35999999999999" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M291.03 347.52L521.76 347.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M521.76 347.52L521.76 232.16Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M521.76 232.16L291.03 232.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M291.03 232.16L291.03 347.52Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.45 318.68L363.43 261" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.43 261L385.4 318.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.69 299.46L377.16 299.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.88 318.68L401.88 261Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.85 318.68L445.83 261" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M445.83 261L467.8 318.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.1 299.46L459.56 299.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_07_03;

    