
    const SvgXx_07_06 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="286.11 226.61 262.12 105.63" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M291.11 231.61L543.23 231.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M543.23 231.61L543.23 327.24Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M543.23 327.24L291.11 327.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M291.11 327.24L291.11 231.61Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M315.93 301.15L315.93 257.69Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M315.93 257.69L332.49 301.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M332.49 301.15L349.04 257.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.04 257.69L349.04 301.15Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.95 272.18L371.81 274.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.81 274.25L367.67 278.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.67 278.39L365.6 284.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.6 284.6L365.6 288.74Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.6 288.74L367.67 294.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.67 294.95L371.81 299.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.81 299.09L375.95 301.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.95 301.15L382.16 301.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.16 301.15L386.3 299.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.3 299.09L390.44 294.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.44 294.95L392.51 288.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.51 288.74L392.51 284.6Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.51 284.6L390.44 278.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.44 278.39L386.3 274.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.3 274.25L382.16 272.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.16 272.18L375.95 272.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.77 257.69L429.77 301.15Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.77 278.39L425.63 274.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.63 274.25L421.49 272.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.49 272.18L415.28 272.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.28 272.18L411.14 274.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.14 274.25L407 278.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407 278.39L404.93 284.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.93 284.6L404.93 288.74Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.93 288.74L407 294.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407 294.95L411.14 299.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.14 299.09L415.28 301.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.28 301.15L421.49 301.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.49 301.15L425.63 299.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.63 299.09L429.77 294.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.26 284.6L469.1 284.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M469.1 284.6L469.1 280.46Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M469.1 280.46L467.03 276.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.03 276.32L464.96 274.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.96 274.25L460.82 272.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.82 272.18L454.61 272.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.61 272.18L450.47 274.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.47 274.25L446.33 278.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.33 278.39L444.26 284.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.26 284.6L444.26 288.74Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.26 288.74L446.33 294.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.33 294.95L450.47 299.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.47 299.09L454.61 301.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.61 301.15L460.82 301.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.82 301.15L464.96 299.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.96 299.09L469.1 294.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.52 301.15L481.52 272.18Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.52 280.46L487.73 274.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M487.73 274.25L491.87 272.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M491.87 272.18L498.08 272.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M498.08 272.18L502.22 274.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M502.22 274.25L504.29 280.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M504.29 280.46L504.29 301.15Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M504.29 280.46L510.5 274.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M510.5 274.25L514.64 272.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M514.64 272.18L520.85 272.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M520.85 272.18L524.99 274.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M524.99 274.25L527.06 280.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M527.06 280.46L527.06 301.15Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_07_06;

    