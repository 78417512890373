const SvgVentilation = () => (
    <svg xmlns='http://www.w3.org/2000/svg' xmlSpace='preserve' width={25.116} height={24.881}>
        <path
            d='M-54.99 18.756c-1.33-.594-2.56-3.638-2.576-6.38l-.007-1.097-.575-.624-.574-.625-.449.503-.448.502-.11 2.272c-.068 1.424-.222 2.538-.414 2.988l-.305.718h-.692c-1.196 0-2.52-1.04-3.745-2.94l-.6-.931-.014-.903-.013-.902.573-.845c.81-1.194 2.637-2.914 3.646-3.433l.856-.44.155-.59c.416-1.576-.223-1.618-3.134-.208-1.195.579-2.433 1.052-2.752 1.052h-.58l-.318-.486-.319-.487.12-1.412c.187-2.216 1.344-4.224 2.668-4.63l.664-.204 1.41.266c1.584.3 2.89.751 4.231 1.465.5.265.952.41 1.007.322.054-.089.356-.217.67-.287.315-.069.572-.232.572-.362 0-.402-.994-1.359-2.2-2.117a24.532 24.532 0 0 1-1.96-1.382l-.814-.66v-.524c0-.904.745-1.498 2.487-1.985 3.015-.84 4.429-.42 5.3 1.577.565 1.292 1.014 3.335 1.014 4.614v1.29l.514.405c.691.544 1.236.437 1.44-.281.09-.316.249-1.647.353-2.959l.19-2.384.41-.438c.227-.24.575-.437.775-.437.934 0 2.538 1.383 3.955 3.411l.445.638-.017.874-.018.874-.513.784c-.848 1.295-3.03 3.227-4.556 4.033-.385.204-.746 1.168-.592 1.582.05.137.417.24.856.24h.766l1.87-.935c1.028-.515 2.153-.996 2.5-1.07l.629-.134.47.308.47.309v1.023c0 1.787-.897 4.11-1.92 4.971l-.596.501-1.276-.002c-1.734-.003-3.435-.54-5.812-1.833l-.517-.281-.677.282-.676.283.123.345c.215.605.696 1.042 2.267 2.06 1.659 1.074 2.58 2.01 2.58 2.624 0 .554-.939 1.346-2.058 1.736-1.122.391-3.605.604-4.16.356'
            style={{
                fill: '{{replacement-value-color}}',
                fillOpacity: 1,
            }}
            transform='translate(67.385 6.02)'
        />
    </svg>
);
export default SvgVentilation;
