import FileGrid from './file-grid';
import { IFile } from './common';
import ImageGrid from './image-grid';
import Stack from '@mui/material/Stack/Stack';
import { useMemo } from 'react';

export interface ImageFileGridProps {
    files: IFile[];
}

const ImageFileGrid = (props: ImageFileGridProps) => {
    const { files } = props;
    const images = useMemo(() => files.filter((f) => f.type === 'image'), [files]);
    const fileFiles = useMemo(() => files.filter((f) => f.type === 'file'), [files]);

    return (
        <Stack>
            <ImageGrid files={images} />
            <FileGrid files={fileFiles} />
        </Stack>
    );
};

export default ImageFileGrid;
