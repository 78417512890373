import { ReactNode, cloneElement } from 'react';

interface Props {
    element: JSX.Element;
    children: ReactNode | ReactNode[];
    condition: boolean;
}

const Wrapper = ({ element, children, condition }: Props) => {
    if (condition) return <>{cloneElement(element, {}, children)}</>;
    return <>{children}</>;
};

export default Wrapper;
