
    const Svg2_04_06 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="346.32 271.73 137.69 73.84999999999997" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M351.32 276.73L351.32 340.58Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M351.32 340.58L479.01 340.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M479.01 340.58L479.01 276.73Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M479.01 276.73L351.32 276.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.95 324.62L369.11 292.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.11 292.69L381.27 324.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.51 313.98L376.71 313.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.39 324.62L390.39 292.69Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.39 292.69L404.07 292.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.07 292.69L408.63 294.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.63 294.21L410.15 295.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.15 295.73L411.67 298.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.67 298.77L411.67 301.81Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.67 301.81L410.15 304.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.15 304.85L408.63 306.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.63 306.37L404.07 307.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.39 307.89L404.07 307.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.07 307.89L408.63 309.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.63 309.42L410.15 310.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.15 310.94L411.67 313.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.67 313.98L411.67 318.54Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.67 318.54L410.15 321.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.15 321.58L408.63 323.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.63 323.1L404.07 324.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.07 324.62L390.39 324.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.32 324.62L422.32 292.69Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.32 292.69L432.96 292.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.96 292.69L437.52 294.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.52 294.21L440.56 297.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.56 297.25L442.08 300.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.08 300.29L443.6 304.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.6 304.85L443.6 312.46Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.6 312.46L442.08 317.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.08 317.02L440.56 320.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.56 320.06L437.52 323.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.52 323.1L432.96 324.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.96 324.62L422.32 324.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.24 292.69L454.24 324.62Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.24 324.62L472.48 324.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_04_06;

    