
    const Svg3_08_01 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="364.94 263.56 87.32 87.32999999999998" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M447.26 268.56L369.94 268.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.94 268.56L369.94 345.89Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.94 345.89L447.26 345.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.26 345.89L447.26 268.56Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.87 297.5L384.87 319.21Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.6 334.43L390.6 318.97Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.64 318.97L401.64 334.43Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.49 318.97L406.8 318.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.16 318.97L412.69 319.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.69 319.7L411.21 321.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.21 321.18L410.48 322.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.48 322.65L409.74 324.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.74 324.86L409.74 328.54Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.74 328.54L410.48 330.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.48 330.75L411.21 332.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.21 332.22L412.69 333.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.69 333.7L414.16 334.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.16 334.43L417.11 334.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.11 334.43L418.58 333.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.58 333.7L420.05 332.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.05 332.22L420.79 330.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.79 330.75L421.52 328.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.52 328.54L421.52 324.86Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.52 324.86L420.79 322.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.79 322.65L420.05 321.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.05 321.18L418.58 319.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.58 319.7L417.11 318.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.11 318.97L414.16 318.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.49 310.62L403.24 305.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.99 307.12L408.49 310.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.74 301.87L411.99 307.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M385.75 270.38L411.99 296.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.99 296.62L397.99 310.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.99 310.62L371.75 284.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.75 284.38L385.75 270.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg3_08_01;

    