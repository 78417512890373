import 'ol/events/condition';

import CTControl, { ICTControl } from './CTControl';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { Collection, ImageTile } from 'ol';
import { ControlEventTarget, ExtendedEvent, SaveStatus } from './ControlHandlerUtils';
import { Source, TileWMS, XYZ } from 'ol/source';

import AnimationHelper from './AnimationHelper';
import { Authentication } from './CaretakerMapSettings';
import CTLayer from './CTLayer';
import { ControlHandler } from './ControlHandler';
import { ControlNames } from '../interfaces';
import GetCreateGuiElements from './react-controls/create-gui/create-gui';
import { IDictionary } from '../../../shared/utils/types';
import { Layer } from 'ol/layer';
import TileLayer from 'ol/layer/Tile';
import { Tools } from './EditingControl';
import { Vector as VectorLayer } from 'ol/layer';
import { WFSSource } from './deprecated/DataforsyningWFSLoader';
import { staticImplements } from '../../../shared/utils/decorators';

//#region Layer Setup

export interface Options {
    //element?: HTMLElement;
    //render?: (p0: MapEvent) => void;
    target?: HTMLElement | string;
}

interface gui {
    div: HTMLDivElement;
    content: HTMLDivElement;
    ctlButtonBackground: HTMLDivElement;
    ctlButton: HTMLDivElement;
}

export interface ILayer<TLayer = Layer<Source>> {
    displayName: string;
    layer: TLayer;
    extra?: number;
}
type extractLayer<P> = P extends ILayer<infer T> ? T : never;

export interface ILayers<TLayer = Layer<Source>> {
    [layerId: string]: ILayer<TLayer>;
}

export enum LayerType {
    baseLayer = 'base',
    overlay = 'over',
    urlLayer = 'url',
}

export enum RepresentationLayer {
    Enhed = 'enhed',
    Bygning = 'bygning',
    Lokale = 'lokale',
}

export const representationLayerKeys: IDictionary<string[], RepresentationLayer> = {
    [RepresentationLayer.Enhed]: ['POLY_Ejendomme', 'POLY_Ejendomme adr'],
    [RepresentationLayer.Bygning]: ['POLY_Bygninger'],
    [RepresentationLayer.Lokale]: ['POLY_Lokaler'],
};

export const isRepresentationLayer = (displayNameOrId: string, representation?: RepresentationLayer): boolean => {
    const key = displayNameOrId.startsWith('POLY_') ? displayNameOrId : `POLY_${displayNameOrId}`;
    if (representation != null) return representationLayerKeys[representation].includes(key);
    return Object.values(representationLayerKeys).some((keys) => keys.includes(key));
}

export class Layers {
    public baseLayerZIndex: number = 1;
    public overlayZIndex: number = 50;
    /** Newer addition. Corresponds to layers recognized by {@link getRepresentationLayer} */
    public representationLayerZIndex: number = 200;
    public urlLayerZIndex: number = 400;
    public baseLayers: ILayers = {};
    public overlays: ILayers = {};
    public urlLayers: ILayers<CTLayer> = {};
    public defaultMainLayer?: string;
    // private _highestUrlZIndex : number;
    // get highesUrlZIndex() : number {
    //     return this._highestUrlZIndex
    // }
    // set highesUrlZIndex(index : number) {
    //     if (index > this._highestUrlZIndex)
    //         this._highestUrlZIndex = index;
    // }

    private addLayer(
        layerId: string,
        value: ILayer<extractLayer<ILayer> | CTLayer>,
        type: LayerType,
        defaultMainLayer?: string
    ) {
        if (defaultMainLayer != null) this.defaultMainLayer = defaultMainLayer;
        if (type === LayerType.baseLayer) {
            this.baseLayerZIndex++;
            this.baseLayers[layerId] = value;
            this.baseLayers[layerId].layer.setZIndex(this.baseLayerZIndex);
            value.layer.setVisible(layerId === this.defaultMainLayer);
        } else if (type === LayerType.overlay) {
            this.overlayZIndex++;
            this.overlays[layerId] = value;
            this.overlays[layerId].layer.setZIndex(this.overlayZIndex);
        } else if (type === LayerType.urlLayer) {
            // Note that urlLayer has been adopted for CTLayers in react
            if (isRepresentationLayer(layerId)) {
                this.urlLayers[layerId] = value as ILayer<CTLayer>;
                this.urlLayers[layerId].layer.setZIndex(this.representationLayerZIndex++);
                return;
            }
            this.urlLayers[layerId] = value as ILayer<CTLayer>;
            this.urlLayers[layerId].layer.setZIndex(this.urlLayerZIndex++);
        } else {
            throw new Error('LayerType was not recognized by the LayerMenuController');
        }
    }

    public getLayersRaw() {
        const layers = new Collection<Layer<Source>>();
        Object.keys(this.baseLayers).forEach((key) => {
            layers.push(this.baseLayers[key].layer);
        });
        Object.keys(this.overlays).forEach((key) => {
            layers.push(this.overlays[key].layer);
        });
        Object.keys(this.urlLayers).forEach((key) => {
            layers.push(this.urlLayers[key].layer);
        });
        return layers;
    }

    public addBaseLayer(layerId: string, value: ILayer, defaultMainLayer?: string) {
        if (defaultMainLayer != null) this.defaultMainLayer = defaultMainLayer;
        this.addLayer(layerId, value, LayerType.baseLayer);
    }

    public getBaseLayers() {
        return this.baseLayers;
    }

    public addOverlay(layerId: string, value: ILayer) {
        this.addLayer(layerId, value, LayerType.overlay);
    }

    public getOverlays() {
        return this.overlays;
    }

    public addUrlLayer(layerId: string, value: ILayer) {
        this.addLayer(layerId, value, LayerType.urlLayer);
    }

    public getUrlLayers() {
        return this.urlLayers;
    }

    public getRepresentationLayer(represents: RepresentationLayer): ILayer<CTLayer> | undefined {
        const key = representationLayerKeys[represents].find((key) => this.urlLayers[key] != null);

        if (key != null) return this.urlLayers[key];
        return undefined;
    }

    public setupDefaultLayers(
        auth: Authentication,
        defaultMainLayer: string = 'dtk_skaermkort_graa',
        baseLayers: { name: string }[] = [
            { name: 'Kort grå' },
            { name: 'Kort Dæmpet' },
            { name: 'Kort' },
            { name: 'Satellitkort' },
            { name: 'Skjul kort' },
        ]
    ) {
        this.defaultMainLayer = defaultMainLayer;

        //#region SkaermkortetKlassisk_layers

        const SkaermkortetKlassisk_layers: IDictionary = {
            'Kort Grå': { layer: 'dtk_skaermkort_graa', opacity: 1 },
            'Kort Dæmpet': { layer: 'dtk_skaermkort_daempet', opacity: 1 },
            'Kort': { layer: 'dtk_skaermkort', opacity: 1 },
        };

        const tileLoader = (tile: ImageTile, src: string) => {
            // eslint-disable-next-line no-restricted-syntax
            fetch(src, {
                method: 'GET',
                referrerPolicy: 'no-referrer',
            })
                .then((response) => response.blob())
                .then((blob) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        (tile.getImage() as HTMLImageElement).src = reader.result!.toString();
                    };
                    reader.readAsDataURL(blob);
                })
                .catch(() => {
                    // (tile.getImage() as HTMLImageElement).src = Error source goes here. For now it just stays blank.
                });
        };

        Object.keys(SkaermkortetKlassisk_layers)
            .filter((key) => baseLayers.find((val) => val.name.toLowerCase() === key.toLowerCase()) !== undefined)
            .forEach((l) => {
                this.addBaseLayer(SkaermkortetKlassisk_layers[l].layer, {
                    displayName: l,
                    layer: new TileLayer({
                        source: new TileWMS({
                            url: 'https://services.datafordeler.dk/Dkskaermkort/topo_skaermkort/1.0.0/wms',
                            crossOrigin: '',
                            params: {
                                username: auth.user,
                                password: auth.pass,
                                LAYERS: SkaermkortetKlassisk_layers[l].layer,
                                TRANSPARENT: 'TRUE',
                                //'TILED': true,
                            },
                            projection: 'EPSG:25832',
                            tileLoadFunction: (tile, src) => tileLoader(tile as ImageTile, src),
                        }),
                        visible: false,
                        opacity: SkaermkortetKlassisk_layers[l].opacity,
                    }),
                });
            });

        //#endregion SkaermkortetKlassisk_layers

        //#region ortho og skyggekort
        if (baseLayers.find((val) => val.name.toLowerCase() === 'satellitkort'))
            this.addBaseLayer('off', {
                displayName: 'Satellitkort',
                layer: new TileLayer({
                    source: new XYZ({
                        url:
                            'https://services.datafordeler.dk/GeoDanmarkOrto/orto_foraar_wmts/1.0.0/Wmts/orto_foraar_wmts/default/KortforsyningTilingDK/{z}/{y}/{x}.jpg?username=' +
                            auth.user +
                            '&password=' +
                            auth.pass,
                        maxZoom: 13,
                        minZoom: 0,
                        maxResolution: 1638.4,
                        //tileOrigin: [120000, 5900000],
                        projection: 'EPSG:25832',
                        tileSize: [256, 256],
                    }),
                    opacity: 1,
                    visible: false,
                    extent: [120000, 5900000, 1000000, 6500000],
                    minZoom: 0,
                    preload: 0,
                    useInterimTilesOnError: false,
                }),
            });

        // Tomt kort
        if (baseLayers.find((val) => val.name.toLowerCase() === 'skjul kort'))
            this.addBaseLayer('none', {
                displayName: 'Skjul kort',
                layer: new TileLayer(),
            });

        this.addOverlay('dhmts', {
            displayName: 'DHM Terraen Skyggekort',
            layer: new TileLayer({
                source: new XYZ({
                    url:
                        'https://services.datafordeler.dk/DHMSkyggekort/dhm_terraen_skyggekort/1.0.0/Wmts/dhm_terraen_skyggekort/default/View1/{z}/{y}/{x}.jpg?username=' +
                        auth.user +
                        '&password=' +
                        auth.pass,
                    maxZoom: 13,
                    minZoom: 0,
                    maxResolution: 1638.4,
                    //tileOrigin: [120000, 5900000],
                    projection: 'EPSG:25832',
                    tileSize: [256, 256],
                }),
                opacity: 0.6,
                visible: false,
                extent: [120000, 5900000, 1000000, 6500000],
                minZoom: 0,
                preload: 0,
                useInterimTilesOnError: false,
            }),
        });

        this.addOverlay('dhmos', {
            displayName: 'DHM Overflade Skyggekort',
            layer: new TileLayer({
                source: new XYZ({
                    url:
                        'https://services.datafordeler.dk/DHMSkyggekort/dhm_overflade_skyggekort/1.0.0/Wmts/dhm_overflade_skyggekort/default/View1/{z}/{y}/{x}.jpg?username=' +
                        auth.user +
                        '&password=' +
                        auth.pass,
                    maxZoom: 13,
                    minZoom: 0,
                    maxResolution: 1638.4,
                    //tileOrigin: [120000, 5900000],
                    projection: 'EPSG:25832',
                    tileSize: [256, 256],
                }),
                opacity: 0.6,
                visible: false,
                extent: [120000, 5900000, 1000000, 6500000],
                minZoom: 0,
                preload: 0,
                useInterimTilesOnError: false,
            }),
        });

        //#endregion ortho og skyggekort

        //Layer WFS Bygning
        this.addOverlay('byg', {
            displayName: 'Bygninger',
            layer: new VectorLayer({
                source: new WFSSource(
                    'https://services.datafordeler.dk/GeoDanmarkVektor/GeoDanmark60_NOHIST_GML3/1.0.0/WFS?username=' +
                    auth.user +
                    '&password=' +
                    auth.pass,
                    'Bygning',
                    'gdk60:geometri'
                ),
                style: new Style({
                    fill: new Fill({
                        color: 'rgba(0, 255, 0, 0.4)',
                    }),
                    stroke: new Stroke({
                        color: '#44ff44',
                        width: 2,
                    }),
                    image: new CircleStyle({
                        radius: 7,
                        fill: new Fill({
                            color: '#44ff44',
                        }),
                    }),
                }),
                minZoom: 13,
                //zIndex: 50,
                visible: false,
            }),
        });

        //Layer WFS SamletFastEjendom
        this.addOverlay('sfe', {
            displayName: 'Samlet Fast Ejendom',
            layer: new VectorLayer({
                source: new WFSSource(
                    'https://services.datafordeler.dk/MATRIKEL/Matrikel_HIST_GML3_1_0/1.0.0/WFS?username=' +
                    auth.user +
                    '&password=' +
                    auth.pass,
                    'SamletFastEjendom',
                    'mat:geometri'
                ),
                style: new Style({
                    fill: new Fill({
                        color: 'rgba(0, 0, 0, 0)',
                    }),
                    stroke: new Stroke({
                        color: '#ff4444',
                        width: 2,
                    }),
                    image: new CircleStyle({
                        radius: 7,
                        fill: new Fill({
                            color: '#ff4444',
                        }),
                    }),
                }),
                minZoom: 13,
                //zIndex: 49,
                visible: false,
            }),
        });

        // Layer WFS Jordstykke Gældende
        this.addOverlay('jsg', {
            displayName: 'Jordstykke Gældende',
            layer: new VectorLayer({
                source: new WFSSource(
                    'https://services.datafordeler.dk/Matrikel/MatrikelGaeldendeOgForeloebigWFS/1.0.0/WFS?username=' +
                    auth.user +
                    '&password=' +
                    auth.pass,
                    'Jordstykke_Gaeldende',
                    'mat:geometri'
                ),
                style: new Style({
                    fill: new Fill({
                        color: 'rgba(0, 0, 0, 0)',
                    }),
                    stroke: new Stroke({
                        color: '#ffff44',
                        width: 2,
                    }),
                    image: new CircleStyle({
                        radius: 7,
                        fill: new Fill({
                            color: '#ffff44',
                        }),
                    }),
                }),
                minZoom: 13,
                //zIndex: 1000,
                visible: false,
            }),
        });

        //#region MatrikelGaeldendeOgForeloebigWMS_layers
        const MatrikelGaeldendeOgForeloebigWMS_layers: IDictionary = {
            'Matrikel': { layer: 'Centroide_Gaeldende,Skelpunkt_Gaeldende,MatrikelSkel_Gaeldende', opacity: 1 },
            'Centroide': { layer: 'Centroide_Gaeldende', opacity: 0.6 },
            'Skelpunkt': { layer: 'Skelpunkt_Gaeldende', opacity: 0.6 },
            'Matrikel Skel': { layer: 'MatrikelSkel_Gaeldende', opacity: 1 },

            'Bygning På Fremmed Grund': {
                layer: 'BygningPaaFremmedGrundPunkt_Gaeldende,BygningPaaFremmedGrundFlade_Gaeldende',
                opacity: 0.6,
            },
            //'Bygning På Fremmed Grund Punkt': { layer: 'BygningPaaFremmedGrundPunkt_Gaeldende', opacity: 0.6 },
            //'Bygning På Fremmed Grund Flade': { layer: 'BygningPaaFremmedGrundFlade_Gaeldende', opacity: 0.6 },

            'Fredskov': { layer: 'FredskovFlade_Gaeldende,FredskovsLinje_Gaeldende', opacity: 1 },
            //'Fredskov Flade': { layer: 'FredskovFlade_Gaeldende', opacity: 1 },
            //'Fredskovs Linje': { layer: 'FredskovsLinje_Gaeldende', opacity: 1 },

            'Strandbeskytelselinje': { layer: 'StrandbeskyttelseFlade_Gaeldende', opacity: 0.7 }, //Strandbeskytelselinje_Gaeldende,
            //'Strandbeskytelselinje': { layer: 'Strandbeskytelselinje_Gaeldende', opacity: 1 },
            //'Strandbeskyttelse Flade': { layer: 'StrandbeskyttelseFlade_Gaeldende', opacity: 0.6 },

            'Klitfredning': { layer: 'Klitfredningslinje_Gaeldende,KlitfredningFlade_Gaeldende', opacity: 0.7 },
            //'Klitfredningslinje': { layer: 'Klitfredningslinje_Gaeldende', opacity: 1 },
            //'Klitfredning Flade': { layer: 'KlitfredningFlade_Gaeldende', opacity: 0.6 },

            'Ejerlejlighed': { layer: 'Ejerlejlighed_Gaeldende', opacity: 0.6 },
            'Ejerlejlighedslod': { layer: 'Ejerlejlighedslod_Gaeldende', opacity: 0.6 },
            'Matrikelkommune': { layer: 'Matrikelkommune_Gaeldende', opacity: 0.6 },
            'Matrikelsogn': { layer: 'Matrikelsogn_Gaeldende', opacity: 0.6 },
            'Samlet Fast Ejendom': { layer: 'SamletFastEjendom_Gaeldende', opacity: 0.6 },
            'Matrikulær Sag Igangvaerende': { layer: 'MatrikulaerSag_Igangvaerende', opacity: 0.6 },
            'Ejerlav': { layer: 'Ejerlav_Gaeldende', opacity: 0.6 },
            'Nul Linie': { layer: 'NulLinie_Gaeldende', opacity: 0.6 },
            'Optaget Vej': { layer: 'OptagetVej_Gaeldende', opacity: 1 },
            'Jordstykke': { layer: 'Jordstykke_Gaeldende', opacity: 0.6 },
            'Lodflade': { layer: 'Lodflade_Gaeldende', opacity: 0.6 },
        };
        //#endregion MatrikelGaeldendeOgForeloebigWMS_layers

        //#region GeoDanmark_60_NOHIST_Layers
        const GeoDanmark_60_NOHIST_Layers: IDictionary = {
            'Bykerne': { layer: 'BYKERNE', opacity: 1 },
            'Erhverv': { layer: 'ERHVERV', opacity: 1 },
            'Bassin': { layer: 'BASSIN', opacity: 1 },
            'Højspændingsledning': { layer: 'HOEJSPAENDINGSLEDNING', opacity: 1 },
            'Bygværk': { layer: 'BYGVAERK', opacity: 1 },
            'Høfde': { layer: 'HOEFDE', opacity: 1 },
            'Parkering': { layer: 'PARKERING', opacity: 1 },
            'Telemast': { layer: 'TELEMAST', opacity: 1 },
            'Vindmølle': { layer: 'VINDMOELLE', opacity: 1 },
            'Mast': { layer: 'MAST', opacity: 1 },
            'Nedløbsrist': { layer: 'NEDLOEBSRIST', opacity: 1 },
            'Brønddæksel': { layer: 'BROENDDAEKSEL', opacity: 1 },
            'Skov': { layer: 'SKOV', opacity: 1 },
            'Hede': { layer: 'HEDE', opacity: 1 },
            'Vådomraade': { layer: 'VAADOMRAADE', opacity: 1 },
            'Råstofomraade': { layer: 'RAASTOFOMRAADE', opacity: 1 },
            'Hegn': { layer: 'HEGN', opacity: 1 },
            'Brugsgrænse': { layer: 'BRUGSGRAENSE', opacity: 1 },
            'Skrænt': { layer: 'SKRAENT', opacity: 1 },
            'Dige': { layer: 'DIGE', opacity: 1 },
            'Træ': { layer: 'TRAE', opacity: 1 },
            'Trægruppe': { layer: 'TRAEGRUPPE', opacity: 1 },
            'Vejmidte': { layer: 'VEJMIDTE', opacity: 1 },
            'Systemlinje': { layer: 'SYSTEMLINJE', opacity: 1 },
            'Vejkant': { layer: 'VEJKANT', opacity: 1 },
            'Helle': { layer: 'HELLE', opacity: 1 },
            'Chikane': { layer: 'CHIKANE', opacity: 1 },
            'Trafikhegn': { layer: 'TRAFIKHEGN', opacity: 1 },
            'Jernbane': { layer: 'JERNBANE', opacity: 1 },
            'Startbane': { layer: 'STARTBANE', opacity: 1 },
            'Skorsten': { layer: 'SKORSTEN', opacity: 1 },
            'Sø': { layer: 'SOE', opacity: 0.5 },
            'Vandløbsmidte': { layer: 'VANDLOEBSMIDTE', opacity: 1 },
            'Havn': { layer: 'HAVN', opacity: 1 },
            'Kyst': { layer: 'KYST', opacity: 1 },
            'Bygning': { layer: 'BYGNING', opacity: 1 },
            'Områdepolygon': { layer: 'OMRAADEPOLYGON', opacity: 1 },
            'Afvandingsgrøft': { layer: 'AFVANDINGSGROEFT', opacity: 1 },
            'Vandløbskant': { layer: 'VANDLOEBSKANT', opacity: 1 },
            'Dæmning': { layer: 'DAEMNING', opacity: 1 },
            'Fotoindex': { layer: 'FOTOINDEX', opacity: 1 },
            'Gartneri': { layer: 'GARTNERI', opacity: 1 },
            'Historiskpunkt': { layer: 'HISTORISKPUNKT', opacity: 1 },
            'Historiskflade': { layer: 'HISTORISKFLADE', opacity: 1 },
            'Parkeringsområde': { layer: 'PARKERINGSOMRAADE', opacity: 1 },
            'Plads': { layer: 'PLADS', opacity: 1 },
            'Sportsbane': { layer: 'SPORTSBANE', opacity: 1 },
            'Togstation': { layer: 'TOGSTATION', opacity: 1 },
            'Udpegningpunkt': { layer: 'UDPEGNINGPUNKT', opacity: 1 },
            'Udpegninglinje': { layer: 'UDPEGNINGLINJE', opacity: 1 },
            'Udpegningflade': { layer: 'UDPEGNINGFLADE', opacity: 0.75 },
            'Vandafstrømningsopland': { layer: 'VANDAFSTROEMNINGSOPLAND', opacity: 1 },
            'Vandhændelse': { layer: 'VANDHAENDELSE', opacity: 1 },
            'Vandknude': { layer: 'VANDKNUDE', opacity: 1 },
            'Lav Bebyggelse': { layer: 'LAV_BEBYGGELSE', opacity: 1 },
            'Høj Bebyggelse': { layer: 'HOEJ_BEBYGGELSE', opacity: 1 },
            'Teknisk Areal': { layer: 'TEKNISK_AREAL', opacity: 1 },
            'Anlæg Diverse': { layer: 'ANLAEG_DIVERSE', opacity: 1 },
            'Statue Sten': { layer: 'STATUE_STEN', opacity: 1 },
            'Krat Bevoksning': { layer: 'KRAT_BEVOKSNING', opacity: 1 },
            'Sand Klit': { layer: 'SAND_KLIT', opacity: 1 },
            'Bade Bådebro': { layer: 'BADE_BAADEBRO', opacity: 1 },
            'Begravelses Område': { layer: 'BEGRAVELSES_OMRAADE', opacity: 1 },
            'Kommuneområde': { layer: 'KOMMUNEOMRAADE', opacity: 1 },
            'DHM Linje': { layer: 'DHM_LINJE', opacity: 1 },
            'DHM Hestesko': { layer: 'DHM_HESTESKO', opacity: 1 },
            'Historisklinje': { layer: 'HISTORISKLINJE', opacity: 1 },
            'Teknisk Anlæg Punkt': { layer: 'TEKNISK_ANLAEG_PUNKT', opacity: 1 },
            'Teknisk Anlæg Flade': { layer: 'TEKNISK_ANLAEG_FLADE', opacity: 0.8 },
            'Rekreativt Område': { layer: 'REKREATIVT_OMRAADE', opacity: 1 },
        };
        //#endregion GeoDanmark_60_NOHIST_Layers

        Object.keys(MatrikelGaeldendeOgForeloebigWMS_layers).forEach((l) => {
            this.addOverlay(l, {
                displayName: l,
                layer: new TileLayer({
                    source: new TileWMS({
                        url: 'https://services.datafordeler.dk/Matrikel/MatrikelGaeldendeOgForeloebigWMS/1.0.0/WMS',
                        crossOrigin: '',
                        params: {
                            username: auth.user,
                            password: auth.pass,
                            LAYERS: MatrikelGaeldendeOgForeloebigWMS_layers[l].layer,
                            TRANSPARENT: 'TRUE',
                            //'TILED': true,
                        },
                        projection: 'EPSG:25832',
                    }),
                    visible: false,
                    opacity: MatrikelGaeldendeOgForeloebigWMS_layers[l].opacity,
                }),
            });
        });

        Object.keys(GeoDanmark_60_NOHIST_Layers).forEach((l) => {
            this.addOverlay(l, {
                displayName: l,
                layer: new TileLayer({
                    source: new TileWMS({
                        url: 'https://services.datafordeler.dk/GeoDanmarkVektor/GeoDanmark_60_NOHIST/1.0.0/WMS',
                        crossOrigin: '',
                        params: {
                            username: auth.user,
                            password: auth.pass,
                            LAYERS: GeoDanmark_60_NOHIST_Layers[l].layer,
                            TRANSPARENT: 'TRUE',
                            //'TILED': true,
                        },
                        projection: 'EPSG:25832',
                    }),
                    visible: false,
                    opacity: GeoDanmark_60_NOHIST_Layers[l].opacity,
                }),
            });
        });

        // Sorter i alfabetisk rækkefølge
        // Sammenligner displayName i sort, og laver ny, sorteret dict i reduce
        this.overlays = Object.keys(this.overlays)
            .sort((a, b) =>
                this.overlays[a].displayName.toLowerCase() < this.overlays[b].displayName.toLowerCase() ? -1 : 1
            )
            // eslint-disable-next-line no-sequences
            .reduce((r: IDictionary, k) => ((r[k] = this.overlays[k]), r), {});
    }
}

//#endregion Layer Setup
@staticImplements<ICTControl>()
export class LayerMenuControl extends CTControl {
    private gui: gui;
    private isVisible: boolean;
    private layers: Layers;
    public static ControlName = ControlNames.LayerMenu;
    private animationHelper: AnimationHelper;

    constructor(controlHandler: ControlHandler, layers: Layers, opt_options?: Options) {
        const gui = LayerMenuControl.createGuiElement(layers, controlHandler);
        super(controlHandler, {
            element: gui.div,
            target: opt_options?.target,
        });
        this.animationHelper = new AnimationHelper();
        gui.div.style.top = `${this.controlHandler.getAssignedHeight()}px`;
        this.layers = layers;
        this.isVisible = false;
        this.gui = gui;

        this.addEventListeners();
    }

    private resize() {
        // if (this.maxMenuHeight == 0)
        //     this.maxMenuHeight = this.gui.content.getBoundingClientRect().height;
        if (this.isVisible) {
            // Fuld højde minus padding, da boksen ellers vil gro hver gang funktionen kaldes.
            const currentHeight =
                this.gui.content.getBoundingClientRect().height -
                2 * parseInt(this.gui.content.style.padding.substring(this.gui.content.style.padding.indexOf('px')));
            const maxHeight = this.map.getTargetElement().getBoundingClientRect().height - 100;
            const h = currentHeight > maxHeight ? maxHeight : currentHeight;
            this.gui.content.style.height = h + 'px';
        }
    }

    private static createGuiElement(layers: Layers, controlHandler: ControlHandler) {
        const div = document.createElement('div');
        //element.id = 'layerMenu';
        div.className = 'ol-unselectable ol-layerControl ol-control-flex-left'; //ol-control

        const content = document.createElement('div');
        content.className = 'ol-layerControl-content ol-replicate-mui-paper ol-replicate-mui-grow-hidden';

        const ctlButtonBackground = document.createElement('div');
        ctlButtonBackground.className = 'ol-layerControl-btn-backGround ol-btn-background-height';

        const ctlButton = document.createElement('div');
        ctlButton.className = 'ol-layerControl-btn';

        // Here the react gui is imported
        content.appendChild(GetCreateGuiElements(layers, controlHandler));

        div.appendChild(content);
        ctlButtonBackground.appendChild(ctlButton);
        div.appendChild(ctlButtonBackground);

        return {
            div: div,
            content: content,
            ctlButtonBackground: ctlButtonBackground,
            ctlButton: ctlButton,
        };
    }

    private changeVisibility() {
        if (this.isVisible) {
            //this.gui.content.classList.remove('ol-layerControl-content-show');
            this.animationHelper.hide(this.gui.content);
            (this.gui.div.style.zIndex as unknown as number) -= 1000;
        } else {
            //this.gui.content.classList.add('ol-layerControl-content-show');
            this.gui.div.style.zIndex += 1000;
            this.controlHandler.activeController = LayerMenuControl.ControlName;

            this.animationHelper.show(this.gui.content);
        }
        this.isVisible = !this.isVisible;
    }

    /** Does nothing. Exists for backwards compatibility */
    public changeBaseLayer(_value: string) {
        return;
    }

    private addEventListeners() {
        this.gui.ctlButton.addEventListener('click', (_) => {
            this.changeVisibility();
        });

        window.addEventListener('resize', () => this.resize());
        window.addEventListener('load', () => this.resize());

        //#region Functions for controlhandler change listener
        type cc_func = (e: ExtendedEvent) => void;

        const cc_hideControl: cc_func = (e) => {
            if (this.isVisible && e.value !== LayerMenuControl.ControlName)
                // Luk når andre åbnes
                this.changeVisibility();
        };

        const cc_prepareLayerForEdit: cc_func = () => {
            // Put layer on top
            const iLayer = this.controlHandler.activeILayer;
            iLayer.layer.putOnTop(this.layers);

            //Show new layer and disable input
            const label = Array.from(this.gui.content.querySelectorAll('label')).find(
                (x) => x.innerHTML === iLayer.displayName
            );
            if (label === undefined) {
                return;
            }

            const input = label.parentElement!.querySelector('input.ol-layerControl-layer-toggle')! as HTMLInputElement;
            if (!input.checked) {
                input.checked = true;
                this.toggleLayer(input);
            }
            input.disabled = true;
        };

        const cc_resetAfterEditing: cc_func = () => {
            this.controlHandler.activeToolType = Tools.Select;
        };

        //#endregion Functions for controlhandler change listener

        this.controlHandler.addCustomEventListener('change', (e: ExtendedEvent) => {
            switch (e.eventTarget) {
                case ControlEventTarget.ActiveController:
                    cc_hideControl(e);
                    break;
                case ControlEventTarget.SaveStatus:
                    switch (e.value) {
                        case SaveStatus.Ready:
                            cc_prepareLayerForEdit(e);
                            break;
                        case SaveStatus.Done:
                            cc_resetAfterEditing(e);
                            break;
                        case SaveStatus.Cancel:
                            cc_resetAfterEditing(e);
                            break;
                    }
            }
        });
    }

    private toggleLayer(input: HTMLInputElement) {
        switch (input.parentElement!.dataset.layerType) {
            case LayerType.overlay:
                this.layers.getOverlays()[input.value].layer.setVisible(input.checked);
                break;
            case LayerType.baseLayer:
                const baseLayers = this.layers.getBaseLayers();
                baseLayers[input.value].layer.setVisible(input.checked);
                Object.keys(baseLayers).forEach((key) => {
                    if (key !== input.value) {
                        baseLayers[key].layer.setVisible(false);
                    }
                });
                break;
            case LayerType.urlLayer:
                this.layers.getUrlLayers()[input.value].layer.setVisible(input.checked);
                break;
            default:
                throw new Error('Layertype not recognized in toggleLayer()');
        }
    }
}

//#endregion Layer Menu Control
