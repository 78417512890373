import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

export const getQueryParam = (key: string) => {
    return new URLSearchParams(window.location.search).get(key);
};

function useQueryParam(key: string) {
    return useQuery().get(key) ?? undefined;
}

export default useQueryParam;
