import BurgerMenuList, { BurgerMenuListItem } from './burger-menu-list';
import { CSSObject, Theme, styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import Breadcrumbs from '../breadcrumbs/breadcrumbs';
import CachedIcon from '@mui/icons-material/Cached';
import { CenterLeftBox } from '../../mui/styled-mui';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import { FlexColumn } from '../../misc/flex';
import GetAppIcon from '@mui/icons-material/GetApp';
import HomeIcon from '@mui/icons-material/Home';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List/List';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import MuiDrawer from '@mui/material/Drawer';
import Restricted from '../../restricted/restricted';
import SettingsIcon from '@mui/icons-material/Settings';
import Toolbar from '@mui/material/Toolbar';
import { UserPermissions } from '../../restricted/interfaces';
import { defferedPrompt, forceReload } from '../../../serviceWorkerRegistration';
import { useAuth } from '../../../shared/hooks/redux-use-user';
import useBreakpoints from '../../../shared/hooks/use-breakpoints';
import useDoubleState from '../../../shared/hooks/use-double-state';
import useHistoryNavigate from '../../../shared/hooks/extended-hooks/use-history-navigate';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

interface IDraverProps {
    children: React.ReactNode | React.ReactNode[];
}

export default function BurgerMenuMini({ children }: IDraverProps) {
    const [open, setOpen] = useDoubleState(!window.location.pathname.toLowerCase().includes('overview'));
    const { navigate } = useHistoryNavigate();
    const { signOut } = useAuth();

    const { md } = useBreakpoints();

    const handleDrawerClick = () => {
        setOpen((o) => !o);
    };

    return (
        <div className='flex-column-container'>
            <div className='flex-item-no-fill'>
                <CssBaseline />
                <AppBar position='sticky' open={open}>
                    <Toolbar sx={{ width: '100%' }}>
                        <IconButton
                            color='inherit'
                            aria-label='open drawer'
                            onClick={handleDrawerClick}
                            edge='start'
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <CenterLeftBox sx={{ pl: '8px' }}>{<Breadcrumbs />}</CenterLeftBox>
                        <IconButton onClick={() => navigate('/')} sx={{ ...(!md && { display: 'none' }) }}>
                            <img src='/images/ct-logo-text-scale.svg' alt='Caretaker Logo' width='150px' />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </div>
            <div className='flex-item-fill flex-row-container'>
                <div className='flex-item-no-fill'>
                    <Drawer variant='permanent' open={open} anchor='left'>
                        <Toolbar />
                        <FlexColumn
                            header={
                                <>
                                    <DrawerHeader sx={{ p: '0px' }}>
                                        <List disablePadding sx={{ width: '100%' }}>
                                            <BurgerMenuListItem tekst='Hjem' path='/' icon={<HomeIcon />} />
                                        </List>
                                    </DrawerHeader>
                                    <Divider />
                                </>
                            }
                            footer={
                                <List>
                                    <Restricted permissions={UserPermissions.Settings}>
                                        <BurgerMenuListItem
                                            tekst='Indstillinger'
                                            path='/Settings'
                                            icon={<SettingsIcon />}
                                        />
                                    </Restricted>
                                    <BurgerMenuListItem
                                        onClick={() => {
                                            forceReload();
                                        }}
                                        tekst='Genstart App'
                                        icon={<CachedIcon />}
                                    />
                                    {defferedPrompt != null && (
                                        <BurgerMenuListItem
                                            onClick={() => defferedPrompt?.prompt()}
                                            tekst='Download app'
                                            icon={<GetAppIcon />}
                                        />
                                    )}
                                    <Divider />
                                    <BurgerMenuListItem onClick={signOut} tekst='Log ud' icon={<LogoutIcon />} />
                                </List>
                            }
                        >
                            <BurgerMenuList />
                        </FlexColumn>
                    </Drawer>
                </div>
                <div className='flex-item-fill'>{children}</div>
            </div>
        </div>
    );
}
