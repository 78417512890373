import './auto-form.css';

import Form, { ButtonItem } from 'devextreme-react/form';
import { FormEvent, forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { IAutoFormHandle, IAutoFormProps } from './interfaces';

import Actions from './actions';
import { Scrollable } from '../../misc/flex';
import { TProvider } from '../../../shared/contexts/t-context';
import { generateItem } from './items';
import { isFormDataFile } from './type-guards';

const AutoForm = forwardRef<IAutoFormHandle, IAutoFormProps>(
    (
        {
            actionsPosition = 'header',
            actions = ['submit', 'cancel', 'edit', 'back'],
            allowEditing = true,
            data,
            editing = true,
            files: _files = [],
            formData,
            items,
            labelMode = 'floating',
            onCancel,
            onSubmit,
        },
        forwardRef
    ) => {
        const formRef = useRef<HTMLFormElement>(null);
        const [isEditing, setIsEditing] = useState(editing);

        const handleSubmit = async (e?: FormEvent<HTMLFormElement>) => {
            e?.preventDefault();

            console.log('submitting');

            const data = new FormData(formRef.current!);

            // Append extra data before submitting
            formData?.forEach((f) =>
                isFormDataFile(f) ? data.append(f.name, f.value, f.filename) : data.append(f.name, f.value)
            );

            await onSubmit?.(data);
        };

        /** Close the form if it was initially opened in edit mode. Otherwise just exit edit mode */
        const handleCancel: VoidFunction = useCallback(() => {
            if (isEditing && !editing) return setIsEditing(false);

            onCancel?.();
        }, [isEditing, editing, onCancel]);

        const triggerBack = () => {
            handleCancel();
        };
        const triggerCancel = () => {
            handleCancel();
        };
        const triggerEdit = () => {
            setIsEditing(true);
        };
        const triggerSubmit = () => {
            (formRef.current!.querySelector('.auto-form-submit-item input') as HTMLInputElement).click();
        };

        useImperativeHandle<IAutoFormHandle, IAutoFormHandle>(forwardRef, () => ({
            submit: handleSubmit,
            cancel: handleCancel,
            element: formRef.current!,
        }));

        /** Use actionsPosition as prop key to place actions in scrollable */
        const _actions = {
            [actionsPosition]: (
                <Actions
                    {...{ actions, allowEditing, isEditing, triggerBack, triggerCancel, triggerEdit, triggerSubmit }}
                />
            ),
        };

        return (
            <TProvider defaultValue={data}>
                <Scrollable {..._actions}>
                    <form ref={formRef} onSubmit={handleSubmit}>
                        <button type='submit' disabled style={{ display: 'none' }} aria-hidden='true' />
                        <Form labelMode={labelMode} formData={data}>
                            <ButtonItem cssClass='auto-form-submit-item' buttonOptions={{ useSubmitBehavior: true }} />
                            {items.map((item) => generateItem(item, isEditing))}
                        </Form>
                    </form>
                </Scrollable>
            </TProvider>
        );
    }
);

export default AutoForm;
