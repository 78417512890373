
    const Svg2_04_05 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="316.01 241.93 191.82 100.90999999999997" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M321.01 246.93L321.01 337.84Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M321.01 337.84L502.83 337.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M502.83 337.84L502.83 246.93Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M502.83 246.93L321.01 246.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.43 315.11L365.75 269.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.75 269.66L383.07 315.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.93 299.96L376.57 299.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.05 315.11L396.05 269.66Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.05 269.66L415.54 269.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.54 269.66L422.03 271.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.03 271.82L424.19 273.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.19 273.99L426.36 278.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.36 278.32L426.36 282.65Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.36 282.65L424.19 286.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.19 286.98L422.03 289.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.03 289.14L415.54 291.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.05 291.3L415.54 291.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.54 291.3L422.03 293.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.03 293.47L424.19 295.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.19 295.63L426.36 299.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.36 299.96L426.36 306.46Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.36 306.46L424.19 310.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.19 310.79L422.03 312.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.03 312.95L415.54 315.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.54 315.11L396.05 315.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.51 269.66L458.83 315.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.83 315.11L476.14 269.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_04_05;

    