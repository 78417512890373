import { IInput, ItemTypes } from '../interfaces';
import { generateProps, generateRules } from './generate';

import { SimpleItem } from 'devextreme-react/form';
import { defaultIInputs } from '../defaults';

const InputDecimal = (input: IInput) => {
    return (
        <SimpleItem {...generateProps(input, defaultIInputs[ItemTypes.Decimal])}>
            {generateRules(input, defaultIInputs[ItemTypes.Decimal])}
        </SimpleItem>
    );
};

export default InputDecimal;
