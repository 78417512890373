import TreeList, { Column, RemoteOperations, Scrolling, SearchPanel, Selection } from 'devextreme-react/tree-list';

import Box from '@mui/material/Box';
import { RowClickEvent } from 'devextreme/ui/tree_list';
import useSyncUrl from '../../shared/hooks/use-sync-url';
import { useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import Api from '../../shared/networking/api';
import { Template } from 'devextreme-react/core/template';
import { useElementWidthNumber } from '../../shared/hooks/use-element-width';

const OverviewTree = () => {
    const [params, setParams] = useSearchParams();
    const ids = params.get('ids')?.split(',') ?? [];
    const treeRef = useRef<TreeList<any, string | null>>(null);
    const enhListRef = useRef<{ name: string; hasItems: string }[] | null>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const width = useElementWidthNumber(wrapperRef) ?? 100;

    useSyncUrl();

    const dataSource = useMemo(
        () => ({
            load: async (options: { parentIds?: string[]; filter?: any }) => {
                // Need an empty fetch the first time, but it was duplicated, so this ensures, that if it wants to do an empty fetch
                // it will only do it once
                const parentIds1 = options.parentIds ?? [];
                const parentIds = parentIds1.filter((id) => id !== '' && id !== '0');
                if (parentIds1.length !== parentIds.length) parentIds.push('');
                const data = [];

                // Fetch for every parent id, this is for when navigating straight to a specific id, we need to fetch all the parents first
                for (const parentId of parentIds) {
                    if (parentId === '' && enhListRef.current) {
                        data.push(...enhListRef.current);
                        continue;
                    }
                    const url = '/Overview?id=' + parentId;
                    // const url = Api.createUrl('/Overview?id=' + parentId);
                    const response = await Api.get<{ name: string; hasItems: string }[]>(url);
                    if (Api.ok(response)) {
                        data.push(...response.data);
                    }
                    if (parentId === '') enhListRef.current ??= response.data;
                }
                data.forEach((d) => {
                    if (d.hasItems === 'false') d.hasItems = '';
                });

                // When searching there will be a filterValue, and we just search through the "enhListRef", and return this result
                if (options.filter?.filterValue) {
                    // const url = Api.createUrl('/Overview?id=');
                    // // const url = Api.createUrl('/Overview?id=' + parentId);
                    // const config = { headers: { Authorization: `Bearer ${Api.token}` } };
                    // const response = await axios.get(url, config);
                    data.push(
                        ...(enhListRef.current ?? []).filter((d: { name: string }) =>
                            d.name.toLowerCase().includes(options.filter?.filterValue.toLowerCase())
                        )
                    );
                }
                return data;
            },
        }),
        []
    );

    const getNewPathIds: (id: string) => string = (id: string) => {
        const node = treeRef.current?.instance.getNodeByKey(id);
        if (node && node?.parent) {
            return getNewPathIds(node.parent.key ?? '') + ',' + id;
        }
        return id;
    };

    const onRowClick = (e: RowClickEvent) => {
        // console.log(e);
        // setParams({ ids: getNewPathIds(e.data.id) });
        // console.log(treeRef.current?.instance.isRowExpanded(e.data.id));
        // treeRef.current?.instance.expandRow(e.data.id);
        treeRef.current?.instance.searchByText('');
    };

    const onFocusedRowChanged = (e: any) => {
        if (e.row && e.row.data) {
            setParams({ ids: getNewPathIds(e.row.data.id) });
            setParams((old) => {
                old.set('ids', getNewPathIds(e.row.data.id));
                return old;
            });
            // console.log(treeRef.current?.instance.isRowExpanded(e.row.data.id));
        }
    };

    const cellTemplate = (options: { data: { name: string; hasItems: string; id: string } }) => {
        return (
            <div>
                <Box
                    key={`item-${options.data.id}`}
                    sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: width - 20 }}
                    fontSize={'14px'}
                    fontFamily={'"Helvetica Neue","Segoe UI",helvetica,verdana,sans-serif'}
                >
                    {options.data.name}
                </Box>
            </div>
        );
    };

    return (
        <Box
            sx={{
                // Style all selected keys like the current focus
                '& tr.dx-selection': {
                    color: 'white',
                    backgroundColor: 'var(--dx-selection-color)',
                },
                '& tr.dx-selection > td': {
                    color: 'white !important',
                    backgroundColor: 'var(--dx-selection-color) !important',
                },
                'height': '100%',
            }}
            ref={wrapperRef}
        >
            {/** Tree stolen from /screens/byg/syn/tree.tsx */}
            <TreeList
                id={`tree-bygsyn`}
                dataSource={dataSource as any}
                // dataStructure='plain'
                columnAutoWidth={true}
                wordWrapEnabled={true}
                showColumnHeaders={false}
                showColumnLines={false}
                showRowLines={true}
                showBorders={true}
                keyExpr={'id'}
                // onSelectionChanged={onSelectionChanged}
                onRowClick={onRowClick}
                onFocusedRowChanged={onFocusedRowChanged}
                // cacheEnabled={false}
                focusedRowEnabled={true}
                rowAlternationEnabled={true}
                height='100%'
                // defaultSelectedRowKeys={ids[ids.length - 1]}
                defaultExpandedRowKeys={ids}
                // onContentReady={onRowExpanded}
                focusedRowKey={ids[ids.length - 1]}
                // focusStateEnabled={true}
                ref={treeRef}
                parentIdExpr={'parentId'}
                hasItemsExpr={'hasItems'}
                rootValue=''
            >
                <Scrolling mode={'virtual'} preloadEnabled />
                <Column dataField='name' cellTemplate={'cellTemplate'} />
                <Selection />
                <RemoteOperations filtering={true} />
                <SearchPanel visible={true} placeholder={'Søg efter enhed'} width={width} />
                <Template name='cellTemplate' render={cellTemplate} />
            </TreeList>
        </Box>
    );
};

export default OverviewTree;
