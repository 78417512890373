
    const Svg4_05_10 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="340.09 286.58 162.66000000000003 77.11000000000001" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M345.09 358.69L414.08 358.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.08 358.69L400.66 291.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.09 358.69L358.51 291.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 400.66 291.58 A 38.567 38.567 0 1 0 358.51 291.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.28 358.69L453.94 312.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.28 312.7L453.94 358.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.08 358.69L497.75 312.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.08 312.7L497.75 358.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg4_05_10;

    