const SvgCircle = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={16}
        height={16}
        fill='none'
        strokeLinecap='round'
        strokeWidth={8}
        viewBox='0 0 100 100'
        stroke='{{replacement-value-color}}'
    >
        <path d='M62 83a36 36 0 1 1 21-21' />
        <circle cx={50} cy={50} r={7} />
        <circle cx={75} cy={75} r={7} />
    </svg>
);
export default SvgCircle;
