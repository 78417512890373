import { parse, useEnhedId } from './use-enhed-id';
import useEnhedList, { ExtendedISearchEnhed } from './use-enhed-list';

import useHistoryNavigate from './extended-hooks/use-history-navigate';
import { useLocation } from 'react-router-dom';
import useStableFunction from './use-stable-function';

// Exported here for legacy reasons
export { useEnhedId } from './use-enhed-id';

type EnhedType<T> = T extends undefined ? ExtendedISearchEnhed | undefined : ExtendedISearchEnhed;

export const useEnhed = <T extends undefined | unknown = unknown>() => {
    const { navigate } = useHistoryNavigate();
    const location = useLocation();
    const id = useEnhedId();
    const { enhedList } = useEnhedList();
    const enhed = enhedList.find((e) => e.id === id) as EnhedType<T>;

    const updateId = useStableFunction((id: number | string) => {
        // default to 0. This is only the case when path is / before router navigates to /0
        if (typeof id === 'string' && id !== '-0') id = parse(id, 0);
        const currPath = location.pathname + location.search + location.hash;
        const newPath = currPath.replace(/^\/-?\d+/, `/${id}`);
        navigate(newPath);
    });

    const showList = useStableFunction(() => updateId(id === 0 ? '-0' : -id));

    return { id, updateId, enhed, showList, enhedList };
};
