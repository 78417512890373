
    const Svg2_03_07 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="427.85 197.9 10.0 192.36999999999998" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M432.85 202.9L432.85 385.27Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 432.85 266.73 A 45.594 45.594 0 1 0 432.85 321.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_03_07;

    