
    const Svg1_01_25 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="178.1 199.07 414.7099999999999 182.40000000000003" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M269.29 307.51L269.29 376.47Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M269.29 376.47L441.69 376.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.69 376.47L441.69 204.07Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.69 204.07L269.29 204.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M269.29 204.07L269.29 307.51Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M269.29 290.27L183.1 290.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M226.19 268.72L269.29 290.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M269.29 290.27L226.19 311.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M476.17 376.47L522.14 307.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M476.17 307.51L522.14 376.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M541.84 376.47L587.81 307.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M541.84 307.51L587.81 376.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.01 247.17L322.01 333.37Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.01 333.37L371.26 333.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_25;

    