
    const SvgXx_06_05 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="232.32 311.54 309.71 11" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M476.43 316.54L537.03 316.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M237.32 316.54L316.91 316.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 476.43 316.54 A 26.587 26.587 0 0 0 423.26 316.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 370.08 316.54 A 26.587 26.587 0 0 0 316.91 316.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 370.08 316.54 A 26.587 26.587 0 0 0 423.26 316.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_06_05;

    