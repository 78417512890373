import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/** Detects whether the current location matches that given by react-router */
const useSyncUrl = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const routerHref = location.pathname + location.hash + location.search;
        const windowUrl = new URL(window.location.href);
        const windowHref = windowUrl.pathname + windowUrl.hash + windowUrl.search;
        if (routerHref != windowHref) {
            navigate(windowHref);
        }
    }, [location, navigate]);
};

export default useSyncUrl;
