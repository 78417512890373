
    const Svg1_01_17 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="225.68 214.44 271.51 167.67000000000002" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M492.19 356.02L492.19 219.44Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.27 287.73L369.46 284.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.46 284.1L370.04 280.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.04 280.51L370.99 277.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.99 277.01L372.32 273.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.32 273.62L373.99 270.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M373.99 270.4L376 267.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376 267.37L378.32 264.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.32 264.57L380.93 262.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.93 262.03L383.79 259.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.79 259.79L386.87 257.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.87 257.86L390.14 256.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.14 256.27L393.55 255.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.55 255.04L397.08 254.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.08 254.18L400.69 253.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.69 253.69L404.32 253.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.32 253.6L407.94 253.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.94 253.89L411.51 254.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.51 254.56L414.99 255.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.99 255.61L418.34 257.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.34 257.02L421.52 258.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.52 258.78L424.5 260.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.5 260.87L427.23 263.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.23 263.27L429.7 265.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.7 265.94L431.86 268.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.86 268.85L433.71 271.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.71 271.99L435.21 275.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.21 275.3L436.35 278.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.35 278.75L437.12 282.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.12 282.3L437.51 285.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.51 285.91L437.51 289.55Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.51 289.55L437.12 293.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.12 293.16L436.35 296.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.35 296.71L435.21 300.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.21 300.16L433.71 303.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.71 303.47L431.86 306.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.86 306.61L429.7 309.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.7 309.52L427.23 312.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.23 312.19L424.5 314.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.5 314.59L421.52 316.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.52 316.68L418.34 318.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.34 318.44L414.99 319.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.99 319.85L411.51 320.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.51 320.9L407.94 321.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.94 321.57L404.32 321.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.32 321.86L400.69 321.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.69 321.77L397.08 321.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.08 321.28L393.55 320.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.55 320.42L390.14 319.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.14 319.19L386.87 317.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.87 317.6L383.79 315.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.79 315.67L380.93 313.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.93 313.43L378.32 310.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.32 310.89L376 308.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376 308.09L373.99 305.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M373.99 305.06L372.32 301.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.32 301.84L370.99 298.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.99 298.45L370.04 294.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.04 294.95L369.46 291.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.46 291.36L369.27 287.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.41 239.93L403.41 219.44Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.27 253.58L348.78 233.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.61 287.73L335.12 287.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.27 321.88L348.78 342.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.56 321.88L458.04 342.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.56 253.58L458.04 233.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.41 335.53L403.41 356.02Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.21 287.73L471.7 287.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M230.68 377.11L267.1 322.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M230.68 322.47L267.1 377.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M282.71 377.11L319.13 322.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M282.71 322.47L319.13 377.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_17;

    