
    const Svg1_04_02 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="295.6 261.63 261.63 142.88" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M315.32 281.39L386.64 352.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M315.32 352.72L386.64 281.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.41 317.05L401.22 312.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.22 312.6L400.63 308.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.63 308.17L399.65 303.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.65 303.82L398.29 299.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.29 299.57L396.56 295.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.56 295.46L394.47 291.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.47 291.52L392.04 287.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.04 287.77L389.29 284.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.29 284.26L386.25 281" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.25 281L382.92 278.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.92 278.02L379.35 275.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.35 275.35L375.55 273.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.55 273.01L371.56 271.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.56 271.01L367.41 269.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.41 269.37L363.13 268.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.13 268.11L358.76 267.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.76 267.22L354.33 266.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.33 266.73L349.86 266.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.86 266.63L345.41 266.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.41 266.93L341 267.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341 267.62L336.67 268.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.67 268.69L332.46 270.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M332.46 270.15L328.38 271.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.38 271.97L324.49 274.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.49 274.14L320.8 276.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.8 276.65L317.34 279.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M317.34 279.48L314.15 282.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M314.15 282.6L311.25 285.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M311.25 285.98L308.66 289.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M308.66 289.62L306.4 293.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M306.4 293.47L304.49 297.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M304.49 297.5L302.95 301.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M302.95 301.68L301.78 305.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M301.78 305.99L300.99 310.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M300.99 310.38L300.6 314.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M300.6 314.82L300.6 319.29Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M300.6 319.29L300.99 323.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M300.99 323.73L301.78 328.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M301.78 328.12L302.95 332.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M302.95 332.43L304.49 336.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M304.49 336.61L306.4 340.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M306.4 340.64L308.66 344.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M308.66 344.49L311.25 348.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M311.25 348.12L314.15 351.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M314.15 351.51L317.34 354.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M317.34 354.63L320.8 357.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.8 357.46L324.49 359.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.49 359.97L328.38 362.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.38 362.14L332.46 363.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M332.46 363.96L336.67 365.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.67 365.42L341 366.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341 366.49L345.41 367.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.41 367.18L349.86 367.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.86 367.48L354.33 367.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.33 367.38L358.76 366.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.76 366.88L363.13 366" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.13 366L367.41 364.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.41 364.74L371.56 363.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.56 363.1L375.55 361.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.55 361.1L379.35 358.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.35 358.75L382.92 356.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.92 356.08L386.25 353.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.25 353.11L389.29 349.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.29 349.85L392.04 346.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.04 346.34L394.47 342.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.47 342.59L396.56 338.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.56 338.65L398.29 334.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.29 334.54L399.65 330.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.65 330.29L400.63 325.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.63 325.93L401.22 321.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.22 321.51L401.41 317.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.59 399.51L475.38 318.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.59 318.82L475.38 399.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M498.44 399.51L552.23 318.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M498.44 318.82L552.23 399.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_04_02;

    