
    const Svg1_01_35 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="332.28 236.76 148.15000000000003 194.21000000000004" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M383.33 379.92L475.43 425.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M475.43 425.97L475.43 241.76Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.33 287.81L337.28 287.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M337.28 379.92L383.33 379.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M337.28 287.81L337.28 379.92Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.33 287.81L475.43 241.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.33 379.92L383.33 287.81Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_35;

    