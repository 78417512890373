
    const Svg2_06_08 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="283.2 235.48 291.07 106.59" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M529.33 288.78L529.13 293.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M529.13 293.11L528.55 297.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M528.55 297.41L527.59 301.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M527.59 301.64L526.25 305.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M526.25 305.76L524.54 309.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M524.54 309.75L522.49 313.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M522.49 313.57L520.1 317.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M520.1 317.19L517.39 320.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M517.39 320.58L514.39 323.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M514.39 323.72L511.13 326.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M511.13 326.57L507.62 329.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M507.62 329.12L503.89 331.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M503.89 331.35L499.98 333.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M499.98 333.23L495.92 334.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M495.92 334.75L491.74 335.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M491.74 335.91L487.47 336.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M487.47 336.68L483.15 337.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M483.15 337.07L478.81 337.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.81 337.07L474.49 336.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M474.49 336.68L470.23 335.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.23 335.91L466.04 334.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.04 334.75L461.98 333.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.98 333.23L458.07 331.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.07 331.35L454.35 329.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.35 329.12L450.84 326.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.84 326.57L447.57 323.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.57 323.72L444.58 320.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.58 320.58L441.87 317.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.87 317.19L439.48 313.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.48 313.57L437.43 309.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.43 309.75L435.72 305.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.72 305.76L434.38 301.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.38 301.64L433.42 297.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.42 297.41L432.83 293.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.83 293.11L432.64 288.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.64 288.78L432.83 284.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.83 284.44L433.42 280.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.42 280.14L434.38 275.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.38 275.91L435.72 271.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.72 271.79L437.43 267.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.43 267.8L439.48 263.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.48 263.98L441.87 260.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.87 260.36L444.58 256.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.58 256.97L447.57 253.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.57 253.83L450.84 250.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.84 250.98L454.35 248.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.35 248.43L458.07 246.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.07 246.2L461.98 244.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.98 244.32L466.04 242.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.04 242.8L470.23 241.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.23 241.64L474.49 240.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M474.49 240.87L478.81 240.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.81 240.48L483.15 240.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M483.15 240.48L487.47 240.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M487.47 240.87L491.74 241.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M491.74 241.64L495.92 242.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M495.92 242.8L499.98 244.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M499.98 244.32L503.89 246.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M503.89 246.2L507.62 248.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M507.62 248.43L511.13 250.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M511.13 250.98L514.39 253.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M514.39 253.83L517.39 256.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M517.39 256.97L520.1 260.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M520.1 260.36L522.49 263.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M522.49 263.98L524.54 267.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M524.54 267.8L526.25 271.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M526.25 271.79L527.59 275.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M527.59 275.91L528.55 280.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M528.55 280.14L529.13 284.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M529.13 284.44L529.33 288.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.89 288.78L384.7 293.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.7 293.11L384.12 297.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.12 297.41L383.15 301.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.15 301.64L381.81 305.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.81 305.76L380.11 309.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.11 309.75L378.05 313.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.05 313.57L375.66 317.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.66 317.19L372.96 320.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.96 320.58L369.96 323.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.96 323.72L366.69 326.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.69 326.57L363.18 329.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.18 329.12L359.46 331.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.46 331.35L355.55 333.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.55 333.23L351.49 334.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M351.49 334.75L347.31 335.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M347.31 335.91L343.04 336.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.04 336.68L338.72 337.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M338.72 337.07L334.38 337.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M334.38 337.07L330.06 336.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.06 336.68L325.79 335.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M325.79 335.91L321.61 334.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M321.61 334.75L317.55 333.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M317.55 333.23L313.64 331.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M313.64 331.35L309.92 329.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M309.92 329.12L306.41 326.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M306.41 326.57L303.14 323.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M303.14 323.72L300.14 320.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M300.14 320.58L297.44 317.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M297.44 317.19L295.05 313.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M295.05 313.57L292.99 309.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M292.99 309.75L291.29 305.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M291.29 305.76L289.95 301.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M289.95 301.64L288.98 297.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M288.98 297.41L288.4 293.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M288.4 293.11L288.2 288.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M288.2 288.78L288.4 284.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M288.4 284.44L288.98 280.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M288.98 280.14L289.95 275.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M289.95 275.91L291.29 271.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M291.29 271.79L292.99 267.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M292.99 267.8L295.05 263.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M295.05 263.98L297.44 260.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M297.44 260.36L300.14 256.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M300.14 256.97L303.14 253.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M303.14 253.83L306.41 250.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M306.41 250.98L309.92 248.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M309.92 248.43L313.64 246.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M313.64 246.2L317.55 244.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M317.55 244.32L321.61 242.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M321.61 242.8L325.79 241.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M325.79 241.64L330.06 240.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.06 240.87L334.38 240.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M334.38 240.48L338.72 240.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M338.72 240.48L343.04 240.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.04 240.87L347.31 241.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M347.31 241.64L351.49 242.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M351.49 242.8L355.55 244.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.55 244.32L359.46 246.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.46 246.2L363.18 248.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.18 248.43L366.69 250.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.69 250.98L369.96 253.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.96 253.83L372.96 256.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.96 256.97L375.66 260.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.66 260.36L378.05 263.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.05 263.98L380.11 267.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.11 267.8L381.81 271.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.81 271.79L383.15 275.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.15 275.91L384.12 280.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.12 280.14L384.7 284.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.7 284.44L384.89 288.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M332.92 288.78L569.27 288.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_06_08;

    