import { IFile, confirmDelete, getFileName } from './common';
import { useEffect, useRef, useState } from 'react';

import Api from '../../shared/networking/api';
import Box from '@mui/material/Box/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Grid from '@mui/material/Grid/Grid';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import Skeleton from '@mui/material/Skeleton/Skeleton';
import { SquareGridItem } from '../mui/styled-mui';
import Stack from '@mui/material/Stack/Stack';
import { downloadToUser } from '../../shared/networking/download';
import styled from '@mui/styles/styled/styled';
import { unstable_batchedUpdates } from 'react-dom';
import useImageFetch from '../../shared/hooks/fetch-hooks/use-image-fetch';
import useSeconds from '../../shared/hooks/use-seconds';

//#region Buttons

const ImageActionButton = styled(LoadingButton)(() => ({
    marginLeft: '12px',
    marginTop: '12px',
}));

interface DeleteButtonProps {
    url: string;
    onDeleted?(): void;
}

const DeleteButton = (props: DeleteButtonProps) => {
    const { url, onDeleted } = props;
    const [deleting, setDeleting] = useState(false);
    const [error, setError] = useState<boolean>();
    const [seconds, start, reset] = useSeconds(3);

    useEffect(() => {
        if (seconds === 0) {
            if (error === true) {
                reset();
                setError(undefined);
            } else if (error === false) {
                onDeleted?.();
            }
        }
    }, [seconds, onDeleted, error, reset]);

    const onClick = async () => {
        if (error === undefined) {
            if (await confirmDelete('image')) {
                setDeleting(true);
                const response = await Api.fetch(url, { method: 'DELETE' });
                unstable_batchedUpdates(() => {
                    setDeleting(false);
                    setError(!response.ok);
                    start();
                });
            }
        }
    };

    return (
        <ImageActionButton
            size='small'
            variant={'contained'}
            loading={deleting}
            startIcon={error !== undefined ? error ? <ErrorOutlineIcon /> : undefined : <DeleteIcon />}
            color={error !== undefined ? (error ? 'error' : 'success') : 'error'}
            onClick={onClick}
        >
            {error !== undefined
                ? error
                    ? `Fejl. Prøv igen om (${seconds})`
                    : `Billedet blev slettet (${seconds})`
                : 'Slet'}
        </ImageActionButton>
    );
};

interface DownloadButtonProps {
    dataUrl: string; // Dataurl, ikke weburl
    fileName: string;
}

const DownloadButton = (props: DownloadButtonProps) => {
    const [seconds, start, reset, , isPaused] = useSeconds(5);

    useEffect(() => {
        if (seconds === 0) {
            reset();
            // setError(undefined);
        }
    }, [seconds, reset]);

    const onClick = async () => {
        start();
        await downloadToUser(props.dataUrl, props.fileName);
    };

    return (
        <ImageActionButton
            size='small'
            variant={'contained'}
            startIcon={<DownloadIcon />}
            color={'primary'}
            onClick={onClick}
            disabled={!isPaused}
        >
            Download
        </ImageActionButton>
    );
};

//#endregion Buttons

interface ClickFillGridImageProps {
    file: IFile;
    onDeleted?(): void;
    disableDelete?: boolean;
    disableDownload?: boolean;
}

export const ImageGridItem = (props: ClickFillGridImageProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const renderedGridItem = useRef<HTMLDivElement>(null);

    const [image, isLoading, isError] = useImageFetch(props.file.url);
    const hide = isError || isDeleted;

    const onDeleted = () => {
        setIsDeleted(true);
        props.onDeleted?.();
    };

    const open = () => {
        if (image !== undefined) setIsOpen(true);
    };

    const close = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        renderedGridItem.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
    }, [isOpen]);

    if (hide) return null;

    if (isOpen)
        return (
            <Grid
                key={`img-${props.file.id}`}
                ref={renderedGridItem}
                item
                xs={12}
                sx={(theme) => ({
                    padding: '8px !important',
                    transition: theme.transitions.create('all', {
                        easing: theme.transitions.easing.easeInOut,
                        duration: '1s',
                    }),
                })}
            >
                <Box sx={{ position: 'relative' }}>
                    <img
                        onClick={close}
                        alt={props.file.alt ?? props.file.name}
                        style={{ width: '100%' }}
                        src={image}
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: '12px',
                        }}
                    >
                        {!props.disableDownload && (
                            <DownloadButton
                                dataUrl={image ?? ''}
                                fileName={getFileName(props.file, `CT-Image-${props.file.id}`)}
                            />
                        )}
                        {!props.disableDelete && <DeleteButton url={props.file.url} onDeleted={onDeleted} />}
                    </Box>
                </Box>
            </Grid>
        );

    return (
        <SquareGridItem
            key={`img-${props.file.id}`}
            ref={renderedGridItem}
            item
            xs={4}
            sm={3}
            md={2}
            sx={(theme) => ({
                transition: theme.transitions.create('all', {
                    easing: theme.transitions.easing.easeInOut,
                    duration: '1s',
                }),
            })}
        >
            <Box
                onClick={open}
                sx={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    padding: 1,
                }}
            >
                {isLoading ? (
                    <Stack spacing={1} sx={{ width: 1 }}>
                        <Skeleton variant='text' />
                        <Skeleton variant='circular' width={40} height={40} />
                        <Skeleton variant='text' />
                        <Skeleton variant='text' />
                    </Stack>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow: 'hidden',
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <img
                            src={image}
                            alt={props.file.alt ?? props.file.name}
                            style={{
                                minHeight: '100%',
                                minWidth: '100%',
                                objectFit: 'cover',
                                overflow: 'hidden',
                            }}
                        />
                    </Box>
                )}
            </Box>
        </SquareGridItem>
    );
};

export interface IImageGridProps {
    files: IFile[];
    disableDelete?: boolean;
}

const ImageGrid = (props: IImageGridProps) => {
    return (
        <Grid container spacing={2} sx={{ paddingLeft: '8px' }}>
            {props.files.map((file, index) => (
                <ImageGridItem file={file} key={`ImageGridItem${index}`} disableDelete={props.disableDelete} />
            ))}
        </Grid>
    );
};

export default ImageGrid;
