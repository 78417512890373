
const SvgTank = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 100 100" fill="none">
        <g filter="url(#filter0_d_350_1072)">
            <circle cx="50" cy="44" r="40" fill='{{replacement-value-color}}' />
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M34.6647 21.197C32.996 21.197 31.5971 22.5626 31.5971 24.3028V66.797H63.5971V24.3028C63.5971 22.5626 62.1981 21.197 60.5295 21.197H34.6647ZM64.5971 24.3028C64.5971 22.0878 62.8015 20.247 60.5295 20.247H34.6647C32.3927 20.247 30.5971 22.0878 30.5971 24.3028V66.797H28.0971C27.8209 66.797 27.5971 67.0097 27.5971 67.272C27.5971 67.5344 27.8209 67.747 28.0971 67.747H67.0971C67.3732 67.747 67.5971 67.5344 67.5971 67.272C67.5971 67.0097 67.3732 66.797 67.0971 66.797H64.5971V56.6866C65.5821 58.1938 67.2104 59.197 69.0971 59.197C72.1954 59.197 74.5971 56.4912 74.5971 53.2912V43.997H76.0971C76.3732 43.997 76.5971 43.7844 76.5971 43.522V34.972C76.5971 34.7097 76.3732 34.497 76.0971 34.497L74.5971 34.497C74.5971 30.6018 72.667 27.2655 69.3186 24.8732C69.0979 24.7155 68.7845 24.7577 68.6185 24.9673C68.4525 25.177 68.4969 25.4748 68.7176 25.6325C71.8429 27.8653 73.5971 30.9323 73.5971 34.497L70.0971 34.497C69.8209 34.497 69.5971 34.7097 69.5971 34.972V43.522C69.5971 43.7844 69.8209 43.997 70.0971 43.997H73.5971V53.2912C73.5971 56.09 71.5215 58.247 69.0971 58.247C66.6726 58.247 64.5971 56.09 64.5971 53.2912V24.3028ZM75.5971 43.047V35.447H70.5971V43.047H75.5971ZM34.5971 27.372C34.5971 27.1097 34.8209 26.897 35.0971 26.897H60.0971C60.3732 26.897 60.5971 27.1097 60.5971 27.372V42.572C60.5971 42.8344 60.3732 43.047 60.0971 43.047H35.0971C34.8209 43.047 34.5971 42.8344 34.5971 42.572V27.372ZM35.5971 27.847V42.097H59.5971V27.847H35.5971Z" fill='white' stroke='white' strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
        <defs>
            <filter id="filter0_d_350_1072" x="0" y="0" width="100" height="100" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_350_1072" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_350_1072" result="shape" />
            </filter>
        </defs>
    </svg>
);
export default SvgTank;


