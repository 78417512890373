
const Svg1_07_04 = () => (
    <svg width="30" height="30" viewBox="0 0 1024 768" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" strokeLinecap="round" strokeLinejoin="round" fillRule="evenodd" xmlSpace="preserve" >
        <defs >
            <clipPath id="clipId0" >
                <path d="M0,768 1024,768 1024,0 0,0 z" />
            </clipPath>
        </defs>
        <g clipPath="url(#clipId0)" fill="none" stroke='{{replacement-value-color}}' strokeWidth="0.1" />
        <g clipPath="url(#clipId0)" fill="none" stroke='{{replacement-value-color}}' strokeWidth="0.25" >
            <polyline points="10.0392,634.98 1013.96,634.98 1013.96,133.02 10.0392,133.02 10.0392,634.98 " />
        </g>
        <g clipPath="url(#clipId0)" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0" >
            <text transform="matrix(150.588 0 -0 150.588 567.244 509.49)" fontFamily="Arial,&apos;sans-serif&apos;" fontSize="1.39636" >
                TCP</text>
            <text transform="matrix(250.98 0 -0 250.98 463.293 509.49)" fontFamily="Arial,&apos;sans-serif&apos;" fontSize="1.39636" >/</text>
            <text transform="matrix(150.588 0 -0 150.588 45.1804 404.221)" fontFamily="Arial,&apos;sans-serif&apos;" fontSize="1.39636" >
                XX</text>
        </g>
    </svg>
);
export default Svg1_07_04;


