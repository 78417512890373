
    const Svg3_07_01 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="320.8 258.52 140.06 96.70000000000005" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M325.8 350.22L455.86 350.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.86 350.22L455.86 263.52Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.86 263.52L325.8 263.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M325.8 263.52L325.8 350.22Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M357.21 324.21L370.42 289.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.42 289.53L383.63 324.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.16 312.65L378.68 312.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.54 324.21L393.54 289.53Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.54 289.53L405.1 289.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.1 289.53L410.06 291.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.06 291.18L413.36 294.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.36 294.48L415.01 297.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.01 297.79L416.66 302.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.66 302.74L416.66 311Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.66 311L415.01 315.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.01 315.95L413.36 319.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.36 319.26L410.06 322.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.06 322.56L405.1 324.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.1 324.21L393.54 324.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg3_07_01;

    