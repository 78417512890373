
    const Svg2_03_02 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="312.01 229.46 182.19 163.65" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M364.84 388.11L364.84 311.58Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.37 388.11L441.37 311.58Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 317.01 234.46 A 86.093 86.093 0 0 0 489.2 234.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M317.01 234.46L489.2 234.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_03_02;

    