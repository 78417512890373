import CreateLayerIcon, { FullLayerIcon } from './create-layer-icon';
import { DragBox, Interaction } from 'ol/interaction';
import { IAutoFormHandle, IGrupper } from '../../../../auto-form/v1';
import Restricted, { UserPermissions } from '../../../../restricted';
import { Tools, addLayerInteractions, getDragboxFeatures, shapeTypes } from '../../EditingControl';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { getArea, getLength } from 'ol/sphere';
import { map, polyLayerStyle, selectedStyle } from '../..';
import useTContext, { TProvider } from '../../../../../shared/contexts/t-context';

import Api from '../../../../../shared/networking/api';
import AutoForm from '../../../../auto-form/v1/auto-form';
import { BaseIconId } from '../../SVG';
import Button from '@mui/material/Button';
import { BygLayer } from '../../deprecated/BygLayer';
import CTLayer from '../../CTLayer';
import CancelIcon from '@mui/icons-material/Cancel';
import { CenterCenterBox } from '../../../../mui/styled-mui';
import Checkbox from '@mui/material/Checkbox';
import Circle from 'ol/geom/Circle';
import Collapse from '@mui/material/Collapse';
import { ControlHandler } from '../../ControlHandler';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { EnhLayer } from '../../deprecated/EnhLayer';
import { EventTypes } from 'ol/Observable';
import { EventsKey } from 'ol/events';
import Feature from 'ol/Feature';
import { FeatureProperty } from '../../feature-utility';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { ICreateGui } from './create-gui';
import { IDictionary } from '../../../../../shared/utils/types';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { isRepresentationLayer, Layers, RepresentationLayer } from '../../LayerMenuControl';
import LoadingScreen from '../../../../../screens/loading';
import Point from 'ol/geom/Point';
import Popup from 'devextreme-react/popup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import SaveIcon from '@mui/icons-material/Save';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { SaveStatus } from '../../ControlHandlerUtils';
import { Scrollable } from '../../../../misc/flex';
import { SqlLayer } from '../../deprecated/SqlLayer';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import Stack from '@mui/material/Stack';
import { Fill, Style } from 'ol/style';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { isMobile } from 'react-device-detect';
import { platformModifierKeyOnly } from 'ol/events/condition';
import { run } from '../../../../../shared/utils/utils';
import { toWkt } from '../../WktIEControl';
import { useCTEffect } from '../../../../../shared/hooks/use-ct';
import useCentralizedSnackbar from '../../../../../shared/hooks/redux-use-centralized-snackbar';
import useFetch from '../../../../../shared/hooks/fetch-hooks/use-fetch';
import useShiftRooms from './use-shift-rooms.hook';

enum Modes {
    Edit = 'edit',
    EditSchema = 'editSchema',
    Delete = 'delete',
    Select = 'select',
    Cancel = 'cancel',
    Save = 'save',
    CopySkema = 'copySkema',
}

enum States {
    Closed,
    Open,
    Editing,
}

interface IRef {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setChecked: React.Dispatch<React.SetStateAction<boolean>>;
    setShowArrow: React.Dispatch<React.SetStateAction<boolean>>;
    setEditData: React.Dispatch<React.SetStateAction<{ saveArray: IDictionary[]; movedFeatures: Feature[] }>>;
}

// Just to make sure that old things still work. The new stuff only uses the shapetype
const getLayerSvg = ({ layers, selectedKey }: { layers: Layers; selectedKey: string }) => {
    const urlLayers = layers.getUrlLayers();
    const shapeType: string | undefined = (urlLayers[selectedKey].layer as CTLayer).options?.shapeType;
    const color = (urlLayers[selectedKey].layer as CTLayer).options.color ?? '';
    if (selectedKey.toLowerCase().startsWith('del'.toLowerCase()))
        return CreateLayerIcon(
            (urlLayers[selectedKey].layer as SqlLayer).baseIconId,
            (urlLayers[selectedKey].layer as SqlLayer).color
        );
    else if (selectedKey.toLowerCase().startsWith('byg'.toLowerCase()))
        return CreateLayerIcon(
            (urlLayers[selectedKey].layer as BygLayer).baseIconID,
            (urlLayers[selectedKey].layer as BygLayer).color
        );
    else if (selectedKey.toLowerCase().startsWith('enh'.toLowerCase()))
        return CreateLayerIcon(
            (urlLayers[selectedKey].layer as EnhLayer).baseIconID,
            (urlLayers[selectedKey].layer as EnhLayer).color
        );
    else if (shapeType === shapeTypes.Point || shapeType === shapeTypes.Line || shapeType === shapeTypes.Polygon)
        return CreateLayerIcon(shapeType, color);
    else {
        return CreateLayerIcon(BaseIconId.Default, '#56aaff');
    }
};

const saveData = async (
    saveArray: IDictionary[],
    movedFeatures: Feature[],
    selectedLayer: CTLayer,
    controlHandler: ControlHandler
) => {
    const url = selectedLayer.options.url;
    if (!url) return;

    let success = true;
    if (saveArray.length > 0) {
        const response = await Api.post(url, saveArray);
        success = success && Api.ok(response);
    }

    const moved = movedFeatures.map((f) => {
        const data = f.get(FeatureProperty.Data);
        const geo = toWkt(f);
        return {
            ...data,
            geometry: geo,
        };
    });
    if (moved.length > 0) {
        const response = await Api.put(url, moved);
        success = success && Api.ok(response);
    }
    if (!success) {
        alert('Der skete en fejl');
    }
    controlHandler.saveStatus = SaveStatus.Start;
    //#endregion
};

// Forwardref to be able to fx close the old menu, when a new one is opened
// Memo to avoid rerendering
const CreateLayerEditMenu = forwardRef<
    IRef,
    ICreateGui & {
        selectedKey: string;
        setCurr: (obj: { index: string; state: States }) => void;
        floor: string;
        theme: Themes;
    }
>(({ layers, controlHandler, selectedKey, setCurr, floor, theme }, ref) => {
    const stackRef = useRef();
    const [open, setOpen] = useState(false);
    const [showArrow, setShowArrow] = useState(true);
    const [selectedFeatures, setSelectedFeatures] = useState<Feature[] | null>(null);
    // const { hasPermissions } = usePermissions();

    // editdata gets data, from the setEditData function exported below, which is triggered
    // in EditingControl.ts whenever a new feature is placed or a feature is moved or clicked
    // This array gives is used to give label to everything
    const [editData, setEditData] = useState<{ saveArray: IDictionary[]; movedFeatures: Feature[] }>({
        saveArray: [],
        movedFeatures: [],
    });

    const iLayer = layers.getUrlLayers()[selectedKey];
    const name = iLayer.displayName ?? selectedKey;
    const layer = iLayer.layer;
    const [showPopup, setShowPopup] = useState(false);

    const { value: mode, setValue: setMode } = useTContext<Modes>();
    const [checked, setChecked] = useState(layer.getVisible());
    const [copyFrom, setCopyFrom] = useState<Feature | null>(null);

    useImperativeHandle(ref, () => ({
        setOpen: setOpen,
        setChecked: setChecked,
        setShowArrow: setShowArrow,
        setEditData: setEditData,
    }));

    useCTEffect(() => {
        if (!open) {
            setMode(() => Modes.Select);
        }
    }, [open]);

    useCTEffect(() => {
        if (controlHandler.saveStatus === SaveStatus.Labels) {
            console.log('saving');
            saveData(editData.saveArray, editData.movedFeatures, layer, controlHandler);
            setEditData({ saveArray: [], movedFeatures: [] });
        }
    }, [editData]);

    useCTEffect(() => {
        if (selectedFeatures?.length == 1 && mode === Modes.EditSchema) {
            setShowPopup(true);
        } else if (mode === Modes.CopySkema && copyFrom === null && selectedFeatures?.length == 1) {
            setCopyFrom(selectedFeatures[0]);
        }
    }, [selectedFeatures]);

    useEffect(() => {
        layer.setVisible(checked);
    }, [checked, layer]);

    const onChange = (checked: boolean, selectedKey: string, e?: React.SyntheticEvent<Element, Event>) => {
        e?.stopPropagation();
        setChecked((prev) => !prev);
    };

    const onOpenClick = () => {
        if (!open) {
            controlHandler.activeILayer = {
                ...iLayer,
                extra: (controlHandler.activeILayer.extra ?? 0) + 1,
            };
            layer.putOnTop(layers);
            if (!layer.getVisible()) {
                layer.setVisible(true);
                setChecked(true);
            }
            setCurr({ index: selectedKey, state: States.Open });
        } else {
            setCurr({ index: selectedKey, state: States.Closed });
            setMode(() => Modes.Select);
        }
        setOpen((prev) => !prev);
    };

    const listernerKeys = useRef<EventsKey[]>([]);
    const interactions = useRef<Interaction[]>([]);

    const onToolClicked = (mode: Modes) => {
        setMode(mode);

        // Reset effects of previous mode
        setSelectedFeatures(null);
        setCopyFrom(null);
        listernerKeys.current.forEach((k) => controlHandler.activeSelectTool?.un([k.type as EventTypes], k.listener));
        interactions.current.forEach((i) => map?.removeInteraction(i));
        listernerKeys.current = [];
        interactions.current = [];

        switch (mode) {
            case Modes.CopySkema:
                setCurr({ index: selectedKey, state: States.Open });
                controlHandler.overrideActiveTool(Tools.Select);
                const selectC = controlHandler.activeSelectTool;
                if (!selectC) break;

                // Set empty instead of null, to indicate it is active
                setSelectedFeatures([]);
                listernerKeys.current.push(
                    selectC.on('select', (e) => {
                        setSelectedFeatures((fs) => {
                            const selectStyle = selectC.getStyle();
                            if (selectStyle) fs?.forEach((f, index) => {
                                let style = selectStyle
                                if(index === 0) {
                                    style = selectedStyle;
                                    style.setFill(new Fill({ color: 'green' }));
                                }
                                f.setStyle(style);
                            });

                            const onlyOneSelected = e.selected.length === 1;
                            const alreadySelected = onlyOneSelected && fs?.find(f => f.get('data').id === e.selected[0].get('data').id);
                            if (alreadySelected) {
                                return fs;
                            }
                            
                            return [...(fs ?? []), ...e.selected];
                        });
                    })
                );
                break;
            case Modes.EditSchema:
            case Modes.Select:
                setCurr({ index: selectedKey, state: States.Open });
                controlHandler.overrideActiveTool(Tools.Select);
                const select = controlHandler.activeSelectTool;
                if (!select) break;

                // Set empty instead of null, to indicate it is active
                setSelectedFeatures([]);
                listernerKeys.current.push(
                    select.on('select', (e) => {
                        setSelectedFeatures((fs) =>
                            (fs ?? [])
                                .filter((f) => {
                                    if (e.mapBrowserEvent.originalEvent.shiftKey && !e.deselected.includes(f)) {
                                        return true;
                                    }
                                    f.setStyle(); // Clear select style
                                    return false;
                                })
                                .concat(e.selected)
                        );
                    })
                );

                const dragBox = new DragBox({ condition: platformModifierKeyOnly });
                map?.addInteraction(dragBox);
                interactions.current.push(dragBox);

                // TODO: This currently doesn't take rotation into account. See https://openlayers.org/en/latest/examples/box-selection.html
                listernerKeys.current.push(
                    dragBox.on('boxend', () => {
                        const features = getDragboxFeatures(dragBox, layer);
                        const selectStyle = select.getStyle();
                        console.log(selectStyle);
                        if (selectStyle) features.forEach((f) => f.setStyle(selectStyle));
                        setSelectedFeatures((fs) => (fs ?? []).filter((f) => !features.includes(f)).concat(features));
                    })
                );
                // Connect layer events to the local dragBox tool
                // All other interactions are handled by the EditingControl
                addLayerInteractions(dragBox, 'dragBox', layer).forEach((eventKey) =>
                    listernerKeys.current.push(eventKey)
                );

                break;
            case Modes.Edit:
                setMode(Modes.Select);
                setCurr({ index: selectedKey, state: States.Editing });
                // Hide textSVG's and make circles bigger to make editing easier
                if (layer.options.svgString && layer.options.shapeType === shapeTypes.Point) {
                    controlHandler.currentFloor = floor;
                    layer.setStyle((f, r) => {
                        const style = polyLayerStyle(f, r, layer, floor, theme);
                        if (layer.options.shapeType === 'point')
                            style[0] = new Style({
                                image: undefined,
                            });
                        style[1].setGeometry(
                            new Circle((f as Feature<Point>).getGeometry()?.getCoordinates() ?? [], 4 * r)
                        );
                        return style;
                    });
                }
                controlHandler.activeILayer = {
                    ...iLayer,
                    extra: (controlHandler.activeILayer.extra ?? 0) + 1,
                };
                // use the appropriate editing tool
                const shapeType: string | undefined = layer.options.shapeType;
                switch (shapeType) {
                    case shapeTypes.Point:
                        controlHandler.activeToolType = Tools.Point;
                        break;
                    case shapeTypes.Line:
                        controlHandler.activeToolType = Tools.LineString;
                        break;
                    case shapeTypes.Polygon:
                        controlHandler.activeToolType = Tools.Polygon;
                        break;
                }
                break;

            case Modes.Delete:
                setCurr({ index: selectedKey, state: States.Editing });
                // Hide textSVG's and make circles bigger to make editing easier
                if (layer.options.svgString) {
                    layer.setStyle((f, r) => {
                        const style = polyLayerStyle(f, r, layer, floor, theme);
                        style[0] = new Style({
                            image: undefined,
                        });
                        style[1].setGeometry(
                            new Circle((f as Feature<Point>).getGeometry()?.getCoordinates() ?? [], 4 * r)
                        );
                        return style;
                    });
                }
                controlHandler.activeToolType = Tools.Delete;
                break;

            case Modes.Save:
                setCurr({ index: selectedKey, state: States.Closed });
                // use select tool => we are done editing
                controlHandler.activeToolType = Tools.Select;
                setMode(Modes.Select);
                // setOpen(false);
                // SaveStatus.Labels triggers an event in EditingControl.ts which in turn
                // calls the setEditData below, to get the newest data to this file
                // This also triggers an effect above, that saves the data
                controlHandler.saveStatus = SaveStatus.Labels;
                // Reset feature styling
                layer.setStyle((f, r) => polyLayerStyle(f, r, layer, floor, theme));
                break;

            case Modes.Cancel:
                setCurr({ index: selectedKey, state: States.Closed });
                setOpen(false);
                setMode(() => Modes.Select);
                setEditData({ saveArray: [], movedFeatures: [] });
                controlHandler.saveStatus = SaveStatus.Cancel;
                controlHandler.activeToolType = Tools.Select;
                // Reset feature styling
                // if (layer.options.svgString) {
                layer.setStyle((f, r) => polyLayerStyle(f, r, layer, floor, theme));
                // }
                break;
        }
    };

    const saveCopySkema = () => {
        Api.post('/map/featureContentCopy', {
            'SourceId': copyFrom?.get('data').id,
            'Destination': selectedFeatures?.slice(1).map((f) => f.get('data').id),
        })
        onToolClicked(Modes.Cancel);
    };

    const selectedText = run(() => {
        const shapeType: shapeTypes = layer.options.shapeType ?? shapeTypes.Point;

        const unit = run(() => {
            const m: Record<shapeTypes, string> = {
                [shapeTypes.Point]: 'stk',
                [shapeTypes.Line]: 'lbm',
                [shapeTypes.Polygon]: 'm2',
            };
            return m[shapeType] ?? m[shapeTypes.Point];
        });

        const meassure = (features: Feature[]) => {
            const meassureFunctions: Record<shapeTypes, (f: Feature) => number> = {
                [shapeTypes.Point]: () => 1,
                [shapeTypes.Line]: (f) => getLength(f.getGeometry()!),
                [shapeTypes.Polygon]: (f) => getArea(f.getGeometry()!),
            };

            let func = meassureFunctions[shapeType];

            // Handle incorrect shapetype by counting features
            func ??= meassureFunctions[shapeTypes.Point];

            if (!features.reduce) {
                console.error('Features is not an array', features);
                return 0;
            }

            const number = features.reduce((acc, f) => acc + func(f), 0);

            return Math.round(number * 10) / 10;
        };

        const selectedMeassure = selectedFeatures ? meassure(selectedFeatures) : 0;
        const totalMeassure = meassure(layer.getSource()!.getFeatures());

        let text = '';
        if (selectedFeatures) text += `${selectedMeassure} af `;
        text += `${totalMeassure} ${unit}`;
        return text;
    });

    const makePopupTitle = () => {
        if (selectedFeatures?.length != 1) return;
        let label = selectedFeatures?.[0].getProperties().data.label;
        if ((layer.options.shapeType ?? shapeTypes.Point) === shapeTypes.Polygon) {
            label += ` (${getArea(selectedFeatures[0].getGeometry()!).toFixed(1)} m2)`;
        }
        return label;
    };

    const svgText = layer.options.svgString;

    // Enable editing panel if no layer is being edited and floor has no shifted features
    const editEnabled = showArrow && floor !== 'A';

    const onClosePopup = () => {
        setShowPopup(false);
        setSelectedFeatures(null);
        onToolClicked(Modes.Save);
        onToolClicked(Modes.EditSchema);
    };

    return (
        <>
            <Stack
                ref={stackRef}
                key={`stack-${selectedKey}`}
                paddingBottom={'4px'}
                direction={'row'}
                justifyContent={'space-between'}
            >
                <Grid container>
                    <Grid item xs>
                        <FormControlLabel
                            sx={{
                                'width': '100%',
                                '& .MuiCheckbox-root': {
                                    flexGrow: 0,
                                },
                                '& .MuiTypography-root': {
                                    flexGrow: 1,
                                },
                            }}
                            onChange={(e, checked) => onChange(checked, selectedKey, e)}
                            control={
                                <Checkbox
                                    checked={checked}
                                    size='small'
                                    sx={{ padding: 0, paddingLeft: '10px', paddingRight: '4px', paddingTop: '1.5px' }}
                                />
                            }
                            label={
                                <Grid container sx={{ width: '100%' }}>
                                    <Grid item xs='auto'>
                                        <CenterCenterBox>{getLayerSvg({ layers, selectedKey })}</CenterCenterBox>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {/**  sx={{ height: '100%' }} */}
                                        {svgText && <FullLayerIcon layer={layer} />}
                                    </Grid>
                                    <Grid item xs>
                                        <Typography sx={{ margin: 0, wordBreak: 'break-word' }}>{name}</Typography>
                                    </Grid>
                                </Grid>
                            }
                        />
                    </Grid>
                    <Grid item xs={'auto'}>
                        <IconButton
                            size='small'
                            sx={{ padding: 0, visibility: editEnabled ? 'visible' : 'hidden' }}
                            onClick={onOpenClick}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </Grid>
                </Grid>
            </Stack>
            {(editEnabled || open) && (
                <Collapse in={open} className='layer-options-collapse'>
                    <Stack direction={'row'} spacing={1} justifyContent='center' sx={{ pt: '8px', pb: '16px' }}>
                        <Restricted
                            permissions={UserPermissions.Map_Update}
                            renderUnauthorized={
                                <Button
                                    disabled
                                    sx={{ padding: '4px', minWidth: '26px' }}
                                    variant='contained'
                                    size='small'
                                    color={'info'}
                                    aria-label='Rediger'
                                >
                                    <EditIcon />
                                </Button>
                            }
                        >
                            <Tooltip title='Rediger'>
                                <Button
                                    sx={{ padding: '4px', minWidth: '26px' }}
                                    variant={mode === Modes.Edit ? 'outlined' : 'contained'}
                                    size='small'
                                    key='edit'
                                    color={'info'}
                                    onClick={() => onToolClicked(Modes.Edit)}
                                    aria-label='Rediger'
                                    disabled={layer.options.readOnly == 1}
                                >
                                    <EditIcon />
                                </Button>
                            </Tooltip>
                        </Restricted>
                        {layer.options.skema && (
                            <Restricted
                                permissions={UserPermissions.Map_Skema}
                                renderUnauthorized={
                                    <Button
                                        disabled
                                        sx={{ padding: '4px', minWidth: '26px' }}
                                        variant='contained'
                                        size='small'
                                        color={'info'}
                                        aria-label='Skema'
                                    >
                                        <EditNoteIcon />
                                    </Button>
                                }
                            >
                                <Tooltip title='Skema'>
                                    <Button
                                        sx={{ padding: '4px', minWidth: '26px' }}
                                        variant={mode === Modes.EditSchema ? 'outlined' : 'contained'}
                                        size='small'
                                        key='edit'
                                        color={'info'}
                                        onClick={() => onToolClicked(Modes.EditSchema)}
                                        aria-label='Skema'
                                    >
                                        <EditNoteIcon />
                                    </Button>
                                </Tooltip>
                            </Restricted>
                        )}
                        {layer.options.skema && (
                            <Restricted
                                permissions={UserPermissions.Map_Copyskema}
                                renderUnauthorized={
                                    <Button
                                        disabled
                                        sx={{ padding: '4px', minWidth: '26px' }}
                                        variant='contained'
                                        size='small'
                                        color={'info'}
                                        aria-label='Kopier skema'
                                    >
                                        <ContentCopyIcon />
                                    </Button>
                                }
                            >
                                <Tooltip title='Kopier skema'>
                                    <Button
                                        sx={{ padding: '4px', minWidth: '26px' }}
                                        variant={mode === Modes.CopySkema ? 'outlined' : 'contained'}
                                        size='small'
                                        color={'info'}
                                        onClick={() => onToolClicked(Modes.CopySkema)}
                                        aria-label='Kopier skema'
                                    >
                                        <ContentCopyIcon />
                                    </Button>
                                </Tooltip>
                            </Restricted>
                        )}
                        <Restricted
                            permissions={UserPermissions.Map_Delete}
                            renderUnauthorized={
                                <Button
                                    disabled
                                    sx={{ padding: '4px', minWidth: '26px' }}
                                    variant='contained'
                                    size='small'
                                    color={'info'}
                                    aria-label='Slet'
                                >
                                    <DeleteIcon />
                                </Button>
                            }
                        >
                            <Tooltip title='Slet'>
                                <Button
                                    sx={{ padding: '4px', minWidth: '26px' }}
                                    variant={mode === Modes.Delete ? 'outlined' : 'contained'}
                                    size='small'
                                    color={'error'}
                                    onClick={() => onToolClicked(Modes.Delete)}
                                    aria-label='Slet'
                                    disabled={layer.options.readOnly == 1}
                                >
                                    <DeleteIcon />
                                </Button>
                            </Tooltip>
                        </Restricted>
                        <Tooltip title='Gem'>
                            <Button
                                sx={{ padding: '4px', minWidth: '26px' }}
                                variant={'contained'}
                                size='small'
                                key='save'
                                color={'success'}
                                onClick={() => (mode === Modes.CopySkema ? saveCopySkema() : onToolClicked(Modes.Save))}
                                aria-label='Gem'
                            >
                                <SaveIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip title='Optæl / Mål'>
                            <Button
                                sx={{ padding: '4px', minWidth: '26px' }}
                                variant={mode === Modes.Edit ? 'outlined' : 'contained'}
                                size='small'
                                key='save'
                                color={'secondary'}
                                onClick={() => onToolClicked(Modes.Select)}
                                aria-label='Optæl / Mål'
                            >
                                <SquareFootIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip title='Annuller'>
                            <Button
                                sx={{ padding: '4px', minWidth: '26px' }}
                                variant={'contained'}
                                size='small'
                                key='cancel'
                                color={'warning'}
                                onClick={() => onToolClicked(Modes.Cancel)}
                                aria-label='Annuller'
                            >
                                <CancelIcon />
                            </Button>
                        </Tooltip>
                    </Stack>
                    {mode === Modes.CopySkema && selectedFeatures ? (
                        copyFrom ? (
                            <>
                                <Typography justifyContent='center' paragraph pl={2}>
                                    {' '}
                                    Kopierer fra: {selectedFeatures[0].get('data').label}
                                </Typography>
                                <Typography justifyContent='center' paragraph pl={2}>
                                    {' '}
                                    Vælg hvor der skal kopieres til ({selectedFeatures.length - 1} valgte)
                                </Typography>
                            </>
                        ) : (
                            <Typography justifyContent='center' paragraph pl={2}>
                                Vælg hvor der skal kopieres fra
                            </Typography>
                        )
                    ) : (
                        selectedFeatures &&
                        !isMobile &&
                        mode !== Modes.EditSchema && (
                            <Typography justifyContent='center' pl={6}>
                                CTRL + klik + træk for at optælle et område.
                            </Typography>
                        )
                    )}
                    {mode !== Modes.CopySkema && (
                        <Typography justifyContent='center' pl={6} pt={2}>
                            {selectedText}
                        </Typography>
                    )}
                    <Collapse in={true} sx={{ pb: '8px' }}>
                        {editData.saveArray
                            .sort((a, b) => a.label - b.label)
                            .map((f, i) => (
                                <TextField
                                    key={`text-${i}`}
                                    fullWidth
                                    autoComplete={'off'}
                                    label={f.label}
                                    variant='filled'
                                    onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                                        (f.label = e.target.value)
                                    }
                                />
                            ))}
                    </Collapse>
                </Collapse>
            )}
            <Popup
                title={makePopupTitle()}
                visible={showPopup}
                onHiding={onClosePopup}
                dragEnabled={true}
                hideOnOutsideClick
            >
                <>
                    {showPopup ? (
                        <SchemaContent selectedFeatures={selectedFeatures ?? []} onSave={onClosePopup} />
                    ) : (
                        <></>
                    )}
                </>
            </Popup>
        </>
    );
});
CreateLayerEditMenu.displayName = 'CreateLayerEditMenu';

// refs outside of the component, so they are usable in the exported
// function (used in EditingControl to send data from the map to here)
const refs: IDictionary<React.RefObject<IRef>, string> = {};
let currRef: string;
let eChangeFloor: ((floor: string) => void) | undefined;

export const setEditData = (saveArr: IDictionary[], movedFeatures: Feature[]) => {
    refs[currRef]?.current?.setEditData({ saveArray: saveArr, movedFeatures });
};

export const changeFloor = (floor?: string) => {
    if (floor) eChangeFloor?.(floor);
};

/** List of floors. K is Basement, T is roof and A is All, which means A displays a translated view of all floors */
export const floors = ['K', 'S', 'P', '1', '2', '3', '4', '5', '6', '7', 'T', 'A'] as const;

export enum Themes {
    theme0 = 'Ingen lokaler',
    theme1 = 'tema1',
    theme2 = 'tema2',
    theme3 = 'tema3',
    theme4 = 'tema4',
}

const themes: Themes[] = [Themes.theme0, Themes.theme1]; //, Themes.theme2, Themes.theme3, Themes.theme4];
const themeNames: Map<Themes, string> = new Map([
    [Themes.theme0, 'Ingen lokaler'],
    [Themes.theme1, 'Vis lokaler'],
    [Themes.theme2, 'tema2'],
    [Themes.theme3, 'tema3'],
    [Themes.theme4, 'tema4'],
]);

const CreateLayerGui = ({ layers, controlHandler }: ICreateGui) => {
    const urlLayers = layers.getUrlLayers();
    const [curr, setCurr] = useState({ index: '', state: States.Closed });
    // Use changeFloor instead of setFloor
    const [floor, setFloor] = useState('');
    const [layerCollapseOpen, setLayerCollapseOpen] = useState(true);
    const { shiftRooms } = useShiftRooms();
    const [open, setOpen] = useState(true);
    const [theme, setTheme] = useState<Themes>(Themes.theme0);
    const hasLokaler = Object.keys(urlLayers).some((l) => isRepresentationLayer(l, RepresentationLayer.Lokale));

    const changeFloor = (_floor: string, nextTheme?: Themes) => {
        setFloor(_floor);
        const _theme = nextTheme ?? theme;
        if (_theme === Themes.theme0 && _floor !== '' && _floor !== 'A') setTheme(Themes.theme1);

        // Change feature visibility / style
        Object.keys(urlLayers).forEach((l) => {
            const layer = urlLayers[l].layer as CTLayer;
            if (layer.options.type === 'POLY')
                layer.setStyle((feature, resolution) => {
                    const style = polyLayerStyle(feature, resolution, layer, _floor, _theme);
                    return style;
                });
        });
    };

    // Handle shifting of features when floor becoms (A)ll
    useEffect(() => {
        if (floor !== 'A') return;

        // Shift features
        const unshift = shiftRooms();

        // Remove background
        const currentBaseKey = controlHandler.activeBaseILayerKey;
        const labelForNone = document.querySelector(`div.ol-layerControl-content label#none`) as HTMLLabelElement;
        labelForNone.click();

        // Remove changes when unmounting
        return () => {
            unshift?.();
            const labelForCurrent = document.querySelector(
                `div.ol-layerControl-content label#${currentBaseKey}`
            ) as HTMLLabelElement;
            console.log('Label: ', labelForCurrent);
            labelForCurrent.click();
        };
    }, [controlHandler, floor, shiftRooms]);

    eChangeFloor = changeFloor;

    // curr is set everytime a layer is edited
    useEffect(() => {
        currRef = curr.index;
        // when a new one opens, make sure everything else is closed
        // the components are memoed, so this will only impact the open ones
        if (curr.state === States.Open) {
            Object.keys(refs).forEach((key) => {
                if (key !== curr.index) {
                    refs[key].current?.setOpen(false);
                }
            });
        }
        // when editing hide the arrow to expand for all elements, so that only
        // one layer can be edited at a time
        else if (curr.state === States.Editing) {
            Object.keys(refs).forEach((key) => {
                refs[key].current?.setShowArrow(false);
            });
        } else if (curr.state === States.Closed) {
            Object.keys(refs).forEach((key) => {
                refs[key].current?.setShowArrow(true);
            });
        }
    }, [curr]);

    const onChangeTheme = (e: React.ChangeEvent<HTMLInputElement>, val: string) => {
        e.stopPropagation();
        let _floor = floor;
        const _theme = val as Themes;
        setTheme(_theme);
        if (_theme === Themes.theme0) {
            changeFloor('', _theme);
            _floor = '';
        } else if (floor === '') {
            changeFloor('S', _theme);
            _floor = 'S';
        }

        // Change feature visibility / style
        Object.keys(urlLayers).forEach((l) => {
            const layer = urlLayers[l].layer as CTLayer;
            if (layer.options.type === 'POLY')
                layer.setStyle((feature, resolution) => {
                    const style = polyLayerStyle(feature, resolution, layer, _floor, _theme);
                    return style;
                });
        });
    };

    return (
        <>
            {
                /*hasLokaler && */ <>
                    <Stack
                        key={`stack-themes`}
                        direction={'row'}
                        justifyContent={'space-between'}
                        onClick={() => setOpen((prev) => !prev)}
                    >
                        <Typography variant='h6' sx={{ mt: '0px' }}>
                            Lokaler
                        </Typography>
                        <IconButton size='small' sx={{ padding: 0 }}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                        {/* <Switch
                    checked={floor !== ''}
                    onChange={() => changeFloor(floor === '' ? 'S' : '')}
                    disabled={curr.state === States.Editing}
                ></Switch> */}
                    </Stack>
                    <Collapse in={open} className='layer-options-collapse'>
                        <FormControl sx={{ pb: '4px' }}>
                            <RadioGroup value={theme} onChange={onChangeTheme}>
                                {themes.map((t) => {
                                    const name = themeNames.get(t) ?? t;
                                    return (
                                        <FormControlLabel
                                            id={t}
                                            key={t}
                                            value={t}
                                            label={<Typography sx={{ margin: 0 }}>{name}</Typography>}
                                            control={
                                                <Radio
                                                    size='small'
                                                    sx={{
                                                        padding: 0,
                                                        paddingLeft: '10px',
                                                        paddingRight: '4px',
                                                        paddingTop: '1.5px',
                                                    }}
                                                />
                                            }
                                        />
                                    );
                                })}
                            </RadioGroup>
                        </FormControl>
                    </Collapse>
                    <Stack
                        direction={'row'}
                        spacing={0.5}
                        sx={{
                            border: 'solid 1px',
                            borderColor: 'grey',
                            borderRadius: '4px',
                            width: 'fit-content',
                            mt: '4px',
                            height: '30px',
                            p: '2px',
                        }}
                    >
                        {floors.map((f) => {
                            return (
                                <Button
                                    key={f}
                                    variant={floor === f ? 'contained' : 'text'}
                                    disabled={curr.state === States.Editing}
                                    size='small'
                                    sx={{ minWidth: '10px' }}
                                    onClick={() => changeFloor(f)}
                                >
                                    {f}
                                </Button>
                            );
                        })}
                    </Stack>
                </>
            }
            <Stack
                key={`stack-baggrundskort`}
                direction={'row'}
                justifyContent={'space-between'}
                onClick={() => setLayerCollapseOpen((o) => !o)}
            >
                <Typography variant='h6'>Valgte lag</Typography>
                <IconButton size='small' sx={{ padding: 0 }}>
                    {layerCollapseOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </Stack>
            <Collapse in={layerCollapseOpen} className='layer-options-collapse'>
                {Object.keys(urlLayers).map((l, index) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const ref = useRef<IRef>(null);
                    refs[l] = ref;
                    return (
                        <TProvider defaultValue={Modes.Select} key={l}>
                            <div style={{ background: index % 2 ? '#f5f5f5' : 'white' }}>
                                <CreateLayerEditMenu
                                    ref={ref}
                                    setCurr={setCurr}
                                    layers={layers}
                                    controlHandler={controlHandler}
                                    selectedKey={l}
                                    floor={floor}
                                    theme={theme}
                                />
                            </div>
                        </TProvider>
                    );
                })}
            </Collapse>
        </>
    );
};

const SchemaButton = ({ onClick }: { onClick: () => void }) => {
    return (
        <>
            <Button
                type='submit'
                name='save'
                value='save'
                variant='contained'
                color='info'
                sx={(theme) => ({
                    transition: theme.transitions.create('all', {
                        easing: theme.transitions.easing.easeInOut,
                        duration: '1s',
                    }),
                    width: '100%',
                    maxWidth: '200px',
                    pointerEvents: 'all',
                })}
                onClick={() => onClick()}
            >
                <SaveIcon />
            </Button>
        </>
    );
};

const SchemaContent = ({ selectedFeatures, onSave }: { selectedFeatures: Feature[]; onSave: () => void }) => {
    const featureId = selectedFeatures[0].getId();
    const dataRef = useRef<IAutoFormHandle>(null);
    const { enqueueSnackbar } = useCentralizedSnackbar();
    const [data, isLoading] = useFetch<IGrupper[]>(`/Map/Feature/${featureId}`);
    if (isLoading) return <LoadingScreen />;
    console.log(data);
    const save = async () => {
        const data = new FormData(dataRef.current?.element);
        // for (const [key, value] of data.entries()) {
        //     console.log(key, value);
        // }
        const response = await Api.put(`/Map/Feature/${featureId}`, data);
        if (!Api.ok(response)) {
            enqueueSnackbar('Der skete en fejl', { variant: 'error' });
        }
        onSave();
    };
    // }]
    return (
        <Scrollable
            footer={
                <CenterCenterBox>
                    <SchemaButton onClick={save} />
                </CenterCenterBox>
            }
        >
            <AutoForm
                grupper={data}
                onSubmit={() => {}}
                onFieldDataChanged={(data) => console.log(data)}
                onCancel={() => {}}
                id={featureId?.toString()}
                useDxForm
                labelMode='floating'
                ref={dataRef}
            />
        </Scrollable>
    );
};

export default CreateLayerGui;
