
    const SvgXx_06_15 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="529.38 255.47000000000003 50.49000000000001 50.51999999999998" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M574.87 280.73L574.67 283.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M574.67 283.55L574.08 286.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M574.08 286.32L573.11 288.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M573.11 288.98L571.79 291.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M571.79 291.47L570.12 293.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M570.12 293.76L568.16 295.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M568.16 295.79L565.93 297.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M565.93 297.53L563.48 298.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M563.48 298.95L560.86 300.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M560.86 300.01L558.12 300.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M558.12 300.69L555.3 300.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M555.3 300.99L552.48 300.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M552.48 300.89L549.69 300.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M549.69 300.4L547 299.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M547 299.52L544.46 298.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M544.46 298.28L542.12 296.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M542.12 296.7L540.02 294.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M540.02 294.81L538.2 292.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M538.2 292.64L536.7 290.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M536.7 290.25L535.55 287.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M535.55 287.66L534.77 284.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M534.77 284.95L534.38 282.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M534.38 282.14L534.38 279.32Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M534.38 279.32L534.77 276.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M534.77 276.52L535.55 273.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M535.55 273.8L536.7 271.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M536.7 271.22L538.2 268.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M538.2 268.82L540.02 266.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M540.02 266.65L542.12 264.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M542.12 264.76L544.46 263.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M544.46 263.18L547 261.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M547 261.94L549.69 261.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M549.69 261.06L552.48 260.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M552.48 260.57L555.3 260.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M555.3 260.47L558.12 260.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M558.12 260.77L560.86 261.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M560.86 261.45L563.48 262.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M563.48 262.51L565.93 263.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M565.93 263.93L568.16 265.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M568.16 265.67L570.12 267.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M570.12 267.7L571.79 269.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M571.79 269.99L573.11 272.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M573.11 272.49L574.08 275.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M574.08 275.14L574.67 277.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M574.67 277.91L574.87 280.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_06_15;

    