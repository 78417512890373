import { ControlHandler } from '../../ControlHandler';
import { ControlNames } from '../../../interfaces';
import { ICTControl } from '../../CTControl';
import PlaygroundGui from './content';
import ReactControl from '../react-control-parent';
import { createRoot } from 'react-dom/client';
import { staticImplements } from '../../../../../shared/utils/decorators';

/**
 * Playground control made for test of temporary concepts before they're made into actual controls
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 */
@staticImplements<ICTControl>()
export class PlaygroundControl extends ReactControl {
    public static readonly ControlName = ControlNames.Playground;

    constructor(controlHandler: ControlHandler) {
        const container = document.createElement('div');
        super(controlHandler, {}, { element: container });
        this.renderGui(container);
    }

    private renderGui(container: HTMLDivElement) {
        container.id = PlaygroundControl.ControlName + '-root';
        const root = createRoot(container);
        root.render(<PlaygroundGui controlHandler={this.controlHandler} />);
    }
}
