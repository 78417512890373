
    const SvgXx_07_05 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="280.02 226.33 192.0 193.66" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M391.57 343.74L391.57 374.69Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.57 405.64L391.57 405.64 383.19 374.84 399.95 374.84 391.57 405.64Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M391.57 335.36L391.57 304.4Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.57 273.45L391.57 273.45 399.95 304.26 383.19 304.26 391.57 273.45Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M426.71 339.55L457.66 339.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.76 339.55L395.76 339.55 426.57 331.16 426.57 347.93 395.76 339.55Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M356.43 339.55L325.47 339.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.38 339.55L387.38 339.55 356.57 347.93 356.57 331.16 387.38 339.55Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M467.02 339.55L466.83 344.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.83 344.99L466.24 350.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.24 350.41L465.26 355.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M465.26 355.77L463.9 361.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M463.9 361.04L462.16 366.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M462.16 366.21L460.05 371.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.05 371.23L457.59 376.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.59 376.09L454.78 380.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.78 380.76L451.64 385.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.64 385.21L448.19 389.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M448.19 389.43L444.44 393.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.44 393.38L440.42 397.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.42 397.06L436.14 400.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.14 400.43L431.63 403.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.63 403.49L426.91 406.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.91 406.21L422.01 408.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.01 408.59L416.95 410.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.95 410.61L411.76 412.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.76 412.25L406.46 413.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.46 413.52L401.08 414.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.08 414.4L395.65 414.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.65 414.89L390.21 414.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.21 414.99L384.77 414.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.77 414.7L379.36 414.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.36 414.01L374.02 412.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.02 412.94L368.77 411.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.77 411.48L363.64 409.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.64 409.65L358.65 407.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.65 407.45L353.84 404.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.84 404.9L349.22 402" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.22 402L344.83 398.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M344.83 398.78L340.68 395.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.68 395.26L336.79 391.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.79 391.44L333.19 387.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M333.19 387.35L329.89 383.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M329.89 383.01L326.91 378.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M326.91 378.45L324.28 373.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.28 373.68L321.99 368.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M321.99 368.74L320.06 363.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.06 363.64L318.51 358.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M318.51 358.42L317.34 353.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M317.34 353.1L316.56 347.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M316.56 347.71L316.16 342.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M316.16 342.27L316.16 336.82Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M316.16 336.82L316.56 331.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M316.56 331.39L317.34 326" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M317.34 326L318.51 320.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M318.51 320.68L320.06 315.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.06 315.46L321.99 310.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M321.99 310.36L324.28 305.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.28 305.41L326.91 300.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M326.91 300.65L329.89 296.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M329.89 296.08L333.19 291.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M333.19 291.75L336.79 287.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.79 287.66L340.68 283.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.68 283.84L344.83 280.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M344.83 280.31L349.22 277.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.22 277.09L353.84 274.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.84 274.2L358.65 271.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.65 271.65L363.64 269.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.64 269.45L368.77 267.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.77 267.62L374.02 266.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.02 266.16L379.36 265.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.36 265.09L384.77 264.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.77 264.4L390.21 264.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.21 264.11L395.65 264.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.65 264.2L401.08 264.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.08 264.69L406.46 265.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.46 265.58L411.76 266.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.76 266.84L416.95 268.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.95 268.49L422.01 270.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.01 270.51L426.91 272.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.91 272.88L431.63 275.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.63 275.61L436.14 278.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.14 278.66L440.42 282.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.42 282.04L444.44 285.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.44 285.71L448.19 289.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M448.19 289.67L451.64 293.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.64 293.88L454.78 298.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.78 298.34L457.59 303.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.59 303.01L460.05 307.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.05 307.87L462.16 312.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M462.16 312.89L463.9 318.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M463.9 318.05L465.26 323.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M465.26 323.33L466.24 328.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.24 328.69L466.83 334.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.83 334.1L467.02 339.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 344.59 260.19 A 92.224 92.224 0 0 0 313.43 290.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 336.05 245.76 A 108.992 108.992 0 0 0 299.23 281.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 327.51 231.33 A 125.759 125.759 0 0 0 285.02 272.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_07_05;

    