
    const SvgXx_06_16 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="341.07 218.44 112.41000000000003 214.81" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M397.27 377.05L397.27 223.44Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.07 428.25L346.07 377.05Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.07 377.05L448.48 377.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M448.48 377.05L448.48 428.25Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_06_16;

    