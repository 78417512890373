
    const Svg1_01_13 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="228.78 152.6 231.64000000000001 271.92999999999995" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M294.26 218.08L294.26 419.53Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M294.26 318.8L455.42 399.38 455.42 399.38 455.42 238.23 294.26 318.8Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M 294.26 197.94 A 22.159 22.159 0 0 0 274.12 218.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 294.26 177.76 A 42.303 42.303 0 0 0 253.93 218.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 294.26 157.6 A 62.447 62.447 0 0 0 233.78 218.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_13;

    