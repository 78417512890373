import { LoaderFunctionArgs, RouteObject, redirect } from 'react-router-dom';

import OverviewLayout from './overview.layout';
import { lazy } from 'react';
import { DriftRouterObjects } from '../drift/drift.router';
import { fromRestrictedRouteObjects } from '../../components/restricted/routing';
import { UserPermissions } from '../../components/restricted';
import TestingTilesScreen from './screens/testing/testing-tiles.screen';
import AutoGridFlexTest from './screens/testing/auto-grid-flex-test';

const OverviewGraphsScreen = lazy(() => import('./screens/graphs.screen'));
const OverviewDebugScreen = lazy(() => import('./screens/debug.screen'));
const OverviewTilesScreen = lazy(() => import('./screens/tiles.screen'));
const OverviewForsyningScreen = lazy(() => import('./screens/forsyning.screen'));
const OverviewForsyningValgScreen = lazy(() => import('./screens/forsyning.valg.screen'));
const OverviewForsyningAdminScreen = lazy(() => import('./screens/forsyning.admin.screen'));
const OverviewSynScreen = lazy(() => import('./screens/syn.screen'));
const OverviewBygningsdelskortScreen = lazy(() => import('./screens/bygningsdelskort.screen'));
const OverviewServiceScreen = lazy(() => import('./screens/service.screen'));
const OverviewStamdataScreen = lazy(() => import('./screens/stamdata/stamdata.screen'));

const handleRedirect = ({ request, params }: LoaderFunctionArgs) => {
    const url = new URL(request.url);
    const { id, overviewContextName = 'Context' } = params;
    const searchParams = new URLSearchParams(url.search);
    // If id exists, set the overview equivalent
    if (!searchParams.has('ids') && id && id !== '0') {
        searchParams.set('ids', 'enh-' + id.toString());
        return redirect(`${url.pathname}${url.hash}?${searchParams.toString()}`);
    }

    // return null if url contains at least 3 segments, then we have a context
    if (/(\/.+){3}/.test(url.pathname)) return null;

    return redirect(`/${id}/Overview/${overviewContextName}`);
};

const OverviewRouteObjects: RouteObject[] = fromRestrictedRouteObjects([
    {
        path: '*',
        loader: handleRedirect,
        children: fromRestrictedRouteObjects([
            {
                path: ':overviewContextName/*',
                loader: handleRedirect,
                element: <OverviewLayout />,
                children: fromRestrictedRouteObjects([
                    {
                        index: true,
                        element: <OverviewTilesScreen />,
                    },
                    {
                        path: 'Debug/*',
                        children: fromRestrictedRouteObjects([
                            {
                                index: true,
                                element: <TestingTilesScreen />,
                            },
                            {
                                path: 'Info',
                                element: <OverviewDebugScreen />,
                                permissions: UserPermissions.Admin,
                            },
                            {
                                path: 'AutoGridFlex',
                                element: <AutoGridFlexTest />,
                                permissions: UserPermissions.Admin,
                            }
                        ])
                    },
                    {
                        path: 'Forsyning/*',
                        permissions: UserPermissions.Forsyning,
                        children: [
                            {
                                index: true,
                                element: <OverviewForsyningValgScreen />,
                            },
                            {
                                path: 'Graphs',
                                element: <OverviewGraphsScreen />,
                            },
                            {
                                path: 'Stamdata',
                                element: <OverviewForsyningScreen />,
                            },
                            {
                                path: 'ForsyningAdmin',
                                element: <OverviewForsyningAdminScreen />,
                            },
                        ],
                    },
                    {
                        path: 'Syn',
                        element: <OverviewSynScreen />,
                        permissions: UserPermissions.Syn,
                    },
                    {
                        path: 'Bygningsdelskort',
                        element: <OverviewBygningsdelskortScreen />,
                        permissions: UserPermissions.Bygningsdelskort,
                    },
                    {
                        path: 'Service',
                        element: <OverviewServiceScreen />,
                        permissions: UserPermissions.Service,
                    },
                    {
                        path: 'Stamdata',
                        element: <OverviewStamdataScreen />,
                        permissions: UserPermissions.Overview,
                    },
                    {
                        path: 'Drift/*',
                        // element: <OverviewDriftScreen />,
                        children: DriftRouterObjects,
                        permissions: UserPermissions.Drift,
                    },
                    {
                        path: '*',
                        element: <OverviewTilesScreen />,
                    },
                ]),
            },
        ]),
    },
]);

export default OverviewRouteObjects;
