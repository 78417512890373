import navigation, { useCliNavigation } from './navigation';

import { IDictionary } from '../shared/utils/types';
import map from './map';

export const cli = {
    map,
    navigation,
};

(window as IDictionary)['cli'] = cli;

export const useCli = () => {
    useCliNavigation();
};
