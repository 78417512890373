import { Func } from '../utils/types';
import { useCallback } from 'react';
import useStable from './use-stable';

const useStableFunction = <T extends Func<Parameters<T>, ReturnType<T>>>(fn: T) => {
    const stable = useStable(fn);
    return useCallback((...args: Parameters<T>) => stable.current?.(...args), [stable]);
};

export default useStableFunction;
