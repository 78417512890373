
    const Svg3_04_01 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="335.67 242.5 150.20999999999998 111.66000000000003" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M441.13 273.28L441.13 273.28 418.99 281.4 418.99 265.15 441.13 273.28Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M396.84 273.28L441.13 273.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.13 293.03L441.13 293.03 418.99 301.15 418.99 284.91 441.13 293.03Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M396.84 293.03L441.13 293.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.02 296.2L372.12 270.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.12 296.2L346.02 270.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.53 283.15L377.33 285.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.33 285.84L376.75 288.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.75 288.47L375.78 290.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.78 290.99L374.46 293.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.46 293.34L372.82 295.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.82 295.47L370.88 297.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.88 297.34L368.69 298.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.69 298.91L366.29 300.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.29 300.14L363.74 301.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.74 301.01L361.09 301.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.09 301.5L358.4 301.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.4 301.6L355.72 301.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.72 301.3L353.11 300.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.11 300.62L350.64 299.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.64 299.57L348.34 298.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.34 298.16L346.26 296.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.26 296.44L344.47 294.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M344.47 294.43L342.98 292.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M342.98 292.19L341.84 289.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.84 289.75L341.06 287.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.06 287.17L340.67 284.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.67 284.5L340.67 281.81Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.67 281.81L341.06 279.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.06 279.14L341.84 276.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.84 276.56L342.98 274.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M342.98 274.12L344.47 271.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M344.47 271.87L346.26 269.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.26 269.87L348.34 268.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.34 268.14L350.64 266.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.64 266.74L353.11 265.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.11 265.69L355.72 265.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.72 265.01L358.4 264.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.4 264.71L361.09 264.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.09 264.81L363.74 265.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.74 265.3L366.29 266.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.29 266.17L368.69 267.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.69 267.4L370.88 268.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.88 268.97L372.82 270.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.82 270.84L374.46 272.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.46 272.97L375.78 275.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.78 275.32L376.75 277.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.75 277.84L377.33 280.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.33 280.47L377.53 283.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 349.52 247.5 A 36.91 36.91 0 0 0 349.52 318.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.77 349.16L362.77 319.63Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.01 349.16L374.01 319.63Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.01 319.63L393.7 349.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.7 349.16L393.7 319.63Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.95 349.16L404.95 319.63Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.95 319.63L414.79 319.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.79 319.63L419.01 321.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.01 321.04L421.82 323.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.82 323.85L423.23 326.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.23 326.66L424.63 330.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.63 330.88L424.63 337.91Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.63 337.91L423.23 342.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.23 342.13L421.82 344.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.82 344.94L419.01 347.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.01 347.75L414.79 349.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.79 349.16L404.95 349.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.48 349.16L434.48 319.63Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.48 319.63L452.76 319.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.48 333.69L445.72 333.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.48 349.16L452.76 349.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.19 349.16L480.88 319.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.19 319.63L480.88 349.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg3_04_01;

    