
    const SvgXx_06_04 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="268.45 266.35 245.57999999999998 73.26999999999998" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M273.45 302.99L509.03 302.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.88 302.99L422.86 301.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.86 301.86L422.8 300.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.8 300.74L422.7 299.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.7 299.62L422.56 298.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.56 298.51L422.38 297.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.38 297.4L422.16 296.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.16 296.3L421.9 295.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.9 295.21L421.61 294.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.61 294.12L421.27 293.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.27 293.05L420.9 291.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.9 291.99L420.49 290.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.49 290.94L420.05 289.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.05 289.91L419.57 288.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.57 288.9L419.05 287.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.05 287.9L418.49 286.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.49 286.92L417.91 285.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.91 285.97L417.67 285.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.05 275.59L406.57 275.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.57 275.31L405.58 274.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.58 274.78L404.57 274.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.57 274.29L403.54 273.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.54 273.84L402.5 273.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.5 273.42L401.44 273.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.44 273.04L400.37 272.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.37 272.69L399.29 272.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.29 272.39L398.2 272.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.2 272.12L397.1 271.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.1 271.9L395.99 271.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.99 271.71L394.88 271.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.88 271.56L393.76 271.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.76 271.45L392.64 271.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.64 271.38L391.52 271.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.52 271.35L390.39 271.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.39 271.36L389.4 271.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.42 275.59L375.42 275.59Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.42 275.59L374.45 276.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.45 276.17L373.51 276.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M373.51 276.78L372.59 277.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.59 277.42L371.7 278.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.7 278.1L370.83 278.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.83 278.81L369.98 279.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.98 279.55L369.16 280.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.16 280.32L368.37 281.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.37 281.12L367.61 281.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.61 281.94L366.88 282.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.88 282.79L366.18 283.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.18 283.67L365.51 284.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.51 284.57L364.87 285.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.87 285.5L364.27 286.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.27 286.44L363.7 287.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.7 287.41L363.16 288.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.16 288.4L362.97 288.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.6 302.99L359.6 303.55Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.6 303.55L359.64 304.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.64 304.67L359.72 305.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.72 305.79L359.84 306.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.84 306.91L360 308.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360 308.02L360.2 309.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.2 309.12L360.44 310.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.44 310.22L360.71 311.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.71 311.31L361.03 312.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.03 312.39L361.38 313.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.38 313.45L361.77 314.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.77 314.51L362.2 315.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.2 315.55L362.66 316.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.66 316.57L363.16 317.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.16 317.57L363.7 318.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.7 318.56L364.27 319.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.27 319.53L364.8 320.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.42 330.39L375.42 330.39Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.42 330.39L376.4 330.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.4 330.93L377.4 331.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.4 331.44L378.42 331.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.42 331.91L379.45 332.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.45 332.35L380.5 332.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.5 332.75L381.57 333.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.57 333.11L382.64 333.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.64 333.43L383.73 333.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.73 333.72L384.82 333.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.82 333.97L385.93 334.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M385.93 334.18L387.04 334.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.04 334.34L388.15 334.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.15 334.47L389.27 334.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.27 334.56L390.39 334.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.39 334.61L391.52 334.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.52 334.62L392.64 334.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.64 334.59L393.08 334.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.05 330.38L407.54 330.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.54 330.1L408.49 329.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.49 329.51L409.42 328.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.42 328.88L410.33 328.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.33 328.21L411.21 327.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.21 327.52L412.07 326.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.07 326.8L412.9 326.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.9 326.04L413.71 325.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.71 325.26L414.48 324.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.48 324.45L415.23 323.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.23 323.61L415.95 322.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.95 322.74L416.63 321.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.63 321.85L417.29 320.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.29 320.94L417.91 320.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.91 320.01L418.49 319.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.49 319.05L419.05 318.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.05 318.07L419.51 317.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_06_04;

    