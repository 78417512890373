
    const Svg2_02_04 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="179.35 198.61 379.0799999999999 193.66999999999996" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M184.35 387.28L368.02 387.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.02 387.28L368.02 203.61Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.02 203.61L184.35 203.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M184.35 203.61L184.35 387.28Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.1 295.45L321.9 299.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M321.9 299.68L321.32 303.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M321.32 303.88L320.35 308.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.35 308.01L319 312.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319 312.03L317.29 315.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M317.29 315.91L315.22 319.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M315.22 319.62L312.83 323.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M312.83 323.12L310.12 326.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.12 326.38L307.12 329.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M307.12 329.38L303.86 332.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M303.86 332.09L300.36 334.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M300.36 334.49L296.65 336.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M296.65 336.55L292.77 338.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M292.77 338.26L288.75 339.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M288.75 339.61L284.62 340.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M284.62 340.58L280.42 341.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M280.42 341.17L276.18 341.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M276.18 341.36L271.95 341.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M271.95 341.17L267.75 340.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M267.75 340.58L263.62 339.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M263.62 339.61L259.6 338.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M259.6 338.26L255.72 336.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M255.72 336.55L252.01 334.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M252.01 334.49L248.51 332.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M248.51 332.09L245.25 329.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M245.25 329.38L242.25 326.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M242.25 326.38L239.54 323.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M239.54 323.12L237.15 319.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M237.15 319.62L235.08 315.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M235.08 315.91L233.37 312.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M233.37 312.03L232.02 308.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M232.02 308.01L231.05 303.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M231.05 303.88L230.46 299.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M230.46 299.68L230.27 295.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M230.27 295.45L230.46 291.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M230.46 291.21L231.05 287.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M231.05 287.01L232.02 282.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M232.02 282.88L233.37 278.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M233.37 278.86L235.08 274.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M235.08 274.98L237.15 271.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M237.15 271.28L239.54 267.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M239.54 267.78L242.25 264.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M242.25 264.51L245.25 261.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M245.25 261.51L248.51 258.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M248.51 258.81L252.01 256.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M252.01 256.41L255.72 254.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M255.72 254.34L259.6 252.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M259.6 252.63L263.62 251.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M263.62 251.28L267.75 250.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M267.75 250.31L271.95 249.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M271.95 249.73L276.18 249.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M276.18 249.53L280.42 249.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M280.42 249.73L284.62 250.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M284.62 250.31L288.75 251.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M288.75 251.28L292.77 252.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M292.77 252.63L296.65 254.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M296.65 254.34L300.36 256.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M300.36 256.41L303.86 258.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M303.86 258.81L307.12 261.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M307.12 261.51L310.12 264.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.12 264.51L312.83 267.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M312.83 267.78L315.22 271.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M315.22 271.28L317.29 274.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M317.29 274.98L319 278.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319 278.86L320.35 282.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.35 282.88L321.32 287.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M321.32 287.01L321.9 291.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M321.9 291.21L322.1 295.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M184.35 203.61L368.02 387.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M184.35 387.28L368.02 203.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.75 387.28L465.97 295.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.75 295.45L465.97 387.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M492.21 387.28L553.43 295.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M492.21 295.45L553.43 387.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_02_04;

    