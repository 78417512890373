
    const Svg4_05_06 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="259.45 237.45 267.84 137.7" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M289.82 242.45L363.55 370.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.55 370.15L388.91 370.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.91 370.15L388.91 242.45Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.91 242.45L363.55 242.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.34 339.33L459.38 273.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.34 273.27L459.38 339.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.25 339.33L522.29 273.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.25 273.27L522.29 339.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.55 242.45L289.82 370.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M289.82 370.15L264.45 370.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M264.45 370.15L264.45 242.45Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M264.45 242.45L289.82 242.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg4_05_06;

    