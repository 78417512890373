import BurgerMenuMini from './burger-menu-mini';
import BurgerMenuMobile from './burger-menu-mobile';
import useBreakpoints from '../../../shared/hooks/use-breakpoints';

const BurgerMenu = ({ children }: { children: JSX.Element }) => {
    const { md } = useBreakpoints();

    return md ? <BurgerMenuMini>{children}</BurgerMenuMini> : <BurgerMenuMobile>{children}</BurgerMenuMobile>;
};

export default BurgerMenu;
