
    const Svg4_05_15 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="305.82 169.8 209.01999999999998 239.44" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M395.11 289.7L394.91 292.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.91 292.35L394.32 294.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.32 294.93L393.35 297.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.35 297.4L392.03 299.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.03 299.7L390.37 301.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.37 301.77L388.43 303.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.43 303.57L386.24 305.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.24 305.07L383.85 306.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.85 306.22L381.32 307" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.32 307L378.7 307.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.7 307.39L376.05 307.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.05 307.39L373.42 307" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M373.42 307L370.89 306.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.89 306.22L368.5 305.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.5 305.07L366.31 303.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.31 303.57L364.37 301.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.37 301.77L362.71 299.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.71 299.7L361.39 297.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.39 297.4L360.42 294.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.42 294.93L359.83 292.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.83 292.35L359.63 289.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.63 289.7L359.83 287.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.83 287.06L360.42 284.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.42 284.47L361.39 282.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.39 282.01L362.71 279.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.71 279.71L364.37 277.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.37 277.64L366.31 275.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.31 275.83L368.5 274.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.5 274.34L370.89 273.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.89 273.19L373.42 272.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M373.42 272.41L376.05 272.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.05 272.01L378.7 272.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.7 272.01L381.32 272.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.32 272.41L383.85 273.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.85 273.19L386.24 274.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.24 274.34L388.43 275.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.43 275.83L390.37 277.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.37 277.64L392.03 279.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.03 279.71L393.35 282.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.35 282.01L394.32 284.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.32 284.47L394.91 287.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.91 287.06L395.11 289.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393 281.32L443.67 252.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.67 252.07L509.84 290.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 421.62 365.59 A 87.839 87.839 0 0 0 465.21 289.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M465.21 289.7L449.12 303.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.29 280.36L311.63 251.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M311.63 251.11L311.46 174.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.81 307.43L376.81 365.93Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.81 365.93L310.82 404.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M479.12 305.8L465.21 289.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg4_05_15;

    