import { cloneElement, useCallback, useMemo } from 'react';

import { RestrictedProps } from './interfaces';
import { UserPermissions } from './interfaces';
import getStore from '../../shared/store';
import { useAppSelector } from '../../shared/hooks/redux-base-hooks';

const internal_hasPermissions = (required: UserPermissions[], permSet: Set<UserPermissions>): boolean => {
    if (required.includes(UserPermissions.Denied)) return false;
    if (permSet.has(UserPermissions.Admin)) return true;
    return required.every((perm) => permSet.has(perm));
};

const createPermissionSet = (permissions: UserPermissions[] | undefined): Set<UserPermissions> => {
    const set = new Set<UserPermissions>(permissions ?? []);
    return set;
};

/**
 * @deprecated use hasPermissions from usePermissions instead. This only exists for cases outside the react render tree
 * This is not written to be performative, simply to work
 */
export const hasPermissions = (...required: UserPermissions[]): boolean => {
    const permissions = getStore().getState().user.permissions;
    const set = createPermissionSet(permissions);
    return internal_hasPermissions(required, set);
};

/**
 * Permission hook
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @returns
 * permissions: An array of permissions
 * hasPermissions: A function to check if a user has the required permissions
 */
export const usePermissions = () => {
    const permissions = useAppSelector((s) => s.user.permissions);
    const permSet = useMemo(() => createPermissionSet(permissions), [permissions]);

    const hasPermissions = useCallback(
        (...required: UserPermissions[]) => internal_hasPermissions(required, permSet),
        [permSet]
    );

    return {
        permissions: permissions,
        hasPermissions,
    };
};

/**
 * A component which conditionally renders children based on user permissions.
 * Alternatively, it can modify childrens props based on user permissions
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 */
const Restricted = ({ permissions, children, renderUnauthorized, unauthorizedProps }: RestrictedProps) => {
    const { hasPermissions } = usePermissions();
    const _permissions = typeof permissions === 'string' ? [permissions] : permissions;

    if (hasPermissions(..._permissions)) return <>{children}</>;

    if (unauthorizedProps !== undefined && children) return <>{cloneElement(children, { ...unauthorizedProps })}</>;

    return <>{renderUnauthorized}</>;
};

export default Restricted;
