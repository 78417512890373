
    const Svg2_03_01 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="275.03 184.27 255.17000000000007 228.78" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M348.13 408.05L348.13 299.08Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.1 408.05L457.1 299.08Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 280.03 189.27 A 122.587 122.587 0 0 0 525.2 189.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M280.03 189.27L525.2 189.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.48 250.56L443.28 254.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.28 254.57L442.69 258.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.69 258.53L441.72 262.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.72 262.42L440.37 266.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.37 266.2L438.65 269.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438.65 269.82L436.59 273.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.59 273.26L434.2 276.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.2 276.48L431.51 279.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.51 279.45L428.54 282.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.54 282.15L425.32 284.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.32 284.54L421.88 286.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.88 286.6L418.25 288.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.25 288.31L414.48 289.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.48 289.66L410.59 290.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.59 290.64L406.62 291.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.62 291.23L402.61 291.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.61 291.42L398.61 291.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.61 291.23L394.64 290.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.64 290.64L390.75 289.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.75 289.66L386.98 288.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.98 288.31L383.35 286.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.35 286.6L379.91 284.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.91 284.54L376.69 282.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.69 282.15L373.72 279.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M373.72 279.45L371.03 276.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.03 276.48L368.64 273.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.64 273.26L366.58 269.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.58 269.82L364.86 266.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.86 266.2L363.51 262.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.51 262.42L362.54 258.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.54 258.53L361.95 254.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.95 254.57L361.75 250.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.75 250.56L361.95 246.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.95 246.55L362.54 242.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.54 242.59L363.51 238.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.51 238.7L364.86 234.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.86 234.92L366.58 231.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.58 231.3L368.64 227.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.64 227.86L371.03 224.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.03 224.64L373.72 221.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M373.72 221.67L376.69 218.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.69 218.97L379.91 216.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.91 216.58L383.35 214.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.35 214.52L386.98 212.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.98 212.81L390.75 211.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.75 211.46L394.64 210.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.64 210.48L398.61 209.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.61 209.89L402.61 209.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.61 209.7L406.62 209.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.62 209.89L410.59 210.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.59 210.48L414.48 211.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.48 211.46L418.25 212.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.25 212.81L421.88 214.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.88 214.52L425.32 216.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.32 216.58L428.54 218.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.54 218.97L431.51 221.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.51 221.67L434.2 224.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.2 224.64L436.59 227.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.59 227.86L438.65 231.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438.65 231.3L440.37 234.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.37 234.92L441.72 238.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.72 238.7L442.69 242.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.69 242.59L443.28 246.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.28 246.55L443.48 250.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M373.72 279.45L431.51 221.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M373.72 221.67L431.51 279.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_03_01;

    