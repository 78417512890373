
    const Svg4_05_20 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="116.43 246.77 574.5699999999999 118.08000000000001" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M154.57 251.77L132.59 359.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M187.71 251.77L165.73 359.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M199.19 287.65L121.43 287.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M199.19 324.15L121.43 324.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M276.28 251.77L254.31 359.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M309.42 251.77L287.45 359.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.9 287.65L243.14 287.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.9 324.15L243.14 324.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.99 251.77L376.02 359.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.13 251.77L409.16 359.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.61 287.65L364.85 287.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.61 324.15L364.85 324.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M519.66 251.77L497.69 359.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M552.8 251.77L530.83 359.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M564.28 287.65L486.52 287.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M564.28 324.15L486.52 324.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M641.37 251.77L619.4 359.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M674.51 251.77L652.54 359.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M686 287.65L608.23 287.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M686 324.15L608.23 324.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg4_05_20;

    