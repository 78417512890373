import Box from '@mui/material/Box/Box';
import Grid from '@mui/material/Grid/Grid';
import { IFile } from './common';
import Skeleton from '@mui/material/Skeleton/Skeleton';
import { SquareGridItem } from '../mui/styled-mui';
import Stack from '@mui/material/Stack/Stack';
import useImageFetch from '../../shared/hooks/fetch-hooks/use-image-fetch';
import { useRef } from 'react';

interface ClickFillGridImageProps {
    file: IFile;
    onDeleted?(): void;
}

export const ImageGridSmallItem = (props: ClickFillGridImageProps) => {
    const renderedGridItem = useRef<HTMLDivElement>(null);

    const [image, isLoading, isError] = useImageFetch(props.file.url);
    const hide = isError;

    if (hide) return null;

    return (
        <SquareGridItem
            key={`img-${props.file.id}`}
            ref={renderedGridItem}
            item
            xs={6}
            sm={4}
            md={4}
            sx={(theme) => ({
                transition: theme.transitions.create('all', {
                    easing: theme.transitions.easing.easeInOut,
                    duration: '1s',
                }),
                width: 500,
                // height: 1000
            })}
        >
            <Box
                sx={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    padding: 1,
                }}
            >
                {isLoading ? (
                    <Stack spacing={1} sx={{ width: 1 }}>
                        <Skeleton variant='text' />
                        <Skeleton variant='circular' width={40} height={40} />
                        <Skeleton variant='text' />
                        <Skeleton variant='text' />
                    </Stack>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow: 'hidden',
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <img
                            src={image}
                            alt={props.file.alt ?? props.file.name}
                            style={{
                                minHeight: '100%',
                                minWidth: '100%',
                                objectFit: 'cover',
                                overflow: 'hidden',
                            }}
                        />
                    </Box>
                )}
            </Box>
        </SquareGridItem>
    );
};

export interface IImageGridProps {
    files: IFile[];
}

const ImageGridSmall = (props: IImageGridProps) => {
    return (
        <Grid container spacing={2} sx={{ width: '100%', marginTop: '10px', marginBottom: '15px', marginLeft: '-8px' }}>
            {props.files.map((file, index) => (
                <ImageGridSmallItem file={file} key={`ImageGridItem${index}`} />
            ))}
        </Grid>
    );
};

export default ImageGridSmall;
