
    const Svg4_05_17 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="210.63 230.76 406.88 168.75" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M295.01 235.76L215.63 235.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M215.63 235.76L215.63 315.13Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M612.51 315.13L612.51 394.51Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M612.51 394.51L533.13 394.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.04 341.59L365.32 288.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.04 288.68L365.32 341.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M448.79 341.59L484.07 288.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M448.79 288.68L484.07 341.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg4_05_17;

    