const SvgEltavle = () => (
    <svg xmlns='http://www.w3.org/2000/svg' xmlSpace='preserve' width={21.683} height={30.153}>
        <path
            d='M70.68 65.304V50.227h21.683l-.089 14.987-.088 14.987-10.753.09-10.753.09zm20.32-.18V51.233l-9.567.09-9.568.09-.088 13.801-.089 13.802H91.001zm-18.288.175v-13.04h17.273v26.08H72.712zm13.208-2.286c0-.14-.914-.254-2.032-.254-1.117 0-2.032-.085-2.032-.188s.583-1.056 1.296-2.117c.713-1.06 1.412-2.126 1.553-2.367l.258-.438-2.61.1-2.61.099-1.262 4.064-1.262 4.064 1.84.103 1.84.102-.206.538c-.114.296-.605 1.927-1.092 3.624a301.634 301.634 0 0 1-1.046 3.593c-.089.279 1.532-2.007 3.602-5.08 2.07-3.074 3.763-5.703 3.763-5.843'
            style={{
                fill: '{{replacement-value-color}}',
                fillOpacity: 1,
            }}
            transform='translate(-70.68 -50.227)'
        />
    </svg>
);
export default SvgEltavle;
