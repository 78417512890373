
    const Svg1_05_04 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="308.31 228.71 233.01000000000005 130.70000000000002" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M434.01 354.41L434.01 233.71Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M313.31 233.71L313.31 354.41Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.01 233.71L313.31 354.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M313.31 354.41L434.01 354.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.01 233.71L313.31 233.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M313.31 233.71L434.01 354.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.15 354.41L490.34 306.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.15 306.13L490.34 354.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M504.13 354.41L536.32 306.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M504.13 306.13L536.32 354.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_05_04;

    