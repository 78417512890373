
    const Svg1_01_24 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="226.37 238.38 329.28 146.01" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M299.37 324.99L299.37 379.39Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M299.37 379.39L435.37 379.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.37 379.39L435.37 243.38Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.37 243.38L299.37 243.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M299.37 243.38L299.37 324.99Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.95 277.38L340.95 345.39Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.95 345.39L379.81 345.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M299.37 311.38L231.37 311.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M231.37 311.38L265.37 294.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M231.37 311.38L265.37 328.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M462.58 379.39L498.84 324.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M462.58 324.99L498.84 379.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M514.39 379.39L550.65 324.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M514.39 324.99L550.65 379.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_24;

    