
    const Svg2_06_04 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="282.32 211.81 344.47999999999996 181.39999999999998" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M287.32 216.81L287.32 388.21Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M287.32 388.21L601.56 388.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M601.56 388.21L601.56 216.81Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M601.56 216.81L287.32 216.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.03 332.62L494.43 332.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M565.85 332.62L565.66 336.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M565.66 336.36L565.07 340.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M565.07 340.05L564.11 343.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M564.11 343.66L562.77 347.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M562.77 347.15L561.07 350.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M561.07 350.48L559.03 353.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M559.03 353.61L556.68 356.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M556.68 356.52L554.04 359.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M554.04 359.16L551.13 361.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M551.13 361.51L548 363.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M548 363.55L544.67 365.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M544.67 365.25L541.18 366.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M541.18 366.59L537.57 367.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M537.57 367.55L533.88 368.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M533.88 368.14L530.14 368.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M530.14 368.33L526.41 368.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M526.41 368.14L522.72 367.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M522.72 367.55L519.11 366.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M519.11 366.59L515.62 365.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M515.62 365.25L512.29 363.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M512.29 363.55L509.15 361.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M509.15 361.51L506.25 359.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M506.25 359.16L503.61 356.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M503.61 356.52L501.25 353.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M501.25 353.61L499.22 350.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M499.22 350.48L497.52 347.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M497.52 347.15L496.18 343.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M496.18 343.66L495.22 340.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M495.22 340.05L494.63 336.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M494.63 336.36L494.43 332.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M494.43 332.62L494.63 328.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M494.63 328.89L495.22 325.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M495.22 325.2L496.18 321.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M496.18 321.59L497.52 318.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M497.52 318.1L499.22 314.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M499.22 314.77L501.25 311.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M501.25 311.63L503.61 308.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M503.61 308.73L506.25 306.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M506.25 306.09L509.15 303.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M509.15 303.73L512.29 301.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M512.29 301.7L515.62 300" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M515.62 300L519.11 298.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M519.11 298.66L522.72 297.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M522.72 297.69L526.41 297.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M526.41 297.11L530.14 296.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M530.14 296.91L533.88 297.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M533.88 297.11L537.57 297.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M537.57 297.69L541.18 298.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M541.18 298.66L544.67 300" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M544.67 300L548 301.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M548 301.7L551.13 303.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M551.13 303.73L554.04 306.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M554.04 306.09L556.68 308.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M556.68 308.73L559.03 311.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M559.03 311.63L561.07 314.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M561.07 314.77L562.77 318.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M562.77 318.1L564.11 321.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M564.11 321.59L565.07 325.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M565.07 325.2L565.66 328.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M565.66 328.89L565.85 332.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.03 332.62L323.03 368.33Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M303.75 271.96L303.75 231.97Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M303.75 231.97L320.89 231.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.89 231.97L326.6 233.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M326.6 233.87L328.51 235.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.51 235.78L330.41 239.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.41 239.58L330.41 243.39Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.41 243.39L328.51 247.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.51 247.2L326.6 249.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M326.6 249.11L320.89 251.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M303.75 251.01L320.89 251.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.89 251.01L326.6 252.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M326.6 252.92L328.51 254.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.51 254.82L330.41 258.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.41 258.63L330.41 264.34Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.41 264.34L328.51 268.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.51 268.15L326.6 270.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M326.6 270.06L320.89 271.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.89 271.96L303.75 271.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.74 271.96L343.74 231.97Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.74 231.97L360.88 231.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.88 231.97L366.6 233.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.6 233.87L368.5 235.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.5 235.78L370.41 239.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.41 239.58L370.41 243.39Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.41 243.39L368.5 247.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.5 247.2L366.6 249.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.6 249.11L360.88 251.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.88 251.01L343.74 251.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M357.08 251.01L370.41 271.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.74 271.96L398.97 231.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.97 231.97L414.21 271.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.45 258.63L408.5 258.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.64 271.96L425.64 231.97Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.64 231.97L452.3 271.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.3 271.96L452.3 231.97Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.54 271.96L467.54 231.97Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.54 231.97L480.87 231.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M480.87 231.97L486.58 233.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M486.58 233.87L490.39 237.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M490.39 237.68L492.29 241.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M492.29 241.49L494.2 247.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M494.2 247.2L494.2 256.73Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M494.2 256.73L492.29 262.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M492.29 262.44L490.39 266.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M490.39 266.25L486.58 270.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M486.58 270.06L480.87 271.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M480.87 271.96L467.54 271.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M557.05 224.35L553.24 228.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M553.24 228.16L549.43 233.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M549.43 233.87L545.62 241.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M545.62 241.49L543.71 251.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M543.71 251.01L543.71 258.63Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M543.71 258.63L545.62 268.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M545.62 268.15L549.43 275.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M549.43 275.77L553.24 281.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M553.24 281.48L557.05 285.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M597.04 237.68L593.23 233.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M593.23 233.87L587.52 231.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M587.52 231.97L579.9 231.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M579.9 231.97L574.19 233.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M574.19 233.87L570.38 237.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M570.38 237.68L570.38 241.49Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M570.38 241.49L572.28 245.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M572.28 245.3L574.19 247.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M574.19 247.2L578 249.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M578 249.11L589.42 252.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M589.42 252.92L593.23 254.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M593.23 254.82L595.14 256.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M595.14 256.73L597.04 260.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M597.04 260.53L597.04 266.25Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M597.04 266.25L593.23 270.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M593.23 270.06L587.52 271.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M587.52 271.96L579.9 271.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M579.9 271.96L574.19 270.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M574.19 270.06L570.38 266.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M608.47 224.35L612.28 228.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M612.28 228.16L616.09 233.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M616.09 233.87L619.89 241.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M619.89 241.49L621.8 251.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M621.8 251.01L621.8 258.63Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M621.8 258.63L619.89 268.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M619.89 268.15L616.09 275.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M616.09 275.77L612.28 281.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M612.28 281.48L608.47 285.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_06_04;

    