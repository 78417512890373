import { Fill, Style, Text } from 'ol/style';

import CTLayer from './CTLayer';
import { Extent } from 'ol/extent';
import Feature from 'ol/Feature';
import { FeatureStyleKey } from '.';
import { Polygon } from 'ol/geom';
import { StyleLike } from 'ol/style/Style';

export enum LayerRepresents {
    Enhed = 'enhed',
    Bygning = 'bygning',
    Lokale = 'lokale',
    Del = 'del',
}

export enum FeatureProperty {
    Data = 'data',
    Search = 'search',
    StyleKeys = 'styleKey',
}

export const drawRectangle = (layer: CTLayer | undefined, extent: Extent, text?: string) => {
    const polygon = new Polygon([
        [
            [extent[0], extent[1]],
            [extent[0], extent[3]],
            [extent[2], extent[3]],
            [extent[2], extent[1]],
        ],
    ]);
    const feature = new Feature(polygon);
    feature.set(FeatureProperty.StyleKeys, FeatureStyleKey.Boundary);
    text !== undefined &&
        feature.set(
            'boundaryText',
            new Text({
                text,
                font: '16px "Segoe UI", Verdana, Tahoma, sans-serif',
                fill: new Fill({ color: 'black' }),
                textBaseline: 'top',
                placement: 'line',
            })
        );
    layer?.getSource()?.addFeature(feature);
    return feature;
};

/**
 * Retrieves a features style and transforms it to an array
 *
 * @param feature Feature to check
 * @param resolution OL resolution. Default is 2, based on this being a breakpoint in our most used style function
 * @returns array of styles
 */
export const getStyleArray = (feature: Feature, resolution = 2): Style[] => {
    let styleLike: StyleLike | void = feature.getStyle();

    if (typeof styleLike === 'function') styleLike = styleLike(feature, resolution);

    if (styleLike instanceof Style) return [styleLike];

    if (Array.isArray(styleLike)) return styleLike.filter((style) => style instanceof Style);

    return [];
};
