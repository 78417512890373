
    const Svg1_01_14 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="308.03 215.54 170.75 155.51000000000002" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M 366.62 327.71 A 97.346 97.346 0 0 0 366.62 220.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M313.03 274.13L377.33 300.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M313.03 274.13L377.33 247.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.69 274.13L398.77 274.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.84 274.13L436.28 274.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.35 274.13L473.78 274.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.33 247.34L398.77 247.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.84 247.34L436.28 247.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.35 247.34L473.78 247.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.33 300.92L398.77 300.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.84 300.92L436.28 300.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.35 300.92L473.78 300.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.68 366.05L425.26 323.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.68 323.18L425.26 366.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.51 366.05L466.09 323.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.51 323.18L466.09 366.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_14;

    