const SvgLineString = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={16}
        height={16}
        fill='none'
        stroke='{{replacement-value-color}}'
        strokeLinecap='round'
        strokeWidth={8}
        viewBox='0 0 100 100'
    >
        <path d='m33 22 34 16M68 53 37 66M38 75l27 7' />
        <circle cx={20} cy={15} r={7} />
        <circle cx={80} cy={45} r={7} />
        <circle cx={23} cy={72} r={7} />
        <circle cx={80} cy={85} r={7} />
    </svg>
);
export default SvgLineString;
