
    const Svg4_05_14 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="159.3 234.26 508.55 140.15000000000003" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M491.27 281.36L463.19 328.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M463.19 328.73L519.34 328.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M519.34 328.73L491.27 281.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M189.14 369.41L551.2 239.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M164.3 369.41L224.85 369.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M257.67 369.41L339.73 369.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.55 369.41L454.6 369.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M487.43 369.41L569.48 369.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M602.3 369.41L662.85 369.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 551.2 239.26 A 136.716 136.716 0 0 0 628.68 369.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M523.27 313.37L523.08 316.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M523.08 316.89L522.5 320.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M522.5 320.37L521.54 323.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M521.54 323.76L520.21 327.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M520.21 327.03L518.53 330.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M518.53 330.13L516.52 333.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M516.52 333.03L514.21 335.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M514.21 335.69L511.61 338.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M511.61 338.07L508.77 340.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M508.77 340.16L505.72 341.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M505.72 341.92L502.49 343.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M502.49 343.34L499.12 344.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M499.12 344.39L495.66 345.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M495.66 345.07L492.15 345.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M492.15 345.36L488.62 345.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.62 345.26L485.13 344.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M485.13 344.78L481.71 343.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.71 343.91L478.41 342.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.41 342.68L475.26 341.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M475.26 341.09L472.31 339.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M472.31 339.16L469.59 336.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M469.59 336.92L467.13 334.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.13 334.39L464.96 331.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.96 331.61L463.12 328.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M463.12 328.6L461.61 325.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.61 325.41L460.47 322.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.47 322.08L459.7 318.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.7 318.64L459.31 315.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.31 315.13L459.31 311.61Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.31 311.61L459.7 308.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.7 308.1L460.47 304.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.47 304.66L461.61 301.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.61 301.33L463.12 298.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M463.12 298.14L464.96 295.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.96 295.13L467.13 292.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.13 292.35L469.59 289.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M469.59 289.82L472.31 287.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M472.31 287.58L475.26 285.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M475.26 285.65L478.41 284.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.41 284.06L481.71 282.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.71 282.82L485.13 281.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M485.13 281.96L488.62 281.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.62 281.47L492.15 281.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M492.15 281.38L495.66 281.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M495.66 281.67L499.12 282.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M499.12 282.34L502.49 283.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M502.49 283.4L505.72 284.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M505.72 284.81L508.77 286.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M508.77 286.58L511.61 288.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M511.61 288.66L514.21 291.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M514.21 291.05L516.52 293.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M516.52 293.71L518.53 296.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M518.53 296.61L520.21 299.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M520.21 299.71L521.54 302.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M521.54 302.98L522.5 306.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M522.5 306.37L523.08 309.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M523.08 309.85L523.27 313.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg4_05_14;

    