
    const Svg1_01_29 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="339.37 238.38 112.13 112.13" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M344.37 345.51L446.5 345.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.5 345.51L446.5 243.38Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.5 243.38L344.37 243.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M344.37 243.38L344.37 345.51Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.91 268.91L364.91 319.97Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M347.89 268.91L381.93 268.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.66 319.97L391.66 268.91Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.66 268.91L408.68 268.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.68 268.91L415.97 271.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.97 271.34L420.84 276.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.84 276.21L423.27 281.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.27 281.07L425.7 288.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.7 288.36L425.7 300.52Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.7 300.52L423.27 307.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.27 307.82L420.84 312.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.84 312.68L415.97 317.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.97 317.54L408.68 319.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.68 319.97L391.66 319.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_29;

    