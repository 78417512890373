class UtilsString extends String {
    public static IsNullOrWhitespace(str: UtilsString | undefined | null): boolean {
        return !str || /^\s*$/.test(str as string);
    }

    public static IsNullOrEmpty(str: UtilsString | undefined | null): boolean {
        return !str || str.length === 0;
    }
}

export default UtilsString;
