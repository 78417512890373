import features from './features';
import info from './info';
import search from './search';

export const map = {
    ...features,
    ...info,
    ...search,
};

export default map;
