
    const Svg2_04_08 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="314.83 255.41000000000003 191.82 100.90999999999997" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M319.83 260.41L319.83 351.32Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319.83 351.32L501.65 351.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M501.65 351.32L501.65 260.41Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M501.65 260.41L319.83 260.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.15 328.59L370.15 283.14Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.15 283.14L389.64 283.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.64 283.14L396.13 285.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.13 285.3L398.29 287.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.29 287.47L400.46 291.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.46 291.79L400.46 296.12Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.46 296.12L398.29 300.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.29 300.45L396.13 302.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.13 302.62L389.64 304.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.64 304.78L370.15 304.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M385.31 304.78L400.46 328.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.61 283.14L415.61 328.59Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.61 328.59L441.59 328.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_04_08;

    