const SvgFlugtvejsbelysning = () => (
    <svg xmlns='http://www.w3.org/2000/svg' xmlSpace='preserve' width={19.839} height={29.892}>
        <path
            d='m14.269 62.852-.57-.229v-.64c0-.353-.105-.642-.233-.642-.54 0-1.79-1.34-1.79-1.92v-.61h3.906c2.15 0 3.972.104 4.051.232.294.475-.384 1.556-1.228 1.959-.516.245-.936.664-1.026 1.02-.236.942-1.795 1.358-3.11.83m-3.044-5.7c-.692-.485-.72-1.022-.073-1.369.28-.15 2.26-.262 4.631-.262h4.142l.303.304c.456.456.366 1.163-.186 1.459-.276.148-2.18.262-4.372.262h-3.882zM8.99 51.409c-1.073-1.566-2.217-3.49-2.542-4.275l-.592-1.428-.039-1.609c-.021-.884.048-2.12.154-2.747.495-2.92 2.894-6.015 5.636-7.272l1.46-.669 2.058-.108 2.058-.109 1.358.45c3.46 1.143 5.78 3.64 6.693 7.203l.418 1.634-.118 1.833-.117 1.833-.861 1.637c-.474.9-1.601 2.722-2.506 4.05l-1.645 2.412-4.732.006-4.732.006zm.111-6.959c.125-.243.292-1.098.37-1.9.28-2.847 1.919-4.695 4.794-5.405l1.7-.42.153-.483c.391-1.232-1.018-1.537-3.335-.723-2.938 1.033-4.677 3.302-5.22 6.812l-.275 1.774.326.394c.436.525 1.205.5 1.487-.049'
            style={{
                fill: '{{replacement-value-color}}',
                fillOpacity: 1,
            }}
            transform='translate(-5.813 -33.192)'
        />
    </svg>
);
export default SvgFlugtvejsbelysning;
