const SvgRogalarmAva = () => (
    <svg xmlns='http://www.w3.org/2000/svg' xmlSpace='preserve' width={27.172} height={26.848}>
        <path
            d='M-58.255-12.068c-1.417-.525-2.317-1.387-3.068-2.937l-.603-1.247.116-2.057c.07-1.246.348-2.563.703-3.34l.587-1.284.322 1.16c.178.637.628 1.445 1.001 1.796l.678.637.135-2.098.134-2.097.714-1.553c.393-.854 1.079-1.985 1.525-2.512l.812-.96.462 1.673.461 1.672 1.537 2.14c1.803 2.509 2.253 3.615 2.27 5.574l.013 1.438-.927 1.534-.927 1.534-1.103.567c-1.414.726-3.447.878-4.842.36m8.335-3.449.462-1.568h1.925l1.925-.001v-.856H-49.6v-.404c0-.223-.071-.592-.16-.82l-.159-.417 1.657-.202c1.767-.215 3.585-.902 4.936-1.864l.83-.59.084 1.792.085 1.792 1.99.086 1.99.086.363-.497.363-.497V-29.379l-.426-.825-.427-.826.274-.72c.508-1.337 2.658-1.31 3.17.038l.277.727-.436.665-.435.664-.004 5.929c-.003 3.26-.102 6.08-.22 6.267-.401.63-1.801.944-4.216.944h-2.405v1.68l-.634.443-.634.444H-50.381zm-.473-5.68c-.19-.417-.855-1.434-1.478-2.259-.623-.824-1.407-2.119-1.741-2.876l-.61-1.376.198-1.579.197-1.579-1.139.903a18.627 18.627 0 0 0-2.016 1.9l-.877 1-.186-.739c-.102-.406-.123-1.607-.047-2.67l.138-1.93.816-1.5c.928-1.704 2.432-3.102 4.361-4.055l1.323-.653 2.424.012 2.424.011 1.322.642c2.016.977 3.55 2.448 4.436 4.253l.802 1.632-.007 2.424-.007 2.425-.7 1.426c-1.445 2.95-4.242 4.876-7.52 5.182l-1.767.165zm2.504-7.298c.685-.685.724-1.365.126-2.218-.847-1.21-2.978-.509-2.978.98 0 .818.937 1.809 1.71 1.809.315 0 .828-.257 1.142-.57'
            style={{
                fill: '{{replacement-value-color}}',
                fillOpacity: 1,
            }}
            transform='translate(61.926 38.61)'
        />
    </svg>
);
export default SvgRogalarmAva;
