
    const Svg2_07_01 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="273.52 212.25 278.85 165.14" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M547.37 239.41L532.59 224.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M532.59 224.63L510.43 217.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M510.43 217.25L480.88 217.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M480.88 217.25L458.72 224.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.72 224.63L443.94 239.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.94 239.41L443.94 254.18Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.94 254.18L451.33 268.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.33 268.96L458.72 276.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.72 276.35L473.49 283.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M473.49 283.74L517.82 298.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M517.82 298.51L532.59 305.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M532.59 305.9L539.98 313.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M539.98 313.29L547.37 328.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M547.37 328.06L547.37 350.22Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M547.37 350.22L532.59 365" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M532.59 365L510.43 372.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M510.43 372.39L480.88 372.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M480.88 372.39L458.72 365" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.72 365L443.94 350.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.8 271.37L358.32 265.89 385.74 238.46 391.23 243.95 363.8 271.37Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M358.32 323.75L363.8 318.26 391.23 345.69 385.74 351.17 358.32 323.75Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M311.43 265.89L305.94 271.37 278.52 243.95 284 238.46 311.43 265.89Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M305.94 318.26L311.43 323.75 284 351.17 278.52 345.69 305.94 318.26Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M334.87 335.73L330.73 335.52 331.52 327.8 334.87 327.97 334.87 335.73Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M330.73 335.52L326.64 334.89 328.2 327.3 331.52 327.8 330.73 335.52Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M326.64 334.89L322.62 333.86 324.95 326.45 328.2 327.3 326.64 334.89Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M322.62 333.86L318.74 332.42 321.8 325.29 324.95 326.45 322.62 333.86Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M318.74 332.42L315.02 330.59 318.78 323.81 321.8 325.29 318.74 332.42Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M315.02 330.59L311.5 328.4 315.93 322.03 318.78 323.81 315.02 330.59Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M311.5 328.4L308.22 325.86 313.27 319.98 315.93 322.03 311.5 328.4Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M308.22 325.86L305.22 323.01 310.84 317.66 313.27 319.98 308.22 325.86Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M305.22 323.01L302.52 319.86 308.65 315.11 310.84 317.66 305.22 323.01Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M302.52 319.86L300.15 316.46 306.73 312.36 308.65 315.11 302.52 319.86Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M300.15 316.46L298.14 312.84 305.1 309.42 306.73 312.36 300.15 316.46Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M298.14 312.84L296.5 309.03 303.78 306.33 305.1 309.42 298.14 312.84Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M296.5 309.03L295.26 305.07 302.77 303.13 303.78 306.33 296.5 309.03Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M295.26 305.07L294.43 301.01 302.1 299.84 302.77 303.13 295.26 305.07Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M294.43 301.01L294.01 296.89 301.76 296.5 302.1 299.84 294.43 301.01Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M294.01 296.89L294.01 292.74 301.76 293.14 301.76 296.5 294.01 296.89Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M294.01 292.74L294.43 288.62 302.1 289.8 301.76 293.14 294.01 292.74Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M294.43 288.62L295.26 284.56 302.77 286.51 302.1 289.8 294.43 288.62Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M295.26 284.56L296.5 280.61 303.78 283.3 302.77 286.51 295.26 284.56Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M296.5 280.61L298.14 276.8 305.1 280.21 303.78 283.3 296.5 280.61Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M298.14 276.8L300.15 273.17 306.73 277.28 305.1 280.21 298.14 276.8Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M300.15 273.17L302.52 269.77 308.65 274.52 306.73 277.28 300.15 273.17Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M302.52 269.77L305.22 266.63 310.84 271.97 308.65 274.52 302.52 269.77Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M305.22 266.63L308.22 263.77 313.27 269.66 310.84 271.97 305.22 266.63Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M308.22 263.77L311.5 261.23 315.93 267.6 313.27 269.66 308.22 263.77Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M311.5 261.23L315.02 259.04 318.78 265.82 315.93 267.6 311.5 261.23Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M315.02 259.04L318.74 257.22 321.8 264.35 318.78 265.82 315.02 259.04Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M318.74 257.22L322.62 255.78 324.95 263.18 321.8 264.35 318.74 257.22Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M322.62 255.78L326.64 254.74 328.2 262.34 324.95 263.18 322.62 255.78Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M326.64 254.74L330.73 254.11 331.52 261.83 328.2 262.34 326.64 254.74Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M330.73 254.11L334.87 253.9 334.87 261.66 331.52 261.83 330.73 254.11Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M334.87 253.9L339.01 254.11 338.23 261.83 334.87 261.66 334.87 253.9Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M339.01 254.11L343.11 254.74 341.55 262.34 338.23 261.83 339.01 254.11Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M343.11 254.74L347.12 255.78 344.8 263.18 341.55 262.34 343.11 254.74Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M347.12 255.78L351.01 257.22 347.95 264.35 344.8 263.18 347.12 255.78Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M351.01 257.22L354.73 259.04 350.96 265.82 347.95 264.35 351.01 257.22Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M354.73 259.04L358.25 261.23 353.81 267.6 350.96 265.82 354.73 259.04Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M358.25 261.23L361.52 263.77 356.47 269.66 353.81 267.6 358.25 261.23Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M361.52 263.77L364.53 266.63 358.91 271.97 356.47 269.66 361.52 263.77Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M364.53 266.63L367.23 269.77 361.09 274.52 358.91 271.97 364.53 266.63Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M367.23 269.77L369.59 273.17 363.01 277.28 361.09 274.52 367.23 269.77Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M369.59 273.17L371.61 276.8 364.64 280.21 363.01 277.28 369.59 273.17Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M371.61 276.8L373.24 280.61 365.97 283.3 364.64 280.21 371.61 276.8Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M373.24 280.61L374.48 284.56 366.97 286.51 365.97 283.3 373.24 280.61Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M374.48 284.56L375.32 288.62 367.65 289.8 366.97 286.51 374.48 284.56Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M375.32 288.62L375.73 292.74 367.99 293.14 367.65 289.8 375.32 288.62Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M375.73 292.74L375.73 296.89 367.99 296.5 367.99 293.14 375.73 292.74Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M375.73 296.89L375.32 301.01 367.65 299.84 367.99 296.5 375.73 296.89Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M375.32 301.01L374.48 305.07 366.97 303.13 367.65 299.84 375.32 301.01Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M374.48 305.07L373.24 309.03 365.97 306.33 366.97 303.13 374.48 305.07Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M373.24 309.03L371.61 312.84 364.64 309.42 365.97 306.33 373.24 309.03Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M371.61 312.84L369.59 316.46 363.01 312.36 364.64 309.42 371.61 312.84Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M369.59 316.46L367.23 319.86 361.09 315.11 363.01 312.36 369.59 316.46Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M367.23 319.86L364.53 323.01 358.91 317.66 361.09 315.11 367.23 319.86Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M364.53 323.01L361.52 325.86 356.47 319.98 358.91 317.66 364.53 323.01Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M361.52 325.86L358.25 328.4 353.81 322.03 356.47 319.98 361.52 325.86Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M358.25 328.4L354.73 330.59 350.96 323.81 353.81 322.03 358.25 328.4Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M354.73 330.59L351.01 332.42 347.95 325.29 350.96 323.81 354.73 330.59Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M351.01 332.42L347.12 333.86 344.8 326.45 347.95 325.29 351.01 332.42Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M347.12 333.86L343.11 334.89 341.55 327.3 344.8 326.45 347.12 333.86Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M343.11 334.89L339.01 335.52 338.23 327.8 341.55 327.3 343.11 334.89Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M339.01 335.52L334.87 335.73 334.87 327.97 338.23 327.8 339.01 335.52Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
</svg>
    );
    export default Svg2_07_01;

    