
    const Svg2_01_08 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="275.95 234.3 233.21000000000004 140.01" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M280.95 325.48L367.13 325.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.13 325.48L367.13 239.3Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.13 239.3L280.95 239.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M280.95 239.3L280.95 325.48Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M305.65 303.94L305.65 260.85Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M305.65 260.85L332.33 260.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M305.65 281.37L322.07 281.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.07 369.31L410.8 326.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.07 326.22L410.8 369.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.11 369.31L451.84 326.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.11 326.22L451.84 369.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.45 282.39L367.13 282.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.45 282.39L452.45 282.39 420.78 291.01 420.78 273.77 452.45 282.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M495.54 282.39L495.35 285.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M495.35 285.26L494.77 288.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M494.77 288.08L493.83 290.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M493.83 290.8L492.53 293.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M492.53 293.37L490.9 295.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M490.9 295.74L488.97 297.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.97 297.88L486.78 299.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M486.78 299.74L484.35 301.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M484.35 301.29L481.74 302.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.74 302.5L478.99 303.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.99 303.35L476.15 303.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M476.15 303.83L473.28 303.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M473.28 303.92L470.41 303.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.41 303.64L467.61 302.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.61 302.97L464.93 301.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.93 301.94L462.4 300.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M462.4 300.55L460.08 298.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.08 298.84L458.02 296.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.02 296.84L456.23 294.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M456.23 294.59L454.77 292.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.77 292.11L453.64 289.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M453.64 289.46L452.88 286.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.88 286.68L452.5 283.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.5 283.83L452.5 280.95Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.5 280.95L452.88 278.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.88 278.1L453.64 275.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M453.64 275.32L454.77 272.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.77 272.67L456.23 270.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M456.23 270.2L458.02 267.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.02 267.94L460.08 265.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.08 265.94L462.4 264.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M462.4 264.23L464.93 262.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.93 262.85L467.61 261.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.61 261.81L470.41 261.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.41 261.15L473.28 260.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M473.28 260.86L476.15 260.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M476.15 260.95L478.99 261.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.99 261.43L481.74 262.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M481.74 262.29L484.35 263.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M484.35 263.5L486.78 265.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M486.78 265.05L488.97 266.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.97 266.9L490.9 269.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M490.9 269.04L492.53 271.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M492.53 271.41L493.83 273.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M493.83 273.98L494.77 276.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M494.77 276.7L495.35 279.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M495.35 279.52L495.54 282.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M474 312.55L474 252.23Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.83 282.39L504.16 282.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_01_08;

    