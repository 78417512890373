
    const Svg1_03_01 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="309.0 234.15 125.80000000000001 125.79999999999998" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M371.9 239.15L314 297.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.8 297.05L371.9 239.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M314 297.05L429.8 297.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M342.95 354.95L342.95 297.05Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.85 354.95L400.85 297.05Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_03_01;

    