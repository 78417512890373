
    const Svg1_06_01 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="286.32 169.9 237.65000000000003 237.65" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M518.97 402.55L518.97 174.9Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M291.32 174.9L291.32 402.55Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M291.32 402.55L518.97 402.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M518.97 174.9L291.32 174.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M291.32 402.55L518.97 174.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M300.58 257.6L341.05 196.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M300.58 196.89L341.05 257.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.39 257.6L398.86 196.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.39 196.89L398.86 257.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.66 391.52L431.13 330.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.66 330.81L431.13 391.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M448.48 391.52L488.95 330.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M448.48 330.81L488.95 391.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_06_01;

    