
    const Svg1_01_05 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="305.04 220.92 168.89 113.24000000000004" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M468.93 249.74L310.04 249.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.06 273.57L310.06 225.92Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M468.92 273.57L468.92 225.92Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.19 289.46L428.99 293.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.99 293.42L428.4 297.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.4 297.33L427.43 301.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.43 301.17L426.07 304.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.07 304.89L424.35 308.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.35 308.46L422.29 311.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.29 311.83L419.9 314.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.9 314.99L417.21 317.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.21 317.89L414.24 320.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.24 320.51L411.02 322.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.02 322.82L407.59 324.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.59 324.8L403.99 326.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.99 326.43L400.23 327.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.23 327.69L396.37 328.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.37 328.57L392.44 329.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.44 329.07L388.49 329.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.49 329.16L384.54 328.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.54 328.87L380.64 328.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.64 328.18L376.83 327.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.83 327.11L373.14 325.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M373.14 325.66L369.62 323.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.62 323.86L366.29 321.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.29 321.71L363.2 319.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.2 319.24L360.36 316.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.36 316.47L357.82 313.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M357.82 313.44L355.59 310.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.59 310.17L353.69 306.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.69 306.69L352.15 303.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.15 303.04L350.99 299.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.99 299.26L350.2 295.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.2 295.38L349.81 291.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.81 291.44L349.81 287.48Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.81 287.48L350.2 283.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.2 283.54L350.99 279.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.99 279.66L352.15 275.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.15 275.88L353.69 272.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.69 272.23L355.59 268.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.59 268.75L357.82 265.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M357.82 265.48L360.36 262.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.36 262.45L363.2 259.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.2 259.68L366.29 257.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.29 257.21L369.62 255.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.62 255.07L373.14 253.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M373.14 253.26L376.83 251.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.83 251.81L380.64 250.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.64 250.74L384.54 250.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.54 250.05L388.49 249.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.49 249.76L392.44 249.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.44 249.86L396.37 250.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.37 250.35L400.23 251.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.23 251.23L403.99 252.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.99 252.49L407.59 254.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.59 254.12L411.02 256.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.02 256.1L414.24 258.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.24 258.41L417.21 261.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.21 261.03L419.9 263.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.9 263.93L422.29 267.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.29 267.09L424.35 270.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.35 270.47L426.07 274.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.07 274.03L427.43 277.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.43 277.75L428.4 281.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.4 281.59L428.99 285.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.99 285.51L429.19 289.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_05;

    