import { IDictionary, PropsOf } from '../../../../shared/utils/types';
import { IInput, ItemTypes } from '../interfaces';

import Api from '../../../../shared/networking/api';
import SelectBox from 'devextreme-react/select-box';
import { SimpleItem } from 'devextreme-react/form';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { defaultIInputs } from '../defaults';
import { generateProps } from './generate';
import { useArgsMemo } from '../../../../shared/hooks/use-args';
import { useState } from 'react';

const InputLookup = (input: IInput) => {
    return (
        <SimpleItem
            {...generateProps(input, defaultIInputs[ItemTypes.Lookup])}
            render={(item) => getRender({ item, input })}
        ></SimpleItem>
    );
};

export default InputLookup;

// Input from v1 dx-form-items

const getRender = (props: { item: PropsOf<SimpleItem>; input: IInput }) => <Render {...props} />;
const Render = ({ item, input }: { item: PropsOf<SimpleItem>; input: IInput }) => {
    const [value, setValue] = useState(item.editorOptions?.value);
    const [open, setOpen] = useState(false);

    const onValueChanged = async (e: { value: unknown }) => {
        setValue(e.value);
    };

    const dataSource = useArgsMemo(
        (_, [choices]) => {
            if (typeof choices !== 'string') return choices;

            return createStore({
                key: 'id',
                loadUrl: Api.createUrl(''),
                loadMode: 'raw',
                onBeforeSend: (_operation, ajax) => {
                    const headers: IDictionary<string> = {
                        Authorization: `Bearer ${Api.token}`,
                    };
                    ajax.headers = headers;
                },
                cacheRawData: true,
            });
        },
        [] as const,
        [input.choices] as const
    );

    return (
        <SelectBox
            {...item.editorOptions}
            dataSource={dataSource}
            showClearButton={open && (!input.required ?? false)}
            showDropDownButton={true}
            placeholder={'Vælg'}
            displayExpr='value'
            valueExpr='id'
            value={value}
            deferRendering={false}
            searchEnabled={true}
            onOpened={() => setOpen(true)}
            onClosed={() => setOpen(false)}
            onValueChanged={onValueChanged}
            name={input.id}
        ></SelectBox>
    );
};
