import { CSSProperties, forwardRef } from 'react';

import { ScrollView } from 'devextreme-react/scroll-view';

interface IColumnProps {
    children?: JSX.Element | JSX.Element[];
    footer?: JSX.Element;
    footerStyle?: CSSProperties;
    header?: JSX.Element;
    headerStyle?: CSSProperties;
    innerStyle?: CSSProperties;
    style?: CSSProperties;
}

interface IFlexColumnProps extends IColumnProps {
    scroll?: boolean;
    style?: CSSProperties;
}

interface IFlexRowProps {
    children?: JSX.Element | JSX.Element[];
    innerStyle?: CSSProperties;
    left?: JSX.Element;
    leftStyle?: CSSProperties;
    right?: JSX.Element;
    rightStyle?: CSSProperties;
    style?: CSSProperties;
}

export const FlexColumn = forwardRef<HTMLDivElement, IFlexColumnProps>((props, ref) => (
    <div className='flex-column-container' ref={ref} style={props.style}>
        <div className='flex-item-no-fill' style={props.headerStyle}>
            {props.header}
        </div>
        <div className={props.scroll ? 'flex-item-fill' : 'flex-item-fill-no-scroll'} style={props.innerStyle}>
            {props.children}
        </div>
        <div className='flex-item-no-fill' style={props.footerStyle}>
            {props.footer}
        </div>
    </div>
));

FlexColumn.displayName = 'FlexColumn';

export const FlexRow = forwardRef<HTMLDivElement, IFlexRowProps>((props, ref) => (
    <div className='flex-row-container' ref={ref} style={props.style}>
        <div className='flex-item-no-fill' style={props.leftStyle}>
            {props.left}
        </div>
        <div className='flex-item-fill-no-scroll vertical-center-items' style={props.innerStyle}>
            {props.children}
        </div>
        <div className='flex-item-no-fill' style={props.rightStyle}>
            {props.right}
        </div>
    </div>
));

FlexRow.displayName = 'FlexRow';

export const Scrollable = forwardRef<HTMLDivElement, IColumnProps>((props, ref) => (
    <div className='flex-column-container' style={{ height: '100%', ...props.style }} ref={ref}>
        <div className='flex-item-no-fill' style={props.headerStyle}>
            {props.header}
        </div>
        <div className='flex-item-fill' style={props.innerStyle}>
            <ScrollView showScrollbar='onScroll'>
                <div>{props.children}</div>
            </ScrollView>
        </div>
        <div className='flex-item-no-fill' style={props.footerStyle}>
            {props.footer}
        </div>
    </div>
));

Scrollable.displayName = 'Scrollable';
