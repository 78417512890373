
    const Svg1_01_12 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="248.3 155.87 220.45 273.06" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M253.3 160.87L253.3 423.93Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M253.3 292.4L463.75 397.62 463.75 397.62 463.75 187.17 253.3 292.4Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
</svg>
    );
    export default Svg1_01_12;

    