
    const SvgXx_06_11 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="265.95 173.75 286.43 286.43" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M547.38 178.75L484.23 199.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M484.23 199.8L526.33 241.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M526.33 241.91L547.38 178.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M547.38 178.75L547.38 178.75 526.33 241.91 484.23 199.8 547.38 178.75Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M547.38 178.75L294.77 431.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M505.28 220.85L442.12 241.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.12 241.91L484.23 284.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M484.23 284.01L505.28 220.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M505.28 220.85L505.28 220.85 484.23 284.01 442.12 241.91 505.28 220.85Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M270.95 431.36L271.31 435.5 294.77 431.36 294.77 431.36 270.95 431.36Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M271.31 435.5L272.39 439.51 294.77 431.36 294.77 431.36 271.31 435.5Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M272.39 439.51L274.14 443.27 294.77 431.36 294.77 431.36 272.39 439.51Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M274.14 443.27L276.52 446.67 294.77 431.36 294.77 431.36 274.14 443.27Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M276.52 446.67L279.46 449.61 294.77 431.36 294.77 431.36 276.52 446.67Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M279.46 449.61L282.86 451.99 294.77 431.36 294.77 431.36 279.46 449.61Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M282.86 451.99L286.62 453.74 294.77 431.36 294.77 431.36 282.86 451.99Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M286.62 453.74L290.63 454.82 294.77 431.36 294.77 431.36 286.62 453.74Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M290.63 454.82L294.77 455.18 294.77 431.36 294.77 431.36 290.63 454.82Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M294.77 455.18L298.9 454.82 294.77 431.36 294.77 431.36 294.77 455.18Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M298.9 454.82L302.91 453.74 294.77 431.36 294.77 431.36 298.9 454.82Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M302.91 453.74L306.68 451.99 294.77 431.36 294.77 431.36 302.91 453.74Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M306.68 451.99L310.08 449.61 294.77 431.36 294.77 431.36 306.68 451.99Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M310.08 449.61L313.01 446.67 294.77 431.36 294.77 431.36 310.08 449.61Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M313.01 446.67L315.39 443.27 294.77 431.36 294.77 431.36 313.01 446.67Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M315.39 443.27L317.15 439.51 294.77 431.36 294.77 431.36 315.39 443.27Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M317.15 439.51L318.22 435.5 294.77 431.36 294.77 431.36 317.15 439.51Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M318.22 435.5L318.58 431.36 294.77 431.36 294.77 431.36 318.22 435.5Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M318.58 431.36L318.22 427.23 294.77 431.36 294.77 431.36 318.58 431.36Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M318.22 427.23L317.15 423.22 294.77 431.36 294.77 431.36 318.22 427.23Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M317.15 423.22L315.39 419.46 294.77 431.36 294.77 431.36 317.15 423.22Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M315.39 419.46L313.01 416.05 294.77 431.36 294.77 431.36 315.39 419.46Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M313.01 416.05L310.08 413.12 294.77 431.36 294.77 431.36 313.01 416.05Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M310.08 413.12L306.68 410.74 294.77 431.36 294.77 431.36 310.08 413.12Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M306.68 410.74L302.91 408.98 294.77 431.36 294.77 431.36 306.68 410.74Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M302.91 408.98L298.9 407.91 294.77 431.36 294.77 431.36 302.91 408.98Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M298.9 407.91L294.77 407.55 294.77 431.36 294.77 431.36 298.9 407.91Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M294.77 407.55L290.63 407.91 294.77 431.36 294.77 431.36 294.77 407.55Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M290.63 407.91L286.62 408.98 294.77 431.36 294.77 431.36 290.63 407.91Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M286.62 408.98L282.86 410.74 294.77 431.36 294.77 431.36 286.62 408.98Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M282.86 410.74L279.46 413.12 294.77 431.36 294.77 431.36 282.86 410.74Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M279.46 413.12L276.52 416.05 294.77 431.36 294.77 431.36 279.46 413.12Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M276.52 416.05L274.14 419.46 294.77 431.36 294.77 431.36 276.52 416.05Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M274.14 419.46L272.39 423.22 294.77 431.36 294.77 431.36 274.14 419.46Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M272.39 423.22L271.31 427.23 294.77 431.36 294.77 431.36 272.39 423.22Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
<path d="M271.31 427.23L270.95 431.36 294.77 431.36 294.77 431.36 271.31 427.23Z" fillRule="nonzero" fill='{{replacement-value-color}}' />
</svg>
    );
    export default SvgXx_06_11;

    