const SvgEltavleS = () => (
    <svg xmlns='http://www.w3.org/2000/svg' xmlSpace='preserve' width={20.632} height={28.82}>
        <path
            d='M37.36 45.316v-14.41H57.994v28.82H37.361Zm19.65.164V32.216H38.344v26.528h18.668zM39.55 57.876c-.124-.123-.224-5.8-.224-12.614v-12.39h16.71l-.086 12.526-.086 12.527-8.045.087c-4.425.048-8.146-.013-8.27-.136m9.231-9.195c1.825-2.743 3.317-5.138 3.317-5.322 0-.192-.854-.335-2-.335h-2l.191-.498c.105-.274.794-1.385 1.53-2.47l1.34-1.973-2.528.096-2.528.096-.457 1.31c-.251.72-.736 2.345-1.078 3.61-.341 1.264-.718 2.554-.838 2.865l-.217.566h3.583l-1.098 3.685c-.604 2.026-1.1 3.75-1.102 3.831-.007.321.7-.672 3.885-5.461'
            style={{
                fill: '{{replacement-value-color}}',
                fillOpacity: 1,
            }}
            transform='translate(-37.36 -30.906)'
        />
    </svg>
);
export default SvgEltavleS;
