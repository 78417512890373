
    const Svg4_05_11 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="343.34 205.74 177.92000000000002 199.0" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M437.37 210.74L375.13 210.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.13 293.84L375.13 210.74Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.13 210.74L437.37 210.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.37 210.74L437.37 293.84Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.17 293.84L348.34 293.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.54 293.84L359.54 302.9Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.54 315.44L359.54 327.98Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.54 340.52L359.54 353.06Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.54 365.6L359.54 378.14Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.54 390.68L359.54 399.74Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.96 293.84L452.96 302.9Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.96 315.44L452.96 327.98Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.96 340.52L452.96 353.06Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.96 365.6L452.96 378.14Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.96 390.68L452.96 399.74Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.54 399.74L374.9 399.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.44 399.74L399.98 399.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.52 399.74L425.06 399.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.6 399.74L452.96 399.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M430.95 345.32L430.75 348.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M430.75 348.42L430.17 351.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M430.17 351.46L429.21 354.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.21 354.41L427.89 357.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.89 357.22L426.23 359.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.23 359.84L424.26 362.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.26 362.23L421.99 364.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.99 364.35L419.49 366.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.49 366.17L416.77 367.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.77 367.67L413.88 368.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.88 368.81L410.88 369.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.88 369.58L407.8 369.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.8 369.97L404.7 369.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.7 369.97L401.62 369.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.62 369.58L398.62 368.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.62 368.81L395.74 367.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.74 367.67L393.02 366.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.02 366.17L390.51 364.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.51 364.35L388.25 362.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.25 362.23L386.27 359.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.27 359.84L384.61 357.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.61 357.22L383.29 354.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.29 354.41L382.33 351.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.33 351.46L381.75 348.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.75 348.42L381.55 345.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.55 345.32L381.75 342.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.75 342.23L382.33 339.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.33 339.18L383.29 336.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.29 336.23L384.61 333.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.61 333.42L386.27 330.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.27 330.8L388.25 328.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.25 328.41L390.51 326.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.51 326.29L393.02 324.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.02 324.47L395.74 322.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.74 322.97L398.62 321.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.62 321.83L401.62 321.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.62 321.06L404.7 320.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.7 320.67L407.8 320.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.8 320.67L410.88 321.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.88 321.06L413.88 321.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.88 321.83L416.77 322.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.77 322.97L419.49 324.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.49 324.47L421.99 326.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.99 326.29L424.26 328.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.26 328.41L426.23 330.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.23 330.8L427.89 333.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.89 333.42L429.21 336.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.21 336.23L430.17 339.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M430.17 339.18L430.75 342.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M430.75 342.23L430.95 345.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.25 320.62L384.86 357.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.86 357.67L427.64 357.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.64 357.67L406.25 320.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M465.5 362.46L486.4 331.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M465.5 331.12L486.4 362.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M495.36 362.46L516.26 331.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M495.36 331.12L516.26 362.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg4_05_11;

    