
    const Svg3_01_06 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="289.3 238.13 247.07999999999998 133.69" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M 459.96 356.52 A 76.8 76.8 0 0 0 459.96 253.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.28 304.97L531.38 304.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.28 304.97L446.14 294.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.28 304.97L446.14 315.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.96 253.42L462.71 273.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.96 253.42L479.03 261.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.96 356.52L462.71 336.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.96 356.52L479.03 348.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M531.38 304.97L513.52 294.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M531.38 304.97L513.52 315.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.97 304.97L417.77 309.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.77 309.89L417.19 314.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.19 314.77L416.22 319.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.22 319.59L414.87 324.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.87 324.32L413.14 328.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.14 328.93L411.06 333.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.06 333.38L408.63 337.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.63 337.66L405.87 341.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.87 341.73L402.79 345.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.79 345.57L399.42 349.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.42 349.15L395.77 352.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.77 352.45L391.87 355.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.87 355.45L387.75 358.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.75 358.13L383.43 360.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.43 360.47L378.93 362.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.93 362.47L374.29 364.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.29 364.1L369.53 365.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.53 365.36L364.69 366.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.69 366.23L359.8 366.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.8 366.72L354.88 366.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.88 366.82L349.97 366.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.97 366.53L345.1 365.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.1 365.84L340.3 364.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.3 364.78L335.6 363.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M335.6 363.33L331.03 361.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M331.03 361.52L326.61 359.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M326.61 359.34L322.39 356.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.39 356.83L318.37 353.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M318.37 353.99L314.6 350.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M314.6 350.83L311.09 347.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M311.09 347.39L307.86 343.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M307.86 343.68L304.94 339.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M304.94 339.72L302.34 335.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M302.34 335.55L300.08 331.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M300.08 331.18L298.17 326.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M298.17 326.64L296.63 321.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M296.63 321.97L295.47 317.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M295.47 317.19L294.69 312.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M294.69 312.33L294.3 307.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M294.3 307.43L294.3 302.51Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M294.3 302.51L294.69 297.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M294.69 297.61L295.47 292.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M295.47 292.75L296.63 287.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M296.63 287.97L298.17 283.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M298.17 283.3L300.08 278.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M300.08 278.77L302.34 274.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M302.34 274.4L304.94 270.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M304.94 270.22L307.86 266.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M307.86 266.27L311.09 262.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M311.09 262.56L314.6 259.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M314.6 259.11L318.37 255.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M318.37 255.96L322.39 253.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.39 253.12L326.61 250.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M326.61 250.6L331.03 248.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M331.03 248.43L335.6 246.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M335.6 246.61L340.3 245.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.3 245.17L345.1 244.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.1 244.1L349.97 243.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.97 243.42L354.88 243.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.88 243.13L359.8 243.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.8 243.22L364.69 243.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.69 243.71L369.53 244.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.53 244.59L374.29 245.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.29 245.84L378.93 247.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.93 247.48L383.43 249.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.43 249.47L387.75 251.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.75 251.82L391.87 254.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.87 254.5L395.77 257.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.77 257.5L399.42 260.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.42 260.8L402.79 264.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.79 264.38L405.87 268.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.87 268.22L408.63 272.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.63 272.28L411.06 276.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.06 276.56L413.14 281.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.14 281.02L414.87 285.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.87 285.62L416.22 290.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.22 290.35L417.19 295.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.19 295.17L417.77 300.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.77 300.06L417.97 304.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.23 300.85L380.86 300.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.23 309.1L393.23 300.85Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.86 309.1L393.23 309.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319 321.47L380.86 321.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.86 321.47L380.86 288.48Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.86 288.48L319 288.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319 288.48L319 321.47Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg3_01_06;

    