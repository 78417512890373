
    const Svg2_01_07 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="263.98 210.02 299.90999999999997 154.25999999999996" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M268.98 359.28L413.25 359.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.25 359.28L413.25 215.02Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.25 215.02L268.98 215.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M268.98 215.02L268.98 359.28Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M268.98 359.28L413.25 215.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M333.71 250.62L323.4 254.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.4 254.05L316.53 264.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M316.53 264.36L313.1 281.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M313.1 281.53L313.1 291.83Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M313.1 291.83L316.53 309.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M316.53 309.01L323.4 319.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.4 319.31L333.71 322.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M333.71 322.75L340.58 322.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.58 322.75L350.88 319.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.88 319.31L357.75 309.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M357.75 309.01L361.19 291.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.19 291.83L361.19 281.53Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.19 281.53L357.75 264.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M357.75 264.36L350.88 254.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.88 254.05L340.58 250.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.58 250.62L333.71 250.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.11 359.28L490.2 287.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.11 287.15L490.2 359.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M510.81 359.28L558.89 287.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M510.81 287.15L558.89 359.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_01_07;

    