
    const Svg1_05_04C = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="305.91 157.9 270.84999999999997 270.86" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M385.44 349.23L571.76 349.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M571.76 349.23L571.76 311.97Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M571.76 311.97L385.44 311.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M385.44 311.97L385.44 349.23Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M497.23 349.23L497.23 423.76Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M497.23 423.76L310.91 423.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.91 423.76L310.91 162.9Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.91 162.9L497.23 162.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M497.23 162.9L497.23 311.97Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.17 274.7L459.97 274.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.97 274.7L459.97 200.17Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.97 200.17L348.17 200.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.17 200.17L348.17 274.7Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M385.44 200.17L385.44 274.7Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.7 200.17L422.7 274.7Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_05_04C;

    