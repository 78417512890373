import { IFile } from '../file-displaying/common';
import ImageGrid from '../file-displaying/image-grid';
import { RefObject } from 'react';

export const AutoGridImageGrid = ({ filelist }: { filelist: RefObject<IFile[]> }) => {
    // const { filelist } = useContext(AutoGridContext);
    return <ImageGrid files={filelist.current?.filter((file) => file.type === 'image') ?? []} />;
};

export const AutoGridFileGrid = ({ filelist }: { filelist: RefObject<IFile[]> }) => {
    // const { filelist } = useContext(AutoGridContext);
    return <ImageGrid files={filelist.current?.filter((file) => file.type === 'file') ?? []} />;
};
