import { Card, CardMedia, IconButton } from '@mui/material';
import useImageFetch from '../../shared/hooks/fetch-hooks/use-image-fetch';
import { CenterCenterBox } from '../mui/styled-mui';
import DragDropUploaderPopup from '../file-management/drag-drop-uploader-popup';
import { useState } from 'react';
import config from '../../config';
import { useCTEffect } from '../../shared/hooks/use-ct';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import LoadingScreen from '../../screens/loading';

interface ImageFavoriteProps {
    id?: string;
    maxHeight?: string;
}

const ImageFavorite = ({ id, maxHeight = '400px' }: ImageFavoriteProps) => {
    const [image, isLoading, isError, , , refetch] = useImageFetch(`/Byg/favoritbillede/${id}`, { autoFetch: false });
    const [isVisible, setIsVisible] = useState(false);

    const onClick = () => {
        setIsVisible(true);
    };

    useCTEffect(() => {
        refetch();
    }, [id]);

    if (isLoading && !image && !isError) {
        return <LoadingScreen />;
    }

    return (
        <>
            <CenterCenterBox p='2px' pb='0px' overflow={'hidden'}>
                <Card sx={{ width: 'auto', maxHeight: maxHeight }}>
                    {/* <CardHeader
                        action={
                            <IconButton aria-label='settings' onClick={onClick}>
                                <MoreVertIcon />
                            </IconButton>
                        }
                        title={data?.name}
                        // subheader='Evt extra info'
                    /> */}
                    {isError ? (
                        <IconButton onClick={onClick}>
                            <AddAPhotoIcon />
                        </IconButton>
                    ) : (
                        <CardMedia
                            onClick={onClick}
                            component='img'
                            height='194'
                            image={image}
                            style={{ maxHeight: maxHeight }}
                        />
                    )}
                </Card>
            </CenterCenterBox>
            <DragDropUploaderPopup
                uploadUrl={`${config.SERVER_URL}/Byg/favoritbillede/${id}`}
                popupOptions={{
                    visible: isVisible,
                    overrideCloseFunction: () => setIsVisible(false),
                    hideOnOutsideClick: true,
                    height: '50%',
                    onSave: () => {
                        refetch();
                        setIsVisible(false);
                    },
                }}
                uploaderOptions={{
                    multiple: false,
                }}
            />
        </>
    );
};
export default ImageFavorite;
