
    const Svg1_05_02 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="307.45 229.95 155.32999999999998 155.32" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M457.78 380.27L457.78 234.95Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M312.45 234.95L312.45 380.27Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.78 234.95L312.45 380.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M312.45 380.27L457.78 380.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.78 234.95L312.45 234.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M312.45 234.95L457.78 380.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_05_02;

    