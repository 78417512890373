const SvgEraserFill = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={16}
        height={16}
        fill='{{replacement-value-color}}'
        className='eraser-fill_svg__bi eraser-fill_svg__bi-eraser-fill'
    >
        <path d='M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828zm.66 11.34L3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293z' />
    </svg>
);
export default SvgEraserFill;
