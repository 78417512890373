import { ILayer, Layers } from '../LayerMenuControl';

import { Control } from 'ol/control';
import { SqlLayer } from './SqlLayer';

/*------------------------------------------------------------------------
--------------------------------------------------------------------------
--------------------------------------------------------------------------
--------------- DEPRECATED. SPLIT INTO EDITING AND SQLSAVE ---------------
----------------------- Everything depr was deleted ----------------------
--------------------------------------------------------------------------
------------------------------------------------------------------------*/

/**
 * @deprecated
 * @todo Move function to the class using it
 */
export class SQLControl extends Control {
    public static ControlName: string = 'SQLControl';

    public static RetrieveSqlILayers(allLayers: Layers): ILayer<SqlLayer>[] {
        const overlays = allLayers.getUrlLayers();
        const sqlILayers: ILayer<SqlLayer>[] = [];
        Object.keys(overlays).forEach((key) => {
            if (key.includes('DEL')) sqlILayers.push(overlays[key] as ILayer<SqlLayer>);
        });
        return sqlILayers;
    }
}
