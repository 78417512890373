
    const Svg2_06_05 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="340.82 209.86 153.39 153.41999999999996" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M489.21 286.57L489.02 291.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M489.02 291.86L488.43 297.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.43 297.13L487.46 302.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M487.46 302.34L486.1 307.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M486.1 307.47L484.37 312.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M484.37 312.48L482.27 317.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M482.27 317.34L479.82 322.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M479.82 322.04L477.03 326.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M477.03 326.55L473.92 330.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M473.92 330.84L470.49 334.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.49 334.89L466.78 338.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.78 338.67L462.8 342.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M462.8 342.17L458.57 345.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.57 345.36L454.11 348.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.11 348.23L449.46 350.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.46 350.77L444.63 352.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.63 352.96L439.65 354.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.65 354.78L434.56 356.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.56 356.23L429.36 357.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.36 357.3L424.11 357.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.11 357.98L418.82 358.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.82 358.28L413.52 358.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.52 358.18L408.24 357.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.24 357.69L403.01 356.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.01 356.81L397.86 355.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.86 355.55L392.82 353.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.82 353.91L387.92 351.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.92 351.91L383.17 349.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.17 349.54L378.61 346.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.61 346.84L374.27 343.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.27 343.8L370.16 340.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.16 340.45L366.31 336.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.31 336.81L362.74 332.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.74 332.89L359.47 328.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.47 328.72L356.51 324.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.51 324.32L353.89 319.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.89 319.72L351.61 314.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M351.61 314.93L349.7 309.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.7 309.99L348.16 304.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.16 304.92L346.99 299.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.99 299.75L346.21 294.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.21 294.5L345.82 289.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.82 289.22L345.82 283.92Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.82 283.92L346.21 278.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.21 278.63L346.99 273.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.99 273.39L348.16 268.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.16 268.22L349.7 263.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.7 263.15L351.61 258.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M351.61 258.2L353.89 253.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.89 253.42L356.51 248.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.51 248.81L359.47 244.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.47 244.41L362.74 240.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.74 240.24L366.31 236.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.31 236.32L370.16 232.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.16 232.68L374.27 229.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.27 229.33L378.61 226.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.61 226.3L383.17 223.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.17 223.59L387.92 221.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.92 221.23L392.82 219.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.82 219.22L397.86 217.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.86 217.58L403.01 216.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.01 216.32L408.24 215.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.24 215.44L413.52 214.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.52 214.96L418.82 214.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.82 214.86L424.11 215.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.11 215.15L429.36 215.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.36 215.83L434.56 216.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.56 216.9L439.65 218.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.65 218.36L444.63 220.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.63 220.18L449.46 222.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.46 222.36L454.11 224.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.11 224.9L458.57 227.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.57 227.77L462.8 230.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M462.8 230.97L466.78 234.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.78 234.47L470.49 238.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.49 238.25L473.92 242.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M473.92 242.29L477.03 246.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M477.03 246.58L479.82 251.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M479.82 251.09L482.27 255.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M482.27 255.79L484.37 260.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M484.37 260.66L486.1 265.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M486.1 265.67L487.46 270.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M487.46 270.79L488.43 276" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.43 276L489.02 281.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M489.02 281.27L489.21 286.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.77 275.47L365.18 273.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.18 273.76L370.3 268.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M370.3 268.64L370.3 304.5Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.34 268.64L394.21 270.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.21 270.34L390.8 275.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.8 275.47L389.09 284.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.09 284.01L389.09 289.13Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.09 289.13L390.8 297.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.8 297.67L394.21 302.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.21 302.79L399.34 304.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.34 304.5L402.75 304.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.75 304.5L407.87 302.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.87 302.79L411.29 297.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.29 297.67L413 289.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413 289.13L413 284.01Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413 284.01L411.29 275.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.29 275.47L407.87 270.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.87 270.34L402.75 268.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.75 268.64L399.34 268.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.24 275.47L426.66 273.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.66 273.76L431.78 268.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.78 268.64L431.78 304.5Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.81 268.64L455.69 270.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.69 270.34L452.27 275.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.27 275.47L450.57 284.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.57 284.01L450.57 289.13Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.57 289.13L452.27 297.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.27 297.67L455.69 302.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.69 302.79L460.81 304.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.81 304.5L464.23 304.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.23 304.5L469.35 302.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M469.35 302.79L472.76 297.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M472.76 297.67L474.47 289.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M474.47 289.13L474.47 284.01Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M474.47 284.01L472.76 275.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M472.76 275.47L469.35 270.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M469.35 270.34L464.23 268.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.23 268.64L460.81 268.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_06_05;

    