import { IInput, ItemTypes } from '../interfaces';
import { generateProps, generateRules } from './generate';

import { SimpleItem } from 'devextreme-react/form';
import { defaultIInputs } from '../defaults';

const InputNumber = (input: IInput) => {
    return (
        <SimpleItem {...generateProps(input, defaultIInputs[ItemTypes.Number])}>
            {generateRules(input, defaultIInputs[ItemTypes.Number])}
        </SimpleItem>
    );
};

export default InputNumber;
