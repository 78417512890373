import { IInput, ItemTypes } from '../interfaces';

import { SimpleItem } from 'devextreme-react/form';
import Typography from '@mui/material/Typography';
import { defaultIInputs } from '../defaults';
import { generateProps } from './generate';

const InputNoInput = (input: IInput) => {
    return (
        <SimpleItem {...generateProps(input, defaultIInputs[ItemTypes.NoInput])}>
            <Typography align='left'>{input.label}</Typography>
        </SimpleItem>
    );
};

export default InputNoInput;
