
    const SvgXx_06_07 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="334.59 252.89999999999998 163.36 87.78000000000003" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M391.65 318.2L442.3 318.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M339.59 296.73L492.95 296.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.61 296.73L431.41 299.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.41 299.19L430.82 301.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M430.82 301.58L429.85 303.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.85 303.85L428.53 305.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.53 305.94L426.89 307.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.89 307.79L424.98 309.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.98 309.35L422.84 310.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.84 310.58L420.54 311.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.54 311.46L418.12 311.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.12 311.95L415.65 312.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.65 312.05L413.2 311.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.2 311.75L410.83 311.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.83 311.07L408.6 310.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.6 310.01L406.57 308.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.57 308.61L404.79 306.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.79 306.9L403.31 304.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.31 304.92L402.16 302.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.16 302.74L401.38 300.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.38 300.4L400.98 297.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.98 297.96L400.98 295.49Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.98 295.49L401.38 293.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.38 293.06L402.16 290.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.16 290.71L403.31 288.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.31 288.53L404.79 286.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.79 286.56L406.57 284.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.57 284.85L408.6 283.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.6 283.44L410.83 282.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.83 282.39L413.2 281.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.2 281.7L415.65 281.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.65 281.4L418.12 281.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.12 281.5L420.54 281.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.54 281.99L422.84 282.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.84 282.87L424.98 284.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.98 284.1L426.89 285.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.89 285.66L428.53 287.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.53 287.51L429.85 289.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.85 289.6L430.82 291.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M430.82 291.87L431.41 294.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.41 294.27L431.61 296.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.22 296.73L455.2 295.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.2 295.48L455.15 294.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.15 294.24L455.05 293" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.05 293L454.91 291.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.91 291.77L454.73 290.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.73 290.54L454.51 289.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.51 289.32L454.26 288.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.26 288.1L454.17 287.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.85 280.88L451.41 279.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.41 279.9L450.85 278.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.85 278.79L450.26 277.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.26 277.7L449.64 276.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.64 276.62L448.98 275.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M448.98 275.57L448.29 274.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M448.29 274.54L447.56 273.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.56 273.53L447.24 273.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.33 267.78L441.65 267.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.65 267.17L440.7 266.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.7 266.38L439.72 265.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.72 265.62L438.71 264.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438.71 264.89L437.69 264.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.69 264.19L436.64 263.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.64 263.52L435.57 262.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.57 262.89L434.95 262.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.31 259.68L427.6 259.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.6 259.45L426.4 259.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.4 259.11L425.2 258.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.2 258.81L423.98 258.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.98 258.54L422.76 258.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.76 258.32L421.53 258.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.53 258.13L420.3 257.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.3 257.98L419.44 257.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.2 257.99L411.62 258.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.62 258.05L410.39 258.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.39 258.22L409.17 258.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.17 258.42L407.95 258.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.95 258.67L406.74 258.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.74 258.95L405.54 259.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.54 259.28L404.35 259.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.35 259.64L403.37 259.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.79 262.99L396.44 263.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.44 263.2L395.38 263.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.38 263.85L394.34 264.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.34 264.53L393.32 265.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.32 265.25L392.33 265.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.33 265.99L391.36 266.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.36 266.77L390.42 267.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.42 267.58L389.54 268.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.76 273.83L384.61 274.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.61 274.03L383.9 275.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.9 275.05L383.23 276.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.23 276.09L382.59 277.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.59 277.16L381.98 278.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.98 278.24L381.41 279.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.41 279.35L380.87 280.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.87 280.47L380.37 281.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.37 281.6L380.33 281.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.17 288.63L378.15 288.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.15 288.71L377.91 289.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.91 289.93L377.72 291.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.72 291.15L377.56 292.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.56 292.39L377.44 293.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.44 293.62L377.36 294.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.36 294.86L377.32 296.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.32 296.1L377.32 297.35Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.32 297.35L377.33 297.63Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.17 304.82L378.42 305.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.42 305.96L378.74 307.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.74 307.16L379.09 308.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.09 308.35L379.48 309.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.48 309.53L379.91 310.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.91 310.7L380.37 311.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.37 311.85L380.87 312.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.87 312.98L381.07 313.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.76 319.62L385.35 320.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M385.35 320.42L386.12 321.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.12 321.39L386.92 322.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.92 322.34L387.75 323.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.75 323.26L388.62 324.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.62 324.16L389.5 325.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.5 325.03L390.42 325.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.42 325.87L390.89 326.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.79 330.46L397.51 330.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.51 330.87L398.61 331.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.61 331.45L399.73 331.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.73 331.99L400.86 332.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.86 332.5L402.01 332.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.01 332.98L403.17 333.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.17 333.41L404.35 333.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.35 333.81L405.1 334.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.2 335.46L412.86 335.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.86 335.53L414.1 335.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.1 335.62L415.34 335.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.34 335.67L416.58 335.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.58 335.68L417.82 335.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.82 335.65L419.06 335.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.06 335.58L420.3 335.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.3 335.47L421.24 335.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.31 333.77L428.78 333.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.78 333.62L429.95 333.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.95 333.2L431.11 332.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.11 332.74L432.25 332.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.25 332.25L433.37 331.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.37 331.73L434.48 331.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.48 331.16L435.57 330.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.57 330.56L436.53 330" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.33 325.67L442.58 325.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.58 325.45L443.48 324.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.48 324.6L444.36 323.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.36 323.72L445.21 322.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M445.21 322.81L446.02 321.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.02 321.87L446.81 320.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.81 320.91L447.56 319.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.56 319.92L448.29 318.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M448.29 318.91L448.31 318.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.85 312.57L451.92 312.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.92 312.42L452.41 311.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.41 311.27L452.85 310.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.85 310.11L453.26 308.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M453.26 308.94L453.63 307.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M453.63 307.75L453.96 306.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M453.96 306.56L454.26 305.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.26 305.35L454.51 304.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.51 304.13L454.55 303.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_06_07;

    