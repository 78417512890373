
    const SvgXx_07_04 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="335.46 251.95999999999998 128.47000000000003 128.49" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M399.67 319.5L399.67 343.81Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.67 368.12L399.67 368.12 393.08 343.92 406.25 343.92 399.67 368.12Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M399.67 312.91L399.67 288.61Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.67 264.3L399.67 264.3 406.25 288.49 393.08 288.49 399.67 264.3Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M427.27 316.21L451.58 316.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.96 316.21L402.96 316.21 427.16 309.62 427.16 322.79 402.96 316.21Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M372.07 316.21L347.76 316.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.38 316.21L396.38 316.21 372.18 322.79 372.18 309.62 396.38 316.21Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M458.93 316.21L458.73 321.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.73 321.04L458.14 325.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.14 325.84L457.16 330.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.16 330.57L455.8 335.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.8 335.21L454.07 339.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.07 339.72L451.97 344.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.97 344.08L449.52 348.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.52 348.25L446.75 352.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.75 352.2L443.65 355.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.65 355.92L440.27 359.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.27 359.37L436.62 362.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.62 362.54L432.72 365.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.72 365.4L428.6 367.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.6 367.93L424.29 370.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.29 370.11L419.81 371.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.81 371.94L415.2 373.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.2 373.4L410.49 374.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.49 374.47L405.7 375.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.7 375.16L400.88 375.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.88 375.45L396.04 375.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.04 375.36L391.24 374.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.24 374.86L386.48 373.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.48 373.98L381.82 372.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.82 372.71L377.27 371.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.27 371.07L372.87 369.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.87 369.06L368.65 366.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.65 366.7L364.64 364.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.64 364.01L360.86 360.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.86 360.99L357.34 357.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M357.34 357.68L354.1 354.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.1 354.09L351.16 350.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M351.16 350.25L348.55 346.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.55 346.19L346.28 341.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.28 341.92L344.36 337.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M344.36 337.48L342.81 332.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M342.81 332.9L341.64 328.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.64 328.21L340.85 323.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.85 323.44L340.46 318.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.46 318.62L340.46 313.79Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.46 313.79L340.85 308.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.85 308.97L341.64 304.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.64 304.2L342.81 299.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M342.81 299.51L344.36 294.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M344.36 294.93L346.28 290.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.28 290.49L348.55 286.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.55 286.23L351.16 282.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M351.16 282.16L354.1 278.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.1 278.32L357.34 274.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M357.34 274.73L360.86 271.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.86 271.42L364.64 268.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.64 268.41L368.65 265.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.65 265.71L372.87 263.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.87 263.35L377.27 261.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.27 261.34L381.82 259.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.82 259.7L386.48 258.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.48 258.43L391.24 257.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.24 257.55L396.04 257.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.04 257.06L400.88 256.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.88 256.96L405.7 257.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.7 257.25L410.49 257.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.49 257.94L415.2 259.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.2 259.02L419.81 260.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.81 260.47L424.29 262.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.29 262.3L428.6 264.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.6 264.49L432.72 267.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.72 267.02L436.62 269.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.62 269.87L440.27 273.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.27 273.04L443.65 276.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.65 276.49L446.75 280.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.75 280.21L449.52 284.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.52 284.17L451.97 288.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.97 288.34L454.07 292.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.07 292.69L455.8 297.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.8 297.21L457.16 301.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.16 301.84L458.14 306.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.14 306.58L458.73 311.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M458.73 311.38L458.93 316.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_07_04;

    