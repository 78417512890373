import { Button as AutoCompleteButton, Autocomplete } from 'devextreme-react/autocomplete';
import { ContentProps, DawaAdresse, DawaSearch, ISearchSource, SearchMode, searchModeNames } from './interfaces';
import { EventInfo, NativeEventInfo } from 'devextreme/events';

import Box from '@mui/material/Box/Box';
import { Properties } from 'devextreme/ui/button';
import SelectBox from 'devextreme-react/select-box';
import { SelectBoxInstance } from 'devextreme/ui/select_box';
import { SelectionChangedInfo } from 'devextreme/ui/drop_down_editor/ui.drop_down_list';
import { StaticCookieHandler } from '../../CookieHandler';
import { ValueChangedInfo } from 'devextreme/ui/editor/editor';
import axios from 'axios';
import dxAutocomplete from 'devextreme/ui/autocomplete';
import style from './style.module.css';
import { useState } from 'react';

const Content = ({ onEnterKey, onSearchStringChange, onSelectionChange, source }: ContentProps) => {
    const [searchMode, setSearchMode] = useState<SearchMode>(SearchMode.Feature);

    //#region Event handlers

    const handleValueChange = (e: NativeEventInfo<dxAutocomplete> & ValueChangedInfo) => {
        onSearchStringChange(e.value);
    };

    const handleSelectionChange = (e: EventInfo<dxAutocomplete> & SelectionChangedInfo<ISearchSource>) => {
        if (!onSelectionChange(e.selectedItem)) return;

        e.component.close();
        if (e.selectedItem != null) e.component.blur();
    };

    const handleEnterKey = (e: EventInfo<dxAutocomplete>) => {
        if (!onEnterKey()) return;

        e.component.close();
        e.component.blur();
    };

    const handeSearchModeSelectionChange = (
        e: EventInfo<SelectBoxInstance> & SelectionChangedInfo<{ value: number }>
    ) => {
        setSearchMode(e.selectedItem.value);
    };

    //#endregion

    const searchOptions: Properties = {
        icon: 'search',
        stylingMode: 'text',
        onClick(e) {
            handleEnterKey(e as EventInfo<dxAutocomplete>);
        },
    };

    const searchComponents = [
        <Autocomplete
            key={SearchMode.Feature}
            dataSource={source}
            searchExpr={['searchString', 'display']}
            valueExpr='display'
            onEnterKey={handleEnterKey}
            onSelectionChanged={handleSelectionChange}
            onValueChanged={handleValueChange}
            minSearchLength={0}
            searchTimeout={0}
            placeholder='Søg efter feature ...'
            className='ol-search-feature-input'
            openOnFieldClick={true}
            showClearButton={true}
            showDropDownButton={true}
            acceptCustomValue
        >
            <AutoCompleteButton name='clear' />
            <AutoCompleteButton name='search' options={searchOptions} />
            <AutoCompleteButton name='dropDown' />
        </Autocomplete>,
        <AddressSearch key={SearchMode.Address} />,
    ];

    return (
        <Box className={`ol-control-flex-left ol-search-feature-box`}>
            {searchComponents[searchMode]}
            <SelectBox
                dataSource={searchModeNames.map((n, i) => ({ display: n, value: i }))}
                displayExpr='display'
                valueExpr='value'
                value={searchMode}
                onSelectionChanged={handeSearchModeSelectionChange}
                buttons={['clear']}
            ></SelectBox>
        </Box>
    );
};

const isDawaAdresse = (x: DawaSearch | string): x is DawaAdresse => {
    if (x == null) return false;

    if (typeof x === 'string') return false;

    return x.type === 'adresse' || x.type === 'adgangsadresse';
};

const AddressSearch = () => {
    const [dawaSource, setDawaSource] = useState<DawaSearch[]>([]);

    const handleInputChange = async (e: NativeEventInfo<SelectBoxInstance, UIEvent>) => {
        if (!e.event) {
            setDawaSource([]);
            return;
        }

        const input = (e.event.currentTarget as HTMLInputElement).value;

        try {
            const response = await axios.get<DawaSearch[]>(
                `https://dawa.aws.dk/autocomplete?type=adresse&srid=25832&q=${input}`
            );
            setDawaSource(response.data);
        } catch {
            setDawaSource([]);
        }
    };

    const handleSelectionChange = (e: EventInfo<SelectBoxInstance> & SelectionChangedInfo<DawaSearch | string>) => {
        e.component.reset();
        e.component.blur();
        // (e.element as HTMLInputElement).value = "";
        const inputElement = e.element.querySelector('input.dx-texteditor-input') as HTMLInputElement;
        inputElement.value = '';
        const item = e.selectedItem;
        if (!isDawaAdresse(item)) return;

        if (!item.data.x || !item.data.y) return;

        StaticCookieHandler.centerAtCoords([item.data.x, item.data.y]);
    };

    return (
        <SelectBox
            dataSource={dawaSource}
            buttons={['clear']}
            onSelectionChanged={handleSelectionChange}
            displayExpr='forslagstekst'
            onInput={handleInputChange}
            acceptCustomValue
            className={style['adresse-field']}
            placeholder='Søg efter adresse ...'
            noDataText='Ingen adresse fundet'
        ></SelectBox>
    );
};

export default Content;
