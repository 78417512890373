
    const Svg4_05_02 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="303.85 215.44 282.74 191.82" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M308.85 402.26L490.68 402.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M490.68 402.26L490.68 220.44Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M490.68 220.44L308.85 220.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M308.85 220.44L308.85 402.26Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.02 278.88L409.36 270.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.36 270.22L396.37 265.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.37 265.9L379.05 265.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.05 265.9L366.07 270.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.07 270.22L357.41 278.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M357.41 278.88L357.41 287.54Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M357.41 287.54L361.74 296.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.74 296.2L366.07 300.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.07 300.53L374.72 304.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.72 304.86L400.7 313.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.7 313.52L409.36 317.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.36 317.85L413.69 322.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.69 322.17L418.02 330.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.02 330.83L418.02 343.82Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.02 343.82L409.36 352.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.36 352.48L396.37 356.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.37 356.81L379.05 356.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.05 356.81L366.07 352.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.07 352.48L357.41 343.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M490.68 311.35L581.59 311.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M581.59 311.35L536.13 288.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M581.59 311.35L536.13 334.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg4_05_02;

    