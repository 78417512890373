
    const Svg1_01_18 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="336.84 226.66 168.77000000000004 154.20000000000002" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M 398.2 339.23 A 93.028 93.028 0 0 0 398.2 236.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.99 288.02L408.44 313.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.99 288.02L408.44 262.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.56 288.02L428.92 288.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.28 288.02L464.77 288.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M480.13 288.02L500.61 288.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.44 262.41L428.92 262.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.28 262.41L464.77 262.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M480.13 262.41L500.61 262.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.44 313.62L428.92 313.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M444.28 313.62L464.77 313.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M480.13 313.62L500.61 313.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 372.59 252.17 A 11.266 11.266 0 0 0 362.35 262.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 372.59 241.91 A 21.507 21.507 0 0 0 352.09 262.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 372.59 231.66 A 31.749 31.749 0 0 0 341.84 262.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.93 375.86L454.24 334.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.93 334.89L454.24 375.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M465.95 375.86L493.26 334.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M465.95 334.89L493.26 375.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_18;

    