import { useEffect, useId } from 'react';

import Api from '../../networking/api';
import { IUseQRArgs } from './interfaces';
import { QrScannerProps } from '@yudiel/react-qr-scanner';
import { useAppDispatch } from '../redux-base-hooks';
import { useEnhed } from '../use-enhed';
import useHistoryNavigate from '../extended-hooks/use-history-navigate';
import useMountEffect from '../use-mount-effect';
import useQRScannerSlice from './slice';
import useStableFunction from '../use-stable-function';

const useQRScanner = <T = unknown,>({ defaultActive, QRProps, PopupProps, onDecode, onError }: IUseQRArgs<T> = {}) => {
    const { navigate } = useHistoryNavigate();
    const dispatch = useAppDispatch();
    const { updateId } = useEnhed();
    const key = useId();

    const start = useStableFunction(() => dispatch(useQRScannerSlice.actions.start({ key })));
    const stop = useStableFunction(() => dispatch(useQRScannerSlice.actions.stop({ key })));

    const handleError: QrScannerProps['onError'] = (e) => {
        onError?.(e);
    };

    const handleDecode: QrScannerProps['onDecode'] = async (data) => {
        try {
            // URL throws exception if QR is not a link
            const url = new URL(data);

            // Navigate if url points to this site
            if (url.hostname === window.location.hostname) return navigate(url.pathname + url.search);

            // If not navigated, expect QR to be CT dynamic format
            if (!/\d{6}QR\d{5}/.test(data)) throw new Error('QR was not CT format');

            dispatch(useQRScannerSlice.actions.setLoading({ key, loading: true }));
            const response = await Api.get<T | unknown>(`/Drift/Lookup/qr/${data.split('/').reverse()[0]}`);

            if (!Api.ok(response)) throw new Error('QR not recognised by server');

            updateId(parseInt(String(response?.data)) ?? 0);

            onDecode?.(response.data);
        } catch (e) {
            onError?.(e as Error);
        } finally {
            stop();
        }
    };

    useMountEffect(() => {
        dispatch(
            useQRScannerSlice.actions.add({
                newState: { key, active: defaultActive ?? false, loading: false, QRProps, PopupProps },
                callbacks: { onDecode: handleDecode, onError: handleError },
            })
        );

        return () => {
            dispatch(useQRScannerSlice.actions.remove({ key }));
        };
    });

    useEffect(() => {
        dispatch(
            useQRScannerSlice.actions.updateProps({
                key,
                QRProps,
                PopupProps,
            })
        );
    }, [key, QRProps, PopupProps, dispatch]);

    return [start, stop] as const;
};

export default useQRScanner;
