
    const Svg4_05_03 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="302.05 222.96 213.55 145.68999999999997" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M307.05 363.65L442.75 363.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.75 363.65L442.75 227.96Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.75 227.96L307.05 227.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M307.05 227.96L307.05 363.65Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M335.42 329.73L335.42 261.88Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M335.42 261.88L361.27 329.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.27 329.73L387.12 261.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.12 261.88L387.12 329.73Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M510.6 295.81L442.75 295.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.75 295.81L476.68 278.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.75 295.81L476.68 312.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg4_05_03;

    