
    const Svg4_05_19 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="-3.52 240.84 807.04 118.32000000000002" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M1.48 300.09L286.14 300.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M513.86 300.09L798.52 300.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M394.42 245.84L372.4 354.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.64 245.84L405.61 354.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.14 281.8L361.21 281.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.14 318.38L361.21 318.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg4_05_19;

    