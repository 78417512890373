
    const Svg1_01_09 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="305.18 223.76 155.32999999999998 155.32" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M455.51 374.08L455.51 228.76Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.18 228.76L310.18 374.08Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.18 374.08L455.51 374.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.51 228.76L310.18 228.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.85 337.75L382.85 315.95Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.85 315.95L382.85 301.42 368.31 315.95 382.85 315.95Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M382.85 315.95L382.85 315.95 382.85 301.42 382.85 315.95Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M382.85 315.95L382.85 301.42 382.85 301.42 382.85 315.95Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M382.85 315.95L397.38 315.95 382.85 301.42 382.85 315.95Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M382.85 315.95L397.38 315.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.38 315.95L382.85 301.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.85 301.42L368.31 315.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.31 315.95L382.85 315.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.18 301.42L346.51 301.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M346.51 315.95L346.51 301.42Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.85 265.09L382.85 301.42Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_09;

    