const SvgKlmedtastatur = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        width='30'
        zoomAndPan='magnify'
        viewBox='0 0 30 30.000001'
        height='30'
        preserveAspectRatio='xMidYMid meet'
        version='1.0'
    >
        <defs>
            <clipPath id='b7185271f1'>
                <path
                    d='M 0.484375 0 L 1.582031 0 L 1.582031 29.03125 L 0.484375 29.03125 Z M 0.484375 0 '
                    clipRule='nonzero'
                />
            </clipPath>
            <clipPath id='6288567592'>
                <path
                    d='M 28.417969 0 L 29.515625 0 L 29.515625 29.03125 L 28.417969 29.03125 Z M 28.417969 0 '
                    clipRule='nonzero'
                />
            </clipPath>
            <clipPath id='34718dafbe'>
                <path
                    d='M 0.484375 28.148438 L 29.515625 28.148438 L 29.515625 29.03125 L 0.484375 29.03125 Z M 0.484375 28.148438 '
                    clipRule='nonzero'
                />
            </clipPath>
            <clipPath id='320128590f'>
                <path
                    d='M 0.484375 0 L 29.515625 0 L 29.515625 0.941406 L 0.484375 0.941406 Z M 0.484375 0 '
                    clipRule='nonzero'
                />
            </clipPath>
            <clipPath id='c0f56a1218'>
                <path
                    d='M 0.484375 0 L 29.515625 0 L 29.515625 29.03125 L 0.484375 29.03125 Z M 0.484375 0 '
                    clipRule='nonzero'
                />
            </clipPath>
        </defs>
        <g clipPath='url(#b7185271f1)'>
            <path
                fill='{{replacement-value-color}}'
                d='M -6.835938 0 L 1.582031 0 L 1.582031 32.527344 L -6.835938 32.527344 Z M -6.835938 0 '
                fillOpacity='1'
                fillRule='nonzero'
            />
        </g>
        <g clipPath='url(#6288567592)'>
            <path
                fill='{{replacement-value-color}}'
                d='M 28.417969 0 L 32.046875 0 L 32.046875 32.519531 L 28.417969 32.519531 Z M 28.417969 0 '
                fillOpacity='1'
                fillRule='nonzero'
            />
        </g>
        <g clipPath='url(#34718dafbe)'>
            <path
                fill='{{replacement-value-color}}'
                d='M 31.257812 28.148438 L 31.257812 31.777344 L -1.261719 31.777344 L -1.261719 28.148438 Z M 31.257812 28.148438 '
                fillOpacity='1'
                fillRule='nonzero'
            />
        </g>
        <g clipPath='url(#320128590f)'>
            <path
                fill='{{replacement-value-color}}'
                d='M -1.257812 0.941406 L -1.257812 -2.6875 L 31.261719 -2.6875 L 31.261719 0.941406 Z M -1.257812 0.941406 '
                fillOpacity='1'
                fillRule='nonzero'
            />
        </g>
        <g clipPath='url(#c0f56a1218)'>
            <path
                strokeLinecap='butt'
                transform='matrix(0.525798, -0.500331, 0.500331, 0.525798, -1.759744, 29.434896)'
                fill='none'
                strokeLinejoin='miter'
                d='M 0.00136685 1.001763 L 59.889607 1.000057 '
                stroke='{{replacement-value-color}}'
                strokeWidth='2'
                strokeOpacity='1'
                strokeMiterlimit='4'
            />
            <path
                strokeLinecap='butt'
                transform='matrix(0.513223, 0.513223, -0.513223, 0.513223, -6.323691, -7.698849)'
                fill='none'
                strokeLinejoin='miter'
                d='M -0.000874627 0.997224 L 102.727746 0.997224 '
                stroke='{{replacement-value-color}}'
                strokeWidth='2'
                strokeOpacity='1'
                strokeMiterlimit='4'
            />
        </g>
    </svg>
);
export default SvgKlmedtastatur;
