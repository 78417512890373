import { ReactNode, createContext, useState } from 'react';

type ContextType = {
    value: (string | string[])[];
    setValue: (breadcrums: (string | string[])[]) => void;
};

export const BreadCrumbContext = createContext<ContextType>({ value: [], setValue: () => undefined });

const BreadCrumbProvider = ({ children }: { children: ReactNode }) => {
    const [value, setValue] = useState<(string | string[])[]>([]);

    return <BreadCrumbContext.Provider value={{ value, setValue }}>{children}</BreadCrumbContext.Provider>;
};

export default BreadCrumbProvider;
