
    const Svg2_06_09 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="309.71 233.81 214.21999999999997 112.11000000000001" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M314.71 238.81L314.71 340.92Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M314.71 340.92L518.93 340.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M518.93 340.92L518.93 238.81Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M518.93 238.81L314.71 238.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.82 238.81L518.93 340.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.82 238.81L314.71 340.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.79 323.99L425.02 283.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.79 283.15L425.02 323.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_06_09;

    