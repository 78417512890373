const SvgOvenlys = () => (
    <svg xmlns='http://www.w3.org/2000/svg' xmlSpace='preserve' width={30.289} height={15.459}>
        <path
            d='M.947-17.018c-.11-.11-.198-3.601-.198-7.76v-7.564l15.145.076 15.144.076v15.218l-14.947.076c-8.22.041-15.036-.014-15.144-.122m15.02-1.193c.402.014 3.694.035 7.316.047l6.585.021.082-6c.046-3.299.01-6.295-.078-6.657l-.162-.658-13.383.009-13.383.01-.512.388-.512.389V-17.975l6.657-.131c3.662-.072 6.987-.12 7.39-.105m-3.178-1.644-.646-.687.4-.333.402-.333.664.706c.365.388.576.848.469 1.02-.29.47-.575.388-1.29-.373m5.425.28c-.193-.503 1.102-1.775 1.536-1.508.488.302.374.748-.345 1.347-.797.665-.988.691-1.191.161m-8.386-3.243-.68-.724.507-.194c.6-.23 1.805.829 1.501 1.32-.298.482-.577.398-1.328-.402m11.247.41c-.268-.433 1.004-1.728 1.507-1.535.564.216.51.788-.127 1.365-.617.559-1.103.619-1.38.17m-14.31-3.179c-.532-.588-.407-1.189.248-1.189.538 0 1.438 1.075 1.21 1.445-.294.476-.89.372-1.458-.256m17.28.322c-.254-.41.697-1.511 1.303-1.511.73 0 .724.43-.016 1.17-.626.626-1.044.736-1.288.34M3.85-28.58c-.444-.632-.385-1.122.134-1.122.454 0 1.481 1.17 1.293 1.473-.295.478-.962.314-1.427-.35m23.12.388c-.253-.409.697-1.51 1.304-1.51.716 0 .733.638.033 1.271-.585.53-1.101.622-1.337.24'
            style={{
                fill: '{{replacement-value-color}}',
                fillOpacity: 1,
            }}
            transform='translate(-.749 32.342)'
        />
    </svg>
);
export default SvgOvenlys;
