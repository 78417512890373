interface ISlice {
    props?: {
        colSpan?: string | number;
    };
    colSpan?: string | number;
}

const useColumnSlice = <T extends ISlice>(arr: T[]) => {
    const maxSlice: T[][] = [];
    let i = 0;
    arr.forEach((item) => {
        if ((item.props?.colSpan ?? item.colSpan) === 'max') i++;
        maxSlice[i] = [...(maxSlice?.[i] ?? []), item];
    });
    return maxSlice;
};

export default useColumnSlice;
