import { Component, ErrorInfo, ReactNode } from 'react';
import { IErrorBoundaryProps, IErrorBoundaryState } from './interface-boundary';
import useHistoryNavigate, { WithHistoryNavigate } from '../hooks/extended-hooks/use-history-navigate';

import Button from '@mui/material/Button/Button';
import { CenterCenterBox } from '../../components/mui/styled-mui';
import Divider from '@mui/material/Divider/Divider';
import Grid from '@mui/material/Grid/Grid';
import { GridItemCenter } from '../../components/mui/extended-mui';

export interface IProps extends IErrorBoundaryProps {
    historyNavigate?: ReturnType<typeof useHistoryNavigate>;
}

export type IState = IErrorBoundaryState;

/** Some styles are defined here, because this component may be shown above the theme provider */

//#region style

interface Children {
    children: ReactNode;
}

const CenterHeader = ({ children }: Children) => (
    <h1
        style={{
            width: '100%',
            textAlign: 'center',
            fontWeight: 300,
            fontSize: '4.5rem',
            lineHeight: 1,
            letterSpacing: '-0.01562em',
            fontFamily: 'StatusHeader',
        }}
    >
        {children}
    </h1>
);

const CenterText = ({ children }: Children) => (
    <h3
        style={{
            width: '100%',
            textAlign: 'center',
        }}
    >
        {children}
    </h3>
);

interface WideButtonProps extends Children {
    onClick?: () => void;
    href?: string;
    variant?: 'contained' | 'outlined';
}

const WideButton = ({ children, onClick, href, variant = 'contained' }: WideButtonProps) => (
    <Button
        variant={variant}
        onClick={onClick}
        sx={{
            width: '100%',
            borderRadius: '100px',
        }}
        href={href}
    >
        {children}
    </Button>
);

//#endregion style

const initialState: IState = {
    hasError: false,
    error: undefined,
};

class GeneralErrorBoundary extends Component<IProps, IState> {
    public state: IState = initialState;

    static getDerivedStateFromError(error: Error) {
        return { hasError: true, error: error };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo);
    }

    clear() {
        this.setState(initialState);
    }

    navigate(path: string) {
        this.clear();
        if (this.props.historyNavigate) this.props.historyNavigate.navigate(path);
        else window.location.href = window.location.protocol + window.location.hostname + path;
    }

    goBack() {
        this.clear();
        if (this.props.historyNavigate) this.props.historyNavigate.goBack();
        else window.history.back();
    }

    restart() {
        window.location.href = window.location.href.replace(/#.*$/, '');
    }

    public render() {
        if (this.state.hasError)
            return (
                <CenterCenterBox>
                    <div style={{ padding: '16px' }}>
                        <CenterHeader>Undskyld</CenterHeader>
                        <CenterHeader>&#128531;</CenterHeader>
                        <CenterText>Noget gik galt, og appen stoppede med at virke</CenterText>
                        <Divider />
                        <CenterText>Prøv en af følgende muligheder for at løse problemet</CenterText>
                        <Grid container spacing={2} sx={{ p: 2 }}>
                            <GridItemCenter item xs={6}>
                                <WideButton onClick={() => this.goBack()}>Tilbage</WideButton>
                            </GridItemCenter>
                            <GridItemCenter item xs={6}>
                                <WideButton onClick={() => this.navigate('/')}>Til forsiden</WideButton>
                            </GridItemCenter>
                            <GridItemCenter item xs={12}>
                                <WideButton
                                    onClick={() => {
                                        window.location.href = window.location.href.replace(/#.*$/, '');
                                    }}
                                    variant='outlined'
                                >
                                    Genstart app
                                </WideButton>
                            </GridItemCenter>
                        </Grid>
                        {/* <h5>
                        Tlf: <a href="tel:+4572489901">72 48 99 01</a>
                        Email: <a href="mailto:kontakt@caretaker.dk">kontakt@caretaker.dk</a>
                    </h5> */}
                    </div>
                </CenterCenterBox>
            );
        return this.props.children;
    }
}

export { GeneralErrorBoundary as OuterErrorBoundary };

// Give boundary access to useHistoryNavigate
export default WithHistoryNavigate(GeneralErrorBoundary);
