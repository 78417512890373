
    const SvgXx_06_17 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="340.12 226.82 107.40999999999997 156.12" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M393.82 231.82L393.82 280.53Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.12 280.53L442.53 280.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.53 280.53L442.53 329.23Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.53 329.23L345.12 329.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.12 329.23L345.12 280.53Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.82 329.23L393.82 377.94Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_06_17;

    