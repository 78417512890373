
    const Svg1_01_23 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="310.81 235.02 151.39999999999998 134.58" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M457.21 364.6L457.21 286.06Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 359.03 344.97 A 71.342 71.342 0 0 0 359.03 266.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319.76 305.7L366.88 325.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319.76 305.7L366.88 286.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.1 310.29L390.31 316.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.51 322.07L414.11 329.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.32 335.82L437.92 343.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.52 291.32L400.12 299.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.33 305.07L423.93 312.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.13 318.81L447.74 326.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.88 325.33L380.49 333.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.69 339.08L404.29 346.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.5 352.82L428.1 360.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 339.39 278.21 A 8.64 8.64 0 0 0 331.54 286.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 339.39 270.34 A 16.494 16.494 0 0 0 323.67 286.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 339.39 262.48 A 24.348 24.348 0 0 0 315.81 286.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.98 271.43L407.92 240.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.98 240.02L407.92 271.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.9 271.43L437.84 240.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.9 240.02L437.84 271.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_23;

    