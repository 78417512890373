
    const Svg3_01_04 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="272.71 249.33 288.17 109.35" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M 487.06 353.68 A 74.006 74.006 0 0 0 487.06 254.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M456.53 304L555.88 304" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M456.53 304L473.74 294.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M456.53 304L473.74 313.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M487.06 254.33L489.71 274.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M487.06 254.33L505.44 261.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M487.06 353.68L489.71 333.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M487.06 353.68L505.44 346.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M555.88 304L538.67 294.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M555.88 304L538.67 313.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M277.71 353.68L426.73 353.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.73 353.68L426.73 254.33Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M426.73 254.33L277.71 254.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M277.71 254.33L277.71 353.68Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.44 297.65L390.37 297.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.44 310.36L409.44 297.65Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.37 310.36L409.44 310.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M294.99 329.44L390.37 329.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.37 329.44L390.37 278.57Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.37 278.57L294.99 278.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M294.99 278.57L294.99 329.44Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg3_01_04;

    