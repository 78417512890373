
    const Svg3_01_05 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="342.8 231.09 133.67000000000002 133.70000000000002" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M471.47 297.94L471.27 302.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M471.27 302.85L470.69 307.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.69 307.74L469.72 312.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M469.72 312.56L468.37 317.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M468.37 317.29L466.64 321.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.64 321.89L464.56 326.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.56 326.35L462.13 330.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M462.13 330.63L459.37 334.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.37 334.7L456.29 338.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M456.29 338.53L452.91 342.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.91 342.11L449.27 345.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.27 345.41L445.37 348.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M445.37 348.41L441.25 351.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.25 351.1L436.92 353.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.92 353.44L432.43 355.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.43 355.44L427.79 357.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.79 357.07L423.03 358.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.03 358.32L418.19 359.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.19 359.2L413.3 359.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.3 359.69L408.38 359.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.38 359.79L403.47 359.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.47 359.49L398.6 358.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.6 358.81L393.8 357.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.8 357.74L389.1 356.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.1 356.3L384.53 354.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.53 354.48L380.11 352.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.11 352.31L375.89 349.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.89 349.8L371.87 346.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.87 346.95L368.1 343.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.1 343.8L364.59 340.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.59 340.36L361.36 336.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.36 336.64L358.44 332.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.44 332.69L355.84 328.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.84 328.51L353.58 324.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.58 324.14L351.67 319.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M351.67 319.61L350.13 314.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.13 314.94L348.97 310.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.97 310.16L348.19 305.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.19 305.3L347.8 300.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M347.8 300.4L347.8 295.48Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M347.8 295.48L348.19 290.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.19 290.58L348.97 285.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.97 285.72L350.13 280.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M350.13 280.94L351.67 276.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M351.67 276.27L353.58 271.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.58 271.73L355.84 267.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.84 267.37L358.44 263.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.44 263.19L361.36 259.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.36 259.23L364.59 255.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.59 255.52L368.1 252.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M368.1 252.08L371.87 248.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.87 248.93L375.89 246.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.89 246.08L380.11 243.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.11 243.57L384.53 241.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.53 241.39L389.1 239.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.1 239.58L393.8 238.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.8 238.14L398.6 237.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.6 237.07L403.47 236.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.47 236.39L408.38 236.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.38 236.09L413.3 236.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.3 236.19L418.19 236.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.19 236.68L423.03 237.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.03 237.55L427.79 238.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.79 238.81L432.43 240.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.43 240.44L436.92 242.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M436.92 242.44L441.25 244.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.25 244.78L445.37 247.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M445.37 247.46L449.27 250.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.27 250.46L452.91 253.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.91 253.77L456.29 257.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M456.29 257.35L459.37 261.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.37 261.18L462.13 265.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M462.13 265.25L464.56 269.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.56 269.53L466.64 273.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.64 273.98L468.37 278.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M468.37 278.59L469.72 283.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M469.72 283.32L470.69 288.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.69 288.14L471.27 293.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M471.27 293.02L471.47 297.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.73 293.81L434.35 293.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.73 302.06L446.73 293.81Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.35 302.06L446.73 302.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.5 314.43L434.35 314.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.35 314.43L434.35 281.44Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.35 281.44L372.5 281.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.5 281.44L372.5 314.43Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg3_01_05;

    