
    const Svg1_01_32 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="318.4 249.87 126.33000000000004 155.42000000000002" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M323.4 371.21L439.73 371.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.73 371.21L439.73 254.87Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.73 254.87L323.4 254.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.4 254.87L323.4 371.21Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M335.04 371.21L335.04 388.66Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M335.04 388.66L352.49 400.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.49 400.29L364.12 388.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.12 388.66L381.57 400.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.57 400.29L399.02 388.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.02 388.66L410.65 400.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.65 400.29L428.1 388.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.1 388.66L428.1 371.21Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.47 283.96L376.63 342.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.63 342.12L398.79 283.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_01_32;

    