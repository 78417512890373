
    const Svg1_04_03 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="308.88 190.12 259.28999999999996 190.5" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M328.51 259.71L399.15 330.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.51 330.35L399.15 259.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.79 295.03L413.59 290.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.59 290.61L413.01 286.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.01 286.23L412.04 281.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.04 281.92L410.69 277.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.69 277.71L408.98 273.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.98 273.64L406.91 269.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.91 269.73L404.5 266.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.5 266.03L401.78 262.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.78 262.54L398.76 259.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.76 259.32L395.47 256.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.47 256.37L391.93 253.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.93 253.73L388.17 251.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.17 251.4L384.22 249.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.22 249.42L380.11 247.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.11 247.8L375.87 246.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.87 246.55L371.54 245.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.54 245.67L367.15 245.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.15 245.18L362.73 245.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.73 245.09L358.32 245.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.32 245.38L353.95 246.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.95 246.06L349.66 247.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.66 247.13L345.48 248.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.48 248.57L341.45 250.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.45 250.37L337.59 252.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M337.59 252.52L333.94 255.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M333.94 255.01L330.52 257.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.52 257.81L327.36 260.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M327.36 260.9L324.48 264.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.48 264.25L321.92 267.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M321.92 267.85L319.68 271.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319.68 271.66L317.79 275.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M317.79 275.66L316.25 279.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M316.25 279.8L315.1 284.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M315.1 284.07L314.32 288.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M314.32 288.42L313.93 292.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M313.93 292.82L313.93 297.24Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M313.93 297.24L314.32 301.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M314.32 301.64L315.1 305.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M315.1 305.99L316.25 310.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M316.25 310.26L317.79 314.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M317.79 314.4L319.68 318.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319.68 318.39L321.92 322.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M321.92 322.21L324.48 325.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.48 325.8L327.36 329.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M327.36 329.16L330.52 332.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.52 332.25L333.94 335.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M333.94 335.05L337.59 337.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M337.59 337.54L341.45 339.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.45 339.69L345.48 341.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.48 341.49L349.66 342.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.66 342.93L353.95 344" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.95 344L358.32 344.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.32 344.68L362.73 344.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.73 344.97L367.15 344.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.15 344.87L371.54 344.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.54 344.39L375.87 343.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.87 343.51L380.11 342.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.11 342.26L384.22 340.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.22 340.63L388.17 338.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.17 338.65L391.93 336.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.93 336.33L395.47 333.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.47 333.69L398.76 330.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.76 330.74L401.78 327.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.78 327.51L404.5 324.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.5 324.03L406.91 320.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.91 320.32L408.98 316.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.98 316.42L410.69 312.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.69 312.34L412.04 308.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.04 308.14L413.01 303.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.01 303.82L413.59 299.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.59 299.44L413.79 295.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M413.79 215.1L383.81 215.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.81 215.1L383.81 195.12Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.81 195.12L343.85 195.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.85 195.12L343.85 215.1Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.85 215.1L313.88 215.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.77 375.62L487.05 295.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.77 295.69L487.05 375.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M509.89 375.62L563.17 295.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M509.89 295.69L563.17 375.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_04_03;

    