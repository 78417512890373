import { RefObject, useEffect } from 'react';

/**
 * A hook to detect clicks outside a component and trigger a callback when that happens
 * https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 *
 * @param ref a refobject to the element clickdetection should be relative to
 * @param callback function to call on click
 * @param options
 */
const useOnOutsideClick = (ref: RefObject<HTMLElement>, callback: (e: MouseEvent) => void) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(e: MouseEvent) {
            if (ref.current != null && !ref.current.contains(e.target as Node)) {
                callback(e);
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);
};

export default useOnOutsideClick;
