
const Svg1_08_11 = () => (
    <svg viewBox="0 0 1024 768" width="30" height="30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" strokeLinecap="round" strokeLinejoin="round" fillRule="evenodd" xmlSpace="preserve" >
        <defs >
            <clipPath id="clipId0" >
                <path d="M0,768 1024,768 1024,0 0,0 z" />
            </clipPath>
        </defs>
        <g clipPath="url(#clipId0)" fill="none" stroke='{{replacement-value-color}}' strokeWidth="0.1" />
        <g clipPath="url(#clipId0)" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="1" >
            <path d="M10.0392,509.49 10.0392,258.51 762.98,509.49 z" />
            <path d="M762.98,509.49 10.0392,258.51 762.98,258.51 z" />
            <path d="M762.98,634.98 762.98,133.02 1013.96,384 z" />
        </g>
        <g clipPath="url(#clipId0)" fill="none" stroke='{{replacement-value-color}}' strokeWidth="0.25" >
            <polyline points="10.0392,258.51 10.0392,509.49 762.98,509.49 762.98,634.98 1013.96,384 762.98,133.02 762.98,258.51 10.0392,258.51 " />
        </g>
    </svg>
);
export default Svg1_08_11;


