
    const Svg2_01_10 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="285.66 232.21 297.55 153.09" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M433.75 380.3L433.75 237.21Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.75 237.21L290.66 237.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M290.66 237.21L290.66 380.3Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M290.66 380.3L433.75 380.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.44 290.02L371.03 283.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.03 283.2L364.21 276.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.21 276.39L357.4 272.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M357.4 272.98L343.77 272.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.77 272.98L336.96 276.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.96 276.39L330.14 283.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.14 283.2L326.74 290.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M326.74 290.02L323.33 300.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.33 300.24L323.33 317.27Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.33 317.27L326.74 327.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M326.74 327.49L330.14 334.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.14 334.31L336.96 341.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.96 341.12L343.77 344.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.77 344.53L357.4 344.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M357.4 344.53L364.21 341.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.21 341.12L371.03 334.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.03 334.31L374.44 327.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.44 327.49L374.44 317.27Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.44 317.27L357.4 317.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M462.37 380.3L510.07 308.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M462.37 308.75L510.07 380.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M530.51 380.3L578.21 308.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M530.51 308.75L578.21 380.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_01_10;

    