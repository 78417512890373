
    const SvgXx_07_09 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="302.02 211.24 209.36 163.34999999999997" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M411.03 273.41L410.83 277.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.83 277.66L410.24 281.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.24 281.87L409.27 286" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.27 286L407.92 290.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.92 290.03L406.2 293.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.2 293.92L404.14 297.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.14 297.63L401.73 301.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.73 301.14L399.02 304.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.02 304.41L396.01 307.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.01 307.41L392.75 310.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.75 310.13L389.24 312.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.24 312.53L385.53 314.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M385.53 314.59L381.64 316.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.64 316.31L377.61 317.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.61 317.66L373.47 318.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M373.47 318.63L369.27 319.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.27 319.22L365.02 319.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.02 319.42L360.78 319.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.78 319.22L356.57 318.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.57 318.63L352.43 317.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.43 317.66L348.4 316.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.4 316.31L344.52 314.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M344.52 314.59L340.8 312.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.8 312.53L337.3 310.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M337.3 310.13L334.03 307.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M334.03 307.41L331.02 304.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M331.02 304.41L328.31 301.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.31 301.14L325.91 297.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M325.91 297.63L323.84 293.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.84 293.92L322.12 290.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.12 290.03L320.77 286" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.77 286L319.8 281.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319.8 281.87L319.21 277.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319.21 277.66L319.02 273.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319.02 273.41L319.21 269.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319.21 269.17L319.8 264.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319.8 264.96L320.77 260.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.77 260.82L322.12 256.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.12 256.79L323.84 252.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.84 252.91L325.91 249.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M325.91 249.19L328.31 245.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.31 245.69L331.02 242.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M331.02 242.42L334.03 239.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M334.03 239.41L337.3 236.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M337.3 236.7L340.8 234.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.8 234.3L344.52 232.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M344.52 232.23L348.4 230.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.4 230.51L352.43 229.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.43 229.16L356.57 228.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.57 228.19L360.78 227.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.78 227.6L365.02 227.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.02 227.41L369.27 227.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.27 227.6L373.47 228.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M373.47 228.19L377.61 229.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.61 229.16L381.64 230.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.64 230.51L385.53 232.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M385.53 232.23L389.24 234.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.24 234.3L392.75 236.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.75 236.7L396.01 239.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.01 239.41L399.02 242.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.02 242.42L401.73 245.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.73 245.69L404.14 249.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.14 249.19L406.2 252.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.2 252.91L407.92 256.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.92 256.79L409.27 260.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.27 260.82L410.24 264.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.24 264.96L410.83 269.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.83 269.17L411.03 273.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M357.5 307.77L385.56 274.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M332.5 286.76L360.49 253.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.36 247.35L367.62 259.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.36 268.36L392.77 280.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.62 259.46L367.62 241.25 353.36 247.35 367.62 259.46Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M367.62 259.46L372.38 239.22 367.62 241.25 367.62 259.46Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M392.77 280.47L392.77 262.24 378.36 268.36 392.77 280.47Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M392.77 280.47L397.53 260.23 392.77 262.24 392.77 280.47Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M367.62 259.46L353.36 247.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.36 247.35L372.38 239.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.38 239.22L367.62 259.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.36 268.36L397.53 260.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.53 260.23L392.77 280.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.77 280.47L378.36 268.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M307.02 369.59L307.02 216.24Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M307.02 216.24L506.38 216.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M506.38 216.24L506.38 369.59Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M506.38 369.59L307.02 369.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.21 316.11L467.21 357Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M453.58 316.11L480.84 316.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_07_09;

    