import createTheme from '@mui/material/styles/createTheme';

const defaultTheme = createTheme({
    components: {
        MuiButton: {
            defaultProps: {
                variant: 'contained',
            },
            styleOverrides: {
                root: {
                    borderRadius: '100px',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#212529',
                },
            },
        },
    },

    //Snackbar zIndex er sat til 1600 i app.css
});

export default defaultTheme;
