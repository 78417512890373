import { Column, DataGrid } from 'devextreme-react/data-grid';
import { useRef, useState } from 'react';

import Api from '../../shared/networking/api';
import { CommandIconButton } from '../auto-grid/render-commands';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IFile } from '../auto-form/v2';
import ListItemIcon from '@mui/material/ListItemIcon';
import LoadingScreen from '../../screens/loading';
import { Menu } from '../mui/extended-mui';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Template } from 'devextreme-react/core/template';
import Typography from '@mui/material/Typography';
import { apiDownload } from '../../shared/networking/download';
import useFetch from '../../shared/hooks/fetch-hooks/use-fetch';

interface IMoreProps {
    data: IFileInfo;
    onDelete?: VoidFunction;
    buttons?: string[];
}

const Open = (props: IMoreProps) => {
    const onClick = async () => {
        await apiDownload(props.data.url, props.data.name, true);
    };

    return (
        <MenuItem key={`command-delete-${props.data.id}`} onClick={onClick}>
            <ListItemIcon key={`command-delete-lii-${props.data.id}`}>
                <OpenInNewIcon key={`command-delete-aci2-${props.data.id}`} color={'action'} />
            </ListItemIcon>
            <Typography>Åbn</Typography>
        </MenuItem>
    );
};

const Delete = (props: IMoreProps) => {
    const onClick: React.MouseEventHandler = async (e) => {
        e.stopPropagation();
        await Api.fetch(props.data.url, { method: 'DELETE' });
        props.onDelete?.();
    };

    return (
        <MenuItem key={`command-delete-${props.data.id}`} onClick={onClick}>
            <ListItemIcon key={`command-delete-lii-${props.data.id}`}>
                <DeleteForeverIcon key={`command-delete-aci2-${props.data.id}`} color={'action'} />
            </ListItemIcon>
            <Typography>Slet</Typography>
        </MenuItem>
    );
};

const More = (props: IMoreProps) => {
    const iconRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const defaults = ['open', 'delete'];

    const onClick: React.MouseEventHandler<HTMLLIElement | HTMLButtonElement> = async (e) => {
        e.stopPropagation();
        setIsOpen(true);
    };

    const close = () => {
        setIsOpen(false);
    };

    const iconButton = (
        <CommandIconButton
            key={`command-more-${props.data?.id}`}
            ref={iconRef}
            onClick={onClick}
            className='auto-grid-icon-button'
        >
            <MoreHorizIcon />
        </CommandIconButton>
    );
    return (
        <>
            {iconButton}
            <Menu key={`command-more-menu-${props.data.id}`} anchorEl={iconRef.current} open={isOpen} onClose={close}>
                {(props.buttons ?? defaults).find((i) => i === 'open') && <Open {...props} />}
                {(props.buttons ?? defaults).find((i) => i === 'delete') && <Delete {...props} />}
            </Menu>
        </>
    );
};
export interface IFileInfo extends IFile {
    type: 'file';
    date: string;
    username: string;
}

export const isIFileInfo = (file: IFile | IFileInfo): file is IFileInfo => {
    return file.type === 'file';
};

interface IFileInfoGridProps {
    getUrl?: string;
    data?: IFileInfo[];
    onDelete?: VoidFunction;
    buttons?: string[];
}

const FileInfoGrid = (props: IFileInfoGridProps) => {
    // url is defaulted to empty. In this case it is never used
    const [data, isLoading] = useFetch<IFileInfo[]>(Api.createUrl(props.getUrl ?? ''), {
        autoFetch: props.data === undefined && !!props.getUrl,
    });

    const linkRender = (options: IMoreProps) => {
        return <More {...props} {...options} />;
    };
    if (isLoading) {
        return <LoadingScreen />;
    }

    return (
        <>
            <DataGrid
                dataSource={props.data ?? data ?? []}
                rowAlternationEnabled
                showBorders
                allowColumnResizing
                columnResizingMode='nextColumn'
                width={'100%'}
            >
                <Column dataField={'name'} caption='Filnavn' />
                <Column dataField={'date'} caption='Dato' dataType={'date'} format={'dd-MM-yyyy'} width='90px' />
                <Column dataField={'username'} caption='Bruger' />
                <Column caption='' dataField={'url'} cellTemplate='linkRender' width='60px' />
                <Template name='linkRender' render={linkRender} />
            </DataGrid>
        </>
    );
};

export default FileInfoGrid;
