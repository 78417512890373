import Api from './api';

export const downloadToUser = async (dataurl: string, filename: string): Promise<void | Error> => {
    try {
        const link = document.createElement('a');
        link.href = dataurl;
        link.download = filename;
        link.click();
    } catch (err) {
        return err as Error;
    }
};

export const downloadSVGToUser = async (svg: Element, filename: string): Promise<void | Error> => {
    const serializedSVG = new XMLSerializer().serializeToString(svg);
    const base64SVG = window.btoa(serializedSVG); // Only deprecated in node.js, not on frontend
    const dataUrl = `data:image/svg+xml;base64,${base64SVG}`;
    console.log('svg url:', dataUrl);
    try {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = filename;
        link.click();
    } catch (err) {
        return err as Error;
    }
};

export const apiDownload = async (url: string, filename: string, download: boolean = true): Promise<void | Error> => {
    try {
        // Using deprecated function for fetch's easier blob api
        await Api.fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.target = '_blank';
                link.title = filename;
                if (download) link.download = filename;
                link.click();
            })
            .catch((err) => {
                throw new Error(err);
            });
    } catch (err) {
        return err as Error;
    }
};
