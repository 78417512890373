
    const SvgXx_07_07 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="361.26 225.44 89.26999999999998 168.54000000000002" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M405.9 230.44L405.9 388.98Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.9 309.71L445.53 230.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.9 309.71L366.26 230.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_07_07;

    