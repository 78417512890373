
    const Svg3_07_06 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="314.13 258.46 183.41000000000003 96.70000000000005" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M492.54 263.46L319.13 263.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319.13 263.46L319.13 350.16Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319.13 350.16L492.54 350.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M492.54 350.16L492.54 263.46Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.81 263.46L340.81 280.8Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.16 263.46L384.16 280.8Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.51 263.46L427.51 280.8Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.87 263.46L470.87 280.8Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.51 350.16L427.51 332.82Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.51 332.82L453.53 287.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg3_07_06;

    