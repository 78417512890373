
    const Svg1_05_01 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="317.53 240.41 153.64000000000004 153.64000000000001" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M466.17 389.05L466.17 245.41Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.53 245.41L322.53 389.05Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.17 245.41L322.53 389.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.53 389.05L466.17 389.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.17 245.41L322.53 245.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_05_01;

    