
    const Svg2_01_13 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="318.66 232.78 267.0199999999999 139.16" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M323.66 237.78L323.66 366.94Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.81 366.94L452.81 237.78Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.66 366.94L452.81 366.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.81 237.78L323.66 237.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.81 302.36L580.68 302.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.81 302.36L452.81 302.36 500.27 289.45 500.27 315.28 452.81 302.36Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M363.15 270.07L363.15 334.65Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.15 334.65L400.05 334.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_01_13;

    