
    const Svg2_05_01 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="289.64 208.52 213.01 212.98" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M294.66 317L294.66 313.02 294.64 315.01 294.66 317Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M294.66 317L294.72 319 294.66 313.02 294.66 317Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M294.72 319L294.72 311.03 294.66 313.02 294.72 319Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M294.72 319L294.82 320.99 294.72 311.03 294.72 319Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M294.82 320.99L294.82 309.04 294.72 311.03 294.82 320.99Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M294.82 320.99L294.95 322.98 294.82 309.04 294.82 320.99Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M294.95 322.98L294.95 307.05 294.82 309.04 294.95 322.98Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M294.95 322.98L295.13 324.96 294.95 307.05 294.95 322.98Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M295.13 324.96L295.13 305.06 294.95 307.05 295.13 324.96Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M295.13 324.96L295.35 326.94 295.13 305.06 295.13 324.96Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M295.35 326.94L295.35 303.08 295.13 305.06 295.35 326.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M295.35 326.94L295.6 328.92 295.35 303.08 295.35 326.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M295.6 328.92L295.6 301.1 295.35 303.08 295.6 328.92Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M295.6 328.92L295.89 330.89 295.6 301.1 295.6 328.92Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M295.89 330.89L295.89 299.13 295.6 301.1 295.89 330.89Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M295.89 330.89L296.22 332.86 295.89 299.13 295.89 330.89Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M296.22 332.86L296.22 297.17 295.89 299.13 296.22 332.86Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M296.22 332.86L296.59 334.81 296.22 297.17 296.22 332.86Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M296.59 334.81L296.59 295.21 296.22 297.17 296.59 334.81Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M296.59 334.81L297 336.76 296.59 295.21 296.59 334.81Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M297 336.76L297 293.26 296.59 295.21 297 336.76Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M297 336.76L297.45 338.71 297 293.26 297 336.76Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M297.45 338.71L297.45 291.32 297 293.26 297.45 338.71Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M297.45 338.71L297.93 340.64 297.45 291.32 297.45 338.71Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M297.93 340.64L297.93 289.38 297.45 291.32 297.93 340.64Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M297.93 340.64L298.45 342.56 297.93 289.38 297.93 340.64Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M298.45 342.56L298.45 287.46 297.93 289.38 298.45 342.56Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M298.45 342.56L299.01 344.48 298.45 287.46 298.45 342.56Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M299.01 344.48L299.01 285.55 298.45 287.46 299.01 344.48Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M299.01 344.48L299.61 346.38 299.01 285.55 299.01 344.48Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M299.61 346.38L299.61 283.64 299.01 285.55 299.61 346.38Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M299.61 346.38L300.24 348.27 299.61 283.64 299.61 346.38Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M300.24 348.27L300.24 281.76 299.61 283.64 300.24 348.27Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M300.24 348.27L300.92 350.14 300.24 281.76 300.24 348.27Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M300.92 350.14L300.92 279.88 300.24 281.76 300.92 350.14Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M300.92 350.14L301.62 352.01 300.92 279.88 300.92 350.14Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M301.62 352.01L301.62 278.02 300.92 279.88 301.62 352.01Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M301.62 352.01L302.37 353.86 301.62 278.02 301.62 352.01Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M302.37 353.86L302.37 276.17 301.62 278.02 302.37 353.86Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M302.37 353.86L303.15 355.69 302.37 276.17 302.37 353.86Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M303.15 355.69L303.15 274.33 302.37 276.17 303.15 355.69Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M303.15 355.69L303.97 357.51 303.15 274.33 303.15 355.69Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M303.97 357.51L303.97 272.52 303.15 274.33 303.97 357.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M303.97 357.51L304.82 359.31 303.97 272.52 303.97 357.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M304.82 359.31L304.82 270.71 303.97 272.52 304.82 359.31Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M304.82 359.31L305.7 361.09 304.82 270.71 304.82 359.31Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M305.7 361.09L305.7 268.93 304.82 270.71 305.7 361.09Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M305.7 361.09L306.63 362.86 305.7 268.93 305.7 361.09Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M306.63 362.86L306.63 267.16 305.7 268.93 306.63 362.86Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M306.63 362.86L307.58 364.61 306.63 267.16 306.63 362.86Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M307.58 364.61L307.58 265.41 306.63 267.16 307.58 364.61Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M307.58 364.61L308.57 366.34 307.58 265.41 307.58 364.61Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M308.57 366.34L308.57 263.68 307.58 265.41 308.57 366.34Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M308.57 366.34L309.6 368.05 308.57 263.68 308.57 366.34Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M309.6 368.05L309.6 261.98 308.57 263.68 309.6 368.05Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M309.6 368.05L310.66 369.74 309.6 261.98 309.6 368.05Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M310.66 369.74L310.66 260.29 309.6 261.98 310.66 369.74Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M310.66 369.74L311.75 371.4 310.66 260.29 310.66 369.74Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M311.75 371.4L311.75 258.62 310.66 260.29 311.75 371.4Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M311.75 371.4L312.87 373.05 311.75 258.62 311.75 371.4Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M312.87 373.05L312.87 256.97 311.75 258.62 312.87 373.05Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M312.87 373.05L314.03 374.67 312.87 256.97 312.87 373.05Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M314.03 374.67L314.03 255.35 312.87 256.97 314.03 374.67Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M314.03 374.67L315.21 376.28 314.03 255.35 314.03 374.67Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M315.21 376.28L315.21 253.75 314.03 255.35 315.21 376.28Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M315.21 376.28L316.43 377.85 315.21 253.75 315.21 376.28Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M316.43 377.85L316.43 252.17 315.21 253.75 316.43 377.85Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M316.43 377.85L317.68 379.41 316.43 252.17 316.43 377.85Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M317.68 379.41L317.68 250.62 316.43 252.17 317.68 379.41Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M317.68 379.41L318.96 380.93 317.68 250.62 317.68 379.41Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M318.96 380.93L318.96 249.09 317.68 250.62 318.96 380.93Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M318.96 380.93L320.27 382.44 318.96 249.09 318.96 380.93Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M320.27 382.44L320.27 247.59 318.96 249.09 320.27 382.44Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M320.27 382.44L321.61 383.91 320.27 247.59 320.27 382.44Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M321.61 383.91L321.61 246.11 320.27 247.59 321.61 383.91Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M321.61 383.91L322.98 385.36 321.61 246.11 321.61 383.91Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M322.98 385.36L322.98 244.66 321.61 246.11 322.98 385.36Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M322.98 385.36L324.37 386.79 322.98 244.66 322.98 385.36Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M324.37 386.79L324.37 243.24 322.98 244.66 324.37 386.79Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M324.37 386.79L396.15 315.01 324.37 243.24 324.37 386.79Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M467.92 386.79L467.92 243.24 396.15 315.01 467.92 386.79Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M467.92 386.79L469.32 385.36 467.92 243.24 467.92 386.79Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M469.32 385.36L469.32 244.66 467.92 243.24 469.32 385.36Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M469.32 385.36L470.68 383.91 469.32 244.66 469.32 385.36Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M470.68 383.91L470.68 246.11 469.32 244.66 470.68 383.91Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M470.68 383.91L472.02 382.44 470.68 246.11 470.68 383.91Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M472.02 382.44L472.02 247.59 470.68 246.11 472.02 382.44Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M472.02 382.44L473.33 380.93 472.02 247.59 472.02 382.44Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M473.33 380.93L473.33 249.09 472.02 247.59 473.33 380.93Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M473.33 380.93L474.61 379.41 473.33 249.09 473.33 380.93Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.61 379.41L474.61 250.62 473.33 249.09 474.61 379.41Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M474.61 379.41L475.86 377.85 474.61 250.62 474.61 379.41Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M475.86 377.85L475.86 252.17 474.61 250.62 475.86 377.85Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M475.86 377.85L477.08 376.28 475.86 252.17 475.86 377.85Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M477.08 376.28L477.08 253.75 475.86 252.17 477.08 376.28Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M477.08 376.28L478.27 374.67 477.08 253.75 477.08 376.28Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M478.27 374.67L478.27 255.35 477.08 253.75 478.27 374.67Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M478.27 374.67L479.42 373.05 478.27 255.35 478.27 374.67Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M479.42 373.05L479.42 256.97 478.27 255.35 479.42 373.05Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M479.42 373.05L480.54 371.4 479.42 256.97 479.42 373.05Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M480.54 371.4L480.54 258.62 479.42 256.97 480.54 371.4Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M480.54 371.4L481.64 369.74 480.54 258.62 480.54 371.4Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M481.64 369.74L481.64 260.29 480.54 258.62 481.64 369.74Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M481.64 369.74L482.69 368.05 481.64 260.29 481.64 369.74Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M482.69 368.05L482.69 261.98 481.64 260.29 482.69 368.05Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M482.69 368.05L483.72 366.34 482.69 261.98 482.69 368.05Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M483.72 366.34L483.72 263.68 482.69 261.98 483.72 366.34Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M483.72 366.34L484.71 364.61 483.72 263.68 483.72 366.34Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M484.71 364.61L484.71 265.41 483.72 263.68 484.71 364.61Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M484.71 364.61L485.67 362.86 484.71 265.41 484.71 364.61Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M485.67 362.86L485.67 267.16 484.71 265.41 485.67 362.86Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M485.67 362.86L486.59 361.09 485.67 267.16 485.67 362.86Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M486.59 361.09L486.59 268.93 485.67 267.16 486.59 361.09Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M486.59 361.09L487.48 359.31 486.59 268.93 486.59 361.09Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M487.48 359.31L487.48 270.71 486.59 268.93 487.48 359.31Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M487.48 359.31L488.33 357.51 487.48 270.71 487.48 359.31Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M488.33 357.51L488.33 272.52 487.48 270.71 488.33 357.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M488.33 357.51L489.14 355.69 488.33 272.52 488.33 357.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M489.14 355.69L489.14 274.33 488.33 272.52 489.14 355.69Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M489.14 355.69L489.92 353.86 489.14 274.33 489.14 355.69Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M489.92 353.86L489.92 276.17 489.14 274.33 489.92 353.86Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M489.92 353.86L490.67 352.01 489.92 276.17 489.92 353.86Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M490.67 352.01L490.67 278.02 489.92 276.17 490.67 352.01Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M490.67 352.01L491.38 350.14 490.67 278.02 490.67 352.01Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M491.38 350.14L491.38 279.88 490.67 278.02 491.38 350.14Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M491.38 350.14L492.05 348.27 491.38 279.88 491.38 350.14Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M492.05 348.27L492.05 281.76 491.38 279.88 492.05 348.27Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M492.05 348.27L492.68 346.38 492.05 281.76 492.05 348.27Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M492.68 346.38L492.68 283.64 492.05 281.76 492.68 346.38Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M492.68 346.38L493.28 344.48 492.68 283.64 492.68 346.38Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M493.28 344.48L493.28 285.55 492.68 283.64 493.28 344.48Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M493.28 344.48L493.84 342.56 493.28 285.55 493.28 344.48Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M493.84 342.56L493.84 287.46 493.28 285.55 493.84 342.56Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M493.84 342.56L494.36 340.64 493.84 287.46 493.84 342.56Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M494.36 340.64L494.36 289.38 493.84 287.46 494.36 340.64Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M494.36 340.64L494.85 338.71 494.36 289.38 494.36 340.64Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M494.85 338.71L494.85 291.32 494.36 289.38 494.85 338.71Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M494.85 338.71L495.29 336.76 494.85 291.32 494.85 338.71Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M495.29 336.76L495.29 293.26 494.85 291.32 495.29 336.76Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M495.29 336.76L495.7 334.81 495.29 293.26 495.29 336.76Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M495.7 334.81L495.7 295.21 495.29 293.26 495.7 334.81Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M495.7 334.81L496.07 332.86 495.7 295.21 495.7 334.81Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M496.07 332.86L496.07 297.17 495.7 295.21 496.07 332.86Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M496.07 332.86L496.4 330.89 496.07 297.17 496.07 332.86Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M496.4 330.89L496.4 299.13 496.07 297.17 496.4 330.89Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M496.4 330.89L496.69 328.92 496.4 299.13 496.4 330.89Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M496.69 328.92L496.69 301.1 496.4 299.13 496.69 328.92Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M496.69 328.92L496.95 326.94 496.69 301.1 496.69 328.92Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M496.95 326.94L496.95 303.08 496.69 301.1 496.95 326.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M496.95 326.94L497.16 324.96 496.95 303.08 496.95 326.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M497.16 324.96L497.16 305.06 496.95 303.08 497.16 324.96Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M497.16 324.96L497.34 322.98 497.16 305.06 497.16 324.96Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M497.34 322.98L497.34 307.05 497.16 305.06 497.34 322.98Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M497.34 322.98L497.48 320.99 497.34 307.05 497.34 322.98Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M497.48 320.99L497.48 309.04 497.34 307.05 497.48 320.99Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M497.48 320.99L497.57 319 497.48 309.04 497.48 320.99Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M497.57 319L497.57 311.03 497.48 309.04 497.57 319Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M497.57 319L497.63 317 497.57 311.03 497.57 319Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M497.63 317L497.63 313.02 497.57 311.03 497.63 317Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M497.63 317L497.65 315.01 497.63 313.02 497.63 317Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M497.65 315.01L497.45 321.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M497.45 321.32L496.87 327.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M496.87 327.61L495.89 333.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M495.89 333.85L494.52 340.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M494.52 340.01L492.78 346.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M492.78 346.08L490.66 352.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M490.66 352.03L488.18 357.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.18 357.83L485.34 363.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M485.34 363.47L482.15 368.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M482.15 368.92L478.64 374.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.64 374.16L474.8 379.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M474.8 379.18L470.66 383.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.66 383.94L466.23 388.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.23 388.44L461.53 392.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.53 392.66L456.57 396.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M456.57 396.57L451.39 400.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.39 400.17L445.98 403.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M445.98 403.44L440.39 406.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.39 406.37L434.63 408.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.63 408.94L428.71 411.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.71 411.15L422.67 412.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.67 412.99L416.53 414.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.53 414.45L410.31 415.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.31 415.52L404.03 416.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.03 416.21L397.72 416.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.72 416.5L391.41 416.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.41 416.41L385.12 415.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M385.12 415.92L378.87 415.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.87 415.03L372.68 413.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.68 413.77L366.59 412.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.59 412.12L360.61 410.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.61 410.09L354.76 407.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.76 407.7L349.08 404.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.08 404.95L343.58 401.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.58 401.85L338.29 398.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M338.29 398.41L333.21 394.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M333.21 394.65L328.38 390.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.38 390.58L323.82 386.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.82 386.23L319.53 381.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319.53 381.59L315.54 376.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M315.54 376.7L311.86 371.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M311.86 371.57L308.5 366.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M308.5 366.22L305.49 360.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M305.49 360.67L302.83 354.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M302.83 354.95L300.53 349.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M300.53 349.07L298.59 343.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M298.59 343.06L297.04 336.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M297.04 336.94L295.87 330.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M295.87 330.73L295.08 324.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M295.08 324.47L294.69 318.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M294.69 318.17L294.69 311.85Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M294.69 311.85L295.08 305.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M295.08 305.55L295.87 299.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M295.87 299.29L297.04 293.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M297.04 293.08L298.59 286.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M298.59 286.97L300.53 280.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M300.53 280.95L302.83 275.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M302.83 275.08L305.49 269.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M305.49 269.35L308.5 263.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M308.5 263.8L311.86 258.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M311.86 258.45L315.54 253.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M315.54 253.32L319.53 248.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M319.53 248.43L323.82 243.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.82 243.8L328.38 239.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.38 239.44L333.21 235.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M333.21 235.37L338.29 231.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M338.29 231.61L343.58 228.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.58 228.18L349.08 225.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.08 225.08L354.76 222.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M354.76 222.32L360.61 219.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.61 219.93L366.59 217.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.59 217.91L372.68 216.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M372.68 216.26L378.87 214.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.87 214.99L385.12 214.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M385.12 214.11L391.41 213.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M391.41 213.62L397.72 213.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.72 213.52L404.03 213.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.03 213.81L410.31 214.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.31 214.5L416.53 215.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.53 215.57L422.67 217.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.67 217.03L428.71 218.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.71 218.87L434.63 221.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.63 221.08L440.39 223.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.39 223.66L445.98 226.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M445.98 226.58L451.39 229.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.39 229.85L456.57 233.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M456.57 233.45L461.53 237.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.53 237.37L466.23 241.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.23 241.58L470.66 246.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M470.66 246.08L474.8 250.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M474.8 250.85L478.64 255.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.64 255.86L482.15 261.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M482.15 261.1L485.34 266.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M485.34 266.55L488.18 272.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.18 272.19L490.66 278" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M490.66 278L492.78 283.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M492.78 283.95L494.52 290.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M494.52 290.01L495.89 296.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M495.89 296.18L496.87 302.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M496.87 302.41L497.45 308.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M497.45 308.7L497.65 315.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.37 243.24L467.92 386.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.37 386.79L467.92 243.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_05_01;

    