import { IInput, ItemTypes } from '../interfaces';
import { generateProps, generateRules } from './generate';

import { SimpleItem } from 'devextreme-react/form';
import { defaultIInputs } from '../defaults';

const InputDefault = (input: IInput) => (
    <SimpleItem {...generateProps(input, defaultIInputs[ItemTypes.Default])}>
        {generateRules(input, defaultIInputs[ItemTypes.Default])}
    </SimpleItem>
);

export default InputDefault;
