
    const Svg3_01_03 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="232.71 211.61 428.78999999999996 145.09999999999997" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M480.88 270.65L440.35 270.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M480.88 297.67L480.88 270.65Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.35 297.67L480.88 297.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M237.71 338.2L440.35 338.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.35 338.2L440.35 230.12Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.35 230.12L237.71 230.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M237.71 230.12L237.71 338.2Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 562.91 351.71 A 100.633 100.633 0 0 0 562.91 216.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M521.4 284.16L656.5 284.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M521.4 284.16L544.8 270.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M521.4 284.16L544.8 297.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M656.5 284.16L633.1 270.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M656.5 284.16L633.1 297.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M562.91 216.61L566.52 243.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M562.91 216.61L587.91 226.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M562.91 351.71L566.52 324.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M562.91 351.71L587.91 341.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg3_01_03;

    