
    const SvgXx_06_08 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="317.26 230.86 219.49 128.56" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M 528.67 354.42 A 59.304 59.304 0 0 0 531.75 235.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M528.67 354.42L322.26 354.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default SvgXx_06_08;

    