class CookieHandler {
    public static read(name: string): {} | string | undefined {
        const result = new RegExp('(?:^|; )' + encodeURIComponent(name) + '=([^;]*)').exec(document.cookie);
        if (result) {
            try {
                return JSON.parse(result[1]);
            } catch (err) {
                return result[1];
            }
        }
        return undefined;
    }

    public static write(name: string, value: unknown, days?: number) {
        if (!days) {
            days = 365 * 20;
        }
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        const expires = '; expires=' + date.toUTCString();
        const sameSite = '; SameSite=Strict';
        document.cookie = name + '=' + JSON.stringify(value) + expires + sameSite + '; path=/';
    }

    public static remove(name: string) {
        this.write(name, '', -1);
    }
}

export default CookieHandler;
