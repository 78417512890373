
    const Svg1_05_12 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="313.08 228.21 155.59000000000003 155.61999999999998" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M318.08 233.21L318.08 378.83Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M390.96 269.51L390.96 342.39Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M463.67 378.83L463.67 233.21Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M318.08 378.83L463.67 378.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M463.67 233.21L318.08 233.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.26 284.09L446.61 279.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.61 279.61L443.68 275.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.68 275.35L440.49 271.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.49 271.33L437.06 267.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.06 267.57L433.4 264.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.4 264.1L429.52 260.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.52 260.91L425.46 258.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.46 258.05L421.22 255.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.22 255.5L416.84 253.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.84 253.3L412.32 251.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.32 251.45L407.7 249.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.7 249.96L403 248.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403 248.83L398.24 248.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.24 248.08L393.44 247.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.44 247.7L388.63 247.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.63 247.7L383.82 248.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.82 248.08L379.06 248.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.06 248.83L374.35 249.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.35 249.96L369.72 251.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.72 251.45L365.2 253.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.2 253.3L360.81 255.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.81 255.5L356.56 258.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.56 258.05L352.49 260.91" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.49 260.91L348.6 264.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.6 264.1L344.93 267.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M344.93 267.57L341.48 271.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.48 271.33L338.27 275.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M338.27 275.35L335.33 279.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M335.33 279.61L332.66 284.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_05_12;

    