
    const Svg2_01_14 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="320.87 242.95 239.33000000000004 125.24000000000001" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M325.87 247.95L325.87 363.19Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.11 363.19L441.11 247.95Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M325.87 363.19L441.11 363.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.11 247.95L325.87 247.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M555.2 269.8L441.11 269.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.11 341.34L555.2 341.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M555.2 269.8L555.2 269.8 512.86 281.33 512.86 258.28 555.2 269.8Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M441.11 341.34L441.11 341.34 483.45 329.81 483.45 352.86 441.11 341.34Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M361.11 276.76L361.11 334.38Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M361.11 334.38L394.03 334.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_01_14;

    