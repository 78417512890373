const SvgUdtryk = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        width='30'
        zoomAndPan='magnify'
        viewBox='0 0 30 30.000001'
        height='30'
        preserveAspectRatio='xMidYMid meet'
        version='1.0'
    >
        <defs>
            <clipPath id='edee381a94'>
                <path
                    d='M 0.484375 0 L 29.515625 0 L 29.515625 29.03125 L 0.484375 29.03125 Z M 0.484375 0 '
                    clipRule='nonzero'
                />
            </clipPath>
            <clipPath id='8ccabce6f4'>
                <path
                    d='M 4.632812 4.148438 L 25.679688 4.148438 L 25.679688 25.195312 L 4.632812 25.195312 Z M 4.632812 4.148438 '
                    clipRule='nonzero'
                />
            </clipPath>
        </defs>
        <g clipPath='url(#edee381a94)'>
            <path
                fill='{{replacement-value-color}}'
                d='M 15 0 C 6.980469 0 0.484375 6.496094 0.484375 14.515625 C 0.484375 22.535156 6.980469 29.03125 15 29.03125 C 23.019531 29.03125 29.515625 22.535156 29.515625 14.515625 C 29.515625 6.496094 23.019531 0 15 0 Z M 15 26.128906 C 8.582031 26.128906 3.386719 20.933594 3.386719 14.515625 C 3.386719 8.101562 8.582031 2.902344 15 2.902344 C 21.417969 2.902344 26.613281 8.101562 26.613281 14.515625 C 26.613281 20.933594 21.417969 26.128906 15 26.128906 Z M 15 26.128906 '
                fillOpacity='1'
                fillRule='nonzero'
            />
        </g>
        <g clipPath='url(#8ccabce6f4)'>
            <path
                fill='{{replacement-value-color}}'
                d='M 15.15625 4.148438 C 9.339844 4.148438 4.632812 8.855469 4.632812 14.671875 C 4.632812 20.484375 9.339844 25.195312 15.15625 25.195312 C 20.96875 25.195312 25.679688 20.484375 25.679688 14.671875 C 25.679688 8.855469 20.96875 4.148438 15.15625 4.148438 Z M 15.15625 23.089844 C 10.503906 23.089844 6.734375 19.324219 6.734375 14.671875 C 6.734375 10.019531 10.503906 6.253906 15.15625 6.253906 C 19.808594 6.253906 23.574219 10.019531 23.574219 14.671875 C 23.574219 19.324219 19.808594 23.089844 15.15625 23.089844 Z M 15.15625 23.089844 '
                fillOpacity='1'
                fillRule='nonzero'
            />
        </g>
    </svg>
);
export default SvgUdtryk;
