
    const Svg1_05_04D = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="305.91 157.9 270.84999999999997 270.86" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M385.44 349.23L571.76 349.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M571.76 349.23L571.76 311.97Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M571.76 311.97L385.44 311.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M385.44 311.97L385.44 349.23Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M497.23 349.23L497.23 423.76Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M497.23 423.76L310.91 423.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.91 423.76L310.91 162.9Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M310.91 162.9L497.23 162.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M497.23 162.9L497.23 311.97Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_05_04D;

    