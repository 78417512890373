
    const Svg2_01_04 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="292.26 213.46 227.20000000000005 190.98999999999998" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M478.26 399.45L451.11 399.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.01 399.45L396.81 399.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M378.71 399.45L342.51 399.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.41 399.45L297.26 399.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M297.26 399.45L297.26 372.3Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M297.26 354.2L297.26 318.01Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M297.26 299.91L297.26 263.71Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M297.26 245.61L297.26 218.46Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M297.26 218.46L324.41 218.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M342.51 218.46L378.71 218.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.81 218.46L433.01 218.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M451.11 218.46L478.26 218.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.26 218.46L478.26 245.61Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.26 263.71L478.26 299.91Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.26 318.01L478.26 354.2Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M478.26 372.3L478.26 399.45Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M514.46 218.46L514.46 245.61Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M514.46 263.71L514.46 299.91Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M514.46 318.01L514.46 354.2Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M514.46 372.3L514.46 399.45Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_01_04;

    