
    const Svg4_05_07 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="325.0 239.43 189.36 99.68" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M330 334.11L509.36 334.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M509.36 334.11L509.36 244.43Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M509.36 244.43L330 244.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330 244.43L330 334.11Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.9 262.37L356.9 316.18Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.9 316.18L392.77 289.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.77 289.27L356.9 262.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.29 311.69L439.19 266.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.29 266.85L439.19 311.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452 311.69L481.89 266.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452 266.85L481.89 311.69" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg4_05_07;

    