import Api, { AuthResponse } from '../networking/api';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from './redux-base-hooks';

import CookieHandler from '../utils/cookie-handler';
import { UserPermissions } from '../../components/restricted/interfaces';
import { getQueryParam } from './use-query-param';
import getStore from '../store';
import { useCallback } from 'react';
import useCentralizedSnackbar from './redux-use-centralized-snackbar';
import useMountEffect from './use-mount-effect';

export interface IUserState {
    active: boolean;
    stayActive: boolean;
    id: number | undefined;
    token: string | undefined;

    userName: string | undefined;
    role: string | undefined;
    team: string | undefined;
    permissions: UserPermissions[];
}

const initialState: IUserState = {
    active: false,
    stayActive: false,
    id: undefined,
    token: undefined,

    userName: undefined,
    role: undefined,
    team: undefined,
    permissions: [],
};

export const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setFromAPIResponse: (state: IUserState, action: PayloadAction<AuthResponse>) => {
            Api.init(action.payload.token!);

            state.active = true;
            state.id = action.payload.id;
            state.token = action.payload.token;
            state.userName = action.payload.username;
            state.role = action.payload.role;
            state.team = action.payload.team;
            state.permissions = action.payload.permissions;
        },

        stayActive: (state: IUserState, action: PayloadAction<boolean | undefined>) => {
            state.stayActive = action?.payload ?? !state.stayActive;
            if (state.stayActive && state.token) CookieHandler.write('auth', state.token);
            else CookieHandler.remove('auth');
        },

        signOut: () => {
            CookieHandler.remove('auth');
            return initialState;
        },
    },
});

export const userReducer = userSlice.reducer;

export const useAuthInit = () => {
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useCentralizedSnackbar();

    // Check CT auth
    useMountEffect(() => {
        const blacklist = ['/reset', '/forgot'];

        // Do not try to validate on blacklisted pages
        if (blacklist.includes(window.location.pathname.toLowerCase())) return;

        // Try access_token in either url or redirect url
        let token = getQueryParam('access_token');
        if (!token) {
            const url = getQueryParam('redirect');
            if (url) {
                token = new URL(url).searchParams.get('access_token');
            }
        }

        if (token) {
            // Remove token from url and history
            const url = new URL(window.location.href);
            url.searchParams.delete('access_token');
            window.history.replaceState(null, '', url.toString());
        } else {
            const cookie = CookieHandler.read('auth');
            if (typeof cookie !== 'string') return;

            token = cookie;
        }

        if (!token) return;

        const validate = async () => {
            const success = await Api.validateToken(token as string);
            if (success) dispatch(userSlice.actions.stayActive(true));
            else enqueueSnackbar('Din session er udløbet. Log ind igen for at fortsætte.', { variant: 'warning' });
        };

        validate();
    });
};

export const useAuth = () => {
    const dispatch = useAppDispatch();

    const setUser = useCallback((a: AuthResponse) => dispatch(userSlice.actions.setFromAPIResponse(a)), [dispatch]);
    const stayActive = useCallback((b?: boolean) => dispatch(userSlice.actions.stayActive(b)), [dispatch]);
    const signOut = useCallback(() => {
        dispatch(userSlice.actions.signOut());
    }, [dispatch]);
    return { setUser, stayActive, signOut };
};

export const getUser = () => getStore().getState().user;

const useUser = () => {
    return useAppSelector((state) => state.user);
};

export default useUser;
