
    const Svg2_01_15 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="315.99 212.58 161.17000000000002 155.41" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M320.99 340.71L320.99 239.85Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.99 239.85L383.4 217.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M320.99 340.71L383.4 362.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.4 217.58L383.4 362.99Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M472.16 258.98L383.4 258.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M383.4 321.59L472.16 321.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M472.16 258.98L472.16 258.98 435.1 269.06 435.1 248.89 472.16 258.98Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M383.4 321.59L383.4 321.59 420.46 311.5 420.46 331.67 383.4 321.59Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
</svg>
    );
    export default Svg2_01_15;

    