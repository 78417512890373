
    const Svg4_05_08 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="353.6 196.72 124.71999999999997 239.44000000000003" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M457.26 259.08L457.06 263.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.06 263.12L456.47 267.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M456.47 267.13L455.48 271.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.48 271.07L454.12 274.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.12 274.88L452.38 278.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.38 278.55L450.3 282.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.3 282.02L447.89 285.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.89 285.28L445.17 288.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M445.17 288.28L442.16 291" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.16 291L438.91 293.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438.91 293.42L435.43 295.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.43 295.5L431.77 297.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.77 297.23L427.95 298.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.95 298.6L424.02 299.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.02 299.58L420.01 300.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.01 300.18L415.96 300.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.96 300.38L411.91 300.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.91 300.18L407.91 299.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.91 299.58L403.97 298.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.97 298.6L400.16 297.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.16 297.23L396.49 295.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.49 295.5L393.02 293.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.02 293.42L389.76 291" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.76 291L386.76 288.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.76 288.28L384.04 285.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.04 285.28L381.62 282.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.62 282.02L379.54 278.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.54 278.55L377.81 274.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.81 274.88L376.44 271.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.44 271.07L375.46 267.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.46 267.13L374.86 263.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.86 263.12L374.66 259.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.66 259.08L374.86 255.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.86 255.03L375.46 251.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.46 251.02L376.44 247.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.44 247.09L377.81 243.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.81 243.27L379.54 239.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.54 239.61L381.62 236.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.62 236.13L384.04 232.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.04 232.88L386.76 229.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.76 229.87L389.76 227.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.76 227.15L393.02 224.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.02 224.74L396.49 222.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.49 222.65L400.16 220.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.16 220.92L403.97 219.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.97 219.56L407.91 218.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.91 218.57L411.91 217.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.91 217.98L415.96 217.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.96 217.78L420.01 217.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.01 217.98L424.02 218.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.02 218.57L427.95 219.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.95 219.56L431.77 220.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.77 220.92L435.43 222.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.43 222.65L438.91 224.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438.91 224.74L442.16 227.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.16 227.15L445.17 229.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M445.17 229.87L447.89 232.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.89 232.88L450.3 236.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.3 236.13L452.38 239.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.38 239.61L454.12 243.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.12 243.27L455.48 247.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.48 247.09L456.47 251.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M456.47 251.02L457.06 255.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.06 255.03L457.26 259.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.76 288.28L445.17 229.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.76 229.87L445.17 288.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.26 373.8L457.06 377.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.06 377.84L456.47 381.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M456.47 381.85L455.48 385.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.48 385.78L454.12 389.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.12 389.6L452.38 393.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.38 393.26L450.3 396.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.3 396.74L447.89 400" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.89 400L445.17 403" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M445.17 403L442.16 405.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.16 405.72L438.91 408.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438.91 408.14L435.43 410.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.43 410.22L431.77 411.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.77 411.95L427.95 413.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.95 413.32L424.02 414.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.02 414.3L420.01 414.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.01 414.9L415.96 415.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.96 415.1L411.91 414.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.91 414.9L407.91 414.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.91 414.3L403.97 413.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.97 413.32L400.16 411.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.16 411.95L396.49 410.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.49 410.22L393.02 408.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.02 408.14L389.76 405.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.76 405.72L386.76 403" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.76 403L384.04 400" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.04 400L381.62 396.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.62 396.74L379.54 393.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.54 393.26L377.81 389.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.81 389.6L376.44 385.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.44 385.78L375.46 381.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.46 381.85L374.86 377.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.86 377.84L374.66 373.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.66 373.8L374.86 369.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374.86 369.75L375.46 365.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.46 365.74L376.44 361.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.44 361.81L377.81 357.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M377.81 357.99L379.54 354.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.54 354.33L381.62 350.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M381.62 350.85L384.04 347.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.04 347.6L386.76 344.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.76 344.59L389.76 341.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.76 341.87L393.02 339.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.02 339.46L396.49 337.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.49 337.37L400.16 335.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.16 335.64L403.97 334.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.97 334.28L407.91 333.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M407.91 333.29L411.91 332.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.91 332.7L415.96 332.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.96 332.5L420.01 332.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.01 332.7L424.02 333.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.02 333.29L427.95 334.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.95 334.28L431.77 335.64" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.77 335.64L435.43 337.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.43 337.37L438.91 339.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438.91 339.46L442.16 341.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.16 341.87L445.17 344.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M445.17 344.59L447.89 347.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M447.89 347.6L450.3 350.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.3 350.85L452.38 354.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.38 354.33L454.12 357.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.12 357.99L455.48 361.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.48 361.81L456.47 365.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M456.47 365.74L457.06 369.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.06 369.75L457.26 373.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.76 403L445.17 344.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M386.76 344.59L445.17 403" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.6 431.16L473.32 431.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M473.32 431.16L473.32 201.72Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M473.32 201.72L358.6 201.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.6 201.72L358.6 431.16Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg4_05_08;

    