import { AppDispatch, RootState } from '../store';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { IDictionary } from '../utils/types';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const transferObjectValues = (target: IDictionary, sender: IDictionary, omit?: (string | number)[]) => {
    Object.keys(sender).forEach((k) => {
        if (omit === undefined || !omit.includes(k)) target[k] = sender[k];
    });
};
