import './uploader.css';

import { DragDropUploaderProps, UploaderOptions } from './drag-drop-uploader';
import Popup, { ToolbarItem } from 'devextreme-react/popup';
import { RefObject, useImperativeHandle, useRef, useState } from 'react';

import Api from '../../shared/networking/api';
import { DxEventInstance } from '../../shared/utils/types';
import { EventInfo } from 'devextreme/events';
import FileUploader from 'devextreme-react/file-uploader';
import ScrollView from 'devextreme-react/scroll-view';
import { ValueChangedEvent } from 'devextreme/ui/file_uploader';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import { useArgsEffect } from '../../shared/hooks/use-args';

//#region Defaults and Interfaces

// This is used internally in Dx but for some reason i can't import it
type PopupInstance = DxEventInstance<Parameters<NonNullable<Popup['props']['onContentReady']>>['0']>;

export interface DragDropUploaderPopupProps extends DragDropUploaderProps {
    popupOptions?: PopupOptions;
}

export interface PopupOptions {
    visible?: boolean;
    hideOnOutsideClick?: boolean;
    title?: string;
    showTitle?: boolean;
    width?: string | number;
    height?: string | number;
    dragEnable?: boolean;
    fullScreen?: boolean;
    handleContentReady?(e: EventInfo<PopupInstance>): void;
    overrideCloseFunction?(anyUploads: boolean): void;
    onSave?: VoidFunction;
}

const defaultProps: DragDropUploaderPopupProps = {
    uploadUrl: '',
    uploaderOptions: {
        innerHtml: (
            <p>
                <span>Upload Filer</span>
            </p>
        ),
        multiple: true,
    },
    popupOptions: {
        visible: true,
        hideOnOutsideClick: true,
        title: 'Upload',
        showTitle: true,
        width: '80%',
        //height: '50%',
        dragEnable: false,
        fullScreen: isMobile,
    },
};

interface IUploadRenderProps {
    uploadUrl: string;
    uploaderOptions?: UploaderOptions;
    hasUnuploadedFilesCallback(hasFiles: boolean): void;
}

interface IOutsidePopupRenderProps extends IUploadRenderProps {
    postUploadedAllCallback: (anyUploads: boolean) => void;
    handle: RefObject<OutsidePopupRenderHandle>;
}

interface OutsidePopupRenderHandle {
    flushUploads(uploadAll?: boolean): void;
}

/** The upload component */
const OutsidePopupRender = ({
    uploadUrl,
    uploaderOptions,
    hasUnuploadedFilesCallback,
    postUploadedAllCallback,
    handle,
}: IOutsidePopupRenderProps) => {
    // const options = useMemo(() => _.merge(defaultProps.uploaderOptions, props.uploaderOptions), [props]);

    // const [uploaderInstance, setUploaderInstance] = useState<dxFileUploader>();
    const uploaderRef = useRef<FileUploader>(null);
    // Hold styr på om der er blevet uploadet nogle filer overhovedet. Hvis ikke kan nmulige parents springe nogle ting over
    //          fx autogrid der refresher for at vise nye billeder
    const [anyUploads, setAnyUploads] = useState(false);

    //#region track om der er filer tilbage
    const [filesLeft, setFilesLeft] = useState(() => 0);

    // useEffect(() => {
    //     hasUnuploadedFilesCallback(filesLeft > 0);
    // }, [filesLeft])

    useArgsEffect(
        ([filesLeft], [hasUnuploadedFilesCallback]) => {
            hasUnuploadedFilesCallback(filesLeft > 0);
        },
        [filesLeft] as const,
        [hasUnuploadedFilesCallback] as const
    );

    const onValueChanged = (e: ValueChangedEvent) =>
        setFilesLeft((amount) => amount + (e.value?.length ?? 0) - (e.previousValue?.length ?? 0));
    const onUploaded = () => {
        setFilesLeft((amount) => amount - 1);
        setAnyUploads(true);
    };
    // Sikkerhed hvis ovenståendee misser noget
    const onFilesUploaded = () => setFilesLeft(0);
    //#endregion track om der er filer tilbage

    const flushUploads = (uploadAll = true) => {
        const instance = uploaderRef.current?.instance;
        if (uploadAll) instance?.upload();
        else {
            instance?.abortUpload();
            instance?.reset();
        }

        postUploadedAllCallback(anyUploads || filesLeft > 0);
    };

    useImperativeHandle<OutsidePopupRenderHandle, OutsidePopupRenderHandle>(handle, () => ({
        flushUploads,
    }));

    return (
        <ScrollView>
            <FileUploader
                ref={uploaderRef}
                multiple={true}
                uploadMode='instantly'
                uploadUrl={uploadUrl}
                uploadHeaders={{
                    Authorization: `Bearer ${Api.token}`,
                }}
                onValueChanged={onValueChanged}
                onUploaded={onUploaded}
                onFilesUploaded={onFilesUploaded}
                {...uploaderOptions}
            />
        </ScrollView>
    );
};

const GetOutsidePopupRender = (props: IOutsidePopupRenderProps) => {
    return (
        <>
            <OutsidePopupRender {...props} />
        </>
    );
};

const DragDropUploaderPopup = (DDUPProps: DragDropUploaderPopupProps) => {
    const props = _.merge(defaultProps, DDUPProps);

    const popupRef = useRef<Popup>(null);
    const handle = useRef<OutsidePopupRenderHandle>(null);

    const closeCallback = (anyUploads: boolean) => {
        console.log('closing popup');
        props.popupOptions?.overrideCloseFunction?.(anyUploads);
        popupRef.current?.instance?.hide();
    };

    const uploaderProps: IOutsidePopupRenderProps = {
        postUploadedAllCallback: closeCallback,
        uploadUrl: props.uploadUrl,
        uploaderOptions: props.uploaderOptions,
        hasUnuploadedFilesCallback: () => 'this should be removed at some point',
        handle,
    };

    const handleContentReady: Popup['props']['onContentReady'] = (e) => {
        props.popupOptions?.handleContentReady?.(e);
    };

    const onGemClicked = () => {
        handle.current?.flushUploads();
        props.popupOptions?.onSave?.();
    };

    const onAnnullerClicked = () => {
        handle.current?.flushUploads(false);
    };

    return (
        <Popup
            ref={popupRef}
            visible={props.popupOptions?.visible}
            hideOnOutsideClick={props.popupOptions?.hideOnOutsideClick}
            // title={props.popupOptions?.title}
            showTitle={props.popupOptions?.showTitle}
            dragEnabled={props.popupOptions?.dragEnable}
            width={props.popupOptions?.width}
            height={props.popupOptions?.height}
            onContentReady={handleContentReady}
            onHidden={onAnnullerClicked}
            contentRender={() => GetOutsidePopupRender(uploaderProps)}
            fullScreen={props.popupOptions?.fullScreen}
            // onHiding={props.popupOptions?.overrideCloseFunction}
        >
            <ToolbarItem
                widget='dxButton'
                toolbar='top'
                location='center'
                options={{
                    text: 'Gem',
                    onClick: onGemClicked,
                }}
            />
            {/* <ToolbarItem
                widget='dxButton'
                toolbar='top'
                location='center'
                options={{
                    text: 'Annullér',
                    onClick: onAnnullerClicked
                }}
            /> */}
        </Popup>
    );
};

export default DragDropUploaderPopup;
