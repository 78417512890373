
    const Svg2_05_02 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="317.4 201.0 191.5 191.51999999999998" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M503.9 296.76L503.7 302.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M503.7 302.76L503.1 308.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M503.1 308.73L502.11 314.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M502.11 314.65L500.74 320.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M500.74 320.49L498.98 326.23" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M498.98 326.23L496.84 331.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M496.84 331.84L494.34 337.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M494.34 337.3L491.48 342.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M491.48 342.58L488.28 347.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.28 347.66L484.76 352.51" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M484.76 352.51L480.91 357.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M480.91 357.12L476.78 361.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M476.78 361.47L472.36 365.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M472.36 365.54L467.68 369.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.68 369.3L462.77 372.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M462.77 372.75L457.64 375.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.64 375.86L452.31 378.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.31 378.63L446.82 381.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.82 381.05L441.17 383.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.17 383.09L435.41 384.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.41 384.75L429.54 386.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.54 386.03L423.61 386.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.61 386.92L417.62 387.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.62 387.42L411.62 387.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.62 387.52L405.63 387.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.63 387.22L399.66 386.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.66 386.53L393.76 385.44" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.76 385.44L387.94 383.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.94 383.97L382.23 382.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.23 382.11L376.66 379.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.66 379.88L371.25 377.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.25 377.29L366.01 374.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.01 374.35L360.99 371.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.99 371.07L356.19 367.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.19 367.46L351.64 363.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M351.64 363.54L347.37 359.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M347.37 359.33L343.37 354.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.37 354.85L339.69 350.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M339.69 350.11L336.32 345.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.32 345.14L333.29 339.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M333.29 339.96L330.61 334.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.61 334.59L328.29 329.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.29 329.05L326.34 323.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M326.34 323.38L324.77 317.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.77 317.58L323.59 311.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.59 311.7L322.8 305.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.8 305.75L322.4 299.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.4 299.76L322.4 293.76Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.4 293.76L322.8 287.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M322.8 287.77L323.59 281.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.59 281.82L324.77 275.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.77 275.93L326.34 270.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M326.34 270.14L328.29 264.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.29 264.46L330.61 258.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.61 258.93L333.29 253.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M333.29 253.56L336.32 248.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M336.32 248.37L339.69 243.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M339.69 243.4L343.37 238.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M343.37 238.67L347.37 234.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M347.37 234.18L351.64 229.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M351.64 229.97L356.19 226.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M356.19 226.06L360.99 222.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M360.99 222.45L366.01 219.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.01 219.17L371.25 216.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.25 216.22L376.66 213.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.66 213.63L382.23 211.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.23 211.4L387.94 209.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.94 209.55L393.76 208.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.76 208.07L399.66 206.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M399.66 206.99L405.63 206.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M405.63 206.3L411.62 206" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.62 206L417.62 206.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.62 206.1L423.61 206.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.61 206.59L429.54 207.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.54 207.48L435.41 208.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.41 208.76L441.17 210.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M441.17 210.43L446.82 212.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.82 212.47L452.31 214.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.31 214.88L457.64 217.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.64 217.65L462.77 220.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M462.77 220.77L467.68 224.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.68 224.21L472.36 227.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M472.36 227.98L476.78 232.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M476.78 232.04L480.91 236.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M480.91 236.39L484.76 241" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M484.76 241L488.28 245.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M488.28 245.86L491.48 250.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M491.48 250.94L494.34 256.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M494.34 256.22L496.84 261.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M496.84 261.67L498.98 267.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M498.98 267.28L500.74 273.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M500.74 273.02L502.11 278.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M502.11 278.87L503.1 284.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M503.1 284.79L503.7 290.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M503.7 290.76L503.9 296.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.94 232.57L477.31 360.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.94 360.94L477.31 232.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_05_02;

    