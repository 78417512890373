
    const Svg4_05_16 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="228.92 193.59 365.95000000000005 239.43999999999997" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M233.92 198.59L262.6 198.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M262.6 198.59L262.6 428.03Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M262.6 428.03L233.92 428.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M233.92 428.03L233.92 198.59Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M262.6 275.45L348.64 275.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M262.6 261.68L348.64 261.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.64 261.68L348.64 275.45Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.56 261.68L411.89 261.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.56 247.92L411.89 247.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.89 247.92L411.89 261.68Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M323.56 247.92L323.56 261.68Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M589.87 198.59L561.19 198.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M561.19 198.59L561.19 428.03Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M561.19 428.03L589.87 428.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M589.87 428.03L589.87 198.59Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M561.19 275.45L475.15 275.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M561.19 261.68L475.15 261.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M475.15 261.68L475.15 275.45Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M500.23 261.68L411.89 261.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M500.23 247.92L411.89 247.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.89 247.92L411.89 261.68Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M500.23 247.92L500.23 261.68Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg4_05_16;

    