
    const SvgXx_07_01 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="347.38 255.94 134.26 73.44" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M352.38 260.94L352.38 324.38Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M476.64 324.38L476.64 260.94Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.38 324.38L476.64 324.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M476.64 260.94L352.38 260.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.63 292.66L373.53 292.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M455.49 292.66L455.49 292.66 416.63 303.23 416.63 282.08 455.49 292.66Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
</svg>
    );
    export default SvgXx_07_01;

    