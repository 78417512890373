
    const Svg1_05_09 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="267.47 230.74 240.71999999999997 125.36000000000001" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M272.47 351.1L503.19 351.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M503.19 235.74L272.47 235.74" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M503.19 351.1L503.19 235.74Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.99 293.42L416.67 264.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.67 293.42L474.35 293.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M272.47 351.1L272.47 235.74Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M358.99 293.42L301.31 293.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.35 275.49L396.35 274.74 396.03 274.9 396.35 275.49Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M396.35 275.49L396.7 276.06 396.35 274.74 396.35 275.49Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M396.7 276.06L396.7 274.56 396.35 274.74 396.7 276.06Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M396.7 276.06L397.09 276.61 396.7 274.56 396.7 276.06Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M397.09 276.61L397.09 274.37 396.7 274.56 397.09 276.61Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M397.09 276.61L397.5 277.14 397.09 274.37 397.09 276.61Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M397.5 277.14L397.5 274.16 397.09 274.37 397.5 277.14Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M397.5 277.14L397.95 277.64 397.5 274.16 397.5 277.14Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M397.95 277.64L397.95 273.94 397.5 274.16 397.95 277.64Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M397.95 277.64L398.42 278.12 397.95 273.94 397.95 277.64Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M398.42 278.12L398.42 273.7 397.95 273.94 398.42 278.12Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M398.42 278.12L398.92 278.56 398.42 273.7 398.42 278.12Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M398.92 278.56L398.92 273.45 398.42 273.7 398.92 278.56Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M398.92 278.56L399.45 278.98 398.92 273.45 398.92 278.56Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M399.45 278.98L399.45 273.19 398.92 273.45 399.45 278.98Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M399.45 278.98L400 279.37 399.45 273.19 399.45 278.98Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M400 279.37L400 272.92 399.45 273.19 400 279.37Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M400 279.37L400.57 279.72 400 272.92 400 279.37Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M400.57 279.72L400.57 272.63 400 272.92 400.57 279.72Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M400.57 279.72L401.16 280.04 400.57 272.63 400.57 279.72Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M401.16 280.04L401.16 272.34 400.57 272.63 401.16 280.04Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M401.16 280.04L401.76 280.32 401.16 272.34 401.16 280.04Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M401.76 280.32L401.76 272.03 401.16 272.34 401.76 280.32Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M401.76 280.32L402.39 280.57 401.76 272.03 401.76 280.32Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M402.39 280.57L402.39 271.72 401.76 272.03 402.39 280.57Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M402.39 280.57L403.02 280.78 402.39 271.72 402.39 280.57Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M403.02 280.78L403.02 271.4 402.39 271.72 403.02 280.78Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M403.02 280.78L403.67 280.96 403.02 271.4 403.02 280.78Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M403.67 280.96L403.67 271.08 403.02 271.4 403.67 280.96Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M403.67 280.96L404.33 281.1 403.67 271.08 403.67 280.96Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M404.33 281.1L404.33 270.75 403.67 271.08 404.33 281.1Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M404.33 281.1L404.99 281.19 404.33 270.75 404.33 281.1Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M404.99 281.19L404.99 270.42 404.33 270.75 404.99 281.19Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M404.99 281.19L405.66 281.25 404.99 270.42 404.99 281.19Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M405.66 281.25L405.66 270.08 404.99 270.42 405.66 281.25Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M405.66 281.25L406.33 281.27 405.66 270.08 405.66 281.25Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M406.33 281.27L406.33 269.75 405.66 270.08 406.33 281.27Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M406.33 281.27L407 281.26 406.33 269.75 406.33 281.27Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M407 281.26L407 269.41 406.33 269.75 407 281.26Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M407 281.26L407.67 281.2 407 269.41 407 281.26Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M407.67 281.2L407.67 269.08 407 269.41 407.67 281.2Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M407.67 281.2L408.34 281.1 407.67 269.08 407.67 281.2Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M408.34 281.1L408.34 268.75 407.67 269.08 408.34 281.1Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M408.34 281.1L408.99 280.97 408.34 268.75 408.34 281.1Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M408.99 280.97L408.99 268.42 408.34 268.75 408.99 280.97Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M408.99 280.97L409.64 280.8 408.99 268.42 408.99 280.97Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M409.64 280.8L409.64 268.09 408.99 268.42 409.64 280.8Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M409.64 280.8L410.28 280.59 409.64 268.09 409.64 280.8Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M410.28 280.59L410.28 267.78 409.64 268.09 410.28 280.59Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M410.28 280.59L410.9 280.34 410.28 267.78 410.28 280.59Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M410.9 280.34L410.9 267.46 410.28 267.78 410.9 280.34Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M410.9 280.34L411.51 280.06 410.9 267.46 410.9 280.34Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M411.51 280.06L411.51 267.16 410.9 267.46 411.51 280.06Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M411.51 280.06L412.1 279.74 411.51 267.16 411.51 280.06Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M412.1 279.74L412.1 266.86 411.51 267.16 412.1 279.74Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M412.1 279.74L412.67 279.39 412.1 266.86 412.1 279.74Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M412.67 279.39L412.67 266.58 412.1 266.86 412.67 279.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M412.67 279.39L413.22 279 412.67 266.58 412.67 279.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M413.22 279L413.22 266.3 412.67 266.58 413.22 279Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M413.22 279L413.75 278.59 413.22 266.3 413.22 279Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M413.75 278.59L413.75 266.04 413.22 266.3 413.75 278.59Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M413.75 278.59L414.25 278.14 413.75 266.04 413.75 278.59Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M414.25 278.14L414.25 265.79 413.75 266.04 414.25 278.14Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M414.25 278.14L414.73 277.67 414.25 265.79 414.25 278.14Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M414.73 277.67L414.73 265.55 414.25 265.79 414.73 277.67Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M414.73 277.67L415.18 277.17 414.73 265.55 414.73 277.67Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M415.18 277.17L415.18 265.33 414.73 265.55 415.18 277.17Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M415.18 277.17L415.59 276.64 415.18 265.33 415.18 277.17Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M415.59 276.64L415.59 265.12 415.18 265.33 415.59 276.64Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M415.59 276.64L415.98 276.09 415.59 265.12 415.59 276.64Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M415.98 276.09L415.98 264.92 415.59 265.12 415.98 276.09Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M415.98 276.09L416.33 275.52 415.98 264.92 415.98 276.09Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M416.33 275.52L416.33 264.75 415.98 264.92 416.33 275.52Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M416.33 275.52L416.65 274.93 416.33 264.75 416.33 275.52Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M416.65 274.93L416.65 264.59 416.33 264.75 416.65 274.93Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M416.65 274.93L416.67 274.89 416.65 264.59 416.65 274.93Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M416.67 274.89L416.67 264.58 416.65 264.59 416.67 274.89Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M416.67 274.89L416.94 274.33 416.67 264.58 416.67 274.89Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M416.94 274.33L416.94 265.15 416.67 264.58 416.94 274.33Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M416.94 274.33L416.95 274.29 416.94 265.15 416.94 274.33Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M416.95 274.29L416.95 265.19 416.94 265.15 416.95 274.29Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M416.95 274.29L417.19 273.7 416.95 265.19 416.95 274.29Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.19 273.7L417.19 265.78 416.95 265.19 417.19 273.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.19 273.7L417.2 273.66 417.19 265.78 417.19 273.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.2 273.66L417.2 265.81 417.19 265.78 417.2 273.66Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.2 273.66L417.4 273.07 417.2 265.81 417.2 273.66Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.4 273.07L417.4 266.41 417.2 265.81 417.4 273.07Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.4 273.07L417.41 273.02 417.4 266.41 417.4 273.07Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.41 273.02L417.41 266.45 417.4 266.41 417.41 273.02Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.41 273.02L417.57 272.42 417.41 266.45 417.41 273.02Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.57 272.42L417.57 267.06 417.41 266.45 417.57 272.42Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.57 272.42L417.58 272.37 417.57 267.06 417.57 272.42Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.58 272.37L417.58 267.1 417.57 267.06 417.58 272.37Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.58 272.37L417.71 271.76 417.58 267.1 417.58 272.37Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.71 271.76L417.71 267.72 417.58 267.1 417.71 271.76Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.71 271.76L417.72 271.71 417.71 267.72 417.71 271.76Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.72 271.71L417.72 267.76 417.71 267.72 417.72 271.71Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.72 271.71L417.81 271.1 417.72 267.76 417.72 271.71Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.81 271.1L417.81 268.39 417.72 267.76 417.81 271.1Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.81 271.1L417.81 271.04 417.81 268.39 417.81 271.1Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.81 271.04L417.81 268.42 417.81 268.39 417.81 271.04Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.81 271.04L417.87 270.43 417.81 268.42 417.81 271.04Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.87 270.43L417.87 269.06 417.81 268.42 417.87 270.43Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.87 270.43L417.87 270.35 417.87 269.06 417.87 270.43Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.87 270.35L417.87 269.09 417.87 269.06 417.87 270.35Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M417.87 270.35L417.89 269.76 417.87 269.09 417.87 270.35Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M396.03 274.9L397.16 276.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.16 276.71L398.59 278.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.59 278.28L400.3 279.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M400.3 279.56L402.2 280.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.2 280.5L404.25 281.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.25 281.08L406.37 281.27" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.37 281.27L408.49 281.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.49 281.07L410.54 280.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.54 280.49L412.44 279.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.44 279.54L414.14 278.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.14 278.25L415.57 276.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.57 276.68L416.69 274.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.69 274.86L417.45 272.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.45 272.88L417.84 270.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.84 270.78L417.84 268.65Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.84 268.65L417.44 266.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M417.44 266.56L416.67 264.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M416.67 264.58L396.03 274.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_05_09;

    