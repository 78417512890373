
    const Svg2_05_03 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="309.93 263.62 192.01999999999998 101.00999999999999" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M314.93 268.62L314.93 359.63Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M314.93 359.63L496.95 359.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M496.95 359.63L496.95 268.62Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M496.95 268.62L314.93 268.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.7 279.99L321.57 348.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M321.57 348.25L389.83 348.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.83 348.25L355.7 279.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.26 300.55L432.01 300.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.01 300.55L432.01 277.8Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.01 277.8L454.76 277.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.76 277.8L454.76 300.55Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.76 300.55L477.52 300.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.14 329.92L465.95 332.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M465.95 332.89L465.36 335.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M465.36 335.81L464.41 338.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.41 338.63L463.09 341.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M463.09 341.3L461.44 343.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.44 343.77L459.48 346.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.48 346.01L457.24 347.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.24 347.97L454.76 349.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.76 349.62L452.09 350.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.09 350.94L449.28 351.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.28 351.9L446.36 352.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.36 352.48L443.39 352.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.39 352.67L440.42 352.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.42 352.48L437.5 351.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.5 351.9L434.68 350.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.68 350.94L432.01 349.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.01 349.62L429.54 347.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.54 347.97L427.3 346.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.3 346.01L425.34 343.77" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.34 343.77L423.68 341.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.68 341.3L422.37 338.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.37 338.63L421.41 335.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.41 335.81L420.83 332.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.83 332.89L420.63 329.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.63 329.92L420.83 326.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M420.83 326.95L421.41 324.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M421.41 324.03L422.37 321.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.37 321.21L423.68 318.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M423.68 318.54L425.34 316.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.34 316.07L427.3 313.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.3 313.83L429.54 311.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.54 311.87L432.01 310.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M432.01 310.21L434.68 308.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M434.68 308.9L437.5 307.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.5 307.94L440.42 307.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.42 307.36L443.39 307.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M443.39 307.17L446.36 307.36" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.36 307.36L449.28 307.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M449.28 307.94L452.09 308.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M452.09 308.9L454.76 310.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.76 310.21L457.24 311.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.24 311.87L459.48 313.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M459.48 313.83L461.44 316.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M461.44 316.07L463.09 318.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M463.09 318.54L464.41 321.21" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.41 321.21L465.36 324.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M465.36 324.03L465.95 326.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M465.95 326.95L466.14 329.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.3 346.01L459.48 313.83" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M427.3 313.83L459.48 346.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_05_03;

    