
    const Svg1_05_06 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="289.95 256.17 291.06 103.68" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M576.01 354.85L482.32 261.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M482.32 354.85L576.01 354.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 388.63 308.01 A 24.007 24.007 0 0 0 341.79 308.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M482.32 261.17L294.95 261.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M294.95 261.17L294.95 354.85Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M294.95 354.85L482.32 354.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M482.32 354.85L482.32 261.17Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 435.48 308.01 A 24.007 24.007 0 0 0 388.63 308.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_05_06;

    