
    const Svg2_02_03 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="333.76 252.29000000000002 112.12 112.12" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M338.76 359.41L440.88 359.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.88 359.41L440.88 257.29Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.88 257.29L338.76 257.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M338.76 257.29L338.76 359.41Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.57 308.86L363.61 309.89 363.57 307.84 363.57 308.86Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M363.61 309.89L363.61 306.82 363.57 307.84 363.61 309.89Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M363.61 309.89L363.69 310.91 363.61 306.82 363.61 309.89Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M363.69 310.91L363.69 305.79 363.61 306.82 363.69 310.91Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M363.69 310.91L363.8 311.93 363.69 305.79 363.69 310.91Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M363.8 311.93L363.8 304.78 363.69 305.79 363.8 311.93Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M363.8 311.93L363.96 312.94 363.8 304.78 363.8 311.93Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M363.96 312.94L363.96 303.76 363.8 304.78 363.96 312.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M363.96 312.94L364.16 313.95 363.96 303.76 363.96 312.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M364.16 313.95L364.16 302.76 363.96 303.76 364.16 313.95Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M364.16 313.95L364.4 314.94 364.16 302.76 364.16 313.95Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M364.4 314.94L364.4 301.76 364.16 302.76 364.4 314.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M364.4 314.94L364.68 315.93 364.4 301.76 364.4 314.94Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M364.68 315.93L364.68 300.77 364.4 301.76 364.68 315.93Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M364.68 315.93L364.99 316.9 364.68 300.77 364.68 315.93Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M364.99 316.9L364.99 299.8 364.68 300.77 364.99 316.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M364.99 316.9L365.34 317.87 364.99 299.8 364.99 316.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M365.34 317.87L365.34 298.84 364.99 299.8 365.34 317.87Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M365.34 317.87L365.73 318.81 365.34 298.84 365.34 317.87Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M365.73 318.81L365.73 297.89 365.34 298.84 365.73 318.81Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M365.73 318.81L366.16 319.75 365.73 297.89 365.73 318.81Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M366.16 319.75L366.16 296.96 365.73 297.89 366.16 319.75Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M366.16 319.75L366.62 320.66 366.16 296.96 366.16 319.75Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M366.62 320.66L366.62 296.04 366.16 296.96 366.62 320.66Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M366.62 320.66L367.12 321.56 366.62 296.04 366.62 320.66Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M367.12 321.56L367.12 295.15 366.62 296.04 367.12 321.56Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M367.12 321.56L367.65 322.43 367.12 295.15 367.12 321.56Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M367.65 322.43L367.65 294.27 367.12 295.15 367.65 322.43Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M367.65 322.43L368.22 323.29 367.65 294.27 367.65 322.43Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M368.22 323.29L368.22 293.42 367.65 294.27 368.22 323.29Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M368.22 323.29L368.82 324.12 368.22 293.42 368.22 323.29Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M368.82 324.12L368.82 292.59 368.22 293.42 368.82 324.12Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M368.82 324.12L369.45 324.92 368.82 292.59 368.82 324.12Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M369.45 324.92L369.45 291.78 368.82 292.59 369.45 324.92Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M369.45 324.92L370.11 325.71 369.45 291.78 369.45 324.92Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M370.11 325.71L370.11 291 369.45 291.78 370.11 325.71Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M370.11 325.71L370.8 326.46 370.11 291 370.11 325.71Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M370.8 326.46L370.8 290.24 370.11 291 370.8 326.46Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M370.8 326.46L371.53 327.19 370.8 290.24 370.8 326.46Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M371.53 327.19L371.53 289.51 370.8 290.24 371.53 327.19Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M371.53 327.19L372.27 327.89 371.53 289.51 371.53 327.19Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M372.27 327.89L372.27 288.81 371.53 289.51 372.27 327.89Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M372.27 327.89L373.05 328.56 372.27 288.81 372.27 327.89Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M373.05 328.56L373.05 288.14 372.27 288.81 373.05 328.56Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M373.05 328.56L373.85 329.2 373.05 288.14 373.05 328.56Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M373.85 329.2L373.85 287.51 373.05 288.14 373.85 329.2Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M373.85 329.2L374.68 329.81 373.85 287.51 373.85 329.2Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M374.68 329.81L374.68 286.9 373.85 287.51 374.68 329.81Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M374.68 329.81L375.53 330.38 374.68 286.9 374.68 329.81Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M375.53 330.38L375.53 286.32 374.68 286.9 375.53 330.38Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M375.53 330.38L376.4 330.92 375.53 286.32 375.53 330.38Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M376.4 330.92L376.4 285.78 375.53 286.32 376.4 330.92Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M376.4 330.92L377.29 331.43 376.4 285.78 376.4 330.92Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M377.29 331.43L377.29 285.28 376.4 285.78 377.29 331.43Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M377.29 331.43L378.2 331.9 377.29 285.28 377.29 331.43Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M378.2 331.9L378.2 284.8 377.29 285.28 378.2 331.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M378.2 331.9L379.12 332.34 378.2 284.8 378.2 331.9Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M379.12 332.34L379.12 284.37 378.2 284.8 379.12 332.34Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M379.12 332.34L380.07 332.73 379.12 284.37 379.12 332.34Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M380.07 332.73L380.07 283.97 379.12 284.37 380.07 332.73Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M380.07 332.73L381.03 333.1 380.07 283.97 380.07 332.73Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M381.03 333.1L381.03 283.61 380.07 283.97 381.03 333.1Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M381.03 333.1L382 333.42 381.03 283.61 381.03 333.1Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M382 333.42L382 283.28 381.03 283.61 382 333.42Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M382 333.42L382.98 333.71 382 283.28 382 333.42Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M382.98 333.71L382.98 283 382 283.28 382.98 333.71Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M382.98 333.71L383.98 333.95 382.98 283 382.98 333.71Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M383.98 333.95L383.98 282.75 382.98 283 383.98 333.95Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M383.98 333.95L384.98 334.16 383.98 282.75 383.98 333.95Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M384.98 334.16L384.98 282.54 383.98 282.75 384.98 334.16Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M384.98 334.16L385.99 334.33 384.98 282.54 384.98 334.16Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M385.99 334.33L385.99 282.37 384.98 282.54 385.99 334.33Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M385.99 334.33L387.01 334.46 385.99 282.37 385.99 334.33Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M387.01 334.46L387.01 282.24 385.99 282.37 387.01 334.46Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M387.01 334.46L388.03 334.55 387.01 282.24 387.01 334.46Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M388.03 334.55L388.03 282.15 387.01 282.24 388.03 334.55Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M388.03 334.55L389.05 334.6 388.03 282.15 388.03 334.55Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M389.05 334.6L389.05 282.1 388.03 282.15 389.05 334.6Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M389.05 334.6L390.08 334.61 389.05 282.1 389.05 334.6Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M390.08 334.61L390.08 282.09 389.05 282.1 390.08 334.61Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M390.08 334.61L391.1 334.58 390.08 282.09 390.08 334.61Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M391.1 334.58L391.1 282.12 390.08 282.09 391.1 334.58Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M391.1 334.58L392.12 334.51 391.1 282.12 391.1 334.58Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M392.12 334.51L392.12 282.19 391.1 282.12 392.12 334.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M392.12 334.51L393.14 334.4 392.12 282.19 392.12 334.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.14 334.4L393.14 282.3 392.12 282.19 393.14 334.4Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M393.14 334.4L394.16 334.25 393.14 282.3 393.14 334.4Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M394.16 334.25L394.16 282.45 393.14 282.3 394.16 334.25Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M394.16 334.25L395.16 334.06 394.16 282.45 394.16 334.25Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M395.16 334.06L395.16 282.64 394.16 282.45 395.16 334.06Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M395.16 334.06L396.16 333.84 395.16 282.64 395.16 334.06Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M396.16 333.84L396.16 282.87 395.16 282.64 396.16 333.84Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M396.16 333.84L397.15 333.57 396.16 282.87 396.16 333.84Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M397.15 333.57L397.15 283.14 396.16 282.87 397.15 333.57Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M397.15 333.57L398.13 333.26 397.15 283.14 397.15 333.57Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M398.13 333.26L398.13 283.44 397.15 283.14 398.13 333.26Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M398.13 333.26L399.1 332.92 398.13 283.44 398.13 333.26Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M399.1 332.92L399.1 283.78 398.13 283.44 399.1 332.92Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M399.1 332.92L400.05 332.54 399.1 283.78 399.1 332.92Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M400.05 332.54L400.05 284.16 399.1 283.78 400.05 332.54Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M400.05 332.54L400.98 332.12 400.05 284.16 400.05 332.54Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M400.98 332.12L400.98 284.58 400.05 284.16 400.98 332.12Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M400.98 332.12L401.9 331.67 400.98 284.58 400.98 332.12Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M401.9 331.67L401.9 285.04 400.98 284.58 401.9 331.67Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M401.9 331.67L402.8 331.18 401.9 285.04 401.9 331.67Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M402.8 331.18L402.8 285.52 401.9 285.04 402.8 331.18Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M402.8 331.18L403.68 330.66 402.8 285.52 402.8 331.18Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M403.68 330.66L403.68 286.05 402.8 285.52 403.68 330.66Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M403.68 330.66L404.54 330.1 403.68 286.05 403.68 330.66Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M404.54 330.1L404.54 286.61 403.68 286.05 404.54 330.1Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M404.54 330.1L405.38 329.51 404.54 286.61 404.54 330.1Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M405.38 329.51L405.38 287.2 404.54 286.61 405.38 329.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M405.38 329.51L406.19 328.88 405.38 287.2 405.38 329.51Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M406.19 328.88L406.19 287.82 405.38 287.2 406.19 328.88Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M406.19 328.88L406.98 328.23 406.19 287.82 406.19 328.88Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M406.98 328.23L406.98 288.48 406.19 287.82 406.98 328.23Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M406.98 328.23L407.75 327.54 406.98 288.48 406.98 328.23Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M407.75 327.54L407.75 289.16 406.98 288.48 407.75 327.54Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M407.75 327.54L408.48 326.83 407.75 289.16 407.75 327.54Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M408.48 326.83L408.48 289.87 407.75 289.16 408.48 326.83Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M408.48 326.83L409.19 326.09 408.48 289.87 408.48 326.83Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M409.19 326.09L409.19 290.62 408.48 289.87 409.19 326.09Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M409.19 326.09L409.86 325.32 409.19 290.62 409.19 326.09Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M409.86 325.32L409.86 291.38 409.19 290.62 409.86 325.32Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M409.86 325.32L410.51 324.52 409.86 291.38 409.86 325.32Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M410.51 324.52L410.51 292.18 409.86 291.38 410.51 324.52Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M410.51 324.52L411.13 323.7 410.51 292.18 410.51 324.52Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M411.13 323.7L411.13 293 410.51 292.18 411.13 323.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M411.13 323.7L411.71 322.86 411.13 293 411.13 323.7Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M411.71 322.86L411.71 293.84 411.13 293 411.71 322.86Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M411.71 322.86L412.26 322 411.71 293.84 411.71 322.86Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M412.26 322L412.26 294.71 411.71 293.84 412.26 322Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M412.26 322L412.77 321.11 412.26 294.71 412.26 322Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M412.77 321.11L412.77 295.59 412.26 294.71 412.77 321.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M412.77 321.11L413.25 320.21 412.77 295.59 412.77 321.11Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M413.25 320.21L413.25 296.5 412.77 295.59 413.25 320.21Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M413.25 320.21L413.7 319.28 413.25 296.5 413.25 320.21Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M413.7 319.28L413.7 297.42 413.25 296.5 413.7 319.28Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M413.7 319.28L414.11 318.34 413.7 297.42 413.7 319.28Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M414.11 318.34L414.11 298.36 413.7 297.42 414.11 318.34Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M414.11 318.34L414.48 317.39 414.11 298.36 414.11 318.34Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M414.48 317.39L414.48 299.32 414.11 298.36 414.48 317.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M414.48 317.39L414.81 316.42 414.48 299.32 414.48 317.39Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M414.81 316.42L414.81 300.29 414.48 299.32 414.81 316.42Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M414.81 316.42L415.11 315.44 414.81 300.29 414.81 316.42Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M415.11 315.44L415.11 301.27 414.81 300.29 415.11 315.44Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M415.11 315.44L415.37 314.45 415.11 301.27 415.11 315.44Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M415.37 314.45L415.37 302.26 415.11 301.27 415.37 314.45Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M415.37 314.45L415.58 313.44 415.37 302.26 415.37 314.45Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M415.58 313.44L415.58 303.26 415.37 302.26 415.58 313.44Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M415.58 313.44L415.76 312.43 415.58 303.26 415.58 313.44Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M415.76 312.43L415.76 304.27 415.58 303.26 415.76 312.43Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M415.76 312.43L415.9 311.42 415.76 304.27 415.76 312.43Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M415.9 311.42L415.9 305.28 415.76 304.27 415.9 311.42Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M415.9 311.42L416 310.4 415.9 305.28 415.9 311.42Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M416 310.4L416 306.3 415.9 305.28 416 310.4Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M416 310.4L416.06 309.38 416 306.3 416 310.4Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M416.06 309.38L416.06 307.33 416 306.3 416.06 309.38Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M416.06 309.38L416.08 308.35 416.06 307.33 416.06 309.38Z" fill='{{replacement-value-color}}' stroke='{{replacement-value-color}}' strokeWidth="0.2" />
<path d="M416.08 308.35L415.88 311.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.88 311.58L415.29 314.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.29 314.76L414.31 317.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.31 317.84L412.96 320.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.96 320.78L411.25 323.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.25 323.52L409.23 326.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.23 326.04L406.91 328.29" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.91 328.29L404.33 330.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.33 330.24L401.53 331.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.53 331.86L398.55 333.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.55 333.12L395.44 334" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.44 334L392.24 334.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.24 334.5L389.01 334.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.01 334.6L385.79 334.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M385.79 334.3L382.63 333.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.63 333.61L379.58 332.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.58 332.54L376.69 331.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.69 331.09L374 329.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374 329.31L371.54 327.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.54 327.2L369.36 324.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.36 324.82L367.49 322.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.49 322.18L365.96 319.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.96 319.33L364.8 316.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.8 316.31L364.01 313.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.01 313.18L363.61 309.97" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.61 309.97L363.61 306.74Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.61 306.74L364.01 303.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.01 303.53L364.8 300.39" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M364.8 300.39L365.96 297.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M365.96 297.38L367.49 294.53" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M367.49 294.53L369.36 291.89" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.36 291.89L371.54 289.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.54 289.5L374 287.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M374 287.4L376.69 285.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.69 285.61L379.58 284.17" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.58 284.17L382.63 283.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.63 283.09L385.79 282.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M385.79 282.4L389.01 282.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M389.01 282.1L392.24 282.2" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.24 282.2L395.44 282.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M395.44 282.7L398.55 283.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.55 283.58L401.53 284.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M401.53 284.84L404.33 286.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M404.33 286.46L406.91 288.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.91 288.41L409.23 290.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M409.23 290.66L411.25 293.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.25 293.18L412.96 295.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M412.96 295.93L414.31 298.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.31 298.87L415.29 301.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.29 301.95L415.88 305.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M415.88 305.12L416.08 308.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_02_03;

    