
    const Svg2_06_01 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="333.85 234.55 112.00999999999999 112.02999999999997" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M440.86 290.56L440.66 295.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.66 295.07L440.07 299.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.07 299.55L439.08 303.95" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.08 303.95L437.7 308.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.7 308.25L435.95 312.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.95 312.42L433.84 316.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.84 316.41L431.38 320.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.38 320.19L428.6 323.75" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.6 323.75L425.52 327.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.52 327.05L422.15 330.06" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.15 330.06L418.54 332.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.54 332.76L414.69 335.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.69 335.13L410.66 337.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.66 337.15L406.46 338.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.46 338.81L402.13 340.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.13 340.09L397.7 340.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.7 340.98L393.22 341.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.22 341.48L388.7 341.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.7 341.58L384.2 341.28" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.2 341.28L379.74 340.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.74 340.59L375.36 339.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.36 339.5L371.09 338.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.09 338.03L366.97 336.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.97 336.19L363.03 333.99" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.03 333.99L359.29 331.45" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.29 331.45L355.8 328.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.8 328.59L352.57 325.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.57 325.43L349.64 322" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.64 322L347.01 318.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M347.01 318.33L344.73 314.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M344.73 314.43L342.8 310.35" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M342.8 310.35L341.23 306.12" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.23 306.12L340.05 301.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.05 301.76L339.25 297.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M339.25 297.32L338.85 292.82" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M338.85 292.82L338.85 288.31Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M338.85 288.31L339.25 283.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M339.25 283.81L340.05 279.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M340.05 279.37L341.23 275.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.23 275.01L342.8 270.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M342.8 270.78L344.73 266.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M344.73 266.7L347.01 262.8" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M347.01 262.8L349.64 259.13" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M349.64 259.13L352.57 255.7" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M352.57 255.7L355.8 252.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M355.8 252.54L359.29 249.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M359.29 249.68L363.03 247.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M363.03 247.14L366.97 244.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.97 244.94L371.09 243.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.09 243.1L375.36 241.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M375.36 241.63L379.74 240.54" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M379.74 240.54L384.2 239.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M384.2 239.85L388.7 239.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M388.7 239.55L393.22 239.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M393.22 239.65L397.7 240.14" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M397.7 240.14L402.13 241.04" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M402.13 241.04L406.46 242.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M406.46 242.32L410.66 243.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M410.66 243.98L414.69 246" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.69 246L418.54 248.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M418.54 248.37L422.15 251.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M422.15 251.07L425.52 254.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M425.52 254.08L428.6 257.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M428.6 257.38L431.38 260.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M431.38 260.94L433.84 264.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.84 264.72L435.95 268.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M435.95 268.71L437.7 272.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M437.7 272.88L439.08 277.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M439.08 277.18L440.07 281.58" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.07 281.58L440.66 286.05" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.66 286.05L440.86 290.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.24 310.98L369.24 270.15Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M396.46 270.15L396.46 310.98Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M369.24 289.59L396.46 289.59" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_06_01;

    