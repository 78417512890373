import { IAutoFormHandle, IGrupper } from '../auto-form/v1/interfaces-and-defaults';
import { RefObject, forwardRef, useImperativeHandle, useRef } from 'react';

import AutoForm from '../auto-form/v1';
import LoadingScreen from '../../screens/loading';
import _ from 'lodash';
import useFetch from '../../shared/hooks/fetch-hooks/use-fetch';

interface IAutoGridEgenskaber {
    url: string;
    idRef: RefObject<string>;
}

export interface IEgenskaberRef {
    egenskaberRef: RefObject<IAutoFormHandle>;
    data: IGrupper[];
    refetch: () => Promise<void>;
    url: string;
}

const AutoGridEgenskaber = forwardRef<IEgenskaberRef, IAutoGridEgenskaber>(({ url, idRef }, ref) => {
    const [data, isLoading, , , , refetch] = useFetch<IGrupper[]>(url + idRef.current);
    const egenskaberRef = useRef<IAutoFormHandle>(null);
    const colCount = 12;

    useImperativeHandle(ref, () => ({
        egenskaberRef: egenskaberRef,
        data: data,
        refetch: refetch,
        url: url,
    }));

    if (isLoading) {
        return <LoadingScreen />;
    }
    return (
        // <Box className='form-egenskaber' pt='20px' onBlur={() => { }}>
        <AutoForm
            grupper={_.cloneDeep(data)}
            onSubmit={() => undefined}
            onCancel={() => undefined}
            submitPosition='none'
            colCount={colCount}
            ref={egenskaberRef}
            useDxForm={true}
        />
        // </Box>
    );
});

export default AutoGridEgenskaber;
