
    const Svg2_03_05 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="337.59 198.55 147.68 205.72999999999996" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M480.27 322.79L342.59 322.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M342.59 322.79L411.43 203.55" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M411.43 203.55L480.27 322.79" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.03 322.79L442.03 399.28Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M380.84 322.79L380.84 399.28Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg2_03_05;

    