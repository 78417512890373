
    const Svg1_05_11 = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="319.25 215.01 155.27999999999997 155.31" version="1.1">
  <desc>
Produce by Acme CAD Converter</desc>
<path d="M469.53 292.67L469.33 287.3" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M469.33 287.3L468.74 281.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M468.74 281.96L467.75 276.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.75 276.68L466.38 271.49" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.38 271.49L464.62 266.42" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.62 266.42L462.5 261.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M462.5 261.48L460.02 256.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.02 256.72L457.19 252.16" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.19 252.16L454.03 247.81" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.03 247.81L450.57 243.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.57 243.71L446.8 239.88" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.8 239.88L442.77 236.34" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.77 236.34L438.48 233.1" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438.48 233.1L433.97 230.19" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.97 230.19L429.25 227.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.25 227.62L424.36 225.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.36 225.4L419.32 223.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.32 223.56L414.15 222.09" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.15 222.09L408.89 221" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.89 221L403.57 220.31" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.57 220.31L398.21 220.01" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.21 220.01L392.84 220.11" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.84 220.11L387.49 220.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.49 220.61L382.19 221.5" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.19 221.5L376.98 222.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.98 222.78L371.87 224.43" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.87 224.43L366.9 226.47" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.9 226.47L362.09 228.86" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.09 228.86L357.47 231.6" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M357.47 231.6L353.07 234.68" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.07 234.68L348.91 238.07" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.91 238.07L345.01 241.76" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.01 241.76L341.39 245.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.39 245.73L338.08 249.96" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M338.08 249.96L335.08 254.41" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M335.08 254.41L332.42 259.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M332.42 259.08L330.12 263.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.12 263.93L328.18 268.94" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.18 268.94L326.62 274.08" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M326.62 274.08L325.43 279.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M325.43 279.32L324.64 284.63" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.64 284.63L324.25 289.98" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.25 289.98L324.25 295.35Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.25 295.35L324.64 300.71" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M324.64 300.71L325.43 306.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M325.43 306.02L326.62 311.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M326.62 311.26L328.18 316.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M328.18 316.4L330.12 321.4" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M330.12 321.4L332.42 326.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M332.42 326.25L335.08 330.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M335.08 330.92L338.08 335.38" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M338.08 335.38L341.39 339.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M341.39 339.61L345.01 343.57" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M345.01 343.57L348.91 347.26" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M348.91 347.26L353.07 350.66" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.07 350.66L357.47 353.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M357.47 353.73L362.09 356.48" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M362.09 356.48L366.9 358.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M366.9 358.87L371.87 360.9" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M371.87 360.9L376.98 362.56" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M376.98 362.56L382.19 363.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M382.19 363.84L387.49 364.73" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M387.49 364.73L392.84 365.22" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M392.84 365.22L398.21 365.32" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M398.21 365.32L403.57 365.02" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M403.57 365.02L408.89 364.33" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M408.89 364.33L414.15 363.25" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M414.15 363.25L419.32 361.78" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M419.32 361.78L424.36 359.93" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M424.36 359.93L429.25 357.72" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M429.25 357.72L433.97 355.15" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M433.97 355.15L438.48 352.24" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M438.48 352.24L442.77 349" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M442.77 349L446.8 345.46" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M446.8 345.46L450.57 341.62" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M450.57 341.62L454.03 337.52" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M454.03 337.52L457.19 333.18" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M457.19 333.18L460.02 328.61" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M460.02 328.61L462.5 323.85" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M462.5 323.85L464.62 318.92" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M464.62 318.92L466.38 313.84" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M466.38 313.84L467.75 308.65" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M467.75 308.65L468.74 303.37" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M468.74 303.37L469.33 298.03" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M469.33 298.03L469.53 292.67" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 411.4 270.87 A 14.533 14.533 0 0 0 382.33 270.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 440.46 270.87 A 14.533 14.533 0 0 0 411.4 270.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M 382.33 270.87 A 14.533 14.533 0 0 0 353.26 270.87" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M353.26 270.87L353.26 329Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
<path d="M440.46 270.87L440.46 329Z" fill="none" stroke='{{replacement-value-color}}' strokeWidth="5" />
</svg>
    );
    export default Svg1_05_11;

    