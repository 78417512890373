import {
    SvgADKcentral,
    SvgADKundercentral,
    SvgAabnesignaldoer,
    SvgAbdl,
    SvgAbv,
    SvgArrowRepeat,
    SvgBrandB,
    SvgCircle,
    SvgCursor,
    SvgCursorFill,
    SvgElSikker,
    SvgEltavle,
    SvgEltavleE,
    SvgEltavleS,
    SvgEraser,
    SvgEraserFill,
    SvgFlugtvejsbelysning,
    SvgFlugtvejsskilt,
    SvgKlmedtastatur,
    SvgKludentastatur,
    SvgLaas,
    SvgLineString,
    SvgLocationMarkerBlue,
    SvgLocationMarkerChartreuse,
    SvgLocationMarkerGray,
    SvgLocationMarkerGreen,
    SvgLocationMarkerOrange,
    SvgLocationMarkerRed,
    SvgLocationMarkerYellow,
    SvgMyLocation,
    SvgNglskab,
    SvgOvenlys,
    SvgPencil,
    SvgPoint,
    SvgPolygon,
    SvgRogalarm,
    SvgRogalarmAva,
    SvgSearch,
    SvgSirene,
    SvgSkydedoer,
    SvgSquatMarker,
    SvgUdlosertryk,
    SvgUdtryk,
    SvgVarslingstryk,
    SvgVentilation,
    Svg1_01_01,
    Svg1_01_03,
    Svg1_01_05,
    Svg1_01_06,
    Svg1_01_07,
    Svg1_01_08,
    Svg1_01_09,
    Svg1_01_10,
    Svg1_01_11,
    Svg1_01_12,
    Svg1_01_13,
    Svg1_01_14,
    Svg1_01_15,
    Svg1_01_17,
    Svg1_01_18,
    Svg1_01_19,
    Svg1_01_20,
    Svg1_01_21,
    Svg1_01_22,
    Svg1_01_23,
    Svg1_01_24,
    Svg1_01_25,
    Svg1_01_26,
    Svg1_01_27,
    Svg1_01_28,
    Svg1_01_29,
    Svg1_01_30,
    Svg1_01_31,
    Svg1_01_32,
    Svg1_01_33,
    Svg1_01_34,
    Svg1_01_35,
    Svg1_01_36,
    Svg1_02_01,
    Svg1_02_02,
    Svg1_03_01,
    Svg1_04_02,
    Svg1_04_03,
    Svg1_05_01,
    Svg1_05_02,
    Svg1_05_03,
    Svg1_05_04A,
    Svg1_05_04B,
    Svg1_05_04C,
    Svg1_05_04D,
    Svg1_05_04,
    Svg1_05_05,
    Svg1_05_06,
    Svg1_05_07,
    Svg1_05_08,
    Svg1_05_09,
    Svg1_05_10,
    Svg1_05_11,
    Svg1_05_12,
    Svg1_05_13,
    Svg1_05_14,
    Svg1_05_15,
    Svg1_05_16,
    Svg1_06_01,
    Svg1_07_01,
    Svg1_07_02,
    Svg1_07_03,
    Svg1_07_04,
    Svg1_08_01,
    Svg1_08_11,
    Svg2_01_01,
    Svg2_01_02,
    Svg2_01_03,
    Svg2_01_04A,
    Svg2_01_04,
    Svg2_01_05,
    Svg2_01_06,
    Svg2_01_07,
    Svg2_01_08,
    Svg2_01_09,
    Svg2_01_10,
    Svg2_01_11,
    Svg2_01_12,
    Svg2_01_13,
    Svg2_01_14,
    Svg2_01_15,
    Svg2_02_01,
    Svg2_02_02,
    Svg2_02_03,
    Svg2_02_04,
    Svg2_02_05,
    Svg2_03_01,
    Svg2_03_02,
    Svg2_03_03,
    Svg2_03_04,
    Svg2_03_05,
    Svg2_03_06,
    Svg2_03_07,
    Svg2_04_01,
    Svg2_04_02,
    Svg2_04_03,
    Svg2_04_04,
    Svg2_04_05,
    Svg2_04_06,
    Svg2_04_07,
    Svg2_04_08,
    Svg2_04_09,
    Svg2_04_10,
    Svg2_05_01,
    Svg2_05_02,
    Svg2_05_03,
    Svg2_05_04,
    Svg2_05_05,
    Svg2_05_06,
    Svg2_05_07,
    Svg2_06_01,
    Svg2_06_02,
    Svg2_06_03,
    Svg2_06_04,
    Svg2_06_05,
    Svg2_06_06,
    Svg2_06_08,
    Svg2_06_09,
    Svg2_07_01,
    Svg2_07_02,
    Svg2_07_03,
    Svg2_07_04,
    Svg3_01_01,
    Svg3_01_02,
    Svg3_01_03,
    Svg3_01_04,
    Svg3_01_05,
    Svg3_01_06,
    Svg3_01_07,
    Svg3_04_01,
    Svg3_07_01,
    Svg3_07_02,
    Svg3_07_03,
    Svg3_07_04,
    Svg3_07_05,
    Svg3_07_06,
    Svg3_07_07,
    Svg3_07_08,
    Svg3_08_01,
    Svg4_05_01,
    Svg4_05_02,
    Svg4_05_03,
    Svg4_05_04,
    Svg4_05_05,
    Svg4_05_06,
    Svg4_05_07,
    Svg4_05_08,
    Svg4_05_09,
    Svg4_05_10,
    Svg4_05_11,
    Svg4_05_12,
    Svg4_05_13,
    Svg4_05_14,
    Svg4_05_15,
    Svg4_05_16,
    Svg4_05_17,
    Svg4_05_18,
    Svg4_05_19,
    Svg4_05_20,
    SvgXx_06_01,
    SvgXx_06_02,
    SvgXx_06_03,
    SvgXx_06_04,
    SvgXx_06_05,
    SvgXx_06_06,
    SvgXx_06_07,
    SvgXx_06_08,
    SvgXx_06_09,
    SvgXx_06_11,
    SvgXx_06_12,
    SvgXx_06_13,
    SvgXx_06_14,
    SvgXx_06_15,
    SvgXx_06_16,
    SvgXx_06_17,
    SvgXx_06_18,
    SvgXx_06_19,
    SvgXx_06_20,
    SvgXx_06_21,
    SvgXx_07_01,
    SvgXx_07_02,
    SvgXx_07_03,
    SvgXx_07_04,
    SvgXx_07_05,
    SvgXx_07_06,
    SvgXx_07_07,
    SvgXx_07_08,
    SvgXx_07_09,
    SvgXx_07_10,
    SvgTank,
    SvgVask,
} from './map-icon-components';

import { BaseIconId } from '../../SVG';
import CTLayer from '../../CTLayer';
import { CenterLeftBox } from '../../../../mui/styled-mui';
import Grid from '@mui/material/Grid';
import ReactDOMServer from 'react-dom/server';
import { SVGProps } from 'react';
import Stack from '@mui/material/Stack';
import getTextWidth from '../../../../../shared/utils/text-width';
import { shapeTypes } from '../../EditingControl';

const knownIcons: Record<string, () => JSX.Element> = {
    'vask': SvgVask,
    'tank': SvgTank,
    'xx_07_10': SvgXx_07_10,
    'xx_07_09': SvgXx_07_09,
    'xx_07_08': SvgXx_07_08,
    'xx_07_07': SvgXx_07_07,
    'xx_07_06': SvgXx_07_06,
    'xx_07_05': SvgXx_07_05,
    'xx_07_04': SvgXx_07_04,
    'xx_07_03': SvgXx_07_03,
    'xx_07_02': SvgXx_07_02,
    'xx_07_01': SvgXx_07_01,
    'xx_06_21': SvgXx_06_21,
    'xx_06_20': SvgXx_06_20,
    'xx_06_19': SvgXx_06_19,
    'xx_06_18': SvgXx_06_18,
    'xx_06_17': SvgXx_06_17,
    'xx_06_16': SvgXx_06_16,
    'xx_06_15': SvgXx_06_15,
    'xx_06_14': SvgXx_06_14,
    'xx_06_13': SvgXx_06_13,
    'xx_06_12': SvgXx_06_12,
    'xx_06_11': SvgXx_06_11,
    'xx_06_09': SvgXx_06_09,
    'xx_06_08': SvgXx_06_08,
    'xx_06_07': SvgXx_06_07,
    'xx_06_06': SvgXx_06_06,
    'xx_06_05': SvgXx_06_05,
    'xx_06_04': SvgXx_06_04,
    'xx_06_03': SvgXx_06_03,
    'xx_06_02': SvgXx_06_02,
    'xx_06_01': SvgXx_06_01,
    '4_05_20': Svg4_05_20,
    '4_05_19': Svg4_05_19,
    '4_05_18': Svg4_05_18,
    '4_05_17': Svg4_05_17,
    '4_05_16': Svg4_05_16,
    '4_05_15': Svg4_05_15,
    '4_05_14': Svg4_05_14,
    '4_05_13': Svg4_05_13,
    '4_05_12': Svg4_05_12,
    '4_05_11': Svg4_05_11,
    '4_05_10': Svg4_05_10,
    '4_05_09': Svg4_05_09,
    '4_05_08': Svg4_05_08,
    '4_05_07': Svg4_05_07,
    '4_05_06': Svg4_05_06,
    '4_05_05': Svg4_05_05,
    '4_05_04': Svg4_05_04,
    '4_05_03': Svg4_05_03,
    '4_05_02': Svg4_05_02,
    '4_05_01': Svg4_05_01,
    '3_08_01': Svg3_08_01,
    '3_07_08': Svg3_07_08,
    '3_07_07': Svg3_07_07,
    '3_07_06': Svg3_07_06,
    '3_07_05': Svg3_07_05,
    '3_07_04': Svg3_07_04,
    '3_07_03': Svg3_07_03,
    '3_07_02': Svg3_07_02,
    '3_07_01': Svg3_07_01,
    '3_04_01': Svg3_04_01,
    '3_01_07': Svg3_01_07,
    '3_01_06': Svg3_01_06,
    '3_01_05': Svg3_01_05,
    '3_01_04': Svg3_01_04,
    '3_01_03': Svg3_01_03,
    '3_01_02': Svg3_01_02,
    '3_01_01': Svg3_01_01,
    '2_07_04': Svg2_07_04,
    '2_07_03': Svg2_07_03,
    '2_07_02': Svg2_07_02,
    '2_07_01': Svg2_07_01,
    '2_06_09': Svg2_06_09,
    '2_06_08': Svg2_06_08,
    '2_06_06': Svg2_06_06,
    '2_06_05': Svg2_06_05,
    '2_06_04': Svg2_06_04,
    '2_06_03': Svg2_06_03,
    '2_06_02': Svg2_06_02,
    '2_06_01': Svg2_06_01,
    '2_05_07': Svg2_05_07,
    '2_05_06': Svg2_05_06,
    '2_05_05': Svg2_05_05,
    '2_05_04': Svg2_05_04,
    '2_05_03': Svg2_05_03,
    '2_05_02': Svg2_05_02,
    '2_05_01': Svg2_05_01,
    '2_04_10': Svg2_04_10,
    '2_04_09': Svg2_04_09,
    '2_04_08': Svg2_04_08,
    '2_04_07': Svg2_04_07,
    '2_04_06': Svg2_04_06,
    '2_04_05': Svg2_04_05,
    '2_04_04': Svg2_04_04,
    '2_04_03': Svg2_04_03,
    '2_04_02': Svg2_04_02,
    '2_04_01': Svg2_04_01,
    '2_03_07': Svg2_03_07,
    '2_03_06': Svg2_03_06,
    '2_03_05': Svg2_03_05,
    '2_03_04': Svg2_03_04,
    '2_03_03': Svg2_03_03,
    '2_03_02': Svg2_03_02,
    '2_03_01': Svg2_03_01,
    '2_02_05': Svg2_02_05,
    '2_02_04': Svg2_02_04,
    '2_02_03': Svg2_02_03,
    '2_02_02': Svg2_02_02,
    '2_02_01': Svg2_02_01,
    '2_01_15': Svg2_01_15,
    '2_01_14': Svg2_01_14,
    '2_01_13': Svg2_01_13,
    '2_01_12': Svg2_01_12,
    '2_01_11': Svg2_01_11,
    '2_01_10': Svg2_01_10,
    '2_01_09': Svg2_01_09,
    '2_01_08': Svg2_01_08,
    '2_01_07': Svg2_01_07,
    '2_01_06': Svg2_01_06,
    '2_01_05': Svg2_01_05,
    '2_01_04': Svg2_01_04,
    '2_01_04a': Svg2_01_04A,
    '2_01_03': Svg2_01_03,
    '2_01_02': Svg2_01_02,
    '2_01_01': Svg2_01_01,
    '1_08_11': Svg1_08_11,
    '1_08_01': Svg1_08_01,
    '1_07_04': Svg1_07_04,
    '1_07_03': Svg1_07_03,
    '1_07_02': Svg1_07_02,
    '1_07_01': Svg1_07_01,
    '1_06_01': Svg1_06_01,
    '1_05_16': Svg1_05_16,
    '1_05_15': Svg1_05_15,
    '1_05_14': Svg1_05_14,
    '1_05_13': Svg1_05_13,
    '1_05_12': Svg1_05_12,
    '1_05_11': Svg1_05_11,
    '1_05_10': Svg1_05_10,
    '1_05_09': Svg1_05_09,
    '1_05_08': Svg1_05_08,
    '1_05_07': Svg1_05_07,
    '1_05_06': Svg1_05_06,
    '1_05_05': Svg1_05_05,
    '1_05_04': Svg1_05_04,
    '1_05_04d': Svg1_05_04D,
    '1_05_04c': Svg1_05_04C,
    '1_05_04b': Svg1_05_04B,
    '1_05_04a': Svg1_05_04A,
    '1_05_03': Svg1_05_03,
    '1_05_02': Svg1_05_02,
    '1_05_01': Svg1_05_01,
    '1_04_03': Svg1_04_03,
    '1_04_02': Svg1_04_02,
    '1_03_01': Svg1_03_01,
    '1_02_02': Svg1_02_02,
    '1_02_01': Svg1_02_01,
    '1_01_36': Svg1_01_36,
    '1_01_35': Svg1_01_35,
    '1_01_34': Svg1_01_34,
    '1_01_33': Svg1_01_33,
    '1_01_32': Svg1_01_32,
    '1_01_31': Svg1_01_31,
    '1_01_30': Svg1_01_30,
    '1_01_29': Svg1_01_29,
    '1_01_28': Svg1_01_28,
    '1_01_27': Svg1_01_27,
    '1_01_26': Svg1_01_26,
    '1_01_25': Svg1_01_25,
    '1_01_24': Svg1_01_24,
    '1_01_23': Svg1_01_23,
    '1_01_22': Svg1_01_22,
    '1_01_21': Svg1_01_21,
    '1_01_20': Svg1_01_20,
    '1_01_19': Svg1_01_19,
    '1_01_18': Svg1_01_18,
    '1_01_17': Svg1_01_17,
    '1_01_15': Svg1_01_15,
    '1_01_14': Svg1_01_14,
    '1_01_13': Svg1_01_13,
    '1_01_12': Svg1_01_12,
    '1_01_11': Svg1_01_11,
    '1_01_10': Svg1_01_10,
    '1_01_09': Svg1_01_09,
    '1_01_08': Svg1_01_08,
    '1_01_07': Svg1_01_07,
    '1_01_06': Svg1_01_06,
    '1_01_05': Svg1_01_05,
    '1_01_03': Svg1_01_03,
    '1_01_01': Svg1_01_01,
    'abdl': SvgAbdl,
    'abv': SvgAbv,
    'brand-b': SvgArrowRepeat,
    'arrow-repeat': SvgBrandB,
    'circle': SvgCircle,
    'cursor-fill': SvgCursor,
    'cursor': SvgCursorFill,
    'el-sikker': SvgElSikker,
    'eltavle-e': SvgEltavle,
    'eltavle-s': SvgEltavleE,
    'eltavle': SvgEltavleS,
    'eraser-fill': SvgEraser,
    'eraser': SvgEraserFill,
    'flugtvejsbelysning': SvgFlugtvejsbelysning,
    'flugtvejsskilt': SvgFlugtvejsskilt,
    'lineString': SvgLineString,
    'location-marker-blue': SvgLocationMarkerBlue,
    'location-marker-chartreuse': SvgLocationMarkerChartreuse,
    'location-marker-gray': SvgLocationMarkerGray,
    'location-marker-green': SvgLocationMarkerGreen,
    'location-marker-orange': SvgLocationMarkerOrange,
    'location-marker-red': SvgLocationMarkerRed,
    'location-marker-yellow': SvgLocationMarkerYellow,
    'my-location': SvgMyLocation,
    'ovenlys': SvgOvenlys,
    'pencil': SvgPencil,
    'point': SvgPoint,
    // 'polygon': SvgPolygon,
    'rogalarm': SvgRogalarm,
    'rogalarm-ava': SvgRogalarmAva,
    'search': SvgSearch,
    'sirene': SvgSirene,
    'squat-marker': SvgSquatMarker,
    'udlosertryk': SvgUdlosertryk,
    'varslingtryk': SvgVarslingstryk,
    'ventilation': SvgVentilation,

    'adkcentral': SvgADKcentral,
    'adkundercentral': SvgADKundercentral,
    'aabnesignaldoer': SvgAabnesignaldoer,
    'klmedtastatur': SvgKlmedtastatur,
    'kludentastatur': SvgKludentastatur,
    'laas': SvgLaas,
    'nglskab': SvgNglskab,
    'skydedoer': SvgSkydedoer,
    'udtryk': SvgUdtryk,
};

export const isKnownIcon = (icon: string) => icon.toLowerCase() in knownIcons;

const DefaultSvg = ({ hexColor, props }: { hexColor: string; props?: SVGProps<SVGSVGElement> }) => {
    return <svg height={'16'} width={'16'} fill={hexColor} {...props} />;
};

// Craetes cirkle/point, line and polygon svg's
const CreateLayerIcon = (baseIconId: BaseIconId | shapeTypes, hexColor: string) => {
    switch (baseIconId) {
        case BaseIconId.Tagrende:
        case shapeTypes.Line:
            return (
                <DefaultSvg
                    hexColor={hexColor}
                    props={{
                        children: (
                            <>
                                <path
                                    transform='rotate(78 6.1851 8.01383)'
                                    d='m9.05591,5.18316l-2.67927,2.83084l2.67927,2.83083l-1.53118,1.61876l-4.21045,-4.44959l4.21045,-4.44993'
                                />
                                <path
                                    transform='rotate(-105 9.896 8.05746)'
                                    d='m12.98952,5.33369l-2.88712,2.72393l2.88712,2.72393l-1.64995,1.55763l-4.53708,-4.28155l4.53708,-4.28188'
                                />
                            </>
                        ),
                    }}
                />
            );
        case BaseIconId.Nedløb:
        case BaseIconId.Enhed:
        case shapeTypes.Point:
            return <DefaultSvg hexColor={hexColor} props={{ children: <circle cx={'8'} cy={'8'} r={'6'} /> }} />;
        case BaseIconId.Bygning:
        case shapeTypes.Polygon:
            return (
                <DefaultSvg
                    hexColor={hexColor}
                    props={{
                        children: (
                            <>
                                <polygon
                                    points='4.5,1 11.5,1 13.66,7.66 8,11.77 2.34,7.66'
                                    transform='rotate(180, 8, 8) translate(0, 2.5)'
                                />
                                <polygon
                                    points='4.5,1 11.5,1 13.66,7.66 8,11.77 2.34,7.66'
                                    transform='rotate(180, 8, 8) scale(0.5) translate(8, 5.82) translate(0, 5)'
                                    fill='#FFFFFF'
                                />
                            </>
                        ),
                    }}
                />
            );
        default:
            return <DefaultSvg hexColor={hexColor} />;
    }
};

// Used in the gui because it is react
export const TextSVG = ({ text, hexColor }: { text: string; hexColor?: string }) => {
    const textOptions: Parameters<typeof getTextWidth>[1] = {
        fontSize: '10',
        fontFamily: 'sans-serif',
        fontWeight: '400',
    };

    const width = getTextWidth(text, textOptions) + 8;

    return (
        <svg width={width} height='13' xmlns='http://www.w3.org/2000/svg'>
            <rect width={width} height='100%' stroke={hexColor} fill={hexColor} />
            <text
                x='50%'
                y='60%'
                dominantBaseline='middle'
                textAnchor='middle'
                fill='rgba(255,255,255,1)'
                {...textOptions}
            >
                {text}
            </text>
        </svg>
    );
};

export const StringTextSVG = (text: string, hexColor?: string) =>
    ReactDOMServer.renderToStaticMarkup(<TextSVG text={text} hexColor={hexColor && hexColor.replace('#', '%23')} />);

export const getIconSrc = (svgString: string, color: string = 'black'): string => {
    const lowerCase = svgString.toLowerCase();

    if (lowerCase in knownIcons) {
        // const Svg = knownIcons['1_01_07'];
        const Svg = knownIcons[lowerCase];
        const rawSvg =
            'data:image/svg+xml;utf8,' +
            ReactDOMServer.renderToStaticMarkup(<Svg />)
                .replaceAll('{{replacement-value-color}}', color) // Add layer color
                .replaceAll('#', '%23'); // Url escape breaking chars
        return rawSvg;
    }

    return 'data:image/svg+xml;utf8,' + StringTextSVG(svgString!, color ?? 'black');
};

export interface FullLayerIconProps {
    layer: CTLayer;
}

export const FullLayerIcon = ({ layer }: FullLayerIconProps) => {
    const src = getIconSrc(layer.options.svgString ?? '', layer.options.color);

    return (
        <Grid item xs='auto' sx={{ height: '100%' }}>
            <Stack alignItems={'center'} height='100%' sx={{ mr: 1, ml: 1 }}>
                <CenterLeftBox>
                    <img src={src} style={{ fill: 'green' }} />
                </CenterLeftBox>
            </Stack>
        </Grid>
    );
};

export default CreateLayerIcon;
