export class Colors {
    public static staticHexColors: string[] = [
        '#4C5E63',
        '#4C5E63',
        '#010099',
        '#339A02',
        '#00FFFD',
        '#FF00FF',
        '#0001FD',
        '#00FC00',
        '#05FCFF',
        '#FF05FF',
    ];
    public static staticRGBColors: string[] = [
        'rgb(76, 94, 99)',
        'rgb(76, 94, 99)',
        'rgb(1, 0, 153)',
        'rgb(51, 154, 2)',
        'rgb(0, 255, 253)',
        'rgb(255, 0, 255)',
        'rgb(0, 1, 253)',
        'rgb(0, 252, 0)',
        'rgb(5, 252, 255)',
        'rgb(255, 5, 255)',
    ];

    public static colorFromOpacity(colorIndex: number, opacity: number) {
        return `${this.staticRGBColors[colorIndex].substring(
            0,
            this.staticRGBColors[colorIndex].length - 1
        )},${opacity})`;
    }

    /**
     * Converts an HSV color value to RGB. Conversion formula
     * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
     * Assumes h, s, and v are contained in the set [0, 1] and
     * returns r, g, and b in the set [0, 255].
     *
     * @param h       The hue
     * @param s       The saturation
     * @param v       The value
     * @return Array  The RGB representation
     */

    public static hsvToRgb(h: number, s: number = 1, v: number = 1) {
        let r: number, g: number, b: number;

        const i = Math.floor(h * 6);
        const f = h * 6 - i;
        const p = v * (1 - s);
        const q = v * (1 - f * s);
        const t = v * (1 - (1 - f) * s);

        switch (i % 6) {
            case 0:
                r = v;
                g = t;
                b = p;
                break;
            case 1:
                r = q;
                g = v;
                b = p;
                break;
            case 2:
                r = p;
                g = v;
                b = t;
                break;
            case 3:
                r = p;
                g = q;
                b = v;
                break;
            case 4:
                r = t;
                g = p;
                b = v;
                break;
            case 5:
                r = v;
                g = p;
                b = q;
                break;
        }

        return [r! * 255, g! * 255, b! * 255];
    }
}

//let x = c * (1 - Math.abs(( ( h / (Math.PI/3) % 2) - 1)));
