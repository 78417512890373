const SvgAbdl = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlSpace='preserve'
        width={31.902}
        height={28.434}
        fill='{{replacement-value-color}}'
    >
        <path d='M2.418 27.686c-.529-.437-1.146-1.19-1.49-1.818l-.585-1.071-.172-1.33L0 22.137l.193-1.22.193-1.222.98-1.643c.54-.903 1.094-1.77 1.232-1.926.91-1.025 2.028-4.05 2.04-5.52.001-.28.08-.562.175-.626.174-.119 1.93 1.282 2.408 1.92.994 1.33 1.727 3.176 2 5.034.129.882.32 1.036.59.474.208-.435.479-.707.709-.712.18-.004.79 1.421 1.173 2.74l.33 1.134-.004 1.89-.003 1.892-.252.367c-.139.202-.252.52-.252.707 0 .427-2.094 2.802-2.47 2.802-.301 0-.389-.495-.107-.598.284-.105.577-2.076.449-3.018l-.12-.875-.766-1.205c-.94-1.48-1.62-2.955-1.622-3.522-.003-.598-.255-.53-.792.211-.616.85-1.088 2.232-1.236 3.618l-.12 1.135H4.47c-.14 0-.445-.323-.677-.716l-.42-.716-.118.243c-.064.134-.22.692-.344 1.24l-.227.998.22 1.177c.12.648.342 1.328.493 1.513l.273.335-.175.193-.175.193zm18.798.522c-.284-.098-1.095-.303-1.804-.455-.708-.153-1.829-.409-2.49-.568-.661-.16-1.86-.424-2.662-.586-.803-.162-1.597-.43-1.763-.594l-.304-.299.33-.393c.6-.713 1.415-2.744 1.602-3.985l.184-1.22-.185-1.093c-.343-2.023-.525-2.792-.795-3.362-.148-.312-.27-.635-.27-.717-.001-.082-.168-.398-.372-.702-.389-.578-.66-.587-.66-.021 0 .364-.796 1.289-.957 1.112-.054-.06-.166-.564-.248-1.12-.238-1.614-.574-2.829-1.076-3.888l-.467-.986.004-2.701.003-2.701.297-.409c.344-.474 1.516-.83 6.824-2.078.66-.156 1.55-.372 1.975-.48A169.37 169.37 0 0 1 21.888.11l.5-.111.23.346.23.346v27.131l-.27.297c-.3.33-.6.35-1.362.088M19.67 14.424c-.058-.104-.604-.19-1.212-.19-.623 0-1.107.083-1.107.19 0 .108.519.189 1.213.189.764 0 1.173-.07 1.106-.19m4.225 11.479-.189-.209V2.746l.25-.228c.537-.491 1.969.529 2.397 1.708l.273.75v18.517l-.277.763c-.294.81-.95 1.44-1.767 1.698l-.497.157zm6.062-8.123-.153-.803-1.03-.095-1.031-.094v-5.106l1.055-.056 1.055-.056.115-.842c.063-.464.176-.843.251-.843.167 0 1.545 3.273 1.671 3.97.056.307-.083.97-.36 1.717-.656 1.762-1.196 2.996-1.315 3.005-.058.004-.174-.355-.258-.797' />
    </svg>
);
export default SvgAbdl;
