import './timeline.css';

import { Dispatch, useEffect, useState } from 'react';

import AutoForm from '../../auto-form/v1/auto-form';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { IFile } from '../../file-displaying/common';
import { IGrupper } from '../../auto-form/v1';
import { ITimelineItem } from './timeline';
import ImageGrid from '../../file-displaying/image-grid';
import Popup from 'devextreme-react/popup';
import { Scrollable } from '../../misc/flex';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import useFetch from '../../../shared/hooks/fetch-hooks/use-fetch';

interface TimelinePopupProps {
    open: boolean;
    setOpen: Dispatch<React.SetStateAction<boolean>>;
    data?: ITimelineItem[];
    selectedId?: string | number;
    urlWithoutId?: string;
}

const TimelinePopup = ({
    open,
    setOpen,
    data,
    selectedId,
    urlWithoutId = '/Drift/Lookup/Journal/Skema?id=',
}: TimelinePopupProps) => {
    const [value, setValue] = useState<string | number | undefined | false>(false);
    const selection = data?.find((item) => item.id === value);
    const [schema, isLoading] = useFetch<IGrupper[] | undefined>(
        `${urlWithoutId}${value !== false ? value : selectedId}`
    );
    const date = selection?.date.split('T');
    const group = data?.filter((item) => item.group === selection?.group);

    const getTabs = () => {
        const tabs = group
            ?.sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf())
            .map((item) => (
                <Tab
                    label={new Date(item.date).toLocaleDateString('da-dk', {
                        day: '2-digit',
                        month: '2-digit',
                        year: '2-digit',
                    })}
                    key={item.id}
                    value={item.id}
                    id={`tab-${item.id}`}
                    sx={{
                        background: value === item.id ? '#e0e0e0' : '#fff',
                        minHeight: '32px',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                    }}
                />
            ));
        return tabs;
    };

    useEffect(() => {
        // Value is initialised here as a workaround for the component complaining about wrong values.
        // Initializing Tabs with Tabs.value = false fixes the issue.
        if (selectedId !== undefined) setValue((v) => (v === false ? selectedId : v));
    }, [selectedId]);

    return (
        <Popup visible={open} hideOnOutsideClick={true} title={selection?.group} onHiding={() => setOpen(false)}>
            <Box sx={{ flexGrow: 1, display: 'flex', height: '100%' }}>
                <Tabs
                    orientation='vertical'
                    variant='scrollable'
                    value={value ?? false}
                    scrollButtons
                    allowScrollButtonsMobile
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    sx={{
                        minWidth: '100px',
                        borderRight: 1,
                        borderColor: 'divider',
                        marginLeft: '-16px',
                    }}
                >
                    {getTabs()}
                </Tabs>
                <Scrollable
                    style={{ paddingLeft: '16px' }}
                    header={
                        <div style={{ paddingBottom: '8px' }}>
                            <Typography pb='20px'>
                                Udført:{' '}
                                {new Date(date?.[0] ?? '').toLocaleDateString('da-dk', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: '2-digit',
                                })}{' '}
                                {date?.[1].split('.')[0]} af {selection?.user}
                            </Typography>
                            <ImageGrid
                                disableDelete
                                files={selection?.files?.filter((file: IFile) => file.type === 'image') ?? []}
                            />
                            <Typography>{selection?.note}</Typography>
                            {!isLoading && schema?.length ? <Divider /> : <></>}
                        </div>
                    }
                    innerStyle={{ overflow: 'hidden' }}
                >
                    {!isLoading && schema ? (
                        <AutoForm
                            grupper={schema}
                            editing={false}
                            onCancel={() => {
                                return;
                            }}
                            onSubmit={() => {
                                return;
                            }}
                            submitPosition='none'
                        />
                    ) : (
                        <></>
                    )}
                </Scrollable>
            </Box>
        </Popup>
    );
};

export default TimelinePopup;
